import React from 'react'
import i18n from "i18next";

function Table ({clientManagers}) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
        <th>
          {i18n.t('clients.manager.last_name')}
        </th>
        <th>
          {i18n.t('clients.manager.first_name')}
        </th>
        <th>
          {i18n.t('clients.manager.email')}
        </th>
        <th>
          {i18n.t('clients.manager.phone')}
        </th>
      </tr>
      </thead>

      <tbody>
      {clientManagers && clientManagers.map((clientManager, index) => (
        <tr
          key={index}
          >
          <td>
            {clientManager.lastName}
          </td>
          <td>
            {clientManager.firstName}
          </td>
          <td>
            {clientManager.email}
          </td>
          <td>
            {clientManager.phone}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default Table;