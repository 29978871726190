import React, {Component} from 'react';
import TopMenu from '../../Components/TopMenu';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getSortParams, getFiltersQuery} from '../../utils';
import {api} from '../../api/apiProvider'
import i18n from "i18next";
import i18next from "i18next";
import Filter from "../../Components/NewFilter/Filter";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import apiUrls from "../../ApiUrls";
import Protected from "../../Components/Roles/Protected";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companiesTotalCount: 0,
      companyRoles: [],
      pools: [],
      page: 1,
      perPage: 30,
      filters: {
        companyRole: {
          type: 'select',
          value: null,
          placeholder: i18n.t('clients.page.role'),
          getOptionsUrlFunction: apiUrls.get.roles,
          name: 'companyRole',
          setFilterParams: (params, filterValue) => {
            if (filterValue) {
              params.set('roles.code', filterValue.value)
            }
          },
          getValueToUrl: (params, role) => {
            if (role && role.value) {
              params.set('companyRole', role.value)
            } else {
              params.delete('companyRole')
            }
          },
          optionValueName: 'code'
        },
        name: {
          type: 'text',
          value: null,
          placeholder: i18n.t('clients.page.name'),
          //getOptionsUrlFunction: apiUrls.get.pools,
          name: 'name',
          setFilterParams: (params, value) => {
            if (value) {
              params.set('name', value)
            }
          },
          getValueToUrl: (params, name) => {
            if (name) {
              params.set('name', name)
            } else {
              params.delete('name')
            }
          },
        },
        pool: {
          type: 'select',
          value: null,
          placeholder: i18n.t('clients.page.pool'),
          getOptionsUrlFunction: apiUrls.get.pools,
          name: 'pool',
          setFilterParams: (params, filterValue) => {
            if (filterValue) {
              params.set('pool.id', filterValue.value)
            }
          },
          getValueToUrl: (params, pool) => {
            if (pool && pool.value) {
              params.set('pool', pool.value)
            } else {
              params.delete('pool')
            }
          },
          isEmptyOption: true
        },
      }
    }
  }

  getData = (filterString) => {
    this.showLoader()
    let url = apiUrls.get.companies() + "?t[]=company_list&" + filterString;

    url += getSortParams(this.state.sort).toString()

    api.get(url).then((data) => {
      const companies = data['hydra:member'];
      this.setState({companies})
      this.setState({companiesTotalCount: data['hydra:totalItems']})
      this.hideLoader()
    }).catch(() => {
      this.hideLoader()
    })
  }

  showLoader = () => {
    this.setState({showLoader: true})
  }

  hideLoader = () => {
    this.setState({showLoader: false})
  }

  deleteClient = async (companyId) => {
    try {
      await api.delete(apiUrls.delete.companies(companyId));
      this.getData();
    } catch (error) {
      toast.error(i18n.t('clients.page.delete_fail'))
    }
  }

  setSort = sort => {
    this.setState({sort}, () => this.goToFilters());
  }

  handlePageClick = (data) => {
    if (data.selected !== undefined) {
      this.setPage(+data.selected + 1)
    }
  }

  setPage = page => {
    this.setState({page})
  }

  render() {
    return (
      <div className="container-fluid padding-side-15">
        <div className="row">
          <TopMenu title={i18n.t('clients.page.company')}>
            <Protected access={'ClientsEdit'}>
              <Link className="button-accent" to="/client/new">
                {i18n.t('clients.page.new_company')}
              </Link>
            </Protected>
          </TopMenu>
          <Filter
            filters={Object.values(this.state.filters)}
            getData={this.getData}
            page={this.state.page}
            setPage={this.setPage}
          />
          <Table
            companies={this.state.companies}
            deleteClient={this.deleteClient}
          />
          {(this.state.companiesTotalCount > 0 && this.state.companiesTotalCount > this.state.perPage) &&
            <ReactPaginate
              previousLabel={i18next.t('projects.list.prev')}
              nextLabel={i18next.t('projects.list.next')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(this.state.companiesTotalCount / this.state.perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={this.state.page - 1}
            />
          }
        </div>
      </div>
    );
  }
}

export default List;