import React, {useEffect, useState} from 'react'
import MenuClient from '../../Components/MenuClient'
import i18n from "i18next"
import Table from "./Table"
import apiUrls from "../../ApiUrls"
import useApiFetch from "../../Components/Hooks/useApiFetch"
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

const maxDelta = 1000
const dateFormat = 'DD-MM-YYYY'

function List() {
  const [dateStart, setDateStart] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)

  const [{data: registryFaces}, getRegistryFaces] = useApiFetch(apiUrls.get.invoiceRegistryFaces())

  const [{data: clients}, getClients] = useApiFetch(apiUrls.get.clients())

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    if (dateStart && dateEnd && selectedClient) {
      getRegistryFaces({
        client_id: selectedClient.value,
        date_start: dateStart,
        date_end: dateEnd,
      })
    }
  }, [dateStart, dateEnd, selectedClient])

  const setDates = date => {
    const dateStart = moment(date).format(dateFormat)
    const dateEnd = moment(date).endOf('month').format(dateFormat)

    setSelectedDate(date)
    setDateStart(dateStart)
    setDateEnd(dateEnd)
  }

  const clientOptions = clients
    ? clients.map(client => ({value: client.id, label: client.name}))
    : []

  if (registryFaces) {
    for (let face of registryFaces) {
      if (face.invoiceFace && face.registryFace) {
        if (Math.abs(face.invoiceFace.spent - face.registryFace.expenses) >= maxDelta) {
          face.isError = true
        }
      }
    }
  }
  
  return (
    <div className="row content flex">
      <div className="edit-client">
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('reconciliation.title')}>
            </MenuClient>

            <div className="menu-filter flex-align-center">
              <span className={'filter-item'}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder={i18n.t('reconciliation.client')}
                  options={clientOptions}
                  onChange={(client) => {
                    setSelectedClient(client)
                  }}
                  value={selectedClient || ''} />
              </span>

              <span className={'filter-item'}>
                <DatePicker
                  placeholderText={i18n.t('reconciliation.month')}
                  selected={selectedDate}
                  onChange={(value) => setDates(value)}
                  dateFormat={"MM.yyyy"}
                  showMonthYearPicker
                />
              </span>
            </div>

            {registryFaces && registryFaces.length > 0 && dateStart && dateEnd && selectedClient &&
            <Table
              registryFaces={registryFaces}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;