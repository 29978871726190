import React, {useState, useEffect} from 'react';
import MenuClient from '../../Components/MenuClient';
import Select from 'react-select';
import {api} from '../../api/apiProvider';
import Input from '../../Components/Inputs/Input';
import DatePicker from "react-datepicker";
import {toast} from 'react-toastify';
import moment from 'moment';
import {Link} from 'react-router-dom';

import i18n from "i18next";
import FaceTable from "./FaceTable";
import apiUrls from "../../ApiUrls";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import useApiPost from "../../Components/Hooks/useApiPost";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import {getFormattedDate} from "../Advertising/Detail/Fact/Utils";
import xlsServiceProvider from "../../api/xlsServiceProvider";
import DownloadFileButton from "../../Components/Buttons/DownloadFileButton";
import {DigitalChannel} from "../../entities/channel/DigitalChannel";
import Protected from "../../Components/Roles/Protected";

const dateFormat = 'YYYY-MM-DD'

function InvoiceEdit(props) {
  const invoiceId = props.match.params.invoiceId
  const [selectedLegalEntity, setSelectedLegalEntity] = useState({})
  const [channel, setChannel] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [formInvoice, setFormInvoice] = useState({})
  const [{data: invoice}, getInvoice] = useApiFetch(apiUrls.get.invoice)
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: invoiceRows}, getInvoiceRows] = useApiFetch(apiUrls.get.invoiceRows(invoiceId))
  const [{data: legalEntities}, getLegalEntities] = useApiFetch(apiUrls.get.clientLegals())

  const [{errors: addErrors}, addInvoice] = useApiPost(
    apiUrls.get.invoices(),
    '',
    'invoice.edit',
    (invoiceId) => {
      toast.success(i18n.t('invoice.edit.created'))
      props.history.push(apiUrls.get.invoiceEdit(invoiceId))
      getInvoice({}, invoiceId)
    }
  )

  const [{errors: updateErrors}, updateInvoice] = useApiPatch(
    apiUrls.get.invoice,
    '',
    'invoice.edit',
    (invoiceId) => {
      toast.success(i18n.t('invoice.edit.updated'))
      props.history.push(apiUrls.get.invoices())
    }
  )

  const isNew = () => {
    return invoiceId === undefined
  }

  useEffect(() => {
    getLegalEntities()
    getChannels()

    if (!isNew()) {
      getInvoice({}, invoiceId)
      getInvoiceRows()
    } else {
      const date = new Date()
      formInvoice.dateStart = moment.utc(date).startOf('month').toDate()
      formInvoice.dateEnd = moment.utc(date).endOf('month').toDate()
    }
  }, [])

  useEffect(() => {
    if (channels) {
      const defaultChannel = channels.find(channel => channel.code === DigitalChannel.getCode())

      if (defaultChannel) {
        setChannel(defaultChannel)
      }
    }
  }, [channels])

  useEffect(() => {
    if (invoice) {
      setFormInvoice(invoice)

      setSelectedLegalEntity({
        label: invoice.legalEntity.name,
        value: invoice.legalEntity.id,
      })

      setCompanyId(invoice.legalEntity.company.split('/').pop())
    }
  }, [invoice])

  const onLegalEntityChange = (value) => {
    setSelectedLegalEntity(value)

    setFormInvoice({
      ...formInvoice,
      legalEntity: value
    })
  }

  const onChange = (value, name) => {
    setFormInvoice({
      ...formInvoice,
      [name]: value
    })
  }

  const onDateChange = (date) => {
    const dateStart = moment.utc(date).startOf('month').toISOString()
    const dateEnd = moment.utc(date).endOf('month').toISOString()

    setFormInvoice({
      ...formInvoice,
      dateStart,
      dateEnd
    })
  }

  const isEmptyFields = () => {
    return (!selectedLegalEntity
      || !selectedLegalEntity.value
      || !formInvoice.name
      || !formInvoice.dateStart
      || !formInvoice.dateEnd)
  }

  const isNotEndedPeriod = () => {
    const currentDate = moment.utc().startOf('month').toDate()
    const invoiceDate = moment.utc(formInvoice.dateStart)

    return invoiceDate >= currentDate
  }

  const onInvoiceAdd = () => {
    if (isEmptyFields()) {
      toast.error(i18n.t('clients.edit.empty_fields'));
      return
    }

    if (isNotEndedPeriod()) {
      toast.error(i18n.t('invoice.edit.not_ended_period_add'));
      return
    }

    try {
      addInvoice({
        legalEntity: selectedLegalEntity.value,
        name: formInvoice.name,
        dateStart: getFormattedDate(formInvoice.dateStart),
        dateEnd: getFormattedDate(formInvoice.dateEnd),
      })
    } catch (error) {
      toast.error(i18n.t('invoice.edit.save_fail'))
    }
  }

  const onInvoiceUpdate = () => {
    if (isEmptyFields()) {
      toast.error(i18n.t('clients.edit.empty_fields'));
      return
    }

    if (isNotEndedPeriod()) {
      toast.error(i18n.t('invoice.edit.not_ended_period_update'));
      return
    }

    try {
      updateInvoice(invoiceId, {
        legalEntity: 'legals/' + selectedLegalEntity.value,
        name: formInvoice.name,
        dateStart: getFormattedDate(formInvoice.dateStart),
        dateEnd: getFormattedDate(formInvoice.dateEnd)
      })
    } catch (error) {
      toast.error(i18n.t('invoice.edit.edit_fail'))
    }
  }

  const onSaveClick = () => {
    if (isNew()) {
      onInvoiceAdd()
    } else {
      onInvoiceUpdate()
    }
  }

  const deleteRow = async (id) => {
    const data = await api.delete(apiUrls.delete.invoiceRow(invoiceId, id));

    toast.success(i18n.t('invoice.edit.deleted'));
    getInvoiceRows()
  }

  const isRows = () => {
    return invoice && invoiceRows && invoiceRows.length > 0
  }

  const getTitle = () => {
    return isNew()
      ? i18n.t('invoice.edit.add')
      : i18n.t('invoice.edit.edit')
  }

  const getLegalEntityOptions = () => {
    return legalEntities
      ? legalEntities.map((legalEntity) => {
        return {value: legalEntity["@id"], label: legalEntity.name}
      })
      : []
  }

  const getFaceEditFilters = () => {
    if (!invoice || !channel || !channel.id) {
      return ''
    }

    const invoiceDate = moment(invoice.dateStart).startOf('month').format(dateFormat)

    const dateFilter = '&date=' + invoiceDate

    const companyFilter = 'company=' + companyId

    const channelFilter = 'channel=' + channel.id

    return dateFilter + '&' + companyFilter + '&' + channelFilter
  }

  return (
    <div className="row content flex">
      <div className="edit-client edit-invoice">
        <div className="table-edit-client advertising-table">
          <MenuClient title={getTitle()}>
            <Protected access={'FinancialInvoicesEdit'}>
              <button className="button-accent" onClick={onSaveClick}>
                {i18n.t('clients.edit.save')}
              </button>
            </Protected>

            <Protected access={'FinancialInvoicesEdit'}>
              <DownloadFileButton
                text={i18n.t('invoice.edit.get_xls')}
                getFunction={() => xlsServiceProvider.getForInvoice(invoiceId)}
                fileName={`invoice_${invoiceId}.xlsx`}
              />
            </Protected>

            <Link
              className="blue-cursor-button back-button"
              to={'/invoices'}
            >{i18n.t('invoice.edit.back')}</Link>
          </MenuClient>
          <div>
            <table className="table redesign-table table-client">
              <tbody>
              <tr>
                <td>
                  {i18n.t('clients.edit.name')}
                </td>
              </tr>
              <tr>
                <td>
                  <Input
                    value={formInvoice.name || ''}
                    onChange={value => onChange(value, 'name')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Select
                    className={'react-select-container select-pseudo-input'}
                    classNamePrefix="react-select"
                    placeholder={i18n.t('invoice.edit.select_legal')}
                    options={getLegalEntityOptions()}
                    value={selectedLegalEntity}
                    onChange={onLegalEntityChange}
                    /*isDisabled={!this.isNew}*/ />
                </td>
              </tr>
              <tr>
                <td>
                  {i18n.t('clients.advertising.month')}
                </td>
              </tr>
              {formInvoice.dateStart &&
              <tr>
                <td>
                    <span className="input-wrap">
                      <DatePicker
                        selected={moment.utc(formInvoice.dateStart).toDate()}
                        onChange={onDateChange}
                        showMonthYearPicker
                        dateFormat="MM.yyyy"
                      /></span>
                </td>
              </tr>
              }
              </tbody>
            </table>

            {!isNew() &&
            <div>{i18n.t('invoice.edit.faces_list')}</div>
            }

            {invoiceRows && invoice && channel &&
            <FaceTable
              invoice={invoice}
              rows={invoiceRows}
              deleteRow={deleteRow}
              companyId={companyId}
            />
            }

            {!isNew() && !isRows() &&
            <span>{i18n.t('invoice.edit.empty_faces')}</span>
            }

            <Protected access={'FinancialInvoicesEdit'}>
              {!isNew() && invoice &&
              <div>
                <Link
                  to={'/faces-without-invoice/' + invoice.id + '?' + getFaceEditFilters()}
                >{i18n.t('invoice.edit.add_faces')}</Link>
              </div>
              }
            </Protected>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceEdit;
