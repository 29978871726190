import React from 'react';
import i18n from "i18next";
import Select from "react-select";
import {cloneDeep} from "lodash";
import FloatInput from "../../../../Components/Inputs/FloatInput";

export default function FactorRow({factor, digitalFactors, onChange, isDisabled}) {
  const getOptions = items => {
    return items
      ? items.map((item) => {
        return {value: item['@id'], label: item.name}
      })
      : []
  }

  const onFactorChange = (name, value) => {
    const toChange = cloneDeep(factor)
    onChange({
      ...toChange,
      [name]: value,
    })
  }

  const selectedFactor = factor => digitalFactorsOptions.find(option => option.value === factor)

  const digitalFactorsOptions = getOptions(digitalFactors)

  return (
    <div key={Object.keys(factor).pop()} className={'datasource-item'}>
      <Select
        className="react-select-container select-pseudo-input small"
        classNamePrefix="react-select"
        placeholder={i18n.t('periods.face.select')}
        options={digitalFactorsOptions}
        value={selectedFactor(factor.factor)}
        onChange={value => onFactorChange('factor', value.value)}
        isDisabled={isDisabled}
      />

      <FloatInput
        className={'factor-value'}
        value={factor ? factor.value : ''}
        onChange={(value) => onFactorChange('value', value)}
      />
    </div>
  )
}