import React, {useEffect, useState} from 'react';
import {cloneDeep} from 'lodash'
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import DataSourceRow from "./DataSourceRow";
import TrackingSourceRow from "./TrackingSourceRow";
import {deepClone} from "transliteration/dist/node/src/common/utils";

export default function TrackingSourcesBlock({sources, onChange, formFace, isDisabled, optionsUrl}) {
  const [keys, setKeys] = useState([])
  const [values, setValues] = useState([])

  const [{data: options}, getOptions, setOptions] = useApiFetch(optionsUrl)

  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    if (options) {
      const keyValues = []
      const values = []

      for (let item of options) {
        keyValues.push({
          value: item.name,
          label: item.name,
        })

        values[item.name] = item.values
      }

      setKeys(keyValues)
      setValues(values)
    }
  }, [options])

  const onCreateValue = (key, value, index) => {
    if (options) {
      const newOptions = deepClone(options)

      if (newOptions.length) {
        for (let option of newOptions) {
          if (option.name && option.name === key) {
            option.values.push(value)
            setOptions(newOptions)
            onRowChange(index, {
              [key]: value
            })
            break
          }
        }
      } else if (keys && keys.length) {
        setOptions([{
          name: key,
          values: [value],
        }])
        onRowChange(index, {
          [key]: value
        })
      }
    }
  }

  const onRowChange = (index, value) => {
    let newSources = cloneDeep(sources)

    if (newSources[index]) {
      newSources[index] = value;
    }

    onChange(newSources)
  }

  const deleteRow = (index) => {
    let newSources = cloneDeep(sources)

    newSources.splice(index, 1);

    onChange(newSources)
  }

  const addRow = () => {
    let newSources = cloneDeep(sources) || []

    newSources.push({})

    onChange(newSources)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'datasources-wrapper'}>
        <div>
          <div>
            {sources && sources.length > 0 &&
            <>
              {sources.map((datasource, index) => (
                <div className={'datasource-wrapper'} key={index}>
                  <TrackingSourceRow
                    source={datasource}
                    keyOptions={keys}
                    values={values}
                    formFace={formFace}
                    onChange={value => onRowChange(index, value)}
                    isDisabled={isDisabled}
                    onCreateValue={(key, value) => onCreateValue(key, value, index)}
                  />
                  {!isDisabled &&
                    <button className={'datasource-delete'} onClick={() => deleteRow(index)}>
                      <i className="fal fa-trash-alt icon-delete"/>
                    </button>
                  }
                </div>
              ))}
            </>
            }

            {!isDisabled && keys && keys.length > 0 &&
            <a onClick={() => addRow()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
            }

          </div>
        </div>
      </div>
    </div>
  )
}