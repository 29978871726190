import React from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";

function DateInput(props) {
  return (
    <span className={'filter-item ' + props.filter.className}>
      <DatePicker
        placeholderText={props.filter.placeholder}
        selected={props.filter.value ?
          moment(props.filter.value, props.filter.filterFormat).toDate() : ''}
        onChange={(value) => {
          props.onChange(props.filter.name, (value ? moment(value).format(props.filter.filterFormat) : ''))
        }}
        startDate={props.filter.startDate}
        endDate={props.filter.endDate}
        dateFormat={props.filter.format}
        showMonthYearPicker={props.filter.showMonthYearPicker}
        //selectsRange={props.filter.isRange || false}
      /></span>
  )
}

export default DateInput