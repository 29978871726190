import React from 'react'
import Select from "react-select";

function DropdownInput(props) {
  const onChange = (value) => {
    if (Array.isArray(value)) {
      const selectAllOption = value.find(item => item.value === 'all')
      
      if (selectAllOption) {
        props.onChange([selectAllOption])
        return
      }
    }

    props.onChange(value)
  }

  return (
    <span className={'filter-item ' + props.filter.className}>
      <Select className="react-select-container"
        classNamePrefix="react-select"
        options={props.filter.options}
        placeholder={props.filter.placeholder}
        onChange={onChange}
        isMulti={props.filter.isMulti}
        value={props.filter.value}
        isDisabled={props.filter.isDisabled}
      />
      </span>
  )
}

export default DropdownInput