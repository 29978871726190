import React, { Component } from 'react';
import { api } from '../api/apiProvider';
import { toast } from 'react-toastify';
import Input from '../Components/Inputs/Input';
import {parseJwt} from "../utils";
import {setUserData} from "../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function mapDispatchToProps(dispatch) {
    return {
        setUserData: (user) => dispatch(setUserData(user))
    }
}

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            loginName: "",
            passwordValue: ""
        }
    }
    onLoginClick = async () => {
        try {
            const body = {
                username: this.state.loginName,
                password: this.state.passwordValue
            }
            const data = await api.post("/authentication_token", body)
            localStorage.setItem("token", data.token);
            const jwtData = parseJwt(data.token);

            this.props.setUserData({
                username: jwtData.username,
                companyID: null,
                roles: jwtData.roles
            })
            
            this.setState({userLoggedIn: true})
            this.props.onLoginSuccess();

            const backUrl = localStorage.getItem("backUrl");

            if (backUrl && backUrl !== '/login') {
                this.props.history.push(backUrl)
            } else {
                this.props.history.push('/')
            }
        } catch (error) {
            toast.error(error.message)
        }
    }
    render() {
        return (
            <div className="container-login">
                <div>
                    <div className="name-page-login">
                        Login
                    </div>
                </div>
                <div>
                    <div>
                        <Input type="text" placeholder="User" value={this.state.loginName} onChange={(value) => this.setState({loginName:value})}/>
                    </div>
                    <div>
                        <Input type="password" placeholder="Password" value={this.state.passwordValue} onChange={(value) => this.setState({passwordValue:value})}/>
                    </div>
                </div>
                <div className="btn-group-admin">
                    <button className="button-accent" onClick={this.onLoginClick}>
                        Login
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(Login)