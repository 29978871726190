import React from "react";
import Loader from "../Loader/Loader";

const nope = () => {
}

function IconButtonWithLoader({title, icon, isLoading = false, onClick = nope}) {
  return (
    <button className={'icon-button-with-loader' + (isLoading ? ' loading' : '') }
            disabled={isLoading}
            onClick={onClick}
            title={title}>
      {isLoading ?
        <Loader/>
        : icon
      }
    </button>
  );
}

export default IconButtonWithLoader;