import React from 'react';
import IntInput from '../../../../Components/Inputs/IntInput';
import FloatInput from "../../../../Components/Inputs/FloatInput";
import {getFieldValue} from "../../../../utils";
import FormattedNumber from "../../../../Components/FormattedNumber";
import {Link} from "react-router-dom";
import Roles from "../../../../roles/Roles";

const floatType = 'float'
const intType = 'int'

function FactRowCell({
                       property,
                       detail,
                       onChange,
                       onSave,
                       faceReturn,
                       faceCommissions,
}) {
  const isEditable = () => {
    if (!detail.fact || detail.fact.editable === undefined) {
      return true
    }

    return Boolean(detail ? detail.fact.editable : false)
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    className: 'money',
    value: detail.fact ? detail.fact.value : '',
    disabled: !isEditable(),
    onKeyDown: handleKeyDown
  }

  return (
    <td key={property.code} className={'money'}>
      <div className={'detail-cell'}>
        {property.code === 'return'
          ? <div>
            <div>{faceReturn || ''}</div>
            {faceCommissions && faceCommissions.map(commission => {
              const commissionId = commission.id

              return <Link to={`/financial/commission/${commissionId}/edit`} key={commissionId}>{commissionId}</Link>
            })}
          </div>
          :
          <>
            {Roles.hasAccess('AdvertisingsFactsEdit') &&
              <>
                {property.type === floatType &&
                <FloatInput
                  onChange={(value) => onChange(value, property)}
                  allowEmptyFormatting={true}
                  allowEmpty={true}
                  {...inputProps}
                />
                }

                {property.type === intType &&
                <IntInput
                  onChange={(value) => onChange(value, property)}
                  allowEmptyFormatting={true}
                  allowEmpty={true}
                  {...inputProps}
                />
                }

              </>
            }

            <FloatInput
              value={(detail.plan && getFieldValue(detail.plan, true)) || ''}
              allowEmptyFormatting={true}
              allowEmpty={true}
              displayType={'text'}
              type={property.type} />

            {detail.bottom &&
              <span className={detail.bottomClass}>{detail.bottom}</span>
            }
          </>
        }
      </div>
    </td>
  )
}

export default FactRowCell
