import {useHistory} from "react-router-dom";
import {getValuesFromUrl} from "../../utils";

const useUrlParams = () => {
  const history = useHistory()

  const get = () => getValuesFromUrl()

  const set = (name, value) => {
    const params = new URLSearchParams(history.location.search)

    if (Array.isArray(value)) {
      params.delete(name)

      for (let item of value) {
        params.append(name, item.value)
      }
    } else {
      params.set(name, value)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const remove = name => {
    const params = new URLSearchParams(history.location.search)

    if (params.has(name)) {
      params.delete(name)

      const paramsString = params.toString()

      if (paramsString) {
        history.push(history.location.pathname + '?' + params.toString())
      } else {
        history.replace(history.location.pathname)
      }
    }
  }

  return {
    set, get, remove
  }
}

export default useUrlParams