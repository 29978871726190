import React from 'react';
import {Link} from "react-router-dom";

function AdvertisingsList({title, advertisings}) {
  return (
    <div className={'advertisings-list'}>
      <div className={'advertisings-list__title'}>{title}</div>
      <div className={'advertisings-list__list'}>
        {advertisings.map((advertising, index) => (
          <div key={advertising.id + index}>
            <Link to={`/advertisings/${advertising.id}`}>{advertising.name}</Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdvertisingsList
