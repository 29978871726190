import React, {Fragment} from 'react';
import SumRowCell from "../../../Views/Advertising/Detail/components/Table/SumRowCell";
import Checkbox from "../../../Components/Inputs/Checkbox";

function SumRow({
                  properties,
                  values,
                  title,
                  titleColSpan = 1,
                  cellsAfterTitle,
                  cellsAfterProps,
                  cellEmptyValue,
                  onCheckChange = () => {},
}) {
  return (
    <tr className={'sum-values-row'}>
      <td colSpan={titleColSpan}><span>{title}</span></td>
      {cellsAfterTitle && cellsAfterTitle}

      {properties && properties.map(property => (
        <Fragment key={property.code}>
          {
            (property.code !== 'return' && property.type !== 'bool') &&
              <SumRowCell
                value={values[property.code]}
                type={property.type}
                cellEmptyValue={cellEmptyValue}
              />
          }

          {property.type === 'bool' &&
            <td>
              <div className="detail-cell">
                <Checkbox onChange={(e) => onCheckChange(e, property.code)} />
              </div>
            </td>
          }

          {property.code === 'return' &&
          <td/>
          }
        </Fragment>
      ))}

      {cellsAfterProps && cellsAfterProps}
    </tr>
  )
}

export default SumRow;