import React from 'react';
import {getFieldValue} from "../../../utils";
import FormattedNumber from "../../../Components/FormattedNumber";
import Roles from "../../../roles/Roles";
import FloatInput from "../../../Components/Inputs/FloatInput";
import IntInput from "../../../Components/Inputs/IntInput";

const floatType = 'float'
const intType = 'int'

function PlanRowCell({
                       property,
                       detail,
                       onChange,
                       onSave,
                       editablePropertiesCodes = []
}) {
  const isEditable = () => {
    if (!detail || detail.editable === undefined) {
      return false
    }

    return Boolean((editablePropertiesCodes.includes(property.code)
    && detail) ? detail.editable : false)
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    className: 'money',
    value: detail && detail.value ? detail.value : 0,
    disabled: !isEditable(),
    onKeyDown: handleKeyDown
  }

  return (
    <td key={property.code} className={'money'}>
      <div className={'detail-cell'}>
        <>
          {
          <>
            {property.type === floatType &&
            <FloatInput
              onChange={(value) => onChange(value, property)}
              {...inputProps}
            />
            }

            {property.type === intType &&
            <IntInput
              onChange={(value) => onChange(value, property)}
              {...inputProps}
            />
            }
          </>
          }
        </>
      </div>
    </td>
  )
}

export default PlanRowCell
