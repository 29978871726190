import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import 'antd/dist/antd.css';
import Select from "react-select";
import useLocalStorage from "../../../../Components/Hooks/useLocalStorage";
import MultiSelectWithCheckbox from "../../../../Components/Inputs/MultiSelectWithCheckbox";

export default function PropsFilter({channelEntity, isLoading, setFilter, properties, setSelectedProperties}) {
  const [selectedProps, setSelectedProps] = useState(null)

  const [savedProperties, setSavedProperties] = useLocalStorage('plan-properties', [])

  useEffect(() => {
    if (properties) {
      const savedPropertyCodes = (savedProperties && savedProperties.length)
        ? savedProperties
        : properties.map(property => property.code)

      const selectedProperties = properties.filter(property => savedPropertyCodes.includes(property.code))

      if (selectedProperties.length) {
        const selectedPropertyOptions = []

        for (let selectedProperty of selectedProperties) {
          selectedPropertyOptions.push({
            value: selectedProperty.code,
            label: selectedProperty.name,
          })
        }

        setSelectedProps(selectedPropertyOptions)
      }
    }
  }, [properties])

  useEffect(() => {
    if (properties) {
      const selectedPropertyCodes = selectedProps.map(property => property.value)

      let selectedProperties = (selectedProps && selectedProps.length)
        ? properties.filter(property => selectedPropertyCodes.includes(property.code))
        : []

      setFilter('properties', selectedProperties.map(property => property.code))
      setSelectedProperties(selectedProperties)
      setSavedProperties(selectedPropertyCodes)
    }
  }, [selectedProps])

  const propsOptions = (properties && properties.length)
    ? properties.map(property => ({
      value: property.code,
      label: property.name,
    }))
    : []

  return (
    <div className="filter-group-wrapper filter-group-wrapper__props">
      <div className="filter-group__title">
        {i18n.t('clients.advertising.select_metrics')}
      </div>
      <MultiSelectWithCheckbox
        className={'className'}
        classNamePrefix="react-select"
        placeholder={i18n.t('common.select')}
        options={propsOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={setSelectedProps}
        allowSelectAll={true}
        value={selectedProps}
      />
    </div>
  )
}