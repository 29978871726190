import React from 'react'
import i18n from "i18next";
import {Link} from "react-router-dom";
import DeleteWithConfirmButton from "../../Components/Buttons/DeleteWithConfirmButton";
import SortIcon from "../../Components/SortIcon";

function Table({products, companyId, deleteProduct = () => {}, sort, onSortClick = () => {}}) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
        <th>
          <div className={'sort ' + (sort['id'] || '')}
               onClick={() => onSortClick('id')}
          >
            <span>{i18n.t('products.list.id')}</span>
            <SortIcon
              name={'id'}
              sort={sort}
            />
          </div>
        </th>
        <th>
          <div className={'sort ' + (sort['name'] || '')}
               onClick={() => onSortClick('name')}
          >
            <span>{i18n.t('products.list.name')}</span>
            <SortIcon
              name={'name'}
              sort={sort}
            />
          </div>
        </th>
        <th>
          {i18n.t('products.list.mrmCode')}
        </th>
        <th>
          {i18n.t('products.list.foreignCode')}
        </th>
        <th />
      </tr>
      </thead>

      <tbody>
      {products.map((product, index) => (
        <tr
          key={index}
        >
          <td key={index}>
            {product.id}
          </td>
          <td>
            <Link to={"/client/" + companyId + "/product/" + product.id}>
              {product.name}
            </Link>
          </td>
          <td>
            {product.mrmCode ? i18n.t('common.yes') : i18n.t('common.no')}
          </td>
          <td>
            {product.foreignCode ? i18n.t('common.yes') : i18n.t('common.no')}
          </td>
          <td />
          <td className="right-text">
            <DeleteWithConfirmButton onClick={() => deleteProduct(product.id)}
              title={i18n.t('common.delete')} iconClass={'icon-delete'}/>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default Table;