import React from 'react';
import FloatInput from "../../../../Components/Inputs/FloatInput";
import IntInput from "../../../../Components/Inputs/IntInput";

function RatioRow({ratio, onRatioChange, formFace}) {
  const ratioProp = ratio;

  const getRatioValue = (ratioIri) => {
    if (!formFace || !formFace.faceUnits || !formFace.faceUnits.length || !formFace.faceUnits[0]
      || !formFace.faceUnits[0].ratios || !formFace.faceUnits[0].ratios.length) {
      return ratioProp.asPercent ? 0 : 1
    }

    const ratio = formFace.faceUnits[0].ratios.find(ratio => (ratio.ratio && ratio.ratio['@id'] === ratioIri))

    if (ratio && ratio.value !== undefined) {
      return ratio.value
    }

    return ratioProp.asPercent ? 0 : 1
  }

  return (
    <div key={ratio.id} className={'ratios-item'}>
      <div className={'ratios-item__name'}>
        {ratio.name}
      </div>
      <div>
        {ratio.asPercent &&
          <div className={'ratios-item__percents'}>
            <FloatInput
              onChange={(value) => onRatioChange(ratio['@id'], value, true)}
              value={getRatioValue(ratio['@id'])}
              disabled={!(formFace && formFace.faceUnits && formFace.faceUnits[0])}
            />
            <span>&nbsp;%</span>
          </div>
        }

        {!ratio.asPercent &&
        <>
          <FloatInput
            onChange={(value) => onRatioChange(ratio['@id'], value)}
            value={getRatioValue(ratio['@id'])}
            disabled={!(formFace && formFace.faceUnits && formFace.faceUnits[0])}
          />
        </>
        }
      </div>
    </div>
  )
}

export default RatioRow;