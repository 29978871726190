import {Channel} from "./Channel";

export class InternetChannel extends Channel {
  static code = 'internet'
  code = 'internet'
  contractorFilter = 'internetFaces.contractor.id'
  faceName = 'internetFace'
  facesName = 'internetFaces'
  platformName = 'internetPlatform'
  platformsName = 'internetPlatforms'
  propertiesName = 'internetProperties'
  faceAdvertisingCompanyIdFilter = 'internetFd.face.advertising.company.id'
  contractorCheckRowFilter = 'internetFd.face.id[]'

  apiUrls = {
    faces: '/internet_faces',
    platforms: '/internet_platforms',
    channels: '/internet_channels',
    fms: '/internet_fms',
    corrections: '/internet_corrections',
    platformClients: '/internet_platform_contractors',
    properties: '/internet_properties',
    faceCommission: '/internet_face_commission',
    faceCommissions: '/internet_face_commissions',
    totalPlan: `/data/plan/${this.code}/faces`,
    totalFact: `/data/total/${this.code}/faces`
  }
}