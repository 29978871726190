import React from "react";
import i18n from "i18next";

function DeleteWithConfirmButton({
                             onClick = () => {},
                             title = '',
                             className = '',
                             iconClass = '',
  }) {

  const click = () => {
    const isConfirm = window.confirm(i18n.t('common.delete') + '?')

    if (isConfirm) {
      onClick()
    }
  }

  return (
    <span
      onClick={click}
      title={title}
      className={'delete-button ' + className}
    >
      <i className={"fal fa-trash-alt " + iconClass} />
    </span>
  );
}

export default DeleteWithConfirmButton;