import React, {useEffect, useState} from 'react';
import PageName from "./PageName";
import GeneralInfo from "./GeneralInfo";
import Plan from "./Plan";
import i18n from "i18next";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";

function Page (props) {
  const advertisingId = props.match.params.advertisingId

  const [{
    data: advertising,
    isLoading: isAdvertisingLoading
  }, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))

  useEffect(() => {
    getAdvertising({
      't[]': 'advert:read'
    })
  }, [])

  return (
    <div className="page-container padding-side-15">
      {advertising &&
        <>
          <PageName advertising={advertising}/>
          <GeneralInfo advertising={advertising}/>
          <Plan advertisingId={advertisingId} advertising={advertising}/>
        </>
      }

    </div>
  )
}
export default Page