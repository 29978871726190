import React, {useState, useEffect, Fragment} from 'react';
import TopMenu from "../../../Components/TopMenu";
import i18n from "i18next";
import ErrorBoundary from "../../../Components/ErrorBoundary";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import {
  getFormattedDate,
  getUtcDate,
} from "./Utils";
import i18next from "i18next";
import Filter from "../../../Components/NewFilter/Filter";
import {cloneDeep} from "lodash";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import SumRow from "../../Advertising/Detail/components/Table/SumRow";
import Row from "../../Advertising/Detail/components/Table/Row";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import FacesRowCell from "./FacesRowCell";
import Header from "../../Advertising/Detail/components/Table/Header";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import Footer from "../../Advertising/Detail/components/Footer";
import {Link} from "react-router-dom";
import Checkbox from "../../../Components/Inputs/Checkbox";
import Protected from "../../../Components/Roles/Protected";
import useDelete from "../../../Components/Hooks/useDelete";
import ModalWindow from "../../../Components/Popup/ModalWindow";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import IconButtonWithLoader from "../../../Components/Buttons/IconButtonWithLoader";

const dateFormat = 'YYYY-MM-DD'
const filterDateFormat = 'YYYY-MM-DD'

const getContractorSumValues = (contractor) => {
  let sums = {}

  for (let platform of contractor.platforms) {
    for (let face of platform.data) {
      for (let propertyCode in face.properties) {
        const propertyValue = face.properties[propertyCode].value

        if (!sums[propertyCode]) {
          sums[propertyCode] = 0
        }

        sums[propertyCode] += propertyValue
      }
    }
  }

  return sums
}

const getPlatformSumValues = (platform) => {
  let sums = {}

  for (let face of platform.data) {
    for (let propertyCode in face.properties) {
      const propertyValue = face.properties[propertyCode].value

      if (!sums[propertyCode]) {
        sums[propertyCode] = 0
      }

      sums[propertyCode] += propertyValue
    }
  }

  return sums
}

const propertiesSortParams = {
  budget: {
    asc: 'asc',
    desc: 'desc',
    param: 'order[face.properties.budget]'
  }
}

function List(props) {
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const filters = {
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      name: 'channel',
      setFilterParams: (params, filterValue) => {
        setSelectedChannel(filterValue)
        return ''
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], filterDateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(filterDateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(filterDateFormat)
        const endDate = dateValue.endOf('month').format(filterDateFormat)
        params.append('date[after]', startDate)
        params.append('date[before]', endDate)
        setStartDate(startDate)
        setEndDate(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: filterDateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, filterValue) => {
        params.append('face.advertising.company.id', filterValue.value)
      },
      getValueToUrl: (params, company) => {
        params.set('company', company.value)
      },
      required: true,
    },
    legal: {
      type: 'select',
      placeholder: i18n.t('clients.page.legal'),
      getOptionsUrlFunction: apiUrls.get.contractorLegals,
      setFilterParams: (params, filterValue) => {
        if (filterValue && filterValue.value) {
          params.append('face.contractor.id', filterValue.value)
        }
      },
      getValueToUrl: (params, legal) => {
        if (legal && legal.value) {
          params.set('legal', legal.value)
        } else {
          params.delete('legal')
        }
      },
      name: 'legal',
      className: 'has-separator pointer',
      //isEmptyOption: true,
      required: true,
    },
  }

  const getDataUrl = () => {
    const channel = channels.find(channel => channel.id === selectedChannel.value)
    const channelEntity = ChannelFactory.create(channel.code)
    return channelEntity.apiUrls.totalFact
  }

  const getPropertiesUrl = () => {
    const channel = channels.find(channel => channel.id === selectedChannel.value)
    const channelEntity = ChannelFactory.create(channel.code)
    return channelEntity.apiUrls.properties
  }

  const getSaveFactUrl = () => {
    const channel = channels.find(channel => channel.id === selectedChannel.value)
    const channelEntity = ChannelFactory.create(channel.code)
    return channelEntity.apiUrls.totalFact
  }

  const getCheckUrl = () => {
    return checkId ? apiUrls.get.contractorCheck(checkId) : ''
  }

  const [dataFilterParams, setDataFilterParams] = useState('')
  const [properties, setProperties] = useState('')
  const [fact, setFact] = useState([])
  const [isFactSaving, setIsFactSaving] = useState(null)
  const [isCheckSaving, setIsCheckSaving] = useState(null)
  const [check, setCheck] = useState(null)
  const [checkId, setCheckId] = useState(null)
  const [isCheckPopupOpen, setIsCheckPopupOpen] = useState(false)
  const [contractorToCheck, setContractorToCheck] = useState(null)
  const [sortParams, setSortParams] = useState({})
  const [changedFaces, setChangedFaces] = useState([])
  const [facesToCheck, setFacesToCheck] = useState([])

  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels)
  const [{data: checkData}, getCheckData] = useApiFetch(getCheckUrl)
  const [{data: channelProperties}, getChannelProperties] = useApiFetch(getPropertiesUrl)
  const [{data: factData, isLoading: factDataLoading}, getFactData] = useApiFetch(getDataUrl)

  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(
    getSaveFactUrl,
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising',
    (params) => {
      toast.success(i18n.t('clients.advertising.correction_updated'));
      //getCorrections(params)
      getFactData(params)
      setChangedFaces([])
      setIsFactSaving(false)
    }
  )

  const getFactDataParams = () => {
    const params = {}
    for (let param of dataFilterParams) {
      params[param[0]] = param[1]
    }

    params['t[]'] = ['contractors_group', 'face_details']
    params['props[]'] = 'budget'

    if (Object.keys(sortParams).length) {
      for (let param in sortParams) {
        const sortOrder = sortParams[param]
        const paramToUrl = propertiesSortParams[param] ? propertiesSortParams[param].param : ''

        if (paramToUrl) {
          params[paramToUrl] = sortOrder
        }
      }
    }

    return params
  }

  const [{errors: saveCheckErrors}, saveCheck] = useApiPost(
    apiUrls.get.contractorChecks(),
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising',
    (params) => {
      toast.success(i18n.t('face_check.budget_checked'));
      setCheckId(params)
      setIsCheckSaving(false)
    }
  )

  const [{errors: updateCheckErrors}, updateCheck] = useApiPatch(
    apiUrls.get.contractorCheck,
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising',
    (params) => {
      toast.success(i18n.t('face_check.budget_updated'));
      //setCheckId(params)
      setIsCheckSaving(false)
    }
  )

  const deleteCheck = useDelete(apiUrls.delete.contractorCheck)

  const saveFactDataValues = async (intervals, callback = (() => {})) => {
    if (intervals && Object.values(intervals).length) {
      setIsFactSaving(true)

      await saveFactData({
        intervals
      }, true, getFactDataParams(), true, () => {
        if (typeof callback === 'function') {
          callback()
        }
      }, () => {
        setIsFactSaving(false)
      });
    } else {
      callback()
    }
  }

  const toggleSort = (name) => {
    if (!propertiesSortParams[name]) {
      return
    }

    const acsSort = propertiesSortParams[name].asc
    const descSort = propertiesSortParams[name].desc

    if (sortParams[name] === acsSort) {
      setSortParams({
        ...sortParams,
        [name]: descSort
      })
    } else if (sortParams[name] === descSort) {
      const params = cloneDeep(sortParams)
      delete params[name]
      setSortParams(params)
      delete sortParams[name]
    } else {
      setSortParams({
        ...sortParams,
        [name]: acsSort
      })
    }
  }

  const setFilterParams = (filterParams) => {
    setDataFilterParams(filterParams)
  }

  useEffect(() => {
    getChannels()
  }, [])

  useEffect(() => {
    if (factData) {
      const data = cloneDeep(factData)

      let checkIri = null
      const checkedFacesIds = []

      for (let interval of data) {
        for (let contractor of interval.contractors) {
          if (!contractor.sums) {
            contractor.sums = {}
          }

          for (let platform of contractor.platforms) {
            if (!platform.sums) {
              platform.sums = {}
            }

            for (let face of platform.data) {
              const tax = (face.item && face.item.contractor && face.item.contractor.tax)
                ? face.item.contractor.tax.tax
                : 0

              face.id = Number(face.item['@id'].split('/').pop())

              if (face.check && !checkIri) {
                checkIri = face.check
              }

              if (face.check) {
                checkedFacesIds.push(face.id)
              }

              const budget = face.properties.budget.value
              const budgetWithTax = budget * tax / 100

              face.properties.tax = {
                editable: false,
                value: budgetWithTax
              }

              face.properties.budget_with_tax = {
                editable: false,
                value: budget + budgetWithTax
              }

              for (let propertyCode in face.properties) {
                const propertyValue = face.properties[propertyCode].value

                if (!platform.sums[propertyCode]) {
                  platform.sums[propertyCode] = 0
                }
                platform.sums[propertyCode] += propertyValue

                if (!contractor.sums[propertyCode]) {
                  contractor.sums[propertyCode] = 0
                }
                contractor.sums[propertyCode] += propertyValue
              }
            }
          }

          setContractorToCheck(contractor)
        }
      }

      if (checkIri) {
        setCheckId(checkIri.split('/').pop())
      } else {
        setCheckId(null)
      }

      setFact(data)
      setFacesToCheck(checkedFacesIds)
    }
  }, [factData])

  useEffect(() => {
    if (checkId) {
      getCheckData()
    } else {
      setCheck(null)
    }
  }, [checkId])

  useEffect(() => {
    if (checkData) {
      setCheck(checkData)
    }
  }, [checkData])

  useEffect(() => {
    if (dataFilterParams && selectedChannel) {
      getFactData(getFactDataParams())
    }

    if (selectedChannel) {
      getChannelProperties()
    }
  }, [selectedChannel, dataFilterParams, sortParams])

  useEffect(() => {
    if (channelProperties) {
      const budgetProperties = channelProperties.filter(property => property.code === 'budget')
      budgetProperties.push({
        name: i18n.t('contractors.tax'),
        code: 'tax',
        type: 'float'
      })

      budgetProperties.push({
        name: i18n.t('contractors.budget_with_tax'),
        code: 'budget_with_tax',
        type: 'float'
      })
      setProperties(budgetProperties)
    }
  }, [channelProperties])

  const saveAllFactData = (callback = (() => {})) => {
    if (fact && fact[0] && fact[0].contractors) {
      const faceIntervals = {}

      for (let contractor of fact[0].contractors) {
        for (let platform of contractor.platforms) {
          for (let face of platform.data) {
            if (!face.item.advertising) {
              continue
            }

            const faceId = face.id

            if (!changedFaces.includes(faceId)) {
              continue
            }

            const [intervalStart, intervalEnd]
              = getAdvertisingDates(face.item.advertising.dateStart, face.item.advertising.dateEnd)

            const intervalStartDate = getFormattedDate(intervalStart, dateFormat)
            const intervalEndDate = getFormattedDate(intervalEnd, dateFormat)

            const intervalKey = intervalStartDate + intervalEndDate

            if (!faceIntervals[intervalKey]) {
              faceIntervals[intervalKey] = {
                dateStart: intervalStartDate,
                dateEnd: intervalEndDate,
                data: []
              }
            }

            faceIntervals[intervalKey].data.push({
              item: face.item['@id'],
              properties: {
                budget: face.properties.budget.value
              }
            })
          }
        }
      }

      saveFactDataValues(Object.values(faceIntervals), callback)
    }
  }

  const onSaveFaceFactData = async (face) => {
    if (fact && fact[0] && face && face.item && face.item.advertising && face.properties) {
      const [intervalStartDate, intervalEndDate]
        = getAdvertisingDates(face.item.advertising.dateStart, face.item.advertising.dateEnd)

      const intervals = [
        {
          dateStart: getFormattedDate(intervalStartDate, dateFormat),
          dateEnd: getFormattedDate(intervalEndDate, dateFormat),
          data: [
            {
              item: face.item['@id'],
              properties: {
                budget: face.properties.budget.value
              }
            }
          ]
        }
      ]

      saveFactDataValues(intervals)
    }
  }

  const onRowChange = async (value, property, periodIndex, contractorIndex, platformIndex, faceIndex) => {
    const formFact = cloneDeep(fact)

    if (formFact && formFact[periodIndex] && formFact[periodIndex].contractors
      && formFact[periodIndex].contractors[contractorIndex] && formFact[periodIndex].contractors[contractorIndex].platforms
      && formFact[periodIndex].contractors[contractorIndex].platforms[platformIndex]
      && formFact[periodIndex].contractors[contractorIndex].platforms[platformIndex].data
      && formFact[periodIndex].contractors[contractorIndex].platforms[platformIndex].data[faceIndex]
    ) {
      const face = formFact[periodIndex].contractors[contractorIndex].platforms[platformIndex].data[faceIndex]

      if (!changedFaces.includes(face.id)) {
        setChangedFaces(values => (
          [
            ...values,
            face.id
          ]
        ))
      }

      if (face && face.properties && face.properties[property.code]) {
        face.properties[property.code].value = value

        const tax = (face.item && face.item.contractor && face.item.contractor.tax)
          ? face.item.contractor.tax.tax
          : 0

        const budget = face.properties.budget.value
        const budgetWithTax = budget * tax / 100

        face.properties.tax.value = budgetWithTax
        face.properties.budget_with_tax.value = budget + budgetWithTax
      }
    }

    await setFact(formFact)
  }

  const getAdvertisingDates = (dateStart, dateEnd) => {
    const periodStartDate = getUtcDate(startDate).toDate()
    const periodEndDate = getUtcDate(endDate).toDate()

    const advertisingStartDate = getUtcDate(dateStart).toDate()
    const advertisingEndDate = getUtcDate(dateEnd).toDate()

    const start = advertisingStartDate > periodStartDate
      ? advertisingStartDate
      : periodStartDate

    const end = advertisingEndDate < periodEndDate
      ? advertisingEndDate
      : periodEndDate

    return [start, end];
  }

  const getFactDataPageUrl = face => {
    const channelId = selectedChannel.value
    const channel = channels.find(channel => channel.id === channelId)

    const [advertisingStartDate, advertisingEndDate] =
      getAdvertisingDates(face.item.advertising.dateStart, face.item.advertising.dateEnd)

    if (channel) {
      return `/advertisings/${
        face.item.advertising.id}/fact/${channel.code}?dateStart=${
        getFormattedDate(advertisingStartDate, dateFormat)}&dateEnd=${
        getFormattedDate(advertisingEndDate, dateFormat)}`
    }
  }

  const getParamsToSaveCheck = contractor => {
    const params = {
      contractor: contractor.contractor['@id'],
      comment: ( check && check.comment ? check.comment : '' ),
      data: []
    }

    for (let platform of contractor.platforms) {
      for (let face of platform.data) {
        if (!facesToCheck.includes(face.id)) {
          continue
        }

        params.data.push({
          face: face.item['@id'],
          dateStart: startDate,
          dateEnd: endDate
        })
      }
    }

    return params
  }

  const onCheckUpdate = async contractor => {
    if (!fact || !fact[0]) {
      return
    }

    if (!facesToCheck.length) {
      onCheckDelete()
    } else {
      setIsCheckSaving(true)

      saveAllFactData(() => {
        const params = getParamsToSaveCheck(contractor)

        updateCheck(check.id, params, {}, () => {
          getFactData(getFactDataParams())
        })
      })
    }
  }

  const onCheckAdd = async contractor => {
    if (!fact || !fact[0]) {
      return
    }

    setIsCheckSaving(true)

    saveAllFactData(() => {
      const params = getParamsToSaveCheck(contractor)

      saveCheck(params, null, {}, true, () => {
        setIsCheckPopupOpen(false)
        getFactData(getFactDataParams())
      })
    })
  }

  const onCheckDelete = () => {
    if (check && check.id) {
      const isConfirm = window.confirm(i18n.t('face_check.delete_check'))

      if (isConfirm) {
        deleteCheck(check.id, () => {
          toast.success(i18n.t('face_check.check_deleted'))
          setCheck(null)
          setCheckId(null)
          getFactData(getFactDataParams())
        })
      }
    }
  }

  const onCheckCommentChange = value => {
    setCheck(prevValue => ({
      ...prevValue,
      comment: value
    }))
  }

  const isAnyLoading = () => isFactSaving || factDataLoading || isCheckSaving

  return (
    <div className="container-fluid padding-side-15 mediaplan-page faces-total">
      <div className="row">
        <TopMenu title={i18next.t('header.total_faces')} />

        <div className={'loader' + (isAnyLoading() ? '' : ' hidden')}>
          <i className="fa fa-refresh fa-spin" />
        </div>
        <ErrorBoundary
          errorText={i18n.t('mediaplans.page.errors.advert_show_error')}
          className={'boundary-error mediaplan-error'}
        >
          <Filter
            filters={Object.values(filters)}
            getData={setFilterParams}
          />

          {fact && properties && selectedChannel &&
          <>
            <div className={'mediaplan-tables'}>
              <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
                <table className="table redesign-table table-client goal-detail mediaplans">
                  <Header
                    properties={properties}
                    cellsBeforeProps={
                      <>
                        <td>{i18n.t('clients.advertising.platform')}</td>
                        <td>{i18n.t('clients.advertising.advertising')}</td>
                      </>
                    }
                    cellsAfterProps={<td />}
                    sortParams={sortParams}
                    propertiesSortParams={propertiesSortParams}
                    setSortParams={toggleSort}
                  />
                  <tbody>
                  {fact.map((periodItem, periodIndex) => (
                    <Fragment key={periodItem.dateStart + periodItem.dateEnd}>
                      {periodItem.contractors &&
                      <>
                        {periodItem.contractors.map((contractor, contractorIndex) => (
                          <Fragment key={contractorIndex}>
                            <SumRow
                              properties={properties}
                              values={getContractorSumValues(contractor)}
                              title={i18n.t('clients.advertising.contractor_sum') + ' ' + contractor.contractor.name}
                              titleColSpan={1}
                              cellsAfterTitle={<td />}
                              cellsAfterProps={<td>
                                <Protected access={'FinancialFacesCheck'}>
                                  <div className='check-buttons'>
                                    <Checkbox
                                      checked={!!check && check.id}
                                      onChange={value => {
                                        if (value) {
                                          if (facesToCheck.length) {
                                            setContractorToCheck(contractor)
                                            setIsCheckPopupOpen(true)
                                          } else {
                                            toast.error(i18n.t('face_check.empty_faces'))
                                          }
                                        } else {
                                          onCheckDelete()
                                        }
                                      }}
                                      title={(check && check.comment) ? check.comment : i18n.t('face_check.checkbox_set_title')}
                                    />
                                    {check && check.id &&
                                      <IconButtonWithLoader
                                        onClick={() => onCheckUpdate(contractorToCheck)}
                                        title={i18n.t('face_check.check_save_changes')}
                                        icon={<i className='far fa-save' />}
                                        isLoading={isCheckSaving}
                                      />
                                    }
                                  </div>

                                </Protected>
                              </td>}
                            />

                            {contractor.platforms.length > 0 && contractor.platforms.map((platform, platformIndex) => (
                              <Fragment key={platformIndex}>
                                <SumRow
                                  properties={properties}
                                  values={getPlatformSumValues(platform)}
                                  title={i18n.t('clients.advertising.platform_sum') + ' ' + platform.platform.name}
                                  titleColSpan={1}
                                  cellsAfterTitle={<td />}
                                  cellsAfterProps={<td />}
                                />

                              {platform.data.length > 0 && platform.data.map((face, faceIndex) => (
                                <Fragment key={face.item['@id']}>
                                  <Row
                                    properties={properties}
                                    values={face.properties || {}}
                                    titleColSpan={1}
                                    index={periodIndex}
                                    faceIindex={faceIndex}
                                    onChange={(value, property) => onRowChange(value, property, periodIndex, contractorIndex, platformIndex, faceIndex)}
                                    key={periodItem.dateStart + periodItem.dateEnd}
                                    title={
                                      <span>{face.item.name}</span>
                                    }
                                    isSelectToPaste={false}
                                    rowCellComponent={FacesRowCell}
                                    cellAfterTitle={
                                      <td>
                                        {face.item.advertising ?
                                          <Link to={getFactDataPageUrl(face)}>
                                            {face.item.advertising.name}
                                          </Link> : ''}
                                      </td>
                                    }
                                    cellAfterProperties={
                                      <td>
                                        <div className='row-check-buttons'>
                                          <Checkbox
                                            checked={check && check.id && facesToCheck.includes(face.id)}
                                            onChange={value => {
                                              if (!facesToCheck.includes(face.id)) {
                                                setFacesToCheck(values => (
                                                  [
                                                    ...values,
                                                    face.id
                                                  ]
                                                ))
                                              } else {
                                                const newValues = facesToCheck.filter(id => id !== face.id)
                                                setFacesToCheck(newValues)
                                              }
                                            }}
                                            title={i18n.t('face_check.checkbox_set_title')}
                                          />
                                          {(!face.check) &&
                                            <>
                                              <div className={'correction-button'}
                                                   onClick={() => onSaveFaceFactData(face)}
                                                   title={i18n.t('clients.advertising.edit_correction')}>
                                                <i className={'far fa-save'} />
                                              </div>
                                            </>
                                          }
                                        </div>
                                      </td>
                                    }
                                  />
                                </Fragment>
                              ))}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </>
                      }
                    </Fragment>
                  ))}

                  </tbody>
                </table>
                {isAnyLoading() &&
                <LoaderBackdrop />
                }
              </div>
            </div>
            <Footer>
              <div />
              <div>
                {fact && changedFaces && changedFaces.length > 0 &&
                <ButtonWithLoader
                  onClick={() => saveAllFactData()}
                  className={"button " + (isFactSaving ? 'load' : '')}
                  text={i18n.t('clients.advertising.save_all')}
                  loadingText={i18n.t('clients.advertising.saving_values')}
                  isLoading={isFactSaving}
                />
                }
              </div>
            </Footer>

            <ModalWindow
              open={isCheckPopupOpen}
              close={() => {
                setIsCheckPopupOpen(false)
                setCheck(null)
                setContractorToCheck(null)
              }}
              className={'popup-face-check'}
            >
              <div className={'row-item'}>
                <div className={'row-item__title'}>
                  {i18n.t('face_check.comment')}
                </div>
                <div className={'row-item__body'}>
                  <textarea rows={'5'}
                    onChange={event => onCheckCommentChange(event.target.value)}
                    value={!!(check && check.comment) ? check.comment : ''}
                  />
                </div>
              </div>

              <div className={'row-item-buttons'}>
                <ButtonWithLoader
                  onClick={() => onCheckAdd(contractorToCheck)}
                  className={"button " + (isCheckSaving ? 'load' : '')}
                  text={i18n.t('face_check.check_popup_save')}
                  loadingText={i18n.t('clients.advertising.saving_values')}
                  isLoading={isCheckSaving}
                />
              </div>
            </ModalWindow>
          </>
          }
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default List
