import React, { useMemo} from 'react'
import i18next from 'i18next'
import moment from "moment";
import FormattedNumber from "../../../../Components/FormattedNumber";
import i18n from "../../../../i18n";
import Roles from "../../../../roles/Roles";
import {Link} from "react-router-dom";
import SortIcon from "../../../../Components/SortIcon";

export default function Table({facesMap, filteredFaces, sort, onSortClick = () => {}}) {
  const showFace = faceKey => {
    if (!filteredFaces.length) {
      return true
    }

    return filteredFaces.includes(faceKey)
  }

  const sumValues = useMemo(() => {
    const sums = {
      planBudget: 0,
      clientBudget: 0,
      factBudget: 0,
      commissionValue: 0,
      marginality: 0,
    }

    facesMap.forEach((face, key) => {
      if (showFace(key)) {
        if (face.planBudget !== undefined) {
          sums.planBudget += face.planBudget
        }

        if (face.clientBudget !== undefined) {
          sums.clientBudget += face.clientBudget
        }

        if (face.factBudget !== undefined) {
          sums.factBudget += face.factBudget
        }

        if (face.commissionValue !== undefined) {
          sums.commissionValue += face.commissionValue
        }

        if (face.marginality !== undefined && face.marginality !== '') {
          sums.marginality += face.marginality
        }
      }
    })

    sums.marginalityPercent = (sums.marginality / (sums.clientBudget || 1)) * 100

    return sums
  }, [facesMap, filteredFaces])

  return (
    <table className="mediaplans redesign-table financial-report">
      {Roles.hasAccess('FinancialReportRead') &&
        <>
          <thead>
          <tr>
            <th>{i18next.t('clients.edit.month')}</th>
            <th>{i18next.t('clients.page.pool')}</th>
            <th>
              <div className={'sort ' + (sort['company'] || '')}
                   onClick={() => onSortClick('company')}
              >
                <span>{i18next.t('clients.page.company')}</span>
                <SortIcon
                  name={'company'}
                  sort={sort}
                />
              </div>
            </th>
            <th>
              <div className={'sort ' + (sort['product'] || '')}
                   onClick={() => onSortClick('product')}
              >
                <span>{i18next.t('clients.advertising.product')}</span>
                <SortIcon
                  name={'product'}
                  sort={sort}
                />
              </div>
            </th>
            <th>
              <div className={'sort ' + (sort['manager'] || '')}
                   onClick={() => onSortClick('manager')}
              >
                <span>{i18next.t('clients.advertising.manager')}</span>
                <SortIcon
                  name={'manager'}
                  sort={sort}
                />
              </div>
            </th>
            <th>{i18next.t('clients.advertising.type')}</th>
            <th>
              <div className={'sort ' + (sort['advertising'] || '')}
                   onClick={() => onSortClick('advertising')}
              >
                <span>{i18next.t('clients.page.advertising')}</span>
                <SortIcon
                  name={'advertising'}
                  sort={sort}
                />
              </div>
            </th>
            <th>{i18next.t('commissions.list.face')}</th>
            <th>
              <div className={'sort ' + (sort['platform'] || '')}
                   onClick={() => onSortClick('platform')}
              >
                <span>{i18next.t('commissions.list.platform')}</span>
                <SortIcon
                  name={'platform'}
                  sort={sort}
                />
              </div>
            </th>
            <th>{i18next.t('clients.advertising.contractor')}</th>
            <th>
              <div className={'sort ' + (sort['planBudget'] || '')}
                   onClick={() => onSortClick('planBudget')}
              >
                <span>{i18next.t('clients.edit.budget_plan')}</span>
                <SortIcon
                  name={'planBudget'}
                  sort={sort}
                />
              </div>
            </th>
            <th>
              <div className={'sort ' + (sort['clientBudget'] || '')}
                   onClick={() => onSortClick('clientBudget')}
              >
                <span>{i18next.t('financiers.clientBudget')}</span>
                <SortIcon
                  name={'clientBudget'}
                  sort={sort}
                />
              </div>
            </th>
            {Roles.hasAccess('FinancialReportAdditionalRead') &&
              <>
                <th>
                  <div className={'sort ' + (sort['factBudget'] || '')}
                       onClick={() => onSortClick('factBudget')}
                  >
                    <span>{i18next.t('financiers.budget')}</span>
                    <SortIcon
                      name={'factBudget'}
                      sort={sort}
                    />
                  </div>
                </th>
                <th>{i18next.t('commissions.edit.ratio')}</th>
                <th>{i18next.t('commissions.edit.value')}</th>
                <th>{i18next.t('commissions.edit.commission_type')}</th>
                <th>{i18next.t('face.list.marginality_sm')}</th>
                <th>{i18next.t('face.list.marginality_percent')}</th>
              </>
            }
          </tr>
          {sumValues &&
            <tr>
              <td>{i18n.t('face_info.total')}</td>
              <td colSpan={9} />
              <td><FormattedNumber value={sumValues.planBudget} type={'float'} /></td>
              <td><FormattedNumber value={sumValues.clientBudget} type={'float'} /></td>
              {Roles.hasAccess('FinancialReportAdditionalRead') &&
                <>
                  <td><FormattedNumber value={sumValues.factBudget} type={'float'} /></td>
                  <td />
                  <td><FormattedNumber value={sumValues.commissionValue} type={'float'} /></td>
                  <td />
                  <td><FormattedNumber value={sumValues.marginality} type={'float'} /></td>
                  <td><FormattedNumber value={sumValues.marginalityPercent} type={'float'} /></td>
                </>
              }
            </tr>
          }
          </thead>
          <tbody>
          {facesMap && [...facesMap].map(([key, face]) => {
            if (showFace(key)) {
              return (<tr key={key}>
                <td>{moment.utc(face.dateStart).format('MM.Y')}</td>
                <td>{face.pool || ''}</td>
                <td>{face.company}</td>
                <td>{face.product || ''}</td>
                <td>{face.manager || ''}</td>
                <td>{face.advertisingType || ''}</td>
                <td>
                  {face.advertising &&
                    <Link to={face.advertisingPageLink} >{face.advertising || ''}</Link>
                  }
                </td>
                <td>
                  {face.code &&
                    <>
                      {face.factDetailPageLink
                        ?
                        <Link to={face.factDetailPageLink} >{face.code || ''}</Link>
                        : face.code || ''
                      }
                    </>

                  }
                </td>
                <td>{face.platform || ''}</td>
                <td>{face.contractor || ''}</td>
                <td>{('planBudget' in face) && <FormattedNumber value={face.planBudget} type={'float'}/>}</td>
                <td>{('clientBudget' in face) && <FormattedNumber value={face.clientBudget} type={'float'}/>}</td>
                {Roles.hasAccess('FinancialReportAdditionalRead') &&
                  <>
                    <td>{('factBudget' in face) && <FormattedNumber value={face.factBudget} type={'float'}/>}</td>
                    <td>{('commissionRatio' in face) &&
                      <FormattedNumber value={face.commissionRatio} type={'float'}/>}</td>
                    <td>
                      {('commissionValue' in face) && <FormattedNumber value={face.commissionValue} type={'float'} />}
                    </td>
                    <td>{face.commissionType || ''}</td>
                    <td>{(face.marginality !== '') &&
                      <FormattedNumber value={face.marginality} type={'float'}/>}</td>
                    <td>{(face.marginalityPercent !== '') &&
                      <FormattedNumber value={face.marginalityPercent} type={'float'}/>}</td>
                  </>
                }
              </tr>)
            }
          })}
          </tbody>
        </>
      }
    </table>
  )
}