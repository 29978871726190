import i18n from "i18next";
import XHR from "i18next-xhr-backend";

import translationRu from "./locales/ru/translation.json";
import translationEn from "./locales/en/translation.json";

i18n
  .use(XHR)
  .init({
    debug: true,
    lng: process.env.REACT_APP_LANG,
    fallbackLng: "en",

    keySeparator: '.',

    interpolation: {
      escapeValue: false
    },

    resources: {
      en: {
        translations: translationEn
      },
      ru: {
        translations: translationRu
      }
    },

    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;