import React, {useMemo} from 'react';
import i18n from "i18next";

function AdvertisingMatchingErrors({title, errors}) {
  const sortedErrors = useMemo(() => {
    const data = Array.from(errors)
    data.sort(function(a, b) {
      return a[1].length - b[1].length
    })

    return data
  }, [errors])

  return (
    <div className={'advertising-matching-errors'}>
      <div className={'advertising-matching-errors__title'}>{title}</div>
      <div className={'advertising-matching-errors__list'}>
        {sortedErrors.map(([column, values]) => (
          <div className={'advertising-matching-errors__column'} key={column}>
            <div className={'advertising-matching-errors__column-title'}>{column}</div>
            <div className={'advertising-matching-errors__column-row advertising-matching-errors__column-row-header'}>
                <div className={'advertising-matching-errors__column-value'}>{i18n.t('financial.value')}</div>
                <div className={'advertising-matching-errors__column-number'}>{i18n.t('financial.number')}</div>
            </div>
              {values.map(value => (
                <div className={'advertising-matching-errors__column-row'} key={value.name}>
                  <div className={'advertising-matching-errors__column-value'}>{value.name}</div>
                  <div className={'advertising-matching-errors__column-number'}>{value.rows.join(', ')}</div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdvertisingMatchingErrors
