import React, {useState} from 'react';

export default function AccordionBlock({title, children}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={'form-accordion'}>
      <div className={'form-accordion__wrapper'}>
        <div className={'form-accordion__header'}>
          <div className={'form-accordion__title'}>{title}</div>
          <div className={'form-accordion__toggle'} onClick={() => setIsOpen(!isOpen)}>
            <span><i className={'fas ' + (isOpen ? 'fa-chevron-up' : 'fa-chevron-down')}/></span></div>
        </div>
        <div className={'form-accordion__body'}>
          {isOpen &&
            children
          }
        </div>
      </div>
    </div>
  )
}