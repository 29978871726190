import React from 'react';
import i18n from "i18next";
import {toast} from "react-toastify";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";

function DigitalTable(props) {
  return (
    <table className="table redesign-table table-client goal-detail mediaplans">
      <thead>
      <tr>
        <th>{i18n.t('periods.face.code')}</th>
        <th>{i18n.t('periods.face.platform')}</th>
        <th>{i18n.t('periods.face.pool')}</th>
        <th>{i18n.t('periods.face.format')}</th>
        <th>{i18n.t('periods.face.strategy')}</th>
        <th>{i18n.t('periods.face.subtype')}</th>
        <th>{i18n.t('periods.face.unit')}</th>
        <th>{i18n.t('periods.face.unitCost')}</th>
        <th>{i18n.t('periods.face.contractor')}</th>
        <th>{i18n.t('clients.advertising.digital_channel')}</th>
        <th>{i18n.t('clients.advertising.digital_department')}</th>
        <th>{i18n.t('clients.advertising.digital_media')}</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {props.faces && props.faces.length && props.faces.map(face => (
        <tr key={face.id}>
          <td>{face.code}</td>
          <td>{face.platform.name}</td>
          <td>{face.platform.pool && face.platform.pool.name ? face.platform.pool.name : ''}</td>
          <td>
            {face.formats.map(format => (
              <div key={format['@id']}>{format.name}</div>
            ))}
          </td>
          <td>{face.strategy ? face.strategy.name : ''}</td>
          <td>{face.type ? face.type.name : ''}</td>
          <td>{(face.faceUnits && face.faceUnits[0] && face.faceUnits[0].unit) ? face.faceUnits[0].unit.name : ''}</td>
          <td className={'money'}>{(face.faceUnits && face.faceUnits[0] && (face.faceUnits[0].unitCost !== undefined)) ? face.faceUnits[0].unitCost : '-'}</td>
          <td>{face.contractor ? face.contractor.name : ''}</td>
          <td>{face.channel ? face.channel.name : ''}</td>
          <td>{face.department ? face.department.name : ''}</td>
          <td>{face.media ? face.media.name : ''}</td>
          <td>
            <div className="delete-face-buttons">
              <span
                onClick={() => props.setSelectedFace(face)}
                title={i18n.t('periods.face.edit')}
              ><i className="fa fa-edit" /></span>

              <span
                onClick={() => props.setSelectedFaceForInfo(face)}
                title={i18n.t('periods.face.edit')}
              ><i className="fal fa-info-circle" /></span>

              <DeleteWithConfirmButton
                onClick={() => {
                  props.deleteFace(face.id, () => {
                    props.getFaces()
                    toast.success(i18n.t('periods.face.deleted'))
                  })
                }}
                title={i18n.t('periods.face.delete')}
              />

              <span
                onClick={() => props.onCopyClick(face)}
                title={i18n.t('periods.face.copy')}
              ><i className="fa fa-copy" /></span>
            </div>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default DigitalTable;