import React from 'react';
import FloatInput from "../../../Components/Inputs/FloatInput";
import Checkbox from "../../../Components/Inputs/Checkbox";
import Input from "../../../Components/Inputs/Input";
import Select from "react-select";

function RowCell({
                   value,
                   onChange,
                   onSave,
                   id,
                   options,
                   editable,
                   className = '',
}) {
  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    value,
    onKeyDown: handleKeyDown,
  }

  return (
    <div className={'detail-cell'}>
      <>
        {typeof value === 'number' &&
          <FloatInput
            className={className}
            onChange={(value) => onChange(value, id)}
            {...inputProps}
            allowNegative={true}
            disabled={!editable}
          />
        }

        {typeof value === 'object' &&
          <Select
            className={'select'}
            value={value}
            onChange={(value) => onChange(value.value, id)}
            allowNegative={true}
            options={options}
          />
        }

        {/*{property.type === intType &&
              <IntInput
                onChange={(value) => onChange(value, property)}
                {...inputProps}
              />
            }*/}

        {typeof value === 'boolean' &&
          <Checkbox
            onChange={(value) => {
              onChange(value, id)
            }}
            {...inputProps}
            checked={value}
            disabled={!editable}
          />
        }

        {typeof value === 'string' &&
          <>
            {editable &&
              <Input
                className={className}
                onChange={(value) => onChange(value, id)}
                {...inputProps}
              />
            }

            {!editable &&
              <span className={'text-non-editable'}>{value}</span>
            }
          </>
        }
      </>
    </div>
  )
}

export default RowCell
