import React from 'react';
import i18n from "i18next";
import Input from "../../../../Components/Inputs/Input";

const noop = () => {}

function PasteForm({value, paste = noop, setTextToPasteTable = noop, setSelectedDetailToPasteTable = noop, setSelectedFieldToPasteTable = noop}) {

  const setEmptySelected = () => {
    setSelectedDetailToPasteTable(null)
    setSelectedFieldToPasteTable(null)
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
           onClick={() => setEmptySelected()}/>
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => setEmptySelected()}>
            <i className='fas fa-remove'/>
          </button>
        </div>
        <div className='popup-form__body'>
          <div className='popup-form__rows'>
            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('periods.edit.copied')}
              </div>
              <textarea
                rows={20}
                className={'paste-textarea'}
                value={value || ''}
                onChange={(event) => setTextToPasteTable(event.target.value)}></textarea>
            </div>
          </div>
          <div className={'popup-form__buttons'}>
            <button className="popup-form__button button-accent" onClick={() => paste()}>
              {i18n.t('periods.edit.paste')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasteForm