import React, {useEffect, useState} from 'react'
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import DropdownInput from "./DropdownInput";
import RadioInput from "./RadioInput";
import {isRequiredFilterValue, logError} from "../../utils";
import DateWithRangeInput from "./DateWithRangeInput";
import DateRangeInput from "./DateRangeInput";
import {cloneDeep, isEqual, isEmpty} from "lodash";

function Filter(props) {
  const [isNotSetParams, setIsNotSetParams] = useState(false)
  const [paramsFromUrl, setParamsFromUrl] = useState({})

  const setFilterValue = function (key, value, callback) {
    props.setPage(1)

    const filters = {}

    if (!key && typeof value === 'object') {
      for (let field in value) {
        filters[field] = value[field]
      }
    } else {
      filters[key] = value
    }

    props.setFilter({
      ...props.filter,
      ...filters,
    }, callback)
  }

  useEffect(() => {
    if (isNotSetParams && !isEmpty(paramsFromUrl)) {
      setTimeout(() => {
        setFilters()
      }, 1000)
    }
  }, [isNotSetParams, paramsFromUrl])

  useEffect(() => {
    if (props.isFetchDataCompleted === undefined || props.isFetchDataCompleted) {
      setFilters()
    }
  }, [props.location.search, props.isFetchDataCompleted])

  const getFilter = () => {
    return cloneDeep(props.filter)
  }

  const getFilters = () => {
    return props.filters
  }

  const setFilters = async (reset = false) => {
    try {
      let filter = getFilter()
      let filterOptions = getFilters()

      if (reset) {
        for (let filterName in filter) {
          if (filter.hasOwnProperty(filterName)) {
            filter[filterName] = ''
          }
        }
      }

      const searchParams = new URLSearchParams(props.location.search)
      let page = 1
      let sort = {}

      let isDisabledEmptyFilter = false
      
      let searchParamsValues = {}
      
      for (let [filterName, value] of searchParams.entries()) {
        if (filterName.includes('[]')) {
          filterName = filterName.replace('[]', '')


          if (!(filterName in searchParamsValues)) {
            searchParamsValues[filterName] = []
          }

          searchParamsValues[filterName].push(value)
        } else {
          searchParamsValues[filterName] = value
        }
      }

      if (!isEmpty(paramsFromUrl)) {
        for (let filterName in paramsFromUrl) {
          searchParamsValues[filterName] = paramsFromUrl[filterName]
        }
      }

      for (let filterName in searchParamsValues) {
        const value = searchParamsValues[filterName]

        if (filter.hasOwnProperty(filterName)) {
          const filterOption = filterOptions.find(
            option => Array.isArray(option.name)
              ? option.name.includes(filterName)
              : (option.name === filterName)
          )

          if (filterOption) {
            if (filterOption.type === 'dropdown') {
              if (filterOption.hasOwnProperty('isDisabled') && filterOption.options.length <= 1
                && isEmpty(filter[filterName])) {
                isDisabledEmptyFilter = true
                setIsNotSetParams(true)

                if (!paramsFromUrl[filterName]) {
                  setParamsFromUrl({
                    ...paramsFromUrl,
                    [filterName]: value
                  })
                }
              } else {
                setIsNotSetParams(false)
              }
              
              for (let option of filterOption.options) {
                if (option.hasOwnProperty('value') && (option.value == value)) {
                  const filterValue = {
                    label: option.label,
                    value,
                  }

                  if (filterOption.isMulti) {
                    const isItemInFilter = filter[filterName] ? filter[filterName].find(elem => (elem.value == value)) : false

                    if (!isItemInFilter) {
                      filter[filterName].push(filterValue)
                    }
                  } else {
                    filter[filterName] = filterValue
                  }
                }
              }
            } else if (filterOption.type === 'date_range') {
              if (filterOption.hasOwnProperty(filterName)) {
                filter[filterName] = value
              }
            } else {
              filter[filterName] = value
            }

            if (!isEmpty(filter[filterName]) && paramsFromUrl[filterName]) {
              const params = cloneDeep(paramsFromUrl)
              delete params[filterName]
              setParamsFromUrl(params)
            }

            if (filterOption && typeof filterOption.callback === 'function') {
              if (filterOption.type === 'dropdown') {
                filterOption.callback(filter[filterName].value)
              } else {
                filterOption.callback(filter[filterName])
              }
            }
          } else if (filter.hasOwnProperty(filterName)) {
            filter[filterName] = value
          }
        }

        if (filterName === 'page') {
          page = value || 1
        }

        if (filterName.includes('order')) {
          let sortName = filterName.split('[').pop().split(']').shift()

          if (sortName && value) {
            sort[sortName] = value
          }
        }
      }

      if (!isEqual(filter, props.filter)) {
        if (isDisabledEmptyFilter) {
          props.setFilter(filter, null, null, page, sort,  false)
        } else {
          props.setFilter(filter, null, null, page, sort)
        }
      }

      if (Object.keys(sort)) {
        await props.setSort(sort)
      }

      if (page) {
        await props.setPage(page)
      }

      let isEmptyRequiredFields = false

      for (let filterName in filter) {
        let options = ''

        for (let option of filterOptions) {
          if ((Array.isArray(option.name) && (option.name.includes(filterName))) || (option.name == filterName)) {
            options = option
          }
        }

        if (options && options.required && !isRequiredFilterValue(filter, filterName)) {
          isEmptyRequiredFields = true
        }

        if (reset) {
          filter[filterName] = ''
        }
      }

      if (!isEmptyRequiredFields) {
        props.getData(page, sort, filter)

      } else {
        props.setEmptyData()
      }

    } catch (e) {

    }
  }

  const renderFilter = (filter, index) => {
    switch (filter.type) {
      case 'text':
        return <TextInput filter={filter} key={index}
          onChange={(value) => setFilterValue(filter.name, value, filter.callback)} />
      case 'date':
        return <DateInput filter={filter} key={index}
          onChange={(name, value, callback) => setFilterValue(name, value, callback)} />
      case 'date_with_range':
        return <DateWithRangeInput filter={filter} key={index}
          onChange={(name, value, callback) => setFilterValue(name, value, callback)} />
      case 'date_range':
        return <DateRangeInput filter={filter} key={index}
          onChange={(name, value, callback) => setFilterValue(name, value, callback)} />
      case 'dropdown':
        return <DropdownInput filter={filter} key={index}
          onChange={(value) => setFilterValue(filter.name, value, filter.callback)} />
      case 'radio':
        return <RadioInput filter={filter} key={index}
          onChange={(value) => setFilterValue(filter.name, value, filter.callback)} />
      default:
        return ''
    }
  }

  return (
    <div className="menu-filter">
      {props.filters && props.filters.map((filter, index) => renderFilter(filter, index))}
    </div>
  )
}

export default Filter