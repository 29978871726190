import React, {Component} from 'react'
import {isFloatNumbersEqual, renderSortIcon} from "../../utils"
import {Link} from "react-router-dom"
import i18next from 'i18next'
import {getFormattedDate} from "./Detail/Fact/Utils";
import Protected from "../../Components/Roles/Protected";
import FormattedNumber from "../../Components/FormattedNumber";
import {InternetChannel} from "../../entities/channel/InternetChannel";
import {sendPlanValues} from "./Utils";

class AdvertTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribedAdvertsId: []
    }
  }

  isInternet = advertising => {
    return advertising.targets.some(target => target.channel && target.channel.code
      && target.channel.code === InternetChannel.getCode())
  }

  render() {
    return (
      <table className="mediaplans redesign-table regional-client">
        <thead>
        <tr>
          <th>
            <div className={'sort ' + (this.props.sort['id'] || '')}
              onClick={() => this.props.onSortClick('id')}
            >
              <span>{i18next.t('clients.advertising.advert_id')}</span>
              {renderSortIcon('id', this.props.sort, this.props.ascSortOrder, this.props.descSortOrder)}
            </div>
          </th>
          <th>{i18next.t('clients.edit.client')}</th>
          <th>{i18next.t('clients.advertising.channel')}</th>
          <th>{i18next.t('clients.page.product')}</th>
          <th>{i18next.t('clients.advertising.type')}</th>
          <th>{i18next.t('clients.edit.name')}</th>
          <th>{i18next.t('clients.edit.budget')}</th>
          <th>{i18next.t('clients.edit.faces_budget_sum')}</th>
          <th>
            <div className={'sort ' + (this.props.sort['dateStart'] || '')}
              onClick={() => this.props.onSortClick('dateStart')}
            >
              <span>{i18next.t('clients.edit.date_start')}</span>
              {renderSortIcon('dateStart', this.props.sort, this.props.ascSortOrder, this.props.descSortOrder)}
            </div>
          </th>
          <th>
            <div className={'sort ' + (this.props.sort['dateEnd'] || '')}
              onClick={() => this.props.onSortClick('dateEnd')}
            >
              <span>{i18next.t('clients.edit.date_end')}</span>
              {renderSortIcon('dateEnd', this.props.sort, this.props.ascSortOrder, this.props.descSortOrder)}
            </div>
          </th>
          <th>{i18next.t('common.date_created')}</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {this.props.advertisings.map((advertising, index) => (
          <tr key={index}>
            <td>
              {advertising.id}
            </td>
            <td>
              {advertising.company.name}
            </td>
            <td>
              {advertising.targets.map(target => (
                <div key={target.id} className={'nowrap'}>{(target.channel && target.channel.name) ? target.channel.name : ''}</div>
              ))}
            </td>
            <td>
              {advertising.product.name}
            </td>
            <td>
              {advertising.type ? advertising.type.name : ''}
            </td>
            <td>
              <Link to={"/advertisings/" + advertising.id}>
                {advertising.name}
              </Link>
            </td>
            <td className={advertising.budget ? '' : 'without-budget'}>
              <FormattedNumber
                value={advertising.budget}  type={'float'} />
            </td>
            <td className={
              isFloatNumbersEqual(this.props.advertisingsFacesBudgetSums[advertising['@id']], advertising.budget, 2)
               ? '' : 'without-budget'}>
              <FormattedNumber
                value={this.props.advertisingsFacesBudgetSums[advertising['@id']]}  type={'float'} />
            </td>
            <td className={advertising.status + ' advert-date'}>
              {getFormattedDate(advertising.dateStart)}
            </td>
            <td className={advertising.status + ' advert-date'}>
              {getFormattedDate(advertising.dateEnd)}
            </td>
            <td className={advertising.status + ' advert-date'}>
              {getFormattedDate(advertising.createdAt)}
            </td>
            <td>
              <Protected access={'AdvertisingsEdit'}>
                <div className="flex-left">
                  {this.isInternet(advertising) &&
                    <button onClick={() => sendPlanValues(advertising.id)} className={'btn-icon'}
                            title={i18next.t('clients.advertising.save_plan_values')}>
                      <i className="fal fa-cloud" />
                    </button>
                  }

                  <button onClick={() => this.props.deleteAdvertisings(advertising.id)} className={'btn-delete-client btn-icon'}
                    title={i18next.t('advertising.list.delete')}>
                    <i className="fal fa-trash-alt icon-delete" />
                  </button>
                  <Link to={`/advertisings/${advertising.id}/upload-xls`} title={i18next.t('advertising.list.upload_file')}>
                    <i className="fal fa-upload" />
                  </Link>
                </div>
              </Protected>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    );
  }
}

export default AdvertTable;