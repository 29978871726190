import {useEffect} from "react"
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import apiUrls from "../../../ApiUrls";
import {getUtcDate} from "../../Advertising/Detail/Fact/Utils";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {api} from "../../../api/apiProvider";

const filterDateFormat = 'YYYY-MM-DD'

const useContractorCheckRows = (faceIds, channel, client, dateStart, dateEnd, faceChannel) => {
  const [{data: contractorCheckRows, isLoading: isContractorCheckRowsLoading}, getContractorCheckRows]
    = useApiFetch(apiUrls.get.contractorCheckRows())

  const [{}, addContractorCheckRow] = useApiPost(apiUrls.get.contractorCheckRows())
  const [{}, updateContractorCheckRow] = useApiPatch(apiUrls.get.contractorCheckRow)
  const [{}, addContractorCheck] = useApiPost(apiUrls.get.contractorChecks())

  useEffect(() => {
    if (faceIds && faceIds.length) {
      getRows()
    }
  }, [faceIds])

  const getRows = () => {
    getContractorCheckRows(getCheckRowParams())
  }

  const getCheckRowParams = () => {
    if (channel && dateStart && dateEnd && faceIds && faceIds.length) {
      const channelEntity = ChannelFactory.create(channel.code)

      const params = {
        [channelEntity.faceAdvertisingCompanyIdFilter]: client.value,
        'financialCheck.channel.id': channel.value,
        'financialCheck.dateStart[after]': dateStart,
        'financialCheck.dateEnd[before]': dateEnd,
        'pagination': 'false',
      }

      if (faceChannel && faceChannel.value) {
        params['internetFd.face.channel.id'] = faceChannel.value
      }

      return params
    }
  }

  const getCheckParams = (contractorId) => {
    if (channel && dateStart && dateEnd) {
      return {
        'channel.id': channel.value,
        'contractor.id': contractorId,
        'dateStart[after]': dateStart,
        'dateEnd[before]': dateEnd,
        'order[dateStart]': 'desc',
        't[]': 'check:extended',
      }
    }
  }

  const createContractorCheck = async (contractorId, callback = () => {}) => {
    const data = {
      'dateEnd': dateEnd,
      'dateStart': dateStart,
      'channel': `/channels/${channel.value}`,
      'contractor': `/legals/${contractorId}`,
      't[]': 'check:extended'
    }

    await addContractorCheck(data, true, {}, true, (checkId) => {
      callback(checkId)
    })
  }
  
  const getContractorCheck = async contractorId => {
    const checkParams = new URLSearchParams(getCheckParams(contractorId))
    const checks = await api.get(apiUrls.get.contractorChecks() + '?' + checkParams.toString())
    
    if (checks['hydra:member'] && checks['hydra:member'][0]) {
      return checks['hydra:member'][0]
    }
    
    return {}
  }

  const getAddCheckRowData = (row, check) => {
    return {
      financialCheck: `/financial/contractor_checks/${check.id}`,
      approved: row.approved || false,
      budget: row.budget,
      budgetTax: row.budgetTax,
      tax: row.tax,
      data: [
        {
          face: row.faceIri,
          dateStart: getUtcDate(check.dateStart).format(filterDateFormat),
          dateEnd: getUtcDate(check.dateEnd).format(filterDateFormat)
        }
      ]
    }
  }

  const saveContractorCheckRow = async row => {
    return new Promise(async (resolve, reject) => {
      if (!row.contractorId) {
        return resolve(true)
      }

      if (row.id) {
        updateContractorCheckRow(row.id, {
          approved: row.approved,
          budget: row.budget,
          budgetTax: row.budgetTax,
          tax: row.tax,
        }, {}, () => {
          return resolve(true)
        }, () => {
          return resolve(false)
        })
      } else {
        const contractorId = row.contractorId
        
        const check = await getContractorCheck(contractorId)
        
        if (check && check.id) {
          await addContractorCheckRow(getAddCheckRowData(row, check), null, {}, true, () => {
            return resolve(true)
          })
        } else {
          await createContractorCheck(contractorId, async (checkId) => {
            const check = await api.get(apiUrls.get.contractorCheck(checkId))
            if (check && check.id) {
              await addContractorCheckRow(getAddCheckRowData(row, check), null,{}, true, () => {
                return resolve(true)
              })
            }
          })
        }
      }
    })
  }
  
  return {
    contractorCheckRows,
    saveContractorCheckRow,
    getRows,
    isContractorCheckRowsLoading,
  }
}

export default useContractorCheckRows