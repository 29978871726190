import React from 'react'

import TextFilter from "./FilterItems/TextFilter";
import DateFilter from "./FilterItems/DateFilter";
import RadioFilter from "./FilterItems/RadioFilter";
import SelectFilter from "./FilterItems/SelectFilter";
import DateRangeFilter from "./FilterItems/DateRangeFilter";
import CheckboxFilter from "./FilterItems/CheckboxFilter";

function FilterElement({element, onChange, value, filterValues, setFilterValue}) {
  const onChanges = ( value) => {
    setFilterValue(element.name, value)
  }

  return (
    <>
      {element.type === 'text' &&
        <TextFilter
          element={element}
          onChange={onChanges}
          value={value}
        />
      }

      {element.type === 'date' &&
        <DateFilter
          onChange={onChanges}
          element={element}
          value={value}
        />
      }

      {element.type === 'dateRange' &&
        <DateRangeFilter
          onChange={onChanges}
          element={element}
          value={value}
        />
      }

      {element.type === 'radio' &&
        <RadioFilter
          element={element}
          onChange={onChanges}
          value={value}
        />
      }

      {element.type === 'checkbox' &&
        <CheckboxFilter
          element={element}
          onChange={onChanges}
          value={value}
        />
      }

      {element.type === 'select' &&
        <SelectFilter
          onChange={onChanges}
          isDisabled={element.isDisabled}
          optionValues={element.optionValues}
          element={element}
          value={value}
          filterValues={filterValues}
          setFilterValue={setFilterValue}
        />
      }
    </>
  )
}

export default FilterElement