import React, {useEffect, useState, forwardRef} from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";
import {logError} from "../../utils";
import i18n from "i18next";

function DateWithRangeInput(props) {
  const [isRange, setIsRange] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (props.filter.dateStart && props.filter.dateEnd) {
      if (moment(props.filter.dateStart, props.filter.filterFormat).format('YYYY-MM')
        !== moment(props.filter.dateEnd, props.filter.filterFormat).format('YYYY-MM')) {
        setStartDate(moment(props.filter.dateStart, props.filter.filterFormat).toDate())
        setEndDate(moment(props.filter.dateEnd, props.filter.filterFormat).toDate())
        setIsRange(true)
      }
    }
  }, [props.filter.dateStart, props.filter.dateEnd])

  useEffect(() => {
    console.log('useEffect');
    console.log(startDate && endDate);
    if (startDate && endDate) {
      props.onChange(null, {
        'dateStart': moment(startDate).startOf('month').format(props.filter.filterFormat),
        'dateEnd': moment(endDate).endOf('month').format(props.filter.filterFormat),
      })
    }

  }, [startDate, endDate])

  const RangeInput = forwardRef(
    ({value, onClick}, ref) => {
      return (
        <>
          <button className="range-button" onClick={onClick} ref={ref}>
            {startDate ? moment(startDate, props.filter.filterFormat).format(props.filter.rangeFormat) : ''}
          </button>
          - <button className="range-button">
            {endDate ? moment(endDate, props.filter.filterFormat).format(props.filter.rangeFormat) : ''}
          </button>
        </>
      )
    },
  );

  return (
    <span className={'filter-item ' + props.filter.className}>
      <div className={'range-date-wrapper'}>
        <div>
        {!isRange &&
        <DatePicker
          placeholderText={props.filter.placeholder}
          selected={props.filter.dateStart ? moment(props.filter.dateStart, props.filter.filterFormat).toDate() : ''}
          onChange={(value) => {
            props.onChange(null, {
              'dateStart': moment(value).startOf('month').format(props.filter.filterFormat),
              'dateEnd': moment(value).endOf('month').format(props.filter.filterFormat),
            })
          }}
          dateFormat={props.filter.format}
          showMonthYearPicker={props.filter.showMonthYearPicker}
        />
        }

          {isRange &&
          <>
            <DatePicker
              placeholderText={props.filter.placeholder}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat={props.filter.format}
              showMonthYearPicker={props.filter.showMonthYearPicker}
              selectsRange
              shouldCloseOnSelect={false}
              customInput={<RangeInput />}
            />
          </>
          }
      </div>
      <div>
        <span className={'period' + (isRange ? '' : ' active')}
          onClick={() => setIsRange(false)}>{i18n.t('components.filter.month')}</span>
        <span className={'period' + (isRange ? ' active' : '')}
          onClick={() => setIsRange(true)}>{i18n.t('components.filter.period')}</span>
      </div>
      </div>
    </span>
  )
}

export default DateWithRangeInput