import React, {Component} from 'react';
import {getFiltersQuery} from '../../utils';
import {api} from '../../api/apiProvider'
import TopMenu from "../../Components/TopMenu";
import Filter from "../../Components/Filter/Filter";
import i18n from "i18next";
import ReactPaginate from "react-paginate";
import moment from 'moment';
import InvoiceTable from "./Table";
import {Link} from "react-router-dom";
import apiUrls from "../../ApiUrls";
import Protected from "../../Components/Roles/Protected";

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      companyRole: null,
      companies: [],
      channels: [],

      invoicesTotalCount: 0,
      perPage: 30,
      page: 1,

      filter: {
        date: '',
        channel: '',
        company: '',
      },

      filterDateFormat: 'YYYY-MM-DD',
      fractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,

      sort: {},
      showLoader: false,
      ascSortOrder: 'asc',
      descSortOrder: 'desc',
      clientCompanyRole: 'client',
    }
  }

  UNSAFE_componentWillMount = async () => {
    await api.get(apiUrls.get.clientRoles()).then((response) => {
      let roles = response['hydra:member'];

      if (roles[0]) {
        this.setState({companyRole: roles[0]})
      }
    });

    await this.getCompanies()

    await this.getChannels()
  }

  getCompanies = async () => {
    await api.get(apiUrls.get.companies() + "?roles.code=" + this.state.companyRole.code).then((data) => {
      const companies = data['hydra:member']
      this.setState({companies})
    })
  }

  getChannels = async () => {
    await api.get(apiUrls.get.channels()).then((data) => {
      const channels = data['hydra:member']
      this.setState({channels})
    })
  }

  showLoader = () => {
    this.setState({showLoader: true})
  }

  hideLoader = () => {
    this.setState({showLoader: false})
  }

  setPage = (page) => {
    this.setState({
      page
    })
  }

  setFilter = async (filter, callback, name) => {
    await this.setState({
      filter
    }, () => {
      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: getFiltersQuery(this.state.filter, this.state.page, this.state.sort)
      })
    })
  }

  setFilterQuery = () => {
    let query = ''

    for (let filterName in this.state.filter) {
      if (this.state.filter.hasOwnProperty(filterName) && this.state.filter[filterName]) {
        switch (filterName) {
          case 'company':
            if (this.state.filter[filterName].value) {
              query += '&legalEntity.' + filterName + '.id=' + this.state.filter[filterName].value
            }

            break;
          case 'channel':
            if (this.state.filter[filterName].value) {
              query += '&' + filterName + '.id=' + this.state.filter[filterName].value
            }
            break;
          case 'date':
            query += '&dateStart[after]=' + this.state.filter[filterName] +
              '&dateEnd[before]=' +
              moment(this.state.filter[filterName]).endOf('month').format(this.state.filterDateFormat)
            break;
          default:
            break;
        }
      }
    }

    return query
  }

  updateData = () => {
    this.showLoader()
    let url = apiUrls.get.invoices() + '?'

    url += this.setFilterQuery()

    api.get(url).then((data) => {
      const invoices = data['hydra:member']

      let invoiceMap = new Map()

      for (let invoice of invoices) {
        invoiceMap.set(invoice.id, invoice)
      }

      this.setState({invoices: invoiceMap});
      this.setState({invoicesTotalCount: data['hydra:totalItems']})
    }).finally(() => {
      this.hideLoader()
    })
  }

  getFilterOptions = () => {
    const emptyOption = {
      label: i18n.t('projects.list.not_selected'),
      value: ''
    }

    const companyOptions = this.state.companies.map((company) => {
      return {value: company.id, label: company.name}
    })

    companyOptions.unshift(emptyOption)

    const channelOptions = this.state.channels.map((channel) => {
      return {value: channel.id, label: channel.name}
    })

    channelOptions.unshift(emptyOption)

    let filter = this.state.filter
    let self = this

    return [
      {
        type: 'date',
        value: filter.date,
        placeholder: i18n.t('clients.advertising.month'),
        name: 'date',
        callback: self.updateDataWithoutPagination,
        format: "MM.yyyy",
        filterFormat: this.state.filterDateFormat,
        className: 'has-separator pointer has-search-icon',
        showMonthYearPicker: true
      },
      {
        type: 'dropdown',
        value: filter.company,
        placeholder: i18n.t('clients.page.company'),
        options: companyOptions,
        name: 'company',
        className: 'has-separator pointer',
      },
      {
        type: 'dropdown',
        value: filter.channel,
        placeholder: i18n.t('clients.advertising.channel'),
        options: channelOptions,
        name: 'channel',
        className: 'pointer'
      },
    ]
  }

  render() {
    let filters = this.getFilterOptions()

    return (
      <div className="row content">
        <div className="edit-client">
          <div className="table-edit-client invoice-list">
            <TopMenu title={i18n.t('header.invoices_list')}>
              <div className={'adverts-menu-header'}>
                <div>
                  <Protected access={'FinancialInvoicesEdit'}>
                    <Link className="blue-cursor-button" to={"/invoice/new"}>
                      <i className="fal fa-plus mr-for-icon" />
                      {i18n.t('clients.edit.add_new')}
                    </Link>
                  </Protected>
                </div>
              </div>
            </TopMenu>

            {this.state.companies.length > 0 && this.state.channels.length > 0 &&
            <Filter
              filters={filters}
              filter={this.state.filter}
              setPage={this.setPage}
              setSort={() => {}}
              setFilter={this.setFilter}
              location={this.props.location}
              getData={this.updateData}
              setEmptyData={this.setEmptyData}
            />
            }

            {this.state.showLoader &&
            <div className={'loader-background'} />
            }

            <div className={'loader' + (this.state.showLoader ? '' : ' hidden')}>
              <i className="fa fa-refresh fa-spin" />
            </div>

            <InvoiceTable
              invoices={this.state.invoices}
              sort={this.state.sort}
              ascSortOrder={this.state.ascSortOrder}
              descSortOrder={this.state.descSortOrder}
            />

          </div>
        </div>
        {(this.state.invoicesTotalCount > 0 && this.state.invoicesTotalCount > this.state.perPage) &&
        <ReactPaginate
          previousLabel={i18n.t('projects.list.prev')}
          nextLabel={i18n.t('projects.list.next')}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(this.state.invoicesTotalCount / this.state.perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={this.state.page - 1}
        />
        }

      </div>
    );
  }
}

export default InvoiceList