import Roles from '../../roles/Roles'
import * as React from "react";

function Protected({access, children}) {
  const hasAccess = Roles.hasAccess(access)

  return (
    hasAccess
      ? children || null
      : null
  )
}

export default Protected