import React from 'react'
import i18n from "i18next";
import {Link} from "react-router-dom";
import Protected from "../../Components/Roles/Protected";

function Table (props) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
        <th>
          {i18n.t('clients.edit.legal_entity')}
        </th>
        <th>
          {i18n.t('clients.edit.itn')}
        </th>
        <th>
          {i18n.t('clients.edit.iec')}
        </th>
        <th>
          {i18n.t('clients.edit.type_of_tax')}
        </th>
        <th>
          {i18n.t('clients.edit.comment')}
        </th>
        <th/>
      </tr>
      </thead>

      <tbody>
      {props.legals && props.legals.map((legal, index) => (
        <tr
          key={index}
          >
          <td key={index}>
            <Link to={"/client/" + props.companyId + "/legal/" + legal.id}>
              {legal.name}
            </Link>
          </td>
          <td>
            {legal.inn}
          </td>
          <td>
            {legal.kpp}
          </td>
          <td>
            {legal.tax.name}
          </td>
          <td>
            {legal.comment}
          </td>
          <td className="right-text">
            <Protected access={'ClientsEdit'}>
              <button onClick={() => props.deleteLegalEntity(legal.id, () => {
                props.getLegals()
              })}>
                <i className="fal fa-trash-alt icon-delete" />
              </button>
            </Protected>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default Table;