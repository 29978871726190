import React, {Fragment} from 'react';
import InternetTable from "./Table/Internet";
import DigitalTable from "./Table/Digital";
import TvTable from "./Table/Tv";
import {DigitalChannel} from "../../../entities/channel/DigitalChannel";
import {InternetChannel} from "../../../entities/channel/InternetChannel";
import {TvChannel} from "../../../entities/channel/TvChannel";

function Table(props) {
  return (
    <Fragment>
      {(props.channelEntity instanceof InternetChannel) &&
        <InternetTable
          {...props}
        />
      }

      {(props.channelEntity instanceof DigitalChannel) &&
        <DigitalTable
          {...props}
        />
      }

      {(props.channelEntity instanceof TvChannel) &&
      <TvTable
        {...props}
      />
      }
    </Fragment>
  )
}

export default Table;