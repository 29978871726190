import React from 'react';
import i18n from "i18next";
import Input from "../../Components/Inputs/Input";
import Select from 'react-select';
import moment from "moment";
import DatePicker from "react-datepicker";

function FaceForm(props) {
  return (
    <table className="table redesign-table table-client">
      <tbody>
      <tr>
        <td>
          {i18n.t('registryFaces.edit.expenses')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
            className={props.inputErrors.hasOwnProperty('name') ? 'has-error' : ''}
            value={props.formFace.expenses || ''}
            onChange={(value) => props.setFormFace({...props.formFace, expenses: parseFloat(value)})}
            isValid={() => !props.inputErrors.hasOwnProperty('expenses')}
          />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('registryFaces.edit.advert_name')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
            className={props.inputErrors.hasOwnProperty('advertName') ? 'has-error' : ''}
            value={props.advertName || ''}
            onChange={(value) => props.setAdvertName(value)}
            isValid={() => !props.inputErrors.hasOwnProperty('advertName')}
          />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('registryFaces.edit.advert')}
        </td>
      </tr>
      <tr>
        <td>
          <Select
            className={"react-select-container select-pseudo-input"}
            classNamePrefix="react-select"
            placeholder={i18n.t('registryFaces.edit.advert')}
            options={props.advertOptions}
            onChange={(advert) => {
              props.setSelectedAdvert(advert)
              props.setFormFace({...props.formFace, advertising: advert.value})
            }}
            value={props.selectedAdvert || ''} />
          {props.inputErrors.hasOwnProperty('advert') &&
          <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
          }
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('registryFaces.edit.channel')}
        </td>
      </tr>
      <tr>
        <td>
          <Select
            className={"react-select-container select-pseudo-input"}
            classNamePrefix="react-select"
            placeholder={i18n.t('registryFaces.edit.channel')}
            options={props.channelOptions}
            onChange={(channel) => {
              props.setSelectedChannel(channel)
            }}
            value={props.selectedChannel || ''} />
          {props.inputErrors.hasOwnProperty('channel') &&
          <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
          }
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('registryFaces.edit.faces')}
        </td>
      </tr>
      <tr>
        <td>
          <Select
            className={"react-select-container select-pseudo-input"}
            classNamePrefix="react-select"
            placeholder={i18n.t('registryFaces.edit.faces')}
            options={props.faceOptions}
            onChange={(faces) => {
              props.setSelectedFaces(faces)

              let internetFaces = []
              let digitalFaces = []

              for (let face of faces) {
                if (face.value.includes('internet_faces')) {
                  internetFaces.push(face.value)
                }

                if (face.value.includes('digital_faces')) {
                  digitalFaces.push(face.value)
                }
              }

              if (internetFaces.length) {
                props.setFormFace({...props.formFace, internetFaces})
              }

              if (digitalFaces.length) {
                props.setFormFace({...props.formFace, digitalFaces})
              }
            }}
            value={props.selectedFaces || ''}
            isMulti
          />
          {props.inputErrors.hasOwnProperty('faces') &&
          <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
          }
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default FaceForm;