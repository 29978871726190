import React, {Component} from 'react';
import {getUrlWithParams, getFiltersQuery} from '../../utils';
import {api} from '../../api/apiProvider'
import TopMenu from "../../Components/TopMenu";
import Filter from "../Filter";
import i18n from "i18next";
import ReactPaginate from "react-paginate";
import FaceTableWithoutInvoice from "./TableWithoutInvoice";
import {toast} from "react-toastify";
import moment from "moment";
import InvoiceAdd from "./InvoiceAdd";
import {Link} from "react-router-dom";
import {cloneDeep} from "lodash";
import InvoiceForm from "./InvoiceForm";
import apiUrls from "../../ApiUrls";
import {getUtcDate} from "../Advertising/Detail/Fact/Utils";
import {DigitalChannel} from "../../entities/channel/DigitalChannel";
import {InternetChannel} from "../../entities/channel/InternetChannel";

class FaceListWithoutInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faces: [],
      companies: [],
      products: [],
      advertisings: [],
      channels: [],
      companyRole: {},
      invoice: {},
      selectedFaces: [],
      facesTotalCount: 0,
      perPage: 30,
      page: 1,

      filter: {
        date: '',
        channel: '',
        company: '',
        product: '',
        advertising: '',
      },

      showAddInvoice: false,
      filterDateFormat: 'YYYY-MM-DD',
      fractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,

      sort: {},
      showLoader: false,
      ascSortOrder: 'asc',
      descSortOrder: 'desc',
      isInvoicePopup: false,
      dateFormat: 'Y-MM-DD'
    }

  }

  UNSAFE_componentWillMount = async () => {
    await api.get(apiUrls.get.clientRoles()).then((response) => {
      let roles = response['hydra:member'];

      if (roles[0]) {
        this.setState({companyRole: roles[0]})
      }
    });

    this.getInvoiceData()

    await this.getCompanies()

    await this.getChannels()
  }

  getInvoiceData = async () => {
    await this.getInvoice()
  }

  getInvoice = async() => {
    await api.get(apiUrls.get.invoice(this.props.match.params.invoiceId)).then(invoice => {
      this.setState({invoice}, () => {
        this.getProducts()
        this.getAdvertisings()
      })
    })
  }

  getCompanies = async () => {
    await api.get(apiUrls.get.clients()).then((data) => {
      const companies = data['hydra:member']
      this.setState({companies})
    })
  }

  getChannels = async () => {
    await api.get(apiUrls.get.channels()).then((data) => {
      const channels = data['hydra:member']
      this.setState({channels}, () => {
        const defaultChannel = channels.find(channel => channel.code === DigitalChannel.getCode())

        const searchParams = new URLSearchParams(window.location.search)
        const isChannel = searchParams.has('channel')

        if (defaultChannel && defaultChannel.id && !isChannel) {
          setTimeout(() => {
            this.setFilter({
              ...this.state.filter,
              channel: {
                value: defaultChannel.id,
                label: defaultChannel.name
              }
            })
          }, 200)

        }
      })
    })
  }

  getProducts = () => {
    const url = getUrlWithParams(apiUrls.get.products(), {'companies.id': this.state.invoice.legalEntity.company.split('/').pop()})

    api.get(url).then((data) => {
      const products = data['hydra:member']
      this.setState({products})
    })
  }

  getAdvertisings = async () => {
    if (this.state.invoice && this.state.invoice.legalEntity) {
      const params = {
        'company.id': this.state.invoice.legalEntity.company.split('/').pop(),
        't[]': 'short',
        'targets.periods.dateStart': getUtcDate(this.state.invoice.dateStart).format(this.state.dateFormat),
        'targets.periods.dateEnd': getUtcDate(this.state.invoice.dateEnd).format(this.state.dateFormat),
        'targets.periods.approved': true,
        'page': 1
      }

      if (this.state.filter.channel && this.state.filter.channel.value) {
        params['targets.channel.id'] = this.state.filter.channel.value
      }

      if (this.state.filter.product && this.state.filter.product.value) {
        params['product.id'] = this.state.filter.product.value
      }

      const url = getUrlWithParams(apiUrls.get.adverts(), params)

      await api.get(url).then((data) => {
        const advertisings = data['hydra:member']
        this.setState({advertisings})
      })
    }
  }

  showLoader = () => {
    this.setState({showLoader: true})
  }

  hideLoader = () => {
    this.setState({showLoader: false})
  }

  setPage = (page) => {
    this.setState({
      page
    })
  }

  setFilter = async (filter, callback, name) => {
    await this.setState({
      filter
    }, () => {
      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: getFiltersQuery(this.state.filter, this.state.page, this.state.sort)
      })

      this.setSelectedFaces([])
    })
  }

  /*getCompanyLegal = async () => {
    await api.get("/legals?role.code=" + this.state.clientCompanyRole + "&company.id=" + this.state.filter.company.value).then((data) => {
      if (data['hydra:member'][0]) {
        const companyLegal = data['hydra:member'][0]
        this.setState({companyLegal})
      }
    })
  }*/

  setFilterQuery = () => {
    let query = ''

    for (let filterName in this.state.filter) {
      if (this.state.filter.hasOwnProperty(filterName) && this.state.filter[filterName]) {
        switch (filterName) {
          case 'channel':
          case 'product':
          case 'advertising':
          case 'company':
            if (this.state.filter[filterName].value) {
              query += '&' + filterName + '.id=' + this.state.filter[filterName].value
            }
            break;

          case 'date':
            query += '&dateStart[after]=' + this.state.filter[filterName] +
              '&dateEnd[before]=' +
              moment(this.state.filter[filterName]).endOf('month').format(this.state.filterDateFormat)
            break;
          default:
            break;
        }
      }
    }

    if (this.state.page) {
      query += "&page=" + this.state.page;
    }

    return query
  }

  updateData = () => {
    this.showLoader()

    let url = apiUrls.get.invoiceFaces() + '/unused?t=if:read&'
    url += this.setFilterQuery()

    api.get(url).then((data) => {
      const faces = data['hydra:member']

      this.setState({faces});
      this.setState({facesTotalCount: data['hydra:totalItems']})
    }).finally(() => {
      this.hideLoader()
    })

    this.getAdvertisings()
  }

  isEmptyFilter = () => {
    let isEmpty = true

    let requiredFields = ['dateStart', 'dateEnd', 'company', 'channel']

    for (let field of requiredFields) {
      if (this.state.filter[field]) {
        isEmpty = true
      }
    }

    return isEmpty
  }

  isDigital = () => {
    if (!this.state.filter.channel || !this.state.channels || !this.state.channels.length) {
      return false
    }

    return this.state.channels.findIndex(channel => (
      channel.code === DigitalChannel.getCode() && channel.id == this.state.filter.channel.value
    )) > -1
  }

  isInternet = () => {
    if (!this.state.filter.channel || !this.state.channels || !this.state.channels.length) {
      return false
    }

    return this.state.channels.findIndex(channel => (
      channel.code === InternetChannel.getCode() && channel.id == this.state.filter.channel.value
    )) > -1
  }

  getFilterOptions = () => {
    const emptyOption = {
      label: i18n.t('projects.list.not_selected'),
      value: ''
    }

    const companyOptions = this.state.companies.map((company) => {
      return {value: company.id, label: company.name}
    })

    companyOptions.unshift(emptyOption)

    const channelOptions = this.state.channels.map((channel) => {
      return {value: channel.id, label: channel.name}
    })

    channelOptions.unshift(emptyOption)

    const productOptions = this.state.products.map((product) => {
      return {value: product.id, label: product.name}
    })

    productOptions.unshift(emptyOption)

    const advertisingOptions = this.state.advertisings.map((advertising) => {
      return {value: advertising.id, label: advertising.name}
    })

    advertisingOptions.unshift(emptyOption)

    let filter = this.state.filter
    let self = this

    return [
      {
        type: 'date',
        value: filter.date,
        placeholder: i18n.t('clients.advertising.month'),
        name: 'date',
        callback: self.updateDataWithoutPagination,
        format: "MM.yyyy",
        filterFormat: this.state.filterDateFormat,
        className: 'has-separator pointer has-search-icon ' + (!filter.date ? 'error' : ''),
        required: true,
        showMonthYearPicker: true,
      },
      {
        type: 'dropdown',
        value: filter.company,
        placeholder: i18n.t('clients.page.company'),
        options: companyOptions,
        name: 'company',
        callback: self.updateData,
        className: 'has-separator pointer ' + (!filter.company.value ? 'error' : ''),
        required: true,
      },
      {
        type: 'dropdown',
        value: filter.product,
        placeholder: i18n.t('clients.page.product'),
        options: productOptions,
        name: 'product',
        callback: self.updateData,
        className: 'has-separator pointer',
        required: false,
      },
      {
        type: 'dropdown',
        value: filter.advertising,
        placeholder: i18n.t('clients.page.advertising'),
        options: advertisingOptions,
        name: 'advertising',
        callback: self.updateData,
        className: 'has-separator pointer',
        required: false,
      },
      {
        type: 'dropdown',
        value: filter.channel,
        placeholder: i18n.t('clients.advertising.channel'),
        options: channelOptions,
        name: 'channel',
        callback: () => {
          console.log('ergerg');
          self.updateData()
          self.getAdvertisings()
        },
        className: 'pointer ' + (!filter.channel.value ? 'error' : ''),
        required: true,
      },
    ]
  }

  getFaceIndexInSelected = face => {
    return this.state.selectedFaces.findIndex(selectedFace =>
      selectedFace.face && face.face && selectedFace.face.id === face.face.id)
  }

  isFaceInSelected = face => {
    return this.getFaceIndexInSelected(face) > -1
  }

  isFaceCheckboxDisabled = face => {
    if (!this.state.selectedFaces || !this.state.selectedFaces[0]) {
      return false
    }

    return face.advertising.id !== this.state.selectedFaces[0].advertising.id
  }

  setIsInvoicePopup = (show = true) => {
    if (show && (!this.state.selectedFaces || !this.state.selectedFaces.length)) {
      toast.error(i18n.t('invoice.edit.faces_not_selected'))
      return
    }

    this.setState({isInvoicePopup: show})
  }

  onCheck = (faceSelected, isSelected) => {
    let selectedFaces = this.state.selectedFaces
    
    if (isSelected) {
      const isInSelected = selectedFaces.some(face => face.face && face.face.id === faceSelected.id)

      if (!isInSelected) {
        selectedFaces.push(faceSelected)
      }
    } else {
      let itemIndex = this.getFaceIndexInSelected(faceSelected)

      if (itemIndex > -1) {
        selectedFaces.splice(itemIndex, 1)
      }
    }

    this.setState({selectedFaces})
  }

  isInvoice = () => {
    return this.props.match.params.invoiceId !== undefined
  }

  onClickAdd = () => {
    this.setState({showAddInvoice: true})
  }

  setSelectedFaces = (selectedFaces) => {
    this.setState({selectedFaces})
  }

  selectedFaces = () => {
    let facesToSave = []

    for (let face of this.state.selectedFaces) {
      facesToSave.push({
        channel: apiUrls.get.channel(this.state.filter.channel.value),
        advertising: face.advertising['@id'],
        work: face.work['@id'],
        internetFace: face.face['@id'],
        expenses: face.expenses || 0
      })
    }

    return facesToSave
  }

  onClickSave = async () => {
    if (!this.state.selectedFaces.length) {
      toast.error(i18n.t('invoice.edit.faces_not_selected'))
      return
    }

    const facesToSave = []

    for (let selectedFaceIndex of this.state.selectedFaces) {
      let face = this.state.faces[selectedFaceIndex]

      facesToSave.push({
        channel: apiUrls.get.channel(this.state.filter.channel.value),
        advertising: face.advertising['@id'],
        contractor: face.legalEntity['@id'],
        work: face.work['@id'],
        internetFace: face.face['@id'],
        expenses: face.expenses || 0
      })
    }

    try {
      await api.patch(apiUrls.get.channel(this.props.match.params.invoiceId), {
        invoiceFaces: facesToSave
      });

      toast.success(i18n.t('invoice.edit.updated'));
      this.updateData()

      this.setState({selectedFaces: []})
    } catch (error) {
      toast.error(i18n.t('invoice.edit.edit_fail'))
    }
  }

  onInvoiceAdd = () => {
    this.updateData()

    this.closeModal()
  }

  closeModal = () => {
    this.setState({showAddInvoice: false})
  }

  handlePageClick = (data) => {
    this.setState({page: (+data.selected + 1)}, () => {
      this.updateData()
    })
  }

  onExpenseChange = (index, value) => {
    let faces = cloneDeep(this.state.faces)

    if (faces[index]) {
      faces[index].expenses = parseFloat(value)
    }

    this.setState({faces})
  }

  render() {
    let filters = this.getFilterOptions()

    let title = i18n.t('header.faces_without_invoice')

    return (
      <div className="row content">
        <div className="edit-client">
          <div className="table-edit-client invoice-list">
            <TopMenu title={title}>
              <div className={'adverts-menu-header'}>
                {this.isInvoice() && this.state.faces &&
                <div>
                  <button
                    className="button-accent ml-2 add-invoice-button"
                    onClick={this.setIsInvoicePopup}
                    disabled={this.state.selectedFaces.length <= 0}
                  >
                    {i18n.t('clients.edit.add_to_invoice')}
                  </button>
                </div>
                }

                {!this.isInvoice() && this.state.faces.length > 0 && this.state.selectedFaces.length > 0 &&
                <div>
                  <button className="button-accent ml-2" onClick={this.onClickAdd}>
                    {i18n.t('face.edit.add')}
                  </button>
                </div>
                }

                {this.props.match.params.invoiceId &&
                <Link className="blue-cursor-button back-button" to={'/invoice/' + this.props.match.params.invoiceId + '/edit'}>
                  {i18n.t('face.list.back')}
                </Link>
                }

              </div>
            </TopMenu>

            {this.state.companies.length > 0 && this.state.channels.length > 0 &&
            <Filter
              filters={filters}
              filter={this.state.filter}
              setPage={this.setPage}
              setSort={this.setSort}
              setFilter={this.setFilter}
              location={this.props.location}
              getData={this.updateData}
              setEmptyData={this.setEmptyData}
            />
            }

            {this.state.showLoader &&
            <div className={'loader-background'}></div>
            }

            <div className={'loader' + (this.state.showLoader ? '' : ' hidden')}>
              <i className="fa fa-refresh fa-spin"></i>
            </div>

            <InvoiceAdd
              selectedFaces={this.selectedFaces()}
              onInvoiceAdd={this.onInvoiceAdd}
              closeModal={this.closeModal}
              showAddInvoice={this.state.showAddInvoice}
            />

            <FaceTableWithoutInvoice
              faces={this.state.faces}
              sort={this.state.sort}
              ascSortOrder={this.state.ascSortOrder}
              descSortOrder={this.state.descSortOrder}
              isEmptyFilter={this.isEmptyFilter}
              onCheck={this.onCheck}
              selectedFaces={this.state.selectedFaces}
              onExpenseChange={this.onExpenseChange}
              isFaceInSelected={this.isFaceInSelected}
              isFaceCheckboxDisabled={this.isFaceCheckboxDisabled}
            />

          </div>
        </div>
        {(this.state.facesTotalCount > 0 && this.state.facesTotalCount > this.state.perPage) &&
        <ReactPaginate
          previousLabel={i18n.t('projects.list.prev')}
          nextLabel={i18n.t('projects.list.next')}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(this.state.facesTotalCount / this.state.perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={this.state.page - 1}
        />
        }

        {this.state.isInvoicePopup &&
          <InvoiceForm
            faces={this.state.selectedFaces}
            setSelectedFaces={this.setSelectedFaces}
            setIsInvoicePopup={this.setIsInvoicePopup}
            invoice={this.state.invoice}
            channel={this.state.filter.channel}
            channels={this.state.channels}
            date={this.state.filter.date}
            updateData={this.updateData}
            fractionDigits={this.state.fractionDigits}
            ></InvoiceForm>
        }

      </div>
    );
  }
}

export default FaceListWithoutInvoice