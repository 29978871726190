import React from 'react';
import IntInput from '../../../Components/Inputs/IntInput';
import FloatInput from "../../../Components/Inputs/FloatInput";
import {Link} from "react-router-dom";

const floatType = 'float'
const intType = 'int'

function FacesRowCell({
                       property,
                       detail,
                       onChange,
                       onSave,
                       faceReturn,
                       faceCommissions,
}) {
  const isEditable = () => {
    if (!detail || detail.editable === undefined) {
      return true
    }

    return Boolean(detail ? detail.editable : false)
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    className: 'money',
    value: detail ? detail.value : 0,
    disabled: !isEditable(),
    onKeyDown: handleKeyDown
  }

  return (
    <td key={property.code} className={'money'}>
      <div className={'detail-cell'}>
        {property.code === 'return'
          ? <div>
            <div>{faceReturn || ''}</div>
            {faceCommissions && faceCommissions.map(commission => {
              const commissionId = commission['@id'].split('/').pop()

              return <Link to={`/financial/commission/${commissionId}/edit`} key={commissionId}>{commissionId}</Link>
            })}
          </div>
          :
          <>
            <>
              {property.type === floatType &&
              <FloatInput
                onChange={(value) => {
                  onChange(value, property)
                }}
                {...inputProps}
              />
              }

              {property.type === intType &&
              <IntInput
                onChange={(value) => onChange(value, property)}
                {...inputProps}
              />
              }
            </>
          </>
        }
      </div>
    </td>
  )
}

export default FacesRowCell
