import * as React from 'react'
import {Redirect, Route, BrowserRouter, useLocation, useHistory, Switch} from 'react-router-dom'

import Login from "../Views/Login";
import CompanyList from "../Views/Company/List";
import CompanyEdit from "../Views/Company/Edit";
import RegionalClient from "../Views/RegionalClient";
import RegionCreating from "../Views/RegionCreating";
import LegalEntityEdit from "../Views/LegalEntities/Edit";
import LegalEntitiesList from "../Views/LegalEntities/List";
import ClientManagersList from "../Views/Company/Managers";
import ProductsList from "../Views/Products/List";
import ProductsEdit from "../Views/Products/Edit";
import RegistryList from "../Views/Registry/List";
import RegistryEdit from "../Views/Registry/Edit";
import RegistryFaceEdit from "../Views/Registry/FaceEdit";
import InvoiceRegistryFaces from "../Views/InvoiceRegistryFaces/List";
import AdvertisingEdit from "./../Views/Advertising/Edit";
import AdvertisingFactData from '../Views/Advertising/Detail/Fact/List'
import AdvertisingFaces from '../Views/Advertising/Faces/List'
import AdvertisingList from "../Views/Advertising/List";
import UploadXls from "../Views/Advertising/UploadXls";
import FinancialReport from "../Views/Advertising/Financial/Report";
import AdvertisingPlan from "../Views/Advertising/Detail/Plan";
import AdvertisingPage from "../Views/Advertising/PlanData/Page";
import AdvertisingDetailPlanList from '../Views/Advertising/Detail/Plan/Advertising/List'
import InvoiceList from "../Views/Invoice/List";
import InvoiceEdit from "../Views/Invoice/Edit";
import FaceListWithoutInvoice from "../Views/Face/ListWithoutInvoice";
import CommissionList from "../Views/Financial/Commission/List/List";
import CommissionEdit from "../Views/Financial/Commission/Edit/Edit";
import ReconciliationWithClient from "../Views/Financial/ReconciliationWithClient";
import ReconciliationWithPlanner from "../Views/Financial/ReconciliationWithPlanner";
import ReconciliationWithFinancier from "../Views/Financial/ReconciliationWithFinancier";
import FinancialFacesTotalList from '../Views/Financial/FacesTotal/List';
import FinancialContractorsList from '../Views/Financial/ContractorChecks/List';
import FinancialClientInvoicesList from '../Views/Financial/ClientInvoices/List';
import FinancialClientInvoiceEdit from '../Views/Financial/ClientInvoices/Edit';
import CheckRowsListWithoutInvoice from '../Views/Financial/ClientCheckRow/ListWithoutInvoice';
import FinancialMonthFact from '../Views/Financial/MonthFact';
import UploadFinancialMonthFactXls from '../Views/Financial/MonthFact/upload/index';
import RepaymentIntervals from '../Views/LegalEntities/RepaymentIntervals/List';
import RepaymentIntervalsEdit from '../Views/LegalEntities/RepaymentIntervals/Edit';
import {useKeycloak} from "@react-keycloak/web";
import PrivateRoute from "./PrivateRoute";
import Roles from "../roles/Roles";
import ClosingPerformance from "../Views/Financial/ClosingPerformance";

const NotFoundPage = () => {
  return <h2>404 - Not found</h2>;
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login"
             component={(props) => <Login {...props} />}/>
      <PrivateRoute exact path="/clients" comp={CompanyList}/>
      <PrivateRoute exact path="/client/:companyID/edit" comp={CompanyEdit}/>
      <PrivateRoute exact path="/client/:companyID/cities" comp={RegionalClient}/>
      <PrivateRoute exact path="/client/:companyID/city/new" comp={RegionCreating}/>
      <PrivateRoute exact path="/client/:companyID/city/:regionID" comp={RegionCreating}/>
      {/*<Route path="/client/:companyID/briefs" comp={ProjectTemplate} />*/}
      <PrivateRoute exact path="/client/:companyID/legal/new" comp={LegalEntityEdit}/>

      {Roles.hasAccess('RepaymentIntervals') &&
        <PrivateRoute exact path="/client/:companyID/legal/:legalID/repayment_intervals" comp={RepaymentIntervals}/>
      }

      {Roles.hasAccess('RepaymentIntervals') &&
        <PrivateRoute exact path="/client/:companyID/legal/:legalID/repayment_intervals/new"
                      comp={RepaymentIntervalsEdit}/>
      }

      {Roles.hasAccess('RepaymentIntervals') &&
        <PrivateRoute exact path="/client/:companyID/legal/:legalID/repayment_interval/:intervalId"
                      comp={RepaymentIntervalsEdit}/>
      }

      <PrivateRoute exact path="/client/:companyID/legal/:legalID" comp={LegalEntityEdit}/>
      <PrivateRoute exact path="/client/:companyID/legals" comp={LegalEntitiesList}/>
      <PrivateRoute exact path="/client/:companyID/managers" comp={ClientManagersList}/>
      <PrivateRoute exact path="/client/:companyID/products" comp={ProductsList}/>
      <PrivateRoute exact path="/client/:companyID/product/new" comp={ProductsEdit}/>
      <PrivateRoute exact path="/client/:companyID/product/:productId" comp={ProductsEdit}/>
      <PrivateRoute exact path="/registries" comp={RegistryList}/>
      <PrivateRoute exact path="/registry/new" comp={RegistryEdit}/>
      <PrivateRoute exact path="/registry/:registryId/faces/new" comp={RegistryFaceEdit}/>
      <PrivateRoute exact path="/registry/:registryId" comp={RegistryEdit}/>
      <PrivateRoute exact path="/invoice-registry-faces" comp={InvoiceRegistryFaces}/>
      {/*<Route path="/client/:companyID/transactions" comp={FinanceTransaction} />*/}
      {/*<PrivateRoute path="/client/:companyID/advertisings" comp={Advertising}/>*/}
      <PrivateRoute exact path="/client/new" comp={CompanyEdit}/>
      {/*<Route path="/project/:projectID/edit" comp={ProjectAction} />
            <Route path="/project/create" comp={ProjectAction} />*/}
      {/*<Route path="/project/:projectID/mediaplans/:mediaplanID/advertising/:advertisingId" comp={AdvertisingFactData} />*/}
      {/*<Route path="/project/:projectID/mediaplans" comp={MediaplanList} />
            <Route path="/projects" comp={ProjectsList} />*/}
      <PrivateRoute exact path="/advertisings" comp={AdvertisingList}/>
      <PrivateRoute exact path="/advertisings/new" comp={AdvertisingEdit}/>
      <PrivateRoute exact path="/advertisings/upload-xls" comp={UploadXls}/>
      <PrivateRoute exact path="/advertisings/:advertisingId" comp={AdvertisingEdit}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/faces/:channel" comp={AdvertisingFaces}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/fact/:channel" comp={AdvertisingFactData}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/plan/:channel" comp={AdvertisingDetailPlanList}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/upload-xls" comp={UploadXls}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/plan" comp={AdvertisingPlan}/>
      <PrivateRoute exact path="/advertisings/:advertisingId/plan-data" comp={AdvertisingPage}/>
      <PrivateRoute exact path="/financial/report" comp={FinancialReport}/>
      {/*<Route path="/invoice/:invoiceId/edit" comp={InvoiceList} />*/}
      <PrivateRoute exact path="/invoices" comp={InvoiceList}/>
      <PrivateRoute exact path="/invoice/new" comp={InvoiceEdit}/>
      <PrivateRoute exact path="/invoice/:invoiceId/edit" comp={InvoiceEdit}/>
      <PrivateRoute exact path="/faces-without-invoice/:invoiceId" comp={FaceListWithoutInvoice}/>
      {/*<Route path="/faces-without-invoice" comp={FaceListWithoutInvoice} />*/}

      {Roles.hasAccess('FinancialCommissionsRead') &&
        <PrivateRoute exact path="/financial/commissions" comp={CommissionList}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/faces-total" comp={FinancialFacesTotalList}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/contractors" comp={FinancialContractorsList}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/client_invoices/new" comp={FinancialClientInvoiceEdit}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/client_invoices/:id" comp={FinancialClientInvoiceEdit}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/faces-without-invoice/:invoiceId" comp={CheckRowsListWithoutInvoice}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&
        <PrivateRoute path="/financial/client_invoices" comp={FinancialClientInvoicesList}/>
      }

      {Roles.hasAccess('FacesTotalEdit') &&//todo
        <PrivateRoute path="/financial/financier" comp={ReconciliationWithFinancier}/>
      }

      {Roles.hasAccess('FinancialFinancierRead') &&//todo
        <PrivateRoute path="/financial/month-fact/upload" comp={UploadFinancialMonthFactXls}/>
      }

      {Roles.hasAccess('FinancialFinancierRead') &&//todo
        <PrivateRoute path="/financial/month-fact/" comp={FinancialMonthFact}/>
      }

      {Roles.hasAccess('FinancialFinancierRead') &&
      <PrivateRoute path="/financial/closing-performance/" comp={ClosingPerformance}/>
      }

      {Roles.hasAccess('ReconciliationWithClientRead') &&
        <PrivateRoute path="/client-reconciliation" comp={ReconciliationWithClient}/>
      }

      {Roles.hasAccess('ReconciliationWithPlannerRead') &&
        <PrivateRoute path="/planner-reconciliation" comp={ReconciliationWithPlanner}/>
      }

      {Roles.hasAccess('FinancialCommissionsRead') &&
        <PrivateRoute exact path="/financial/commission/new" comp={CommissionEdit}/>
      }

      {Roles.hasAccess('FinancialCommissionsRead') &&
        <PrivateRoute path="/financial/commission/from-fact/:advertisingId/:channel/:faceId"
                      component={CommissionEdit}/>
      }

      {Roles.hasAccess('FinancialCommissionsRead') &&
        <PrivateRoute exact path="/financial/commission/:id/edit" comp={CommissionEdit}/>
      }

      <PrivateRoute path="/" exact>
        <Redirect to="/advertisings"/>
      </PrivateRoute>

      <Route path="*" comp={NotFoundPage} status={404}/>
    </Switch>
  )
}

export const Router = () => {
  const {keycloak} = useKeycloak();

  return (
    <>
      {
        keycloak.authenticated &&
        <>
          <BrowserRouter>
            <Routes/>
          </BrowserRouter>
        </>
      }
    </>
  )
}

export default Router