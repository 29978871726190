import store from '../store/store';
import roles from '../roles/roles.json'

export default class Roles {
  static hasAccess(accessName) {
    const state = store.getState()

    if (!state || !state.user || !state.user.roles) {
      return false
    }

    const userRoles = state.user.roles
    return Roles.isRolesInAccess(accessName, userRoles)
  }

  static isRolesInAccess(access, userRoles) {
    if (!(access in roles)) {
      return false
    }

    const accessRoles = roles[access]
    const intersection = accessRoles.filter(x => userRoles.includes(x));
    return intersection.length > 0
  }
}