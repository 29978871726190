import React from 'react';
import FloatInput from "./Inputs/FloatInput";

const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function FormattedNumber({value = 0, type, fractions = 0, ...rest}) {
  return (
    <div className={'formatted-number'}>
      <FloatInput
        value={value}
        disabled={true}
        allowNegative={true}
        displayType={'text'}
        isValid={() => true}
      />
    </div>
  );
}

export default FormattedNumber;