import React, {Fragment} from 'react';

function Row({
               properties,
               row,
               values,
               onChange,
               onSave,
               title = '',
               titleColSpan = 1,
               setSelectedRow,
               index,
               showIndex = false,
               className,
               rowCellComponent,
               cellBeforeTitle,
               cellAfterTitle,
               cellAfterProperties,
               faceReturn,
               faceCommissions,
               faceIindex,
               isSelectToPaste = true,
               editablePropertiesCodes = []
             }) {
  const RowCellComponent = rowCellComponent

  return (
    <tr className={className || ''}>
      {cellBeforeTitle &&
        cellBeforeTitle
      }
      {index !== undefined && showIndex &&
        <td>{index + 1}</td>
      }
      <td colSpan={titleColSpan}>{title}</td>
      {cellAfterTitle &&
        cellAfterTitle
      }
      {isSelectToPaste &&
      <td className={'select-paste-column'} >
        <span
          onClick={() => {
            setSelectedRow(index, faceIindex)
          }}
        ><i className="fas fa-arrow-alt-circle-right"/></span>
      </td>
      }

      {properties && properties.map(property => (
        <Fragment key={property.code + values[property.code]}>
          <RowCellComponent
            property={property}
            detail={values[property.code] || {}}
            onChange={onChange}
            entity={'advertising'}
            onSave={onSave}
            faceReturn={faceReturn}
            faceCommissions={faceCommissions}
            editablePropertiesCodes={editablePropertiesCodes}
            row={row}
          />
        </Fragment>
      ))}

      {cellAfterProperties &&
        cellAfterProperties
      }
    </tr>
  )
}

export default Row;