import {useEffect, useState} from "react"
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {getAdvertisingDates, logError} from "../../../utils";
import {getFormattedDate} from "../../Advertising/Detail/Fact/Utils";
import {toast} from "react-toastify";
import i18n from "../../../i18n";
import useContractorCheckRows from "./useContractorCheckRows";
import useClientCheckRows from "./useClientCheckRows";
import useCommissions from "./useCommissions";

const filterDateFormat = 'YYYY-MM-DD'
const bannerTypePlatformId = process.env.REACT_APP_INTERNET_PLATFORM_BANNER

const useFaces = (filterParams, selectedChannel, clientFilter, dateStartFilter, dateEndFilter, faceChannelFilter) => {
  const getFacesUrl = () => {
    if (selectedChannel && selectedChannel.code) {
      const channelEntity = ChannelFactory.create(selectedChannel.code)

      return channelEntity.apiUrls.faces
    }

    return null
  }

  const getFactDataUrl = () => {
    if (selectedChannel && selectedChannel.code) {
      const channelEntity = ChannelFactory.create(selectedChannel.code)

      return channelEntity.apiUrls.totalFact
    }

    return null
  }

  const [facesMap, setFacesMap] = useState(null)
  const [facesIdsWithContractors, setFacesIdsWithContractors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [{data: faces, isLoading: isFacesLoading}, fetchFaces] = useApiFetch(getFacesUrl())
  const [{data: factData, isLoading: isFactDataLoading}, fetchFact] = useApiFetch(getFactDataUrl())
  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(getFactDataUrl())

  const [{}, addNewFace] = useApiPost(getFacesUrl())

  const {contractorCheckRows, saveContractorCheckRow, getRows: getContractorCheckRows, isContractorCheckRowsLoading}
    = useContractorCheckRows(facesIdsWithContractors, selectedChannel, clientFilter, dateStartFilter, dateEndFilter, faceChannelFilter)

  const {clientCheck, saveClientCheckRow, getCheck: getClientCheck, isClientChecksLoading}
    = useClientCheckRows(selectedChannel, clientFilter, dateStartFilter, dateEndFilter)

  const {commissions, getCommissions, save: saveCommission} = useCommissions(facesIdsWithContractors, selectedChannel, dateStartFilter, dateEndFilter)

  useEffect(() => {
    if (clientFilter && dateStartFilter && dateEndFilter && selectedChannel) {
      getFaces()
      getFact()
    }
  }, [selectedChannel, filterParams])

  useEffect(() => {
    if (faces) {
      const facesIds = []

      for (let face of faces) {
        if (face.contractor) {
          facesIds.push(face.id)
        }
      }

      setFacesIdsWithContractors(facesIds)
    }
  }, [faces])

  useEffect(() => {
    if (faces && factData && contractorCheckRows && !isContractorCheckRowsLoading && commissions && !isClientChecksLoading) {
      const facesMap = new Map()

      faces.map(face => {
        facesMap.set(face['@id'], {
          '@id': face['@id'],
          advertisingId: face.advertising.id,
          companyId: clientFilter.value,
          contractor: face.contractor,
          channel: face.channel,
          platform: face.platform,
          id: face.id,
          name: face.name,
          budget: 0,
          goal: 0,
        })
      })

      if (factData[0] && factData[0].data && Array.isArray(factData[0].data)) {
        factData[0].data.map(item => {
          if (item.item && facesMap.has(item.item) && item.properties && item.properties.budget) {
            const face = facesMap.get(item.item)

            facesMap.set(item.item, {
              ...face,
              budget: item.properties.budget ? item.properties.budget.value : 0,
              goal: item.properties.goal ? item.properties.goal.value : 0,
            })
          }
        })
      }

      if (contractorCheckRows && contractorCheckRows.length) {
        for (let contractorCheckRow of contractorCheckRows) {
          if (contractorCheckRow.data && contractorCheckRow.data[0]) {
            const checkRowFaceIri = contractorCheckRow.data[0].face

            if (facesMap.has(checkRowFaceIri)) {
              const face = facesMap.get(checkRowFaceIri)

              facesMap.set(checkRowFaceIri, {
                ...face,
                checkRowId: contractorCheckRow.id,
                checkRowApproved: contractorCheckRow.approved,
                checkRowBudget: contractorCheckRow.budget,
                checkRowBudgetTax: contractorCheckRow.budgetTax,
                checkRowTax: contractorCheckRow.tax,
              })
            }
          }
        }
      }

      if (clientCheck && clientCheck.rows && clientCheck.rows.length) {
        for (let clientCheckRow of clientCheck.rows) {
          if (clientCheckRow.faces && clientCheckRow.faces[0]) {
            const clientCheckRowFace = clientCheckRow.faces[0]

            if (facesMap.has(clientCheckRowFace['@id'])) {
              const face = facesMap.get(clientCheckRowFace['@id'])

              facesMap.set(clientCheckRowFace['@id'], {
                ...face,
                clientCheckRowId: clientCheckRow.id,
                clientCheckRowBudget: clientCheckRow.budget,
                clientCheckRowApproved: clientCheckRow.approved,
              })
            }
          }
        }
      }

      if (commissions && Object.values(commissions).length) {
        for (let faceIri in commissions) {
          const commission = commissions[faceIri]
          if (facesMap.has(faceIri)) {
            const face = facesMap.get(faceIri)

            facesMap.set(faceIri, {
              ...face,
              commissionId: commission.id,
              bonus: commission.value,
            })
          }
        }
      }

      setFacesMap(facesMap)
    }
  }, [faces, factData, contractorCheckRows, clientCheck, commissions])

  const getFacesParams = () => {
    if (selectedChannel && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'advertising.company.id[]': clientFilter.value,
        'advertising.dateEnd[after]': dateStartFilter,
        'advertising.dateStart[before]]': dateEndFilter,
        't[]': ['name', 'face_contractor', 'advertising', 'face_channel', 'face_platform'],
        'pagination': 'false',
        'channel.id': (faceChannelFilter && faceChannelFilter.value) ? faceChannelFilter.value : null,
      }
    }

    return {}
  }

  const getTotalDataParams = () => {
    if (selectedChannel && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'face.advertising.company.id[]': clientFilter.value,
        interval: 'month',
        'date[after]': dateStartFilter,
        'date[before]': dateEndFilter,
        'props[]': ['budget', 'goal'],
        'face.channel.id': (faceChannelFilter && faceChannelFilter.value) ? faceChannelFilter.value : null,
      }
    }

    return {}
  }
  
  const getFaces = () => fetchFaces(getFacesParams())

  const getFact = () => fetchFact(getTotalDataParams())

  const getFaceData = () => {
    getFact()
    getCommissions()
  }

  const changeFace = (value, propertyCode, faceIri, oneBannerPrice) => {
    const newFacesMap = new Map(facesMap)

    if (newFacesMap.has(faceIri)) {
      const face = newFacesMap.get(faceIri)

      const facePlatformId = face.platform ? face.platform.id : null

      if (facePlatformId && facePlatformId == bannerTypePlatformId && propertyCode == 'goal') {
        face.budget = value * oneBannerPrice
      }

      if (propertyCode === 'checkRowBudget' || propertyCode === 'checkRowTax') {
        if (propertyCode === 'checkRowBudget') {
          face.checkRowTax = value * 0.2
          face.checkRowBudgetTax = value + (face.checkRowTax || 0)
        } else {
          face.checkRowBudgetTax = (face.checkRowBudget || 0) + value
        }
      }

      newFacesMap.set(faceIri, {
        ...face,
        [propertyCode]: value
      })

      setFacesMap(newFacesMap)
    }
  }

  const saveFactDataValues = (intervals, callback) => {
    if (intervals && intervals.length) {
      saveFactData({
        intervals
      }, null, {}, true, () => {
        if (typeof callback === 'function') {
          callback()
        }
      }, () => {}
      );
    }
  }

  const saveFaceFactData = async (face, advertising) => {
    return new Promise((resolve, reject) => {
      if (!advertising || face.checkRowApproved) {
        return resolve(true)
      }

      const [intervalStart, intervalEnd]
        = getAdvertisingDates(advertising.dateStart.origin, advertising.dateEnd.origin, dateStartFilter, dateEndFilter)

      const intervals = [
        {
          data: [
            {
              item: face['@id'],
              properties: {
                budget: face.budget || 0,
                goal: face.goal || 0,
              }
            }
          ],
          dateStart: getFormattedDate(intervalStart, filterDateFormat),
          dateEnd: getFormattedDate(intervalEnd, filterDateFormat),
        }
      ]

      saveFactDataValues(intervals, () => {
        return resolve(true)
      })
    })
  }

  const addFace = face => {
    addNewFace(face, null, {}, true, () => {
      fetchFaces()
    })
  }

  const saveFace = (face, advertising) => {
    setIsLoading(true)

    const itemsToSave = []

    itemsToSave.push(saveFaceFactData(face, advertising))

    if (face.clientCheckRowBudget || face.clientCheckRowApproved !== undefined) {
      itemsToSave.push(saveClientCheckRow(face))
    }

    if ('bonus' in face) {
      itemsToSave.push(saveCommission(face))
    }

    if (face.budget) {
      const contractorCheckRow = {
        approved: face.checkRowApproved,
        budget: face.checkRowBudget,
        budgetTax: face.checkRowBudgetTax,
        tax: face.checkRowTax,
        id: face.checkRowId,
        contractorId: face.contractor ? face.contractor.id : null,
        faceIri: face['@id']
      }

      itemsToSave.push(saveContractorCheckRow(contractorCheckRow))
    }

    Promise.all(
      itemsToSave
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        getFaceData()
        if (face.budget) {
          getContractorCheckRows()
        }
        getClientCheck()
      })
      .finally(() => {
        setIsLoading(false)
        //setChangedFaces([])
      })
      .catch(() => {
        setIsLoading(false)
        //setChangedFaces([])
      })
  }

  return {
    facesMap,
    changeFace,
    isFacesLoading,
    isFactDataLoading,
    getFact,
    saveFace,
    addFace,
    getFaces,
  }
}

export default useFaces