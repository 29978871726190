import React from 'react'
import i18n from "i18next";
import {Link} from "react-router-dom";

function Table(props) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
          <th colSpan="4">Счета клиента</th>
          <th colSpan="4" >Документы подрядчика</th>
      </tr>
      <tr>
        <th>
          {i18n.t('reconciliation.invoice')}
        </th>
        <th>
          {i18n.t('reconciliation.advert')}
        </th>
        <th>
          {i18n.t('reconciliation.face')}
        </th>
        <th>
          {i18n.t('reconciliation.spent')}
        </th>


        <th>
          {i18n.t('reconciliation.expenses')}
        </th>
        <th>
            {i18n.t('reconciliation.face')}
        </th>
        <th>
            {i18n.t('reconciliation.advert')}
        </th>
        <th>
          {i18n.t('reconciliation.registry')}
        </th>
        <th />
      </tr>
      </thead>

      <tbody>
      {props.registryFaces.map((face, index) => (
        <tr
          key={index}
        >
          <td>
            {/*<Link to={`/invoice/${face.invoice.id}/edit`}>
                  {face.invoiceFace ? face.invoiceFace.face.name : ''}
                </Link>*/}
              {face.invoiceFace ? <Link to={`/invoice/${face.invoiceFace.invoice.id}/edit`}>{face.invoiceFace.invoice.name}</Link> : 'Не найдено в счетах'}
          </td>
          <td>
            {face.invoiceFace ? face.invoiceFace.advertising.name : ''}
          </td>
          <td>
            {face.invoiceFace ? face.invoiceFace.face.name : ''}
          </td>
          <td>
            <span className={face.isError ? 'error-colored' : ''}>
              {face.invoiceFace ? face.invoiceFace.spent.toFixed(2) : ''}
            </span>
          </td>

          <td>
            <span className={face.isError ? 'error-colored' : ''}>
              {face.registryFace.expenses.toFixed(2) || ''}
            </span>
          </td>
          <td>
            {face.registryFace.internetFaces.map((internetFace, internetFaceIndex) => (
              <div key={internetFaceIndex}>{internetFace.name || ''}</div>
            ))}
          </td>
          <td>
            {face.registryFace.advertising.name || ''}
          </td>
          <td>
            {face.registryFace ? <Link to={`/registry/${face.registryFace.registry.id}/edit`} >{face.registryFace.registry.name }</Link>: 'Не найдено в документах подрядчика'}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default Table;
