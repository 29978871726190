import React from 'react'
import i18n from "i18next";
import {Link} from "react-router-dom";
import {getFormattedDate} from "../../Advertising/Detail/Fact/Utils";
import {toast} from "react-toastify";

function Table ({intervals, companyId, legalId, getIntervals, deleteInterval}) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
        <th>
          {i18n.t('commissions.edit.dateStart')}
        </th>
        <th>
          {i18n.t('commissions.edit.dateEnd')}
        </th>
        <th>
          {i18n.t('commissions.edit.repayment_interval')}
        </th>
        <th/>
      </tr>
      </thead>

      <tbody>
      {intervals && intervals.map((interval) => (
        <tr
          key={interval.id}
          >
          <td>
            {getFormattedDate(interval.dateStart)}
          </td>
          <td>
            {getFormattedDate(interval.dateEnd)}
          </td>
          <td>
            <Link to={`/client/${companyId}/legal/${legalId}/repayment_interval/${interval.id}`}>
              {interval.name}
            </Link>
          </td>
          <td className="right-text">
            <button onClick={() => deleteInterval(interval.id, () => {
              toast.success(i18n.t('clients.edit.legal_repayment_deleted'))
              getIntervals({
                'contractor.id': legalId
              })
            })}>
              <i className="fal fa-trash-alt icon-delete" />
            </button>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

export default Table;
