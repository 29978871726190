import React, {Fragment, useEffect, useState} from 'react';
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import DateFilter from "./dateFilter";
import PropsFilter from "./propsFilter";
import {cloneDeep} from "lodash";
import Protected from "../../../../Components/Roles/Protected";
import UnusedFaces from "./unusedFaces";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import DigitalForm from "../Faces/DigitalForm";
import {InternetChannel} from "../../../../entities/channel/InternetChannel";
import InternetForm from "../Faces/InternetForm";
import {TvChannel} from "../../../../entities/channel/TvChannel";
import TvForm from "../Faces/TvForm";
import PeriodsTable from "./table";
import LoaderBackdrop from "../../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../../Components/Loader/Loader";
import moment from "moment";
import {getFormattedDate, getUtcDate} from "../Fact/Utils";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import i18n from "i18next";
import ButtonWithLoader from "../../../../Components/Buttons/ButtonWithLoader";
import DigitalFaceInfo from "../Faces/DigitalFaceInfo";
import Footer from "../components/Footer";
import CopyFromTableForm from "./CopyFromTableForm";
import {Link} from "react-router-dom";
import TopMenu from "../../../../Components/TopMenu";
const dateFormat = 'YYYY-MM-DD';

export default function PeriodPlan(props) {
  const advertisingId = props.match.params.advertisingId

  const [channelEntity, setChannelEntity] = useState(null)
  const [filter, setFilter] = useState({})
  const [selectedProperties, setSelectedProperties] = useState(null)
  const [advertisingFaces, setAdvertisingFaces] = useState({})
  const [unusedFaces, setUnusedFaces] = useState(null)
  const [selectedFace, setSelectedFace] = useState(null)
  const [selectedFaceForInfo, setSelectedFaceForInfo] = useState(null)
  const [plan, setPlan] = useState([])
  const [advertPeriod, setAdvertPeriod] = useState(null)
  const [isFacesSaving, setIsFacesSaving] = useState(false)
  const [selectedDetailToPasteTable, setSelectedDetailToPasteTable] = useState(null)
  const [selectedIntervalToPasteTable, setSelectedIntervalToPasteTable] = useState(null)
  const [selectedFieldToPasteTable, setSelectedFieldToPasteTable] = useState(null)
  const [filterTimer, setFilterTimer] = useState(null)

  const [{data: properties}, getProperties] = useApiFetch(channelEntity
    ? (channelEntity.getApiUrl('properties') + '?entity=face')
    : null
  )

  const [{data: faces, isLoading: isFacesLoading}, getFaces] = useApiFetch(channelEntity ? channelEntity.getApiUrl('faces') : null)
  const [{
    data: planData,
    isLoading: isPlanDataLoading
  }, getPlanData] = useApiFetch(apiUrls.get.advertisingPlanData(channelEntity ? channelEntity.getCode() : null))

  const [{data: advertising}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))

  const getPeriodsUrl = () => {
    if (channelEntity) {
      return `/data/plan/${channelEntity.code}/faces`
    }
  }

  const [{errors: savePlanDataErrors}, savePlanData] = useApiPost(
    getPeriodsUrl,
    '',
    'clients.advertising',
    (params) => {
      toast.success(i18n.t('common.saved'));
      setIsFacesSaving(false)
      getPlanDataValues()
    }
  )

  useEffect(() => {
    if (advertising && advertising.targets && advertising.targets[0] && advertising.targets[0].channel) {
      setChannelEntity(ChannelFactory.create(advertising.targets[0].channel.code))

      setAdvertPeriod(advertising.targets[0])
    }
  }, [advertising])

  useEffect(() => {
    if (channelEntity) {
      getAdvertisingFaces()
      getProperties()
    }
  }, [channelEntity])

  useEffect(() => {
    if (faces && planData) {
      const usedFacesIds = [];

      for (let interval of planData) {
        if (interval.data) {
          for (let face of interval.data) {
            usedFacesIds.push(face.item['@id'])
          }
        }
      }

      const usedFaces = faces.filter(face => {
        return usedFacesIds.includes(face['@id'])
      })

      const unusedFaces = faces.filter(face => {
        return !usedFacesIds.includes(face['@id'])
      })

      setAdvertisingFaces(usedFaces)
      setUnusedFaces(unusedFaces)
    }
  }, [faces, planData])

  useEffect(() => {
    if (planData) {
      const plan = cloneDeep(planData)
      setPlan(plan)
    }
  }, [planData])

  useEffect(() => {
    getAdvertising({
      't[]': 'advert:read',
    })
  }, [])

  useEffect(() => {
    if (filter && filter.period && filter.interval && filter.properties) {
      if (filterTimer) {
        clearTimeout(filterTimer);
      }

      const timerId = setTimeout(() => {
        getPlanDataValues()
      }, 1000);

      setFilterTimer(timerId)

      const advertPeriod = advertising.targets[0].periods.find(period => {
        const periodDateStart = getUtcDate(period.dateStart).format(dateFormat)
        const periodDateEnd = getUtcDate(period.dateEnd).format(dateFormat)

        const filterDateStart = filter.period.dateStart.format(dateFormat)
        const filterDateEnd = filter.period.dateEnd.format(dateFormat)

        return periodDateStart === filterDateStart && periodDateEnd === filterDateEnd
      })

      if (advertPeriod) {
        setAdvertPeriod(advertPeriod)
      } else {
        setAdvertPeriod(advertising.targets[0])
      }
    }
  }, [filter])

  const getPlanDataValues = () => {
    const params = {
      'date[after]': filter.period.dateStart.format(dateFormat),
      'date[before]': filter.period.dateEnd.format(dateFormat),
      'face.advertising.id': advertising.id,
      'props[]': filter.properties,
      'order[face.id]': 'asc',
      't[]': ['face_details', 'advertising', 'id'],
      interval: filter.interval.value
    }

    getPlanData(params)
  }

  const getAdvertisingFaces = () => {
    getFaces({
      't[]': ['face_details', 'advertising', 'id', 'strategy'],
      'advertising.id': advertisingId
    })
  }

  const setFilterValues = (code, value) => {
    setFilter(filter => {
      return {
        ...filter,
        [code]: value,
      }
    })
  }

  const addFaceToDetails = (iri) => {
    for (let interval of plan) {
      if (interval.data) {
        const isFace = Boolean(interval.data.find(item => item.item && item.item['@id'] === iri))

        if (isFace) {
          return
        }
      }
    }

    const face = faces.find(face => face['@id'] === iri)

    if (face && face['@id']) {
      addNewDetail(face)
    }
  }

  const addNewDetail = (face) => {
    const detail = {
      item: face,
      properties: {},
      isNew: true,
    }

    const isUnitCost = face.faceUnits && face.faceUnits[0] && face.faceUnits[0].unitCost

    for (let property of properties) {

      const editable = !(
        ((typeof property.editable !== "undefined") && !property.editable)
        || (typeof property.callback !== "undefined") && isUnitCost
      )

      detail.properties[property.code] = {
        editable,
        value: 0
      }
    }

    let newPlan = cloneDeep(plan)

    for (let interval of newPlan) {
      if (interval.data) {
        interval.data.push(cloneDeep(detail))
      }
    }

    setPlan(newPlan)
    setTimeout(() => {
      const el = document.querySelector('.new-row');
      if (el) {
        el.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
      }

    }, 300)
  }

  const onRowChange = (value, item, periodIndex) => {
    const changedPlan = cloneDeep(plan)

    if (changedPlan[periodIndex]) {
      const face = changedPlan[periodIndex].data.find(intervalFace => intervalFace.item && intervalFace.item['@id']
        && intervalFace.item['@id'] === item.item['@id'])

      if (face) {
        face.properties = value
      }
    }

    setPlan(changedPlan)
  }

  const savePlanDataValues = intervals => {
    if (intervals && intervals.length) {
      savePlanData({
        intervals
      }, true, {
        'face.advertising.id': advertisingId,
        'date[after]': getFormattedDate(filter.period.dateStart, dateFormat),
        'date[before]': getFormattedDate(filter.period.dateEnd, dateFormat),
        'interval': filter.interval,
        't[]': ['face_details', 'advertising', 'id', 'strategy']
      }, true, null);
    }
  }

  const saveAll = () => {
    setIsFacesSaving(true)
    let intervals = []

    for (let interval of plan) {
      let intervalToSave = {}

      intervalToSave.dateStart = moment(interval.dateStart).format('Y-MM-DD')
      intervalToSave.dateEnd = moment.utc(interval.dateEnd).startOf('day').format('Y-MM-DD')

      intervalToSave.data = []

      for (let face of interval.data) {
        let faceItem = {
          item: face.item['@id'],
          properties: {}
        }

        for (let propertyCode in face.properties) {
          faceItem.properties[propertyCode] = face.properties[propertyCode].value
        }

        intervalToSave.data.push(faceItem)
      }

      if (intervalToSave.data.length) {
        intervals.push(intervalToSave)
      }
    }

    savePlanDataValues(intervals)
  }

  const setSelectedInfoFace = id => {
    if (faces) {
      const face = faces.find(face => face.id === id)

      setSelectedFaceForInfo(face)
    }
  }

  const isAnyLoading = () => isPlanDataLoading || isFacesLoading

  return (
    <div className={'plan-data-page'}>
      {advertising &&
        <>
          <TopMenu title={
            <Fragment>
              <div className={'title-text'}>{i18n.t('clients.advertising.advertising_faces_detail')}</div>
              <div className={'title'}>{<Link to={`/advertisings/${advertising.id}`}>
                {`(${advertising.id}) ${advertising.name}`}
              </Link>} <div className={'title-text'}>{' (' + getFormattedDate(advertising.dateStart, 'DD.MM.Y') + '-' +
                getFormattedDate(advertising.dateEnd, 'DD.MM.Y') + ' ' + (advertising.targets[0]
                  ? advertising.targets[0].channel.code
                  : '') + ' "' + advertising.product.name + '")'}</div></div>
            </Fragment>
          } />

          <div className={'filter-items'}>
            <DateFilter advertising={advertising} setFilter={setFilterValues} channelEntity={channelEntity}/>
            <PropsFilter channelEntity={channelEntity} isLoading={isAnyLoading()} setFilter={setFilterValues}
                         properties={properties} setSelectedProperties={setSelectedProperties}/>
          </div>

          <div className={'mediaplan-tables periods plan-period' + ((selectedFace || selectedFaceForInfo
            || selectedDetailToPasteTable || selectedIntervalToPasteTable) ? ' face-selected' : '')}>
            <div className={(isAnyLoading() ? ' loading' : '')}>
              {plan && advertisingFaces &&
                <PeriodsTable
                  properties={properties}
                  selectedProperties={selectedProperties}
                  savePlanDataValues={savePlanDataValues}
                  plan={plan}
                  setPlan={setPlan}
                  setSelectedFace={setSelectedFace}
                  selectedInterval={filter.interval}
                  setSelectedInfoFace={setSelectedInfoFace}
                  setSelectedDetailToPasteTable={setSelectedDetailToPasteTable}
                  setSelectedIntervalToPasteTable={setSelectedIntervalToPasteTable}
                  setSelectedFieldToPasteTable={setSelectedFieldToPasteTable}
                  onChange={(value, item, periodIndex) => onRowChange(value, item, periodIndex)}
                />
              }

              {isAnyLoading() &&
                <LoaderBackdrop />
              }

              {isAnyLoading() &&
                <Loader />
              }
            </div>
          </div>

          <Protected access={'AdvertisingsFacesEdit'}>
            {channelEntity &&
              <UnusedFaces
                isLoading={false}
                getAdvertFaces={getAdvertisingFaces}
                faces={unusedFaces}
                setSelectedFace={setSelectedFace}
                channelEntity={channelEntity}
                addFaceToDetails={addFaceToDetails}
                plan={plan}
                advertisingId={advertisingId}
              />
            }
          </Protected>

          {selectedFace && channelEntity &&
            <>
              {channelEntity instanceof DigitalChannel &&
                <DigitalForm
                  faceId={selectedFace.id}
                  getAdvertFaces={getAdvertisingFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getPlanDataValues}
                  advertisingId={advertisingId}
                  companyId={props.match.params.companyID}
                />
              }

              {channelEntity instanceof InternetChannel &&
                <InternetForm
                  faceId={selectedFace.id}
                  advertisingId={advertisingId}
                  getAdvertFaces={getAdvertisingFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getPlanDataValues}
                />
              }

              {channelEntity instanceof TvChannel &&
                <TvForm
                  faceId={selectedFace.id}
                  advertisingId={advertisingId}
                  getAdvertFaces={getAdvertisingFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getPlanDataValues}
                  companyId={props.match.params.companyID}
                />
              }
            </>
          }

          <CopyFromTableForm
            plan={plan}
            setPlan={setPlan}
            properties={selectedProperties}
            selectedDetailToPasteTable={selectedDetailToPasteTable}
            setSelectedDetailToPasteTable={setSelectedDetailToPasteTable}
            selectedIntervalToPasteTable={selectedIntervalToPasteTable}
            setSelectedIntervalToPasteTable={setSelectedIntervalToPasteTable}
            selectedFieldToPasteTable={selectedFieldToPasteTable}
            setSelectedFieldToPasteTable={setSelectedFieldToPasteTable}
          />

          <Footer>
            <div>
              {/*{channelEntity instanceof InternetChannel &&
                <button onClick={() => sendPlan()} className={'btn-icon fact-table__buttons-send'}
                        title={i18n.t('clients.advertising.save_plan_values')}>
                  <i className="fal fa-cloud" />
                </button>
              }*/}
            </div>
            <div>
              {plan &&
                <Protected access={'AdvertisingsEdit'}>
                  <ButtonWithLoader
                    onClick={saveAll}
                    className={"button " + (isFacesSaving ? 'load' : '')}
                    text={i18n.t('clients.advertising.save')}
                    loadingText={i18n.t('clients.advertising.saving_values')}
                    isLoading={isFacesSaving}
                  />
                </Protected>
              }
            </div>
            {selectedFaceForInfo &&
              <>
                {channelEntity instanceof DigitalChannel &&
                  <DigitalFaceInfo
                    face={selectedFaceForInfo}
                    setSelectedFaceForInfo={setSelectedFaceForInfo}
                    companyId={advertising.company.id}
                    closeModal={() => setSelectedFaceForInfo(null)}
                    advertising={advertising}
                  />
                }
              </>
            }
          </Footer>
        </>
      }
    </div>
  )
}