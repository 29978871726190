import React from "react";
import i18n from "i18next";

const noop = () => {};

const isFile = value => Boolean(value && (value.id || value.size))

const FileInput = ({ value, onChange = noop, setFileInput, ...rest }) => (
  <div>
    {isFile(value) && (
      <div>{value.originalName || value.name}</div>
    )}
    <label>
        <a className="blue-cursor-button add-link">
          <i className="fal fa-plus mr-for-icon" />
          {!isFile(value) ?
            i18n.t('components.file-input.add')
            :
            i18n.t('components.file-input.change')
          }
        </a>


      <input
        {...rest}
        ref={(input) => { setFileInput(input) }}
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          onChange([...e.target.files]);
        }}
      />
    </label>
  </div>
);

export default FileInput;