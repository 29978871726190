import i18n from "i18next";

const intervals = {
  P1M: i18n.t('date.names.month'),
  P1W: i18n.t('date.names.week'),
}

const weekDays = {
  monday: i18n.t('date.days.monday'),
  tuesday: i18n.t('date.days.tuesday'),
  wednesday: i18n.t('date.days.wednesday'),
  thursday: i18n.t('date.days.thursday'),
  friday: i18n.t('date.days.friday'),
  saturday: i18n.t('date.days.saturday'),
  sunday: i18n.t('date.days.sunday'),
}

const getIntervalOptions = () => Object.keys(intervals).map((key) => (
  {
    value: key,
    label: intervals[key]
  })
)

const getWeekDayOptions = () => Object.keys(weekDays).map((key) => (
  {
    value: key,
    label: weekDays[key]
  })
)

export {intervals, weekDays, getIntervalOptions, getWeekDayOptions}