import React from 'react'
import {isEqual} from 'lodash'

function RadioFilter({value, element, onChange}) {
  return (
    <div className="radio-group">
      {element.label &&
        <span className={'radio-group__title'}>{element.label}</span>
      }
      {element.options.map(option => (
        <div className="radio-item" key={option.value}>
          <label className={(isEqual(value, option.value) ? 'active ' : '') + option.className}>
            <input type="radio"
                   id={option.code}
                   name={option.name}
                   checked={isEqual(value, option.value)}
                   onChange={() => onChange(option.value)}/>
            <span>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioFilter