import {useEffect} from "react"
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {toast} from "react-toastify";
import i18n from "../../../i18n";

const useAdvert = (client, dateStart, dateEnd, channel) => {
  const [{data: advertisings, isLoading: isAdvertisingsLoading}, getAdvertisings] = useApiFetch(apiUrls.get.adverts())
  const [{}, updateAdvert] = useApiPatch(apiUrls.patch.adverts)
  const [{}, patchApproved] = useApiPatch(apiUrls.patch.patchApproved)

  useEffect(() => {
    if (client && dateStart && dateEnd && channel) {
      getAdvertisings(getParams())
    }
  }, [client, dateStart, dateEnd, channel])

  const getParams = () => {
    if (client && dateStart && dateEnd && channel) {
      return {
        'company.id': client.value,
        'targets.channel.id': channel.value,
        'dateEnd[after]': dateStart,
        'dateStart[before]': dateEnd,
        't[]': 'advert:read',
        'pagination': 'false',
      }
    }
  }

  const saveAdvertData = advert => {
    const advertData = advertisings.find(advertising => advertising.id === advert.id)

    if (advertData) {
      const dataToSave = {
        targets: advertData.targets,
      }

      dataToSave.comment = advert.advertComment
      dataToSave.dashboard = advert.advertDashboard

      for (let target of dataToSave.targets) {
        if (target.id === advert.advertTargetId) {
          target.name = advert.advertTargetName
          target.channel = target.channel['@id']

          updateAdvert(advert.id, dataToSave, {}, async () => {
            for (let period of target.periods) {
              if (period.id === advert.advertPeriodId) {
                const periodData = {
                  nameFinancial: advert.nameFinancial,
                  budgetCode: advert.budgetCode,
                }

                await patchApproved(period.id, periodData)
              }
            }

            toast.success(i18n.t('common.saved'))

            getAdvertisings(getParams())
          })
        }
      }
    }
  }

  return {
    advertisings,
    isAdvertisingsLoading,
    saveAdvertData,
  }
}

export default useAdvert