import React, { Component } from 'react';

class DropDown extends Component {
    constructor(props){
        super(props);
        this.state = {
            active: false,
        }
    }

    open = () => this.setState({active:true});
    close = () => this.setState({active:false});

    openDropDown = () => {
       if(this.state.active === true){
           this.close()
       }
       else{
           this.open();

           if (this.props.timeToClose) {
             this.setTimerToClose()
           }
       }
    }

    setTimerToClose = () => {
      setTimeout(() => {
        this.close()
      }, this.props.timeToClose)
    }

    render() {
        let className = "dropdown-container";
        if(this.state.active === true){
            className = className + " active";
        }
        let contentsClass = "dropdown-contents";
        if(this.props.direction === "up"){
            contentsClass = contentsClass + " height-dropdown";
        }
        return (
            <span className={className}>
                <button onClick={this.openDropDown} className="dropdown-toggler text-button">
                    {this.props.title}
                    {this.props.icon || <i aria-hidden="true" className="fal fa-ellipsis-h" />}
                </button>
                <div className={contentsClass}>
                    {this.props.children}
                </div>
            </span>
        );
    }
}

export default DropDown;