export class TvFace {
  listGroupParams = [
    'face_details',
    'face_communication',
    'face_region',
    'face_distribution',
    'face_factors',
    'face_contractor',
    'advertising',
    'id',
  ]
}