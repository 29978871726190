import React, { useEffect, useState} from 'react';
import i18n from "i18next";
import TableWithoutInvoice from "./TableWithoutInvoice";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {cloneDeep} from "lodash";
import apiUrls from "../../../ApiUrls";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import TopMenu from "../../../Components/TopMenu";
import {getFormattedDate, getUtcDate} from "../FacesTotal/Utils";
import {api} from "../../../api/apiProvider";
import Loader from "../../../Components/Loader/Loader";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import Filter from "../../../Components/NewFilter/Filter";
import {groupParamsByKey} from "../../../utils";

const filterDateFormat = 'YYYY-MM-DD'

export default function ListWithoutInvoice(props) {
  const invoiceId = props.match.params.invoiceId

  const [selectedRows, setSelectedRows] = useState([])
  const [isFacesSaving, setIsFacesSaving] = useState(false)
  const [channelEntity, setChannelEntity] = useState(null)
  const [filters, setFilters] = useState(null)

  const [{data: clientInvoice}, getClientInvoice] = useApiFetch(apiUrls.get.clientInvoice)
  const [{data: clientCheckRows, isLoading: isClientChecksLoading}, getClientCheckRows] = useApiFetch(apiUrls.get.clientCheckRows())

  useEffect(() => {
    getClientInvoice({}, invoiceId)
  }, [])

  useEffect(() => {
    if (clientInvoice) {
      const channelEntity = ChannelFactory.create(clientInvoice.channel.code)
      setChannelEntity(channelEntity)
    }
  }, [clientInvoice])

  useEffect(() => {
    if (channelEntity) {
      const filters = {
        platform: {
          type: 'select',
          value: null,
          placeholder: i18n.t('clients.advertising.platform'),
          getOptionsUrlFunction: () => (channelEntity.apiUrls.platforms + '?pagination=false'),
          name: 'platform',
          setFilterParams: (params, values) => {
            params.delete(`${channelEntity.facesName}.platform.id[]`)
            for (let filterValue of values) {
              params.append(`${channelEntity.facesName}.platform.id[]`, filterValue.value)
            }
          },
          getValueToUrl: (params, platform) => {
            if (platform && platform.length) {
              params.delete('platform[]')
              for (let platformItem of platform) {
                params.append('platform[]', platformItem.value)
              }
            } else {
              params.delete('platform[]')
            }
          },
          isMulti: true,
        },
        legal: {
          type: 'select',
          value: null,
          placeholder: i18n.t('clients.page.legal'),
          getOptionsUrlFunction: apiUrls.get.legals,
          name: 'legal',
          setFilterParams: (params, values) => {
            params.delete(`${channelEntity.facesName}.contractor.id[]`)
            for (let filterValue of values) {
              params.append(`${channelEntity.facesName}.contractor.id[]`, filterValue.value)
            }
          },
          getValueToUrl: (params, legal) => {
            if (legal && legal.length) {
              params.delete('legal[]')
              for (let legalItem of legal) {
                params.append('legal[]', legalItem.value)
              }
            } else {
              params.delete('legal[]')
            }
          },
          isMulti: true,
        },
      }
      setFilters(filters)
    }
  }, [channelEntity])

  const getData = filterParams => {
    const clientsFilterName = `${channelEntity.facesName}.advertising.company.id`

    let params = {
      'pagination': false,
      'exists[clientInvoice]': false,
      'clientCheck.dateStart[after]': getFormattedDate(clientInvoice.dateStart, filterDateFormat),
      'clientCheck.dateEnd[before]': getFormattedDate(clientInvoice.dateEnd, filterDateFormat),
      [clientsFilterName]: clientInvoice.client.id,
      'exists[contractorCheckRow]': true,
      't[]': ['face_contractor', 'name'],
    }

    if (filterParams) {
      params = {
        ...params,
        ...groupParamsByKey(filterParams)
      }
    }

    getClientCheckRows(params)
  }

  const getIndexInSelected = row => {
    return selectedRows.findIndex(selectedRow =>
      selectedRow['@id'] && row['@id'] && selectedRow['@id'] === row['@id'])
  }

  const isInSelected = face => {
    return getIndexInSelected(face) > -1
  }

  const onCheck = (checkRowSelected, isSelected) => {
    let rows = cloneDeep(selectedRows)

    if (isSelected) {
      const isInSelected = rows.some(row => row['@id'] === checkRowSelected['@id'])

      if (!isInSelected) {
        rows.push(checkRowSelected)
      }
    } else {
      let itemIndex = getIndexInSelected(checkRowSelected)

      if (itemIndex > -1) {
        rows.splice(itemIndex, 1)
      }
    }

    setSelectedRows(rows)
  }

  const saveToInvoice = async () => {
    if (!selectedRows.length) {
      toast.error(i18n.t('invoice.edit.faces_not_selected'))
      return
    }

    setIsFacesSaving(true)

    await Promise.all(selectedRows.map(async (selectedRow) => {
      await api.patch(selectedRow['@id'], {
        'clientInvoice': `/financial/client_invoices/${invoiceId}`
      })
    })).then(() => {
      toast.success(i18n.t('invoice.edit.updated'));
      getClientInvoice({}, invoiceId)
      setSelectedRows([])
      getData()
    }).catch((e) => {
      console.log(e)
      toast.error(i18n.t('invoice.edit.edit_fail'))
    }).finally(() => {
      setIsFacesSaving(false)
    })
  }

  let title = i18n.t('header.faces_without_invoice')

  const isAnyLoading = () => isClientChecksLoading || isFacesSaving

  return (
    <div className="row content">
      <div className="edit-client">
        <div className={"table-edit-client invoice-list"  + (isAnyLoading() ? ' loading' : '')}>
          <TopMenu title={title}>
            <div className={'adverts-menu-header'}>
              {clientInvoice && selectedRows &&
                <div>
                  <button
                    className="button-accent ml-2 add-invoice-button"
                    onClick={saveToInvoice}
                    disabled={selectedRows.length <= 0}
                  >
                    {i18n.t('clients.edit.add_to_invoice')}
                  </button>
                </div>
              }

              {invoiceId &&
                <Link className="blue-cursor-button back-button"
                      to={'/financial/client_invoices/' + invoiceId}>
                  {i18n.t('face.list.back')}
                </Link>
              }
            </div>
          </TopMenu>

          {filters &&
            <Filter
              filters={Object.values(filters)}
              getData={getData}
            />
          }

          {isAnyLoading() &&
            <Loader />
          }

          {clientCheckRows &&
            <TableWithoutInvoice
              clientCheckRows={clientCheckRows}
              isInSelected={isInSelected}
              onCheck={onCheck}
            />
          }
        </div>
      </div>
    </div>
  )
}