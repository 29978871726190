import React, {PureComponent} from 'react';
import i18n from 'i18next'

class Input extends PureComponent {
  filterProp = (key) => {
    return typeof this.props[key] !== 'function' && key !== 'errorText'
  }

  getProps = () => {
    return Object.keys(this.props)
      .filter(key => this.filterProp(key))
      .reduce((obj, key) => {
        obj[key] = this.props[key];
        return obj;
      }, {});
  }

  render() {
    const props = this.getProps()

    return (
      <span className="input-wrap">
                <input {...props} onChange={(event) => this.props.onChange(event.target.value)} />
        {
          this.props.isValid && this.props.isValid(this.props.value) === false &&
          <span className="title-error">
                        {this.props.errorText || i18n.t('errors.not_valid')}
                    </span>
        }
      </span>
    );
  }
}

export default Input;