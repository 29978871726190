import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import Input from "../../Components/Inputs/Input";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import useApiPost from "../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getFormattedDate} from "../Advertising/Detail/Fact/Utils";
import apiUrls from "../../ApiUrls";
import FileInput from "../../Components/Inputs/FileInput";

const dateFormat = i18n.t('calendar_date_format')
const getDataFromDocument = document => {
  const data = {
    name: document.name,
    number: document.number,
    comment: document.comment,
    link: document.link,
    id: document.id,
    registry: document.registry,
    dateSigned: getFormattedDate(document.dateSigned, 'YYYY-MM-DD'),
  }

  /*if (document.file) {
    data.file = document.file['@id'] ? document.file['@id'] : document.file
  }*/

  return data
}

function Form(props) {
  const [fileInput, setFileInput] = useState(null)

  const [formDocument, setFormDocument] = useState(props.document)
  const [selectedFile, setSelectedFile] = useState(null)

  const [{errors: updateDocumentErrors}, updateDocument] = useApiPatch(
    apiUrls.patch.documents,
    '',
    'documents.edit',
    () => {
      toast.success(i18n.t('documents.edit.document_saved'))

      setTimeout(() => {
        props.setSelectedDocument(null)
        props.getRegistry()
      }, 500)
    }
  )

  const [{errors: addDocumentErrors}, addDocument] = useApiPost(
    apiUrls.post.documents(),
    '',
    'documents.edit',
    () => {
      toast.success(i18n.t('documents.edit.document_added'))

      setTimeout(() => {
        props.setSelectedDocument(null)
        props.getRegistry()
      }, 500)
    }
  )

  const [{errors: addFileErrors}, addFile] = useApiPost(
    apiUrls.post.media(),
    '',
    'documents.edit'
  )

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData()

      formData.append('file', fileInput.files[0], selectedFile.name)

      addFile(formData, null, {}, false, (id) => {
        if (typeof id === 'number') {
          setFormDocument({
            ...formDocument,
            file: `/media/${id}`
          })
        }
      }, () => {
        setSelectedFile(null)
      })
    }
  }, [selectedFile])

  const onChange = (value, name) => {
    setFormDocument({
      ...formDocument,
      [name]: value
    })
  }

  const save = () => {
    const data = getDataFromDocument(formDocument)

    if (formDocument.id) {
      updateDocument(formDocument.id, data)
    } else {
      addDocument(data)
    }
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
        onClick={() => props.setSelectedDocument(null)} />
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => props.setSelectedDocument(null)}>
            <i className='fas fa-remove' />
          </button>
        </div>
        <div className='popup-form__body'>
          <div className='popup-form__rows'>
            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.name')}
              </div>
              <Input
                value={formDocument.name}
                onChange={(value) => onChange(value, 'name')}
              />
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.number')}
              </div>
              <Input
                value={formDocument.number}
                onChange={(value) => onChange(value, 'number')}
              />
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.date')}
              </div>
              <DatePicker
                selected={moment.utc(formDocument.dateSigned).toDate()}
                onChange={(value) => onChange(value, 'dateSigned')}
                dateFormat={dateFormat}
              />
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.comment')}
              </div>
              <Input
                value={formDocument.comment}
                onChange={(value) => onChange(value, 'comment')}
              />
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.link')}:
              </div>
              <Input
                value={formDocument.link}
                onChange={(value) => onChange(value, 'link')}
              />
              {/*<FileInput
                setFileInput={setFileInput}
                value={(selectedFile && selectedFile.name) ? selectedFile : formDocument.file}
                onChange={(value) => {
                  if (value && value[0]) {
                    setSelectedFile(value[0])
                  }
                }}
              />*/}
            </div>

          </div>
          <div className={'popup-form__buttons'}>
            <button className="popup-form__button button-accent" onClick={save}>
              {i18n.t('documents.edit.save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form