import {InternetChannel} from "./InternetChannel";
import {DigitalChannel} from "./DigitalChannel";
import {TvChannel} from "./TvChannel";

export class ChannelFactory {
  static create (code) {
    switch (code) {
      case 'digital':
        return new DigitalChannel()
      case 'internet':
        return new InternetChannel()
      case 'tv':
        return new TvChannel()
      default:
        return null
    }
  }
}