import React, {useEffect} from 'react';
import {cloneDeep} from 'lodash'
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import DataSourceRow from "./DataSourceRow";

function DataSourcesBlock({datasources, onChange, formFace, isDisabled}) {
  const [{data: digitalDataProperties}, getDigitalDataProperties] = useApiFetch(apiUrls.get.digitalDataProperties())
  const [{data: digitalDataSources}, getDigitalDataSources] = useApiFetch(apiUrls.get.digitalDataSources())

  useEffect(() => {
    getDigitalDataProperties()
    getDigitalDataSources()
  }, [])

  const onRowChange = (index, value) => {
    let sources = cloneDeep(datasources)

    if (sources[index]) {
      sources[index] = value;
    }

    onChange(sources)
  }

  const deleteRow = (index) => {
    let sources = cloneDeep(datasources)

    sources.splice(index, 1);

    onChange(sources)
  }

  const addRow = () => {
    let sources = cloneDeep(datasources) || []

    sources.push({})

    onChange(sources)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'datasources-wrapper'}>
        <div>
          <div>
            {datasources && datasources.length > 0 &&
            <>
              {datasources.map((datasource, index) => (
                <div className={'datasource-wrapper'} key={index}>
                  <DataSourceRow
                    datasource={datasource}
                    properties={digitalDataProperties}
                    sources={digitalDataSources}
                    formFace={formFace}
                    onChange={value => onRowChange(index, value)}
                    isDisabled={isDisabled}
                  />
                  {!isDisabled &&
                    <button className={'datasource-delete'} onClick={() => deleteRow(index)}>
                      <i className="fal fa-trash-alt icon-delete"/>
                    </button>
                  }
                </div>
              ))}
            </>
            }

            {!isDisabled &&
            <a onClick={() => addRow()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default DataSourcesBlock;