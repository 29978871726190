import React, {useState, useEffect} from 'react';
import SubMenu from '../../../Components/SubMenu';
import MenuClient from '../../../Components/MenuClient';
import {toast} from 'react-toastify';
import i18n from "i18next";
import apiUrls from "../../../ApiUrls";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import Form from "./Form";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import useApiPost from "../../../Components/Hooks/useApiPost";

function Edit(props) {
  const [companyId] = useState(props.match.params.companyID)
  const [legalId] = useState(props.match.params.legalID)
  const [intervalId] = useState(props.match.params.intervalId)

  const [intervals] = useState([
    {
      label: 'Месяц',
      value: 'Месяц',
      code: 'month'
    },
    {
      label: 'Квартал',
      value: 'Квартал',
      code: 'quarter'
    },
    {
      label: 'Полугодие',
      value: 'Полугодие',
      code: 'halfyear'
    },
    {
      label: 'Год',
      value: 'Год',
      code: 'year'
    },
    {
      label: 'Иное',
      value: 'Иное',
      code: 'other'
    },
  ])

  const [formInterval, setFormInterval] = useState({
    contractor: `/legals/${legalId}`
  })

  const [inputErrors, setInputErrors] = useState({})

  const [{data: interval}, getInterval] = useApiFetch(
    apiUrls.get.repaymentInterval(intervalId)
  )

  const [{errors: updateIntervalErrors}, updateInterval] = useApiPatch(
    apiUrls.get.repaymentInterval,
    '',
    'clients.edit',
    () => {
      getInterval()
      toast.success(i18n.t('clients.edit.legal_repayment_changed'))
    }
  )

  const [{errors: addIntervalErrors}, addInterval] = useApiPost(
    apiUrls.get.repaymentIntervals(),
    '',
    'clients.edit',
    () => {
      toast.success(i18n.t('clients.edit.legal_repayment_created'))
      props.history.push(`/client/${companyId}/legal/${legalId}/repayment_intervals`)
    }
  )

  const isNew = () => intervalId === undefined

  useEffect(() => {
    if (!isNew()) {
      getInterval()
    }
  }, [])

  useEffect(() => {
    if (interval) {
      setFormInterval(interval)
    }
  }, [interval])

  useEffect(() => {
    if (addIntervalErrors) {
      setInputErrors(addIntervalErrors)
    } else if (updateIntervalErrors) {
      setInputErrors(updateIntervalErrors)
    } else {
      setInputErrors([])
    }
  }, [addIntervalErrors, updateIntervalErrors])

  const onClickSave = () => {
    setInputErrors([])

    const errors = {};

    if (!formInterval.dateStart) {
      errors.date_start = 'error'
    }

    if (!formInterval.dateEnd) {
      errors.date_end = 'error'
    }

    if (!formInterval.name) {
      errors.name = 'error'
    }

    if (Object.entries(errors).length) {
      setInputErrors(errors)
      return
    }

    isNew()
      ? addInterval(formInterval)
      : updateInterval(formInterval.id, formInterval)
  }

  let topTitle = isNew()
    ? i18n.t('clients.edit.add_legal_repayment')
    : i18n.t('clients.edit.edit_legal_repayment')

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu />
        <div className="table-edit-client">
          <div>
            <MenuClient title={topTitle}>
              <button className="button-accent" onClick={onClickSave}>
                {i18n.t('clients.edit.save')}
              </button>
            </MenuClient>
            <Form
              formInterval={formInterval}
              setFormInterval={setFormInterval}
              inputErrors={inputErrors}
              intervals={intervals}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;