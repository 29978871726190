import React, {useEffect} from 'react';
import {cloneDeep} from 'lodash'
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import FactorRow from "./FactorRow";

export default function FactorsBlock({factors, onChange, isDisabled}) {
  const [{data: digitalFactors}, getDigitalFactors] = useApiFetch(apiUrls.get.digitalFactors())

  useEffect(() => {
    getDigitalFactors()
  }, [])

  const onRowChange = (index, value) => {
    let digitalFactors = cloneDeep(factors)

    if (digitalFactors[index]) {
      digitalFactors[index] = value;
    }

    onChange(digitalFactors)
  }

  const deleteRow = (index) => {
    let digitalFactors = cloneDeep(factors)

    digitalFactors.splice(index, 1);

    onChange(digitalFactors)
  }

  const addRow = () => {
    let digitalFactors = cloneDeep(factors) || []

    digitalFactors.push({})

    onChange(digitalFactors)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'datasources-wrapper'}>
        <div>
          <div>
            {factors && factors.length > 0 &&
            <>
              {factors.map((factor, index) => (
                <div className={'datasource-wrapper'} key={index}>
                  <FactorRow
                    factor={factor}
                    digitalFactors={digitalFactors}
                    onChange={value => onRowChange(index, value)}
                    isDisabled={isDisabled}
                  />
                  {!isDisabled &&
                    <button className={'datasource-delete'} onClick={() => deleteRow(index)}>
                      <i className="fal fa-trash-alt icon-delete"/>
                    </button>
                  }
                </div>
              ))}
            </>
            }

            {!isDisabled &&
            <a onClick={() => addRow()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
            }

          </div>
        </div>
      </div>
    </div>
  )
}