import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import i18n from "i18next"
import apiUrls from "../../../../ApiUrls"
import useApiFetch from "../../../../Components/Hooks/useApiFetch"
import useApiPost from "../../../../Components/Hooks/useApiPost";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import MenuClient from "../../../../Components/MenuClient";
import {transliterate as tr} from "transliteration";

import FloatInput from "../../../../Components/Inputs/FloatInput";
import DatePicker from "react-datepicker";
import moment from "moment";
import {cloneDeep} from "lodash";
import SelectField from "./SelectField";
import {getFormattedDate, getUtcDate} from "../../../Advertising/Detail/Fact/Utils";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import {TvChannel} from "../../../../entities/channel/TvChannel";
import Protected from "../../../../Components/Roles/Protected";
import Select from "react-select";
import {InternetChannel} from "../../../../entities/channel/InternetChannel";

const dateFormat = 'Y-MM-DD'

const getDataToSave = (formCommission, channelEntity) => {
  let params = {
    dateEnd: formCommission.dateEnd ? getFormattedDate(formCommission.dateEnd, dateFormat) : null,
    dateStart: formCommission.dateStart ? getFormattedDate(formCommission.dateStart, dateFormat) : null,
    comment: formCommission.comment,
  }

  params.asPercent = formCommission.asPercent.value

  let value = formCommission.value
    ? (
      params.asPercent
        ? formCommission.value / 100
        : formCommission.value
    )
    : 0

  if (!params.asPercent) {
    value = parseFloat(parseFloat(value).toFixed(2))
  }

  params.value = value

  params.advertising = (formCommission.advertising && formCommission.advertising.value) ? formCommission.advertising.value : null
  params.channel = (formCommission.channel && formCommission.channel.value) ? formCommission.channel.value : null
  params.contractType = (formCommission.contractType && formCommission.contractType.value) ? formCommission.contractType.value : null
  params.clients = (formCommission.clients && formCommission.clients.length) ? formCommission.clients.map(client => client.value) : []

  if (formCommission.contractor && formCommission.contractor.value) {
    params.contractor = formCommission.contractor.value
  }

  const digitalChannel = ChannelFactory.create(DigitalChannel.getCode())
  const tvChannel = ChannelFactory.create(TvChannel.getCode())

  params[digitalChannel.platformsName] = []
  params[tvChannel.platformsName] = []

  const platformsName = channelEntity.platformsName
  const faceName = channelEntity.faceName

  if (faceName) {
    params[faceName] = (formCommission.face && formCommission.face.value) ? formCommission.face.value : null
  }

  if (platformsName) {
    params[platformsName] = (formCommission.platforms && formCommission.platforms.length)
      ? formCommission.platforms.map(platform => platform.value)
      : []
  }

  return params
}

function Edit(props) {
  const commissionId = parseInt(props.match.params.id)

  const advertisingId = parseInt(props.match.params.advertisingId)
  const channelCode = props.match.params.channel
  const faceId = parseInt(props.match.params.faceId)

  const isNew = () => isNaN(commissionId)

  const isFromFact = () => {
    return advertisingId && channelCode && faceId
  }

  const [formCommission, setFormCommission] = useState({})
  const [inputErrors, setInputErrors] = useState({})
  const [channelEntity, setChannelEntity] = useState(channelCode ? ChannelFactory.create(channelCode) : null)

  const [asPercentOptionMoney] = useState({
    value: false,
    label: i18n.t('commissions.edit.money')
  })

  const [asPercentOptionPercent] = useState({
    value: true,
    label: i18n.t('commissions.edit.percent')
  })

  const [asPercentOptions] = useState([
    asPercentOptionMoney,
    asPercentOptionPercent,
  ])

  const [asPercent, setAsPercent] = useState({})

  const [{data: contractorLegals}, getContractorLegals] = useApiFetch(apiUrls.get.contractorLegals());
  const [{data: repaymentIntervals}, getRepaymentIntervals] = useApiFetch(apiUrls.get.repaymentIntervals());

  const [{data: clients}, getClients] = useApiFetch(apiUrls.get.clients());

  const getPlatformClientsUrl = () => {
    if (channelEntity) {
      return channelEntity.getApiUrl('platformClients')
    } else if (channels) {
      const channel = channels.find(channel => channel['@id'] === formCommission.channel.value)

      if (channel && channel.code) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.getApiUrl('platformClients')
      }
    }

    return null
  }

  const getFacesUrl = () => {
    if (channelEntity) {
      return `${channelEntity.getApiUrl('faces')}`
    }

    return ''
  }

  const getFaceUrl = () => {
    if (channelCode) {
      const channelEntity = ChannelFactory.create(channelCode)

      return `${channelEntity.getApiUrl('faces')}/${faceId}`
    }

    return ''
  }

  const [{data: platformClients}, fetchPlatformClients] = useApiFetch(getPlatformClientsUrl);
  const [{data: selectedPlatformClients}, fetchSelectedPlatformClients] = useApiFetch(getPlatformClientsUrl);

  const [{data: face}, getFace] = useApiFetch(getFaceUrl);
  const [{data: faces}, getFaces] = useApiFetch(getFacesUrl);
  const [{data: advertisings}, getAdvertisings] = useApiFetch(apiUrls.get.adverts());
  const [{data: advertising}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId));
  const [{data: contractTypes}, getContractTypes] = useApiFetch(apiUrls.get.contractTypes());

  const [{data: commission}, getCommission] = useApiFetch(apiUrls.get.commission)
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())

  const [{errors: addErrors}, addCommission] = useApiPost(
    apiUrls.get.commissions(),
    '',
    'commissions.edit',
    (commissionId) => {
      toast.success(i18n.t('commissions.edit.added'))
      props.history.push(`/financial/commission/${commissionId}/edit`)
      getCommission({}, commissionId)
    }
  )

  const [{errors: updateErrors}, updateCommission] = useApiPatch(
    apiUrls.get.commission,
    '',
    'commissions.edit',
    (commissionId) => {
      toast.success(i18n.t('commissions.edit.changed'))
      getCommission({}, commissionId)
    }
  )

  useEffect(() => {
    if (!isNew()) {
      getCommission({}, commissionId)
    } else if (isFromFact()) {
      getFace({
        't[]': 'target'
      })
      getAdvertising({
        't[]': 'advert:read'
      })

      setAsPercent(asPercentOptionPercent)
    } else {
      setFormCommission({
        dateStart: new Date(),
        dateEnd: new Date(),
        contractor: null,
        client: null,
        platforms: [],
        advertising: null,
        face: null,
        value: 0,
      })

      setAsPercent(asPercentOptionPercent)
    }

    getContractorLegals()
    getClients()
    getChannels()
    getContractTypes()
  }, [])

  useEffect(() => {
    if (addErrors) {
      setInputErrors(addErrors)
    }

    if (updateErrors) {
      setInputErrors(updateErrors)
    }
  }, [addErrors, updateErrors])

  useEffect(() => {
    if (commission) {
      if (commission.channel && commission.channel.code) {
        const channelEntity = ChannelFactory.create(commission.channel.code)

        setChannelEntity(channelEntity)

        const formCommission = {
          dateStart: getUtcDate(commission.dateStart).toDate(),
          dateEnd: getUtcDate(commission.dateEnd).toDate(),
          value: commission.asPercent ? commission.value * 100 : commission.value,
          '@id': commission['@id'],
          id: commission.id,
          comment: commission.comment,
          clients: commission.clients.map(client => ({
            value: client['@id'],
            label: client.name
          })),
        }

        if (commission.channel && commission.channel['@id']) {
          formCommission.channel = {
            value: commission.channel['@id'],
            label: commission.channel.name,
            code: commission.channel.code,
          }
        } else {
          formCommission.channel = null
        }

        if (commission.contractType && commission.contractType['@id']) {
          formCommission.contractType = {
            value: commission.contractType['@id'],
            label: commission.contractType.name
          }
        } else {
          formCommission.contractType = null
        }

        if (commission.contractor && commission.contractor['@id']) {
          formCommission.contractor = {
            value: commission.contractor['@id'],
            label: commission.contractor.name
          }
        } else {
          formCommission.contractor = null
        }

        if (commission.advertising && commission.advertising['@id']) {
          formCommission.advertising = {
            value: commission.advertising['@id'],
            label: commission.advertising.name
          }
        } else {
          formCommission.advertising = null
        }

        if (commission[channelEntity.faceName] && commission[channelEntity.faceName]['@id']) {
          formCommission.face = {
            value: commission[channelEntity.faceName]['@id'],
            label: commission[channelEntity.faceName].name
          }
        } else {
          formCommission.face = null
        }

        if (commission[channelEntity.platformsName] && commission[channelEntity.platformsName].length) {
          formCommission.platforms = commission[channelEntity.platformsName].map(platform => {
            return {
              value: platform['@id'],
              label: platform.name
            }
          })
        } else {
          formCommission.platforms = []
        }

        if (commission.asPercent !== undefined) {
          const selectedAsPercent = asPercentOptions.find(option => option.value === commission.asPercent)

          setAsPercent(selectedAsPercent || {})
          formCommission.asPercent = selectedAsPercent
        }

        setFormCommission(formCommission)
      }
    }
  }, [commission])

  useEffect(() => {
    setFormCommission({
      ...formCommission,
      asPercent: asPercent
    })
  }, [asPercent])

  useEffect(() => {
    if (formCommission.channel && formCommission.channel.value && channels) {
      if (channels) {
        const channel = channels.find(channel => channel['@id'] === formCommission.channel.value)

        if (channel && channel.code) {
          setChannelEntity(ChannelFactory.create(channel.code))
        }
      }
    }

  }, [formCommission.channel, channels])

  useEffect(() => {
    if (formCommission.advertising && formCommission.platforms && formCommission.platforms.length === 1) {
      getFaces({
        't[]': 'name',
        'advertising.id': formCommission.advertising.value.split('/').pop(),
        'platform.id': formCommission.platforms[0].value.split('/').pop(),
      })
    }

    if (formCommission.platforms && formCommission.platforms.length !== 1) {
      setFormCommission({
        ...formCommission,
        face: null
      })
    }

  }, [formCommission.platforms, formCommission.advertising])

  useEffect(() => {
    if (isFromFact() && channels) {
      const selectedChannel = channels.find(channel => channel.code === channelCode)

      setFormCommission({
        ...formCommission,
        channel: {
          value: selectedChannel['@id'],
          label: selectedChannel.name
        }
      })
    }

  }, [channels])

  useEffect(() => {
    if (isFromFact() && clients && advertising && advertising.company) {
      const selectedClient = clients.find(client => client.id === advertising.company.id)

      setFormCommission({
        ...formCommission,
        clients: [{
          value: selectedClient['@id'],
          label: selectedClient.name
        }],
        advertising: {
          value: advertising['@id'],
          label: advertising.name
        },
        dateStart: getUtcDate(advertising.dateStart).toDate(),
        dateEnd: getUtcDate(advertising.dateEnd).toDate(),
      })
    }
  }, [clients, advertising])

  useEffect(() => {
    if (face) {
      const selectedContractor = face.contractor
       ? {
          value: face.contractor['@id'],
          label: face.contractor.name
        }
      : null

      const form = {
        ...formCommission,
        contractor: selectedContractor,
      }

      if (channelEntity) {
        form.face = {
          value: face['@id'],
          label: face.name,
        }
      }

      setFormCommission(form)
    }

  }, [face])

  useEffect(() => {
    if (isFromFact() && selectedPlatformClients) {
      const selectedPlatformClient = selectedPlatformClients.pop()

      if (selectedPlatformClient) {
        formCommission.platforms = [{
          value: selectedPlatformClient.platform['@id'],
          label: selectedPlatformClient.platform.name
        }]
      } else {
        formCommission.platforms = []
      }
    }

  }, [selectedPlatformClients])

  useEffect(() => {
    if (channelEntity && formCommission.contractor && formCommission.contractor.value) {
      fetchPlatformClients({
        't[]': 'platform_client',
        'contractor.id': formCommission.contractor.value.split('/').pop(),
        'order[platform.name]': 'asc',
        pagination: false
      })
    }
  }, [formCommission.contractor, channelEntity])

  useEffect(() => {
    if (channelEntity && face && formCommission.contractor && formCommission.contractor.value) {
      fetchSelectedPlatformClients({
        't[]': 'platform_client',
        'contractor.id': formCommission.contractor.value.split('/').pop(),
        'platform.id': face.platform.id,
        'order[platform.name]': 'asc',
        pagination: false
      })
    }
  }, [formCommission.contractor, channelEntity, face])

  useEffect(() => {
    if ((formCommission.clients && formCommission.clients.length) && formCommission.dateStart && formCommission.dateEnd) {
      getAdvertisings({
        pagination: false,
        'company.id[]': formCommission.clients.map(client => client.value.split('/').pop()),
        'dateStart[before]': moment.utc(formCommission.dateEnd).format(dateFormat),
        'dateEnd[after]': moment.utc(formCommission.dateStart).format(dateFormat),
      })
    }

  }, [formCommission.clients, formCommission.dateStart, formCommission.dateEnd])

  useEffect(() => {
    if (formCommission.contractor && formCommission.dateStart && formCommission.dateEnd) {
      getRepaymentIntervals({
        'contractor.id': formCommission.contractor.value.split('/').pop(),
        'dateStart[before]': moment.utc(formCommission.dateStart).format(dateFormat),
        'dateEnd[after]': moment.utc(formCommission.dateEnd).format(dateFormat),
      })
    }

  }, [formCommission.contractor, formCommission.dateStart, formCommission.dateEnd])

  const onDateChange = (prop, value) => {
    const offset = value ? value.getTimezoneOffset() : null

    if (offset < 0) {
      value.setHours(-offset / 60)
    }

    onChange(prop, value)
  }

  const onChange = (prop, value) => {
    const newForm = {
      ...formCommission,
      [prop]: cloneDeep(value)
    }

    if (prop === 'channel') {
      newForm.platforms = []
    }

    clearInputError(prop)

    setFormCommission(newForm)
  }

  const clearInputError = (name) => {
    const errors = cloneDeep(inputErrors)

    delete(errors[name])

    setInputErrors(errors)
  }

  const onClickSave = async () => {
    let errors = {}

    if (formCommission) {
      if ((asPercent && asPercent.value === asPercentOptionPercent.value) && formCommission.value < -100) {
        toast.error(i18n.t('commissions.edit.value_min_error'))
        errors.value = i18n.t('commissions.edit.value_min_error')
      }

      if ((asPercent && asPercent.value === asPercentOptionPercent.value) && formCommission.value > 100) {
        toast.error(i18n.t('commissions.edit.value_max_error'))
        errors.value = i18n.t('commissions.edit.value_max_error')
      }

      if (!formCommission.contractType || !formCommission.contractType.value) {
        toast.error(i18n.t('commissions.edit.empty_contract_type'))
        errors.contractType = i18n.t('commissions.edit.empty_contract_type')
      }

      if (!formCommission.contractor || !formCommission.contractor.value) {
        toast.error(i18n.t('commissions.edit.empty_contractor'))
        errors.contractor = i18n.t('commissions.edit.empty_contractor')
      }

      if (!formCommission.dateStart) {
        toast.error(i18n.t('commissions.edit.empty_date_start'))
        errors.dateStart = i18n.t('commissions.edit.empty_date_start')
      }

      if (!formCommission.dateEnd) {
        toast.error(i18n.t('commissions.edit.empty_date_end'))
        errors.dateEnd = i18n.t('commissions.edit.empty_date_end')
      }
    }

    if (
      asPercent.value === asPercentOptionMoney.value
    ) {
      if (!formCommission.clients || !formCommission.clients.length) {
        toast.error(i18n.t('commissions.edit.empty_clients'))
        errors.clients = i18n.t('commissions.edit.empty_clients')
      }

      if (!formCommission.advertising || !formCommission.advertising.value) {
        toast.error(i18n.t('commissions.edit.empty_advert'))
        errors.advertising = i18n.t('commissions.edit.empty_advert')
      }

      if (!formCommission.platforms || !formCommission.platforms.length) {
        toast.error(i18n.t('commissions.edit.empty_platform'))
        errors.platforms = i18n.t('commissions.edit.empty_platform')
      }
    }

    if (Object.keys(errors).length > 0) {
      setInputErrors(errors)
      return
    }

    isNew()
      ? addCommission(getDataToSave(formCommission, channelEntity))
      : updateCommission(formCommission.id, getDataToSave(formCommission, channelEntity))
  }

  let topTitle = isNew()
    ? i18n.t('commissions.edit.create_title')
    : i18n.t('commissions.edit.edit_title')

  const emptyOption = {
    label: i18n.t('projects.list.not_selected'),
    value: ''
  }

  const contractorOptions = contractorLegals
    ? contractorLegals.map(contractorLegal => ({value: contractorLegal['@id'], label: contractorLegal.name,}))
    : []

  const clientOptions = clients
    ? clients.map(client => ({value: client['@id'], label: client.name,}))
    : []

  const contractTypeOptions = contractTypes
    ? contractTypes.map(contractType => ({value: contractType['@id'], label: contractType.name,}))
    : []

  const platformOptions = platformClients
    ? platformClients.filter(platformClient => platformClient.platform && platformClient.platform.id)
      .map((platformClient) => ({value: platformClient.platform['@id'], label: platformClient.platform.name}))
    : []

  const faceOptions = faces
    ? faces.map(face => ({value: face['@id'], label: face.name,}))
    : []

  faceOptions.unshift(emptyOption)

  const advertisingOptions = advertisings
    ? advertisings.map(advertising => ({value: advertising['@id'], label: advertising.name,}))
    : []

  const channelOptions = channels
    ? channels
      .filter(channel => [DigitalChannel.getCode(), TvChannel.getCode(), InternetChannel.getCode()].includes(channel.code))
      .map(channel => ({value: channel['@id'], label: channel.name,}))
    : []

  advertisingOptions.unshift(emptyOption)

  const isAdvertFieldDisabled = () => {
    return !formCommission.dateStart
      || !formCommission.dateEnd
      || (!formCommission.clients || !formCommission.clients.length)
  }

  const getRepaymentIntervalsText = () => {
    if (repaymentIntervals && repaymentIntervals.length > 1) {
      return <>
        <div>{i18n.t('commissions.edit.wrong_repayment_intervals')}</div>
        {repaymentIntervals.map(interval => (
          <div>{moment.utc(interval.dateStart).format(i18n.t('date_format')) + ' - ' +
          moment.utc(interval.dateEnd).format(i18n.t('date_format'))}</div>
        ))}
      </>
    }

    if (repaymentIntervals && repaymentIntervals.length > 0 && repaymentIntervals[0].name) {
      return i18n.t('commissions.edit.repayment_interval') + ': ' + repaymentIntervals[0].name
    }

    return i18n.t('commissions.edit.repayment_interval') + ': ' + i18n.t('commissions.edit.repayment_interval_not_set')
  }

  return (
    <div className="row content flex" id={'edit-order'}>
      <div className="edit-client">
        <div className="table-edit-client">
          <div>
            <MenuClient title={topTitle}>
              {formCommission && formCommission.channel && formCommission.channel.value &&
                <Protected access={'FinancialCommissionsEdit'}>
                  <button className="button-accent" onClick={onClickSave}>
                    {i18n.t('commissions.edit.save')}
                  </button>
                </Protected>
              }
            </MenuClient>
            <table className="table redesign-table table-client">
              <tbody>
              <SelectField
                title={i18n.t('commissions.edit.channel')}
                options={channelOptions}
                selected={formCommission.channel}
                onChange={value => onChange('channel', value)}
              />

              {formCommission.channel && formCommission.channel.value &&
                <>
                  <tr>
                    <td>
                      <div className="flex-space date-group">
                        <div className="w-48">
                          {i18n.t('commissions.edit.dateStart')}
                        </div>
                        <div className="w-48">
                          {i18n.t('commissions.edit.dateEnd')}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex-space">
                        <div className="w-48">
                        <span className="input-wrap">
                          <DatePicker
                            className={inputErrors['dateStart'] ? 'has-error' : ''}
                            selected={formCommission ? formCommission.dateStart : ''}
                            onChange={value => onDateChange('dateStart', value)}
                            dateFormat={i18n.t('calendar_date_format')}
                            utcOffset={5}
                          />
                          {inputErrors['dateStart'] &&
                          <span className="title-error">
                            {inputErrors['dateStart']}
                          </span>
                          }
                        </span>
                        </div>

                        <div className="w-48">
                        <span className="input-wrap">
                          <DatePicker
                            className={inputErrors['dateEnd'] ? 'has-error' : ''}
                            selected={formCommission ? formCommission.dateEnd : ''}
                            onChange={value => onDateChange('dateEnd', value)}
                            dateFormat={i18n.t('calendar_date_format')}
                            utcOffset={5}
                          />
                          {inputErrors['dateEnd'] &&
                          <span className="title-error">
                            {inputErrors['dateEnd']}
                          </span>
                          }
                        </span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <SelectField
                    title={i18n.t('commissions.edit.contractor')}
                    className={'react-select-container select-pseudo-input ' + (inputErrors['contractor'] ? 'has-error' : '')}
                    options={contractorOptions}
                    selected={formCommission.contractor}
                    onChange={value => onChange('contractor', value)}
                  />

                  <tr>
                    <td>
                      <div className={'slim-text'}>{getRepaymentIntervalsText()}</div>
                    </td>
                  </tr>

                  <SelectField
                    title={i18n.t('commissions.edit.contract_type')}
                    options={contractTypeOptions}
                    className={'react-select-container select-pseudo-input ' + (inputErrors['contractType'] ? 'has-error' : '')}
                    selected={formCommission.contractType}
                    onChange={value => onChange('contractType', value)}
                  />

                  <SelectField
                    title={i18n.t('commissions.edit.clients')}
                    placeholder={i18n.t('commissions.list.all')}
                    options={clientOptions}
                    selected={formCommission.clients}
                    className={'react-select-container select-pseudo-input ' + (inputErrors['clients'] ? 'has-error' : '')}
                    isMulti={true}
                    onChange={values => {
                      onChange('clients', values)
                    }}
                  />

                  {channelEntity &&
                    <SelectField
                      title={i18n.t('commissions.edit.platforms')}
                      options={platformOptions}
                      className={'react-select-container select-pseudo-input ' + (inputErrors['platforms'] ? 'has-error' : '')}
                      selected={formCommission.platforms}
                      onChange={value => {
                        const copy = cloneDeep(value)
                        onChange('platforms', copy || [])
                      }}
                      isDisabled={!(formCommission.contractor && formCommission.contractor.value)}
                      isMulti={true}
                    />
                  }

                  <SelectField
                    title={i18n.t('commissions.edit.advertising')}
                    options={advertisingOptions}
                    className={'react-select-container select-pseudo-input ' + (inputErrors['advertising'] ? 'has-error' : '')}
                    selected={formCommission.advertising}
                    onChange={value => onChange('advertising', value)}
                    isDisabled={isAdvertFieldDisabled()}
                  />

                  <SelectField
                    title={i18n.t('commissions.edit.face')}
                    options={faceOptions}
                    selected={formCommission.face}
                    className={'react-select-container select-pseudo-input ' + (inputErrors['clients'] ? 'has-error' : '')}
                    onChange={values => {
                      onChange('face', values)
                    }}
                    isDisabled={(!formCommission.advertising || !formCommission.advertising.value)
                      || (!formCommission.platforms || !formCommission.platforms.length || formCommission.platforms.length !== 1)}
                  />

                  <tr>
                    <td>
                      {i18n.t('commissions.edit.value')}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="flex-space date-group">
                        <div className="w-48">
                          <FloatInput
                            value={asPercent.value ? (formCommission.value || '') : (formCommission.value || '')}
                            onChange={
                              (value) => onChange('value', value)
                            }
                            decimalScale={asPercent.value ? 4 : 2}
                            isValid={() => !inputErrors['value']}
                            allowNegative={true}
                          />
                        </div>
                        <div className="w-48">
                          <Select
                            className={"react-select-container select-pseudo-input"}
                            classNamePrefix="react-select"
                            options={asPercentOptions}
                            value={asPercent}
                            onChange={value => {
                              setAsPercent(value)
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {i18n.t('commissions.edit.comment')}
                    </td>
                  </tr>

                  <tr>
                    <td>
                  <textarea
                    rows={5}
                    value={formCommission.comment || ''}
                    onChange={
                      (e) => {
                        onChange('comment', e.target.value)
                      }
                    }
                  />
                    </td>
                  </tr>
                </>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;