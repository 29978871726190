import React, {useEffect, useState} from 'react';
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import {getFormattedDate} from "../Detail/Fact/Utils";

const dateFormat = 'DD.MM.YYYY';

function GeneralInfo({advertising}) {
  return (
    <div>
      {advertising &&
      <table className={'plan-general-info'}>
        <tbody>
        <tr>
          <td>Клиент</td>
          <td>{advertising.company.name}</td>
        </tr>
        <tr>
          <td>Продукт</td>
          <td>{advertising.product.name}</td>
        </tr>
        <tr>
          <td>Сроки</td>
          <td>{getFormattedDate(advertising.dateStart, dateFormat)} - {getFormattedDate(advertising.dateEnd, dateFormat)}</td>
        </tr>
        <tr>
          <td>Бюджет</td>
          <td>{advertising.budget}</td>
        </tr>
        </tbody>
      </table>
      }
    </div>
  )
}

export default GeneralInfo