import React, {Fragment} from 'react';
import SumRowCell from "./SumRowCell";

function SumRow({properties, values, title, titleColSpan = 1, cellsAfterTitle,
                  cellsBeforeTitle, cellsAfterProps, cellEmptyValue}) {
  return (
    <tr className={'sum-values-row'}>
      {cellsBeforeTitle && cellsBeforeTitle}
      <td colSpan={titleColSpan}><span>{title}</span></td>
      {cellsAfterTitle && cellsAfterTitle}

      {properties && properties.map(property => (
        <Fragment key={property.code}>
          {
            property.code !== 'return' &&
              <SumRowCell
                value={values[property.code]}
                type={property.type}
                cellEmptyValue={cellEmptyValue}
              />
          }

          {property.code === 'return' &&
          <td/>
          }
        </Fragment>
      ))}

      {cellsAfterProps && cellsAfterProps}
    </tr>
  )
}

export default SumRow;