import {parseJwt} from "../utils";

export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_KEYCLOAK = 'SET_KEYCLOAK'
export const SET_ERROR = 'ADD_ERROR'
export const SET_ERRORS = 'SET_ERRORS'
export const DELETE_ERROR = 'DELETE_ERROR'
export const DELETE_ERRORS = 'DELETE_ERRORS'
export const ADD_SUBSCRIBED = 'ADD_SUBSCRIBED'

const ssoClientId = process.env.REACT_APP_SSO_CONFIG_CLIENT_ID

export const setUserData = function (token) {
  const tokenData = parseJwt(token)

  let tokenRoles = (
    tokenData.roles ? tokenData.roles :
      (
        (
          tokenData.resource_access && tokenData.resource_access[ssoClientId]
          && tokenData.resource_access[ssoClientId].roles
        )
        ? tokenData.resource_access[ssoClientId].roles
        : []
      )
  )

  const user = {
    username: tokenData.preferred_username || tokenData.name || '',
    roles: tokenRoles
  }

  return {
    type: SET_USER_DATA,
    user
  }
}

export const setErrors = (errors) => {
  return {
    type: SET_ERRORS,
    errors
  }
}

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error
  }
}

export const deleteError = (key) => {
  return {
    type: DELETE_ERROR,
    key
  }
}

export const deleteErrors = () => {
  return {
    type: DELETE_ERRORS
  }
}

export const addSubscribed = (url) => {
  return {
    type: ADD_SUBSCRIBED,
    url
  }
}
