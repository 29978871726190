import React, {useState} from 'react';
import {getColumnDataFromTableText, getRowDataFromTableText} from "../../../../utils";
import {cloneDeep} from 'lodash'
import PasteForm from "../components/PasteForm";

export default function CopyFromTableForm({plan, setPlan, properties,
                                            selectedDetailToPasteTable, setSelectedDetailToPasteTable,
                                            selectedIntervalToPasteTable, setSelectedIntervalToPasteTable,
                                            selectedFieldToPasteTable, setSelectedFieldToPasteTable
                                          }) {
  const [textToPasteTable, setTextToPasteTable] = useState(null)

  const pasteFromTable = () => {
    if ((selectedDetailToPasteTable === null && selectedFieldToPasteTable === null)
      || selectedIntervalToPasteTable === null
      || textToPasteTable === null
    ) {
      return
    }

    if (selectedFieldToPasteTable !== null) {
      const data = getColumnDataFromTableText(textToPasteTable, properties, selectedFieldToPasteTable)

      setColumnDetailValues(data)
    } else if (selectedDetailToPasteTable !== null) {
      const data = getRowDataFromTableText(textToPasteTable)

      setRowDetailValues(data)
    }

    setTextToPasteTable(null)
    setSelectedDetailToPasteTable(null)
    setSelectedFieldToPasteTable(null)
    setSelectedIntervalToPasteTable(null)
  }

  const getPropertiesCode = () => {
    return properties.map(field => field.code)
  }

  const setRowDetailValues = (values) => {
    if (values && values.length && plan[selectedIntervalToPasteTable]) {
      const formPlan = cloneDeep(plan)
      const formPlanPeriod = formPlan[selectedIntervalToPasteTable]

      if (formPlanPeriod.data && formPlanPeriod.data.length ) {
        for (let [rowIndex, row] of values.entries()) {
          let detailIndex = +selectedDetailToPasteTable + rowIndex;
          let fieldIndex = 0;

          if (formPlanPeriod.data[detailIndex]) {
            let detail = formPlanPeriod.data[detailIndex]

            for (let field of getPropertiesCode()) {
              if (row[fieldIndex] === undefined) {
                break
              } else {
                if (detail.properties[field] && detail.properties[field].editable) {
                  detail.properties[field].value = row[fieldIndex]
                  fieldIndex++;
                }
              }
            }
          }
        }

        setPlan(formPlan)
      }
    }
  }

  const setColumnDetailValues = (values) => {//todo
    if (values && typeof values === 'object') {
      const formPlan = cloneDeep(plan)
      const formPlanPeriod = formPlan[selectedIntervalToPasteTable]

      if (formPlanPeriod.data && formPlanPeriod.data.length ) {
        for (let field in values) {
          for (let [valIndex, val] of values[field].entries()) {
            if (formPlanPeriod.data[valIndex]) {
              const face = formPlanPeriod.data[valIndex]

              if (face.properties.hasOwnProperty(field)) {
                face.properties[field].value = val
              }
            }
          }
        }

        setPlan(formPlan)
      }
    }
  }

  return (
    <>
      {(selectedDetailToPasteTable !== null || selectedFieldToPasteTable !== null) &&
        <PasteForm
          value={textToPasteTable || ''}
          setTextToPasteTable={setTextToPasteTable}
          setSelectedDetailToPasteTable={setSelectedDetailToPasteTable}
          setSelectedFieldToPasteTable={setSelectedFieldToPasteTable}
          paste={pasteFromTable}
        ></PasteForm>
      }
    </>

  )
}