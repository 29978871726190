import React from 'react';

function Footer({children}) {
  return (
    <footer className="detail-footer">{
      children
    }</footer>
  )
}

export default Footer