import React from 'react';
import i18n from "i18next";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import i18next from "i18next";
import FormattedNumber from "../../../../Components/FormattedNumber";
import SortIcon from "../../../../Components/SortIcon";
import {getFormattedDate} from "../../../Advertising/Detail/Fact/Utils";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import Protected from "../../../../Components/Roles/Protected";

const getCommissionValue = commission => {
  return commission.asPercent ? (commission.value * 100) : commission.value
}

const getFaceFieldName = commission => {
  const channelEntity = ChannelFactory.create(commission.channel.code)

  return channelEntity.faceName
}

const getPlatformsFieldName = commission => {
  const channelEntity = ChannelFactory.create(commission.channel.code)

  return channelEntity.platformsName
}

function Table({commissions, onDelete, onSortClick, sort}) {
  return (
    <table className="table redesign-table table-client goal-detail mediaplans">
      <thead>
      <tr>
        <th>
          <div className={'sort ' + (sort['id'] || '')}
            onClick={() => onSortClick('id')}
          >
            <span>{i18n.t('commissions.list.id')}</span>
            <SortIcon
              name={'id'}
              sort={sort}
            />
          </div>
        </th>
        <th>
          <div className={'sort ' + (sort['dateStart'] || '')}
            onClick={() => onSortClick('dateStart')}
          >
            <span>{i18n.t('commissions.list.dates')}</span>
            <SortIcon
              name={'dateStart'}
              sort={sort}
            />
          </div>
        </th>
        <th>{i18n.t('commissions.list.contractor')}</th>
        <th>{i18n.t('commissions.list.contractType')}</th>
        <th>{i18n.t('commissions.list.clients')}</th>
        <th>{i18n.t('commissions.list.channel')}</th>
        <th>{i18n.t('commissions.list.platform')}</th>
        <th>{i18n.t('commissions.list.face')}</th>
        <th>{i18n.t('commissions.list.advertising')}</th>
        <th>{i18n.t('commissions.list.value')}</th>
        <th>{i18n.t('commissions.list.measure')}</th>
        <th>{i18n.t('commissions.list.comment')}</th>
        <th>{i18n.t('commissions.list.repayment_interval')}</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {commissions && commissions.length> 0 && commissions.map(commission => (
        <tr key={commission.id}>
          <td>
            <Link to={`/financial/commission/${commission.id}/edit`}>
              {commission.id}
            </Link>
          </td>
          <td>
            <Link to={`/financial/commission/${commission.id}/edit`}>
              {getFormattedDate(commission.dateStart)} - {getFormattedDate(commission.dateEnd)}
            </Link>
          </td>
          <td>
            <Link to={`/financial/commission/${commission.id}/edit`}>
              {commission.contractor ? commission.contractor.name : ''}
            </Link>
          </td>
          <td>
            {commission.contractType ? commission.contractType.name : ''}
          </td>
          <td>
            {commission.clients && commission.clients.length > 0 &&
            commission.clients.map(client => (
              <div key={client.id}>
                  {client ? client.name : ''}
              </div>
            ))
            }

            {(!commission.clients || commission.clients.length === 0) &&
              <Link to={'/financial/commission/' + commission.id }>
                {i18n.t('commissions.list.all')}
              </Link>
            }

          </td>
          <td>
            {commission.channel ? commission.channel.name : ''}
          </td>
          <td>{commission[getPlatformsFieldName(commission)] && commission[getPlatformsFieldName(commission)].length > 0 &&
            commission[getPlatformsFieldName(commission)].map(platform => (
              <div key={platform.id}>{platform.name}</div>
            ))}
          </td>
          <td>{commission[getFaceFieldName(commission)] ? commission[getFaceFieldName(commission)].name : ''}</td>
          <td>{commission.advertising ? commission.advertising.name : ''}</td>
          <td>
            <FormattedNumber
              value={getCommissionValue(commission)}
              type={'float'}
              fractions={commission.asPercent ? 4 : 2}
            />
          </td>
          <td>{commission.asPercent ? ' %' : ' ' + i18next.t('commissions.list.currency')}</td>
          <td>{commission.comment}</td>
          <td>{(commission.repaymentInterval && commission.repaymentInterval.name)
            ? commission.repaymentInterval.name
            : ''
          }</td>
          <td>
            <Protected access={'FinancialCommissionsEdit'}>
              <button onClick={() => onDelete(commission.id)} className="btn-delete-client btn-icon"
                title={i18next.t('commissions.list.delete')}>
                <i className="fal fa-trash-alt" />
              </button>
            </Protected>
          </td>
        </tr>
      ))}
      {!commissions || !commissions.length &&
        <tr>
          <td colSpan={7}>{i18n.t('commissions.list.empty_list')}</td>
        </tr>
      }
      </tbody>
    </table>
  )
}

export default Table;
