import React from 'react';
import i18n from "i18next";

function AdvertisingWritingErrors({title, errors}) {
  return (
    <div className={'advertising-writing-errors'}>
      <div className={'advertising-writing-errors__title'}>{title}</div>
      <div className={'advertising-writing-errors__list'}>
        {errors.map(error => (
          <div className={'advertising-writing-errors__advert'} key={error.message}>
            <div className={'advertising-writing-errors__advert-title'}>
              {i18n.t('financial.advert')}: {error.advertisingName}
            </div>
            <div className={'advertising-writing-errors__advert-error'}>
              {error.message}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdvertisingWritingErrors
