import {Channel} from "./Channel";

export class DigitalChannel extends Channel {
  static code = 'digital'
  code = 'digital'
  contractorFilter = 'digitalFaces.contractor.id'
  faceName = 'digitalFace'
  facesName = 'digitalFaces'
  platformName = 'digitalPlatform'
  platformsName = 'digitalPlatforms'
  propertiesName = 'digitalProperties'
  faceAdvertisingCompanyIdFilter = 'digitalFd.face.advertising.company.id'
  contractorCheckRowFilter = 'digitalFd.face.id[]'

  apiUrls = {
    faces: '/digital_faces',
    channels: '/digital_channels',
    sources: '/digital_sources',
    platforms: '/digital_platforms',
    subTypes: '/digital_face_types',
    strategies: '/digital_strategies',
    formats: '/digital_face_formats',
    units: '/digital_units',
    ratios: '/digital_ratios',
    fms: '/digital_fms',
    corrections: '/digital_corrections',
    platformClients: '/digital_platform_clients',
    properties: '/digital_properties',
    faceCommission: '/digital_face_commission',
    faceCommissions: '/digital_face_commissions',
    totalPlan: `/data/plan/${this.code}/faces`,
    totalFact: `/data/total/${this.code}/faces`
  }
}