import React, {useState, useEffect, useRef} from 'react';
import MenuClient from '../../../Components/MenuClient';
import Select from 'react-select';
import {cloneDeep} from 'lodash';
import Input from '../../../Components/Inputs/Input';
import DatePicker from "react-datepicker";
import {toast} from 'react-toastify';
import moment from 'moment';
import {Link} from 'react-router-dom';

import i18n from "i18next";
import FaceTable from "./FaceTable";
import apiUrls from "../../../ApiUrls";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import useApiPost from "../../../Components/Hooks/useApiPost";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {getUtcDate} from "../../Advertising/Detail/Fact/Utils";
import Protected from "../../../Components/Roles/Protected";
import Loader from "../../../Components/Loader/Loader";
import FloatInput from "../../../Components/Inputs/FloatInput";

const dateFormat = 'YYYY-MM-DD'

const getNumber = numberValue => {
  const number = parseFloat(numberValue)

  if (isNaN(number)) {
    return 0
  }

  return number
}

const getDataToSave = formInvoice => {
  const data = {}

  if (formInvoice.id) {
    data.id = formInvoice.id
  }

  data.budget = getNumber(formInvoice.budget)
  data.budgetTax = getNumber(formInvoice.budgetTax)
  data.tax = getNumber(formInvoice.tax)
  data.name = formInvoice.name
  data.client = formInvoice.client.value
  data.channel = formInvoice.channel.value
  data.legalFrom = formInvoice.legalFrom.value
  data.dateStart = getUtcDate(formInvoice.dateStart).format(dateFormat)
  data.dateEnd = getUtcDate(formInvoice.dateEnd).format(dateFormat)
  data.dateSigning = getUtcDate(formInvoice.dateSigning).format(dateFormat)

  return data
}

function InvoiceEdit(props) {
  const invoiceId = props.match.params.id
  const [inputErrors, setInputErrors] = useState({})
  const [formInvoice, setFormInvoice] = useState({})
  const [isDeleting, setIsDeleting] = useState(false)
  const firstUpdate = useRef(true);
  const [{data: invoice}, getInvoice] = useApiFetch(apiUrls.get.clientInvoice)
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: clients}, getClients] = useApiFetch(apiUrls.get.clients())
  const [{data: legals}, getLegals] = useApiFetch(apiUrls.get.legals())
  const [{
    data: clientCheckRows,
    isLoading: isClientChecksLoading
  }, getClientCheckRows] = useApiFetch(apiUrls.get.clientCheckRows())

  const [{errors: addErrors}, addInvoice] = useApiPost(
    apiUrls.get.clientInvoices(),
    '',
    'invoice.edit',
    (invoiceId) => {
      toast.success(i18n.t('invoice.edit.created'))
      props.history.push(`/financial/client_invoices/${invoiceId}`)
      getInvoice({}, invoiceId)
    }
  )

  const [{errors: updateErrors}, updateInvoice] = useApiPatch(
    apiUrls.get.clientInvoice,
    '',
    'invoice.edit',
    (invoiceId) => {
      toast.success(i18n.t('invoice.edit.updated'))
    }
  )

  const [{errors: updateClientCheckRowErrors}, updateClientCheckRow] = useApiPatch(
    apiUrls.get.clientCheckRow,
    '',
    'errors',
  )

  const deleteRow = async row => {
    setIsDeleting(true)
    const id = row['@id'].split('/').pop()
    await updateClientCheckRow(id, {
      'clientInvoice': null,
    }, {}, () => {
      setIsDeleting(false)
      getClientCheckRows(getClientCheckRowsParams())
    })
  }

  const isNew = () => {
    return invoiceId === undefined
  }

  useEffect(() => {
    getClients()
    getChannels()
    getLegals({
      't[]': 'legals_taxes',
    })

    if (!isNew()) {
      getInvoice({}, invoiceId)
      getClientCheckRows(getClientCheckRowsParams())
    } else {
      const date = new Date()
      formInvoice.dateStart = moment.utc(date).startOf('month').toDate()
      formInvoice.dateEnd = moment.utc(date).endOf('month').toDate()
      formInvoice.dateSigning = moment.utc(date).toDate()
    }
  }, [])

  useEffect(() => {
    if (invoice) {
      const formInvoice = cloneDeep(invoice)
      formInvoice.client = {
        value: invoice.client["@id"],
        label: invoice.client.name,
      }

      formInvoice.channel = {
        value: invoice.channel["@id"],
        label: invoice.channel.name,
      }

      formInvoice.legalFrom = {
        value: invoice.legalFrom["@id"],
        label: invoice.legalFrom.name,
      }

      setFormInvoice(formInvoice)
    }
  }, [invoice])

  useEffect(() => {
    if (formInvoice.budget && formInvoice.legalFrom && formInvoice.legalFrom.value && legals) {
      if (!isNew() && firstUpdate.current) {
        firstUpdate.current = false
        return
      }

      const legal = legals.find(legal => legal['@id'] === formInvoice.legalFrom.value)

      if (legal && legal.tax) {
        const invoiceTax = (formInvoice.budget * Number(legal.tax.tax) / 100)
        const invoiceBudgetWithTax = formInvoice.budget + invoiceTax

        setFormInvoice({
          ...formInvoice,
          tax: invoiceTax,
          budgetTax: invoiceBudgetWithTax,
        })
      }
    }
  }, [formInvoice.budget, formInvoice.legalFrom, legals])

  const getClientCheckRowsParams = () => {
    return {
      'clientInvoice.id': invoiceId,
      't[]': ['face_contractor', 'name', 'legals_taxes'],
    }
  }

  const onChange = (value, name) => {
    setFormInvoice({
      ...formInvoice,
      [name]: value
    })

    setInputErrors(inputErrors => ({
      ...inputErrors,
      [name]: false,
    }))
  }

  const onDateChange = (date) => {
    const dateStart = moment.utc(date).startOf('month').toISOString()
    const dateEnd = moment.utc(date).endOf('month').toISOString()

    setFormInvoice({
      ...formInvoice,
      dateStart,
      dateEnd
    })
  }

  const isEmptyFields = () => {
    const formFields = ['dateStart', 'dateSigning', 'client', 'name', 'channel', 'legalFrom']

    const inputErrors = {}

    for (let field of formFields) {
      if (!formInvoice[field]) {
        inputErrors[field] = true
      }
    }

    setInputErrors(inputErrors)

    return Object.values(inputErrors).length > 0
  }

  const isNotEndedPeriod = () => {
    const currentDate = moment.utc().startOf('month').toDate()
    const invoiceDate = moment.utc(formInvoice.dateStart)

    if (invoiceDate >= currentDate) {
      setInputErrors(inputErrors => ({
        ...inputErrors,
        'dateStart': true,
      }))
    }

    return invoiceDate >= currentDate
  }

  const onInvoiceAdd = () => {
    if (isEmptyFields()) {
      toast.error(i18n.t('clients.edit.empty_fields'));
      return
    }

    if (isNotEndedPeriod()) {
      toast.error(i18n.t('invoice.edit.not_ended_period_add'));
      return
    }

    try {
      addInvoice(getDataToSave(formInvoice))
    } catch (error) {
      toast.error(i18n.t('invoice.edit.save_fail'))
    }
  }

  const onInvoiceUpdate = () => {
    if (isEmptyFields()) {
      toast.error(i18n.t('clients.edit.empty_fields'));
      return
    }

    if (isNotEndedPeriod()) {
      toast.error(i18n.t('invoice.edit.not_ended_period_update'));
      return
    }

    try {
      updateInvoice(invoiceId, getDataToSave(formInvoice))
    } catch (error) {
      toast.error(i18n.t('invoice.edit.edit_fail'))
    }
  }

  const onSaveClick = () => {
    if (isNew()) {
      onInvoiceAdd()
    } else {
      onInvoiceUpdate()
    }
  }

  const isRows = () => {
    return invoice && clientCheckRows && clientCheckRows.length > 0
  }

  const getTitle = () => {
    return isNew()
      ? i18n.t('invoice.edit.add')
      : i18n.t('invoice.edit.edit')
  }

  const getOptions = options => {
    return options
      ? options.map((option) => {
        return {value: option["@id"], label: option.name}
      })
      : []
  }

  const isAnyLoading = () => isClientChecksLoading || isDeleting

  return (
    <div className="row content flex">
      <div className="edit-client edit-invoice">
        <div className="table-edit-client advertising-table">
          <MenuClient title={getTitle()}>
            <Protected access={'FinancialInvoicesEdit'}>
              <button className="button-accent" onClick={onSaveClick}>
                {i18n.t('clients.edit.save')}
              </button>
            </Protected>
            {formInvoice &&
              <Link
                className="blue-cursor-button back-button"
                to={`/financial/client_invoices?date=${getUtcDate(formInvoice.dateStart).format(dateFormat)}`}
              >{i18n.t('invoice.edit.back')}</Link>
            }
          </MenuClient>
          <div className={isAnyLoading() ? 'loading' : ''}>
            <table className="table redesign-table table-client">
              <tbody>
              <tr>
                <td>
                  {i18n.t('invoice.list.client')}
                </td>
              </tr>
              <tr>
                <td>
                  <Select
                    className={'react-select-container select-pseudo-input ' + (inputErrors['client'] ? 'has-error' : '')}
                    classNamePrefix="react-select"
                    options={getOptions(clients)}
                    placeholder={i18n.t('clients.edit.select')}
                    value={formInvoice.client}
                    onChange={value => onChange(value, 'client')}
                    /*isDisabled={!this.isNew}*/ />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.date_period')}
                </td>
              </tr>
              <tr>
                <td>
                    <span className="input-wrap">
                      <DatePicker
                        className={inputErrors['dateStart'] ? 'has-error' : ''}
                        selected={moment.utc(formInvoice.dateStart).toDate()}
                        onChange={onDateChange}
                        showMonthYearPicker
                        dateFormat="MM.yyyy"
                      /></span>
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('clients.edit.name')}
                </td>
              </tr>
              <tr>
                <td>
                  <Input
                    className={inputErrors['name'] ? 'has-error' : ''}
                    value={formInvoice.name || ''}
                    onChange={value => onChange(value, 'name')}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.channel')}
                </td>
              </tr>
              <tr>
                <td>
                  <Select
                    className={'react-select-container select-pseudo-input ' + (inputErrors['channel'] ? 'has-error' : '')}
                    classNamePrefix="react-select"
                    options={getOptions(channels)}
                    placeholder={i18n.t('clients.edit.select')}
                    value={formInvoice.channel}
                    onChange={value => onChange(value, 'channel')}
                    />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.legal')}
                </td>
              </tr>
              <tr>
                <td>
                  <Select
                    className={'react-select-container select-pseudo-input ' + (inputErrors['legalFrom'] ? 'has-error' : '')}
                    classNamePrefix="react-select"
                    options={getOptions(legals)}
                    placeholder={i18n.t('clients.edit.select')}
                    value={formInvoice.legalFrom}
                    onChange={value => onChange(value, 'legalFrom')}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.document_date')}
                </td>
              </tr>
              <tr>
                <td>
                    <span className="input-wrap">
                      <DatePicker
                        selected={moment.utc(formInvoice.dateSigning).toDate()}
                        onChange={value => onChange(value, 'dateSigning')}
                        dateFormat="dd.MM.yyyy"
                      /></span>
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.sum_without_vat')}
                </td>
              </tr>
              <tr>
                <td>
                  <FloatInput
                    value={formInvoice.budget}
                    onChange={value => onChange(value, 'budget')}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.sum_vat')}
                </td>
              </tr>
              <tr>
                <td>
                  <FloatInput
                    value={formInvoice.tax}
                    onChange={value => onChange(value, 'tax')}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {i18n.t('invoice.list.sum_with_vat')}
                </td>
              </tr>
              <tr>
                <td>
                  <FloatInput
                    value={formInvoice.budgetTax}
                    onChange={value => onChange(value, 'budgetTax')}
                  />
                </td>
              </tr>
              </tbody>
            </table>

            {!isNew() &&
              <h3>{i18n.t('invoice.edit.document_faces')}</h3>
            }

            {isAnyLoading() &&
              <Loader />
            }

            {clientCheckRows && clientCheckRows.length > 0 && invoice &&
              <FaceTable
                rows={clientCheckRows}
                deleteRow={deleteRow}
              />
            }

            {!isNew() && !isRows() &&
              <span>{i18n.t('invoice.edit.empty_faces')}</span>
            }

            <Protected access={'FinancialInvoicesEdit'}>
              {!isNew() && invoice &&
                <div>
                  <Link
                    to={`/financial/faces-without-invoice/${invoice.id}`}
                  >{i18n.t('invoice.edit.add_faces')}</Link>
                </div>
              }
            </Protected>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceEdit;
