import React, {Fragment, useEffect, useState} from 'react';
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {dayInterval, monthInterval, weekInterval, wholeInterval} from "../Detail/Plan/components/dateIntervals";
import {getFormattedDate} from "../Detail/Fact/Utils";
import i18n from "i18next";
import RadioGroup from "../../../Components/Inputs/RadioGroup";
import TableHeader from "../Detail/components/Table/Header";
import SumRow from "../Detail/components/Table/SumRow";
import Row from "../Detail/components/Table/Row";
import PeriodTitle from "../Detail/components/Table/PeriodTitle";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../Components/Loader/Loader";
import PlanRowCell from "./PlanRowCell";
import Select from "react-select";
import DigitalForm from "../Detail/Faces/DigitalForm";
import {cloneDeep} from "lodash";
import Protected from "../../../Components/Roles/Protected";
import moment from "moment";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import {FaceFactory} from "../../../entities/face/FaceFactory";

const dateIntervals = [wholeInterval, monthInterval, weekInterval, dayInterval]
const dateFormat = 'YYYY-MM-DD';
const round = value => Math.round((value + Number.EPSILON) * 100) / 100
const propsToLoad = [
  'budget',
  'quantity',
  'impressions',
  'clicks',
  'views',
  'conversions',
  'reach_tech',
  // 'reach_unique',
  // 'audience',
  'quartile25',
  'quartile50',
  'quartile75',
  'quartile100'
]
const calculatedProperties = [
  {
    name: 'CPM',
    code: 'cpm',
    type: 'float'
  },
  {
    name: 'CPV',
    code: 'cpv',
    type: 'float'
  },
  {
    name: 'CPC',
    code: 'cpc',
    type: 'float'
  },
  {
    name: 'CTR, %',
    code: 'ctr',
    type: 'float'
  },
  {
    name: 'VTR, %',
    code: 'vtr',
    type: 'float'
  },
  {
    name: 'CPA',
    code: 'cpa',
    type: 'float'
  },
]

const notCalculateSumValuesForCodes = [
  'cpm', 'cpv', 'cpc', 'ctr', 'vtr', 'cpa'
];

function Plan({advertisingId, advertising}) {
  const [channelEntity, setChannelEntity] = useState(ChannelFactory.create('digital'))
  const [selectedInterval, setSelectedInterval] = useState(wholeInterval)
  const [selectedProperties, setSelectedProperties] = useState(null)
  const [selectedProps, setSelectedProps] = useState(null)
  const [selectedFace, setSelectedFace] = useState(null)
  const [plan, setPlan] = useState([])
  const [isPlanSaving, setIsPlanSaving] = useState(null)
  const [isFacePlanSaving, setIsFacePlanSaving] = useState(null)

  const [{data: properties}, getProperties] = useApiFetch(channelEntity.getApiUrl('properties') + '?entity=face')
  const [{data: faces, isLoading: isFacesLoading}, getFaces] = useApiFetch(channelEntity.getApiUrl('faces'))
  const [{data: planData, isLoading: isPlanDataLoading}, getPlanData] = useApiFetch(apiUrls.get.advertisingPlanData(channelEntity.getCode()))
  const [{data: ctrs, isLoading: isCtrsLoading}, getCtrs] = useApiFetch(apiUrls.get.digitalCtrs())
  const [{data: vtrs, isLoading: isVtrsLoading}, getVtrs] = useApiFetch(apiUrls.get.digitalVtrs())

  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(
    `/data/plan/${channelEntity.code}/faces`,
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising',
    (params) => {
      toast.success(i18n.t('clients.advertising.correction_updated'));
      //getCorrections(params)
      getPlanData(params)
      setIsPlanSaving(false)
      setIsFacePlanSaving(false)
    }
  )

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (properties) {
      const selectedProps = properties.filter(property => propsToLoad.includes(property.code))
      setSelectedProps(selectedProps.map(property => ({
        value: property.code,
        label: property.name,
      })))
    }
  }, [properties])

  useEffect(() => {
    if (faces) {
      const platformsIds = faces.map(face => face.platform.id)
      const params = {
        'platform.id[]': platformsIds,
        dateStart: moment.utc(advertising.dateStart).format('Y-MM-DD')
      }
      getCtrs(params)
      getVtrs(params)
    }
  }, [faces])

  useEffect(() => {
    getAdvertFaces()
  }, [planData])

  useEffect(() => {
    if (planData && ctrs && vtrs && faces) {
      const facePlatforms = {}
      for (let face of faces) {
        facePlatforms[face['@id']] = face.platform['@id']
      }

      const plan = cloneDeep(planData)

      for (let period of plan) {
        for (let face of period.data) {
          const props = face.properties
          props.cpm = {
            editable: false,
            value: props.impressions && props.impressions.value ? 1000 * (props.budget.value / props.impressions.value) : 0// стоимость (цена) 1000 показов
          }

          props.cpv = {
            editable: false,
            value: props.views && props.views.value ? (props.budget.value / props.views.value) : 0
          }

          props.cpc = {
            editable: false,
            value: props.clicks && props.clicks.value ? (props.budget.value / props.clicks.value) : 0
          }

          props.ctr = {
            editable: false,
            value: props.impressions && props.impressions.value && props.clicks && props.clicks.value
              ? 100 * (props.clicks.value/props.impressions.value)  // %
              : 0
          }

          props.vtr = {
            editable: false,
            value: props.impressions && props.impressions.value && props.views && props.views.value
              ? 100 * (props.views.value/props.impressions.value):
              0
          }

          props.cpa = {
            editable: false,
            value: props.conversions && props.conversions.value ? (props.budget.value / props.conversions.value) : 0// стоимость (цена) 1000 показов
          }

          // const facePlatformId = facePlatforms[face.item]

          const faceData = faces.find(item => item['@id'] === face.item)

          if (faceData && faceData.frequency) {
            props.reach_calc = {
              editable: false,
              value: props.views && props.views.value ? props.views.value / faceData.frequency : 0
            }
            props.unique_reach = {
              editable: false,
              value: props.reach_calc && props.reach_calc.value ? props.reach_calc.value * 0.55 : 0
            }
          }
        }
      }

      setPlan(plan)
    }
  }, [planData, ctrs, vtrs, faces])

  useEffect(() => {
    if (properties) {
      let selectedProperties = (selectedProps && selectedProps.length)
        ? properties.filter(property => selectedProps.map(property => property.value).includes(property.code))
        : []

      if (!selectedInterval || selectedInterval.value !== dayInterval.value) {
        selectedProperties = selectedProperties.concat(calculatedProperties)
      }
      setSelectedProperties(selectedProperties)
    }
  }, [selectedProps])

  const fetchData = () => {
    const props = properties.filter(property => selectedProps.map(property => property.value).includes(property.code)).map(property => property.code)
    const params = {
      'date[after]': getFormattedDate(advertising.dateStart, dateFormat),
      'date[before]': getFormattedDate(advertising.dateEnd, dateFormat),
      'face.advertising.id': advertising.id,
      'props[]': props,
      interval: selectedInterval.value,
      't[]': ['face_details'],
    }

    getPlanData(params)
  }

  useEffect(() => {
    if (advertising && selectedProperties) {
      fetchData()
    }
  }, [advertising, selectedProperties])

  const getSumValues = () => {
    let sums = {}

    for (let interval of plan) {
      for (let face of interval.data) {
        const properties = selectedProperties.filter(property => !notCalculateSumValuesForCodes.includes(property.code))

        for (let property of properties) {
          const propCode = property.code

          if (!sums[propCode]) {
            sums[propCode] = 0
          }

          const propValue = face.properties[propCode] && face.properties[propCode].value
            ? face.properties[propCode].value
            : 0
          sums[propCode] += Number(propValue)
          sums[propCode] = round(sums[propCode])
        }
      }
    }

    return  sums
  }

  const getAdvertFaces = () => {
    if (planData && planData[0]) {
      const faces = []

      if (planData[0].data) {
        for (let face of planData[0].data) {
          faces.push(face.item['@id'].split('/').pop())
        }
      }

      getFaces({
        't[]': getFacesParamsGroups(),
        'id[]': faces
      })
    }
  }

  const getFacesParamsGroups = () => {
    const faceEntity = FaceFactory.create(channelEntity.code)

    return faceEntity.listGroupParams
  }

  const propsOptions = (properties && properties.length)
    ? properties.map(property => ({
      value: property.code,
      label: property.name,
    }))
    : []

  const isAnyLoading = () => {
    return isPlanDataLoading || isFacesLoading || isPlanSaving || isFacePlanSaving
  }

  const saveFactDataValues = intervals => {
    if (intervals && intervals.length) {
      const props = properties.filter(property => propsToLoad.includes(property.code)).map(property => property.code)

      for (let selectedProp of selectedProperties) {
        if (!props.includes(selectedProp.code)) {
          props.push(selectedProp.code)
        }
      }

      saveFactData({
        intervals
      }, null, {
        'date[after]': getFormattedDate(advertising.dateStart, dateFormat),
        'date[before]': getFormattedDate(advertising.dateEnd, dateFormat),
        'face.advertising.id': advertising.id,
        'props[]': props,
        interval: selectedInterval.value
      }, true, null);
    }
  }

  const onSaveAllFactData = () => {
    setIsPlanSaving(true)
    let intervals = []

    for (let interval of plan) {
      let correctionInterval = {}

      correctionInterval.dateStart = moment.utc(interval.dateStart).format('Y-MM-DD')
      correctionInterval.dateEnd = moment.utc(interval.dateEnd).format('Y-MM-DD')

      correctionInterval.data = []

      for (let face of interval.data) {
        const faceToEdit = {
          item: face.item['@id'],
          properties: {}
        }

        const propsCodes = selectedProps.map(prop => prop.value)

        for (let propCode in face.properties) {
          if (propsCodes && propsCodes.includes(propCode)) {
            faceToEdit.properties[propCode] = face.properties[propCode].value
          }
        }

        correctionInterval.data.push(faceToEdit)
      }

      if (correctionInterval.data.length) {
        intervals.push(correctionInterval)
      }
    }

    saveFactDataValues(intervals)
  }

  const onSaveFaceFactData = async (intervalIndex, face) => {
    setIsFacePlanSaving(true)
    let intervals = []

    if (plan && plan[intervalIndex]) {
      const interval = plan[intervalIndex]

      let correctionInterval = {}

      correctionInterval.dateStart = moment.utc(interval.dateStart).format('Y-MM-DD')
      correctionInterval.dateEnd = moment.utc(interval.dateEnd).format('Y-MM-DD')

      correctionInterval.data = []

      const faceToEdit = {
        item: face.item['@id'],
        properties: {}
      }

      const propsCodes = selectedProps.map(prop => prop.value)

      for (let propCode in face.properties) {
        if (propsCodes && propsCodes.includes(propCode)) {
          faceToEdit.properties[propCode] = face.properties[propCode].value
        }
      }

      correctionInterval.data.push(faceToEdit)

      if (correctionInterval.data.length) {
        intervals.push(correctionInterval)
      }
    }

    saveFactDataValues(intervals)
  }

  const onRowChange = async (value, property, periodIndex, faceIndex) => {

    const formPlan = cloneDeep(plan)

    if (formPlan && formPlan[periodIndex] && formPlan[periodIndex].data && formPlan[periodIndex].data[faceIndex]
      && formPlan[periodIndex].data[faceIndex].properties && formPlan[periodIndex].data[faceIndex].properties[property.code]
    ) {
      formPlan[periodIndex].data[faceIndex].properties[property.code].value = value
    }

    await setPlan(formPlan)
  }

  return (
    <>
      <div className="container-fluid padding-side-15 mediaplan-page mediaplan plan-data">
        <div className="row">
          {advertising &&
          <>
            <div className={'radio-block'}>
              <RadioGroup
                title={i18n.t('clients.advertising.select_interval')}
                selected={selectedInterval}
                setSelected={(selected) => {
                  setSelectedInterval(selected)

                  const propsToShow = (selected.value === dayInterval.value)
                    ? ['budget']
                    :(selectedProperties ? selectedProperties.map(property => property.code) : [])

                  const props = properties.filter(property => propsToShow.includes(property.code))
                    .map(property => ({
                      label: property.name,
                      value: property.code
                    }))
                  setSelectedProps(props)
                }}
                options={dateIntervals}
                name={'interval'}
              />

              <div className="filter-group-wrapper">
                <div className="filter-group__title" >
                  {i18n.t('clients.advertising.select_metrics')}
                </div>
                <Select
                  className={'className'}
                  classNamePrefix="react-select"
                  placeholder={i18n.t('clients.advertising.select_metrics')}
                  options={propsOptions}
                  value={selectedProps}
                  onChange={setSelectedProps}
                  isMulti={true}
                  isDisabled={isAnyLoading()}
                />
              </div>
            </div>

            {plan && selectedProperties &&
            <div className={'mediaplan-tables'}>
              <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
                <table className="table redesign-table table-client goal-detail mediaplans">
                  <TableHeader
                    properties={selectedProperties}
                    leftColumnsColSpan={3}
                  />
                  <tbody>

                  <SumRow
                    properties={selectedProperties}
                    values={getSumValues()}
                    title={i18n.t('clients.advertising.plan_values')}
                    titleColSpan={3}
                  />

                  {plan.map((periodItem, periodIndex) => (
                    <Fragment key={periodItem.dateStart + periodItem.dateEnd}>
                      {selectedInterval && selectedInterval.value !== wholeInterval.value &&
                      <tr>
                        <td>
                          <PeriodTitle
                            interval={selectedInterval}
                            dateStart={periodItem.dateStart}
                            dateEnd={periodItem.dateEnd}
                          />
                        </td>
                        <td/>
                        <td/>
                        {selectedProperties.map((property) => (
                          <td key={property.code}/>
                        ))}
                      </tr>
                      }

                      {periodItem.data &&
                      <>
                        {periodItem.data.map((face, faceIndex) => (
                          <Fragment key={face.item}>
                            <Row
                              properties={selectedProperties}
                              values={face.properties || {}}
                              titleColSpan={2}
                              index={periodIndex}
                              faceIindex={faceIndex}
                              isSelectToPaste={false}
                              title={
                                <div>
                                  <a onClick={() => setSelectedFace(face)}>{face.item.name}</a>
                                </div>
                              }
                              //onSave={() => onSaveFaceFactData(face)}
                              editablePropertiesCodes={selectedProps.map(prop => prop.value)}
                              rowCellComponent={PlanRowCell}
                              onChange={(value, property) => onRowChange(value, property, periodIndex, faceIndex)}
                              cellAfterTitle={
                                <div className={'face-buttons'}>
                                  <Protected access={'AdvertisingsEdit'}>
                                    <div className={'correction-button'}
                                         onClick={() => onSaveFaceFactData(periodIndex, face)}
                                         title={i18n.t('clients.advertising.edit_correction')}>
                                      <i className={'far fa-save'} />
                                    </div>
                                  </Protected>
                                </div>
                              }
                            />
                          </Fragment>
                        ))}
                      </>
                      }
                    </Fragment>
                  ))}

                  </tbody>
                </table>
                {isAnyLoading() &&
                <LoaderBackdrop />
                }

                {isAnyLoading() &&
                <Loader />
                }
              </div>
            </div>
            }
          </>
          }
        </div>

        <Protected access={'AdvertisingsEdit'}>
          <ButtonWithLoader
            onClick={() => onSaveAllFactData()}
            className={"button " + (isPlanSaving ? 'load' : '')}
            text={i18n.t('clients.advertising.save_all')}
            loadingText={i18n.t('clients.advertising.saving_values')}
            isLoading={isPlanSaving}
          />
        </Protected>

        {advertising && selectedFace && channelEntity &&
        <DigitalForm
          faceId={selectedFace.id}
          getAdvertFaces={getFaces}
          setSelectedFace={setSelectedFace}
          readonly={true}
          companyId={advertising.company.id}
        />
        }

      </div>
    </>
  )
}

export default Plan
