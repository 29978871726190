import React, {useEffect, useState} from 'react';
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import {getFormattedDate, getUtcDate} from "../Fact/Utils";
import i18next from "i18next";
import Loader from "../../../../Components/Loader/Loader";

const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function DigitalFaceInfo({face, closeModal = () => {}, advertising}) {
  const [quantities, setQuantities] = useState(null)
  const [quantitiesSum, setQuantitiesSum] = useState(null)
  const [totalSum, setTotalSum] = useState(null)
  const [periods, setPeriods] = useState(null)

  const [{data: totalForMonth, isLoading: isTotalForMonthLoading}, getTotalForMonth] = useApiFetch((new DigitalChannel()).apiUrls.totalPlan)
  const [{data: totalForDays, isLoading: isTotalForDaysLoading}, getTotalForDays] = useApiFetch((new DigitalChannel()).apiUrls.totalPlan)

  useEffect(() => {
    getTotalForMonth({
      interval: 'month',
      't[]': 'simple_props',
      'props[]': 'quantity',
      'face.id': face.id,
      'date[after]': getFormattedDate(advertising.dateStart, 'YYYY-MM-DD'),
      'date[before]': getFormattedDate(advertising.dateEnd, 'YYYY-MM-DD'),
    })

    getTotalForDays({
      interval: 'day',
      'face.id': face.id,
      'date[after]': getFormattedDate(advertising.dateStart, 'YYYY-MM-DD'),
      'date[before]': getFormattedDate(advertising.dateEnd, 'YYYY-MM-DD'),
      't[]': 'simple_props',
      'props[]': ['budget', 'quantity', 'goal', 'clicks', 'impressions', 'views', 'visits']
    })
  }, [])

  const isIntersection = (dateStart, dateEnd, periodDateStart, periodDateEnd) => {
    return dateStart.isSameOrBefore(periodDateEnd)
      && dateEnd.isSameOrAfter(periodDateStart)
  }

  useEffect(() => {
    if (totalForMonth && periods) {
      const quantities = []
      let quantitiesSum = 0

      for (let month of totalForMonth) {
        const periodMonth = i18next.t('clients.advertising.' + getUtcDate(month.dateStart).format('MMMM').toLowerCase())

        const isPlacementPeriod = periods.some(period => isIntersection(
          getUtcDate(period.startDate, 'DD.MM.YYYY'),
          getUtcDate(period.endDate, 'DD.MM.YYYY'),
          getUtcDate(month.dateStart),
          getUtcDate(month.dateEnd)
        ))

        if (isPlacementPeriod) {
          const quantity = (month.data && month.data[0] && month.data[0].properties && month.data[0].properties.quantity)
            ? month.data[0].properties.quantity
            : 0

          quantities.push({
            name: periodMonth,
            value: quantity
          })

          quantitiesSum += quantity
        }
      }

      let totalSum = 0

      if (face.faceUnits && face.faceUnits[0] && face.faceUnits[0].unitCost) {
        totalSum = quantitiesSum * face.faceUnits[0].unitCost * face.faceUnits[0].ratio * face.faceUnits[0].discount
      }

      setQuantities(quantities)
      setQuantitiesSum(quantitiesSum)
      setTotalSum(totalSum)
    }
  }, [totalForMonth, periods])

  useEffect(() => {
    if (totalForDays && totalForDays.length) {
      const periods = []
      let startDate, endDate = null

      for (let day of totalForDays) {
        if (day.data && day.data.length) {
          startDate = getFormattedDate(day.dateStart, 'DD.MM.YYYY')
          break
        }
      }

      for (let day of totalForDays) {
        if (day.data && day.data.length) {
          if (!startDate) {
            startDate = getFormattedDate(day.dateStart, 'DD.MM.YYYY')
          }

          endDate = getFormattedDate(day.dateStart, 'DD.MM.YYYY')
        } else if (startDate && endDate) {
          periods.push({
            startDate,
            endDate
          })

          startDate = null
          endDate = null
        }
      }

      if (startDate && endDate) {
        periods.push({
          startDate,
          endDate
        })
      }

      setPeriods(periods)
    }
  }, [totalForDays])

  const getTotalDiscount = () => {
    return (face && face.faceUnits && face.faceUnits[0] && face.faceUnits[0].discount !== undefined)
      ? ((1- face.faceUnits[0].discount) * 100).toFixed(fractionDigits) + ' %'
      : 0
  }

  const isAnyLoading = () => isTotalForDaysLoading || isTotalForMonthLoading

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
        onClick={closeModal} />
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={closeModal}>
            <i className='fas fa-remove' />
          </button>
        </div>

        {face && periods && quantities &&
        <div className='popup-form__body'>
          <table className="table redesign-table table-client goal-detail mediaplans face-info-table">
            <tbody>
            <tr>
              <td>{i18next.t('face_info.agency')}</td>
              <td>Сбермаркетинг</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.advertiser')}:</td>
              <td>{face.advertising && face.advertising.company ? face.advertising.company.name : ''}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.advertising')}:</td>
              <td>{face.advertising ? face.advertising.name : ''}</td>
            </tr>
            <tr>
              <td>{i18next.t('periods.face.platform')}:</td>
              <td>{face.platform ? face.platform.name : ''}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.platform_code')}:</td>
              <td>{face.code}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.placement_period')}:</td>
              <td>{periods && periods.length > 0 && periods.map(period => (
                <div key={period.startDate}>{`${period.startDate} - ${period.endDate}`}</div>
              ))}</td>
            </tr>
            <tr>
              <td>{i18next.t('periods.face.format')}:</td>
              <td>
                {face.formats && face.formats.length > 0 && face.formats.map(format => (
                  <div key={format.name}>{format.name}</div>
                ))}
              </td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.volume')}:</td>
              <td>{quantitiesSum}</td>
            </tr>
            {quantities && quantities.map(quantity => (
              <tr key={quantity.name}>
                <td className={'nested-item'}>{quantity.name}</td>
                <td>{quantity.value}</td>
              </tr>
            ))}

            <tr>
              <td>{i18next.t('face_info.allocation_type')}</td>
              <td>{face.strategy ? face.strategy.name : ''}</td>
            </tr>
            <tr>
              <td className={'nested-item'}>{i18next.t('face_info.purchasing_model')}</td>
              <td>{face.faceUnits && face.faceUnits[0] && face.faceUnits[0].unit ? face.faceUnits[0].unit.name : ''}</td>
            </tr>
            <tr>
              <td className={'nested-item'}>{i18next.t('face_info.unit_cost')}</td>
              <td>{face.faceUnits && face.faceUnits[0] ? face.faceUnits[0].unitCost : ''}</td>
            </tr>
            <tr>
              <td className={'nested-item'}>{i18next.t('face_info.markup')}</td>
              <td>{face.faceUnits && face.faceUnits[0] ? face.faceUnits[0].ratio : ''}</td>
            </tr>
            <tr>
              <td className={'nested-item'}>{i18next.t('face_info.discount')}</td>
              <td>{getTotalDiscount()}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.total')}</td>
              <td>{totalSum}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.comments')}</td>
              <td>{(face.frequency ? face.frequency : '') + ' ' + (face.targeting ? face.targeting : '')}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.legal')}</td>
              <td>{advertising.legal ? advertising.legal.name : ''}</td>
            </tr>
            <tr>
              <td>{i18next.t('face_info.inn')}</td>
              <td>{advertising.legal ? advertising.legal.inn : ''}</td>
            </tr>
            </tbody>
          </table>
        </div>
        }

        {isAnyLoading() &&
          <Loader
            className={'face-form-loader'}
          />
        }
      </div>
    </div>
  )
}

export default DigitalFaceInfo;
