import React, {useMemo} from 'react'
import FormattedNumber from "../../../Components/FormattedNumber";
import i18n from "i18next";

export default function FaceTable({
                                    rows, deleteRow = () => {
  }
                                  }) {
  const budgetSum = useMemo(() => {
    let budget = 0

    rows.forEach(row => {
      budget += Number(row.budget)
    })

    return budget
  }, [rows])

  return (
    <div className={'mediaplan-tables'}>
      <div>{i18n.t('invoice.list.faces_count')} - {rows.length}</div>

      <table className="table redesign-table">
        <thead>
        <tr>
          <th>{i18n.t('face.list.face')}</th>
          <th>{i18n.t('face.list.contractor')}</th>
          <th>{i18n.t('financiers.clientBudget')}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr className={'sum-values-row'}>
          <td></td>
          <td></td>
          <td><FormattedNumber
            value={budgetSum} type={'float'}/></td>
          <td></td>
        </tr>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>
              {(row.faces && row.faces[0] && row.faces[0].name)
                ? row.faces[0].name : ''}
            </td>
            <td>
              {(row.faces && row.faces[0] && row.faces[0].contractor && row.faces[0].contractor.name)
                ? row.faces[0].contractor.name : ''}
            </td>
            <td>
              <FormattedNumber
                value={row.budget} type={'float'}/>
            </td>
            <td>
              <button onClick={() => deleteRow(row)}>
                <i className="fal fa-trash-alt icon-delete"/>
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}