import React, {useEffect} from 'react'

function CheckboxFilter({value, element, onChange}) {
  useEffect(() => {
    if (value === undefined) {
      onChange(0)
    }
  }, [])

  return (
    <div className="checkbox-group">
      <div className="checkbox-item">
        <input type="checkbox"
               id={'checkbox-item' + element.name}
               name={element.name}
               checked={Boolean(parseInt(Array.isArray(value) ? value[0] : value))}
               onChange={() => onChange(Number(!parseInt(Array.isArray(value) ? value[0] : value)))}/>
        {element.label &&
          <label htmlFor={'checkbox-item' + element.name} className={'title'}>{element.label}</label>
        }
      </div>
    </div>
  )
}

export default CheckboxFilter