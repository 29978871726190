import React, {Component} from 'react';
import SubMenu from '../../Components/SubMenu';
import {toast} from 'react-toastify';
import MenuClient from '../../Components/MenuClient';
import Input from '../../Components/Inputs/Input';
import {sprintToastErrors} from '../../utils';
import {api, responseHandler} from '../../api/apiProvider'
import QueueInput from '../../Components/Inputs/QueueInput';
import i18n from "i18next";
import Select from "react-select";
import apiUrls from "../../ApiUrls";
import Protected from "../../Components/Roles/Protected";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyRoles: [],
      contractTypes: [],
      pools: [],
      selectedRoles: [],
      selectedPool: null,
      selectedContractType: null,
      altDisplay: false,
      site: "",
      emails: [],
      roles: [],
      phones: [],
      phoneInput: "",
      clientRoleCode: 'client',
      clientCodeIri: null
    };
  }

  async componentDidMount() {
    if (!this.isNew()) {
      await this.getData();
    }

    this.getCompanyRoles()
    this.getContractTypes()
    this.getPools()
  }

  getData = async () => {
    const companyID = this.props.match.params.companyID;
    await api.get("/companies/" + companyID + "?t[]=company_list").then((data) => {
      this.setState({
        companyName: data.name,
        company: data,
        site: data.site,
        emails: data.emails,
        phones: data.phones,
        roles: data.roles,
      });

      const selectedRoles = data.roles.map(role => ({
        value: role['@id'],
        label: role.name,
      }))

      this.setState({selectedRoles})

      const selectedContractType = (data.contractType && data.contractType['@id'])
        ? {
          value: data.contractType['@id'],
          label: data.contractType.name,
        }
        : null

      const selectedPool = (data.pool && data.pool['@id'])
        ? {
          value: data.pool['@id'],
          label: data.pool.name,
        }
        : null

      this.setState({selectedContractType})
      this.setState({selectedPool})
    })
  }

  getCompanyRoles = () => {
    api.get(apiUrls.get.roles()).then((data) => {
      const companyRoles = data['hydra:member'];

      const clientRole = companyRoles.find(role => role.code === this.state.clientRoleCode)

      if (clientRole) {
        this.setState({clientCodeIri: clientRole['@id']});
      }

      this.setState({companyRoles});
    })
  }

  getContractTypes = () => {
    api.get(apiUrls.get.contractTypes()).then((data) => {
      const contractTypes = data['hydra:member'];

      this.setState({contractTypes});
    })
  }

  getPools = () => {
    api.get(apiUrls.get.pools()).then((data) => {
      const pools = data['hydra:member'];

      this.setState({pools});
    })
  }

  isNew = () => {
    return this.props.match.params.companyID === undefined
  }

  isClientRole = roles => {
    if (!Array.isArray(roles) || !this.state.clientCodeIri) {
      return false
    }

    const clientRole = roles.find(role => role.value === this.state.clientCodeIri)

    return Boolean(clientRole)
  }
  
  onRoleChange = (selectedRoles) => {
    this.setState({selectedRoles})

    if (!this.isClientRole(selectedRoles)) {
      this.setState({selectedContractType: null})
      this.setState({selectedPool: null})
    }
  }

  onPoolChange = (selectedPool) => {
    this.setState({selectedPool})
  }

  onContractTypeChange = (selectedContractType) => {
    this.setState({selectedContractType})
  }

  selectedRolesId = () => {
    return this.state.selectedRoles
      ? this.state.selectedRoles.map(role => role.value)
      : []
  }

  addCompany = async () => {
    const body = {
      name: this.state.companyName,
      description: "",
      site: this.state.site,
      address: "",
      phones: this.state.phones,
      emails: this.state.emails,
      roles: this.selectedRolesId(),
      contractType: this.state.selectedContractType ? this.state.selectedContractType.value : null,
      pool: this.state.selectedPool ? this.state.selectedPool.value : null
    }

    try {
      const companyData = await api.post("/companies", body);
      toast.success(i18n.t('clients.edit.company_created'));

      if (companyData && companyData.id) {
        this.props.history.push("/client/" + companyData.id + "/edit")
      }
    } catch (error) {
      sprintToastErrors(error, '', 'clients.edit')
    }
  }

  updateCompany = async () => {
    const body = {
      name: this.state.companyName,
      site: this.state.site,
      emails: this.state.emails,
      phones: this.state.phones,
      roles: this.selectedRolesId(),
      contractType: this.state.selectedContractType ? this.state.selectedContractType.value : null,
      pool: this.state.selectedPool ? this.state.selectedPool.value : null
    }

    try {
      await api.patch("/companies/" + this.props.match.params.companyID, body)
        .then(responseHandler)
      toast.success(i18n.t('clients.edit.company_changed'));
    } catch (error) {
      sprintToastErrors(error, '', 'clients.edit')
    }
  }

  onClickSave = () => {
    if (this.isNew()) {
      this.addCompany();
    } else {
      this.updateCompany();
    }
  }

  render() {
    let topTitle;
    if (this.isNew()) {
      topTitle = i18n.t('clients.edit.add_company');
    } else {
      topTitle = i18n.t('clients.edit.edit_company');
    }

    const roleOptions = this.state.companyRoles.map((role) => {
      return {value: role['@id'], label: role.name}
    })

    const contractTypesOptions = this.state.contractTypes.map((contractType) => {
      return {value: contractType['@id'], label: contractType.name}
    })

    const poolsOptions = this.state.pools.map((pool) => {
      return {value: pool['@id'], label: pool.name}
    })

    return (
      <div className="row content flex">
        <div className="edit-client">
          {!this.isNew() &&
            <SubMenu/>
          }
          <div className="table-edit-client company-edit">
            <div>
              <MenuClient title={topTitle}>
                <Protected access={'ClientsEdit'}>
                  <button className="button-accent" onClick={this.onClickSave}>
                    {i18n.t('clients.edit.save')}
                  </button>
                </Protected>
              </MenuClient>
              <table className="table redesign-table table-client">
                <tbody>
                {this.isClientRole(this.state.selectedRoles) &&
                <>
                  <tr>
                    <td>
                      {i18n.t('clients.page.pool')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        className="react-select-container select-pseudo-input small"
                        classNamePrefix="react-select"
                        options={poolsOptions}
                        placeholder={i18n.t('clients.page.pool')}
                        onChange={this.onPoolChange}
                        value={this.state.selectedPool || null}
                      />
                    </td>
                  </tr>
                </>
                }

                <tr>
                  <td>
                    {i18n.t('clients.page.company')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input value={this.state.companyName} onChange={(value) => this.setState({companyName: value})}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.page.roles')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      className="react-select-container select-pseudo-input small"
                      classNamePrefix="react-select"
                      options={roleOptions}
                      placeholder={i18n.t('clients.page.roles')}
                      onChange={this.onRoleChange}
                      value={this.state.selectedRoles || ''}
                      isMulti
                    />
                  </td>
                </tr>

                {this.isClientRole(this.state.selectedRoles) &&
                  <>
                    <tr>
                      <td>
                        {i18n.t('clients.page.contract_type')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Select
                          className="react-select-container select-pseudo-input small"
                          classNamePrefix="react-select"
                          options={contractTypesOptions}
                          placeholder={i18n.t('clients.page.contract_type')}
                          onChange={this.onContractTypeChange}
                          value={this.state.selectedContractType || ''}
                        />
                      </td>
                    </tr>
                  </>
                }

                <tr>
                  <td>
                    {i18n.t('clients.page.site')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input type="text" value={this.state.site} onChange={(value) => this.setState({site: value})}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.page.phones')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <QueueInput value={this.state.phones} onChange={(value) => this.setState({phones: value})}
                                nameButton={i18n.t('clients.edit.add_phone')}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.page.email')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <QueueInput value={this.state.emails} onChange={(value) => this.setState({emails: value})}
                                nameButton={i18n.t('clients.edit.add_email')}/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;
