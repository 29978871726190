import {isEmpty} from 'lodash'

export class Channel {
  static code = ''
  contractorFilter = ''

  apiUrls = {}

  static getCode () {
    if (!this.code) {
      throw new Error('empty code')
    }
    return this.code
  }

  getCode () {
    if (!this.code) {
      throw new Error('empty code')
    }
    return this.code
  }

  getContractorFilter () {
    return this.contractorFilter
  }

  getApiUrl (name, params = {}) {
    if (!this.apiUrls.hasOwnProperty(name) || !this.apiUrls[name]) {
      return null
    }

    let paramsString = ''

    if (!isEmpty(params)) {
      const filterParams = new URLSearchParams(params)
      paramsString = filterParams.toString()
    }

    return this.apiUrls[name] + (paramsString ? '?' + paramsString : '')
  }

  getApiUrlWithId (name, id, params) {
    if (!this.apiUrls.hasOwnProperty(name) || !this.apiUrls[name]) {
      throw new Error(`empty ${name} url`)
    }

    if (!id) {
      throw new Error(`empty id`)
    }

    let paramsString = ''

    if (!isEmpty(params)) {
      const filterParams = new URLSearchParams(params)
      paramsString = filterParams.toString()
    }

    return `${this.apiUrls[name]}/${id}` + (paramsString ? '?' + paramsString : '')
  }

  getApiUrlFunction (name, params) {
    return () => {
      return this.getApiUrl(name, params)
    }
  }

  getApiUrlFunctionWithId (name, params) {
    return (id) => {
      return this.getApiUrlWithId(name, id, params)
    }
  }
}