import React from 'react';
import IntInput from '../../../../../Components/Inputs/IntInput';
import FloatInput from "../../../../../Components/Inputs/FloatInput";

const floatType = 'float'
const intType = 'int'

//property свойство
//detail значение свойства {
// editable: true
// value: 18
// }

function RowCell({property, detail, onChange, entity, onSave}) {
  const isEditable = () => {
    if (detail.editable === undefined) {
      return true
    }

    return Boolean(detail ? detail.editable : false)
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    className: 'money',
    value: detail.value || 0,
    disabled: !isEditable(),
    onKeyDown: handleKeyDown
  }

  return (
    <td key={property.code} className={'money'}>
      <div className={'detail-cell'}>
        {property.type === floatType &&
        <FloatInput
          onChange={(value) => onChange(value, property)}
          {...inputProps}
        />
        }

        {property.type === intType &&
        <IntInput
          onChange={(value) => onChange(value, property)}
          {...inputProps}
        />
        }
      </div>
    </td>
  )
}

export default RowCell
