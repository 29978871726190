import React, {Component} from 'react';

class SubTitle extends Component {
  render() {
    return (
      <div className="container-button-advert">
        <div className="subtitle">
          {this.props.title}
        </div>
        <div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SubTitle;