import React from 'react'
import Checkbox from "../../../Components/Inputs/Checkbox"
import FormattedNumber from "../../../Components/FormattedNumber";
import i18n from "i18next";

export default function TableWithoutInvoice ({clientCheckRows, isInSelected = () => {}, onCheck = () => {}}) {
    return (
      <table className="mediaplans redesign-table financial-report">
        <thead>
        <tr>
          <th></th>
          <th>{i18n.t('face.list.face')}</th>
          <th>{i18n.t('face.list.contractor')}</th>
          <th>{i18n.t('financiers.clientBudget')}</th>
        </tr>
        </thead>
        <tbody>
        {clientCheckRows && clientCheckRows.map((clientCheckRow, index) => (
          <tr key={index}>
            <td>
              <Checkbox
                name={index}
                checked={isInSelected(clientCheckRow)}
                onChange={(value) => {
                  onCheck(clientCheckRow, value)
                }}
                />
            </td>
            <td>
              {(clientCheckRow.faces && clientCheckRow.faces[0] && clientCheckRow.faces[0].name)
                ? clientCheckRow.faces[0].name : ''}
            </td>
            <td>
              {(clientCheckRow.faces && clientCheckRow.faces[0] && clientCheckRow.faces[0].contractor && clientCheckRow.faces[0].contractor.name)
                ? clientCheckRow.faces[0].contractor.name : ''}
            </td>
            <td>
              <FormattedNumber
                value={clientCheckRow.budget}  type={'float'} />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    )
}
