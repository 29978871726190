import React, {Fragment, useEffect} from 'react';
import {cloneDeep} from 'lodash'
import {getFormattedDate} from "../Fact/Utils";
import i18n from "i18next";
import QualityRow from "./QualityRow";

function QualitiesBlock({qualities, onChange, formFace, title}) {
  //const [isOpen, setIsOpen] = useState(false)

  const onRowChange = (index, value) => {
    let newQualities = cloneDeep(qualities)

    if (newQualities[index]) {
      newQualities[index] = value;
    }

    let quality = newQualities[index]

    let fields = ['super', 'superPrime', 'fixPrime'];

    for (let field of fields) {
      if (quality[field] < 0) {
        quality[field] = 0
      }

      if (quality[field] > 100) {
        quality[field] = 100
      }
    }

    if (quality.super >= 0 && quality.super <= 100) {
      quality.fix = 100 - quality.super

      if (quality.super === 0) {
        quality.superPrime = 0
        quality.superOffPrime = 0
      }

      if (quality.super === 100) {
        quality.fixPrime = 0
        quality.fixOffPrime = 0
      }

      if (quality.superPrime && quality.superPrime <= 100) {
        quality.superOffPrime = 100 - quality.superPrime
      }

      if (quality.fixPrime && quality.fixPrime <= 100) {
        quality.fixOffPrime = 100 - quality.fixPrime
      }
    }

    onChange(newQualities)
  }

  const deleteRow = (index) => {
    let affs = cloneDeep(qualities)

    affs.splice(index, 1);

    onChange(affs)
  }

  const addRow = () => {
    let newQuality = cloneDeep(qualities) || []

    newQuality.push({
      dateStart: getFormattedDate(new Date(), 'YYYY-MM-DD'),
      super: 0,
      superPrime: 0,
      fixPrime: 0,
      fix: 100,
      superOffPrime: 100,
      fixOffPrime: 100,
    })

    onChange(newQuality)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'popup-form__row-title'}>{title}</div>
      <div className={'affinities-wrapper'}>
        <div>
          <div>
            {qualities && qualities.length > 0 &&
            <>
              {qualities.map((quality, index) => (
                <Fragment key={quality.id}>
                  <div className={'quality-wrapper'} key={index}>
                    <QualityRow
                      key={quality.id}
                      quality={quality}
                      formFace={formFace}
                      onChange={value => onRowChange(index, value)}
                      index={index}
                      deleteRow={deleteRow}
                    />
                  </div>
                </Fragment>
              ))}
            </>
            }

            <a onClick={() => addRow()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QualitiesBlock;