import React, { Component } from 'react';
import SubMenu from '../Components/SubMenu';
import { Link } from 'react-router-dom';
import MenuClient from '../Components/MenuClient';
import { toast } from 'react-toastify';
import { api } from '../api/apiProvider';
import i18n from "i18next";
import apiUrls from "../ApiUrls";
import Protected from "../Components/Roles/Protected";

class RegionalClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regions: []
        }
    }

    componentDidMount() {
        api.get(apiUrls.get.regions() + "?t[]=region_location&company.id=" + this.props.match.params.companyID).then((data) => {
            const regions = data['hydra:member'];
            this.setState({ regions: regions });
        })
    }

    updateRegionData = () => {
        let url = apiUrls.get.regions() + "?t[]=region_location&company.id=" + this.props.match.params.companyID;
        api.get(url).then((data) => {
            const regions = data['hydra:member'];
            this.setState({ regions: regions });
        })
    }

    deleteRegion = async (regionId) => {
        try {
            const response = await api.delete(apiUrls.get.region(regionId));
            this.updateRegionData();
            toast.success(i18n.t('clients.edit.region_deleted'));
        }
        catch (error) {
            toast.error(i18n.t('clients.edit.delete_fail'))
        }
    }

    render() {
        return (
            <div className="row content flex ">
                <div className="edit-client">
                    <SubMenu/>
                    <div className="table-edit-client">
                        <div>
                            <MenuClient title="Regional client division">
                                <Protected access={'ClientsEdit'}>
                                    <Link className="blue-cursor-button" to={"/client/" + this.props.match.params.companyID + "/city/new"}>
                                        <i className="fal fa-plus mr-for-icon"></i>
                                        {/* {{ 'index.new_region'|trans({}, 'mp_project') }} */}
                                        {i18n.t('clients.edit.new_region')}
                                    </Link>
                                </Protected>
                            </MenuClient>
                            <table className="table redesign-table regional-client">
                                <thead>
                                    <tr>
                                        <th>
                                            {i18n.t('clients.edit.region')}
                                        </th>
                                        <th>
                                            {i18n.t('clients.edit.region_cities')}
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.regions.map((region, index) => (
                                        <tr key={index}>
                                            <td>
                                                {region.name}
                                            </td>
                                            <td>
                                                {region.locations.map((location, index) => (<div key={index}>{location.name}</div>))}
                                            </td>
                                            <td className="right-text">
                                                <Link to={"/client/" + this.props.match.params.companyID + "/city/" + region.id}>
                                                    <i className="fal fa-edit"></i>
                                                </Link>
                                                <button onClick={() => this.deleteRegion(region.id)}>
                                                    <i className="fal fa-trash-alt icon-delete"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* {% include 'PromoAtlasMainBundle::pagination.html.twig' with {'route_path': 'projects_client_city_list', 'route_params': {client: client.id}, 'paginator': cities } %} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegionalClient;