import React from 'react';
import i18n from "i18next";
import {Link} from "react-router-dom";
import {getFormattedDate} from "../Fact/Utils";
import ArrowLink from "../../../../Components/Links/ArrowLink";
import {TvChannel} from "../../../../entities/channel/TvChannel";

const dateFormat = 'YYYY-MM-DD';

function BackButtons({companyId, advertisingId, startDate, endDate, channelEntity}) {
  const getPlanDetailLink = () => {
    const periodDateStart = getFormattedDate(startDate, dateFormat)
    const periodDateEnd = getFormattedDate(endDate, dateFormat)

    const defaultInterval = (channelEntity && channelEntity.code === TvChannel.getCode())
      ? 'week'
      : 'month'

    return `/advertisings/${advertisingId}/plan?dateStart=${periodDateStart}&dateEnd=${periodDateEnd}&interval=${defaultInterval}`
  }

  return (
    <div className="back-buttons">
      {companyId && advertisingId &&
        <>
          <ArrowLink
            to={`/advertisings/${advertisingId}/plan`}
            text={i18n.t('clients.advertising.to_advertising_plan')}
          />

          {startDate && endDate &&
            <ArrowLink
              to={getPlanDetailLink()}
              text={i18n.t('clients.advertising.to_advertising_faces_plan')}
            />
          }

        </>
      }
    </div>
  )
}

export default BackButtons