import React from 'react';
import i18n from "i18next";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import {TvChannel} from "../../../../entities/channel/TvChannel";

function Face(props) {

  const deleteFace = () => {
    let isConfirm = window.confirm(i18n.t('periods.face.are_you_sure'))

    if (isConfirm) {
      props.deleteFace(props.face.id, () => {
        props.getAdvertFaces()
      })
    }
  }

  function Row(props) {
    return (
      <div className={'period-face__row'}>
        <div className={'period-face__row-name'}>
          {props.name}:
        </div>
        <div className={'period-face__row-value'}>
          {props.value}
        </div>
      </div>
    )
  }

  const getPlatformName = () => {
    if (props.channelEntity instanceof TvChannel) {
      return props.face.hpa && props.face.hpa.platform && props.face.hpa.platform.name
        ? props.face.hpa.platform.name
        : ''
    } else {
      return props.face.platform && props.face.platform.name
    }

    return ''
  }

  return (
    <div className={'period-face'}>
      <div className={'period-face__header'}>
        <button
          className="period-face__button"
          onClick={deleteFace}
          title={i18n.t('periods.face.delete')}
        ><i className={'fal fa-trash-alt'}/></button>
      </div>
      <div className={'period-face__body'}>
        <h3
          className={'period-face__title'}
          onClick={() => props.setSelectedFace(props.face)}
          title={i18n.t('periods.face.edit')}
        >{props.face.code}</h3>
        <div className={'period-face__rows'}>
          <Row name={i18n.t(`clients.advertising.platform`)} value={getPlatformName()}/>

          {props.channelEntity instanceof DigitalChannel &&
            <>
              <Row name={i18n.t(`clients.advertising.subtype`)} value={props.face.type.name}/>
              <Row name={i18n.t(`clients.advertising.format`)} value={props.face.formats &&
              props.face.formats.map(format => (
                <div key={format.id}>
                  {format.name}
                </div>
              ))}/>
              <Row name={i18n.t(`clients.advertising.targeting`)} value={props.face.targeting}/>
              <Row name={i18n.t(`clients.advertising.formatText`)} value={props.face.formatText}/>
              <Row name={i18n.t(`clients.advertising.strategy`)} value={props.face.strategy && 'name' in props.face.strategy ? props.face.strategy.name: ''}/>
              <Row name={i18n.t(`clients.advertising.unit`)} value={props.face.faceUnits[0] ? props.face.faceUnits[0].unit.name : ''}/>
              <Row name={i18n.t(`clients.advertising.unitCost`)} value={props.face.faceUnits[0] ? props.face.faceUnits[0].unitCost : ''}/>
            </>
          }

        </div>
      </div>

      <button
        className="period-face__button  add"
        onClick={() => props.addFaceToDetails(props.face['@id'])}
        title={i18n.t('periods.face.add_to_detail')}
      ><i className={'far fa-plus-circle'}/></button>

    </div>
  )
}

export default Face;