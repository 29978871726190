import React from 'react';
import FormattedNumber from "../../../../../Components/FormattedNumber";

function SumRowCell({value, type, key = Math.floor(Math.random() * 1000), cellEmptyValue = 0}) {
  return (
    <td key={key}>
      {(type === 'int' || type === 'float') &&
        <FormattedNumber
          value={(value !== null && value !== undefined) ? value : cellEmptyValue}
          type={type} />
      }
    </td>
  )
}

export default SumRowCell;