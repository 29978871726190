import React from 'react';
import IntInput from '../../../../Components/Inputs/IntInput';
import FloatInput from "../../../../Components/Inputs/FloatInput";

const floatType = 'float'
const intType = 'int'

export default function RowCell({value, property, onChange, disabled = false}) {
  const inputProps = {
    className: 'money',
    value: value,
    onChange: (value) => onChange(value, property.code),
    isValid: () => true,
    disabled,
  }

  return (
    <div className={'money'}>
      <div className={'budget-cell'}>
        {property.type === floatType &&
          <FloatInput
            {...inputProps}
          />
        }

        {property.type === intType &&
          <IntInput
            {...inputProps}
          />
        }
      </div>
    </div>
  )
}