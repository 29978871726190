import React, {useState} from 'react';
import ReactTooltip from "react-tooltip";

function CheckRowComment({value, onChange}) {
  const [isInputOpen, setIsInputOpen] = useState(false)

  return (
    <>
      <div className={'check-comment'}>
        <i className="fal fa-edit" onClick={() => setIsInputOpen(!isInputOpen)}/>

        {value &&
          <>
            <i className="fal fa-info-circle"
               data-for="main"
               data-tip={value}
               data-iscapture="true"
            />
            <ReactTooltip
              id="main"
              place="right"
              effect="solid"
              multiline={true}
            />
          </>
        }
      </div>

      {isInputOpen &&
        <textarea value={value}
                  onChange={onChange}/>
      }
    </>
  )
}

export default CheckRowComment
