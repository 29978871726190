import { useState, useCallback } from 'react';
import {logError, sprintToastErrors} from "../../utils";
import {api} from '../../api/apiProvider'

const getUrl = (url, params = {}) => {
  if (typeof params !== 'object') {
    return url
  }

  if (!Object.keys(params).length) {
    return url
  }

  let urlParams = []

  for (let key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      for (let value of params[key]) {
        urlParams.push(`${key}=${value}`)
      }
    } else {
      urlParams.push(`${key}=${params[key]}`)
    }
  }

  const urlParamsString = urlParams.join('&')

  const delimiter = url.includes('?')
    ? '&'
    : '?'

  return urlParams.length
    ? `${url}${delimiter}${urlParamsString}`
    : url
}

const useDelete = (getUrlFunction) => {
  const [isDeleted, setIsDeleted] = useState(false)
  const [isError, setIsError] = useState(false)

  return useCallback((id, callback, params = {}) => {
    const deleteItem = async () => {
      if (typeof getUrlFunction !== 'function') {
        logError('bad get url function')
        return
      }

      const url = id ? getUrlFunction(id) : getUrlFunction()
      const deleteUrl = getUrl(url, params)
      
      if (!url) {
        logError('url empty')
        return
      }

      setIsDeleted(false)
      setIsError(false)

      await api
        .delete(deleteUrl)
        .then(() => {
          setIsDeleted(true)

          if (typeof callback === 'function') {
            callback()
          }
        })
        .catch((error) => {
          sprintToastErrors(error)
          setIsError(true)
        })

      return {isDeleted, isError}
    }

    return deleteItem()
  }, [])
}

export default useDelete