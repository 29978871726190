import React from 'react';
import i18n from "i18next";
import {getFormattedDate} from "../../Fact/Utils";

function PeriodTitle({interval, dateStart, dateEnd}) {
  const getPeriod = () => {
    return interval.code === 'day'
      ? getFormattedDate(dateStart, i18n.t('date_format'))
      : getFormattedDate(dateStart, i18n.t('date_format')) + ' - ' + getFormattedDate(dateEnd, i18n.t('date_format'))
  }

  return (
    <>
      {
        (interval.code === 'whole')
          ? ''
          :
          `${interval.label} ${getPeriod()}`
      }
    </>
  )
}

export default PeriodTitle;