import React, {useMemo} from 'react';
import RowCell from "./RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';
import Protected from "../../../../Components/Roles/Protected";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import FaceUnits from "./FaceUnits";
import i18n from "i18next";
import FormattedNumber from "../../../../Components/FormattedNumber";
import {getFieldValue, getRoundedNumber} from "../../../../utils";
import moment from "moment";
import {getFormattedDate} from "../Fact/Utils";
import {dayInterval, monthInterval, weekInterval} from "./components/dateIntervals";
import { useVT } from 'virtualizedtableforantd4';
import {cloneDeep} from 'lodash';

const getSumValues = (plan, fields) => {
  let sums = {}

  if (!fields || !fields.length) {
    return sums
  }

  for (let field of fields) {
    sums[field.code] = 0
  }

  for (let period of plan) {
    for (let face of period.data) {
      for (let field of fields) {
        if (face.properties.hasOwnProperty(field.code) && face.properties[field.code].value) {
          const value = getFieldValue(face.properties[field.code].value)

          sums[field.code] += Number(value)
        }

        sums[field.code] = getRoundedNumber(sums[field.code], 2)
      }
    }
  }

  return  sums
}

const isMonth = interval => {
  return interval && (interval.value === monthInterval.value)
}

const isWeek = interval => {
  return interval && (interval.value === weekInterval.value)
}

const isDay = interval => {
  return interval && (interval.value === dayInterval.value)
}

export default function PeriodsTable ({plan, properties, selectedProperties, savePlanDataValues,
                                        setSelectedFace, setSelectedInfoFace,
                                       onChange, setPlan, selectedInterval,
                                        setSelectedDetailToPasteTable, setSelectedIntervalToPasteTable,
                                        setSelectedFieldToPasteTable}) {

  const [ vt, set_components ] = useVT(() => ({ scroll: { y: 600 } }), []);
  const onValueChange = (value, key, item, index) => {
    if (key === 'budget_without_discount') {
      return
    }

    value = isNaN(value) ? 0 : value

    if (item.properties[key] && (item.properties[key].value !== undefined)) {
      value = {
        ...item.properties[key],
        value
      }
    }

    onChange({
      ...item.properties,
      [key]: value,
    }, item, index)
  }

  const sums = useMemo(() => {
    return getSumValues(plan, selectedProperties)
  }, [plan, selectedProperties])

  const getPeriodTitle = (interval) => {
    if (isWeek(selectedInterval)) {
      return `${i18n.t('clients.advertising.week')} ${getFormattedDate(interval.dateStart, i18n.t('date_format'))} - ${
        getFormattedDate(interval.dateEnd, i18n.t('date_format'))}`
    }

    if (isDay(selectedInterval)) {
      return moment(interval.dateStart).format(i18n.t('date_format'))
    }

    return ''
  }

  const data = useMemo(() => {
    const data = []

    for (let periodIndex in plan) {
      const period = plan[periodIndex]
      if (plan.length) {
        const item = {
          name: getPeriodTitle(period),
          key: period.dateStart + '_periodTitle_' + periodIndex,
        }

        for (let property of properties) {
          let propertyCode = property.code

          item[propertyCode] = <div>
            <div className={'select-paste-column'} key={propertyCode}>
              <span
                onClick={() => {
                  setSelectedFieldToPasteTable(propertyCode)
                  setSelectedIntervalToPasteTable(periodIndex)
                }}
              ><i className="fas fa-arrow-alt-circle-down"/></span>
            </div>
          </div>
        }

        data.push(item)
      }

      for (let index in period.data) {
        const faceData = period.data[index]
        const item = {}
        item.item = faceData.item
        item.isNew = faceData.isNew
        item.key = period.dateStart + faceData.item['@id']
        item.name =
          <div className="period-face-title__wrapper">
            <div className={'period-face-title__block'}>
              <div className="period-face-title">
              <span
                onClick={() => setSelectedInfoFace(faceData.item.id)}
                title={i18n.t('periods.face.booking')}
              ><i className="fal fa-info-circle"/></span>
                <a
                  className={'detail-name'}
                  onClick={() => setSelectedFace(faceData.item)}
                >
                  {faceData.item.name}
                </a>
              </div>
              {faceData.item.faceUnits && faceData.item.faceUnits[0] &&
                <FaceUnits face={faceData.item} properties={selectedProperties} />
              }
            </div>
            <div>
              <Protected access={'AdvertisingsEdit'}>
                <div className={'plan-period__row-buttons'}>
                  <button
                    className={'plan-period__row-buttons-save'}
                    onClick={() => onSave(faceData.item)}
                  >
                    <i className="far fa-save" />
                  </button>
                  <DeleteWithConfirmButton className={'plan-period__row-buttons--delete'} onClick={() =>
                    deleteFace(faceData.item, faceData.isNew)}/>
                </div>
              </Protected>
            </div>
          </div>
        for (let propertyCode in faceData.properties) {
          let propertyValue = faceData.properties[propertyCode]

          const property = properties.find(property => property.code === propertyCode)

          item[propertyCode] = <RowCell
            value={propertyValue.value}
            onChange={value => {
              onValueChange(value, propertyCode, faceData, periodIndex)
            }}
            property={property}
            disabled={!propertyValue.editable}
          />
        }

        item.rowPasteButtons = <div className={'flex-end'}>
          <span
            className={'table-paste-button'}
            onClick={() => {
              setSelectedDetailToPasteTable(index)
              setSelectedIntervalToPasteTable(periodIndex)
            }}>
            <i className="fas fa-arrow-alt-circle-right" />
          </span>
        </div>

        data.push(item)
      }
    }

    return data
  }, [plan])

  const columns = useMemo(() => {
    if (!selectedProperties) {
      return []
    }

    const columns = [
      {
        title: '',
        width: 270,
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: '',
        width: 35,
        dataIndex: 'rowPasteButtons',
      },
    ]

    for (let property of selectedProperties) {
      columns.push({
        title: property.name,
        width: 130,
        dataIndex: property.code,
        editable: true,
      })
    }

    return columns
  }, [selectedProperties, plan])

  const onSave = record => {
    let intervals = []

    for (let interval of plan) {
      let intervalToSave = {}

      intervalToSave.dateStart = moment(interval.dateStart).format('Y-MM-DD')
      intervalToSave.dateEnd = moment.utc(interval.dateEnd).startOf('day').format('Y-MM-DD')

      intervalToSave.data = []

      for (let face of interval.data) {
        if (record['@id'] && face.item && record['@id'] === face.item['@id']) {
          let faceItem = {
            item: face.item['@id'],
            properties: {}
          }

          for (let propertyCode in face.properties) {
            faceItem.properties[propertyCode] = face.properties[propertyCode].value
          }

          intervalToSave.data.push(faceItem)
        }
      }

      if (intervalToSave.data.length) {
        intervals.push(intervalToSave)
      }
    }

    savePlanDataValues(intervals)
  }

  const deleteFace = (record, isNew) => {
    if (isNew) {
      const newPlan = cloneDeep(plan)
      for (let interval of newPlan) {
        for (let faceId in interval.data) {
          const face = interval.data[faceId]
          if (face.item && face.item['@id'] === record['@id']) {
            interval.data.splice(faceId, 1);
          }
        }
      }
      setPlan(newPlan)
    } else {
      let intervals = []

      for (let interval of plan) {
        let intervalToSave = {}

        intervalToSave.dateStart = moment(interval.dateStart).format('Y-MM-DD')
        intervalToSave.dateEnd = moment.utc(interval.dateEnd).startOf('day').format('Y-MM-DD')

        intervalToSave.data = []

        for (let face of interval.data) {
          if (record['@id'] && face.item && record['@id'] === face.item['@id']) {
            let faceItem = {
              item: face.item['@id'],
              properties: {}
            }

            for (let property of properties) {
              faceItem.properties[property.code] = 0
            }

            intervalToSave.data.push(faceItem)
          }
        }

        if (intervalToSave.data.length) {
          intervals.push(intervalToSave)
        }
      }

      savePlanDataValues(intervals)
    }
  }

  return <Table
    columns={columns}
    dataSource={data}
    summary={() => (
      <Table.Summary fixed={'top'}>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={1}>
            {i18n.t('clients.advertising.all_input_values')}
          </Table.Summary.Cell>
          <Table.Summary.Cell/>
          {sums && (Object.entries(sums)).map(([key, sum], index) => (
            <Table.Summary.Cell key={key}>
              <FormattedNumber
                value={sum}
                type={'float'} />
            </Table.Summary.Cell>
          ))}
          <Table.Summary.Cell />
        </Table.Summary.Row>
      </Table.Summary>
    )}
    pagination={false}
    scroll={{
      x: '100%',
      y: 600,
    }}
    components={vt}
    rowClassName={(record) => (record.isNew ? 'new-row' : '')}
  />
}