import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {api} from "../../../api/apiProvider";
import {useEffect, useState} from "react";
import useApiPatch from "../../../Components/Hooks/useApiPatch";

const useClientCheckRows = (channel, client, dateStart, dateEnd) => {
  const [clientCheck, setClientCheck] = useState(null)

  const [{data: clientChecks, isLoading: isClientChecksLoading}, getClientChecks] = useApiFetch(apiUrls.get.clientChecks())

  const [{}, updateClientCheckRow] = useApiPatch(apiUrls.get.clientCheckRowApproved)
  const [{}, addClientCheckRow] = useApiPost(apiUrls.get.clientCheckRows())
  const [{}, addClientCheck] = useApiPost(apiUrls.get.clientChecks())

  useEffect(() => {
    if (channel && client && dateStart && dateEnd) {
      getCheck()
    }
  }, [channel, client, dateStart, dateEnd])

  useEffect(() => {
    if (clientChecks) {
      if (clientChecks.length) {
        setClientCheck(clientChecks[0])
      }
    }
  }, [clientChecks])

  const getCheck = () => {
    getClientChecks(getClientChecksParams())
  }

  const saveClientCheckRow = async face => {
    return new Promise(async (resolve, reject) => {
      const params = {
        budget: face.clientCheckRowBudget || 0,
        approved: face.clientCheckRowApproved,
      }

      params.contractorCheckRow = face.checkRowId
        ? `/financial/contractor_check_rows/${face.checkRowId}`
        : null

      if (face.clientCheckRowId) {
        return updateClientCheckRow(face.clientCheckRowId, params, {}, () => {
          return resolve(true)
        }, () => {
          return resolve(true)
        })
      } else if (params.approved || params.budget) {
        let check = clientCheck

        if (!check) {
          await addClientCheck({
            client: `companies/${client.value}`,
            channel: `channels/${channel.value}`,
            dateStart: dateStart,
            dateEnd: dateEnd,
          }, null, {}, true, async clientCheckId => {
            const clientCheck = await api.get(apiUrls.get.clientCheck(clientCheckId))

            if (clientCheck) {
              setClientCheck(clientCheck)

              addClientCheckRow({
                ...params,
                clientCheck: clientCheck['@id'],
                faces: [
                  face['@id'],
                ],
              }, null, {}, true, () => {
                return resolve(true)
              }, () => {
                return reject(false)
              })
            }
          })
        } else {
          addClientCheckRow({
            ...params,
            clientCheck: check['@id'],
            faces: [
              face['@id'],
            ],
          }, null, {}, true, () => {
            return resolve(true)
          }, () => {
            return reject(false)
          })
        }
      } else {
        return resolve(true)
      }
    })
  }

  const getClientChecksParams = () => {
    if (channel && client && dateStart && dateEnd) {
      return {
        'client.id[]': channel.value,
        'channel.id': client.value,
        'dateStart': dateStart,
        'dateEnd': dateEnd,
      }
    }

    return {}
  }

  return {
    clientCheck,
    saveClientCheckRow,
    getCheck,
    isClientChecksLoading,
  }
}

export default useClientCheckRows