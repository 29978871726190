import {Channel} from "./Channel";

export class TvChannel extends Channel {
  static code ='tv'
  code ='tv'
  contractorFilter = 'tvFaces.contractor.id'
  faceName = 'tvFace'
  facesName = 'tvFaces'
  platformName = 'tvPlatform'
  platformsName = 'tvPlatforms'
  propertiesName = 'tvProperties'
  faceAdvertisingCompanyIdFilter = 'tvFd.face.advertising.company.id'
  contractorCheckRowFilter = 'tvFd.face.id[]'

  apiUrls = {
    communications: '/tv_communications',
    corrections: '/tv_corrections',
    faceUnits: '/tv_face_units',
    faces: '/tv_faces',
    fms: '/tv_fms',
    platformClients: '/tv_platform_clients',
    platforms: '/tv_platforms',
    properties: '/tv_properties',
    ratios: '/tv_ratios',
    units: '/tv_units',
    faceCommission: '/tv_face_commission',
    faceCommissions: '/tv_face_commissions',
    totalPlan: `/data/plan/${this.code}/faces`,
    totalFact: `/data/total/${this.code}/faces`,
  }
}