import React from 'react'
import i18n from "i18next"
import DropDown from "../../Components/DropDown"
import {Link} from "react-router-dom"
import Protected from "../../Components/Roles/Protected";
import Roles from "../../roles/Roles";

function Table(props) {
  return (
    <table
      className="mediaplans redesign-table client-table"
      id="main-client-page"
    >
      <thead>
      <tr>
        <th>
          {i18n.t('clients.page.company')}
        </th>
        <th>
          {i18n.t('clients.page.site')}
        </th>
        <th>
          {i18n.t('clients.page.phones')}
        </th>
        <th>
          {i18n.t('clients.page.email')}
        </th>
        <th>
          {i18n.t('clients.page.roles')}
        </th>
        <th />
      </tr>
      </thead>
      <tbody>
      {props.companies.map((company, index) => (
        <tr key={index}>
          <td>
            <Link to={"/client/" + company.id + "/edit"}>
              {company.name}
            </Link>
          </td>
          <td>
            {company.site}
          </td>
          <td>
            {company.phones.map((phone, index) => (<div key={index}>{phone}</div>))}
          </td>
          <td>
            {company.emails.map((email, index) => (<div key={index}>{email}</div>))}
          </td>
          <td>
            {company.roles.map((role, index) => (<div key={index}>{role.name || ''}</div>))}
          </td>
          <td>
            <Protected access={'ClientsEdit'}>
              <button onClick={() => props.deleteClient(company.id)} className="btn-delete-client btn-icon">
                <i className="fal fa-trash-alt"/>
              </button>
            </Protected>
          </td>
        </tr>))}
      </tbody>
    </table>
  )
}

export default Table