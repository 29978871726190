import React, {Component} from 'react'
import i18next from 'i18next'
import Checkbox from "../../Components/Inputs/Checkbox"
import FormattedNumber from "../../Components/FormattedNumber";

class FaceTableWithoutInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,
      dateFormat: 'DD-MM-YYYY',
    }
  }

  render() {
    let faces = this.props.faces

    return (
      <table className="mediaplans redesign-table regional-client">
        <thead>
        <tr>
          <th></th>
          <th>{i18next.t('face.list.product')}</th>
          {/*<th>{i18next.t('face.list.advert')}</th>*/}
          <th>{i18next.t('face.list.budget_id')}</th>
          <th>{i18next.t('face.list.closing')}</th>
          {/*<th>{i18next.t('face.list.month')}</th>*/}
          <th>{i18next.t('face.list.face')}</th>
          <th>{i18next.t('face.list.work')}</th>
          <th>{i18next.t('face.list.client_budget_plan_without_vat')}</th>
          {/*<th>{i18next.t('face.list.client_budget_plan_with_vat')}</th>*/}
          <th>{i18next.t('face.list.client_budget_fact_without_vat')}</th>
          {/*<th>{i18next.t('face.list.client_budget_fact_with_vat')}</th>*/}
        </tr>
        </thead>
        <tbody>
        {faces.map((face, index) => (
          <tr key={index}>
            <td>
              <Checkbox
                name={index}
                checked={this.props.isFaceInSelected(face)}
                {...(this.props.isFaceCheckboxDisabled(face) ? {disabled:true} : {})}
                onChange={(value) => {
                  this.props.onCheck(face, value)
                }}
                />
            </td>
            <td>
              {face.advertising.product.name}
            </td>
            {/*<td>*/}
            {/*  {face.advertising.nameFinancial}*/}
            {/*</td>*/}
            <td>
              {face.advertising.budgetCode}
            </td>
            <td>
              {face.advertising.nameFinancial}
            </td>
            {/*<td>{moment(face.dateStart).format("MM.YYYY")}</td>*/}
            <td>{face.face.name}</td>
            <td>{face.work.name}</td>
            <td>
              <FormattedNumber
                value={face.budget}  type={'float'} />
            </td>
            {/*<td className={'money'}>{(face.budget.toFixed(this.state.fractionDigits))}</td>*/}
            <td>
              <FormattedNumber
                value={face.spent}  type={'float'} />
            </td>
            {/*<td className={'money'}>{(face.spent.toFixed(this.state.fractionDigits))}</td>*/}
          </tr>
        ))}
        </tbody>
      </table>
    );
  }
}

export default FaceTableWithoutInvoice;
