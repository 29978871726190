import React, {useEffect} from 'react';
import SubMenu from '../../Components/SubMenu';
import MenuClient from '../../Components/MenuClient';
import {Link} from 'react-router-dom';
import i18n from "i18next";
import Table from "./Table";
import apiUrls from "../../ApiUrls";
import useDelete from "../../Components/Hooks/useDelete";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import Protected from "../../Components/Roles/Protected";

function List(props) {
  const companyId = props.match.params.companyID

  const [{data: legals}, getLegals] = useApiFetch(
    apiUrls.get.companyLegals(companyId)
  );

  const deleteLegal = useDelete(apiUrls.delete.legals)

  useEffect(() => {
    getLegals()
  }, [])

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu/>
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('clients.edit.legal_entities_list')}>
              <Protected access={'ClientsEdit'}>
                <Link className="blue-cursor-button" to={"/client/" + props.match.params.companyID + "/legal/new"}>
                  <i className="fal fa-plus mr-for-icon"/>
                  {i18n.t('clients.edit.add_legal_entity')}
                </Link>
              </Protected>
            </MenuClient>

            {legals &&
            <Table
              legals={legals}
              companyId={props.match.params.companyID}
              deleteLegalEntity={deleteLegal}
              getLegals={getLegals}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;