import React, {useState} from 'react'
import i18next from 'i18next'
import {Link} from "react-router-dom";
import {getFormattedDate} from "../Advertising/Detail/Fact/Utils";
import i18n from "i18next";
import Form from "./Form";
import useDelete from "../../Components/Hooks/useDelete";
import apiUrls from "../../ApiUrls";
import {toast} from "react-toastify";
import Protected from "../../Components/Roles/Protected";

const API_ENDPOINT = process.env.REACT_APP_API_ENTRYPOINT

function Table(props) {
  const [selectedDocument, setSelectedDocument] = useState(null)

  const getFileUrl = url => API_ENDPOINT + url

  const addNewDocument = () => {
    setSelectedDocument({
      name: '',
      number: '',
      comment: '',
      registry: props.registry['@id'],
     /* file: '',*/
      dateSigned: new Date(),
    })
  }

  const deleteDoc = useDelete(apiUrls.delete.documents)

  const deleteDocument = id => {
    deleteDoc(id, () => {
      toast.success(i18n.t('documents.edit.document_deleted'))
      props.getRegistry()
    })
  }

  return (
    <div className={'documents-list'}>
      <h3>{i18n.t('registries.edit.files_list')}</h3>
      <Protected access={'FinancialRegistriesEdit'}>
        <a
          className="blue-cursor-button add-link"
          onClick={addNewDocument}
        >
          <i className="fal fa-plus mr-for-icon" />
          {i18n.t('documents.list.add')}</a>
      </Protected>

      {props.registry && props.registry.documents && props.registry.documents.length > 0 &&
        <table className="table redesign-table table-client">
          <thead>
          <tr>
            <th>{i18next.t('documents.list.name')}</th>
            <th>{i18next.t('documents.list.number')}</th>
            <th>{i18next.t('documents.list.date')}</th>
            <th>{i18next.t('documents.list.link')}</th>
            <th />
          </tr>
          </thead>
          <tbody>

          {props.registry.documents.map((document, index) => (
            <tr key={index}>
              <td>
                <a className={'edit-document'} onClick={() => setSelectedDocument(document)}>{document.name || ''}</a>
              </td>
              <td>
                {document.number || ''}
              </td>
              <td>
                {getFormattedDate(document.dateSigned)}
              </td>

              <td>
                {document.link &&
                <a href={document.link} target={'_blank'} download>
                  {document.link}
                </a>
                }
              </td>
              <td>
                <button onClick={() => deleteDocument(document.id)}>
                  <i className="fal fa-trash-alt icon-delete" />
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      }


      {selectedDocument &&
        <Form
          document={selectedDocument}
          getRegistry={props.getRegistry}
          setSelectedDocument={setSelectedDocument}
        />
      }
    </div>
  )
}

export default Table