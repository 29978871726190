const apiUrls = {
  get: {
    legals: () => '/legals',
    repaymentIntervals: () => '/financial/repayment_intervals',
    repaymentInterval: id => `/financial/repayment_intervals/${id}`,
    legalsWithTaxes: () => '/legals?t[]=legals_taxes',

    companyLegals(id) {
      if (!id) {
        return null
      }

      return `/legals?t[]=legals_taxes&company.id=${id}`
    },

    companyProducts(id) {
      if (!id) {
        return null
      }

      return `/companies/${id}/products`
    },

    company(id) {
      if (!id) {
        return null
      }

      return `/companies/${id}`
    },

    companies: () => '/companies',

    legalWithTaxes(id) {
      if (!id) {
        return null
      }

      return `/legals/${id}?t[]=legals_taxes`
    },

    taxes: () => '/taxes',

    roles: () => '/company_roles',
    tvRegions: () => '/tv_regions',
    clientRoles: () => '/company_roles?code=client',
    managers: () => '/agency/managers?pagination=false',
    clientManagers: companyId => `/company/company_managers?companies.id=${companyId}&pagination=false`,
    products: () => '/products',
    productsWithoutPagination: () => '/products?pagination=false',
    pools: () => '/pools',

    product: (id) => {
      if (!id) {
        return null
      }

      return `/products/${id}`
    },

    registries: () => '/financial/registries',

    registry: (id) => {
      if (!id) {
        return null
      }

      return `/financial/registries/${id}`
    },

    commissions: () => '/financial/commissions',

    commission: id => `/financial/commissions/${id}`,

    productForeignCodes: () => '/campaign-products',

    clients: () => '/companies?pagination=false&roles.code=client',

    contractors: () => '/companies?pagination=false&roles.code=contractor',

    contractorLegals: () => '/legals?pagination=false&role.code=contractor',

    companyContractorLegals: iri => `${iri}/legals`,

    contractorChecks: () => '/financial/contractor_checks',

    clientChecks: () => '/financial/client_checks',

    clientCheck: id => `/financial/client_checks/${id}`,

    clientCheckRows: () => '/financial/client_check_rows',

    clientCheckRow: id => `/financial/client_check_rows/${id}`,

    clientCheckRowApproved: id => `/financial/client_check_rows/${id}/approved`,

    contractorCheck: id => `/financial/contractor_checks/${id}`,

    contractorCheckRows: () => '/financial/contractor_check_rows',

    contractorCheckRow: id => `/financial/contractor_check_rows/${id}`,

    clientLegals: () => '/legals?role.code=client',

    clientInvoices: () => '/financial/client_invoices',

    clientInvoice: id => `/financial/client_invoices/${id}`,

    channels: () => '/channels',

    advertisingPlan: (channelCode) => `/data/plan/${channelCode}/advertisings`,
    advertisingsFacesBudget: (channelCode, advertisingIds) => {
      const params = new URLSearchParams({
        'props[]': 'budget'
      })

      for (let advertisingId of advertisingIds) {
        params.append('face.advertising.id[]', advertisingId)
      }

      return `/data/plan/${channelCode}/sum?` + params.toString()
    },

    advertisingPlanData: (channelCode) => `/data/plan/${channelCode}/faces`,

    goals: () => '/internet_goals',

    contractTypes: () => '/financial/contract_types',

    digitalChannels: () => '/digital_channels',

    digitalDepartments: () => '/digital_departments',

    digitalMedias: () => '/digital_media',

    digitalCtrs: () => '/digital/platform/ctrs',
    digitalVtrs: () => '/digital/platform/vtrs',

    digitalDataProperties: () => '/digital_dataproperties',

    digitalFactors: () => '/digital_factors',

    digitalDataSources: () => '/digital_datasources',

    digitalSources: () => '/digital_sources',

    advertTypes: () => '/advert_types',

    channel: id => `/channels/${id}`,

    channelByCode: code => `/channels?code=${code}`,

    adverts: () => '/adverts',

    advertising: id => `/adverts/${id}`,

    internetFaces: () => '/internet_faces',

    internetFace: id => `/internet_faces/${id}`,

    digitalFaces: () => '/digital_faces',

    digitalFace: id => `/digital_faces/${id}`,

    tvFaces: () => '/tv_faces',

    tvFace: id => `/tv_faces/${id}`,

    invoiceRegistryFaces: () => '/financial/invoice-registry-faces',

    digitalPlatforms: () => '/digital_platforms?pagination=false',

    tvHpas: () => '/tv_hpas',

    tvHpasLists: () => '/tv_hpas/lists',

    internetPlatforms: () => '/internet_platforms?pagination=false',

    internetChannels: () => '/internet_channels?pagination=false',

    digitalSubtypes: () => '/digital_face_types?pagination=false',

    digitalStrategies: () => '/digital_strategies?pagination=false',

    digitalFormats: () => '/digital_face_formats?pagination=false',

    digitalUnits: () => '/digital_units?pagination=false',

    digitalRatios: () => '/digital_ratios',

    advertPeriod: id => `/advert_target_periods/${id}`,

    periods: () => '/advert_target_periods?t[]=target',

    //advertFaces: advertId => `/digital_faces?advertising.id=${advertId}`,

    mediaplans: () => '/mediaplans',

    mediaplan: id => `/mediaplans/${id}`,

    project: id => `/projects/${id}`,

    advertisingPeriods: () => `/advert_target_periods`,

    digitalFms: () => '/digital_fms',

    digitalFaceCommission: (id, dateStart, dateEnd) => `/digital_face_commission/${id}?dateStart=${dateStart}&dateEnd=${dateEnd}`,

    internetFaceCommission: (id, dateStart, dateEnd) => `/internet_face_commission/${id}?dateStart=${dateStart}&dateEnd=${dateEnd}`,

    internetFms: () => '/internet_fms',

    digitalCorrections: () => '/digital_corrections',

    internetCorrections: () => '/internet_corrections',

    digitalTrackings: () => '/digital_trackings',

    digitalTargetings: () => '/digital_targetings',

    invoices: () => `/invoices`,

    invoice: id => `/invoices/${id}`,

    invoiceEdit: id => `/invoice/${id}/edit`,

    invoiceRows: invoiceId => `/invoices/${invoiceId}/invoice_rows?order[advertising.id]=asc`,

    tax: id => `/taxes/${id}`,

    projects: () => '/agg/projects',

    financialList: () => '/adverts/financial_list',

    clientAdvertisings: companyId => `/client/${companyId}/advertisings`,

    companyRegions: companyId => `/companies/${companyId}/regions`,

    productCampaignNames: productId =>
      `/internet_campaign_nazvanie_iz_sverkis?product_id=${productId}`,

    productAdvertPlatforms: productId => `/products/${productId}/advert_platforms`,

    regions: () => '/regions',

    region: id => `/regions/${id}`,

    invoiceFaces: () => '/invoice_faces',

    clientCities: companyId => `/client/${companyId}/cities`,

    digitalPlatformClients: () => '/digital_platform_clients',

    internetPlatformContractors: () => '/internet_platform_contractors',

    internetProperties: () => '/internet_properties',

    digitalProperties: () => '/digital_properties',

    pullHistories: () => '/advert_pull_histories',
  },

  delete: {
    legals(id) {
      if (!id) {
        return null
      }

      return `/legals/${id}`
    },

    repaymentInterval(id) {
      if (!id) {
        return null
      }

      return `/financial/repayment_intervals/${id}`
    },

    products(id) {
      if (!id) {
        return null
      }

      return `/products/${id}`
    },

    registryFace: id =>  `/financial/registry_faces/${id}`,

    digitalFace: id => `/digital_faces/${id}`,
    internetFace: id => `/internet_faces/${id}`,
    invoiceRow: (invoiceId, invoiceRowId) => `/invoices/${invoiceId}/invoice_rows/${invoiceRowId}`,
    documents: id => `/financial/documents/${id}`,
    projects: id => `/projects/${id}`,
    companies: id => `/companies/${id}`,
    commission: id => `/financial/commissions/${id}`,
    contractorCheck: id => `/financial/contractor_checks/${id}`
  },

  post: {
    legals: () => '/legals',
    products: () => '/products',
    registry: () => '/financial/registries',
    registryFace: () => '/financial/registry_faces',
    digitalFace: () => '/digital_faces',
    internetFace: () => '/internet_faces',
    /*getMediaplanFactResults: ({id, advertId = null}) => {
      return advertId ? `/mediaplans/${id}/update-data/${advertId}` : `/mediaplans/${id}/update-data`
    },*/
    getAdvertisingFactResults: ({id, dateStart, dateEnd}) =>
      `/adverts/${id}/pull?dateStart=${dateStart}&dateEnd=${dateEnd}`,
    documents: () => '/financial/documents',
    media: () => '/media',
    digitalSource: () => '/digital_sources',
  },

  patch: {
    legals: id => `/legals/${id}`,
    products: id => `/products/${id}`,
    registry: id => `/financial/registries/${id}`,
    periods: id => `/advert_target_periods/${id}`,
    digitalFace: id => `/digital_faces/${id}`,
    internetFace: id => `/internet_faces/${id}`,
    invoice: id => `/invoices/${id}`,
    documents: id => `/financial/documents/${id}`,
    patchApproved: periodId => `/advert_target_periods/${periodId}/patch_approved`,
    adverts: id => `/adverts/${id}`
  }
}

export default apiUrls
