import React from 'react';
import 'antd/dist/antd.css';
import FormattedNumber from "../../../Components/FormattedNumber";

export default function AllSum({data}) {
  return (
    <div>
      {data.map(item => (
        <div key={item.title} className={'advert-all-sum'}>
          <div className={'advert-all-sum__title'}>{item.title}</div>
          <FormattedNumber value={item.value} type={'float'}/>
        </div>
      ))}
    </div>
  )
}