import React, {Fragment, useEffect, useState} from 'react';
import i18n from "i18next";
import Input from "../../Components/Inputs/Input";
import {toast} from "react-toastify";
import FileInput from "../../Components/Inputs/FileInput";
import FormSelect from "../../Components/Inputs/FormSelect";
import xlsServiceProvider from "../../api/xlsServiceProvider";
import TopMenu from "../../Components/TopMenu";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import apiUrls from "../../ApiUrls";
import responseStatuses from "../../api/responseStatuses";
import {Link} from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import Checkbox from "../../Components/Inputs/Checkbox";
import {ChannelFactory} from "../../entities/channel/ChannelFactory";

const getRequest = channel => {
  if (channel === 'digital') {
    return xlsServiceProvider.postDigitalPlan
  } else if (channel === 'internet') {
    return xlsServiceProvider.postInternetPlan
  } else if (channel === 'tv') {
      return xlsServiceProvider.postTvPlan
  }

  return null
}

function UploadXls(props) {
  const advertisingId = props.match.params.advertisingId

  const [fileInput, setFileInput] = useState(null)
  const [advertId, setAdvertId] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [sheetName, setSheetName] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [selectedCommunication, setSelectedCommunication] = useState(null)
  const [selectedIsUpdate, setSelectedIsUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [advertLink, setAdvertLink] = useState('')
  const [advertName, setAdvertName] = useState('')

  const [channels] = useState([
    {
      value: 'digital',
      label: 'Digital',
    },
    {
      value: 'internet',
      label: 'Performance',
    },
    {
      value: 'tv',
      label: 'TV',
    },
  ])

  const [{data: clients}, getClients] = useApiFetch(apiUrls.get.clients())
  const [{data: products}, getProducts] = useApiFetch(apiUrls.get.products())
  const [{data: advertising}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))

  const tvChannelEntity = ChannelFactory.create('tv')
  const [{data: tvCommunications}, getTvCommunications] = useApiFetch(tvChannelEntity.apiUrls.communications)

  useEffect(() => {
    getClients()

    if (advertisingId) {
      getAdvertising()
    }
  }, [])

  useEffect(() => {
    if (selectedCompany && selectedCompany.value) {
      getProducts({
        pagination: false,
        'company.id[]': selectedCompany.value
      })
    }

    if (selectedProduct) {
      setSelectedProduct(null)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (selectedChannel && selectedChannel.value && selectedChannel.value === 'tv') {
      getTvCommunications()
    }
  }, [selectedChannel])

  useEffect(() => {
    if (!products || !products.length) {
      setSelectedProduct(null)
    }
  }, [products])

  useEffect(() => {
    if (clients && clients.length && advertising && !selectedCompany) {
      const selectedClient = clients.find(client => client['@id'] === advertising.company)

      if (selectedClient) {
        setSelectedCompany({
          label: selectedClient.name,
          value: selectedClient.id,
        })
      }
    }

    if (products && products.length && advertising && !selectedProduct) {
      const selectedProduct = products.find(product => product['@id'] === advertising.product)

      if (selectedProduct) {
        setSelectedProduct({
          label: selectedProduct.name,
          value: selectedProduct.id,
        })
      }
    }

    if (advertising && !advertId) {
      setAdvertId(advertising.id)
    }
  }, [advertising, clients, products])

  const save = () => {
    setErrors([])
    setAdvertLink('')
    setAdvertName('')

    const formData = new FormData()

    if (!selectedCompany) {
      toast.error(i18n.t('advertising.list.select_company'))
      return
    }

    if (!selectedProduct) {
      toast.error(i18n.t('advertising.list.select_product'))
      return
    }

    if (!selectedFile) {
      toast.error(i18n.t('advertising.list.select_file'))
      return
    }

    if (!selectedChannel) {
      toast.error(i18n.t('advertising.list.select_channel'))
      return
    }

    formData.append('client_id', selectedCompany.value)
    formData.append('product_id', selectedProduct.value)
    formData.append('sheet_name', sheetName || '')
    formData.append('file', selectedFile, selectedFile.name)
    formData.append('advertising_id', advertId)

    if (selectedChannel.value && selectedChannel.value === 'tv') {
      formData.append('is_update', selectedIsUpdate ? 1 : 0)
      formData.append('template_type', selectedCommunication ? selectedCommunication.label : '')
    }

    const request = getRequest(selectedChannel.value);

    if (!request) {
      toast.error(i18n.t('server_error'));
    }

    setIsLoading(true)

    request(formData).then(response => {
      if (response.status < responseStatuses.SERVER_ERROR_STATUS && response.status >= responseStatuses.BAD_REQUEST_STATUS) {
        response.json()
          .then(data => {
            if (data && data.error) {
              const errors = data.error.split("\n");
              setErrors(errors)
            }
          })
      }

      if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
        toast.error(i18n.t('invoice.edit.get_xls_error'));
      }

      if (response.ok) {
        response.json()
          .then(data => {
            if (data && data.id && data.company && data.name) {
              const clientId = data.company.split('/').pop()
              const advertLink = `/advertisings/${data.id}`

              setAdvertLink(advertLink)
              setAdvertName(data.name)
            }
          })

        toast.success(i18n.t('invoice.edit.post_xls_success'))
      }
    }).catch((e) => {
      toast.error(i18n.t('server_error'));
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const getOptions = items => {
    return items
      ? items.map((item) => {
        return {value: item['id'], label: item.name}
      })
      : []
  }

  const companyOptions = getOptions(clients)
  const productsOptions = getOptions(products)
  const tvCommunicationsOptions = getOptions(tvCommunications)

  return (
    <div className="row content">
      <div className="edit-client">
        <div className="table-edit-client advertising-table advertising-main-table">
          <TopMenu title={i18n.t('advertising.list.upload_digital_plan')}>
            <div className={'adverts-menu-header'}>

            </div>
          </TopMenu>
          <div className='popup-form__rows'>
            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('advertising.list.advertising_id')}
              </div>
              <Input
                value={advertId || ''}
                onChange={(value) => setAdvertId(value)}
              />
            </div>

            <div className={'popup-form__row'}>
              <FormSelect
                name='company'
                value={selectedCompany}
                options={companyOptions}
                label={i18n.t('advertising.list.company')}
                placeholder={i18n.t('advertising.list.select_company')}
                onChange={(value) => setSelectedCompany(value)}
              />
            </div>

            <div className={'popup-form__row'}>
              <FormSelect
                name='product'
                value={selectedProduct}
                options={productsOptions}
                label={i18n.t('advertising.list.product')}
                placeholder={i18n.t('advertising.list.select_product')}
                onChange={(value) => setSelectedProduct(value)}
                isDisabled={!selectedCompany}
              />
            </div>

            <div className={'popup-form__row'}>
              <FormSelect
                name='channel'
                value={selectedChannel}
                options={channels}
                label={i18n.t('advertising.list.channel')}
                placeholder={i18n.t('advertising.list.select_channel')}
                onChange={(value) => setSelectedChannel(value)}
              />
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('advertising.list.sheet_name')}
              </div>
              <Input
                value={sheetName || ''}
                onChange={(value) => setSheetName(value)}
              />
            </div>

            {selectedChannel && selectedChannel.value && selectedChannel.value === 'tv' &&
              <div className={'popup-form__row'}>
                <div className={'popup-form__row-title'}>
                  {i18n.t('documents.edit.update_selected_period')}
                </div>
                <Checkbox
                  name={'is_update'}
                  checked={selectedIsUpdate}
                  onChange={(value) => {
                    setSelectedIsUpdate(value)
                  }}
                />
              </div>
            }

            {selectedChannel && selectedChannel.value && selectedChannel.value === 'tv' &&
              <div className={'popup-form__row'}>
                <FormSelect
                  name='tvCommunication'
                  value={selectedCommunication}
                  options={tvCommunicationsOptions}
                  label={i18n.t('clients.advertising.communication')}
                  placeholder={i18n.t('clients.advertising.select_communication')}
                  onChange={(value) => setSelectedCommunication(value)}
                />
              </div>
            }

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.file')}:
              </div>
              <FileInput
                setFileInput={setFileInput}
                value={(selectedFile && selectedFile.name) ? selectedFile : ''}
                onChange={(value) => {
                  if (value && value[0]) {
                    setSelectedFile(value[0])
                  }
                }}
              />
            </div>

          </div>
          <div className={'popup-form__buttons xls-upload-buttons'}>
            <button className="popup-form__button button-accent"
              onClick={save}
              disabled={isLoading}
            >
              {i18n.t('documents.edit.save')}
            </button>
            {isLoading &&
            <Loader />
            }
          </div>

          {advertLink &&
            <div className={'upload-xls-success'}>
              {i18n.t('documents.edit.xls_file_uploaded')}
              <Link to={advertLink}>
                {advertName}
              </Link>
            </div>
          }

          {errors && errors.length > 0 &&
            <div className={'upload-xls-errors'}>
              <div className={'upload-xls-errors__title'}>{i18n.t('invoice.edit.post_xls_errors_title')}</div>
              <ol>
                {errors.map((error, index) => (
                  <Fragment key={index}>
                    {error &&
                    <li key={index}>{error}</li>
                    }
                  </Fragment>
                ))}
              </ol>

            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default UploadXls
