import React from 'react';
import i18n from "i18next";
import Input from "../../Components/Inputs/Input";
import Select from 'react-select';
import moment from "moment";
import DatePicker from "react-datepicker";
import FloatInput from "../../Components/Inputs/FloatInput";
import FormattedNumber from "../../Components/FormattedNumber";

function Form(props) {
  return (
    <table className="table redesign-table table-client registry-edit-form">
      <tbody>
      <tr>
        <td>
          {i18n.t('registries.edit.name')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
            className={props.inputErrors.hasOwnProperty('name') ? 'has-error' : ''}
            value={props.formRegistry.name || ''}
            onChange={(value) => props.setFormRegistry({...props.formRegistry, name: value})}
            isValid={() => !props.inputErrors.hasOwnProperty('name')}
          />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('registries.edit.month')}
        </td>
      </tr>
      <tr>
        <td>
          <DatePicker
            className={(props.inputErrors.hasOwnProperty('date_start') || props.inputErrors.hasOwnProperty('date_end')) ? 'has-error' : ''}
            placeholderText={i18n.t('registries.edit.month')}
            selected={props.formRegistry.dateStart ?
              moment(props.formRegistry.dateStart).toDate() : ''}
            onChange={(value) => props.setDates(value)}
            dateFormat={"MM.yyyy"}
            showMonthYearPicker
          />
        </td>
      </tr>
      <tr>
        <td>
          <div className={'registry-reference-fields'}>
            <div>{i18n.t('registries.edit.client')}</div>
            <div>{i18n.t('registries.edit.advertisings')}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td className={props.inputErrors.hasOwnProperty('client') ? 'has-error' : ''}>
          <div className={'registry-reference-fields'}>
            <div>
              <Select
                className={"react-select-container select-pseudo-input"}
                classNamePrefix="react-select"
                placeholder={i18n.t('registries.edit.select')}
                options={props.clientCompanyOptions}
                onChange={(clientCompany) => {
                  props.setSelectedClientCompany(clientCompany)
                  props.setFormRegistry({...props.formRegistry, client: clientCompany.value})
                }}
                value={props.selectedClientCompany || ''}
                isDisabled={!props.isNew}
              />
              {props.inputErrors.hasOwnProperty('client') &&
              <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
              }
            </div>

            <div>
              <Select
                className={"react-select-container select-pseudo-input"}
                classNamePrefix="react-select"
                placeholder={i18n.t('registries.edit.select')}
                options={props.advertisingOptions}
                onChange={(advertisings) => {
                  props.setSelectedAdvertisings(advertisings)
                  props.setFormRegistry({
                    ...props.formRegistry,
                    advertisings: advertisings ? advertisings.map(advertising => advertising.value) : []
                  })
                }}
                value={props.selectedAdvertisings || ''}
                isMulti
                isDisabled={!props.showAdvertisings}
              />
              {props.inputErrors.hasOwnProperty('advertisings') &&
              <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
              }
            </div>
          </div>
        </td>
      </tr>

      <tr>
          <td>
            <div className={(props.isNew ? 'registry-reference-fields' : '')}>
              {props.isNew &&
                <div>{i18n.t('registries.edit.contractor_company')}</div>
              }
              <div>{i18n.t('registries.edit.contractor')}</div>
            </div>
          </td>
      </tr>
      <tr>
        <td>
          <div className={(props.isNew ? 'registry-reference-fields' : '') + (props.inputErrors.hasOwnProperty('contractor') ? ' has-error' : '')}>
            <div>
              {props.isNew &&
                <Select
                  className="react-select-container select-pseudo-input"
                  classNamePrefix="react-select"
                  placeholder={i18n.t('registries.edit.select')}
                  options={props.contractorCompanyOptions}
                  onChange={(contractorCompany) => {
                    if (!contractorCompany.value) {
                      props.setSelectedContractorCompany(null)
                      return
                    }

                    props.setSelectedContractorCompany(contractorCompany)
                  }}
                  value={props.selectedContractorCompany || ''} />
              }
            </div>
            <div>
              <Select
              className="react-select-container select-pseudo-input"
              classNamePrefix="react-select"
              placeholder={i18n.t('registries.edit.select')}
              options={props.contractorOptions}
              onChange={(contractor) => {
                props.setSelectedContractor(contractor)
                props.setFormRegistry({...props.formRegistry, contractor: contractor.value})
              }}
              value={props.selectedContractor || ''}
              isDisabled={!props.isNew || (props.isNew && !props.selectedContractorCompany)}
            />
              {props.inputErrors.hasOwnProperty('contractor') &&
              <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
              }
            </div>
          </div>

        </td>
      </tr>

      {props.tax &&
      <>
        <tr>
          <td>
            {i18n.t('registries.edit.contractor_tax')}
          </td>
        </tr>
        <tr>
          <td>
            <div>
              {props.tax.name}
            </div>
          </td>
        </tr>
      </>
      }

      <tr>
        <td>
          {i18n.t('registries.edit.expenses')}
        </td>
      </tr>
      <tr>
        <td>
          <FloatInput
            className={props.inputErrors.hasOwnProperty('expenses') ? 'has-error' : ''}
            value={props.formRegistry.expenses || 0}
            onChange={(value) => props.setFormRegistry({...props.formRegistry, expenses: value})} />
        </td>
      </tr>

      {props.tax && props.tax.tax >= 0 && props.formRegistry.expenses !== undefined &&
        <>
          <tr>
            <td>
              {i18n.t('registries.edit.expenses_with_tax')}
            </td>
          </tr>
          <tr>
            <td>
              <span>
                <FormattedNumber
                  value={(props.formRegistry.expenses * (1 + (props.tax.tax/100)))}  type={'float'} />
              </span>
            </td>
          </tr>
        </>
      }

      <tr>
        <td>
          {i18n.t('registries.edit.comment')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
            value={props.formRegistry.comment || ''}
            onChange={(value) => props.setFormRegistry({...props.formRegistry, comment: value})} />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default Form;