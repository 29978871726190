import React, {useEffect, useState} from 'react';
import apiUrls from "../../../../ApiUrls";
import TopMenu from "../../../../Components/TopMenu";
import Protected from "../../../../Components/Roles/Protected";
import DownloadFileButton from "../../../../Components/Buttons/DownloadFileButton";
import xlsServiceProvider from "../../../../api/xlsServiceProvider";
import i18n from "i18next";
import Filter from "../../../../Components/NewFilter/Filter";
import Table from "./Table";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import {api} from "../../../../api/apiProvider";
import {getFormattedDate, getUtcDate} from "../../Detail/Fact/Utils";
import Roles from "../../../../roles/Roles";
import Checkbox from "../../../../Components/Inputs/Checkbox";
import options from "../../../../options";
import useUrlParams from "../../../../Components/Hooks/useUrlParams";
import {groupParamsByKey} from "../../../../utils";
import {TvChannel} from "../../../../entities/channel/TvChannel";
const filterDateFormat = 'YYYY-MM-DD'

const chunk = (array, size) =>
  Array.from({length: Math.ceil(array.length / size)}, (value, index) =>
    array.slice(index * size, index * size + size));

const getAdvertisingsUrl = (companies, channel, dateRange, pool) => {
  let advertsUrl = apiUrls.get.adverts();

  const params = new URLSearchParams();
  params.append('pagination', 'false')
  params.append('t[]', 'short')

  if (companies && companies.length) {
    for (let company of companies) {
      if (company.value) {
        params.append('company.id[]', company.value)
      }
    }
  }

  if (pool && pool.length) {
    for (let poolItem of pool) {
      if (poolItem.value) {
        params.append('company.pool.id[]', poolItem.value)
      }
    }
  }

  if (channel && channel.value) {
    params.append('targets.channel.id', channel.value)
  }

  if (dateRange && dateRange[0] && dateRange[1]) {
    params.append('dateEnd[after]', dateRange[0])
    params.append('dateStart[before]', dateRange[1])
  }

  return advertsUrl + '?' + params.toString()
}

const selectAllOption = {
  label: i18n.t('projects.list.all_clients'),
  value: 'all'
}

export default function Report() {
  const {set: setUrlParam, get: getUrlParams, remove: removeUrlParam} = useUrlParams()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [filterParams, setFilterParams] = useState('')
  const [fileFilterParams, setFileFilterParams] = useState(null)
  const [companyFilters, setCompanyFilters] = useState([])
  const [productFilters, setProductFilters] = useState([])
  const [channelFilter, setChannelFilter] = useState({})
  const [facesMap, setFacesMap] = useState(null)
  const [faces, setFaces] = useState(null)
  const [isFacesLoading, setIsFacesLoading] = useState(false)
  const [clientCheckRows, setClientCheckRows] = useState(null)
  const [isClientCheckRowsLoading, setIsClientCheckRowsLoading] = useState(false)
  const [commissions, setCommissions] = useState(null)
  const [isCommissionsLoading, setIsCommissionsLoading] = useState(false)
  const [isFactFilter, setIsFactFilter] = useState(false)
  const [isSorting, setIsSorting] = useState(false)
  const [filteredFacesIris, setFilteredFacesIris] = useState([])
  const [sort, setSort] = useState({})

  const getFactDataUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)
        return channelEntity.apiUrls.totalFact
      }
    }

    return null
  }

  const getPlanDataUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)
        return channelEntity.apiUrls.totalPlan
      }
    }

    return null
  }

  const getFacesUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)
        return channelEntity.apiUrls.faces
      }
    }

    return null
  }

  const getPlatformsUrl = channelId => {
    if (channelId && channels) {
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)
        return channelEntity.apiUrls.platforms
      }
    }

    return null
  }

  const [{data: factData, isLoading: isFactDataLoading}, getFactData] = useApiFetch(getFactDataUrl())
  const [{data: planData, isLoading: isPlanDataLoading}, getPlanData] = useApiFetch(getPlanDataUrl())

  const filters = {
    dateRange: {
      type: 'dateRange',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'dateRange',
      getValueFromUrl: values => {
        return [values.dateStart ? values.dateStart[0] : '', values.dateEnd ? values.dateEnd[0] : '']
      },
      getValueToUrl: (params, [dateStart, dateEnd]) => {
        if (dateStart && dateEnd) {
          params.set('dateStart', dateStart)
          params.set('dateEnd', dateEnd)
        } else {
          params.delete('dateStart', dateStart)
          params.delete('dateEnd', dateEnd)
        }
      },
      setFilterParams: (params, values) => {
        params.set('date[after]', values[0])
        params.set('date[before]', values[1])
        setStartDate(values[0])
        setEndDate(values[1])
      },
      value: null,
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: filterDateFormat,
      required: true,
      isMonth: true,
    },
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      name: 'channel',
      setFilterParams: (params, filterValue) => {
        params.set('face.channel.id', filterValue.value)
        setChannelFilter(filterValue)
      },
      getValueToUrl: (params, channel) => {
        if (channel && channel.value) {
          params.set('channel', channel.value)
        } else {
          params.delete('channel')
        }
      },
      className: 'pointer',
      required: true,
    },
    pool: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.pool'),
      getOptionsUrlFunction: apiUrls.get.pools,
      name: 'pool',
      setFilterParams: (params, values) => {
        for (let filterValue of values) {
          params.append('face.advertising.company.pool.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, pool) => {
        if (pool && pool.length) {
          params.delete('pool[]')
          for (let poolItem of pool) {
            params.append('pool[]', poolItem.value)
          }
        } else {
          params.delete('pool[]')
        }
      },
      isEmptyOption: true,
      isMulti: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: filterValues => {
        return filterValues && filterValues.pool && filterValues.pool.length
          ? apiUrls.get.clients() + '&' + filterValues.pool.map(pool => 'pool.id[]=' + pool.value).join('&')
          : apiUrls.get.clients()
      },
      name: 'company',
      setFilterParams: (params, values) => {
        const companyIds = []

        for (let filterValue of values) {
          params.append('face.advertising.company.id[]', filterValue.value)
          companyIds.push(filterValue.value)
        }
        setCompanyFilters(companyIds)
      },
      getValueToUrl: (params, company) => {
        if (company && company.length) {
          params.delete('company[]')
          for (let companyItem of company) {
            params.append('company[]', companyItem.value)
          }
        } else {
          params.delete('company[]')
        }
      },
      depends: ['pool'],
      firstNotWaitDepend: true,
      additionalOptions: [
        selectAllOption
      ],
      required: true,
      isMulti: true,
    },
    product: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.product'),
      getOptionsUrlFunction: ({company}) => {
        let params = new URLSearchParams();

        for (let companyItem of company) {
          params.append('company.id[]', companyItem.value)
        }

        params.append('pagination', false)
        return apiUrls.get.products() + '?' + params.toString()
      },
      name: 'product',
      setFilterParams: (params, values) => {
        const productsIds = []

        for (let filterValue of values) {
          params.append('face.advertising.product.id[]', filterValue.value)
          productsIds.push(filterValue.value)
        }

        setProductFilters(productsIds)
      },
      getValueToUrl: (params, product) => {
        if (product && product.length) {
          params.delete('product[]')
          for (let productItem of product) {
            params.set('product[]', productItem.value)
          }
        } else {
          params.delete('product[]')
        }
      },
      className: 'has-separator pointer',
      depends: ['company'],
      isMulti: true,
    },
    manager: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.manager'),
      getOptionsUrlFunction: apiUrls.get.managers,
      getValueToUrl: (params, manager) => {
        if (manager && manager.length) {
          params.delete('manager[]')
          for (let managerItem of manager) {
            params.append('manager[]', managerItem.value)
          }
        } else {
          params.delete('manager[]')
        }
      },
      name: 'manager',
      setFilterParams: (params, values) => {
        for (let filterValue of values) {
          params.append('face.advertising.manager.id[]', filterValue.value)
        }
      },
      className: 'has-separator pointer',
      isMulti: true,
    },
    type: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.type'),
      getOptionsUrlFunction: apiUrls.get.advertTypes,
      name: 'type',
      setFilterParams: (params, filterValue) => {
        if (filterValue && filterValue.value) {
          params.set('face.advertising.type.id', filterValue.value)
        }
      },
      getValueToUrl: (params, type) => {
        if (type) {
          params.set('type', type.value)
        } else {
          params.delete('type')
        }
      },
      className: 'pointer',
    },
    advertising: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.advertising'),
      getOptionsUrlFunction: ({company, channel, dateRange, pool}) => {
        return getAdvertisingsUrl(company, channel, dateRange, pool)
      },
      name: 'advertising',
      setFilterParams: (params, values) => {
        for (let filterValue of values) {
          params.append('face.advertising.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, id) => {
        if (id && id.length) {
          params.delete('advertising[]')
          for (let idItem of id) {
            params.append('advertising[]', idItem.value)
          }
        } else {
          params.delete('advertising[]')
        }
      },
      depends: ['company', 'channel', 'dateRange', 'pool'],
      className: 'has-separator pointer',
      isMulti: true,
    },
    platform: {
      type: 'select',
      value: null,
      placeholder: i18n.t('face.list.face'),
      getOptionsUrlFunction: ({channel}) => {
        return getPlatformsUrl(channel.value) + '?pagination=false'
      },
      name: 'platform',
      setFilterParams: (params, values, filterValues) => {
        const paramName = (filterValues && filterValues.channel && filterValues.channel.code === TvChannel.getCode())
          ? 'face.hpa.platform.id[]'
          : 'face.platform.id[]'

        for (let filterValue of values) {
          params.append(paramName, filterValue.value)
        }
      },
      getValueToUrl: (params, id) => {
        if (id && id.length) {
          params.delete('platform[]')
          for (let idItem of id) {
            params.append('platform[]', idItem.value)
          }
        } else {
          params.delete('platform[]')
        }
      },
      depends: ['channel'],
      className: 'has-separator pointer',
      isMulti: true,
    },
    legal: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.legal'),
      getOptionsUrlFunction: apiUrls.get.contractorLegals,
      setFilterParams: (params, values) => {
        for (let filterValue of values) {
          params.append('face.contractor.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, legal) => {
        if (legal && legal.length) {
          params.delete('legal[]')
          for (let legalItem of legal) {
            params.set('legal[]', legalItem.value)
          }
        } else {
          params.delete('legal[]')
        }
      },
      name: 'legal',
      depends: ['channel'],
      className: 'has-separator pointer',
      isMulti: true,
    },
  }

  useEffect(() => {
    getChannels()
  }, [])

  useEffect(() => {
    if (facesMap) {
      const valuesFromUrl = getUrlParams()

      if (valuesFromUrl && valuesFromUrl.budget_not_null && valuesFromUrl.budget_not_null.length) {
        setIsFactFilter(true)
      }
    }
  }, [facesMap])

  useEffect(() => {
    if (filterParams && channelFilter && channelFilter.value) {
      const params = getParams()
      const fileFilterParams = new URLSearchParams()

      for (let key in params) {
        if (!params.hasOwnProperty(key)) {
          continue
        }

        if (Array.isArray(params[key])) {
          for (let value of params[key]) {
            fileFilterParams.append(key, value)
          }
        } else {
          fileFilterParams.append(key, params[key])
        }
      }

      setFileFilterParams(fileFilterParams)
    }
  }, [filterParams])

  useEffect(() => {
    if (factData && planData && channelFilter) {
      const facesMap = new Map()

      const periodsWithFacesIds = {}

      for (let period of factData) {
        periodsWithFacesIds[period.dateStart] = {
          dateStart: period.dateStart,
          dateEnd: period.dateEnd,
        }

        if (!('facesIds' in periodsWithFacesIds[period.dateStart])) {
          periodsWithFacesIds[period.dateStart].facesIds = new Set()
        }
        
        for (let face of period.data) {
          const faceId = face.item.split('/').pop()
          periodsWithFacesIds[period.dateStart].facesIds.add(faceId)

          facesMap.set(getFormattedDate(period.dateStart, filterDateFormat) + face.item, {
            factBudget: face.properties.budget,
            dateStart: period.dateStart,
            dateEnd: period.dateEnd,
          })
        }
      }

      for (let period of planData) {
        if (!(period.dateStart in periodsWithFacesIds)) {
          periodsWithFacesIds[period.dateStart] = {
            dateStart: period.dateStart,
            dateEnd: period.dateEnd,
          }
        }

        if (!('facesIds' in periodsWithFacesIds[period.dateStart])) {
          periodsWithFacesIds[period.dateStart].facesIds = new Set()
        }

        for (let face of period.data) {
          const faceId = face.item.split('/').pop()
          const faceKey = getFormattedDate(period.dateStart, filterDateFormat) + face.item
          periodsWithFacesIds[period.dateStart].facesIds.add(faceId)

          if (facesMap.has(faceKey)) {
            const existedFace = facesMap.get(faceKey)

            facesMap.set(faceKey, {
              ...existedFace,
              planBudget: face.properties.budget,
              dateStart: period.dateStart,
              dateEnd: period.dateEnd,
            })
          } else {
            facesMap.set(faceKey, {
              planBudget: face.properties.budget,
              dateStart: period.dateStart,
              dateEnd: period.dateEnd,
            })
          }
        }
      }

      const facesIds = []

      facesMap.forEach((face, faceKey) => {
        facesIds.push(faceKey.split('/').pop())
      })

      getFaces(facesIds)
      setFacesMap(facesMap)

      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        getClientCheckRows(channelEntity.facesName + '.id[]', facesIds)

        if (Roles.hasAccess('FinancialReportAdditionalRead')) {
          getCommissions(channelEntity.apiUrls.faceCommissions, periodsWithFacesIds)
        }

      }
    }
  }, [factData, planData])

  useEffect(() => {
    if (faces && clientCheckRows && (Roles.hasAccess('FinancialReportRead') || commissions) && facesMap) {
      const newFacesMap = new Map(JSON.parse(JSON.stringify([...facesMap])))

      const faceDataMap = new Map()

      for (let face of faces) {
        faceDataMap.set(face.id, face)
      }

      newFacesMap.forEach((face, key) => {
        const faceId = Number(key.split('/').pop())

        if (faceDataMap.has(faceId)) {
          const faceData = faceDataMap.get(faceId)

          const dataFromFace = {
            code: faceData.code,
            platform: faceData.platform.name,
            company: faceData.advertising.company.name,
          }

          if (faceData.contractor && faceData.contractor.name) {
            dataFromFace.contractor = faceData.contractor.name
          }

          if (faceData.advertising.company.pool && faceData.advertising.company.pool.name) {
            dataFromFace.pool = faceData.advertising.company.pool.name
          }

          if (faceData.advertising.product && faceData.advertising.product.name) {
            dataFromFace.product = faceData.advertising.product.name
          }

          if (faceData.advertising.manager && faceData.advertising.manager.name) {
            dataFromFace.manager = faceData.advertising.manager.name
          }

          if (faceData.advertising.type && faceData.advertising.type.name) {
            dataFromFace.advertisingType = faceData.advertising.type.name
          }

          dataFromFace.advertising = faceData.advertising.name
          dataFromFace.advertisingPageLink = `/advertisings/${faceData.advertising.id}`
          dataFromFace.factDetailPageLink = getFactDataPageUrl(face, faceData)

          newFacesMap.set(key, {
            ...face,
            ...dataFromFace
          })
        }
      })

      for (let clientCheckRow of clientCheckRows) {
        const face = clientCheckRow.faces && clientCheckRow.faces[0]
        const dateStart = clientCheckRow.clientCheck.dateStart

        const faceKey = getFormattedDate(dateStart, filterDateFormat) + face['@id']

        if (face) {
          if (newFacesMap.has(faceKey)) {
            const existedFace = newFacesMap.get(faceKey)

            const faceParams = {
              ...existedFace,
              approved: clientCheckRow.approved,
              clientBudget: clientCheckRow.budget
            }

            newFacesMap.set(faceKey, {
              ...faceParams,
            })
          }
        }
      }

      if (Roles.hasAccess('FinancialReportAdditionalRead') && commissions) {
        for (let commission of commissions) {
          if (commission.face && commission.dateStart) {
            const faceKey = getFormattedDate(commission.dateStart, filterDateFormat) + commission.face

            if (newFacesMap.has(faceKey)) {
              const existedFace = newFacesMap.get(faceKey)

              const commissionData = {
                ...existedFace,
                commissionRatio: commission.ratio * 100,
              }

              if ('value' in commission) {
                commissionData['commissionValue'] = commission.value
              }

              if (commission.commissions[0]) {
                commissionData['commissionComment'] = commission.commissions[0].comment
              }

              if (commission.contractType && commission.contractType.return) {
                commissionData['commissionType'] = commission.contractType.return
              }

              newFacesMap.set(faceKey, commissionData)
            }
          }
        }

        newFacesMap.forEach((face, key) => {
          const consumption = ('factBudget' in face) ? ((face.factBudget || 0) - (face.commissionValue || 0)) : ''
          let marginality = ''

          if ('clientBudget' in face) {
            marginality = face.clientBudget ? (face.clientBudget - (face.factBudget || 0)) : 0
          }

          if (marginality !== '' && face.commissionType && face.commissionType === 'Возврат') {
            marginality += (face.commissionValue || 0)
          }

          let marginalityPercent = ''

          if ('clientBudget' in face) {
            marginalityPercent = (marginality && face.clientBudget) ? ((marginality / face.clientBudget) * 100) : 0
          }

          newFacesMap.set(key, {
            ...face,
            consumption,
            marginality,
            marginalityPercent,
          })
        })
      }


      setFacesMap(newFacesMap)
    }
  }, [faces, clientCheckRows, commissions])

  useEffect(() => {
    if (isFactFilter) {
      const facesIrisWithFact = []

      facesMap.forEach((face, key) => {
        if (face.factBudget) {
          facesIrisWithFact.push(key)
        }
      })

      setFilteredFacesIris(facesIrisWithFact)
    } else {
      setFilteredFacesIris([])
    }

    if (fileFilterParams && fileFilterParams.entries()) {
      if (isFactFilter) {
        fileFilterParams.set('budget_not_null', 'true')
      } else {
        fileFilterParams.delete('budget_not_null')
      }

      setFileFilterParams(fileFilterParams)
    }


  }, [isFactFilter])

  useEffect(() => {
    if (sort && Object.values(sort).length) {
      setIsSorting(true)
      let sortedFacesMap = new Map([...facesMap.entries()])

      const sortFunction = (a, b, key) => {
        if (!a[1][key]) {
          return -1
        }

        if (!b[1][key]) {
          return 1
        }

        if (typeof a[1][key] === 'number') {
          return a[1][key] - b[1][key]
        } else {
          return a[1][key].localeCompare(b[1][key])
        }
      }

      for (let key in sort) {
        const direction = sort[key]

        if (direction === 'asc') {
          sortedFacesMap = new Map([...sortedFacesMap.entries()].sort((a, b) => sortFunction(a, b, key)))
        } else if (direction === 'desc') {
          sortedFacesMap = new Map([...sortedFacesMap.entries()].sort((a, b) => sortFunction(a, b, key)).reverse())
        }
      }

      setFacesMap(sortedFacesMap)
      setIsSorting(false)
    }
  }, [sort])

  const getParams = () => {
    if (filterParams) {
      const params = groupParamsByKey(filterParams)
      params['interval'] = 'months'
      params['order[face.id]'] = 'asc'
      params['t[]'] = 'simple_props'
      params['props[]'] = 'budget'

      return params
    }

    return {}
  }

  const getFactDetailDates = (face, faceData) => {
    const periodStartDate = getUtcDate(face.dateStart).toDate()
    const periodEndDate = getUtcDate(face.dateEnd).toDate()

    const advertisingStartDate = getUtcDate(faceData.advertising.dateStart).toDate()
    const advertisingEndDate = getUtcDate(faceData.advertising.dateEnd).toDate()

    if (advertisingEndDate < periodStartDate || advertisingStartDate > periodEndDate) {
      return ['', '']
    }

    const start = advertisingStartDate > periodStartDate
      ? advertisingStartDate
      : periodStartDate

    const end = advertisingEndDate < periodEndDate
      ? advertisingEndDate
      : periodEndDate

    return [start, end];
  }

  const getFactDataPageUrl = (face, faceData) => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const [intervalStart, intervalEnd]
          = getFactDetailDates(face, faceData)

        if (!intervalStart || !intervalEnd) {
          return ''
        }

        if (channel.code) {
          return `/advertisings/${
            faceData.advertising.id}/fact/${channel.code}?dateStart=${
            getFormattedDate(intervalStart, filterDateFormat)}&dateEnd=${
            getFormattedDate(intervalEnd, filterDateFormat)}`
        }
      }
    }
  }

  const getFaces = (facesIds) => {
    setIsFacesLoading(true)

    async function fetchData(faceIds) {
      const url = getFacesUrl()
      const params = new URLSearchParams()

      for (let group of ['id', 'code', 'face_contractor', 'advertising', 'company', 'advert_type', 'face_platform', 'client_pool', 'manager', 'product']) {
        params.append('t[]', group)
      }

      for (let faceId of faceIds) {
        params.append('id[]', faceId)
      }

      const facesResult = await api.get(url + '?' + params.toString())
      const faces = facesResult['hydra:member']

      return {
        faces
      }
    }

    const chunkedFaceIds = chunk(facesIds, 250);

    Promise.all(chunkedFaceIds.map(faceIds => fetchData(faceIds))).then(values => {
      let faces = []

      for (let value of values) {
        if (value.faces) {
          faces = faces.concat(value.faces)
        }
      }

      setFaces(faces)
    }).finally(() => {
      setIsFacesLoading(false)
    })
  }

  const getClientCheckRows = (fieldName, facesIds) => {
    setIsClientCheckRowsLoading(true)

    async function fetchData(faceIds) {
      const url = apiUrls.get.clientCheckRows()
      const params = new URLSearchParams({
        pagination: false,
        't[]': 'check:read',
        'clientCheck.dateStart[after]': startDate,
        'clientCheck.dateEnd[before]': endDate,
      })

      for (let faceId of faceIds) {
        params.append(fieldName, faceId)
      }

      const clientCheckRowsResult = await api.get(url + '?' + params.toString())
      const clientCheckRows = clientCheckRowsResult['hydra:member']

      return {
        clientCheckRows
      }
    }

    const chunkedFaceIds = chunk(facesIds, 120);
    Promise.all(chunkedFaceIds.map(faceIds => fetchData(faceIds))).then(values => {
      let clientCheckRows = []

      for (let value of values) {
        if (value.clientCheckRows) {
          clientCheckRows = clientCheckRows.concat(value.clientCheckRows)
        }
      }

      setClientCheckRows(clientCheckRows)
    }).finally(() => {
      setIsClientCheckRowsLoading(false)
    })
  }

  const getCommissions = (commissionsUrl, periodsWithFacesIds) => {
    setIsCommissionsLoading(true)

    async function fetchData(dateStart, dateEnd, faceIds) {
      const url = commissionsUrl
      const params = new URLSearchParams({
        dateStart: getFormattedDate(dateStart, filterDateFormat),
        dateEnd: getFormattedDate(dateEnd, filterDateFormat),
      })

      for (let faceId of faceIds) {
        params.append('face.id[]', faceId)
      }

      const commissionsResult = await api.get(url + '?' + params.toString())
      const commissions = commissionsResult['hydra:member']

      return {
        commissions
      }
    }

    const commissionPeriods = []

    for (let period of Object.values(periodsWithFacesIds)) {
      const chunkedFacesIds = chunk(Array.from(period.facesIds), 200)

      for (let facesIds of chunkedFacesIds) {
        commissionPeriods.push({
          dateStart: period.dateStart,
          dateEnd: period.dateEnd,
          facesIds: facesIds,
        })
      }
    }

    Promise.all(commissionPeriods.map(period => fetchData(period.dateStart, period.dateEnd, period.facesIds))
    ).then(values => {
      let commissions = []

      for (let value of values) {
        if (value.commissions) {
          commissions = commissions.concat(value.commissions)
        }
      }

      setCommissions(commissions)
    }).finally(() => {
      setIsCommissionsLoading(false)
    })
  }

  const updateData = async () => {
    if (filterParams && channelFilter && channelFilter.value) {
      const params = getParams()

      getFactData(params)
      getPlanData(params)
    }
  }

  const getXlsFileName = () => {
    const nameParams = [
      'prefinancial',
      startDate ? startDate.substr(0, 7) : '',
    ]

    if (companyFilters) {
      for (let company of companyFilters) {
        nameParams.push('client-' + company)
      }
    }

    if (productFilters) {
      for (let product of productFilters) {
        nameParams.push('product-' + product)
      }
    }

    nameParams.push((new Date()).getTime() + '.xlsx')

    return nameParams.join('_')
  }

  const isAnyLoading = () => isFactDataLoading || isPlanDataLoading || isFacesLoading || isClientCheckRowsLoading
    || isCommissionsLoading || isSorting

  const onSortClick = async (name) => {
    let order = ''

    if (sort[name] === options.ascSortOrder) {
      order = options.descSortOrder
    } else if (sort[name] === options.descSortOrder) {
      order = ''
    } else {
      order = options.ascSortOrder
    }

    changeSort(name, order)
  }

  const changeSort = async (name, value) => {
    await setSort({
      ...sort,
      [name]: value
    })
  }

  return (
    <div className="row content">
      <div className="table-edit-client advertising-table advertising-financial">
        <TopMenu title={i18n.t('clients.edit.revise')}>
          <Protected access={'FinancialInvoicesDownload'}>
            {filterParams &&
              <>
                <DownloadFileButton
                  text={i18n.t('clients.edit.get_xls_prefinancial_file')}
                  getFunction={() => xlsServiceProvider.getForPrefinancial(fileFilterParams)}
                  fileName={getXlsFileName()}
                />
                <button
                  className={'get-xls-button'}
                  onClick={updateData}
                >{'Показать в таблице'}</button>
              </>
            }
          </Protected>
        </TopMenu>

        {channels &&
          <>
            <Filter
              filters={Object.values(filters)}
              getData={setFilterParams}
              delay={0}
            />
            {facesMap &&
              <label>
                <Checkbox checked={isFactFilter}
                          onChange={value => {
                            setIsFactFilter(value)
                            if (value) {
                              setUrlParam('budget_not_null', true)
                            } else {
                              removeUrlParam('budget_not_null')
                            }
                          }}
                          className={'financial-report__filter-checkbox'} />
                {i18n.t('clients.advertising.spent')}
              </label>
            }
          </>
        }

        {isAnyLoading() &&
          <div className={'loader-background'} />
        }

        <div className={'loader' + (isAnyLoading() ? '' : ' hidden')}>
          <i className="fa fa-refresh fa-spin" />
        </div>

        {facesMap && !isAnyLoading() &&
          <Table
            facesMap={facesMap}
            filteredFaces={filteredFacesIris}
            sort={sort}
            onSortClick={onSortClick}
          />
        }
      </div>
    </div>
  )
}