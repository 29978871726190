import React, {Component} from 'react';
import Select from 'react-select';
import i18n from "i18next";

class Parts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partName: null,
      partsDateStart: null,
      partsDateEnd: null
    }
  }

  onPartsDateChange = (date) => {
    this.props.onChange({
      ...this.props.part,
      partsDateStart: date
    })
  }

  onPartsChange = (object) => {
    this.props.onChange({
      ...this.props.part,
      partName: object
    })
  }

  onPartsEndChange = (date) => {
    this.props.onChange({
      ...this.props.part,
      partsDateEnd: date
    })
  }


  render() {
    const partsOption = Object.entries(this.props.parts).map((part) => {
      return {value: part[1], label: part[1]}
    })

    return (
      <div>
        <div className="parts-select">
          <div className={'parts-select__row'}>
            <Select
              options={partsOption}
              value={this.props.part.partName}
              onChange={this.onPartsChange}
              className="react-select-container select-pseudo-input small"
              classNamePrefix="react-select"
              placeholder={i18n.t('clients.advertising.select_parts')} />

            <div className="vertical-center">
              <button onClick={this.props.deletePart}>
                <i className="fal fa-trash-alt icon-delete" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Parts;