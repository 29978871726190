import React, {useState, useEffect} from 'react';
import i18n from "i18next";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import Table from "./Table";
import useDelete from "../../../Components/Hooks/useDelete";
import DigitalForm from "../Detail/Faces/DigitalForm";
import InternetForm from "../Detail/Faces/InternetForm";
import {cloneDeep} from 'lodash';
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {toast} from "react-toastify";
import {InternetChannel} from "../../../entities/channel/InternetChannel";
import {DigitalChannel} from "../../../entities/channel/DigitalChannel";
import TvForm from "../Detail/Faces/TvForm";
import {TvChannel} from "../../../entities/channel/TvChannel";
import DigitalFaceInfo from "../Detail/Faces/DigitalFaceInfo";
import MenuClient from "../../../Components/MenuClient";
import ArrowLink from "../../../Components/Links/ArrowLink";
import {sendPlanValues} from "../Utils";
import {FaceFactory} from "../../../entities/face/FaceFactory";

function List(props) {
  const advertisingId = props.match.params.advertisingId
  const channelCode = props.match.params.channel

  const [channelEntity] = useState(ChannelFactory.create(channelCode))
  const [companyId, setCompanyId] = useState(null)

  if (!channelEntity) {
    toast.error('channel not found')
  }

  const [selectedFaceForForm, setSelectedFaceForForm] = useState(null)
  const [selectedFaceForInfo, setSelectedFaceForInfo] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const [{data: company}, getCompany] = useApiFetch(apiUrls.get.company(companyId))
  const [{data: advertising}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channelByCode(channelCode))

  const [{data: faces}, fetchFaces] = useApiFetch(channelEntity.getApiUrl('faces'))

  const deleteFace = useDelete(channelEntity.getApiUrlFunctionWithId('faces'))

  const getFaces = () => {
    fetchFaces({
      't[]': getFacesParamsGroups(),
      'advertising.id': advertising.id
    })
  }

  const getFacesParamsGroups = () => {
    const faceEntity = FaceFactory.create(channelCode)

    return faceEntity.listGroupParams
  }

  const addNewFace = () => {
    const newFace = {
      isNew: true,
    }

    setSelectedFaceForForm(newFace)
  }

  const copyFace = (face) => {
    const copiedFace = {
      id: face.id,
      isNew: true,
    }

    setSelectedFaceForForm(copiedFace)
  }

  useEffect(() => {
    getCompany()
    getAdvertising({
      't[]': ['advert:read', 'legals_taxes']
    })
    getChannels()
  }, [])

  useEffect(() => {
    if (company && !company.id) {
      props.history.push('/clients')
    }
  }, [company])

  useEffect(() => {
    if (advertising && !advertising.id) {
      props.history.push('/advertisings')
    }

    if (advertising && advertising.id) {
      getFaces()
    }

    if (advertising && advertising.company && advertising.company.id) {
      setCompanyId(advertising.company.id)
    }
  }, [advertising])

  useEffect(() => {
    if (channels && !channels[0] && company && company.id && advertising && advertising.id) {
      props.history.push(`/advertisings/${advertising.id}`)
    }
  }, [channels, company, advertising])

  useEffect(() => {
    if (companyId) {
      getCompany()
    }
  }, [companyId])

  const sendPlan = () => {
    if (!isSubscribed) {
      sendPlanValues(advertisingId)
      setIsSubscribed(true)
    } else {
      sendPlanValues(advertisingId, false)
    }
  }

  return (
    <div className={'period-faces edit-client'}>
      <div className={'period-faces__wrapper'}>
        <div>
          <MenuClient title={i18n.t('clients.advertising.faces')}>
            <div className={'period-faces__menu-buttons'}>
              <a
                className="blue-cursor-button add-link"
                onClick={addNewFace}
              >
                <i className="fal fa-plus mr-for-icon"/>
                {i18n.t('clients.advertising.add_platform')}
              </a>
              <div>
                {channelEntity instanceof InternetChannel &&
                  <button onClick={() => sendPlan()} className={'btn-icon fact-table__buttons-send'}
                          title={i18n.t('clients.advertising.save_plan_values')}>
                    <i className="fal fa-cloud" />
                  </button>
                }
              </div>
            </div>
          </MenuClient>
        </div>
        <div>
          <ArrowLink
            to={`/advertisings/${advertisingId}`}
            text={i18n.t('clients.advertising.to_advertising')}
          />
        </div>
        <div className={'period-faces__items'}>
          {(faces && faces.length > 0)
            ? <Table
              faces={faces}
              deleteFace={deleteFace}
              getFaces={getFaces}
              channelEntity={channelEntity}
              setSelectedFace={setSelectedFaceForForm}
              setSelectedFaceForInfo={setSelectedFaceForInfo}
              onCopyClick={copyFace}
            />
            :
            <div>{i18n.t('periods.face.empty_list')}</div>
          }

          {selectedFaceForForm &&
            <>
              {channelEntity instanceof DigitalChannel &&
                <DigitalForm
                  faceId={selectedFaceForForm.id}
                  isNew={selectedFaceForForm.isNew}
                  advertisingId={advertisingId}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFaceForForm}
                  getAdvertPeriod={() => {}}
                  companyId={props.match.params.companyID}
                />
              }

              {channelEntity instanceof InternetChannel &&
                <InternetForm
                  faceId={selectedFaceForForm.id}
                  isNew={selectedFaceForForm.isNew}
                  advertisingId={advertisingId}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFaceForForm}
                  getAdvertPeriod={getFaces}
                  companyId={props.match.params.companyID}
                />
              }

              {channelEntity instanceof TvChannel &&
                <TvForm
                  faceId={selectedFaceForForm.id}
                  isNew={selectedFaceForForm.isNew}
                  advertisingId={advertisingId}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFaceForForm}
                  getAdvertPeriod={getFaces}
                  companyId={props.match.params.companyID}
                />
              }
            </>
          }

          {selectedFaceForInfo &&
            <>
              {channelEntity instanceof DigitalChannel &&
                <DigitalFaceInfo
                  face={selectedFaceForInfo}
                  setSelectedFaceForInfo={setSelectedFaceForInfo}
                  companyId={props.match.params.companyID}
                  closeModal={() => setSelectedFaceForInfo(null)}
                  advertising={advertising}
                />
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default List;