import React, {useState, useEffect, Fragment} from 'react';
import i18n from "i18next";
import ErrorBoundary from "../../../../Components/ErrorBoundary";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import PeriodsTable from "./PeriodsTable";
import {toast} from "react-toastify"
import {
  getFormattedDate, subscribeToAdvertisingsUpdates,
} from "./Utils";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import Roles from "../../../../roles/Roles";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import sprintf from 'sprintf-js'
import BackButtons from "../components/BackButtons";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import DigitalForm from "../Faces/DigitalForm";
import {InternetChannel} from "../../../../entities/channel/InternetChannel";
import InternetForm from "../Faces/InternetForm";
import {TvChannel} from "../../../../entities/channel/TvChannel";
import TvForm from "../Faces/TvForm";
import PullHistory from "./PullHistory";
import {FaceFactory} from "../../../../entities/face/FaceFactory";
import {Link} from "react-router-dom";
import TopMenu from "../../../../Components/TopMenu";

const vsprintf = sprintf.vsprintf

function List(props) {
  const advertisingId = +props.match.params.advertisingId
  const channelCode = props.match.params.channel

  const [channelEntity] = useState(ChannelFactory.create(channelCode))
  const [selectedFace, setSelectedFace] = useState(null)
  const [company, setCompany] = useState(null)
  const searchParams = new URLSearchParams(props.location.search)

  const dateStart = searchParams.get('dateStart')
  const dateEnd = searchParams.get('dateEnd')

  const [factResultsUpdated, setFactResultsUpdated] = useState({})
  const [subscribeMediaplanUpdateError, setSubscribeMediaplanUpdateError] = useState(false)
  const [currentPeriod, setCurrentPeriod] = useState(null)
  const [channel, setChannel] = useState(null)
  const [subscribedToUpdates, setSubscribedToUpdates] = useState(false)
  const [pullHistories, setPullHistories] = useState(null)

  const returnField = Roles.hasAccess('FinancialCommissionsRead')
    ? {
      code: "return",
      name: i18n.t('clients.advertising.return'),
      required: false,
      type: "return"
    }
    : {}

  const getFacesUrl = () => {
    if (channelEntity) {
      return channelEntity.getApiUrl('faces')
    }
  }

  const [{data: advertising, isLoading: isAdvertisingLoading}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))
  const [{data: periods}, getPeriods] = useApiFetch(apiUrls.get.advertisingPeriods())
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: advertFaces}, getAdvertFaces] = useApiFetch(getFacesUrl)

  const [{}, getFactResults] = useApiPost(apiUrls.post.getAdvertisingFactResults,
    '',
    '',
    () => {
    toast.info(i18n.t('mediaplans.page.task_in_stack'))
  })

  const [{data: properties}, getProperties] = useApiFetch(channelEntity.getApiUrl('properties'))
  const [{data: propertiesForAdvertising}, getPropertiesForAdvertising] = useApiFetch(channelEntity.getApiUrl('properties'))
  const [{data: advertPullHistories, isLoading: isHistoryLoading}, getAdvertPullHistories] = useApiFetch(apiUrls.get.pullHistories())

  const getAdvertsPeriods = () => {
    getPeriods({
      't[]': 'target',
      'target.advertising.id': advertisingId,
      'dateStart[after]': dateStart,
      'dateEnd[before]': dateEnd,
      'target.channel.id': channel.id,
    })
  }

  const getFacesParamsGroups = () => {
    const faceEntity = FaceFactory.create(channelEntity.code)

    return faceEntity.listGroupParams
  }

  const getFaces = () => {
    getAdvertFaces({
      't[]': getFacesParamsGroups(),
      'advertising.id': advertisingId
    })
  }

  const getFields = () => {
    let fields = properties

    if (fields && fields.length) {
      fields.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))
    }

    const isReturnField = fields && fields.find(field => field.code === 'return')

    if (fields && !isReturnField && returnField.code) {
      fields.unshift(returnField)
    }

    return fields
  }

  useEffect(() => {
    fetchAdvertising()
    getChannels()
    getFaces()
  }, [])

  useEffect(() => {
    if (channels && channels.length) {
      const channel = channels.find(channel => channel.code === channelCode)

      if (channel) {
        setChannel(channel)
      }
    }
  }, [channels])

  useEffect(() => {
    if (advertPullHistories) {
      const pullMap = new Map()

      for (let historyItem of advertPullHistories) {
        const key = `${historyItem.platform.id}${historyItem.channel.id}${historyItem.face}${historyItem.error}`

        if (!pullMap.has(key, historyItem)) {
          pullMap.set(key, historyItem)
        }
      }

      const history = Array.from(pullMap, ([name, value]) => ({...value }))

      if (history && history.length) {
        setPullHistories(history)
      } else {
        setPullHistories(null)
      }
    }
  }, [advertPullHistories])

  useEffect(() => {
    if (advertising && channel) {
      getAdvertsPeriods()
      getProperties({
        entity: 'face'
      })

      getPropertiesForAdvertising({
        entity: 'advertising'
      })
    }

    if (advertising && advertising.company && advertising.company.id) {
      setCompany(advertising.company)
    }

    const isAdvertisingPull = (result) => {
      return result.advertising && result.status && result.dateStart && result.dateEnd
        && advertising.id === result.advertising && result.dateStart === dateStart
        && result.dateEnd === dateEnd
    }

    if (advertising && !subscribedToUpdates) {
      subscribeToAdvertisingsUpdates(result => {
        if (isAdvertisingPull(result)) {
          fetchAdvertising()
          getAdvertsPeriods()
          toast.success(result.message)
        }
      }, result => {
        if (isAdvertisingPull(result)) {
          setSubscribeMediaplanUpdateError(true)
          toast.error(result.message)
        }
      }, result => {
        if (isAdvertisingPull(result)) {
          getFactResultsHistory()
          toast.warn(result.message)
        }
      })

      setSubscribedToUpdates(true)
    }
  }, [channel, advertising])

  useEffect(() => {
    if (advertising && periods) {
      for (let period of periods) {
        if (period.target && period.target.advertising && period.target.advertising.company 
          && period.target.advertising.company.id) {
          setCurrentPeriod(period)
        }
      }
    }
  }, [periods])

  const fetchAdvertising = () => {
    const advertParams = {
      't[]': ['advert:read', 'legals_taxes'],
    }

    getAdvertising(advertParams)
  }

  const getFactResultsCommand = async () => {
    const params = {
      id: advertisingId,
      dateStart,
      dateEnd,
    }

    getFactResults({}, params)
  }

  const getFactResultsHistory = () => {
    getAdvertPullHistories({
      'advertising.id': advertisingId,
      'exists[error]': true,
      'order[id]': 'desc',
      'requestedDateStart[before]': dateEnd,
      'requestedDateEnd[after]': dateStart,
      't[]': ['face_platform', 'face_channel'],
    })
  }

  const Title = () => (
    <>
      <div>{getFormattedDate(dateStart, 'Y') + ' '}
      {i18n.t(`clients.advertising.${getFormattedDate(dateStart, 'MMMM').toLowerCase()}`)} {channel.name}</div>
      <div>{i18n.t('mediaplans.page.product')} "{advertising.product.name}"</div>
    </>
  )

  return (
    <div className="container-fluid padding-side-15 mediaplan-page">
      <div className="row">
        {advertising && channel &&
          <TopMenu title={
            <Fragment>
              <div className={'title-text'}>{i18n.t('clients.advertising.advertising_fact_detail')}</div>
              <div className={'title'}>{<Link to={`/advertisings/${advertising.id}`}>
                {`(${advertising.id}) ${advertising.name}`}
              </Link>} <div className={'title-text'}>{' (' + getFormattedDate(advertising.dateStart, 'DD.MM.Y') + '-' +
                getFormattedDate(advertising.dateEnd, 'DD.MM.Y') + ' ' + (advertising.targets[0]
                  ? advertising.targets[0].channel.code
                  : '') + ' "' + advertising.product.name + '")'}</div></div>
            </Fragment>
          } />
        }

        {company &&
          <BackButtons
            companyId={company.id}
            advertisingId={advertisingId}
            channelEntity={channelEntity}
            startDate={dateStart}
            endDate={dateEnd}
          />
        }

        <div className={'loader' + (isAdvertisingLoading ? '' : ' hidden')}>
          <i className="fa fa-refresh fa-spin" />
        </div>
        <ErrorBoundary
          errorText={i18n.t('mediaplans.page.errors.advert_show_error')}
          className={'boundary-error mediaplan-error'}
        >
          {!channelEntity &&
            <span>{vsprintf(i18n.t('mediaplans.page.errors.channel_not_found'), channelCode)}</span>
          }

          {advertising && channel && company && channelEntity && getFields() && advertFaces &&
          <PeriodsTable
            fields={getFields()}
            advert={advertising}
            channel={channel}
            companyId={company.id}
            advertFaces={advertFaces}
            setSelectedFace={setSelectedFace}
            dateStart={dateStart}
            dateEnd={dateEnd}
            channelEntity={channelEntity}
            factResultsUpdated={factResultsUpdated}
            getFactResultsCommand={getFactResultsCommand}
            getFactResultsHistory={getFactResultsHistory}
          />
          }

          {selectedFace && channelEntity && company &&
            <>
              {channelEntity instanceof DigitalChannel &&
                <DigitalForm
                  faceId={selectedFace.id}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getAdvertsPeriods}
                  companyId={company.id}
                />
              }

              {channelEntity instanceof InternetChannel &&
                <InternetForm
                  faceId={selectedFace.id}
                  advertisingId={advertisingId}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getAdvertsPeriods}
                />
              }

              {channelEntity instanceof TvChannel &&
                <TvForm
                  faceId={selectedFace.id}
                  advertisingId={advertisingId}
                  getAdvertFaces={getFaces}
                  setSelectedFace={setSelectedFace}
                  getAdvertPeriod={getAdvertsPeriods}
                  companyId={company.id}
                />
              }
            </>
          }

          {channelEntity instanceof InternetChannel && pullHistories &&
            <PullHistory
              items={pullHistories}
              setItems={setPullHistories}
              advertising={advertising}
              getItems={getFactResultsHistory}
              isLoading={isHistoryLoading}
              />
          }
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default List