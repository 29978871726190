export class DigitalFace {
  listGroupParams = [
    'face_details',
    'face_factors',
    'strategy',
    'face_channel',
    'face_department',
    'face_tracking',
    'face_media',
    'format',
    'subtype',
    'face_datasources',
    'advertising',
    'face_rim',
    'id',
  ]
}