import React from 'react';
import i18n from "i18next";
import FormattedNumber from "../../../../Components/FormattedNumber";
import {getCoefficientPercent} from "../../../../utils";

const quantityFieldCallbackClass = '\\App\\Service\\Properties\\UnitsCount'

const getUnitCost = (face, unit, properties) => {
  if (unit.unitCost) {
    return unit.unitCost
  }

  const quantity = getQuantityValue(face, properties)
  const budgetWithoutDiscount = (face && face.budget_without_discount) ? face.budget_without_discount.budget.value : null

  if (quantity && budgetWithoutDiscount && unit.ratio) {
    return (budgetWithoutDiscount / (quantity * unit.ratio))
  }

  return ''
}

const getQuantityValue = (face, properties) => {
  const quantityField = properties.find(field => (field.callback && field.callback === quantityFieldCallbackClass))

  if (!quantityField || !quantityField.code) {
    return null
  }

  const quantityCode = quantityField.code

  return (face[quantityCode] && face[quantityCode].value) ? face[quantityCode].value : null
}

export default function FaceUnits({face, properties}) {
  const unit = face.faceUnits[0]

  return (
    <div className={'face-unit wrapper'}>
      {unit &&
        <>
          <div className={'face-unit__item'} title={i18n.t('periods.face.unit_price')}>
            <FormattedNumber
              value={getUnitCost(face, unit, properties)}
              type={'float'} />
          </div>

          {unit.ratio &&
          <div className={'face-unit__item'} title={i18n.t('periods.face.coefficient')}>
            <FormattedNumber
              value={unit.ratio}
              type={'float'} />
          </div>
          }

          {(unit.discount !== undefined) &&
          <div className={'face-unit__item'} title={i18n.t('periods.face.discount')}>
            <FormattedNumber
              value={getCoefficientPercent(unit.discount)}
              type={'float'} />
          </div>
          }
        </>
      }
    </div>
  )
}
