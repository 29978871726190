import React, {useEffect} from 'react';
import FloatInput from "../../../../Components/Inputs/FloatInput";
import i18n from "i18next";
import DatePicker from "react-datepicker";
import {getFormattedDate, getUtcDate} from "../Fact/Utils";

function QualityRow({quality, onChange, index, deleteRow}) {
  const onDateChange = (value) => {
    onChange({
      ...quality,
      dateStart: getFormattedDate(value, 'YYYY-MM-DD')
    })
  }

  const onValueChange = (name, value) => {
    onChange({
      ...quality,
      [name]: value
    })
  }

  return (
    <div key={quality.id} className={'quality-item'}>
      <div className={'quality-item__row spaced'}>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('periods.face.date_start')}</span>
          <DatePicker
            selected={getUtcDate(quality.dateStart).toDate()}
            onChange={onDateChange}
            dateFormat={i18n.t('calendar_date_format')}
          />
        </div>

        <button onClick={() => deleteRow(index)}>
          <i className="fal fa-trash-alt icon-delete" />
        </button>
      </div>

      <div className='quality-item__row'>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.super')}</span>
          <FloatInput
            onChange={value => onValueChange('super', value)}
            value={quality.super}
          />
        </div>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.fix')}</span>
          <FloatInput
            value={quality.fix}
            disabled={true}
          />
        </div>
      </div>

      <div className={'quality-item__row'}>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.superPrime')}</span>
          <FloatInput
            onChange={value => onValueChange('superPrime', value)}
            value={quality.superPrime}
            disabled={!quality.super}
          />
        </div>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.superOffPrime')}</span>
          <FloatInput
            value={quality.superOffPrime}
            disabled={true}
          />
        </div>
      </div>

      <div className={'quality-item__row'}>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.fixPrime')}</span>
          <FloatInput
            onChange={value => onValueChange('fixPrime', value)}
            value={quality.fixPrime}
            disabled={quality.super === 100}
          />
        </div>
        <div className='quality-item__row-field'>
          <span className='title'>{i18n.t('clients.advertising.fixOffPrime')}</span>
          <FloatInput
            value={quality.fixOffPrime}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export default QualityRow;