import React from 'react';
import IntInput from '../../../Components/Inputs/IntInput';
import FloatInput from "../../../Components/Inputs/FloatInput";
import Checkbox from "../../../Components/Inputs/Checkbox";

const floatType = 'float'
const intType = 'int'
const boolType = 'bool'
const stringType = 'string'

function RowCell({
                       property,
                       detail,
                       onChange,
                       onSave,
                        row,
}) {
  const isEditable = () => {
    if (!detail || detail.editable === undefined) {
      return true
    }

    return Boolean(detail ? detail.editable : false)
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      onSave()
    }
  }

  const inputProps = {
    className: 'money ' + property.code,
    value: detail ? detail.value : 0,
    disabled: !isEditable(),
    onKeyDown: handleKeyDown,
  }

  return (
    <td key={property.code} className={'money'}>
      <div className={'detail-cell'}>
          <>
            {property.type === floatType &&
              <FloatInput
                onChange={(value) => onChange(value, property)}
                {...inputProps}
                allowNegative={true}
              />
            }

            {property.type === intType &&
              <IntInput
                onChange={(value) => onChange(value, property)}
                {...inputProps}
              />
            }

            {property.type === boolType &&
              <Checkbox
                onChange={(value) => {
                  onChange(value, property)
                }}
                {...inputProps}
                checked={detail && detail.value}
              />
            }

            {property.type === stringType &&
              <span>
                {detail.value || ''}
              </span>
            }

            {detail.bottom &&
              <span className={detail.bottomClass}>{detail.bottom}</span>
            }
          </>
      </div>
    </td>
  )
}

export default RowCell
