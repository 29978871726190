import React, {useEffect, useMemo, useState} from 'react';
import {getFormattedDate, getUtcDate} from "../Fact/Utils";
import {cloneDeep} from "lodash";
import {/*dayInterval,*/ monthInterval, weekInterval, wholeInterval} from "./components/dateIntervals";
import {getValuesFromUrl} from "../../../../utils";
import i18n from "i18next";
import 'antd/dist/antd.css';
import {Button} from 'antd';
import ArrowLink from "../../../../Components/Links/ArrowLink";
import {useHistory} from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const dateFormat = 'YYYY-MM-DD';

export default function DateFilter({advertising, setFilter, channelEntity}) {
  const history = useHistory()
  const datePeriods = useMemo(() => {
    let dateStart = getUtcDate(advertising.dateStart)
    const datePeriodEnd = getUtcDate(advertising.dateEnd)
    const dates = []

    while (dateStart <= datePeriodEnd) {
      let dateEnd = cloneDeep(dateStart)
      dateEnd = getUtcDate(dateEnd.endOf("month"))

      dates.push({
        dateStart: cloneDeep(dateStart),
        dateEnd: dateEnd
      })

      dateStart = getUtcDate(cloneDeep(dateEnd).add(1, 'months').startOf('month').toISOString())
    }

    if (dates.length) {
      dates[dates.length - 1].dateEnd = datePeriodEnd
    }

    return dates;
  }, [advertising])
  const [selectedInterval, setSelectedInterval] = useState(wholeInterval)
  const [selectedPeriod, setSelectedPeriod] = useState({
    dateStart: getUtcDate(advertising.dateStart),
    dateEnd: getUtcDate(advertising.dateEnd),
  })

  const onSelectAll = () => {
    setSelectedInterval(wholeInterval)
    setSelectedPeriod({
      dateStart: getUtcDate(advertising.dateStart),
      dateEnd: getUtcDate(advertising.dateEnd),
    })
  }

  useEffect(() => {
    setFilter('interval', selectedInterval)
  }, [selectedInterval])

  useEffect(() => {
    setFilter('period', selectedPeriod)
  }, [selectedPeriod])

  useEffect(() => {
    const valuesFromUrl = getValuesFromUrl()

    if (valuesFromUrl && valuesFromUrl.dateStart && valuesFromUrl.dateEnd) {
      const dateStart = getUtcDate(valuesFromUrl.dateStart[0], dateFormat)
      const dateEnd = getUtcDate(valuesFromUrl.dateEnd[0], dateFormat)

      setSelectedPeriod({
        dateStart,
        dateEnd,
      })
    }

    if (valuesFromUrl && valuesFromUrl.interval) {
      const intervalValue = valuesFromUrl.interval[0]

      for (let interval of [monthInterval, weekInterval, wholeInterval]) {
        if (intervalValue === interval.code) {
          setSelectedInterval(interval)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (selectedPeriod) {
      setValueToUrl('dateStart', selectedPeriod.dateStart.format(dateFormat))
      setValueToUrl('dateEnd', selectedPeriod.dateEnd.format(dateFormat))
    } else {
      deleteValueFromUrl('dateStart')
      deleteValueFromUrl('dateEnd')
    }

    if (selectedInterval) {
      setValueToUrl('interval', selectedInterval.code)
    } else {
      deleteValueFromUrl('interval')
    }
  }, [selectedPeriod, selectedInterval])

  const setValueToUrl = (name, value) => {
    const params = new URLSearchParams(history.location.search)

    if (Array.isArray(value)) {
      params.delete(name)

      for (let item of value) {
        params.append(name, item.value)
      }
    } else {
      params.set(name, value)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const deleteValueFromUrl = name => {
    const params = new URLSearchParams(history.location.search)

    if (params.has(name)) {
      params.delete(name)

      const paramsString = params.toString()

      if (paramsString) {
        history.push(history.location.pathname + '?' + params.toString())
      } else {
        history.replace(history.location.pathname)
      }
    }
  }

  const onClick = (period, interval) => {
    setSelectedInterval(interval)
    setSelectedPeriod(period)
  }

  const isSelected = (period, interval) => {
    return selectedPeriod && (
      period.dateStart.format('DD-MM-YYYY') === selectedPeriod.dateStart.format('DD-MM-YYYY')
      && period.dateEnd.format('DD-MM-YYYY') === selectedPeriod.dateEnd.format('DD-MM-YYYY')
    ) && selectedInterval && selectedInterval === interval
  }

  return (
    <div className={'plan-period-dates filter-group-wrapper'}>
      <div>
        <div className="filter-group__title">
          {i18n.t('clients.advertising.select_interval')}
        </div>
      </div>

      {datePeriods &&
        <div className={'date-filter-selected'}>
          <div className={'date-filter-selected__value'}>
            {(selectedInterval.value === wholeInterval.value
                ? ''
                : (i18n.t('clients.advertising.' + selectedPeriod.dateStart.locale('ru')
                  .format('MMMM').toLowerCase()) + ' - ')
            ) + selectedInterval.label}
          </div>
          <Dropdown
            overlay={<div className={'date-filter-popup'}>
            <table>
              <tbody>
              <tr>
                <td />
                {datePeriods && datePeriods.map(period => (
                  <td key={period.dateStart}>
                    <div>
                      {i18n.t('clients.advertising.' + period.dateStart.locale('ru').format('MMMM').toLowerCase())}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Button
                    onClick={onSelectAll}
                    type={(isSelected({
                      dateStart: getUtcDate(advertising.dateStart),
                      dateEnd: getUtcDate(advertising.dateEnd),
                    }, wholeInterval) ? 'primary' : 'default')}
                  >
                    {wholeInterval.label}
                  </Button>
                </td>
                {datePeriods && datePeriods.map(period => (
                  <td key={period.dateStart}>
                    <Button
                      onClick={() => onClick(period, monthInterval)}
                      type={(isSelected(period, monthInterval) ? 'primary' : 'default')}
                    >
                      {monthInterval.label}
                    </Button>
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {datePeriods && datePeriods.map(period => (
                  <td key={period.dateStart}>
                    <Button
                      onClick={() => onClick(period, weekInterval)}
                      type={(isSelected(period, weekInterval) ? 'primary' : 'default')}
                    >
                      {weekInterval.label}
                    </Button>
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {advertising && channelEntity && datePeriods && datePeriods.map(period => (
                  <td key={period.dateStart}>
                    <ArrowLink
                      to={`/advertisings/${advertising.id}/fact/${channelEntity.code
                      }?dateStart=${getFormattedDate(period.dateStart, dateFormat)}&dateEnd=${getFormattedDate(period.dateEnd, dateFormat)}`}
                      text={i18n.t('clients.advertising.to_advertising_fact')}
                    />
                  </td>
                ))}
              </tr>
              </tbody>
            </table>
          </div>}>
            <a onClick={e => e.preventDefault()}>
              <Space>
               <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      }
    </div>
  )
}