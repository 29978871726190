import React from "react";
import Loader from "../Loader/Loader";

const nope = () => {}

function ButtonWithLoader({text, loadingText, className, isLoading = false, onClick = nope}) {
  return (
    <button className={'btn-with-loader ' + className} onClick={onClick} disabled={isLoading}>
      <>
        {isLoading ? loadingText : text}

        {isLoading &&
        <Loader />
        }
      </>
    </button>
  );
}

export default ButtonWithLoader;