import React from 'react';
import {isEqual} from 'lodash'

function RadioGroup({title, options, setSelected, selected, name}) {
  return (
    <div className="filter-group-wrapper">
      <div className="filter-group__title" >
        {title}
      </div>
      <div className="filter-group">
        {options.map(option => (
          <div className="filter-item" key={option.code}>
            <label className={selected && isEqual(selected.value, option.value) ? 'active' : ''}>
              <input type="radio"
                     id={option.code}
                     name={name}
                     //value={option.value}
                     checked={selected && isEqual(selected.value, option.value)}
                     onChange={() => setSelected(option)} />
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RadioGroup