import React from 'react'

function RadioInput(props) {
  return (
    <div className={'radio-filter-block'}>
      {props.filter.options.length && props.filter.options.map((option, index) =>
        <div key={index}>
          <label className={option.className}>
            <input
              type="radio"
              name={props.filter.name}
              onChange={() => props.onChange(option.value)}
              checked={props.filter.value === option.value}
            />{option.name}
          </label></div>
      )}
    </div>
  )
}

export default RadioInput