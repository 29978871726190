import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {api} from '../api/apiProvider';
import i18next from "i18next";
import apiUrls from "../ApiUrls";
import Protected from "./Roles/Protected";

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyId: '',
      roles: [],
      legal: null
    }
  }

  componentDidMount() {
    let companyId = null

    if (this.props.match.params.companyID) {
      companyId = this.props.match.params.companyID
    } else {
      companyId = this.props.companyId
    }

    if (this.props.match.params.legalID) {
      const legalId = this.props.match.params.legalID

      api.get(apiUrls.get.legalWithTaxes(legalId)).then((data) => {
        this.setState({legal: data})
      })
    }

    api.get("/companies/" + companyId).then((data) => {
      this.setState({companyName: data.name})
      this.setState({roles: data.roles})
    })

    this.setState({companyId: companyId});
  }

  isClientCompany = () => {
    if (this.state.roles && this.state.roles.length) {
      return this.state.roles.some((role) => {
        return role.code === 'client'
      })
    }

    return false
  }

  isContractorCompany = () => {
    if (this.state.roles && this.state.roles.length) {
      return this.state.roles.some((role) => {
        return role.code === 'contractor'
      })
    }

    return false
  }

  render() {
    return (
      <div className="menu-edit-client">
        <ul>
          {
            this.state.companyId !== undefined &&
            <>
              <li>
                <div className="title-submenu">
                  {this.state.companyName}
                </div>
              </li>
              <li>
                <NavLink exact to={"/client/" + (this.props.companyId || this.state.companyId) + "/edit"}>
                  {i18next.t('clients.edit.edit_company')}
                </NavLink>
              </li>
              <li>
                <NavLink exact to={"/client/" + (this.props.companyId || this.state.companyId) + "/cities"}>
                  {i18next.t('clients.edit.regional_company_division')}
                </NavLink>
              </li>
              <li>
                <NavLink to={"/client/" + (this.props.companyId || this.state.companyId) + "/legals"}>
                  {i18next.t('clients.edit.legal_entities_list')}
                </NavLink>
              </li>

              {this.isClientCompany() &&
                <>
                  <li>
                    <NavLink to={"/client/" + (this.props.companyId || this.state.companyId) + "/managers"}>
                      {i18next.t('clients.edit.client_managers')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/client/" + (this.props.companyId || this.state.companyId) + "/products"}>
                      {i18next.t('clients.edit.products')}
                    </NavLink>
                  </li>
                </>
              }

              {this.state.legal && this.state.legal.role && this.state.legal.role.code &&
                this.state.legal.role.code === 'contractor' &&
                <Protected access={'RepaymentIntervals'}>
                  <li>
                    <NavLink to={`/client/${this.props.companyId
                    || this.state.companyId}/legal/${this.props.match.params.legalID}/repayment_intervals`}>
                      {i18next.t('clients.edit.repayment_periods')}
                    </NavLink>
                  </li>
                </Protected>
              }

              {/*{this.isContractorCompany() &&
              <li>
                <NavLink to={"/client/" + (this.props.companyId || this.state.companyId) + "/faces"}>
                  {i18next.t('clients.edit.faces')}
                </NavLink>
              </li>
              }*/}

            </>
            ||
            <li>
              <NavLink to={"/client/new"}>
                {i18next.t('clients.edit.add_company')}
              </NavLink>
            </li>
          }
        </ul>
      </div>
    );
  }
}

export default withRouter(SubMenu);
