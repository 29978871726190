import React, {useState} from "react";
import i18n from "i18next";
import {downloadFile} from "../../utils";
import {toast} from "react-toastify";
import Loader from "../Loader/Loader";

function DownloadXlsButton({
                             getFunction,
                             fileName,
                             text = i18n.t('invoice.edit.get_xls'),
                             loadingText = i18n.t('invoice.edit.get_xls_pending'),
                             saveText = i18n.t('invoice.edit.get_xls_download')
  }) {
  const [file, setFile] = useState(null)
  const [downloadName, setDownloadName] = useState(fileName)
  const [showLoader, setShowLoader] = useState(false)

  const download = async () => {
    await downloadFile(file, downloadName)
    setFile(null)
  }

  const getFile = async () => {
    try {
      setShowLoader(true)

      const response = await getFunction()

      if (!response) {
        setShowLoader(false)
        return
      }

      const contentTypeHeader = response.headers.get("Content-Type");
      const contentDispositionHeader = response.headers.get("Content-Disposition");
      
      const headerFileNames = contentDispositionHeader.split('=')
      const headerFileName = headerFileNames.pop()

      if (headerFileName && headerFileNames.length > 0) {
        await setDownloadName(headerFileName)
      }

      const blob = await response.blob()

      const file = new File([blob], downloadName, { type: contentTypeHeader })
      setFile(file)
      setShowLoader(false)
    } catch (error) {
      console.error(error)
      toast.error(i18n.t('server_error'));
      setShowLoader(false)
      setFile(null)
    }
  }

  const onClick = async () => {
    if (!file) {
      await getFile()
    } else {
      await download()
    }
  }

  return (
    <button className={"get-xls-button" + ((file && !showLoader) ? ' active' : '')} onClick={onClick}>
      <>
        {(!file && !showLoader) && text}

        {showLoader &&
          <>
            {loadingText}
            <Loader />
          </>
        }

        {(file && !showLoader) && saveText }
      </>
    </button>
  );
}

export default DownloadXlsButton;