import React, {useState, useEffect} from 'react'
import MenuClient from '../../Components/MenuClient'
import {toast} from 'react-toastify'
import i18n from "i18next"
import apiUrls from "../../ApiUrls"
import Form from "./Form"
import useApiFetch from "../../Components/Hooks/useApiFetch";
import useApiPost from "../../Components/Hooks/useApiPost";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import {Link} from "react-router-dom";
import moment from "moment";
import {cloneDeep} from "lodash";
import FaceTable from "./FaceTable";
import useDelete from "../../Components/Hooks/useDelete";
import {getFormattedDate} from "../Advertising/Detail/Fact/Utils";
import DocumentsTable from "../Documents/Table";
import {getTaxedValue} from "../../utils";
import Protected from "../../Components/Roles/Protected";

const dateFormat = 'Y-MM-DD'
const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function Edit(props) {
  const getRegistryId = () => props.match.params.registryId

  const isNew = () => getRegistryId() === undefined

  const [formRegistry, setFormRegistry] = useState({
    'agency': '/legals/1'
  })

  const [selectedClientCompany, setSelectedClientCompany] = useState(null)
  const [selectedContractorCompany, setSelectedContractorCompany] = useState(null)
  const [selectedContractor, setSelectedContractor] = useState(null)
  const [selectedAdvertisings, setSelectedAdvertisings] = useState(null)
  const [inputErrors, setInputErrors] =useState({})

  const [{data: registry}, fetchRegistry] = useApiFetch(apiUrls.get.registry(getRegistryId()))

  const [{data: clientCompanies}, getClientCompanies] = useApiFetch(apiUrls.get.clients())

  const [{data: advertisings}, getAdvertisings] = useApiFetch(apiUrls.get.adverts())

  const [{data: tax}, getTax] = useApiFetch(apiUrls.get.tax)

  const [{data: contractorCompanies}, getContractorCompanies] = useApiFetch(apiUrls.get.contractors())

  const [{data: contractors}, getContractors] = useApiFetch(
    (!selectedContractorCompany || !selectedContractorCompany.value)
     ? apiUrls.get.contractorLegals()
     : apiUrls.get.companyContractorLegals(selectedContractorCompany.value)
  )

  const [{errors: addErrors}, addRegistry] = useApiPost(
    apiUrls.post.registry(),
    '',
    'registries.edit',
    (id) => {
      toast.success(i18n.t('registries.edit.added'))

      if (id) {
        props.history.push("/registry/" + id)
        getRegistry()
      }

    }
  )

  const [{errors: updateErrors}, updateRegistry] = useApiPatch(
    apiUrls.patch.registry,
    '',
    'registries.edit',
    () => {
      toast.success(i18n.t('registries.edit.saved'))
    }
  )

  const deleteFace = useDelete(apiUrls.delete.registryFace)

  const setSelected = () => {
    const selectedClientCompany = clientCompanies.find(company => company['@id'] === registry.client)

    if (selectedClientCompany) {
      setSelectedClientCompany({
        value: selectedClientCompany['@id'],
        label: selectedClientCompany.name,
      })
    }

    const selectedContractor = contractors.find(legal => legal['@id'] === registry.contractor)

    if (selectedContractor) {
      setSelectedContractor({
        value: selectedContractor['@id'],
        label: selectedContractor.name,
      })

      getTax({}, selectedContractor.tax.split('/').pop())
    }
  }

  const getAdverts = () => {
    getAdvertisings({
      't[]': 'short',
      'dateStart[before]': getFormattedDate(formRegistry.dateEnd, dateFormat),
      'dateEnd[after]': getFormattedDate(formRegistry.dateStart, dateFormat),
      'pagination': false,
      'company.id': selectedClientCompany.value.split('/').pop(),
    })
  }

  const getRegistry = () => {
    fetchRegistry({
      't[]': 'registry_edit'
    })
  }

  const showAdvertisings = () => Boolean(selectedClientCompany && formRegistry && formRegistry.dateStart && formRegistry.dateEnd)

  useEffect(() => {
    if (showAdvertisings()) {
      getAdverts()
    }
  }, [selectedClientCompany, formRegistry])

  const isFetchedData = () => {
    return registry && clientCompanies && advertisings
  }

  useEffect(() => {
    !isNew() && getRegistry()

    if (isNew()) {
      getClientCompanies()
      getContractorCompanies()
    }
  }, [props.match.params.registryId])

  useEffect(() => {
    if (tax) {
      const registry = cloneDeep(formRegistry)

      if (registry.invoiceRows) {
        for (let row of registry.invoiceRows) {
          row.expenses_taxed = (tax && tax.tax)
            ? getTaxedValue(row.expenses, tax.tax)
            : 0
        }

        setFormRegistry(registry)
      }
    }
  }, [tax])

  useEffect(() => {
    if (registry && registry.id) {
      const formRegistry = {
        ...registry,
        advertisings: registry.advertisings.map(advert => advert['@id'])
      }

      setFormRegistry(formRegistry)
    }

    if (registry && clientCompanies && (contractorCompanies || !isNew()) && contractors) {
      setSelected()
    }
  }, [registry, clientCompanies, contractorCompanies, contractors])

  useEffect(() => {
    if (registry) {
      if (registry.advertisings && registry.advertisings.length) {
        const selectedAdvertisings= registry.advertisings.map(advertising => ({
          label: advertising.name,
          value: advertising['@id'],
        }))

        setSelectedAdvertisings(selectedAdvertisings)
      }

      if (!isNew()) {
        getClientCompanies({
          id: registry.client.split('/').pop()
        })
      }
    }
  }, [registry])

  useEffect(() => {
    if (isNew() && selectedContractorCompany) {
      getContractors()
    } else if (registry) {
      getContractors({
        id: registry.contractor.split('/').pop()
      })
    }

    if (selectedContractorCompany) {
      setSelectedContractor(null)
    }
  }, [selectedContractorCompany, registry])

  useEffect(() => {
    if (addErrors) {
      setInputErrors(addErrors)
    } else if (updateErrors) {
      setInputErrors(updateErrors)
    } else {
      setInputErrors([])
    }
  }, [addErrors, updateErrors])

  const onClickSave = () => {
    const { registryFaces, ...form } = formRegistry
    
    isNew()
      ? addRegistry(form)
      : updateRegistry(form.id, form)
  }
  
  const setDates = date => {
    const dateStart = date
    const dateEnd = moment(date).endOf('month').toDate()

    setFormRegistry({
      ...formRegistry, dateStart, dateEnd
    })
  }

  const isFaces = () => {
    return formRegistry && formRegistry.invoiceRows && formRegistry.invoiceRows.length > 0
  }

  let topTitle = isNew()
    ? i18n.t('registries.edit.title_create')
    : i18n.t('registries.edit.title_edit')

  const clientCompanyOptions = clientCompanies
    ? clientCompanies.map(client => ({value: client['@id'], label: client.name}))
    : []

  const contractorOptions = contractors
    ? contractors.map(contractor => ({value: contractor['@id'], label: contractor.name}))
    : []

  const contractorCompanyOptions = contractorCompanies
    ? contractorCompanies.map(contractor => ({value: contractor['@id'], label: contractor.name}))
    : []

  const advertisingOptions = advertisings
    ? advertisings.map(advertising => ({value: advertising['@id'], label: advertising.name}))
    : []

  const emptyOption = {
    label: i18n.t('projects.list.not_selected'),
    value: ''
  }

  contractorCompanyOptions.unshift(emptyOption)

  return (
    <div className="row content flex">
      <div className="edit-client registry-edit">
        <div className="table-edit-client">
          <div>
            <MenuClient title={topTitle}>
              <Protected access={'FinancialRegistriesEdit'}>
                <button className="button-accent" onClick={onClickSave}>
                  {i18n.t('clients.edit.save')}
                </button>
              </Protected>

              <Link className="blue-cursor-button back-button" to={'/registries'}>
                {i18n.t('registries.edit.back')}
              </Link>
            </MenuClient>
            {(isFetchedData() || isNew()) &&
              <Form
                formRegistry={formRegistry}
                setFormRegistry={setFormRegistry}
                clientCompanyOptions={clientCompanyOptions}
                selectedClientCompany={selectedClientCompany}
                setSelectedClientCompany={setSelectedClientCompany}
                contractorOptions={contractorOptions}
                selectedContractor={selectedContractor}
                setSelectedContractor={setSelectedContractor}
                contractorCompanyOptions={contractorCompanyOptions}
                selectedContractorCompany={selectedContractorCompany}
                setSelectedContractorCompany={setSelectedContractorCompany}
                advertisingOptions={advertisingOptions}
                selectedAdvertisings={selectedAdvertisings}
                setSelectedAdvertisings={setSelectedAdvertisings}
                inputErrors={inputErrors}
                setDates={setDates}
                tax={tax}
                fractionDigits={fractionDigits}
                isNew={isNew()}
                showAdvertisings={showAdvertisings()}
              />
            }

            {registry  &&
              <Protected access={'FinancialRegistriesEdit'}>
                <DocumentsTable
                  registry={registry}
                  getRegistry={getRegistry}
                  fractionDigits={fractionDigits}
                ></DocumentsTable>
              </Protected>
            }

            {isFaces() && tax && tax.id &&
            <FaceTable
              formRegistry={formRegistry}
              deleteFace={deleteFace}
              getRegistry={getRegistry}
              fractionDigits={fractionDigits}
            ></FaceTable>
            }

          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;