import React, {Component, Fragment} from 'react';
import Select, { components } from "react-select";
import Input from '../../Components/Inputs/Input';
import IntInput from '../../Components/Inputs/IntInput';
import {cloneDeep, isEqual} from "lodash";
import moment from 'moment';
import i18n from "i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import FloatInput from "../../Components/Inputs/FloatInput";
import FormattedNumber from "../../Components/FormattedNumber";
import {InternetChannel} from "../../entities/channel/InternetChannel";
import Protected from "../../Components/Roles/Protected";
import Roles from "../../roles/Roles";
import {ChannelFactory} from "../../entities/channel/ChannelFactory";
import {TvChannel} from "../../entities/channel/TvChannel";
import {DigitalChannel} from "../../entities/channel/DigitalChannel";
import i18next from "i18next";
import Checkbox from "../../Components/Inputs/Checkbox";
import DownloadMediaplanXls from "./DownloadMediaplanXls";
import {getFormattedDate} from "./Detail/Fact/Utils";

const { Option, SingleValue } = components;

const ColoredSingleValue = props => {
  const selected = props.selectProps.options.find(option => option.value === props.selectProps.value.value)

  return (
    <SingleValue {...props} >
      {(selected && selected.colored) ?
        <span style={{color: 'red'}}>{props.data.label}</span>
        :
        props.data.label
      }
    </SingleValue>
  )
};

const ColoredOption = props => {
  return (<Option {...props}>
    {props.data.colored ?
      <span style={{color: 'red'}}>{props.data.label}</span>
      :
      props.data.label
    }

  </Option>)
};

const mapStateToProps = state => ({inputErrors: state.inputErrors})

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,
      budgetId: '',
      isPeriodBudgetIdsFilled: false,
      channelEntity: props.detail.channel ? ChannelFactory.create(props.detail.channel.code) : null,
      channelsOption: this.props.channels.map((channel) => {
        return {value: channel["@id"], label: channel.name, code: channel.code}
      }),
      goalsOptions: this.props.goals.map((goal) => {
        return {value: goal.name, label: goal.name}
      })
    }

    const existGoal = this.props.goals.find(goal => goal.name === this.props.detail.goal.value)

    if (!existGoal) {
      this.state.goalsOptions.unshift({
        value: this.props.detail.goal.value, label: this.props.detail.goal.label, colored: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.detail, this.props.detail)) {
      this.setState({
        channelEntity: this.props.detail.channel ? ChannelFactory.create(this.props.detail.channel.code): null
      })
    }

    if (!isEqual(prevProps.goals, this.props.goals)) {
      this.setState({
        goalsOptions: this.props.goals.map((goal) => {
          return {value: goal.name, label: goal.name}
        })
      })
    }
    /*if (prevState.selectedProduct !== this.state.selectedProduct) {
      if (this.state.selectedProduct === null) {
        //this.setState({goals: [], parts: []})
      } else {
        const productID = this.state.selectedProduct.value.replace("/products/", "");
        /!*api.get(apiUrls.get.productAdvertGoals(productID)).then((data) => {
          this.setState({goals: data});
        })*!/

        this.getParts(productID)

        api.get(apiUrls.get.productAdvertPlatforms(productID)).then((data) => {
          this.setState({platform: data})
        })
      }
    }*/
  }

  onGoalChange = (value) => {
    this.props.onChange({
      ...this.props.detail,
      goal: value
    })
  }

  onSelectChange = (object) => {
    this.props.onChange({
      ...this.props.detail,
      channel: object
    })

    this.props.setChannelEntity(object.code)

    if (object.code && object.code === InternetChannel.code) {
      this.props.getGoals()
      this.props.getParts()
    }
  }

  onPeriodBudgetCodeChange = (period, value) => {
    const periods = this.props.detail.periods

    for (let item of periods) {
      if (item.dateStartDetail === period.dateStartDetail) {
        item.budgetCode = value
      }
    }

    this.props.onChange({
      ...this.props.detail,
      periods
    })
  }

  onPeriodNameFinancialChange = (period, value) => {
    const periods = this.props.detail.periods

    for (let item of periods) {
      if (item.dateStartDetail === period.dateStartDetail) {
        item.nameFinancial = value
      }
    }

    this.props.onChange({
      ...this.props.detail,
      periods
    })
  }

  onPeriodApprovedChange = (period, value) => {
    const periods = this.props.detail.periods

    for (let item of periods) {
      if (item.dateStartDetail === period.dateStartDetail) {
        item.approved = value
      }
    }

    this.props.onChange({
      ...this.props.detail,
      periods
    })
  }

  onNumberGoalChange = (value) => {
    value = isNaN(parseInt(value)) ? '' : parseInt(value)

    this.props.onChange({
      ...this.props.detail,
      numberGoal: value
    })
  }

  onBudgetGoalChange = (value) => {
    this.props.onChange({
      ...this.props.detail,
      budgetGoal: value
    })
  }

  addDetailForm = (index) => {
    const newFormValue = cloneDeep(this.props.detail);
    newFormValue.periods.push({
      channelDetail: null,
      numberDetail: "",
      dateStartDetail: null,
      budgetDetail: ""
    })
    this.props.onChange(
      newFormValue
    )
  }

  onNumberDetailChange = (value, index) => {
    value = isNaN(parseInt(value)) ? '' : parseInt(value)
    const newFormValue = cloneDeep(this.props.detail);
    newFormValue.periods[index].numberDetail = value;
    this.props.onChange(
      newFormValue
    )
  }

  onBudgetDetailChange = (value, index) => {
    const newFormValue = cloneDeep(this.props.detail);
    newFormValue.periods[index].budgetDetail = value;
    this.props.onChange(
      newFormValue
    )
  }

  onBudgetIdChange = (event) => {
    this.setState({
      budgetId: event.target.value
    })
  }

  onPeriodBudgetsPress = (event) => {
    if (event.key === 'Enter') {
      this.fillPeriodsBudgetIds(event.target.value)
    }
  }

  onPeriodBudgetsFocusout = (event) => {
    this.fillPeriodsBudgetIds(event.target.value)
  }

  fillPeriodsBudgetIds = (value) => {
    if (this.props.detail.periods && this.props.detail.periods.length) {
      for (let period of this.props.detail.periods) {
        if (!period.budgetCode) {
          this.onPeriodBudgetCodeChange(period, value)
        }
      }
    }
  }

  isPeriodsFilled = () => Boolean(this.props.detail && this.props.detail.periods.every(period => period.budgetCode))

  fillPeriodsData = () => {
    if ((this.props.detail.periods && this.props.detail.periods.length)
      && (this.props.detail.budgetGoal || this.props.detail.numberGoal)) {
      const goal = this.props.detail.numberGoal / this.props.detail.periods.length
      const budget = this.props.detail.budgetGoal / this.props.detail.periods.length

      for (let period of this.props.detail.periods) {
        period.budgetDetail = budget
        period.numberDetail = goal
      }

      this.props.onChange({
        ...this.props.detail
      })
    }
  }

  render() {
    let goalLimit = Number(this.props.detail.numberGoal);

    let sumGoal = 0;
    for (const item of this.props.detail.periods) {
      if (!isNaN(Number(item.numberDetail))) {
        sumGoal += Number(item.numberDetail);
      }
    }

    let budgetLimit = (Number(this.props.detail.budgetGoal) * 100);

    let sumBudget = 0;
    for (const item of this.props.detail.periods) {
      if (!isNaN(Number(item.budgetDetail))) {
        sumBudget += (Number(item.budgetDetail) * 100)
      }
    }
    const bigBudget = (Number(this.props.mainBudgetLimit) * 100);

    const getPlanDetailLink = (period) => {
      const periodDateStart = getFormattedDate(period.dateStartDetail, 'YYYY-MM-DD')
      const periodDateEnd = getFormattedDate(period.dateEndDetail, 'YYYY-MM-DD')

      const defaultInterval = (this.state.channelEntity && this.state.channelEntity.code === TvChannel.getCode())
        ? 'week'
        : 'month'

      return `/advertisings/${this.props.advertId}/plan?dateStart=${periodDateStart}&dateEnd=${periodDateEnd}&interval=${defaultInterval}`
    }

    const getChannelCodeByIri = iri => {
      const channel = this.props.channels.find(channel => channel['@id'] === iri)

      if (channel && channel.code) {
        return channel.code
      }

      return ''
    }

    const getAdvertFacesLink = () => {
      return `/advertisings/${this.props.advertId}/faces/${getChannelCodeByIri(this.props.detail.channel.value)}`
    }

    const getAdvertisingPlanLink = () => {
      return `/advertisings/${this.props.advertId}/plan/${getChannelCodeByIri(this.props.detail.channel.value)}`
    }

    const isPerfomanceMedia = () => {
      if (!this.props.detail.channel || !this.props.detail.channel.value) {
        return false
      }

      return this.props.detail.channel.code === InternetChannel.getCode()
    }

    return (
      <div className="goals">
        <div className={'flex-center-v'}>
          <div className="w-25">
            <h3>{(this.props.detail.channel && this.props.detail.channel.label) ? this.props.detail.channel.label : ''}</h3>
          </div>
          {(this.state.channelEntity instanceof DigitalChannel || this.state.channelEntity instanceof TvChannel) && this.props.advertId &&
            <div className="w-25">
              <DownloadMediaplanXls advertisingId={this.props.advertId} />
            </div>
          }
        </div>

        <table className="table redesign-table table-client goal-detail">
          <thead>
          <tr>
            <th className="w-25">
              {(this.state.channelEntity instanceof TvChannel) &&
                <Link to={getAdvertisingPlanLink()}>{i18n.t('clients.advertising.advertising_plan')}</Link>
              }
            </th>
            <th className="w-25">{i18n.t('clients.advertising.goal_name')}</th>
            <th>{i18n.t('clients.advertising.goal_numbers')}</th>
            <th className="w-25">{i18n.t('clients.advertising.budget_to_vat')}</th>
          </tr>

          </thead>
          <tbody>
          <tr>
            <td className="w-25">
              {!this.props.detail.id
                ?
                  <Select
                    className="react-select-container select-pseudo-input small"
                    classNamePrefix="react-select"
                    placeholder={i18n.t('clients.advertising.select_channel')}
                    options={this.state.channelsOption}
                    value={this.props.detail.channel}
                    onChange={this.onSelectChange}
                    />
                :
                <Protected access={'AdvertisingsFacesEdit'}>
                  <Fragment>
                    {this.props.detail.channel && this.props.detail.channel.value &&
                    <Link to={getAdvertFacesLink()}>
                      {i18n.t('clients.advertising.faces')}</Link>
                    }
                  </Fragment>
                </Protected>
              }
            </td>
            <td className="w-25">
              {(this.state.channelEntity instanceof InternetChannel) ?
                <Select
                  className="react-select-container select-pseudo-input small"
                  classNamePrefix="react-select"
                  //placeholder={i18n.t('clients.advertising.select_channel')}
                  options={this.state.goalsOptions}
                  value={this.props.detail.goal}
                  components={{ Option: ColoredOption, SingleValue: ColoredSingleValue }}
                  onChange={this.onGoalChange}
                />
                :
                <Input
                  value={this.props.detail.goal.value}
                  onChange={(value) => {
                    this.onGoalChange({
                      label: value,
                      value: value,
                    })
                  }}
                  disabled={this.props.detail.channel === null} />
              }

            </td>
            <td>
              <IntInput
                value={this.props.detail.numberGoal}
                onChange={this.onNumberGoalChange}
                disabled={this.props.detail.channel === null}
                className={this.props.inputErrors['channel_goal_' + this.props.index] ? ' has-error' : ''}
                isValid={(value) => !isNaN(value)
                  && (Math.abs(sumGoal - goalLimit) <= 5)
                  && !this.props.inputErrors['channel_goal_' + this.props.index]
                }
                errorText={this.props.inputErrors['channel_goal_' + this.props.index]}
              />
            </td>
            <td className="w-25">
              <div className='flex'>
                <FloatInput
                  value={this.props.detail.budgetGoal}
                  onChange={this.onBudgetGoalChange}
                  disabled={this.props.detail.channel === null}
                  className={this.props.inputErrors['channel_budget_' + this.props.index] ? ' has-error' : ''}
                  isValid={(value) => !isNaN(value)
                    && (Math.abs(bigBudget - this.props.globalSumDetail) <= 5)
                    && !this.props.inputErrors['channel_budget_' + this.props.index]
                  }
                  errorText={this.props.inputErrors['channel_budget_' + this.props.index]} />
                <div className='fill-budget' onClick={this.fillPeriodsData}
                  title={i18n.t('clients.advertising.fill_data')}
                >
                  <i className='fas fa-arrow-alt-circle-down' />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="w-25">
              <Link to={`/advertisings/${this.props.advertId}/plan`}>
                {i18n.t('clients.advertising.to_advertising_plan')}</Link>
            </td>
            <td className="w-25">
              <div className="subtitle-goal">{i18n.t('clients.advertising.all_input_values')}</div>
            </td>
            <td className={(sumGoal !== goalLimit) ? 'error' : ''}>
              <FormattedNumber
                value={sumGoal}  type={'float'} />
            </td>
            <td className={(Math.abs(budgetLimit - sumBudget) > 5) ? 'error' : ''}>
              <FormattedNumber
                value={sumBudget / 100}  type={'float'} />
            </td>
          </tr>
          </tbody>
        </table>
        <div className="goals-submenu middle">
            <div className="w-25" />
          <div className="subtitle-goal">{i18n.t('clients.advertising.detailing_by_goal')}</div>
          <div>
            <button onClick={this.props.generatePeriod}>
              <i className="fal fa-plus" />
              {i18n.t('clients.advertising.generate_periods')}
            </button>
          </div>
        </div>
        <table className="table redesign-table table-client goal-detail">
          <thead>
          <tr>
            <td className="w-25" />
            <th className="w-20">{i18n.t('clients.advertising.budget_id')}</th>
            <th className="w-20">{i18n.t('clients.advertising.month')}</th>
            <th className="w-20">{i18n.t('clients.advertising.goal_numbers')}</th>
            <th className="w-20">{i18n.t('clients.advertising.budget_to_vat')}</th>
          </tr>
          <tr>
            <td className="w-25"/>
            <td className="w-20">
              <input
                value={this.state.budgetId}
                placeholder={i18n.t('clients.advertising.budget_id')}
                onChange={this.onBudgetIdChange}
                onKeyPress={this.onPeriodBudgetsPress}
                onBlur={this.onPeriodBudgetsFocusout}
                disabled={this.isPeriodsFilled() || !Roles.hasAccess('AdvertisingPeriodsBudgetCodeEdit')} />
            </td>
          </tr>
          </thead>
        </table>
        {this.props.detail.periods.map((period, index) => {
          return (
            <Fragment key={index}>
              <table className="table redesign-table table-client goal-detail">
                <tbody>
                <tr>
                  <td className="w-20">
                    <div>
                      {period.id &&
                      <Protected access={'AdvertisingsPlanRead'}>
                        <div>
                          <Link to={getPlanDetailLink(period)}>
                            {i18n.t('clients.advertising.plan_detail')}
                          </Link>
                        </div>
                      </Protected>
                      }

                      {period.factDataUrl &&
                        <Protected access={'AdvertisingsFactsRead'}>
                          <div><Link to={period.factDataUrl}>
                            {i18n.t('clients.advertising.mediaplan_fact')}
                          </Link></div>
                        </Protected>
                      }
                    </div>

                  </td>
                  <td className="w-20">
                    <Input
                      onChange={(value) => this.onPeriodBudgetCodeChange(period, value)}
                      value={period.budgetCode || ''}
                      disabled={!Roles.hasAccess('AdvertisingPeriodsBudgetCodeEdit')}
                    />
                  </td>
                  <td className="w-20">
                    <Input
                      value={period.dateStartDetail === null ? "" : moment(period.dateStartDetail).format("MMMM")}
                      disabled={true}
                    />
                  </td>
                  <td className="w-20">
                    <IntInput
                      value={period.numberDetail}
                      onChange={(value) => this.onNumberDetailChange(value, index)}
                      disabled={this.props.detail.channel === null}
                      className={this.props.inputErrors['period_goal_' + this.props.index] ? ' has-error' : ''}
                      isValid={(value) =>
                        ((value === undefined || value === "")
                        || !isNaN(value))
                        && (value <= goalLimit
                        && sumGoal === goalLimit
                        && !this.props.inputErrors['period_goal_' + this.props.index])
                      }
                      errorText={this.props.inputErrors['period_goal_' + this.props.index]}
                    />
                  </td>
                  <td className="w-20">
                    <FloatInput
                      value={period.budgetDetail}
                      onChange={(value) => this.onBudgetDetailChange(value, index)}
                      disabled={this.props.detail.channel === null}
                      className={this.props.inputErrors['period_budget_' + this.props.index] ? ' has-error' : ''}
                      isValid={(value) =>
                        !isNaN(value)
                        && (Math.abs(budgetLimit - sumBudget) <= 5)
                        && !this.props.inputErrors['period_budget_' + this.props.index]
                      }
                      errorText={this.props.inputErrors['period_budget_' + this.props.index]}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="w-25">
                    {i18n.t('clients.edit.name_financial')}
                  </td>
                  <td className="w-20" colSpan={2}>
                    <Input
                      onChange={(value) => this.onPeriodNameFinancialChange(period, value)}
                      value={period.nameFinancial || ''}
                      disabled={!Roles.hasAccess('AdvertisingPeriodsNameEdit')}
                    />
                  </td>
                  <td className="w-20" colSpan={2}>
                    <Checkbox
                      onChange={(value) => this.onPeriodApprovedChange(period, value)}
                      checked={period.approved || false}
                      disabled={!Roles.hasAccess('AdvertisingPeriodsNameApproved')}
                    />
                    <span className={'goal-detail-approved--text'}>{i18next.t('clients.edit.approved_name')}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </Fragment>
          )
        })}

        {isPerfomanceMedia() && this.props.selectedProduct && this.props.parts}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Details);