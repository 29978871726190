import React, {Fragment} from 'react';
import TopMenu from "../../../../Components/TopMenu";

function Header({title, advertisingName, productName, channelName, period}) {
  return (
    <TopMenu title={
      <Fragment>
        <div className={'title-text'}>{period} {channelName} "{productName}"</div>
        <div>{advertisingName}</div>
        <div className={'title-text'}>{title}</div>
      </Fragment>
    } />
  )
}

export default Header