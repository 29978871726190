import React, {Fragment, useEffect, useState} from 'react'

import useApiFetch from "../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../ApiUrls";
import i18n from "i18next";
import MenuClient from "../../../Components/MenuClient";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {getUtcDate} from "../../Advertising/Detail/Fact/Utils";
import Header from "../../Advertising/Detail/components/Table/Header";
import SumRow from "../../Advertising/Detail/components/Table/SumRow";
import SumRowWithCheckBox from "../ReconciliationWithFinancier/SumRow";
import {cloneDeep} from "lodash";
import Row from "../../Advertising/Detail/components/Table/Row";
import RowCell from "./RowCell";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import Loader from "../../../Components/Loader/Loader";
import {Link, useHistory} from "react-router-dom";
import useDelete from "../../../Components/Hooks/useDelete";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import Footer from "../../Advertising/Detail/components/Footer";
import {getFormattedDate} from "../FacesTotal/Utils";
import Roles from "../../../roles/Roles";
import Select from "react-select";
import {getRoundedNumber, getValuesFromUrl} from "../../../utils";
import Filter from "../../../Components/NewFilter/Filter";
import {api} from "../../../api/apiProvider";
import Input from "../../../Components/Inputs/Input";
import DeleteWithConfirmButton from "../../../Components/Buttons/DeleteWithConfirmButton";

const dateFormat = 'YYYY-MM-DD'
const budgetMargin = process.env.REACT_APP_BUDGET_MARGIN_CONTRACTOR_AGENCY

const emptyOption = {
  label: i18n.t('projects.list.not_selected'),
  value: null
}

const propertiesSortParams = {
  budget: {
    asc: 'asc',
    desc: 'desc',
    param: 'order[face.properties.budget]'
  }
}

const getContractorSumValues = (contractor) => {
  let sums = {}

  for (let platform of contractor.platforms) {
    for (let face of platform.data) {
      for (let propertyCode in face.properties) {
        const propertyValue = face.properties[propertyCode].value

        if (!sums[propertyCode]) {
          sums[propertyCode] = 0
        }

        sums[propertyCode] += propertyValue
      }
    }
  }

  return sums
}

const getPlatformSumValues = platformData => {
  let sums = {}

  for (let face of platformData) {
    for (let propertyCode in face.properties) {
      const propertyValue = face.properties[propertyCode].value

      if (!sums[propertyCode]) {
        sums[propertyCode] = 0
      }

      sums[propertyCode] += propertyValue
    }
  }

  return sums
}

const getDataToSave = (face, check) => {
  const data = {}

  if (face.checkRowId) {
    data.id = face.checkRowId
  }

  data.financialCheck = `/financial/contractor_checks/${check.id}`
  data.budget = face.properties.checkBudget.value
  data.budgetTax = face.properties.checkBudgetTax.value
  data.tax = face.properties.checkTax.value
  data.approved = face.properties.budget_approved.value
  data.data = [
    {
      face: face.item['@id'],
      dateStart: getUtcDate(check.dateStart).format(dateFormat),
      dateEnd: getUtcDate(check.dateEnd).format(dateFormat)
    }
  ]

  return data
}

const getCheckRowDataToSave = (face, check) => {
  const data = {}

  if (face.checkRowId) {
    data.id = `/financial/contractor_check_rows/${face.checkRowId}`
  }

  data.budget = face.properties.checkBudget.value
  data.budgetTax = face.properties.checkBudgetTax.value
  data.tax = face.properties.checkTax.value
  data.approved = face.properties.budget_approved.value
  data.data = [
    {
      face: face.item['@id'],
      dateStart: getUtcDate(check.dateStart).format(dateFormat),
      dateEnd: getUtcDate(check.dateEnd).format(dateFormat)
    }
  ]

  return data
}

function Index() {
  const history = useHistory()

  const [faceRows, setFaceRows] = useState({})
  const [properties, setProperties] = useState([])
  const [faces, setFaces] = useState([])
  const [sortParams, setSortParams] = useState('')
  const [isFacesSaving, setIsFacesSaving] = useState(null)
  const [platformFilter, setPlatformFilter] = useState(null)
  const [advertisingFilter, setAdvertisingFilter] = useState(null)
  const [channelFilter, setChannelFilter] = useState({})
  const [dateStartFilter, setDateStartFilter] = useState(null)
  const [dateEndFilter, setDateEndFilter] = useState(null)
  const [contractorFilter, setContractorFilter] = useState({})
  const [clientFilter, setClientFilter] = useState([])
  const [channels, setChannels] = useState(null)
  const [platformOptions, setPlatformOptions] = useState([])
  const [advertisingOptions, setAdvertisingOptions] = useState([])
  const [filterParams, setFilterParams] = useState('')
  const [check, setCheck] = useState(null)
  const [comment, setComment] = useState('')

  const [{data: checks, isLoading: isChecksLoading}, getChecks] = useApiFetch(apiUrls.get.contractorChecks())

  const getFacesUrl = () => {
    if (channelFilter && channels) {
      const selectedChannel = channels.find(channel => channel.value === channelFilter.value)

      if (selectedChannel) {
        const channelEntity = ChannelFactory.create(selectedChannel.code)

        if (channelEntity) {
          return channelEntity.apiUrls.totalFact
        }
      }
    }
  }

  const [{data: factData, isLoading: isFacesLoading}, getFactData] = useApiFetch(getFacesUrl)

  const [{errors: createCheckRowsError}, createCheckRows] = useApiPost(
    apiUrls.get.contractorCheckRows(),
    i18n.t('common.added'),
    'errors'
  )

  const [{errors: updateCheckRowsError}, updateCheckRows] = useApiPatch(
    apiUrls.get.contractorCheckRow,
    i18n.t('common.saved'),
    'errors',
  )

  const [{errors: updateCheckError}, updateCheck] = useApiPatch(
    apiUrls.get.contractorCheck,
    i18n.t('common.saved'),
    'errors',
    () => {},
    () => {
      setIsFacesSaving(false)
    },
  )

  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(
    getFacesUrl,
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising',
    () => {},
    () => {
      setIsFacesSaving(false)
    },
  )

  const [{errors: addErrors}, addCheck] = useApiPost(
    apiUrls.get.contractorChecks(),
    '',
    'contractors',
    () => {
      toast.success(i18n.t('common.added'))
    }
  )

  const filters = {
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      setOptions: options => {
        setChannels(options)
      },
      name: 'channel',
      setFilterParams: (params, filterValue) => {
        params.append('channel.id', filterValue.value)
        setChannelFilter(filterValue)
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], dateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(dateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(dateFormat)
        const endDate = dateValue.endOf('month').format(dateFormat)
        params.append('dateStart[after]', startDate)
        params.append('dateEnd[before]', endDate)
        setDateStartFilter(startDate)
        setDateEndFilter(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: dateFormat,
      required: true,
      isMonth: true,
    },
    legal: {
      type: 'select',
      placeholder: i18n.t('clients.page.legal'),
      getOptionsUrlFunction: apiUrls.get.contractorLegals,
      setFilterParams: (params, filterValue) => {
        if (filterValue && filterValue.value) {
          params.append('contractor.id', filterValue.value)
          setContractorFilter(filterValue)
        }
      },
      getValueToUrl: (params, legal) => {
        if (legal && legal.value) {
          params.set('legal', legal.value)
        } else {
          params.delete('legal')
        }
      },
      name: 'legal',
      className: 'has-separator pointer',
      //isEmptyOption: true,
      required: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, values) => {
        const clients = []
        params.delete('clients.id[]')
        for (let filterValue of values) {
          params.append('clients.id[]', filterValue.value)
          clients.push(filterValue)
        }

        setClientFilter(clients)
      },
      getValueToUrl: (params, company) => {
        if (company && company.length) {
          params.delete('company[]')
          for (let companyItem of company) {
            params.append('company[]', companyItem.value)
          }
        } else {
          params.delete('company[]')
        }
      },
      isMulti: true,
    },
  }

  const deleteFace = useDelete(apiUrls.get.contractorCheckRow)

  const getCheckData = () => {
    getChecks(getCheckParams())
  }

  const getCheckParams = () => {
    return {
      'channel.id': channelFilter.value,
      'contractor.id': contractorFilter.value,
      'dateStart[after]': dateStartFilter,
      'dateEnd[before]': dateEndFilter,
      'order[dateStart]': 'desc',
      't[]': 'check:extended',
    }
  }

  const getFacts = () => {
    const factDataParams = {
      'date[after]': dateStartFilter,
      'date[before]': dateEndFilter,
      'face.contractor.id': contractorFilter ? contractorFilter.value : null,
      //'face.advertising.company.id[]': (check && check.clients) ? check.clients.map(client => client.id) : [],
      'props[]': 'budget',
      't[]': ['contractors_group', 'face_financial', 'short'],
    }

    if (Object.keys(sortParams).length) {
      for (let param in sortParams) {
        const sortOrder = sortParams[param]
        const paramToUrl = propertiesSortParams[param] ? propertiesSortParams[param].param : ''

        if (paramToUrl) {
          factDataParams[paramToUrl] = sortOrder
        }
      }
    }

    getFactData(factDataParams)
  }

  useEffect(() => {
    const budgetProperties = []

    budgetProperties.push({
      name: i18n.t('contractors.budget'),
      code: 'budget',
      type: 'float'
    })

    /*budgetProperties.push({
      name: i18n.t('contractors.tax'),
      code: 'tax',
      type: 'float'
    })

    budgetProperties.push({
      name: i18n.t('contractors.budget_with_tax'),
      code: 'budget_with_tax',
      type: 'float'
    })*/

    budgetProperties.push({
      name: i18n.t('contractors.budget_approved'),
      code: 'budget_approved',
      type: 'bool'
    })

    budgetProperties.push({
      name: i18n.t('contractors.checkBudget'),
      code: 'checkBudget',
      type: 'float'
    })

    budgetProperties.push({
      name: i18n.t('contractors.checkTax'),
      code: 'checkTax',
      type: 'float'
    })

    budgetProperties.push({
      name: i18n.t('contractors.checkBudgetTax'),
      code: 'checkBudgetTax',
      type: 'float'
    })

    budgetProperties.push({
      name: i18n.t('contractors.check_budget_submitted'),
      code: 'budget_submitted',
      type: 'bool'
    })

    setProperties(budgetProperties)
  }, [])

  useEffect(() => {
    if (checks && checks[0]) {
      setCheck(checks[0])
    } else {
      setCheck(null)
    }
  }, [checks])

  useEffect(() => {
    if (check && check.checkRows) {
      const faceRows = {}

      for (let row of check.checkRows) {
        if (row.data && row.data[0] && row.data[0].face) {
          faceRows[row.data[0].face] = {
            budget: row.budget,
            budgetTax: row.budgetTax,
            tax: row.tax,
            id: row.id,
            approved: row.approved,
            comment: row.comment,
            clientCheckRow: row.clientCheckRow,
          }
        }
      }

      const clientOptions = []
      if (check.clients) {
        for (let client of check.clients) {
          clientOptions.push({
            label: client.name,
            value: client.id,
          })
        }
      }

      const valuesFromUrl = getValuesFromUrl()

      if (valuesFromUrl && valuesFromUrl['client[]'] && valuesFromUrl['client[]'].length) {
        const clientIds = []

        for (let id of valuesFromUrl['client[]']) {
          clientIds.push(Number(id))
        }

        const clients = clientOptions.filter(client => clientIds.includes(client.value))

        if (clients) {
          setClientFilter(clients)
        }
      }

      setFaceRows(faceRows)

      setComment(check.comment)
    } else {
      setFaceRows({})
    }
  }, [check])

  useEffect(() => {
    if (channelFilter && dateStartFilter && dateEndFilter && contractorFilter) {
      getFacts()
      getCheckData()
    }
  }, [channelFilter, dateStartFilter, dateEndFilter, contractorFilter])

  useEffect(() => {
    if (factData && faceRows) {
      const data = cloneDeep(factData)
      const platformsMap = new Map();
      const advertisingsMap = new Map();

      for (let interval of data) {
        for (let contractor of interval.contractors) {
          if (!contractor.sums) {
            contractor.sums = {}
          }

          const contractorData = contractor.contractor

          for (let platform of contractor.platforms) {
            platformsMap.set(platform.platform.id, platform.platform.name)

            if (!platform.sums) {
              platform.sums = {}
            }

            for (let face of platform.data) {
              const tax = (contractorData && contractorData.tax)
                ? contractorData.tax.tax
                : 0

              face.id = Number(face.item['@id'].split('/').pop())

              if (face.properties.budget) {
                face.properties.budget.editable = Roles.hasAccess('FinancialCheckBudgetUpdate')
                  && faceRows[face.item['@id']] && !faceRows[face.item['@id']].approved
              }

              const budget = face.properties.budget.value
              const actTax = getRoundedNumber((budget * tax / 100), 2)
              const budgetWithTax = getRoundedNumber((budget + actTax), 2)

              face.properties.tax = {
                editable: false,
                value: actTax
              }

              face.properties.budget_with_tax = {
                editable: false,
                value: budgetWithTax
              }

              face.properties.checkBudget = {
                editable: Roles.hasAccess('FinancialCheckPropertiesUpdate'),
                value: faceRows[face.item['@id']]
                  ? getRoundedNumber(faceRows[face.item['@id']].budget, 2)
                  : face.properties.budget.value
              }

              if (faceRows[face.item['@id']] && faceRows[face.item['@id']].clientCheckRow) {
                face.isBottomText = true
                face.properties.checkBudget.bottom = i18n.t('face_check.contractor_check_row_checked')
                face.properties.checkBudget.bottomClass = 'contractor-checks__rows-with-text'
              }

              face.properties.checkTax = {
                editable: Roles.hasAccess('FinancialCheckPropertiesUpdate'),
                value: faceRows[face.item['@id']]
                  ? getRoundedNumber(faceRows[face.item['@id']].tax, 2)
                  : face.properties.tax.value
              }

              face.properties.checkBudgetTax = {
                editable: Roles.hasAccess('FinancialCheckPropertiesUpdate'),
                value: faceRows[face.item['@id']]
                  ? getRoundedNumber(faceRows[face.item['@id']].budgetTax, 2)
                  : face.properties.budget_with_tax.value
              }

              face.properties.budget_submitted = {
                editable: Roles.hasAccess('FinancialCheckPropertiesUpdate'),
                value: !!faceRows[face.item['@id']]
              }

              face.properties.budget_approved = {
                editable: face.properties.budget_submitted.value && Roles.hasAccess('FinancialCheckPropertiesUpdate'),
                value: faceRows[face.item['@id']]
                  ? faceRows[face.item['@id']].approved
                  : false
              }

              face.approved = faceRows[face.item['@id']]
                ? faceRows[face.item['@id']].approved
                : false

              face.comment = faceRows[face.item['@id']]
                ? faceRows[face.item['@id']].comment
                : ''

              face.budgetDiff = Math.abs(face.properties.budget.value - face.properties.checkBudget.value)

              if (faceRows[face.item['@id']]) {
                face.checkRowId = faceRows[face.item['@id']].id
              }

              for (let propertyCode in face.properties) {
                const propertyValue = face.properties[propertyCode].value

                if (!platform.sums[propertyCode]) {
                  platform.sums[propertyCode] = 0
                }
                platform.sums[propertyCode] += propertyValue

                if (!contractor.sums[propertyCode]) {
                  contractor.sums[propertyCode] = 0
                }
                contractor.sums[propertyCode] += propertyValue
              }

              if (face.item && face.item.advertising && face.item.advertising.id) {
                advertisingsMap.set(face.item.advertising.id, face.item.advertising.name)
              }
            }
          }
        }
      }

      const platformOptions = []
      platformsMap.forEach((label, value) => {
        platformOptions.push({label, value})
      })

      platformOptions.unshift(emptyOption)

      const advertisingsOptions = []
      advertisingsMap.forEach((label, value) => {
        advertisingsOptions.push({label, value})
      })

      advertisingsOptions.unshift(emptyOption)

      const valuesFromUrl = getValuesFromUrl()

      if (valuesFromUrl && valuesFromUrl.platform && valuesFromUrl.platform.length) {
        const platformId = Number(valuesFromUrl.platform[0])

        const platfrom = platformOptions.find(platform => platform.value === platformId)

        if (platfrom) {
          setPlatformFilter(platfrom)
        }
      }

      if (valuesFromUrl && valuesFromUrl.advertising && valuesFromUrl.advertising.length) {
        const advertisingId = Number(valuesFromUrl.advertising[0])

        const advertising = advertisingsOptions.find(advertising => advertising.value === advertisingId)

        if (advertising) {
          setAdvertisingFilter(advertising)
        }
      }

      setPlatformOptions(platformOptions)
      setAdvertisingOptions(advertisingsOptions)

      setFaces(data)
    }
  }, [factData, faceRows])

  useEffect(() => {
    if (faces && !isFacesLoading) {
      if (platformOptions && platformOptions.length) {
        if (platformFilter && platformFilter.value) {
          setValueToUrl('platform', platformFilter.value)
        } else {
          deleteValueFromUrl('platform')
        }
      }

      if (advertisingOptions && advertisingOptions.length) {
        if (advertisingFilter && advertisingFilter.value) {
          setValueToUrl('advertising', advertisingFilter.value)
        } else {
          deleteValueFromUrl('advertising')
        }
      }
    }
  }, [clientFilter, platformFilter, advertisingFilter])

  const setValueToUrl = (name, value) => {
    const params = new URLSearchParams(history.location.search)

    if (Array.isArray(value)) {
      params.delete(name)

      for (let item of value) {
        params.append(name, item.value)
      }
    } else {
      params.set(name, value)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const deleteValueFromUrl = name => {
    const params = new URLSearchParams(history.location.search)

    if (params.has(name)) {
      params.delete(name)

      const paramsString = params.toString()

      if (paramsString) {
        history.push(history.location.pathname + '?' + params.toString())
      } else {
        history.replace(history.location.pathname)
      }
    }
  }

  const onRowChange = async (value, property, periodDateStart, contractorIndex, platformId, faceId) => {
    const formFaces = cloneDeep(faces)

    for (let periodIndex in formFaces) {
      const period = formFaces[periodIndex]

      if (period.dateStart === periodDateStart) {
        if (period.contractors && period.contractors[contractorIndex] && period.contractors[contractorIndex].platforms.length) {
          for (let platformIndex in period.contractors[contractorIndex].platforms) {
            const platform = period.contractors[contractorIndex].platforms[platformIndex]

            if (platform.platform && platform.platform.id === platformId) {
              if (platform.data && platform.data.length) {
                const face = platform.data.find(platformFace => platformFace.id === faceId)

                if (face && face.properties && face.properties[property.code]) {
                  face.properties[property.code].value = value

                  if (property.code === 'budget_submitted') {
                    face.properties.budget_approved.editable = value
                  }

                  if (property.code === 'budget' || property.code === 'checkBudget') {
                    face.budgetDiff = Math.abs(face.properties.budget.value - face.properties.checkBudget.value)
                  }
                }
              }
            }
          }
        }
      }
    }

    await setFaces(formFaces)
  }

  const onSaveFace = async (face) => {
    if (Roles.hasAccess('FinancialCheckPropertiesUpdate')) {
      if (!check) {
        await createCheck(async () => {
          const params = new URLSearchParams(getCheckParams())
          const checks = await api.get(apiUrls.get.contractorChecks() + '?' + params.toString())
          if (checks && checks['hydra:member'] && checks['hydra:member'].length) {
            const contractorCheck = checks['hydra:member'][0]
            await setCheck(contractorCheck)

            saveFace(face, contractorCheck)
          }
        })
      } else {
        saveFace(face)
      }
    } else {
      saveFace(face)
    }
  }

  const saveFace = (face, contractorCheck) => {
    const toSave = []

    if (face && Roles.hasAccess('FinancialCheckPropertiesUpdate')) {
      toSave.push(saveFaceCheckRowData(face, contractorCheck))
    }

    if (face && !face.approved && Roles.hasAccess('FinancialCheckBudgetUpdate')) {
      toSave.push(saveFaceFactData(face))
    }

    if (toSave.length) {
      Promise.all(
        toSave
      )
        .then(() => {
          toast.success(i18n.t('common.saved'))
          getCheckData()
          getFacts()
        }).catch((error) => {
        toast.error(error)
      })
    }
  }

  const saveFaceFactData = async face => {
    return new Promise((resolve, reject) => {
      const [intervalStart, intervalEnd]
        = getAdvertisingDates(face.item.advertising.dateStart, face.item.advertising.dateEnd)

      const intervals = [
        {
          dateStart: getFormattedDate(intervalStart, dateFormat),
          dateEnd: getFormattedDate(intervalEnd, dateFormat),
          data: [
            {
              item: face.item['@id'],
              properties: {
                budget: face.properties.budget.value
              }
            }
          ]
        }
      ]

      saveFactData({intervals}, true, {}, true, () => {
        return resolve(true)
      })
    })
  }

  const saveFaceCheckRowData = async (face, contractorCheck) => {
    return new Promise(async (resolve, reject) => {
      const data = getDataToSave(face, check || contractorCheck)

      if (data.id) {
        updateCheckRows(data.id, data, {}, () => {
          return resolve(true)
        })
      } else {
        createCheckRows(data, null, null, true, () => {
          return resolve(true)
        })
      }
    })
  }

  const createCheck = async (callback = () => {}) => {
    const data = {
      'comment': comment,
      'dateEnd': dateEndFilter,
      'dateStart': dateStartFilter,
      'channel': `/channels/${channelFilter.value}`,
      'contractor': `/legals/${contractorFilter.value}`,
      't[]': 'check:extended'
    }

    await addCheck(data, true, {}, true, () => {
      callback()
    })
  }

  const onFaceDelete = face => {
    deleteFace(face.checkRowId, () => {
      toast.success(i18n.t('common.deleted'))
      getCheckData()
    })
  }

  const onSaveAllFaces = async () => {
    if (Roles.hasAccess('FinancialCheckPropertiesUpdate')) {
      if (!check) {
        await createCheck(async () => {
          const params = new URLSearchParams(getCheckParams())
          const checks = await api.get(apiUrls.get.contractorChecks() + '?' + params.toString())
          if (checks && checks['hydra:member'] && checks['hydra:member'].length) {
            const contractorCheck = checks['hydra:member'][0]
            await setCheck(contractorCheck)

            saveAllFaces(contractorCheck)
          }
        })
      } else {
        saveAllFaces()
      }
    } else {
      saveAllFaces()
    }
  }

  const saveAllFaces = (contractorCheck = check) => {
    setIsFacesSaving(true)
    const toSave = []

    if (Roles.hasAccess('FinancialCheckPropertiesUpdate')) {
      toSave.push(saveAllFacesCheckRows(contractorCheck))
    }

    if (Roles.hasAccess('FinancialCheckBudgetUpdate')) {
      toSave.push(saveAllFactData())
    }

    Promise.all(
      toSave
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        getCheckData()
        getFacts()
      })
      .finally(() => {
        setIsFacesSaving(false)
      })
      .catch(() => {
        setIsFacesSaving(false)
      })
  }

  const saveAllFactData = () => {
    return new Promise((resolve, reject) => {
      const faceIntervals = {}

      for (let contractor of faces[0].contractors) {
        for (let platform of contractor.platforms) {
          for (let face of platform.data) {
            if (!face.item.advertising) {
              continue
            }

            if (!face.properties || !face.properties.budget || !face.properties.budget.value) {
              continue
            }

            if (face.approved) {
              continue
            }

            const advert = face.item.advertising

            const [intervalStart, intervalEnd]
              = getAdvertisingDates(advert.dateStart, advert.dateEnd)//todo

            const intervalStartDate = getFormattedDate(intervalStart, dateFormat)
            const intervalEndDate = getFormattedDate(intervalEnd, dateFormat)

            const intervalKey = intervalStartDate + intervalEndDate

            if (!faceIntervals[intervalKey]) {
              faceIntervals[intervalKey] = {
                dateStart: intervalStartDate,
                dateEnd: intervalEndDate,
                data: []
              }
            }

            faceIntervals[intervalKey].data.push({
              item: face.item['@id'],
              properties: {
                budget: face.properties.budget.value
              }
            })
          }
        }
      }

      if (!Object.keys(faceIntervals).length) {
        return resolve(true)
      }

      saveFactData({intervals: Object.values(faceIntervals)}, true, {}, true, () => {
        return resolve(true)
      }, () => {
        setIsFacesSaving(false)
      })
    })
  }

  const saveAllFacesCheckRows = contractorCheck => {
    return new Promise((resolve, reject) => {
      if (faces && faces[0] && faces[0].contractors) {
        setIsFacesSaving(true)

        const facesData = []

        for (let contractor of faces[0].contractors) {
          for (let platform of contractor.platforms) {
            for (let face of platform.data) {
              if (!face.item.advertising) {
                continue
              }

              if (!face.properties || !face.properties.budget_submitted || !face.properties.budget_submitted.value) {
                continue
              }

              facesData.push(getCheckRowDataToSave(face, contractorCheck))
            }
          }
        }

        updateCheck(contractorCheck.id, {checkRows: facesData, comment}, {}, () => {
          resolve(true)
        })
      }
    })
  }

  const getAdvertisingDates = (dateStart, dateEnd) => {
    const periodStartDate = getUtcDate(dateStartFilter).toDate()
    const periodEndDate = getUtcDate(dateEndFilter).toDate()

    const advertisingStartDate = getUtcDate(dateStart).toDate()
    const advertisingEndDate = getUtcDate(dateEnd).toDate()

    const start = advertisingStartDate > periodStartDate
      ? advertisingStartDate
      : periodStartDate

    const end = advertisingEndDate < periodEndDate
      ? advertisingEndDate
      : periodEndDate

    return [start, end];
  }

  const getFactDataPageUrl = face => {
    const channelId = channelFilter.value
    const selectedChannel = channels.find(channel => channel.value === channelId)

    if (!selectedChannel) {
      return ''
    }

    const channelCode = selectedChannel.code

    const [intervalStart, intervalEnd]
      = getAdvertisingDates(face.item.advertising.dateStart, face.item.advertising.dateEnd)

    if (channelCode && check) {
      return `/advertisings/${
        face.item.advertising.id}/fact/${channelCode}?dateStart=${
        getFormattedDate(intervalStart, dateFormat)}&dateEnd=${
        getFormattedDate(intervalEnd, dateFormat)}&contractor=${check.contractor.id}`
    }

    return ''
  }

  const toggleSort = (name) => {
    if (!propertiesSortParams[name]) {
      return
    }

    const acsSort = propertiesSortParams[name].asc
    const descSort = propertiesSortParams[name].desc

    if (sortParams[name] === acsSort) {
      setSortParams({
        ...sortParams,
        [name]: descSort
      })
    } else if (sortParams[name] === descSort) {
      const params = cloneDeep(sortParams)
      delete params[name]
      setSortParams(params)
      delete sortParams[name]
    } else {
      setSortParams({
        ...sortParams,
        [name]: acsSort
      })
    }
  }

  const onChecked = (value, code) => {
    const newFaces = cloneDeep(faces)

    for (let period of newFaces) {
      for (let contractor of period.contractors) {
        for (let platform of contractor.platforms) {
          if (isInPlatformFilter(platform)) {
            for (let face of platform.data) {
              if (face.properties && face.properties[code] && face.properties[code].editable
                && isInClientFilter([face])) {
                face.properties[code].value = value
              }
            }
          }
        }
      }
    }

    setFaces(newFaces)
  }

  const onPlatformChanged = (value) => {
    setPlatformFilter(value)
  }

  const onAdvertisingChanged = (value) => {
    setAdvertisingFilter(value)
  }

  const isInClientFilter = faces => {
    if (!clientFilter || !clientFilter.length) {
      return true
    }

    const facesCompaniesIds = []

    for (let face of faces) {
      if (face.item && face.item.advertising && face.item.advertising.company) {
        facesCompaniesIds.push(face.item.advertising.company.id)
      }
    }

    return Boolean(clientFilter.find(company => facesCompaniesIds.includes(company.value)))
  }

  const isInAdvertisingFilter = faces => {
    if (!advertisingFilter || !advertisingFilter.value) {
      return true
    }

    const facesAdvertisingsIds = []

    for (let face of faces) {
      if (face.item && face.item.advertising && face.item.advertising.id) {
        facesAdvertisingsIds.push(face.item.advertising.id)
      }
    }

    return advertisingFilter.value && facesAdvertisingsIds.includes(advertisingFilter.value)
  }

  const isInPlatformFilter = platform => {
    if (!platformFilter || !platformFilter.value) {
      return true
    }

    let isPlatformFaces = false

    for (let face of platform.data) {
      if (isInClientFilter([face])) {
        isPlatformFaces = true
        break
      }
    }

    if ((platformFilter.value && (platform.platform.id === platformFilter.value))
      && isPlatformFaces) {
      return true
    }

    return false
  }

  const getSelectedPlatformsSumValues = (contractor) => {
    let sums = {}

    for (let platform of contractor.platforms) {
      if (!isInPlatformFilter(platform)) {
        continue
      }
      for (let face of platform.data) {
        if (!isInClientFilter([face]) || !isInAdvertisingFilter([face])) {
          continue
        }
        if (face.properties && face.properties.budget_submitted && face.properties.budget_submitted.value) {
          for (let propertyCode in face.properties) {
            const propertyValue = face.properties[propertyCode].value

            if (!sums[propertyCode]) {
              sums[propertyCode] = 0
            }

            sums[propertyCode] += propertyValue
          }
        }
      }
    }

    return sums
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const isAllLoading = () => isFacesLoading || isFacesSaving || isChecksLoading

  return (
    <div className="row content flex container-fluid padding-side-15 mediaplan-page">
      <div className={"table-edit-client contractor-checks"}>
        <MenuClient title={i18n.t('registries.list.title')}></MenuClient>
        <div className={'contractor-checks__header'}>
          <div className={'contractor-checks__filters'}>
            <div>
              <Filter
                filters={Object.values(filters)}
                getData={setParams}
              />
            </div>

            {isAllLoading() &&
              <Loader />
            }

            <div className="menu-filter">
              <span className={'filter-item platforms-select'}>
                <Select className="react-select-container"
                        classNamePrefix="react-select"
                        options={platformOptions}
                        placeholder={'Площадка'}
                        onChange={onPlatformChanged}
                        value={platformFilter}
                        isDisabled={false}
                />
              </span>
              <span className={'filter-item platforms-select'}>
                <Select className="react-select-container"
                        classNamePrefix="react-select"
                        options={advertisingOptions}
                        placeholder={'РК'}
                        onChange={onAdvertisingChanged}
                        value={advertisingFilter}
                        isDisabled={false}
                />
                </span>
            </div>
          </div>
          <div>
            {faces && checks &&
              <div className={'contractor-checks__comment'}>
                <label>Комментарий чека</label>
                <Input value={comment} onChange={setComment}/>
              </div>
            }
          </div>
        </div>


        {faces && checks &&
          <>
            <div className={'mediaplan-tables'}>
              <div className={"edit-client" + (isAllLoading() ? ' loading' : '')}>
                <table className="table redesign-table table-client goal-detail mediaplans">
                  <Header
                    properties={properties}
                    cellsBeforeProps={
                      <>
                        <td>{i18n.t('clients.advertising.platform')}</td>
                        <td>{i18n.t('clients.advertising.advertising')}</td>
                        <td>{i18n.t('clients.page.company')}</td>
                      </>
                    }
                    cellsAfterProps={
                    <>
                      <td>{i18n.t('clients.edit.comment')}</td>
                      <td />
                    </>
                    }
                    sortParams={sortParams}
                    propertiesSortParams={propertiesSortParams}
                    setSortParams={toggleSort}
                  />
                  <tbody>
                  {faces.map((periodItem, periodIndex) => (
                    <Fragment key={periodItem.dateStart + periodItem.dateEnd}>
                      {periodItem.contractors &&
                        <>
                          {periodItem.contractors.map((contractor, contractorIndex) => (
                            <Fragment key={contractorIndex}>
                              <SumRowWithCheckBox
                                properties={properties}
                                values={getContractorSumValues(contractor)}
                                title={i18n.t('clients.advertising.contractor_sum') + ' ' + contractor.contractor.name}
                                titleColSpan={1}
                                cellsAfterTitle={<td colSpan={2}/>}
                                cellsAfterProps={<td colSpan={2}/>}
                                onCheckChange={onChecked}
                              />

                              <SumRow
                                properties={properties}
                                values={getSelectedPlatformsSumValues(contractor)}
                                title={i18n.t('clients.advertising.selected_platforms_sum')}
                                titleColSpan={1}
                                cellsAfterTitle={<td colSpan={2}/>}
                                cellsAfterProps={<td colSpan={2}/>}
                              />

                              {contractor.platforms.length > 0 && contractor.platforms
                                .filter(platform => isInPlatformFilter(platform))
                                .map((platform, platformIndex) => (
                                  <Fragment key={platformIndex}>
                                    {platform.data && platform.data.length > 0 && isInClientFilter(platform.data) &&
                                      isInAdvertisingFilter(platform.data) &&
                                      <>
                                        <SumRow
                                          properties={properties}
                                          values={getPlatformSumValues(platform.data
                                            .filter(face => isInClientFilter([face]))
                                            .filter(face => isInAdvertisingFilter([face]))
                                          )}
                                          title={i18n.t('clients.advertising.platform_sum') + ' ' + platform.platform.name}
                                          titleColSpan={1}
                                          cellsAfterTitle={<td colSpan={2}/>}
                                          cellsAfterProps={<td colSpan={2}/>}
                                        />

                                        {platform.data
                                          .filter(face => isInClientFilter([face]))
                                          .filter(face => isInAdvertisingFilter([face]))
                                          .map((face, faceIndex) => (
                                            <Fragment key={face.item['@id']}>
                                              <Row
                                                className={((face.budgetDiff >= budgetMargin) ? 'row-with-diff' : '')
                                                  + (face.isBottomText ? 'bottom-text' : '')}
                                                properties={properties}
                                                values={face.properties || {}}
                                                titleColSpan={1}
                                                index={periodIndex}
                                                faceIindex={faceIndex}
                                                onChange={(value, property) => onRowChange(value, property, periodItem.dateStart, contractorIndex, platform.platform.id, face.id)}
                                                key={periodItem.dateStart + periodItem.dateEnd}
                                                title={
                                                  <span>{face.item.name}</span>
                                                }
                                                isSelectToPaste={false}
                                                rowCellComponent={RowCell}
                                                cellAfterTitle={
                                                  <>
                                                    <td>
                                                      {(face.item.advertising && getFactDataPageUrl(face)) ?
                                                        <Link to={getFactDataPageUrl(face)}>
                                                          {face.item.advertising.name}
                                                        </Link> : ''}
                                                    </td>
                                                    <td>
                                                      {(face.item.advertising && face.item.advertising.company) ?
                                                        <Link to={`/client/${face.item.advertising.company.id}/edit`}>
                                                          {face.item.advertising.company.name}
                                                        </Link> : ''}
                                                    </td>
                                                  </>
                                                }
                                                cellAfterProperties={
                                                  <>
                                                    <td>
                                                      {face.comment}
                                                    </td>
                                                    <td>
                                                      <div className='row-check-buttons'>
                                                        <div className={'correction-button'}
                                                             onClick={() => onSaveFace(face)}
                                                             title={i18n.t('contractors.save')}>
                                                          <i className={'far fa-save'}/>
                                                        </div>
                                                        {face.checkRowId &&
                                                          <DeleteWithConfirmButton
                                                            onClick={() => onFaceDelete(face)}
                                                            title={i18n.t('common.delete')}
                                                            className={'correction-button'}
                                                            iconClass={'icon-delete'}
                                                          />
                                                        }
                                                      </div>
                                                    </td>
                                                  </>

                                                }
                                              />
                                            </Fragment>
                                          ))
                                        }
                                      </>
                                    }
                                  </Fragment>
                                ))}
                            </Fragment>
                          ))}
                        </>
                      }
                    </Fragment>
                  ))}

                  </tbody>
                </table>
                {isAllLoading() &&
                  <LoaderBackdrop />
                }

                <Footer>
                  <div />
                  <div>
                    {faces &&
                      <ButtonWithLoader
                        onClick={onSaveAllFaces}
                        className={"button " + (isFacesSaving ? 'load' : '')}
                        text={i18n.t('clients.advertising.save_all')}
                        loadingText={i18n.t('clients.advertising.saving_values')}
                        isLoading={isFacesSaving}
                      />
                    }
                  </div>
                </Footer>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Index;