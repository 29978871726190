import React, {useState, useEffect} from 'react';
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import TopMenu from "../../../../Components/TopMenu";
import {Link} from "react-router-dom";
import ErrorBoundary from "../../../../Components/ErrorBoundary";
import moment from "moment";
import Table from "./Table";
import {getFiltersQuery, getSortParams, setUrlParams} from "../../../../utils";
import Filter from "../../../../Components/Filter/Filter";
import ReactPaginate from "react-paginate";
import i18next from "i18next";
import useDelete from "../../../../Components/Hooks/useDelete";
import {toast} from "react-toastify";
import options from "../../../../options";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import Protected from "../../../../Components/Roles/Protected";

const perPage = 30
const dateFormat = 'Y-MM-DD'

function List(props) {
  const [page, setPage] = useState(1)

  const [filter, setFilter] = useState({
    clients: [],
    contractor: '',
    advertising: '',
    platform: '',
    channel: '',
    dateStart: '',
    dateEnd: '',
  })

  const [sort, setSort] = useState({})

  const [isFetchDataCompleted, setIsFetchDataCompleted] = useState(null)

  const [{data: commissions, total: commissionsTotal}, getCommissions] = useApiFetch(apiUrls.get.commissions())

  const [{data: contractorLegals}, getContractorLegals] = useApiFetch(apiUrls.get.contractorLegals());

  const [{data: clients}, getClients] = useApiFetch(apiUrls.get.clients());

  const getPlatformsUrl = () => {
    if (filter.channel && filter.channel.value) {
      const channel = channels.find(channel => channel.id == filter.channel.value)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        if (channelEntity) {
          return channelEntity.getApiUrl('platformClients')
        }
      }
    }
  }

  const [{data: platformClients}, fetchPlatformClients, setPlatformClients] = useApiFetch(getPlatformsUrl);

  const [{data: advertisings}, fetchAdvertisings] = useApiFetch(apiUrls.get.adverts());

  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels());

  const deleteCommissionRequest = useDelete(apiUrls.delete.commission)

  const deleteCommission = id => {
    const isConfirm = window.confirm(i18n.t('commissions.list.confirm_delete'))

    if (isConfirm) {
      deleteCommissionRequest(id, () => {
        toast.success(i18n.t('commissions.list.deleted'))
        getCommissions()
      })
    }
  }

  const changeSort = async (name, value) => {
    await setSort({
      ...sort,
      [name]: value
    })

    goToFilters(page, {
      ...sort,
      [name]: value
    }, filter)
  }

  const changePage = (page) => {
    setPage(page)
    goToFilters(page, sort, filter)
  }

  const onSortClick = async (name) => {
    let order = ''

    if (sort[name] === options.ascSortOrder) {
      order = options.descSortOrder
    } else if (sort[name] === options.descSortOrder) {
      order = ''
    } else {
      order = options.ascSortOrder
    }

    changeSort(name, order)
  }

  useEffect(() => {
    getContractorLegals()
    getClients()
    getChannels()
  }, [])

  const fetchCommissions = (page, sort, filter) => {
    const sortParams = {}

    if (sort && Object.keys(sort).length) {
      for (let key of getSortParams(sort).keys()) {
        const param = getSortParams(sort).get(key)

        if (param) {
          sortParams[key] = param
        }
      }
    }

    getCommissions({
      page,
      ...sortParams,
      //'t[]': 'registry_list',
      ...getFilterParams(filter)
    })
  }

  useEffect(() => {
    if (contractorLegals && clients /*&& platformClients && advertisings*/) {
      setIsFetchDataCompleted(true)
    }

  }, [contractorLegals, clients])

  const getPlatformClientsByContractor = () => {
    if (filter.contractor && filter.contractor.value && filter.channel && filter.channel.value) {
      const channel = channels.find(channel => channel.id == filter.channel.value)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        if (channelEntity) {
          if (channelEntity.getApiUrl('platformClients')) {
            fetchPlatformClients({
              't[]': 'platform_client',
              'contractor.id': filter.contractor.value,
              'order[platform.name]': 'asc',
              pagination: false
            })
          } else {
            setPlatformClients([])
          }
        }
      }
    }
  }

  const getAdvertisings = () => {
    if (filter.dateStart && filter.dateEnd && filter.client && filter.client.value) {
      fetchAdvertisings({
        'company.id': filter.client.value,
        'dateStart[before]': filter.dateEnd,
        'dateEnd[after]': filter.dateStart,
        pagination: false
      })
    }
  }

  const getFilterParams = (filter) => {
    let queryParams = {}

    for (let filterName in filter) {
      if (filter.hasOwnProperty(filterName) && filter[filterName]) {
        switch (filterName) {
          case 'contractor':
          case 'advertising':
          case 'channel':
            if (filter[filterName].value) {
              queryParams[filterName + '.id'] = filter[filterName].value
            }

            break;

          case 'platform':
            if (!filter.channel || !filter.channel.value) {
              break
            }

            const channel = channels.find(channel => channel.id == filter.channel.value)

            if (!channel) {
              break
            }

            const channelEntity = ChannelFactory.create(channel.code)

            if (!channelEntity) {
              break
            }

            if (filter[filterName].value) {
              queryParams[channelEntity.platformName + '.id'] = filter[filterName].value
            }

            break;

          case 'clients':
            if (filter[filterName] && filter[filterName].length) {
              for (let filterItem of filter[filterName]) {
                if (filterItem.value === 'all') {
                  queryParams['exists[clients]'] = false
                } else {
                  if (!queryParams[filterName + '.id[]']) {
                    queryParams[filterName + '.id[]'] = [];
                  }

                  queryParams[filterName + '.id[]'].push(filterItem.value)
                }
              }
            }

            break;
          case 'dateStart':
            queryParams['dateEnd[after]'] = moment.utc(filter[filterName]).format(dateFormat)
            break;
          case 'dateEnd':
            queryParams['dateStart[before]'] = moment.utc(filter[filterName]).format(dateFormat)
            break;
        }
      }
    }

    return queryParams
  }

  const onFilterSet = (filter, callback, name, page, sort, goToFilter = true) => {
    setFilter(filter)

    if (goToFilter) {
      goToFilters(page, sort, filter)
    }
  }

  const goToFilters = (page, sort, filter) => {
    props.history.push({
      pathname: props.history.location.pathname,
      search: getFiltersQuery(filter, page, sort)
    })
  }

  const emptyOption = {
    label: i18n.t('commissions.list.not_selected'),
    value: ''
  }

  const contractorOptions = contractorLegals
    ? contractorLegals.map((contractor) => ({value: contractor.id, label: contractor.name}))
    : []

  const clientOptions = clients
    ? clients.map((client) => ({value: client.id, label: client.name}))
    : []

  const platformOptions = platformClients
    ? platformClients.filter(platformClient => platformClient.platform && platformClient.platform.id)
      .map((platformClient) => ({value: platformClient.platform.id, label: platformClient.platform.name}))
    : []

  const advertisingOptions = advertisings
    ? advertisings.map((advertising) => ({value: advertising.id, label: advertising.name}))
    : []

  const channelsOptions = channels
    ? channels.map((channel) => ({value: channel.id, label: channel.name}))
    : []

  const allClientsOption = {
    label: i18n.t('commissions.list.all'),
    value: 'all'
  }

  contractorOptions.unshift(emptyOption)
  clientOptions.unshift(allClientsOption)
  platformOptions.unshift(emptyOption)
  advertisingOptions.unshift(emptyOption)

  const filterOptions = [
    {
      type: 'date',
      value: filter.dateStart,
      placeholder: i18n.t('commissions.list.dateStart'),
      name: 'dateStart',
      format: i18n.t('calendar_date_format'),
      filterFormat: dateFormat,
      className: 'has-separator pointer',
      callback: getAdvertisings,
    },
    {
      type: 'date',
      value: filter.dateEnd,
      placeholder: i18n.t('commissions.list.dateEnd'),
      name: 'dateEnd',
      format: i18n.t('calendar_date_format'),
      filterFormat: dateFormat,
      className: 'has-separator pointer',
      callback: getAdvertisings,
    },
    {
      type: 'dropdown',
      value: filter.contractor,
      placeholder: i18n.t('commissions.list.contractor'),
      options: contractorOptions,
      name: 'contractor',
      callback: getPlatformClientsByContractor,
      className: 'has-separator pointer'
    },
    {
      type: 'dropdown',
      value: filter.clients,
      placeholder: i18n.t('commissions.list.clients'),
      options: clientOptions,
      name: 'clients',
      className: 'has-separator pointer',
      callback: getAdvertisings,
      isMulti: true
    },
    {
      type: 'dropdown',
      value: filter.channel,
      placeholder: i18n.t('commissions.list.channel'),
      options: channelsOptions,
      name: 'channel',
      callback: getPlatformClientsByContractor,
      className: 'has-separator pointer'
    },
    {
      type: 'dropdown',
      value: filter.platform,
      placeholder: i18n.t('commissions.list.platform'),
      options: platformOptions,
      name: 'platform',
      className: 'has-separator pointer',
      isDisabled: !filter.contractor || !filter.contractor.value || !filter.channel || !filter.channel.value,
    },
    {
      type: 'dropdown',
      value: filter.advertising,
      placeholder: i18n.t('commissions.list.advertising'),
      options: advertisingOptions,
      name: 'advertising',
      className: 'has-separator pointer',
      isDisabled: (!filter.clients || !filter.clients.length) || (!filter.dateStart) || (!filter.dateEnd),
    },
  ]

  return (
    <div className="container-fluid padding-side-15 mediaplan-page mediaplan">
      <div className="row">
        <TopMenu title={i18n.t('commissions.list.title')}>
          <Protected access={'FinancialCommissionsEdit'}>
            <Link to={'/financial/commission/new'}
              className="button-accent mediaplan-ad-button">
              {i18n.t('commissions.list.add')}
            </Link>
          </Protected>
        </TopMenu>

        <ErrorBoundary
          errorText={i18n.t('mediaplans.page.errors.advert_show_error')}
          className={'boundary-error mediaplan-error'}
        >
          {filterOptions && contractorLegals &&
            <Filter
              filters={filterOptions}
              filter={filter}

              setPage={(value) => {
                setPage(value)
                /*setUrlParams(props,
                  {
                    page: value
                  })*/
              }}
              setFilter={onFilterSet}
              setSort={sort => {
                setSort(sort)

              }}
              getData={(page, sort, filter) => {
                fetchCommissions(page, sort, filter)
              }}
              location={props.location}
              isFetchDataCompleted={isFetchDataCompleted}
            />
          }

          {filter && sort && page &&
          <Table
            commissions={commissions}
            onDelete={deleteCommission}
            onSortClick={onSortClick}
            sort={sort}
          />
          }

          {(commissionsTotal > 0 && commissionsTotal > perPage) && page &&
          <ReactPaginate
            previousLabel={i18next.t('projects.list.prev')}
            nextLabel={i18next.t('projects.list.next')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(commissionsTotal / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(value) => {
              changePage(+value.selected + 1)
            }
            }
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={page - 1}
          />
          }
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default List;