import {EventSourcePolyfill} from 'event-source-polyfill';
import {toastSubscribeResult} from "../../subscribe";
import keycloak from '../../keycloak';
import apiUrls from "../../ApiUrls";
import {toast} from "react-toastify";
import i18n from 'i18next'
import {api} from "../../api/apiProvider";
import store from '../../store/store';
import {addSubscribed} from "../../store/actions";

const EventSource = EventSourcePolyfill;

export const sendPlanValues = async (id, withSubscribe = true) => {
  if (withSubscribe) {
    await subscribeToAdvertisingPush(id)
  }

  api.post(apiUrls.get.advertising(id) + '/push')
    .then((data) => {
      if (data && data.success) {
        toast.success(i18n.t('clients.advertising.plan_values_sent'))
      }
    })
}

export const subscribeToAdvertisingPush = async (id) => {
  const url = process.env.REACT_APP_MERCURE_SUBSCRIBE_URL + '?topic='
    + encodeURIComponent(apiUrls.get.advertising(id) + '/push')

  const storeState = store.getState()

  if (storeState.subscribed && (url in storeState.subscribed)) {
    return
  }

  store.dispatch(addSubscribed(url))

  let eventSource = await new EventSource(url,
    {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      }
    });

  eventSource.onerror = () => {
    //
  }

  eventSource.onmessage = async event => {
    try {
      const result = JSON.parse(event.data);

      toastSubscribeResult(result)
    } catch (e) {
      toast.error(i18n.t('clients.advertising.plan_values_sent_error'))
    }
  }
}