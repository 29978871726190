import React, {useEffect, useState} from 'react';
import {getFormattedDate} from "./Utils";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import i18n from "i18next";

export default function PullHistory({
                                      items, advertising, setItems = () => {
  }, getItems = () => {
  }, isLoading = false
                                    }) {
  const [{errors: addErrors}, addFace] = useApiPost(
    apiUrls.post.internetFace(),
    '',
    'periods.face',
    () => {
      getItems()
      toast.success(i18n.t('periods.face.added'))
    }
  )

  const createFace = (item) => {
    let form = {}
    form.name = ''
    form.advertising = advertising ? advertising['@id'] : null
    form.platform = item.platform ? item.platform['@id'] : null
    form.channel = item.channel ? item.channel['@id'] : null

    addFace(form)
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
           onClick={() => setItems(null)}/>
      <div className='popup-form__wrapper history-popup'>
        <div className='popup-form__header'>
          <button
            onClick={() => setItems(null)}>
            <i className='fas fa-remove'/>
          </button>
        </div>
        {items &&
          <table className="table redesign-table table-client goal-detail mediaplans with-bottom-text">
            <thead className={'table-header'}>
            <tr>
              <th>{i18n.t('pull_history.name')}</th>
              <th>{i18n.t('pull_history.channel')}</th>
              <th>{i18n.t('pull_history.platform')}</th>
              <th>{i18n.t('pull_history.date')}</th>
              <th>{i18n.t('pull_history.error')}</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>{item.face ? item.face.name : ''}</td>
                <td>{item.channel ? item.channel.name : ''}</td>
                <td>{item.platform ? item.platform.name : ''}</td>
                <td>{getFormattedDate(item.date)}</td>
                <td>{item.error}</td>
                <td>{item.platform && item.channel && !item.face &&
                  <a className="blue-cursor-button pull-add-button"
                     onClick={() => createFace(item)}>
                    <i className="fal fa-plus mr-for-icon"></i>{i18n.t('pull_history.add_face')}</a>
                }</td>
              </tr>
            ))}
            </tbody>
          </table>
        }

        {items && items.length === 0 && !isLoading &&
          <span>{i18n.t('pull_history.empty_history')}</span>
        }

      </div>
    </div>
  )
}