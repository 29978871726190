import React, { Component } from 'react';

class Checkbox extends Component {
    filterProp = (key) => {
        return typeof this.props[key] !== 'function' && key !== 'errorText'
    }

    getProps = () => {
        return Object.keys(this.props)
          .filter(key => this.filterProp(key))
          .reduce((obj, key) => {
              obj[key] = this.props[key];
              return obj;
          }, {});
    }

    render() {
        const props = this.getProps()

        return (
            <input {...props} type="checkbox" checked={this.props.checked} onChange={(event) => this.props.onChange(event.target.checked)} />
        );
    }
}

export default Checkbox;