import React from 'react';
import FloatInput from "../../../../Components/Inputs/FloatInput";
import i18n from "i18next";
import DatePicker from "react-datepicker";
import {getFormattedDate, getUtcDate} from "../Fact/Utils";

function AffinityRow({affinity, onChange}) {
  const onDateChange = (value) => {
    onChange({
      ...affinity,
      dateStart: getFormattedDate(value, 'YYYY-MM-DD')
    })
  }

  const onValueChange = (value) => {
    onChange({
      ...affinity,
      value
    })
  }

  return (
    <div key={affinity.id} className={'affinity-item'}>
      <DatePicker
        selected={getUtcDate(affinity.dateStart).toDate()}
        onChange={onDateChange}
        dateFormat={i18n.t('calendar_date_format')}
      />

      <FloatInput
        onChange={onValueChange}
        value={affinity.value}
      />
    </div>
  )
}

export default AffinityRow;