import React, {useEffect, useState, forwardRef} from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";

function DateRangeFilter({value, onChange, element}) {
  const currentDate = new Date()
  const firstMonthDay = moment(currentDate).startOf('month').toDate()

  const [startDate, setStartDate] = useState((value && value[0]) ? value[0] : firstMonthDay);
  const [endDate, setEndDate] = useState((value && value[1]) ? value[1] : null);

  const onDatesChange = dates => {
    const [start, end] = dates;
    const startDate = start ? moment(start).format(element.filterFormat) : null
    const endDate = end ? moment(end).endOf('month').format(element.filterFormat) : null

    onChange([startDate, endDate])
  };

  useEffect(() => {
    if (value) {
      if (value[0]) {
        setStartDate(moment(value[0], element.filterFormat).toDate())
      }

      setEndDate((value && value[1]) ? moment(value[1], element.filterFormat).endOf('month').toDate() : null)
    }
  }, [value])

  const RangeInput = forwardRef(
    ({value, onClick}, ref) => {
      return (
        <>
          <button className="range-button" onClick={onClick} ref={ref}>
            {startDate ? moment(startDate, element.filterFormat).format(element.rangeFormat) : ''}
          </button>
          - <button className="range-button" onClick={onClick}>
          {endDate ? moment(endDate, element.filterFormat).format(element.rangeFormat) : '...'}
        </button>
        </>
      )
    },
  );

  return (
    <span className={'filter-item ' + ((!startDate || !endDate) ? 'error' : '')}>
      <DatePicker
        placeholderText={element.placeholder}
        selected={startDate}
        onChange={onDatesChange}
        startDate={startDate}
        endDate={endDate}
        dateFormat={element.format}
        selectsRange
        showMonthYearPicker={element.isMonth}
        shouldCloseOnSelect={false}
        customInput={<RangeInput />}
      /></span>
  )
}

export default DateRangeFilter