import React, { Component } from 'react';
import SubMenu from '../Components/SubMenu';
import MenuClien from '../Components/MenuClient';
import { toast } from 'react-toastify';
import AutocompleteInput from '../Components/Inputs/AutocompleteInput';
import { api } from '../api/apiProvider';
import Input from '../Components/Inputs/Input';
import i18n from "i18next";
import apiUrls from "../ApiUrls";

class RegionCreating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionName: "",
            locations: [],
            locationToAdd: []
        }
    }
    getData = () => {
        const regionId = this.props.match.params.regionID;
        api.get(apiUrls.get.region(regionId) + "?t[]=region_location").then((data) => {
            this.setState({ regionName: data.name, locations: data.locations, locationToAdd: data.locations });
        })
    }


    componentDidMount() {
        const regionID = this.props.match.params.regionID;
        if (regionID !== undefined) {
            this.getData();
        }
    }

    enqueueAddLocation = (result) => {
        const currentLocation = this.state.locationToAdd;
        currentLocation.push(result);
        this.setState({ locationToAdd: currentLocation });
    }

    removeLocationToAdd = (index) => {
        let currentLocation = this.state.locationToAdd;
        currentLocation.splice(index, 1);
        this.setState({ locationToAdd: currentLocation });
    }

    saveNewRegion = async () => {
        const body = {
            name: this.state.regionName,
            company: apiUrls.get.company(this.props.match.params.companyID),
            locations: this.state.locationToAdd.map((location) => location["@id"])
        }
        try {
            await api.post(apiUrls.get.regions(), body);
            toast.success(i18n.t('clients.edit.region_changed'));
            this.props.history.push(apiUrls.get.clientCities(this.props.match.params.companyID))
        } catch (error) {
            toast.error(i18n.t('clients.edit.save_fail'))
        }
    }


    editRegion = async () => {
        const body = {
            name: this.state.regionName,
            locations: this.state.locationToAdd.map((location) => location["@id"])
        }
        try {
            await api.patch(apiUrls.get.region(this.props.match.params.regionID), body);
            toast.success(i18n.t('clients.edit.region_changed'));
        } catch (error) {
            toast.error(i18n.t('clients.edit.save_fail'))
        }
    }


    onClickSave = () => {
        if (this.props.match.params.regionID === undefined) {
            this.saveNewRegion();

        }
        else {
            this.editRegion();
        }
    }

    render() {
        let topTitle;
        if (this.props.match.params.regionID === undefined) {
            topTitle = i18n.t('clients.edit.create_region');
        }
        else {
            topTitle = i18n.t('clients.edit.edit_region')
        }
        return (
            <div className="row content flex">
                <div className="edit-client">
                    <SubMenu/>
                    <div className="table-edit-client">
                        <div>
                            <MenuClien title={topTitle}>
                                <button onClick={this.onClickSave} className="button-accent">
                                    {/* {{ 'index.save'|trans }} */}
                                    {i18n.t('clients.edit.save')}
                                </button>
                            </MenuClien>
                            <input type="hidden" id="cities_id" name="cities_ids" />
                            <table className="table redesign-table table-client">
                                <tbody>
                                    <tr>
                                        <td>
                                            {i18n.t('clients.edit.region')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input type="text" value={this.state.regionName} onChange={(value) => this.setState({ regionName: value })} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {i18n.t('clients.edit.cities')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <AutocompleteInput
                                                    resource="locations"
                                                    filter="name"
                                                    onSelect={(result) => this.enqueueAddLocation(result)}
                                                    placeholder={i18n.t('clients.edit.start_typing')}
                                                />
                                            </div>
                                            <div className="add_new_city">
                                                {this.state.locationToAdd.map((location, index) => (
                                                    <div key={index} className="city_show">
                                                        <span onClick={() => this.removeLocationToAdd(index)}>
                                                            <i className="fal fa-minus red-icon"></i>
                                                        </span>
                                                        {location.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegionCreating;