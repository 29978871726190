import React from 'react';
import Select from "react-select";

function FilterSelect({title, options, selected, placeholder = '', onChange, isMulti = false}) {
  return (
    <div className="filter-group-wrapper">
      <div className="filter-group__title" >
        {title}
      </div>
      <div className="filter-group">
        <Select className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                isMulti={isMulti}
                value={selected}
                isDisabled={false}
        />
      </div>
    </div>
  )
}

export default FilterSelect