import React, {useEffect, useMemo, useState} from 'react'
import MenuClient from '../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../ApiUrls"
import useApiFetch from "../../../Components/Hooks/useApiFetch"
import Filter from "../../../Components/NewFilter/Filter";
import {getFormattedDate, getUtcDate} from "../FacesTotal/Utils";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../Components/Loader/Loader";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import useApiPost from "../../../Components/Hooks/useApiPost";
import Header from "../../Advertising/Detail/components/Table/Header";
import Row from "../../Advertising/Detail/components/Table/Row";
import Roles from "../../../roles/Roles";
import {Link} from "react-router-dom";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import Footer from "../../Advertising/Detail/components/Footer";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {toast} from "react-toastify";
import useDelete from "../../../Components/Hooks/useDelete";
import SumRow from "../../Advertising/Detail/components/Table/SumRow";
import i18next from "i18next";
import EmptyRowCell from "../EmptyRowCell";

const dateFormat = 'Y-MM-DD'

const emptyOption = {
  label: i18next.t('projects.list.not_selected'),
  value: ''
}

function Index() {

  const [filterParams, setFilterParams] = useState('')
  const [channelFilter, setChannelFilter] = useState({})
  const [dateStartFilter, setDateStartFilter] = useState(null)
  const [dateEndFilter, setDateEndFilter] = useState(null)
  const [contractorFilter, setContractorFilter] = useState(null)
  const [platformFilter, setPlatformFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState([])
  const [advertisingFilter, setAdvertisingFilter] = useState([])
  const [advertisingsMap, setAdvertisingsMap] = useState(null)
  const [facesMap, setFacesMap] = useState(null)
  const [facesMapWithData, setFacesMapWithData] = useState(null)
  const [clientCheck, setClientCheck] = useState(null)
  const [properties, setProperties] = useState(null)
  const [changedFaces, setChangedFaces] = useState([])
  const [isFactSaving, setIsFactSaving] = useState(null)
  const [adverts, setAdverts] = useState(null)

  const sumValues = useMemo(() => {
    let sums = {}

    if (!facesMapWithData) {
      return {}
    }

    facesMapWithData.forEach(face => {
      if (face.properties) {
        for (let code in face.properties) {
          if (sums[code] === undefined) {
            sums[code] = 0
          }

          const value = face.properties[code].value || 0

          sums[code] += value
        }
      }
    })

    return sums
  }, [facesMapWithData])

  const getFacesUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.apiUrls.faces
      }
    }

    return null
  }

  const getChannelPropertiesUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.apiUrls.properties
      }
    }

    return null
  }

  const getPlanDataUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.apiUrls.totalPlan
      }
    }

    return null
  }

  const [{
    data: clientChecks,
    isLoading: isClientChecksLoading
  }, getClientChecks] = useApiFetch(apiUrls.get.clientChecks())
  const [{data: channels, isLoading: isChannelsLoading}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: faces, isLoading: isFacesLoading}, getFaces] = useApiFetch(getFacesUrl())
  const [{
    data: channelProperties,
    isLoading: isChannelProperties
  }, getChannelProperties] = useApiFetch(getChannelPropertiesUrl())
  const [{data: planData, isLoading: isPlanDataLoading}, getPlanData] = useApiFetch(getPlanDataUrl())
  const [{errors: createClientCheckError}, createClientCheck] = useApiPost(
    apiUrls.get.clientChecks(),
    '',
    'errors'
  )

  const getPlatformsUrl = channelId => {
    if (channelId && channels) {
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)
        return channelEntity.apiUrls.platforms
      }
    }

    return null
  }

  const [{errors: updateClientCheckErrors}, updateClientCheck] = useApiPatch(
    apiUrls.get.clientCheck,
    '',
    'errors',
    () => {},
    () => {
      setIsFactSaving(false)
    }
  )

  const [{errors: addClientCheckRowErrors}, addClientCheckRow] = useApiPost(
    apiUrls.get.clientCheckRows(),
    '',
    'errors',
  )

  const [{errors: updateClientCheckRowErrors}, updateClientCheckRow] = useApiPatch(
    apiUrls.get.clientCheckRow,
    '',
    'errors',
  )

  const deleteClientCheckRow = useDelete(apiUrls.get.clientCheckRow)

  const filters = {
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.channel'),
      //getOptionsUrlFunction: apiUrls.get.channels,
      name: 'channel',
      optionValues: channels ? channels.map(channel => ({
        value: channel.id, label: channel.name, code: channel.code
      })) : [],
      /*setOptions: (options) => {
        setChannels(options)
      },*/
      setFilterParams: (params, filterValue) => {
        params.append('channel.id', filterValue.value)
        setChannelFilter(filterValue)
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], dateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(dateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(dateFormat)
        const endDate = dateValue.endOf('month').format(dateFormat)
        params.append('dateStart[after]', startDate)
        params.append('dateEnd[before]', endDate)
        setDateStartFilter(startDate)
        setDateEndFilter(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: dateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, filterValue) => {
        params.set('company.id', filterValue.value)

        setClientFilter(filterValue)
      },
      getValueToUrl: (params, company) => {
        if (company && company.value) {
          params.set('company', company.value)
        } else {
          params.delete('company')
        }
      },
      required: true,
    },
    legal: {
      type: 'select',
      placeholder: i18n.t('clients.page.legal'),
      getOptionsUrlFunction: apiUrls.get.contractorLegals,
      setFilterParams: (params, filterValue) => {
        params.delete('contractor.id')
        setContractorFilter(null)

        if (filterValue && filterValue.value) {
          params.append('contractor.id', filterValue.value)
          setContractorFilter(filterValue)
        }
      },
      getValueToUrl: (params, legal) => {
        if (legal && legal.value) {
          params.set('legal', legal.value)
        } else {
          params.delete('legal')
        }
      },
      name: 'legal',
      className: 'has-separator pointer',
      isEmptyOption: true,
    },
    advertising: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.advertising'),
      getOptionsUrlFunction: ({company, channel, date}) => {
        const dateValue = getUtcDate(date)
        const startDate = dateValue.startOf('month').format(dateFormat)
        const endDate = dateValue.endOf('month').format(dateFormat)
        return getAdvertsUrl(channel.value, company.value, startDate, endDate)
      },
      setOptionValues: values => {
        setAdverts(values)
      },
      name: 'advertising',
      setFilterParams: (params, values) => {
        const advertisingsFilter = []

        for (let filterValue of values) {
          params.append('advertising.id[]', filterValue.value)
          advertisingsFilter.push(filterValue.value)
        }

        setAdvertisingFilter(advertisingsFilter)
      },
      getValueToUrl: (params, id) => {
        if (id && id.length) {
          params.delete('advertising[]')
          for (let idItem of id) {
            params.append('advertising[]', idItem.value)
          }
        } else {
          params.delete('advertising[]')
        }
      },
      isDisabled: ({channel, company, date}) => !channel || !channel.value || !company || !company.value || !date,
      depends: ['company', 'channel', 'date'],
      className: 'has-separator pointer',
      isMulti: true,
    },
    platform: {
      type: 'select',
      placeholder: i18n.t('clients.advertising.platform'),
      getOptionsUrlFunction: ({channel}) => {
        if (!getPlatformsUrl(channel.value)) {
          return null
        }
        return getPlatformsUrl(channel.value) + '?pagination=false'
      },
      setFilterParams: (params, filterValue) => {
        setPlatformFilter(null)

        if (filterValue && filterValue.value) {
          setPlatformFilter(filterValue)
        }
      },
      getValueToUrl: (params, legal) => {
        if (legal && legal.value) {
          params.set('platform', legal.value)
        } else {
          params.delete('platform')
        }
      },
      name: 'platform',
      depends: ['channel'],
      className: 'has-separator pointer',
      isEmptyOption: true,
    },
  }

  const fetchData = () => {
    if (filterParams) {
      getFaces(getFacesParams())
      getClientChecks(getClientChecksParams())
    }
  }

  const updateData = () => {
    if (adverts && channels) {
      getClientChecks(getClientChecksParams())
    }

    if (faces) {
      getPlanData(getTotalDataParams())
    }
  }

  useEffect(() => {
    getChannels()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filterParams])

  useEffect(() => {
    if (adverts && channels) {

      const advertisingsMap = new Map()

      for (let advert of adverts) {
        advertisingsMap.set(advert['@id'], advert)
      }

      setAdvertisingsMap(advertisingsMap)
    }
  }, [adverts, channels])

  useEffect(() => {
    if (faces) {
      const facesMap = new Map()

      for (let face of faces) {
        const advertising = advertisingsMap.get(face.advertising)

        facesMap.set(face['@id'], {
          id: face.id,
          name: face.name,
          advertising: advertising,
        })
      }

      setFacesMap(facesMap)
      getPlanData(getTotalDataParams())
    }
  }, [faces])

  useEffect(() => {
    if (clientChecks && planData
      && !isClientChecksLoading && !isPlanDataLoading) {
      const newFacesMap = new Map(JSON.parse(JSON.stringify([...facesMap])))

      newFacesMap.forEach(face => {
        if (!('check' in face)) {
          face.check = null
        }

        if (!face.properties) {
          face.properties = {
            clientBudget: {
              editable: true,
              value: '',
            },
            planBudget: {
              editable: false,
              value: 0,
            }
          }
        }
      })

      for (let period of planData) {
        if (period.data && period.data.length) {
          for (let item of period.data) {
            const itemIri = item.item

            if (newFacesMap.has(itemIri)) {
              const face = newFacesMap.get(itemIri)

              face.properties.planBudget.value = item.properties.budget.value

              newFacesMap.set(itemIri, {
                ...face,
              })
            }
          }
        }
      }

      if (clientChecks[0] && clientChecks[0].rows && clientChecks[0].rows.length) {
        for (let checkRow of clientChecks[0].rows) {
          const faceIri = checkRow.faces[0]['@id']

          if (newFacesMap.has(faceIri)) {
            const face = newFacesMap.get(faceIri)

            face.checkRow = checkRow

            face.properties.clientBudget = {
              editable: !checkRow.approved,
              value: checkRow.budget
            }

            face.clientCheckRowApproved = !!checkRow.approved

            if (checkRow.approved) {
              face.properties.clientBudget.bottom = i18n.t('face_check.client_budget_checked')
              face.properties.clientBudget.bottomClass = 'cell-bottom checked'
            }

            newFacesMap.set(faceIri, {
              ...face,
            })
          }
        }
      }

      newFacesMap.forEach(face => {
        if (!Roles.hasAccess('ClientsReconClientBudgetEdit')) {
          face.properties.clientBudget.editable = false
        }
      })

      setFacesMapWithData(newFacesMap)
    }
  }, [/*factData, */clientChecks, planData])

  useEffect(() => {
    if (clientChecks) {
      if (!clientChecks.length) {
        createClientCheck({
          channel: `/channels/${channelFilter.value}`,
          client: `/companies/${clientFilter.value}`,
          dateStart: dateStartFilter,
          dateEnd: dateEndFilter
        }, null, {}, true, () => {
          getClientChecks(getClientChecksParams())
        })
      } else {
        setClientCheck(clientChecks[0])
      }
    }
  }, [clientChecks])

  useEffect(() => {
    if (channelFilter && channelFilter.value) {
      getChannelProperties(getPropertiesParams())
    }
  }, [channelFilter])

  useEffect(() => {
    if (channelProperties && channelProperties.length) {
      const properties = channelProperties

      properties.push({...channelProperties[0]})

      properties[1].name = i18n.t('clients.edit.client_budget')
      properties[1].code = 'clientBudget'

      properties[0].code = 'planBudget'

      setProperties(properties)
    }
  }, [channelProperties])

  const getClientChecksParams = () => {
    if (channelFilter && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'client.id': clientFilter.value,
        'channel.id': channelFilter.value,
        'dateStart': dateStartFilter,
        'dateEnd': dateEndFilter,
      }
    }

    return {}
  }

  const getTotalDataParams = () => {
    if (faces && channelFilter && clientFilter && dateStartFilter && dateEndFilter) {
      const params = {
        'face.advertising.company.id': clientFilter.value,
        interval: 'month',
        'date[after]': dateStartFilter,
        'date[before]': dateEndFilter,
        'order[face.advertising.id]': 'ASC',
        'props[]': 'budget',
        'face.advertising.id[]': advertisingFilter,
      }

      if (contractorFilter && contractorFilter.value) {
        params['face.contractor.id'] = contractorFilter.value
      }

      if (platformFilter && platformFilter.value) {
        params['face.platform.id'] = platformFilter.value
      }

      return params
    }

    return {}
  }

  const getAdvertsUrl = (channelId, companyId, dateStart, dateEnd) => {
    const params = new URLSearchParams({
      'company.id': companyId,
      'targets.channel.id': channelId,
      'dateEnd[after]': dateStart,
      'dateStart[before]': dateEnd,
      't[]': 'short',
      'pagination': 'false',
    })


    return apiUrls.get.adverts() + '?' + params.toString()
  }

  const getAdvertsParams = () => {
    if (channelFilter && clientFilter && dateStartFilter && dateEndFilter) {

      return {
        'company.id': clientFilter.value,
        'targets.channel.id': channelFilter.value,
        'dateEnd[after]': dateStartFilter,
        'dateStart[before]': dateEndFilter,
        't[]': 'short',
        'pagination': 'false',
      }
    }

    return {}
  }

  const getFacesParams = () => {
    return {
      'advertising.company.id': clientFilter.value,
      'contractor.id': contractorFilter ? contractorFilter.value : null,
      'advertising.dateEnd[after]': dateStartFilter,
      'advertising.dateStart[before]]': dateEndFilter,
      't[]': 'face_financial',
      'order[advertising.id]': 'ASC',
      'advertising.id[]': advertisingFilter,
      'platform.id': platformFilter ? platformFilter.value : null,
      'pagination': 'false',
    }
  }

  const getPropertiesParams = () => {
    return {
      code: 'budget',
      entity: "face"
    }
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const getAdvertisingDates = (dateStart, dateEnd) => {
    const periodStartDate = getUtcDate(dateStartFilter).toDate()
    const periodEndDate = getUtcDate(dateEndFilter).toDate()

    const advertisingStartDate = getUtcDate(dateStart).toDate()
    const advertisingEndDate = getUtcDate(dateEnd).toDate()

    const start = advertisingStartDate > periodStartDate
      ? advertisingStartDate
      : periodStartDate

    const end = advertisingEndDate < periodEndDate
      ? advertisingEndDate
      : periodEndDate

    return [start, end];
  }

  const onRowChange = async (value, key, code) => {
    const newFacesMap = new Map(JSON.parse(JSON.stringify([...facesMapWithData])))
    if (newFacesMap.has(key)) {
      const face = newFacesMap.get(key)
      face.properties[code].value = value

      newFacesMap.set(key, {
        ...face,
      })

      setFacesMapWithData(newFacesMap)

      if (!changedFaces.includes(key)) {
        setChangedFaces(values => (
          [
            ...values,
            key
          ]
        ))
      }
    }
  }

  const onSaveAllData = async () => {
    setIsFactSaving(true)

    Promise.all(
      [
        saveAllClientCheckRows()
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        updateData()
      })
      .finally(() => {
        setIsFactSaving(false)
        setChangedFaces([])
      })
      .catch(() => {
        setIsFactSaving(false)
        setChangedFaces([])
      })
  }

  const onSaveFaceData = async (face, faceIri) => {
    Promise.all(
      [
        saveClientCheckRow(face, faceIri)
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        updateData()
      })
  }

  const saveClientCheckRow = async (face, faceIri) => {
    return new Promise((resolve, reject) => {
      if (!face || !face.properties || !face.properties.clientBudget || !face.properties.clientBudget.editable
        || face.properties.clientBudget.value === '') {
        return resolve(true)
      }

      if (face.checkRow && face.checkRow.approved) {
        return resolve(true)
      }

      if (face.checkRow) {
        updateClientCheckRow(face.checkRow.id, {
          clientCheck: clientCheck['@id'],
          budget: face.properties.clientBudget.value,
          faces: [
            faceIri
          ]
        }, {}, () => {
          return resolve(true)
        })
      } else {
        addClientCheckRow({
          clientCheck: clientCheck['@id'],
          budget: face.properties.clientBudget.value,
          faces: [
            faceIri
          ]
        }, true, {}, true, () => {
          return resolve(true)
        })
      }
    })
  }

  const saveAllClientCheckRows = async () => {
    return new Promise((resolve, reject) => {
      const check = {
        client: clientCheck.client,
        channel: clientCheck.channel,
        dateStart: clientCheck.dateStart,
        dateEnd: clientCheck.dateEnd,
        rows: [],
      }

      for (let faceIri of changedFaces) {
        if (facesMapWithData.has(faceIri)) {
          const face = facesMapWithData.get(faceIri)

          if (!face.properties.clientBudget.editable || face.properties.clientBudget.value === '') {
            continue
          }

          const checkRowData = {
            budget: face.properties.clientBudget.value,
            faces: [
              faceIri
            ]
          }

          if (face.checkRow && face.checkRow['@id']) {
            checkRowData.id = face.checkRow['@id']
          }

          check.rows.push(checkRowData)
        }
      }

      if (check.rows.length) {
        updateClientCheck(clientCheck.id, check, {}, () => {
          return resolve(true)
        })
      } else {
        return resolve(true)
      }
    })
  }

  const getFactDataPageUrl = face => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const [intervalStart, intervalEnd]
          = getAdvertisingDates(face.advertising.dateStart, face.advertising.dateEnd)

        if (channel.code) {
          return `/advertisings/${
            face.advertising.id}/fact/${channel.code}?dateStart=${
            getFormattedDate(intervalStart, dateFormat)}&dateEnd=${
            getFormattedDate(intervalEnd, dateFormat)}` + (contractorFilter ? `&contractor=${contractorFilter.value}` : '')
        }
      }
    }
  }

  const onDeleteClientCheckRow = (face) => {
    if (face && face.checkRow) {
      deleteClientCheckRow(face.checkRow.id, () => {
        toast.success(i18n.t('common.deleted'))
        //fetchData()
      })
    }
  }

  const isFaceEditable = face => face && face.properties && face.properties.clientBudget
    && face.properties.clientBudget.editable && face.properties.clientBudget.value !== ''

  const isAnyLoading = () => isClientChecksLoading || isFacesLoading

  return (
    <div className="row content flex">
      <div className={"mediaplan-page reconciliation-list with-bottom-text " + (isAnyLoading() ? ' loading' : '')}>
        <div>
          <div>
            <MenuClient title={i18n.t('header.reconciliation_with_planner')}/>
            {channels &&
              <Filter
                filters={Object.values(filters)}
                getData={setParams}
              />
            }

            {facesMapWithData && properties && clientChecks &&
              <div className={'mediaplan-tables edit-client'}>
                <table className="table redesign-table table-client goal-detail mediaplans">
                  <Header
                    properties={properties}
                    cellsBeforeProps={
                      <>
                        <td>{i18n.t('face.list.face')}</td>
                        <td>{i18n.t('face.list.advert')}</td>
                      </>
                    }
                    cellsAfterProps={<td colSpan={2} />}
                  />
                  <tbody>
                  <SumRow
                      properties={properties}
                      values={sumValues}
                      title={''}
                      titleColSpan={1}
                      cellsAfterTitle={<td />}
                      cellsAfterProps={<td colSpan={2} />}
                    />
                  {facesMapWithData && [...facesMapWithData].map(([key, face]) => (
                    <Row
                      key={key}
                      properties={properties}
                      values={face.properties || {}}
                      titleColSpan={1}
                      onChange={(value, property) => onRowChange(value, key, property.code)}
                      title={
                        <div>
                          <span>{face.name}</span>
                        </div>
                      }
                      isSelectToPaste={false}
                      rowCellComponent={EmptyRowCell}
                      cellAfterTitle={
                        <td>
                          {face.advertising ?
                            <Link to={getFactDataPageUrl(face)}>
                              {face.advertising.name}
                            </Link> : ''}
                        </td>
                      }
                      cellAfterProperties={
                        <>
                          <td>
                            <div className='flex-left'>
                              {isFaceEditable(face) &&
                                <div className={'correction-button'}
                                     onClick={() => onSaveFaceData(face, key)}
                                     title={i18n.t('common.save')}>
                                  <i className={'far fa-save'}/>
                                </div>
                              }
                              {face.checkRow && !face.clientCheckRowApproved &&
                                <div className={'correction-button'}
                                     onClick={() => onDeleteClientCheckRow(face)}
                                     title={i18n.t('common.delete')}>
                                  <i className={'far fa-trash'}/>
                                </div>
                              }
                            </div>
                          </td>
                        </>
                      }
                    />
                  ))}

                  </tbody>
                </table>
                {isAnyLoading() &&
                  <LoaderBackdrop/>
                }
                <Footer>
                  <div/>
                  <div>
                    {facesMapWithData && changedFaces && changedFaces.length > 0 &&
                      <ButtonWithLoader
                        onClick={() => onSaveAllData()}
                        className={"button " + (isAnyLoading() ? 'load' : '')}
                        text={i18n.t('clients.advertising.save_all')}
                        loadingText={i18n.t('clients.advertising.saving_values')}
                        isLoading={isFactSaving}
                      />
                    }
                  </div>
                </Footer>
              </div>
            }

            {isAnyLoading() &&
              <Loader/>
            }

            {isAnyLoading() &&
              <LoaderBackdrop/>
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default Index;
