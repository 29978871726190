import React, {useState, useEffect, useCallback, useRef} from 'react'
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Footer from "../../Advertising/Detail/components/Footer";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import i18n from "../../../i18n";
import apiUrls from "../../../ApiUrls";
import {getUtcDate} from "../FacesTotal/Utils";
import Filter from "../../../Components/NewFilter/Filter";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import {InternetChannel} from "../../../entities/channel/InternetChannel";
import {
  getFormattedDate,
  subscribeToAdvertisingsUpdates,
} from "../../Advertising/Detail/Fact/Utils";
import {getAdvertisingDates} from "../../../utils";
import {api} from "../../../api/apiProvider";
import PullHistory from "../../Advertising/Detail/Fact/PullHistory";
import Loader from "../../../Components/Loader/Loader";
import AdvertFacesTable from "./table";
import useAdvert from "./useAdvert";
import useFaces from "./useFaces";
import InternetForm from "../../Advertising/Detail/Faces/InternetForm";
import TopMenu from "../../../Components/TopMenu";
import FloatInput from "../../../Components/Inputs/FloatInput";
import {Link} from "react-router-dom";

const filterDateFormat = 'YYYY-MM-DD'
const bannerDefaultPrice = process.env.REACT_APP_INTERNET_PRICE_BANNER

export default function ClosingPerformance() {
  const [dataFilterParams, setDataFilterParams] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [advertisingsFacesMap, setAdvertisingsFacesMap] = useState(null)
  const [selectedAdvertisingsIds, setSelectedAdvertisingsIds] = useState([])
  const [dateStartFilter, setDateStartFilter] = useState(null)
  const [dateEndFilter, setDateEndFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [faceChannelFilter, setFaceChannelFilter] = useState(null)
  const [advertisingsStatuses, setAdvertisingsStatuses] = useState({})
  const [pullHistories, setPullHistories] = useState(null)
  const [pullHistoriesAdvertising, setPullHistoriesAdvertising] = useState(null)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isFaceSaving, setIsFaceSaving] = useState(false)
  const [selectedFace, setSelectedFace] = useState(null)
  const [bannerPrice, setBannerPrice] = useState(bannerDefaultPrice)
  const bannerPriceRef = useRef(bannerDefaultPrice)

  const [{data: internetGoals}, getInternetGoals] = useApiFetch(apiUrls.get.goals())
  const [{
    data: advertPullHistories,
    isLoading: isHistoryLoading
  }, getAdvertPullHistories] = useApiFetch(apiUrls.get.pullHistories())
  const {advertisings, isAdvertisingsLoading, saveAdvertData} = useAdvert(clientFilter, dateStartFilter, dateEndFilter, selectedChannel)

  const {facesMap, isFacesLoading, getFact, isFactDataLoading, saveFace, changeFace, getFaces} = useFaces(dataFilterParams,
    selectedChannel, clientFilter, dateStartFilter, dateEndFilter, faceChannelFilter)

  const handlePullResult = useCallback((result) => {
    if (result.advertising && result.status && result.dateStart && result.dateEnd
      && advertisingsFacesMap.has(result.advertising) && result.dateStart === dateStartFilter
      && result.dateEnd === dateEndFilter) {
      setAdvertisingStatus(result.advertising, result)
    }
  }, [advertisingsFacesMap, dateStartFilter, dateEndFilter])

  const filters = {
    channel: {
      type: 'select',
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      setOptions: (options, change) => {
        if (options) {
          const internetChannel = options.find(option => option.code === InternetChannel.getCode())

          if (internetChannel) {
            setSelectedChannel(internetChannel)
            change(internetChannel)
          }
        }
      },
      name: 'channel',
      setFilterParams: (params/*, filterValue*/) => {
        if (selectedChannel && selectedChannel.value) {
          params.append('targets.channel.id', selectedChannel.value)
          //setSelectedChannel(filterValue)
        }

        return ''
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
      isDisabled: () => true
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], filterDateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(filterDateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(filterDateFormat)
        const endDate = dateValue.endOf('month').format(filterDateFormat)
        params.append('dateEnd[after]', startDate)
        params.append('dateStart[before]', endDate)
        setDateStartFilter(startDate)
        setDateEndFilter(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: filterDateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, filterValue) => {
        params.set('company.id', filterValue)
        setClientFilter(filterValue)
      },
      getValueToUrl: (params, company) => {
        params.set('company', company.value)
      },
      required: true,
    },
    faceChannel: {
      type: 'select',
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.internetChannels,
      name: 'face_channel',
      setFilterParams: (params, filterValue) => {
        if (filterValue && filterValue.value) {
          params.append('internetFaces.channel.id', filterValue.value)
          setFaceChannelFilter(filterValue)
        }

        return ''
      },
      getValueToUrl: (params, channel) => {
        if (channel && channel.value) {
          params.set('face_channel', channel.value)
        } else {
          params.delete('face_channel')
        }

        return params
      },
      className: 'pointer',
      isEmptyOption: true,
    },
  }

  useEffect(() => {
    if (dataFilterParams && selectedChannel) {
      setAdvertisingsStatuses({})
    }
  }, [selectedChannel, dataFilterParams])

  useEffect(() => {
    bannerPriceRef.current = bannerPrice
  }, [bannerPrice])

  useEffect(() => {
    if (advertisingsFacesMap && dataFilterParams) {
      const subscription = subscribeToAdvertisingsUpdates(handlePullResult, handlePullResult, handlePullResult, false)

      return () => {
        subscription.close()
      }
    }
  }, [dataFilterParams])

  useEffect(() => {
    if (advertisings && facesMap) {
      const advertsMap = new Map()

      for (let advertising of advertisings) {
        const performanceTarget = advertising.targets.find(target => target.channel && target.channel.code === InternetChannel.getCode())

        if (!performanceTarget) {
          continue
        }

        const period = performanceTarget.periods.find(targetPeriod => {
          const targetPeriodMonth = getUtcDate(targetPeriod.dateStart).month()
          const currentMonth = getUtcDate(dateStartFilter).month()

          return targetPeriodMonth === currentMonth
        })

        if (!period) {
          continue
        }

        advertsMap.set(advertising.id,
          {
            id: advertising.id,
            ['@id']: advertising['@id'],
            advertName: advertising.name,
            company: advertising.company,
            advertComment: advertising.comment,
            advertDashboard: advertising.dashboard,
            advertTask: advertising.task,
            advertTargetName: performanceTarget.name,
            advertTargetId: performanceTarget.id,
            advertPeriodId: period.id,
            budgetCode: period.budgetCode,
            nameFinancial: period.nameFinancial,
            dateStart: {
              origin: advertising.dateStart,
              value: getFormattedDate(advertising.dateStart)
            },
            dateEnd: {
              origin: advertising.dateEnd,
              value: getFormattedDate(advertising.dateEnd)
            },
            factPageLink: getFactDataPageUrl(advertising)
          })
      }

      facesMap.forEach((face, faceIri) => {
        const faceAdvertisingId = face.advertisingId

        if (faceAdvertisingId && advertsMap.has(faceAdvertisingId)) {
          const advertising = advertsMap.get(faceAdvertisingId)

          let faces = advertising.faces

          if (!faces) {
            faces = []
          }

          faces.push(face)

          advertsMap.set(advertising.id,
            {
              ...advertising,
              faces
            })
        }
      })

      advertsMap.forEach(advertising => {
        const advertFaces = advertising.faces
        const advertFacesMap = {}

        if (advertFaces && advertFaces.length) {
          for (let face of advertFaces) {
            const channelName = face.channel.name

            if (!(channelName in advertFacesMap)) {
              advertFacesMap[channelName] = []
            }

            advertFacesMap[channelName].push(face)
          }
        }

        advertsMap.set(advertising.id,
          {
            ...advertising,
            faces: advertFacesMap,
          })
      })

      setAdvertisingsFacesMap(advertsMap)
    }
  }, [advertisings, facesMap])

  useEffect(() => {
    if (advertPullHistories) {
      const pullMap = new Map()

      for (let historyItem of advertPullHistories) {
        const key = `${historyItem.platform.id}${historyItem.channel.id}${historyItem.face}${historyItem.error}`

        if (!pullMap.has(key, historyItem)) {
          pullMap.set(key, historyItem)
        }
      }

      setPullHistories(Array.from(pullMap, ([name, value]) => ({...value})))
    }
  }, [advertPullHistories])

  useEffect(() => {
    if (advertisingsFacesMap) {
      selectAll(isAllChecked)
    }
  }, [isAllChecked])

  useEffect(() => {
    if (advertisingsStatuses && Object.values(advertisingsStatuses).length) {
      const isLoading = Object.values(advertisingsStatuses).find(item => item.status === 'loading')

      if (!isLoading) {
        getFact()
      }
    }
  }, [advertisingsStatuses])

  useEffect(() => {
    getInternetGoals()
  }, [])

  const getFactResultsHistory = advertisingId => {
    const advertising = advertisingsFacesMap.get(advertisingId)

    if (!advertising) {
      return
    }

    getAdvertPullHistories({
      'advertising.id': advertising.id,
      'order[id]': 'desc',
      'exists[error]': true,
      'requestedDateStart[before]': dateEndFilter,
      'requestedDateEnd[after]': dateStartFilter,
      't[]': ['face_platform', 'face_channel'],
    })
    setPullHistoriesAdvertising(advertising)
  }

  const setFilterParams = (filterParams) => {
    setDataFilterParams(filterParams)
  }

  const selectAll = value => {
    if (value) {
      const advertisingsIds = []

      advertisingsFacesMap.forEach((advertising, key) => {
        if (advertising.parts && advertising.parts.length) {
          advertisingsIds.push(key)
        }
      })

      setSelectedAdvertisingsIds(advertisingsIds)
    } else {
      setSelectedAdvertisingsIds([])
    }
  }

  const getFactDataPageUrl = advertising => {
    const channelCode = selectedChannel.code

    const [intervalStart, intervalEnd]
      = getAdvertisingDates(advertising.dateStart, advertising.dateEnd, dateStartFilter, dateEndFilter)

    if (channelCode) {
      return `/advertisings/${
        advertising.id}/fact/${channelCode}?dateStart=${
        getFormattedDate(intervalStart, filterDateFormat)}&dateEnd=${
        getFormattedDate(intervalEnd, filterDateFormat)}`
    }
  }

  const setAdvertisingStatus = (id, value) => {
    setAdvertisingsStatuses(advertisingsStatuses => ({
      ...advertisingsStatuses,
      [id]: value,
    }))
  }

  const onDownloadFactClick = async () => {
    setAdvertisingsStatuses({})

    setIsAllChecked(false)

    for (let advertisingId of selectedAdvertisingsIds) {
      setAdvertisingStatus(advertisingId, {
        status: 'loading'
      })
    }

    setSelectedAdvertisingsIds([])

    for (let advertisingId of selectedAdvertisingsIds) {
      const params = {
        id: advertisingId,
        dateStart: dateStartFilter,
        dateEnd: dateEndFilter,
      }

      await api.post(apiUrls.post.getAdvertisingFactResults(params), {}).catch(error => {
        if (error['hydra:description']) {
          setAdvertisingStatus(advertisingId, {
            message: error['hydra:description'],
            isError: true,
            status: 'danger',
          })
        }
      })
    }
  }

  const onAdvertChange = (value, propertyCode, advertisingId) => {
    if (advertisingsFacesMap.has(advertisingId)) {
      const adverts = new Map(advertisingsFacesMap)
      const advert = adverts.get(advertisingId)

      adverts.set(advertisingId, {
        ...advert,
        [propertyCode]: value
      })

      setAdvertisingsFacesMap(adverts)
    }
  }

  const addNewFace = (advertising) => {
    setSelectedFace({
      advertisingId: advertising.id
    })
  }

  const onFaceChange = (value, propertyCode, faceId) => {
    changeFace(value, propertyCode, faceId, bannerPriceRef.current)
  }

  const onSaveAdvertData = async advert => {
    saveAdvertData(advert)
  }

  const isAnyLoading = () => isAdvertisingsLoading || isFactDataLoading || isFacesLoading || isFaceSaving

  return (
    <div className="row closing-performance">
      <div>
        <TopMenu title={i18n.t('header.financial_close_performance')} />

        <div className={'header'}>
          <div className={'header-left'}>
            <Filter
              filters={Object.values(filters)}
              getData={setFilterParams}
            />

            <Link to={`/financial/month-fact/upload/`} title={i18n.t('financial.save_month_fact')}
              className={'upload-link'}>
              <i className="fal fa-upload" />
            </Link>
          </div>

          <div className={'banner-price detail-cell'}>
            <FloatInput
              value={bannerPrice}
              onChange={value => setBannerPrice(value)}
              isValid={() => true}
            /> {i18n.t('close_performance.one_banner_price')}
          </div>
        </div>

        {advertisingsFacesMap &&
          <div className={'edit-client'}>
            <div className={'mediaplan-tables table-client table'}>
              <div className={(isAnyLoading() ? ' loading' : '')}>
                <AdvertFacesTable
                  adverts={advertisingsFacesMap}
                  setAdverts={setAdvertisingsFacesMap}
                  internetGoals={internetGoals}
                  onAdvertChange={onAdvertChange}
                  onFaceChange={onFaceChange}
                  onSaveAdvertData={onSaveAdvertData}
                  saveFace={saveFace}
                  setSelectedFace={setSelectedFace}
                  addFace={addNewFace}
                />

                {isAnyLoading() &&
                  <LoaderBackdrop/>
                }

                {isAnyLoading() &&
                  <Loader/>
                }

                <Footer>
                  <div>
                    {selectedAdvertisingsIds && selectedAdvertisingsIds.length > 0 &&
                      <ButtonWithLoader
                        onClick={onDownloadFactClick}
                        className={"button " + (isAdvertisingsLoading ? 'load' : '')}
                        text={i18n.t('mediaplans.page.get_fact_results')}
                      />
                    }
                  </div>
                  <div>
                    {/*{changedFaces && changedFaces.length > 0 &&
                        <ButtonWithLoader
                          onClick={() => onSaveAllFacesData()}
                          className={"button " + (isFaceSaving ? 'load' : '')}
                          text={i18n.t('clients.advertising.save_all')}
                          loadingText={i18n.t('clients.advertising.saving_values')}
                          isLoading={isFaceSaving}
                        />
                      }*/}
                  </div>
                </Footer>
              </div>
            </div>
          </div>
        }

        {pullHistories && pullHistoriesAdvertising &&
          <PullHistory
            items={pullHistories}
            setItems={setPullHistories}
            advertising={pullHistoriesAdvertising}
            getItems={getFactResultsHistory}
            isLoading={isHistoryLoading}
          />
        }
      </div>

      {selectedChannel && selectedFace && selectedChannel.code ===  InternetChannel.getCode() &&
        <InternetForm
          faceId={selectedFace.id}
          advertisingId={selectedFace.advertisingId}
          getAdvertFaces={getFaces}
          setSelectedFace={setSelectedFace}
          getAdvertPeriod={() => {}}
        />
      }
    </div>
  )
}
