import React, {useState, useEffect} from 'react';
import SubMenu from '../../Components/SubMenu';
import MenuClient from '../../Components/MenuClient';
import {toast} from 'react-toastify';
import i18n from "i18next";
import apiUrls from "../../ApiUrls";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import Form from "./Form";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import useApiPost from "../../Components/Hooks/useApiPost";

function Edit(props) {
  const [selectedTax, setSelectedTax] = useState({})
  const [selectedRole, setSelectedRole] = useState({})
  const [legalId] = useState(props.match.params.legalID)
  const companyId = props.match.params.companyID
  const [formLegal, setFormLegal] = useState({
    company: `companies/${companyId}`
  })

  const [inputErrors, setInputErrors] = useState([])

  const [{data: legal}, dispatchLegal] = useApiFetch(
    apiUrls.get.legalWithTaxes(legalId)
  )

  const [{data: roles}, getRoles] = useApiFetch(
    apiUrls.get.roles()
  )

  const [{data: company}, dispatchCompany] = useApiFetch(
    apiUrls.get.company(companyId)
  )

  const [{data: taxes}, dispatchTaxes] = useApiFetch(
    apiUrls.get.taxes()
  )

  const [{errors: updateLegalErrors}, updateLegal] = useApiPatch(
    apiUrls.patch.legals,
    '',
    'clients.edit',
    () => {
      toast.success(i18n.t('clients.edit.legal_entity_changed'))
    }
  )

  const [{errors: addLegalErrors}, addLegal] = useApiPost(
    apiUrls.post.legals(),
    '',
    'clients.edit',
    () => {
      toast.success(i18n.t('clients.edit.legal_entity_created'))
      props.history.push("/client/" + companyId + "/legals")
    }
  )

  const isNew = () => legalId === undefined

  useEffect(() => {
    if (!isNew()) {
      dispatchLegal()
    }

    getRoles()
    dispatchCompany()
    dispatchTaxes()
  }, [])

  useEffect(() => {
    if (addLegalErrors) {
      setInputErrors(addLegalErrors)
    } else if (updateLegalErrors) {
      setInputErrors(updateLegalErrors)
    } else {
      setInputErrors([])
    }
  }, [addLegalErrors, updateLegalErrors])

  useEffect(() => {
    setSelected()
  }, [legal, company, taxes, roles])

  const setSelected = () => {
    if (legal && company && roles && roles.length) {
      if (legal.role) {
        let legalRole = roles.filter(role => role['@id'] === legal.role['@id']).pop()

        if (legalRole && legalRole['@id']) {
          setSelectedRole({
            label: legalRole.name,
            value: legalRole['@id'],
          })

          legal.role = legalRole['@id']
        }
      }

      if (legal && legal.tax && legal.tax['@id']) {
        setSelectedTax({
          label: legal.tax.name,
          value: legal.tax['@id'],
        })

        if (legal) {
          setFormLegal({...legal, tax: legal.tax['@id']})
        }
      }
    }
  }

  const onClickSave = () => {
    isNew()
      ? addLegal(formLegal)
      : updateLegal(formLegal.id, formLegal)
  }

  const setTax = (tax) => {
    setSelectedTax(tax)

    setFormLegal({...formLegal, tax: tax.value})
  }

  const isCompanyHasRoleError = () => {
    if (!selectedRole || !selectedRole.value || !company || !company.roles || !company.roles.length) {
      return false
    }

    const isCompanyRole = company.roles.some(companyRole => companyRole['@id'] === selectedRole.value)

    if (!isCompanyRole) {
      return true
    }

    return false
  }

  const setRole = async (role) => {
    /*const isCompanyRole = company.roles.some(companyRole => companyRole['@id'] === role.value)

    if (!isCompanyRole) {
      toast.warn(i18n.t('clients.edit.not_company_role'))
    }*/

    setSelectedRole(role)

    setFormLegal({...formLegal, role: role.value})
  }

  let topTitle = isNew()
    ? i18n.t('clients.edit.create_legal_entity')
    : i18n.t('clients.edit.edit_legal_entity')

  const taxOptions = taxes && taxes.length
    ? taxes.map((tax) => {
      return {value: tax["@id"], label: tax.name}
    })
    : []

  const roleOptions = roles && roles.length
    ? roles.map((role) => {
      return {value: role["@id"], label: role.name}
    })
    : []

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu />
        <div className="table-edit-client">
          <div>
            <MenuClient title={topTitle}>
              {!isCompanyHasRoleError() &&
              <button className="button-accent" onClick={onClickSave}>
                {i18n.t('clients.edit.save')}
              </button>
              }
            </MenuClient>
            <Form
              formLegal={formLegal}
              setFormLegal={setFormLegal}
              selectedTax={selectedTax}
              taxOptions={taxOptions}
              setTax={setTax}
              selectedRole={selectedRole}
              roleOptions={roleOptions}
              setRole={setRole}
              isCompanyHasRoleError={isCompanyHasRoleError}
              inputErrors={inputErrors}
              companyId={companyId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;