import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import FormSelect from "../../../../Components/Inputs/FormSelect";
import {getOptions} from "../../../../utils";
import {deepClone} from "transliteration/dist/node/src/common/utils";
import {FaceFactory} from "../../../../entities/face/FaceFactory";

const getDataFromFace = (formFace) => {
  let form = {}
  form.id = formFace.id
  form.name = formFace.name
  form.targeting = formFace.targeting
  form.advertising = formFace.advertising ? formFace.advertising['@id'] : null
  form.platform = formFace.platform ? formFace.platform['@id'] : null
  form.contractor = (formFace.contractor && formFace.contractor['@id'] && formFace.contractor['@id'] !== 'null') ? formFace.contractor['@id'] : null
  form.channel = formFace.channel ? formFace.channel['@id'] : null

  return form
}

const emptyOption = {
  label: i18n.t('projects.list.not_selected'),
  value: 'null'
}

function InternetForm(props) {
  const [formFace, setFormFace] = useState({})

  const [selectedPlatform, setSelectedPlatform] = useState({})
  const [selectedChannel, setSelectedChannel] = useState({})
  const [selectedContractor, setSelectedContractor] = useState({})
  const [contractors, setContractors] = useState([])

  const [{data: platforms}, getPlatforms] = useApiFetch(apiUrls.get.internetPlatforms())
  const [{data: internetChannels}, getInternetChannels] = useApiFetch(apiUrls.get.internetChannels())
  const [{data: platformContractors}, getPlatformContractors] = useApiFetch(apiUrls.get.internetPlatformContractors())
  const [{data: face}, getFace] = useApiFetch(apiUrls.get.internetFace(props.faceId))

  const [{errors: updateErrors}, updateFace] = useApiPatch(
    apiUrls.patch.internetFace,
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      props.getAdvertPeriod({
        't[]': 'target'
      })
      toast.success(i18n.t('periods.face.saved'))
    }
  )

  const [{errors: addErrors}, addFace] = useApiPost(
    apiUrls.post.internetFace(),
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      toast.success(i18n.t('periods.face.added'))
    }
  )

  useEffect(() => {
    if (props.faceId) {
      const faceEntity = FaceFactory.create('internet')
      getFace({
        't[]': faceEntity.listGroupParams
      })
    } else {
      setNewFace()
    }

    getPlatforms()
    getInternetChannels()
  }, [])

  useEffect(() => {
    if (face) {
      const formFace = deepClone(face)

      if (props.isNew) {
        delete(formFace.id)
        delete(formFace.code)

        formFace.advertising = {
          '@id': `/adverts/${props.advertisingId}`,
          id: props.advertisingId,
        }
      }

      setFormFace(formFace)
    }
  }, [face])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.value) {
      getPlatformContractors({
        't[]': 'platform-legal',
        'platform.id': selectedPlatform.value.split('/').pop(),
        'client.id': props.companyId,
        'pagination': false
      })
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (platformContractors) {
      if (platformContractors.length) {
        let clientContractors = []

        for (let client of platformContractors) {
          if (client.contractor && client.contractor.id) {
            clientContractors.push(client.contractor)
          }
        }

        const selectedContractorInClients = selectedContractor.value ? clientContractors.find(contractor => {
          return contractor['@id'] === selectedContractor.value
        }) : ''

        if (!selectedContractorInClients) {
          onChange({}, 'contractor')
        }

        setContractors(clientContractors)
      } else {
        onChange({}, 'contractor')
        setContractors([])
      }
    }
  }, [platformContractors])

  useEffect(() => {
    if (formFace.platform && formFace.platform['@id']) {
      setSelectedPlatform({
        value: formFace.platform['@id'],
        label: formFace.platform.name
      })
    } else {
      setSelectedPlatform({})
    }
  }, [formFace.platform])

  useEffect(() => {
    if (formFace.channel && formFace.channel['@id']) {
      setSelectedChannel({
        value: formFace.channel['@id'],
        label: formFace.channel.name
      })
    } else {
      setSelectedChannel({})
    }
  }, [formFace.channel])

  useEffect(() => {
    if (formFace.contractor && formFace.contractor['@id']) {
      setSelectedContractor({
        value: formFace.contractor['@id'],
        label: formFace.contractor.name
      })
    } else {
      setSelectedContractor({})
    }
  }, [formFace.contractor])

  const setNewFace = () => {
    setFormFace({
      code: '',
      name: '',
      advertising: {
        '@id': `/adverts/${props.advertisingId}`,
        id: props.advertisingId,
      },
      platform: {},
      channel: {},
      contractor: {},
    })
  }

  const onChange = (value, name) => {
    if (value && value.value) {
      setFormFace({
        ...formFace,
        [name]: {
          '@id': value.value,
          name: value.label
        }
      })
    } else {
      setFormFace({
        ...formFace,
        [name]: value
      })
    }
  }

  const save = () => {
    const data = getDataFromFace(formFace)

    if (formFace.id) {
      updateFace(formFace.id, data)
    } else {
      addFace(data)
    }
  }

  const platformOptions = getOptions(platforms)

  const contractorOptions = getOptions(contractors)
  contractorOptions.unshift(emptyOption)

  const channelsOptions = getOptions(internetChannels)

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
           onClick={() => props.setSelectedFace(null)}/>
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => props.setSelectedFace(null)}>
            <i className='fas fa-remove'/>
          </button>
        </div>
        <div className='popup-form__body'>
          <div className='popup-form__rows'>
            <FormSelect
              name='platform'
              value={selectedPlatform}
              options={platformOptions}
              label={i18n.t('clients.advertising.platform')}
              onChange={(value) => onChange(value, 'platform')}
            />

            <FormSelect
              name='channel'
              value={selectedChannel}
              options={channelsOptions}
              label={i18n.t('clients.advertising.channel')}
              onChange={(value) => onChange(value, 'channel')}
            />

            <FormSelect
              name='contractor'
              value={selectedContractor}
              options={contractorOptions}
              label={i18n.t('clients.advertising.contractor')}
              onChange={(value) => onChange(value, 'contractor')}
            />

          </div>
          <div className={'popup-form__buttons'}>
            <button className="popup-form__button button-accent" onClick={save}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternetForm