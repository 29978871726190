import React, {Fragment} from 'react';
import {cloneDeep} from 'lodash'
import i18n from "i18next";
import DatePicker from "react-datepicker";
import {getFormattedDate, getUtcDate} from "../Fact/Utils";
import IntInput from "../../../../Components/Inputs/IntInput";

function DurationSplitRow({
                            durationSplit,
                            onChange,
                            index,
                            deleteDurationSplit,
                            deleteDuration,
                            addDuration,
}) {
  const onDateChange = (value) => {
    onChange({
      ...durationSplit,
      dateStart: getFormattedDate(value, 'YYYY-MM-DD')
    })
  }

  const onValueChange = (name, value, durationIndex) => {
    const newDurationSplit = cloneDeep(durationSplit)

    if (newDurationSplit.durations && newDurationSplit.durations[durationIndex]) {
      newDurationSplit.durations[durationIndex][name] = value
    }

    onChange(newDurationSplit)
  }

  const getSum = () => {
    let sum = 0

    for (let duration of durationSplit.durations) {
      sum += duration.value
    }

    return sum
  }

  return (
    <div key={durationSplit.id} className={'quality-item'}>
      <div className={'quality-item__row spaced'}>
        <div>
          <span className='title'>{i18n.t('periods.face.date_start')}</span>
          <div className='flex'>
            <DatePicker
              selected={getUtcDate(durationSplit.dateStart).toDate()}
              onChange={onDateChange}
              dateFormat={i18n.t('calendar_date_format')}
            />
            {getSum() !== 100 &&
              <span className='duration-error'>{i18n.t('clients.advertising.duration_value_error')}</span>
            }
          </div>
        </div>

        <button onClick={() => deleteDurationSplit(index)}>
          <i className="fal fa-trash-alt icon-delete" />
        </button>
      </div>

      {durationSplit.durations && durationSplit.durations.length > 0 &&
        <>
          {durationSplit.durations.map((duration, durationIndex) => (
            <Fragment key={durationIndex}>
              <div className={'quality-wrapper durations'} key={index}>
                <div className='quality-item__row'>
                  <div className='quality-item__row-fields'>
                    <div className='quality-item__row-field'>
                      <span className='title'>{i18n.t('clients.advertising.duration_duration')}</span>
                      <IntInput
                        onChange={value => onValueChange('duration', value, durationIndex)}
                        value={duration.duration}
                      />
                    </div>
                    <div className='quality-item__row-field'>
                      <span className='title'>{i18n.t('clients.advertising.duration_value')}</span>
                      <IntInput
                        value={duration.value}
                        onChange={value => onValueChange('value', value, durationIndex)}
                      />
                    </div>
                  </div>

                  <div className='quality-item__row-field comment'>
                    <span className='title'>{i18n.t('clients.advertising.duration_comment')}</span>
                    <textarea
                      rows={2}
                      className={'paste-textarea'}
                      value={duration.comment}
                      onChange={event => onValueChange('comment', event.target.value, durationIndex)}></textarea>
                  </div>
                </div>
                <button onClick={() => deleteDuration(index, durationIndex)}>
                  <i className="fal fa-trash-alt icon-delete" />
                </button>
              </div>
            </Fragment>
          ))}
        </>
      }
      <a onClick={() => addDuration(index)} className="blue-cursor-button add-link">
        <i className="fal fa-plus mr-for-icon" />
        {i18n.t('clients.advertising.add')}
      </a>

    </div>
  )
}

export default DurationSplitRow;