import React, {Fragment} from 'react';
import {cloneDeep} from 'lodash'
import {getFormattedDate} from "../Fact/Utils";
import i18n from "i18next";
import DurationSplitRow from "./DurationSplitRow";

function DurationSplitsBlock({durationSplits, onChange, formFace, title}) {
  const onRowChange = (index, value) => {
    const newDurationSplits = cloneDeep(durationSplits)

    if (newDurationSplits[index]) {
      newDurationSplits[index] = value;
    }

    onChange(newDurationSplits)
  }

  const deleteDurationSplit = (index) => {
    const newDurationSplits = cloneDeep(durationSplits)

    newDurationSplits.splice(index, 1);

    onChange(newDurationSplits)
  }

  const deleteDuration = (index, durationIndex) => {
    const newDurationSplits = cloneDeep(durationSplits)

    if (newDurationSplits[index] && newDurationSplits[index].durations
      && newDurationSplits[index].durations[durationIndex] && newDurationSplits[index].durations.length > 1) {
      newDurationSplits[index].durations.splice(durationIndex, 1);
    }
    onChange(newDurationSplits)
  }

  const addDurationSplit = () => {
    const newDurationSplits = cloneDeep(durationSplits) || []

    newDurationSplits.push({
      dateStart: getFormattedDate(new Date(), 'YYYY-MM-DD'),
      durations: [
        {
          duration: 0,
          value: 0,
          comment: '',
        }
      ]
    })

    onChange(newDurationSplits)
  }

  const addDuration = (index) => {
    const newDurationSplits = cloneDeep(durationSplits) || []
    const durationSplit = newDurationSplits[index]

    durationSplit.durations.push({
      duration: 0,
      value: 0,
      comment: '',
    })

    onChange(newDurationSplits)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'popup-form__row-title'}>{title}</div>
      <div className={'affinities-wrapper'}>
        <div>
          <div>
            {durationSplits && durationSplits.length > 0 &&
            <>
              {durationSplits.map((durationSplit, index) => (
                <Fragment key={index}>
                  <div className={'quality-wrapper durations'}>
                    <DurationSplitRow
                      key={durationSplit.id}
                      durationSplit={durationSplit}
                      formFace={formFace}
                      onChange={value => onRowChange(index, value)}
                      index={index}
                      deleteDurationSplit={deleteDurationSplit}
                      deleteDuration={deleteDuration}
                      addDuration={addDuration}
                    />
                  </div>
                </Fragment>
              ))}
            </>
            }

            <a onClick={() => addDurationSplit()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DurationSplitsBlock;