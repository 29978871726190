import React, {useEffect, useState} from 'react'
import MenuClient from '../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../ApiUrls"
import useApiFetch from "../../../Components/Hooks/useApiFetch"
import Filter from "../../../Components/NewFilter/Filter";
import {getUtcDate} from "../FacesTotal/Utils";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../Components/Loader/Loader";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import Header from "../../Advertising/Detail/components/Table/Header";
import Row from "../../Advertising/Detail/components/Table/Row";
import Roles from "../../../roles/Roles";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import Footer from "../../Advertising/Detail/components/Footer";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {toast} from "react-toastify";
import SumRow from "./SumRow";
import {api} from "../../../api/apiProvider";
import RowCell from "./RowCell";
import Select from "react-select";
import i18next from "i18next";
import useApiPost from "../../../Components/Hooks/useApiPost";
import {useHistory} from "react-router-dom";

const dateFormat = 'Y-MM-DD'

const emptyOption = {
  label: i18next.t('projects.list.not_selected'),
  value: ''
}

const getSumValues = (facesMap) => {
  let sums = {}

  facesMap.forEach(face => {
    if (face.properties) {
      for (let code in face.properties) {
        if (!sums[code]) {
          sums[code] = 0
        }

        const value = face.properties[code].value || 0

        sums[code] += value
      }
    }
  })

  return sums
}

function Index() {
  const history = useHistory()
  const [filterParams, setFilterParams] = useState('')
  const [channelFilter, setChannelFilter] = useState({})
  const [dateStartFilter, setDateStartFilter] = useState(null)
  const [dateEndFilter, setDateEndFilter] = useState(null)
  const [contractorFilter, setContractorFilter] = useState(null)
  const [platformFilter, setPlatformFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState([])
  const [contractorOptions, setContractorOptions] = useState([])
  const [platformOptions, setPlatformOptions] = useState([])
  const [facesMap, setFacesMap] = useState(null)
  const [filteredFacesMap, setFilteredFacesMap] = useState(null)
  const [facesIdsFromChecks, setFacesIdsFromChecks] = useState(null)
  const [isAllChecked, setIsAllChecked] = useState(null)
  const [properties] = useState([
    {
      code: "clientBudget",
      name: i18n.t('financiers.clientBudget'),
      type: "float"
    },
    {
      code: "result",
      name: i18n.t('financiers.result'),
      type: "bool"
    },
    {
      code: "budget",
      name: i18n.t('financiers.budget'),
      type: "float"
    },
    {
      code: "margin",
      name: i18n.t('financiers.margin'),
      type: "float"
    },
    {
      code: "profit",
      name: i18n.t('financiers.profit'),
      type: "float"
    },
    {
      code: "commission",
      name: i18n.t('financiers.commission'),
      type: "float"
    },
    {
      code: "return",
      name: i18n.t('financiers.return'),
      type: "string"
    },
  ])
  const [changedFaces, setChangedFaces] = useState([])
  const [changedCheckRows, setChangedCheckRows] = useState([])
  const [isFaceSaving, setIsFaceSaving] = useState(null)
  const [faceCommissions, setFaceCommissions] = useState(null)
  const [isCommissionsLoading, setIsCommissionsLoading] = useState(false)
  const [clientCheck, setClientCheck] = useState(null)

  const getChannelCommissionsUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.apiUrls.faceCommissions
      }
    }

    return null
  }

  const getChannelFacesUrl = () => {
    if (channelFilter && channels) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return channelEntity.apiUrls.faces
      }
    }

    return null
  }

  const [{
    data: clientCheckRows,
    isLoading: isClientRowsChecksLoading
  }, getClientCheckRows] = useApiFetch(apiUrls.get.clientCheckRows())

  const [{
    data: contractorCheckRows,
    isLoading: isContractorCheckRowsLoading
  }, getContractorCheckRows] = useApiFetch(apiUrls.get.contractorCheckRows())

  const [{
    data: faces,
    isLoading: isFacesLoading
  }, getFaces] = useApiFetch(getChannelFacesUrl)

  const [{data: channels, isLoading: isChannels}, getChannels] = useApiFetch(apiUrls.get.channels())

  const [{errors: updateClientCheckRowErrors}, updateClientCheckRow] = useApiPatch(
    apiUrls.get.clientCheckRowApproved,
    '',
    'errors',
  )

  const [{errors: addClientCheckRowErrors}, addClientCheckRow] = useApiPost(
    apiUrls.get.clientCheckRows(),
    '',
    'errors',
  )

  const [{errors: updateCheckRowsError}, updateCheckRows] = useApiPatch(
    apiUrls.get.contractorCheckRow,
    i18n.t('common.saved'),
    'errors',
  )

  const filters = {
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      /*setOptions: options => {
        setChannels(options)
      },*/
      name: 'channel',
      setFilterParams: (params, filterValue) => {
        params.append('channel.id', filterValue.value)
        setChannelFilter(filterValue)
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], dateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(dateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(dateFormat)
        const endDate = dateValue.endOf('month').format(dateFormat)
        params.append('dateStart[after]', startDate)
        params.append('dateEnd[before]', endDate)
        setDateStartFilter(startDate)
        setDateEndFilter(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: dateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      /*setOptions: options => {
        setClients(options)
      },*/
      name: 'company',
      setFilterParams: (params, filterValue) => {
        params.set('company.id', filterValue.value)

        setClientFilter(filterValue)
      },
      getValueToUrl: (params, company) => {
        if (company && company.value) {
          params.set('company', company.value)
        } else {
          params.delete('company')
        }
      },
      required: true,
    },
  }

  const fetchData = () => {
    if (channels && channelFilter && channelFilter.value) {
      getClientCheckRows(getClientCheckRowsParams())
      getContractorCheckRows(getContractorCheckRowsParams())
      getFaces(getFacesParams())
    }
  }

  useEffect(() => {
    getChannels()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filterParams])

  useEffect(() => {
    if (channels) {
      fetchData()
    }
  }, [channels])

  useEffect(() => {
    if (facesMap && !isAnyLoading()) {
      const newFacesMap = new Map(JSON.parse(JSON.stringify([...facesMap])))
      const channelFacesMap = new Map()

      for (let face of faces) {
        channelFacesMap.set(face['@id'], face)
      }

      if ((contractorFilter && contractorFilter.value) || (platformFilter && platformFilter.value)) {
        newFacesMap.forEach((item, faceIri) => {
          const channelFace = channelFacesMap.get(faceIri)
          if (
            contractorFilter && contractorFilter.value
            && (!channelFace.contractor || (channelFace.contractor.id !== contractorFilter.value))
          ) {
            newFacesMap.delete(faceIri)
          }

          if (platformFilter && platformFilter.value && (channelFace.platform.id !== platformFilter.value)) {
            newFacesMap.delete(faceIri)
          }
        })
      }

      if (contractorOptions && contractorOptions.length) {
        if (contractorFilter && contractorFilter.value) {
          setValueToUrl('contractor', contractorFilter.value)
        } else {
          deleteValueFromUrl('contractor')
        }
      }

      if (platformOptions && platformOptions.length) {
        if (platformFilter && platformFilter.value) {
          setValueToUrl('platform', platformFilter.value)
        } else {
          deleteValueFromUrl('platform')
        }
      }

      setFilteredFacesMap(newFacesMap)
    }
  }, [facesMap, contractorFilter, platformFilter])

  useEffect(() => {
    if (clientCheckRows && contractorCheckRows) {
      const facesIris = new Set()

      if (clientCheckRows && clientCheckRows.length) {
        for (let checkRow of clientCheckRows) {
          if (checkRow.faces && checkRow.faces[0] && checkRow.faces[0]['@id']) {
            const faceIri = checkRow.faces[0]['@id']

            facesIris.add(faceIri)
          }
        }
      }

      if (contractorCheckRows && contractorCheckRows.length) {
        for (let contractorCheckRow of contractorCheckRows) {
          if (contractorCheckRow.data && contractorCheckRow.data[0] && contractorCheckRow.data[0].face) {
            facesIris.add(contractorCheckRow.data[0].face)
          }
        }
      }

      const facesIds = [...facesIris].map(faceIri => faceIri.split('/').pop())

      getFaceCommissions(facesIds)

      setFacesIdsFromChecks([...facesIris])
    }
  }, [clientCheckRows, contractorCheckRows])

  useEffect(() => {
    if (clientCheckRows && contractorCheckRows && faceCommissions && faces) {
      const channelFacesMap = new Map()
      const newFacesMap = new Map()

      for (let face of faces) {
        channelFacesMap.set(face['@id'], face)
      }

      if (clientCheckRows && clientCheckRows.length) {
        for (let checkRow of clientCheckRows) {
          const faceIri = checkRow.faces[0]['@id']
          const face = channelFacesMap.get(faceIri)
          const faceName = face ? face.name : ''

          if (!facesIdsFromChecks.includes(faceIri)) {
            continue
          }

          newFacesMap.set(faceIri, {
            name: faceName,
            iri: faceIri,
            checkRow,
            contractor: (face && face.contractor) ? face.contractor : null,
            platform: (face && face.platform) ? face.platform : null,
          })
        }
      }

      if (contractorCheckRows && contractorCheckRows.length) {
        for (let contractorCheckRow of contractorCheckRows) {
          const faceIri = (contractorCheckRow.data && contractorCheckRow.data[0] && contractorCheckRow.data[0].face)
            ? contractorCheckRow.data[0].face
            : null

          const face = channelFacesMap.get(faceIri)
          const faceName = face ? face.name : ''

          if (newFacesMap.has(faceIri)) {
            const face = newFacesMap.get(faceIri)

            newFacesMap.set(faceIri, {
              ...face,
              contractorCheckRow,
            })
          } else {
            newFacesMap.set(faceIri, {
              name: faceName,
              iri: faceIri,
              contractor: (face && face.contractor) ? face.contractor : null,
              platform: (face && face.platform) ? face.platform : null,
              contractorCheckRow,
            })
          }
        }
      }

      let isAllChecked = true

      newFacesMap.forEach((face, index) => {
        face.properties = {}

        for (let property of properties) {
          face.properties[property.code] = {
            editable: false,
            value: 0,
          }
        }

        face.approved = face.contractorCheckRow && face.contractorCheckRow.approved
        face.properties.budget.value = face.contractorCheckRow ? face.contractorCheckRow.budget : null
        face.properties.budget.bottom = face.approved
          ? i18n.t('face_check.budget_checked')
          : (
            face.contractorCheckRow ? i18n.t('face_check.budget_not_checked') : i18n.t('face_check.empty_contractor_budget')
          )

        face.properties.budget.bottomClass = face.approved
          ? 'cell-bottom checked'
          : 'cell-bottom non-checked'
        face.properties.clientBudget.value = face.checkRow ? face.checkRow.budget : null

        if (face.properties.clientBudget.value !== null && face.properties.budget.value !== null) {
          face.properties.profit.value = face.properties.clientBudget.value - face.properties.budget.value
        } else {
          face.properties.profit.value = null
        }

        face.properties.result.value = face.checkRow && face.checkRow.approved

        if (!face.properties.result.value) {
          isAllChecked = false
        }

        if (faceCommissions[index]) {
          const faceCommission = faceCommissions[index]
          face.properties.commission.value = faceCommission.value

          if (faceCommission.value && faceCommission.contractType
            && faceCommission.contractType['@id'] === '/financial/contract_types/1') {
            face.properties.margin.value = face.properties.commission.value + face.properties.profit.value
          } else {
            face.properties.margin.value = face.properties.profit.value
          }

          if (faceCommission.contractType && faceCommission.contractType.return) {
            face.properties.return.value = faceCommission.contractType.return
          }

        } else {
          face.properties.margin.value = face.properties.profit.value
        }
      })

      setIsAllChecked(isAllChecked)

      const newContractorOptions = []
      const newPlatformOptions = []
      const contractorIds = []
      const platformIds = []

      newFacesMap.forEach(face => {
        const channelFace = channelFacesMap.get(face.iri)

        if (channelFace) {
          if (Roles.hasAccess('FinancierReconAgentBudgetEdit')) {
            face.properties.clientBudget.editable = !face.checkRow || !face.checkRow.approved
            face.properties.result.editable = true

            face.properties.clientBudget.bottom = (face.checkRow && face.checkRow.approved) //todo
              ? i18n.t('face_check.client_budget_checked')
              : ''

            face.properties.clientBudget.bottomClass = (face.checkRow && face.checkRow.approved) //todo
              ? 'cell-bottom checked'
              : ''
          }
          if (channelFace.contractor != undefined && !contractorIds.includes(channelFace.contractor.id)) {
            contractorIds.push(channelFace.contractor.id)
            newContractorOptions.push({
              label: channelFace.contractor.name,
              value: channelFace.contractor.id,
            })
          }

          if (!platformIds.includes(channelFace.platform.id)) {
            platformIds.push(channelFace.platform.id)
            newPlatformOptions.push({
              label: channelFace.platform.name,
              value: channelFace.platform.id,
            })
          }
        }
      })

      newContractorOptions.unshift(emptyOption)
      setContractorOptions(newContractorOptions)

      newPlatformOptions.unshift(emptyOption)
      setPlatformOptions(newPlatformOptions)

      const valuesFromUrl = getValuesFromUrl()

      if (valuesFromUrl && valuesFromUrl.contractor && valuesFromUrl.contractor.length) {
        const contractorId = Number(valuesFromUrl.contractor[0])

        const contractor = newContractorOptions.find(contractor => contractor.value === contractorId)

        if (contractor && !contractorFilter) {
          setContractorFilter(contractor)
        }
      }

      if (valuesFromUrl && valuesFromUrl.platform && valuesFromUrl.platform.length) {
        const platformId = Number(valuesFromUrl.platform[0])

        const platfrom = newPlatformOptions.find(platform => platform.value === platformId)

        if (platfrom && !platformFilter) {
          setPlatformFilter(platfrom)
        }
      }

      setFacesMap(newFacesMap)
    }
  }, [clientCheckRows, contractorCheckRows, facesIdsFromChecks, faceCommissions])

  const setValueToUrl = (name, value) => {
    const params = new URLSearchParams(history.location.search)

    if (Array.isArray(value)) {
      params.delete(name)

      for (let item of value) {
        params.append(name, item.value)
      }
    } else {
      params.set(name, value)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const deleteValueFromUrl = name => {
    const params = new URLSearchParams(history.location.search)

    if (params.has(name)) {
      params.delete(name)

      const paramsString = params.toString()

      if (paramsString) {
        history.push(history.location.pathname + '?' + params.toString())
      } else {
        history.replace(history.location.pathname)
      }
    }
  }

  const getValuesFromUrl = () => {
    const values = []
    const params = new URLSearchParams(window.location.search.substring(1))

    params.forEach((value, key) => {
      if (!values[key]) {
        values[key] = []
      }
      values[key].push(value)
    });

    return values
  }

  const getContractorCheckRowsParams = () => {
    if (channelFilter && clientFilter && dateStartFilter && dateEndFilter) {
      const channelId = channelFilter.value
      const channel = channels.find(channel => channel.id === channelId)

      if (channel) {
        const channelEntity = ChannelFactory.create(channel.code)

        return {
          [channelEntity.faceAdvertisingCompanyIdFilter]: clientFilter.value,
          'financialCheck.channel.id': channelFilter.value,
          'financialCheck.dateStart[after]': dateStartFilter,
          'financialCheck.dateEnd[before]': dateEndFilter,
          'pagination': 'false',
        }
      }
    }

    return {}
  }

  const getClientCheckRowsParams = () => {
    if (channelFilter && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'clientCheck.client.id': clientFilter.value,
        'clientCheck.channel.id': channelFilter.value,
        'clientCheck.dateStart[after]': dateStartFilter,
        'clientCheck.dateEnd[before]': dateEndFilter,
        'pagination': false,
      }
    }

    return {}
  }

  const getFacesParams = () => {
    if (channelFilter && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'advertising.company.id': clientFilter.value,
        'advertising.dateStart[before]': dateEndFilter,
        'advertising.dateEnd[after]': dateStartFilter,
        't[]': ['face_financial', 'face_contractor', 'face_platform'],
        'pagination': false,
      }
    }

    return {}
  }

  const chunk = (array, size) =>
    Array.from({length: Math.ceil(array.length / size)}, (value, index) =>
      array.slice(index * size, index * size + size));

  const getFaceCommissions = (facesIds) => {
    if (facesIds && Roles.hasAccess('FinancialCommissionsRead')) {
      setIsCommissionsLoading(true)

      async function fetchData(faceIds) {
        const url = getChannelCommissionsUrl()
        const params = new URLSearchParams({
          dateStart: dateStartFilter,
          dateEnd: dateEndFilter,
        })

        const faceParams = faceIds.map(id => `face.id[]=${id}`).join('&')

        const faceCommissionsResult = await api.get(url + '?' + params.toString() + '&' + faceParams)
        const faceCommissions = faceCommissionsResult['hydra:member']
        const facesCommissions = {}

        for (let faceCommission of faceCommissions) {
          const faceIri = faceCommission.face

          if (faceCommission) {
            facesCommissions[faceIri] = faceCommission
          }
        }

        return {
          facesCommissions,
        }
      }

      const chunkedFaceIds = chunk(facesIds, 100);

      Promise.all(chunkedFaceIds.map(faceIds => fetchData(faceIds))).then(values => {
        let allFaceCommissions = {}
        for (let value of values) {
          if (value.facesCommissions && Object.keys(value.facesCommissions).length) {
            allFaceCommissions = {...allFaceCommissions, ...value.facesCommissions}
          }
        }
        setFaceCommissions(allFaceCommissions)
      }).finally(() => {
        setIsCommissionsLoading(false)
      }).catch(() => {
        setIsCommissionsLoading(false)
      })
    }
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const onRowChange = async (value, key, code) => {
    const newFacesMap = new Map(JSON.parse(JSON.stringify([...filteredFacesMap])))
    if (newFacesMap.has(key)) {
      const face = newFacesMap.get(key)

      if (code === 'comment') {
        face.contractorCheckRow.comment = value

        if (!changedCheckRows.includes(key)) {
          setChangedCheckRows(values => (
            [
              ...values,
              key
            ]
          ))
        }
      } else {
        face.properties[code].value = value

        if (code === 'budget' || code === 'clientBudget') {
          face.properties.profit.value = face.properties.clientBudget.value - face.properties.budget.value
          face.properties.margin.value = face.properties.commission.value + face.properties.profit.value
        }

        if (!changedFaces.includes(key)) {
          setChangedFaces(values => (
            [
              ...values,
              key
            ]
          ))
        }
      }

      newFacesMap.set(key, {
        ...face,
      })

      setFilteredFacesMap(newFacesMap)
    }
  }

  const onSaveAllData = async () => {
    setIsFaceSaving(true)

    Promise.all(
      changedFaces.map(faceIri => {
        if (filteredFacesMap.has(faceIri)) {
          const face = filteredFacesMap.get(faceIri)

          return saveClientCheckRow(face)
        }
      }).concat(changedCheckRows.map(faceIri => {
        if (filteredFacesMap.has(faceIri)) {
          const face = filteredFacesMap.get(faceIri)

          return saveCheckRow(face, faceIri)
        }
      }))
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        fetchData()
      })
      .finally(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
        setChangedCheckRows([])
      })
      .catch(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
        setChangedCheckRows([])
      })
  }

  const onSaveFaceData = async (face, faceIri) => {
    if (!face || !face.properties) {
      return
    }

    Promise.all(
      [
        saveClientCheckRow(face),
        saveCheckRow(face, faceIri),
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        fetchData()

        if (changedFaces.includes(faceIri)) {
          const faces = changedFaces.filter(iri => iri !== faceIri)
          setChangedFaces(faces)
        }
      })
  }

  const saveCheckRow = async (face, faceIri) => {
    return new Promise((resolve, reject) => {
      if (changedCheckRows.includes(faceIri) && face.contractorCheckRow) {
        updateCheckRows(face.contractorCheckRow.id, {
          comment: face.contractorCheckRow.comment
        }, {}, () => {
          const faces = changedCheckRows.filter(iri => iri !== faceIri)
          setChangedCheckRows(faces)

          return resolve(true)
        })
      } else {
        return resolve(true)
      }
    })
  }

  const saveClientCheckRow = async (face) => {
    return new Promise(async (resolve, reject) => {
      const params = {
        budget: face.properties.clientBudget.value || 0,
        approved: face.properties.result.value,
      }

      if (face.properties.result.value && face.contractorCheckRow && face.contractorCheckRow.id) {
        params.contractorCheckRow = `/financial/contractor_check_rows/${face.contractorCheckRow.id}`
      } else {
        params.contractorCheckRow = null
      }

      if (face.checkRow) {
        const clientCheckRowId = face.checkRow['@id'].split('/').pop()

        return updateClientCheckRow(clientCheckRowId, params, {}, () => {
          return resolve(true)
        }, () => {
          return resolve(true)
        })
      } else if (params.approved || params.budget) {
        let check = clientCheck

        if (!check) {
          const clientChecks = await api.get(apiUrls.get.clientChecks()
            + `?client.id=${clientFilter.value}&channel.id=${channelFilter.value}&dateStart=${dateStartFilter}&dateEnd=${dateEndFilter}`)

          if (clientChecks['hydra:member'] && clientChecks['hydra:member'][0]) {
            check = clientChecks['hydra:member'][0]
            setClientCheck(check)
          }
        }

        if (check) {
          addClientCheckRow({
            ...params,
            clientCheck: check['@id'],
            faces: [
              face.iri,
            ],
          }, null, {}, true, () => {
            return resolve(true)
          }, () => {
            return reject(false)
          })
        } else {
          return resolve(true)
        }
      } else {
        return false
      }
    })
  }

  const isAnyLoading = () => isClientRowsChecksLoading || isContractorCheckRowsLoading || isCommissionsLoading

  const checkAll = value => {
    setIsAllChecked(value)

    const newFacesMap = new Map(JSON.parse(JSON.stringify([...filteredFacesMap])))
    const changedFacesKeys = []

    newFacesMap.forEach((face, key) => {
      if (Roles.hasAccess('FinancierReconAgentBudgetEdit')) {
        face.properties.result.value = value
      }

      changedFacesKeys.push(key)
    })

    setFacesMap(newFacesMap)
    setChangedFaces(changedFacesKeys)
  }

  const onLegalChanged = (value) => {
    setContractorFilter(value)
  }

  const onPlatformChanged = (value) => {
    setPlatformFilter(value)
  }

  return (
    <div className="row content flex">
      <div className={"mediaplan-page reconciliation-list with-bottom-text" + (isAnyLoading() ? ' loading' : '')}>
        <div>
          <div>
            <MenuClient title={i18n.t('header.financier')}/>
            <Filter
              filters={Object.values(filters)}
              getData={setParams}
            />
            <div className="menu-filter">
              <span className={'filter-item'}>
                <Select className="react-select-container"
                        classNamePrefix="react-select"
                        options={contractorOptions}
                        placeholder={i18n.t('clients.page.legal')}
                        onChange={onLegalChanged}
                        value={contractorFilter}
                        isDisabled={false}
                />
                </span>
              <span className={'filter-item platforms-select'}>
                <Select className="react-select-container"
                        classNamePrefix="react-select"
                        options={platformOptions}
                        placeholder={'Площадка'}
                        onChange={onPlatformChanged}
                        value={platformFilter}
                        isDisabled={false}
                />
                </span>
            </div>
            {filteredFacesMap && clientCheckRows && contractorCheckRows &&
              <div className={'mediaplan-tables'}>
                <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
                  <table className="table redesign-table table-client goal-detail mediaplans">
                    <Header
                      properties={properties}
                      cellsBeforeProps={
                        <>
                          <td />
                          <td>{i18n.t('face.list.face')}</td>
                          <td>{i18n.t('face.list.contractor')}</td>
                        </>
                      }
                      cellsAfterProps={<td colSpan={2}/>}
                    />
                    <tbody>
                    <SumRow
                      properties={properties}
                      values={getSumValues(filteredFacesMap)}
                      title={''}
                      titleColSpan={3}
                      cellsAfterProps={<td colSpan={2}/>}
                      onCheckChange={checkAll}
                    />
                    {filteredFacesMap && [...filteredFacesMap].map(([key, face], index) => (
                      <Row
                        key={key}
                        index={index}
                        showIndex={true}
                        row={face}
                        properties={properties}
                        values={face.properties || {}}
                        titleColSpan={1}
                        onChange={(value, property) => onRowChange(value, key, property.code)}
                        title={
                          <div>
                            <span>{face.name}</span>
                            {face.check && face.properties && face.properties.budget && !face.properties.budget.editable &&
                              <div className={'face-checked-title'}>{i18n.t('face_check.budget_checked')}</div>
                            }
                            {face.check && face.properties && face.properties.budget && face.properties.budget.editable &&
                              <span className={'face-for-check-title'}>{i18n.t('face_check.budget_checking')}</span>
                            }
                          </div>
                        }
                        cellAfterTitle={
                          <td>
                            {face.contractor ? face.contractor.name : ''}
                          </td>
                        }
                        isSelectToPaste={false}
                        rowCellComponent={RowCell}
                        cellAfterProperties={
                          <>
                            <td>
                              <div className='flex-left'>
                                <div className={'correction-button'}
                                     onClick={() => onSaveFaceData(face, key)}
                                     title={i18n.t('common.save')}>
                                  <i className={'far fa-save'}/>
                                </div>
                              </div>
                            </td>
                          </>
                        }
                      />
                    ))}

                    </tbody>
                  </table>
                  {isAnyLoading() &&
                    <LoaderBackdrop/>
                  }
                  <Footer>
                    <div/>
                    <div>
                      {filteredFacesMap && ((changedFaces && changedFaces.length > 0) || (changedCheckRows && changedCheckRows.length > 0)) &&
                        <ButtonWithLoader
                          onClick={() => onSaveAllData()}
                          className={"button " + (isFaceSaving ? 'load' : '')}
                          text={i18n.t('clients.advertising.save_all')}
                          loadingText={i18n.t('clients.advertising.saving_values')}
                          isLoading={isFaceSaving}
                        />
                      }
                    </div>
                  </Footer>
                </div>
              </div>
            }

            {isClientRowsChecksLoading &&
              <Loader/>
            }

            {isClientRowsChecksLoading &&
              <LoaderBackdrop/>
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default Index;
