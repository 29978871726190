import React, {Fragment} from 'react'
import i18n from "i18next";
import {toast} from "react-toastify";
import useDelete from "../../../Components/Hooks/useDelete";
import apiUrls from "../../../ApiUrls";
import {Link} from "react-router-dom";
import {getFormattedDate} from "../FacesTotal/Utils";
import FormattedNumber from "../../../Components/FormattedNumber";

function Table({clientInvoices, getData = () => {}}) {
  const deleteClientInvoice = useDelete(apiUrls.get.clientInvoice)

  const onClientInvoiceDelete = (id) => {
    if (id) {
      const isConfirm = window.confirm(i18n.t('common.delete') + '?')

      if (isConfirm) {
        deleteClientInvoice(id, () => {
          toast.success(i18n.t('documents.edit.document_deleted'))
          getData()
        })
      }
    }
  }

  return (
    <table className="mediaplans redesign-table financial-report">
      <thead>
      <tr>
        <th>{i18n.t('invoice.list.client')}</th>
        <th>{i18n.t('clients.edit.name')}</th>
        <th>{i18n.t('invoice.list.legal')}</th>
        <th>{i18n.t('invoice.list.channel')}</th>
        <th>{i18n.t('invoice.list.document_date')}</th>
        <th>{i18n.t('invoice.list.date_period')}</th>
        <th>{i18n.t('invoice.list.sum_without_vat')}</th>
        <th>{i18n.t('invoice.list.sum_vat')}</th>
        <th>{i18n.t('invoice.list.sum_with_vat')}</th>
        <th />
        <th />
        <th />
      </tr>
      </thead>

      <tbody>
      {clientInvoices && clientInvoices.map((clientInvoice, index) => (
        <Fragment
          key={index}
        >
          <tr>
            <td>{clientInvoice.client ? clientInvoice.client.name : ''}</td>
            <td>{clientInvoice.name || ''}</td>
            <td>{clientInvoice.legalFrom ? clientInvoice.legalFrom.name : ''}</td>
            <td>{clientInvoice.channel ? clientInvoice.channel.name : ''}</td>
            <td>{getFormattedDate(clientInvoice.dateSigning)}</td>
            <td>{getFormattedDate(clientInvoice.dateStart, 'MM.YYYY')}</td>
            <td><FormattedNumber value={clientInvoice.budget} type={'float'}/></td>
            <td><FormattedNumber value={clientInvoice.tax} type={'float'}/></td>
            <td><FormattedNumber value={clientInvoice.budgetTax} type={'float'}/></td>
            <td>
              <div className={'contractor-buttons'}>
                <Link to={`/financial/client_invoices/${clientInvoice.id}`} title={i18n.t('contractors.detail')}>
                  <i className="far fa-edit" />
                </Link>
                <button onClick={() => onClientInvoiceDelete(clientInvoice.id)} className={'btn-delete-client btn-icon'}
                        title={i18n.t('common.delete')}>
                  <i className="fal fa-trash-alt icon-delete" />
                </button>
              </div>
            </td>
          </tr>
        </Fragment>
      ))}
      </tbody>
    </table>
  );
}

export default Table;
