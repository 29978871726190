import {toast} from "react-toastify";
import i18next from "../i18n";
import responseStatuses from "./responseStatuses";
import keycloak from '../keycloak'

const apiPoint = process.env.REACT_APP_API_ENTRYPOINT

export const api = {
  get: function (url) {
    return fetch(apiPoint + url, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      }
    }).then(responseHandler)
  },
  post: function (url, body, toJson = true) {
    const options = {
      method: "POST",
      body: toJson ? JSON.stringify(body) : body
    }

    options.headers = {}

    if (toJson) {
      options.headers = {"Content-Type": "application/ld+json"}
    }

    if (url !== '/authentication_token') {
      options.headers.Authorization = ` Bearer ${keycloak.token}`
    }

    return fetch(apiPoint + url, options)
      .then(responseHandler)
  },
  patch: function (url, body) {
    return fetch(apiPoint + url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(body)
    })
  },
  put: function (url, body) {
    return fetch(apiPoint + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/ld+json",
        Authorization: `Bearer ${keycloak.token}`
      },
      body: JSON.stringify(body)
    }).then(responseHandler)

  },

  delete: function (url) {
    return fetch(apiPoint + url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
    }).then(responseHandler)
  }
}

export const responseHandler = (response) => {
  if (response.status === responseStatuses.UNAUTHORIZED_STATUS) {
    keycloak.logout()
  }

  if (response.status === responseStatuses.NO_CONTENT_STATUS) {
    return Promise
      .resolve(true)
  }

  if (
    response.status >= responseStatuses.OK_STATUS
    && response.status < responseStatuses.BAD_REQUEST_STATUS
  ) {
    return Promise
      .resolve(response.json())
  }

  if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
    toast.error(i18next.t('server_error'))

    return Promise
      .reject('')
  }

  if (response.status === responseStatuses.DENIED_STATUS) {
    toast.error(i18next.t('errors.access_denied'))

    return Promise
      .reject('')
  }

  if (response.status === responseStatuses.NOT_FOUND_STATUS) {
    toast.error(i18next.t('errors.not_found'))

    return Promise
      .reject('')
  }

  if (
    response.status >= responseStatuses.BAD_REQUEST_STATUS
    && response.status < responseStatuses.SERVER_ERROR_STATUS
  ) {
    return Promise
      .resolve(response.json())
      .then((responseInJson) => {
        return Promise.reject(responseInJson)
      })
  }
}