import React, {useState, useEffect} from 'react'
import MenuClient from '../../Components/MenuClient'
import {toast} from 'react-toastify'
import i18n from "i18next"
import apiUrls from "../../ApiUrls"
import useApiFetch from "../../Components/Hooks/useApiFetch"
import {intervals, weekDays, getIntervalOptions, getWeekDayOptions} from './Options'
import Form from "./Form"
import useApiPost from "../../Components/Hooks/useApiPost";
import useApiPatch from "../../Components/Hooks/useApiPatch";

const uuidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

function Edit(props) {
  const companyId = props.match.params.companyID
  const [productId] = useState(props.match.params.productId)
  const [selectedInterval, setSelectedInterval] = useState(null)
  const [selectedWeekday, setSelectedWeekday] = useState(null)
  const [selectedForeignCode, setSelectedForeignCode] = useState(null)
  const [formProduct, setFormProduct] = useState({})
  const [inputErrors, setInputErrors] =useState({})

  const [{data: foreignCodes}, getForeignCodes] = useApiFetch(apiUrls.get.productForeignCodes())

  const [{data: product}, dispatchProduct] = useApiFetch(
    apiUrls.get.product(productId)
  )

  const [{errors: addErrors}, addProduct] = useApiPost(
    apiUrls.post.products(),
    '',
    'products.edit',
    () => {
      toast.success(i18n.t('products.edit.added'))
      props.history.push("/client/" + companyId + "/products")
    }
  )

  const [{errors: updateErrors}, updateProduct] = useApiPatch(
    apiUrls.patch.products,
    '',
    'products.edit',
    () => {
      toast.success(i18n.t('products.edit.changed'))
    }
  )

  const isNew = () => productId === undefined

  const setSelected = () => {
    if (intervals[product.interval]) {
      setSelectedInterval({
        label: intervals[product.interval],
        value: product.interval,
      })
    }

    if (weekDays[product.weekDay]) {
      setSelectedWeekday({
        label: weekDays[product.weekDay],
        value: product.weekDay,
      })
    }

    if (foreignCodes.length) {
      for (let code of foreignCodes) {
        if (code.id === product.foreignCode) {
          setSelectedForeignCode({
            label: code.product,
            value: code.id,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (!isNew()) {
      dispatchProduct()
    }

    getForeignCodes()
  }, [])

  useEffect(() => {
    if (product && !formProduct.id && foreignCodes) {
      setFormProduct(product)
      setSelected()
    } else {
      setFormProduct({
        company: `/companies/${companyId}`
      })
    }
  }, [product, foreignCodes])

  useEffect(() => {
    if (addErrors) {
      setInputErrors(addErrors)
    } else if (updateErrors) {
      setInputErrors(updateErrors)
    } else {
      setInputErrors([])
    }
  }, [addErrors, updateErrors])

  const onClickSave = () => {
    if (formProduct.mrmCode && !uuidRegexExp.test(formProduct.mrmCode)) {
      setInputErrors({
        'mrmCode': true
      })
      toast.error('Значением поля код МРМ должна быть строка UUID')
      return
    }

    isNew()
      ? addProduct(formProduct)
      : updateProduct(formProduct.id, formProduct)
  }

  let topTitle = isNew()
    ? i18n.t('clients.edit.create_product')
    : i18n.t('clients.edit.edit_product')

  const foreignCodeOptions = foreignCodes
    ? foreignCodes.map((code) => ({
      value: code.id,
      label: code.product,
    }))
    : []

  return (
    <div className="row content flex">
      <div className="edit-client">
        <div className="table-edit-client">
          <div>
            <MenuClient title={topTitle}>
              <button className="button-accent" onClick={onClickSave}>
                {i18n.t('clients.edit.save')}
              </button>
            </MenuClient>
            <Form
              formProduct={formProduct}
              setFormProduct={setFormProduct}
              intervalOptions={getIntervalOptions()}
              weekDayOptions={getWeekDayOptions()}
              setSelectedInterval={setSelectedInterval}
              setSelectedWeekday={setSelectedWeekday}
              selectedInterval={selectedInterval}
              selectedWeekday={selectedWeekday}
              foreignCodeOptions={foreignCodeOptions}
              selectedForeignCode={selectedForeignCode}
              setSelectedForeignCode={setSelectedForeignCode}
              inputErrors={inputErrors}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;