import React, {useEffect, useState, forwardRef} from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";
import {logError} from "../../utils";
import i18n from "i18next";

function DateRangeInput(props) {
  const currentDate = new Date()
  const firstMonthDay = moment(currentDate).startOf('month').toDate()

  const [startDate, setStartDate] = useState(firstMonthDay);
  const [endDate, setEndDate] = useState(null);
  const onChange = dates => {
    const [start, end] = dates;
    const endDate = end ? moment(end).endOf('month').toDate(): ''
    setStartDate(start);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (props.filter.dateStart && props.filter.dateEnd) {
      setStartDate(moment(props.filter.dateStart, props.filter.filterFormat).toDate())
      setEndDate(moment(props.filter.dateEnd, props.filter.filterFormat).toDate())
    }
  }, [props.filter.dateStart, props.filter.dateEnd])

  useEffect(() => {
    props.onChange(null, {
      'dateStart': startDate ? moment(startDate).startOf('month').format(props.filter.filterFormat) : '',
      'dateEnd': endDate ? moment(endDate).endOf('month').format(props.filter.filterFormat) : '',
    })
  }, [startDate, endDate])

  const RangeInput = forwardRef(
    ({value, onClick}, ref) => {
      return (
        <>
          <button className="range-button" onClick={onClick} ref={ref}>
            {startDate ? moment(startDate, props.filter.filterFormat).format(props.filter.rangeFormat) : ''}
          </button>
          - <button className="range-button">
            {endDate ? moment(endDate, props.filter.filterFormat).format(props.filter.rangeFormat) : ''}
          </button>
        </>
      )
    },
  );

  return (
    <span className={'filter-item ' + props.filter.className}>
      <div className={'range-date-wrapper'}>
        <div>
          <DatePicker
            placeholderText={props.filter.placeholder}
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat={props.filter.format}
            showMonthYearPicker={props.filter.showMonthYearPicker}
            selectsRange
            shouldCloseOnSelect={false}
            customInput={<RangeInput />}
          />
        </div>
      </div>
    </span>
  )
}

export default DateRangeInput