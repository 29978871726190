import {useEffect, useState} from "react"
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {api} from "../../../api/apiProvider";
import Roles from "../../../roles/Roles";
import useApiPost from "../../../Components/Hooks/useApiPost";
import apiUrls from "../../../ApiUrls";
import useApiPatch from "../../../Components/Hooks/useApiPatch";
import {DigitalChannel} from "../../../entities/channel/DigitalChannel";
import {TvChannel} from "../../../entities/channel/TvChannel";
import useApiFetch from "../../../Components/Hooks/useApiFetch";

const agentTypeCode = 'agent'

const useCommissions = (faceIds, channel, dateStart, dateEnd, advertising) => {
  const [commissions, setCommissions] = useState({})
  const [agentContractType, setAgentContractType] = useState({})

  const [{errors: addErrors}, addCommission] = useApiPost(
    apiUrls.get.commissions()
  )

  const [{errors: updateErrors}, updateCommission] = useApiPatch(
    apiUrls.get.commission
  )

  const [{data: contractTypes}, getContractTypes] = useApiFetch(apiUrls.get.contractTypes())

  const chunk = (array, size) =>
    Array.from({length: Math.ceil(array.length / size)}, (value, index) =>
      array.slice(index * size, index * size + size));

  useEffect(() => {
    getContractTypes()
  }, [])

  useEffect(() => {
    if (contractTypes) {
      const agentContractType = contractTypes.find(type => type.code === agentTypeCode)

      if (agentContractType) {
        setAgentContractType(agentContractType)
      }
    }
  }, [contractTypes])

  useEffect(() => {
    if (channel && channel.code && faceIds && faceIds.length && Roles.hasAccess('FinancialCommissionsRead')) {
      getCommissions()
    }
  }, [faceIds])

  const getCommissions = () => {
    async function fetchData(faceIds) {
      const channelEntity = ChannelFactory.create(channel.code)
      const url = channelEntity.getApiUrl('faceCommissions')
      const params = new URLSearchParams({
        dateStart: dateStart,
        dateEnd: dateEnd,
      })

      const faceParams = faceIds.map(id => `face.id[]=${id}`).join('&')

      const faceCommissionsResult = await api.get(url + '?' + params.toString() + '&' + faceParams)
      const faceCommissions = faceCommissionsResult['hydra:member']

      let facesCommissions = {}

      for (let faceCommission of faceCommissions) {
        const faceIri = faceCommission.face

        if (faceCommission.commissions && faceCommission.commissions.length) {
          facesCommissions[faceIri] = faceCommission.commissions
        }
      }

      return {
        facesCommissions,
      }
    }

    const chunkedFaceIds = chunk(faceIds, 100);

    Promise.all(chunkedFaceIds.map(faceIds => fetchData(faceIds))).then(values => {
      let facesCommissions = {}

      for (let value of values) {
        if (value.facesCommissions && Object.keys(value.facesCommissions).length) {
          for(let key in value.facesCommissions) {
            if (value.facesCommissions[key] && value.facesCommissions[key].length) {
              const commission = value.facesCommissions[key][0]

              facesCommissions[key] = {
                value: commission.value,
                id: Number(commission['@id'].split('/').pop()),
              }
            }
          }
        }
      }

      setCommissions(facesCommissions)
    })
  }

  const params = (face) => {
    const channelEntity = ChannelFactory.create(channel.code)
    let params = {
      advertising: `/adverts/${face.advertisingId}`,
      channel: `/channels/${channel.value}`,
      clients: [`/companies/${face.companyId}`],
      dateEnd,
      dateStart,
      contractType: agentContractType ? agentContractType['@id'] : null,
      contractor: face.contractor['@id'],
      comment: '',
      asPercent: false,
      value: face.bonus
    }

    const digitalChannel = ChannelFactory.create(DigitalChannel.getCode())
    const tvChannel = ChannelFactory.create(TvChannel.getCode())

    params[digitalChannel.platformsName] = []
    params[tvChannel.platformsName] = []

    const platformsName = channelEntity.platformsName
    const faceName = channelEntity.faceName

    if (faceName) {
      params[faceName] = face['@id']
    }

    if (platformsName) {
      params[platformsName] = [face.platform['@id']]
    }

    return params
  }

  const save = face => {
    return new Promise(async (resolve, reject) => {
      if (!face.contractor || !face.contractor['@id']) {
        return resolve(true)
      }

      if (face.commissionId) {
        updateCommission(face.commissionId, params(face), {}, () => {
          return resolve(true)
        })
      } else {
        addCommission(params(face), null, {}, true, () => {
          return resolve(true)
        })
      }
    })
  }

  return {
    commissions,
    getCommissions,
    save,
  }
}

export default useCommissions