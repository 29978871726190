import {useReducer, useCallback} from 'react';
import reducer, {initialState} from '../Api/ReducerPost'
import {SUCCESS, ERROR} from '../Api/actionTypes';
import {getInputErrors, sprintToastErrors} from "../../utils";
import {api} from '../../api/apiProvider'

const useApiPost = (url, errorDescription, translationPath, callback) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const makeRequest = useCallback((data, id, params = {}, toJson = true, requestCallback, errorCallback) => {
    try {
      const postUrl = ((typeof url === 'function') && id)
        ? url(id)
        : url

      api.post(postUrl, data, toJson)
        .then((response) => {
          if (response) {
            dispatch({type: SUCCESS, data: {}})
          }

          if (typeof callback === 'function') {
            if (response.id) {
              callback(response.id)
            } else {
              callback(params)
            }
          }

          if (typeof requestCallback === 'function') {
            if (response.id) {
              requestCallback(response.id)
            } else {
              requestCallback(params)
            }
          }
        })
        .catch((error) => {
          if (typeof errorCallback === 'function') {
            errorCallback(params)
          }

          sprintToastErrors(error, errorDescription, translationPath)
          dispatch({type: ERROR, data: getInputErrors(error)})
        })
    } catch (error) {
      if (typeof errorCallback === 'function') {
        errorCallback(params)
      }

      sprintToastErrors(error, errorDescription, translationPath)
      dispatch({type: ERROR, data: getInputErrors(error)})
    }
  }, [url]);

  return [state, makeRequest];
};

export default useApiPost;