import React, {useState, useEffect} from 'react';
import SubMenu from '../../../Components/SubMenu';
import MenuClient from '../../../Components/MenuClient';
import {Link} from 'react-router-dom';
import i18n from "i18next";
import apiUrls from "../../../ApiUrls";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import Protected from "../../../Components/Roles/Protected";
import i18next from "i18next";
import Table from "./Table";
import useDelete from "../../../Components/Hooks/useDelete";

function List(props) {
  const [companyId] = useState(props.match.params.companyID)
  const [legalId] = useState(props.match.params.legalID)

  const [{data: intervals}, getIntervals] = useApiFetch(
    apiUrls.get.repaymentIntervals()
  );

  const deleteInterval = useDelete(apiUrls.delete.repaymentInterval)

  useEffect(() => {
    getIntervals({
      'contractor.id': legalId
    })
  }, [])

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu/>
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18next.t('clients.edit.repayment_periods')}>
              <Protected access={'RepaymentIntervals'}>
                <Link className="blue-cursor-button" to={`/client/${companyId}/legal/${legalId}/repayment_intervals/new`}>
                  <i className="fal fa-plus mr-for-icon"/>
                  {i18n.t('clients.edit.add_legal_repayment')}
                </Link>
              </Protected>
            </MenuClient>

            {intervals &&
            <Table
              intervals={intervals}
              companyId={companyId}
              legalId={legalId}
              deleteInterval={deleteInterval}
              getIntervals={getIntervals}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
