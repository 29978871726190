import React from 'react';

function Loader ({className = ''}) {
  return (
    <div className={'loader ' + className}>
      <i className="fa fa-refresh fa-spin" />
    </div>
  );
}

export default Loader;