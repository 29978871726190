import i18n from "i18next";

export const wholeInterval = {
  value: '',
  label: i18n.t('clients.advertising.whole_advertising'),
  code: 'whole',
}

export const monthInterval = {
  value: 'month',
  label: i18n.t('clients.advertising.month'),
  code: 'month',
}

export const weekInterval = {
  value: 'week',
  label: i18n.t('clients.advertising.week'),
  code: 'week',
}

export const dayInterval = {
  value: 'day',
  label: i18n.t('clients.advertising.day'),
  code: 'day',
}