import React from 'react';
import Select from 'react-select';
import Input from '../../Components/Inputs/Input';
import i18n from "i18next";
import {Link} from "react-router-dom";

function Form(props) {
  return (
    <table className="table redesign-table table-client">
      <tbody>
      <tr>
        <td>
          {i18n.t('clients.edit.name')}
        </td>
      </tr>
      <tr>
        <td>
          <Input
            type="text"
            value={props.formLegal.name || ''}
            className={props.inputErrors.hasOwnProperty('name') ? 'has-error' : ''}
            onChange={(value) => props.setFormLegal({...props.formLegal, name: value})} />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.itn')}
        </td>
      </tr>
      <tr>
        <td>
          <Input
            type="text"
            value={props.formLegal.inn || ''}
            onChange={
              (value) => props.setFormLegal({...props.formLegal, inn: value})
            } />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.iec')}
        </td>
      </tr>
      <tr>
        <td>
          <Input
            type="text"
            value={props.formLegal.kpp || ''}
            onChange={
              (value) => props.setFormLegal({...props.formLegal, kpp: value})
            } />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.type_of_tax')}
        </td>
      </tr>
      <tr>
        <td className={props.inputErrors.hasOwnProperty('tax') ? 'has-error' : ''}>
          {props.selectedTax &&
          <>
            <Select
              className="react-select-container select-pseudo-input"
              classNamePrefix="react-select"
              placeholder={i18n.t('clients.edit.select_type')}
              options={props.taxOptions}
              onChange={props.setTax}
              value={props.selectedTax} />
            {props.inputErrors.hasOwnProperty('tax') &&
            <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
            }
          </>
          }
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.role')}
        </td>
      </tr>
      <tr>
        <td className={props.inputErrors.hasOwnProperty('role') ? 'has-error' : ''}>
          {props.selectedRole &&
          <>
            <Select
              className="react-select-container select-pseudo-input"
              classNamePrefix="react-select"
              placeholder={i18n.t('clients.edit.select_type')}
              options={props.roleOptions}
              onChange={props.setRole}
              value={props.selectedRole} />
            {props.inputErrors.hasOwnProperty('role') &&
            <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
            }
          </>
          }
        </td>
      </tr>
      {props.isCompanyHasRoleError() &&
      <tr>
        <td className={'has-error'}>
          <span className="error-text">{i18n.t('clients.edit.company_hasnt_role')}
            <Link to={`/client/${props.companyId}/edit`}> {i18n.t('clients.edit.company_edit_page')}</Link></span>
        </td>
      </tr>
      }
      <tr>
        <td>
          {i18n.t('clients.edit.comment')}
        </td>
      </tr>
      <tr>
        <td>
          <Input
            type="text"
            value={props.formLegal.comment || ''}
            onChange={
              (value) => props.setFormLegal({...props.formLegal, comment: value})
            } />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default Form;