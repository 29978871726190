import React, {Fragment} from 'react'
import i18n from "i18next";
import {Link} from "react-router-dom";

const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function Table(props) {
  return (
    <table className="table redesign-table regional-client">
      <thead>
      <tr>
        <th>
          {i18n.t('registries.list.name')}
        </th>
        <th>
          {i18n.t('registries.list.client')}
        </th>
        <th>
          {i18n.t('registries.list.contractor')}
        </th>
        <th>
          {i18n.t('registries.list.expenses')}
        </th>
        <th>
          {i18n.t('registries.list.app_expenses')}
        </th>
        <th>
          {i18n.t('registries.list.delta')}
        </th>
        <th>
          {i18n.t('registries.list.comment')}
        </th>
        <th />
      </tr>
      </thead>

      <tbody>
      {props.registries.map((registry, index) => (
        <Fragment
          key={index}
        >
          <tr
            onClick={() => props.toggleRegistry(index)}
          >
            <td>
              <Link to={"/registry/" + registry.id}>
                {registry.name}
              </Link>
            </td>
            <td>
              {registry.client.name || ''}
            </td>
            <td>
              {registry.contractor.name || ''}
            </td>
            <td className='money'>
              {registry.expenses.toFixed(fractionDigits) || ''}
            </td>
            <td className='money'>
              {registry.sumExpenses.toFixed(fractionDigits) || ''}
            </td>
            <td className='money'>
              <span className={Math.abs(registry.expenses - registry.sumExpenses) ? 'error-colored' : ''}>
                {Math.abs(registry.expenses - registry.sumExpenses).toFixed(fractionDigits)}
              </span>
            </td>
            <td>
              {registry.comment || ''}
            </td>
            <td />
          </tr>

          {registry.invoiceRows && registry.invoiceRows.length > 0 && registry.active &&
          <Fragment>
            {registry.invoiceRows.map(row => (
              <tr key={row.id}>
                <td />
                <td colSpan={3}><Link to={`${row.invoice['@id']}/edit`}>{row.invoice.name}</Link> {row.advertising.name}
                </td>
                <td className='money'>{row.expenses}</td>
                <td colSpan={2} />
              </tr>
            ))}
          </Fragment>
          }
        </Fragment>
      ))}
      </tbody>
    </table>
  );
}

export default Table;
