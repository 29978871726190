import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import Input from "../../../../Components/Inputs/Input";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import FormSelect from "../../../../Components/Inputs/FormSelect";
import Select from "react-select";
import {deepClone} from "transliteration/dist/node/src/common/utils";
import FloatInput from "../../../../Components/Inputs/FloatInput";
import RatioBlock from "./RatioBlock";
import {getCoefficientPercent} from "../../../../utils";
import {TvChannel} from "../../../../entities/channel/TvChannel";
import AffinitiesBlock from "./AffinitiesBlock";
import apiUrls from "../../../../ApiUrls";
import {api} from "../../../../api/apiProvider";
import QualitiesBlock from "./QualitiesBlock";
import Checkbox from "../../../../Components/Inputs/Checkbox";
import DurationSplitsBlock from "./DurationSplitsBlock";
import {FaceFactory} from "../../../../entities/face/FaceFactory";

const getRatioCoefficient = (value) => {
  return 1 - (value / 100)
}

const getRatioPercent = (value) => {
  return getCoefficientPercent(value)
}

const isFaceUnits = (face) => {
  return Boolean(face.faceUnits && face.faceUnits.length)
}

const isAffinity = (face) => {
  return Boolean(face.affinities && face.affinities.length)
}

const isQualities = (face) => {
  return Boolean(face.qualities && face.qualities.length)
}

const isAffinityValues = (face) => {
  if (!isAffinity(face)) {
    return false
  }

  for (let affinity of face.affinities) {
    if (!affinity.dateStart || !affinity.value) {
      return false
    }
  }

  return true
}

const emptyOption = {
  label: i18n.t('projects.list.not_selected'),
  value: ''
}

const getDataFromFace = (formFace, ratios) => {
  let form = {}
  form.id = formFace.id
  form.targeting = formFace.targeting
  form.sponsorship = formFace.sponsorship || false
  form.advertising = formFace.advertising['@id']
  form.hpa = formFace.hpaIri
  form.affinities = formFace.affinities
  form.cppps = formFace.cppps
  form.qualities = []
  form.durationSplits = []

  if (formFace.qualities) {
    for (let formQuality of formFace.qualities) {
      form.qualities.push({
        '@id': formQuality['@id'],
        id: formQuality.id,
        dateStart: formQuality.dateStart,
        super: formQuality.super,
        superPrime: formQuality.superPrime,
        fixPrime: formQuality.fixPrime,
      })
    }
  }

  if (formFace.durationSplits) {
    for (let durationsSplit of formFace.durationSplits) {
      const newDuration = deepClone(durationsSplit)

      for (let duration of newDuration.durations) {
        duration.value = duration.value / 100
      }

      form.durationSplits.push(newDuration)
    }
  }

  form.faceUnits = []

  if (formFace.contractor && formFace.contractor['@id']) {
    form.contractor = formFace.contractor['@id']
  } else {
    form.contractor = null
  }

  if (isFaceUnits(formFace)) {
    for (let faceUnit of formFace.faceUnits) {
      let unitCost = null

      if (faceUnit.unitCost !== null) {
        unitCost = isNaN(parseFloat(faceUnit.unitCost)) ? null : parseFloat(faceUnit.unitCost)
      }

      if (!faceUnit.ratios || !faceUnit.ratios.length) {
        faceUnit.ratios = []
      }

      for (let ratio of ratios) {
        const ratioValue = faceUnit.ratios
          ? faceUnit.ratios.find(unitRatio => unitRatio.ratio['@id'] === ratio['@id'])
          : false

        if (!ratioValue) {
          faceUnit.ratios.push({
            ratio: {
              '@id': ratio['@id'],
              name: ratio.name
            },
            value: 1
          })
        }
      }

      for (let ratio of faceUnit.ratios) {
        if (ratio.ratio.asPercent) {
          ratio.value = getRatioCoefficient(ratio.value)
        }
      }

      const faceUnitParams = {
        unit: faceUnit.unit['@id'],
        unitCost: unitCost,
        ratios: faceUnit.ratios
      }

      if (formFace.id) {
        faceUnitParams.id = faceUnit.id
      }

      form.faceUnits.push(faceUnitParams)
    }
  }

  return form
}

const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function TvForm(props) {
  const companyId = props.companyId
  const [formFace, setFormFace] = useState({})
  const [channelEntity] = useState(new TvChannel())
  const [selectedPlatform, setSelectedPlatform] = useState({})
  const [selectedRegion, setSelectedRegion] = useState({})
  const [selectedCommunication, setSelectedCommunication] = useState({})
  const [selectedContractor, setSelectedContractor] = useState({})
  const [selectedUnit, setSelectedUnit] = useState({})
  const [contractors, setContractors] = useState([])
  const [ratios, setRatios] = useState([])
  const [platformOptions, setPlatformOptions] = useState(null)
  const [communicationOptions, setCommunicationOptions] = useState(null)
  const [regionOptions, setRegionOptions] = useState(null)
  const [unitOptions, setUnitOptions] = useState(null)
  const [getCodeError, setGetCodeError] = useState('')

  const [{data: platforms}, getPlatforms] = useApiFetch(channelEntity.getApiUrl('platforms'))
  const [{data: communications}, getCommunications] = useApiFetch(channelEntity.getApiUrl('communications'))
  const [{data: units}, getUnits] = useApiFetch(channelEntity.getApiUrl('units'))
  const [{data: ratiosFromApi}, getRatiosFromApi] = useApiFetch(channelEntity.getApiUrl('ratios'))
  const [{data: platformClients}, getPlatformClients] = useApiFetch(channelEntity.getApiUrl('platformClients'))
  const [{data: regions}, getRegions] = useApiFetch(apiUrls.get.tvRegions)
  //const [{data: hpas}, getHpas] = useApiFetch(apiUrls.get.tvHpas())
  const [{data: hpasLists}, getHpasLists] = useApiFetch(apiUrls.get.tvHpasLists())
  const [{data: face}, getFace] = useApiFetch(apiUrls.get.tvFace(props.faceId))

  const [{errors: updateErrors}, updateFace] = useApiPatch(
    channelEntity.getApiUrlFunctionWithId('faces'),
    //apiUrls.patch.digitalFace,
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      props.getAdvertPeriod({
        't[]': 'target'
      })
      toast.success(i18n.t('periods.face.saved'))
    }
  )

  const [{errors: addErrors}, addFace] = useApiPost(
    channelEntity.getApiUrl('faces'),
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      toast.success(i18n.t('periods.face.added'))
    }
  )

  const getFaceHpa = async (platform, communication, region, unit) => {
    const params = new URLSearchParams({
      'platform.id': platform,
      'communication.id': communication,
      'region.id': region,
      'unit.id': unit,
    })

    return await api.get(apiUrls.get.tvHpas() + '?' + params.toString()).then((result) => {
      const list = result['hydra:member']

      if (list && list[0]) {
        return list[0]
      }
    })
  }

  useEffect(() => {
    getPlatforms({
      'pagination': false
    })
    getCommunications()
    getUnits()
    getRatiosFromApi()
    getRegions({
      pagination: false
    })

    if (props.faceId) {
      const faceEntity = FaceFactory.create('tv')
      getFace({
        't[]': faceEntity.listGroupParams
      })
    } else {
      setNewFace()
    }
  }, [])

  useEffect(() => {
    if (face) {
      const formFace = deepClone(face)

      if (props.isNew) {
        delete(formFace.id)
        delete(formFace.code)

        formFace.advertising = {
          '@id': `/adverts/${props.advertisingId}`,
          id: props.advertisingId,
        }
      }

      if (formFace.faceUnits && formFace.faceUnits.length) {
        for (let unit of formFace.faceUnits) {
          if (!unit.ratios) {
            continue
          }

          for (let ratio of unit.ratios) {
            if (ratio.ratio && ratio.ratio.asPercent) {
              ratio.value = getRatioPercent(ratio.value)
            }
          }
        }
      }

      if (formFace.hpa && formFace.hpa.platform && formFace.hpa.platform['@id']) {
        formFace.platform = formFace.hpa.platform
      }

      if (formFace.hpa && formFace.hpa.communication && formFace.hpa.communication['@id']) {
        formFace.communication = formFace.hpa.communication
      }

      if (formFace.hpa && formFace.hpa.region && formFace.hpa.region['@id']) {
        formFace.region = formFace.hpa.region
      }

      if (formFace.durationSplits) {
        for (let durationsSplit of formFace.durationSplits) {
          for (let duration of durationsSplit.durations) {
            duration.value = duration.value * 100
          }
        }
      }

      setFormFace(formFace)
    }
  }, [face])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.value) {
      getPlatformClients({
        't[]': 'platform-legal',
        'platform.id': selectedPlatform.value.split('/').pop(),
        'client.id': companyId,
        'pagination': false
      })
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (selectedPlatform.value || selectedCommunication.value || selectedRegion.value || selectedUnit.value) {
      const getHpasListsParams = {}

      if (selectedPlatform.value) {
        getHpasListsParams['platform.id'] = selectedPlatform.value.split('/').pop()
      }

      if (selectedCommunication.value) {
        getHpasListsParams['communication.id'] = selectedCommunication.value.split('/').pop()
      }

      if (selectedRegion.value) {
        getHpasListsParams['region.id'] = selectedRegion.value.split('/').pop()
      }

      if (selectedUnit.value) {
        getHpasListsParams['unit.id'] = selectedUnit.value.split('/').pop()
      }

      if (selectedPlatform.value && selectedCommunication.value && selectedRegion.value && selectedUnit.value) {
        getHpa()
      } else {
        setFormFace({
          ...formFace,
          hpaIri: null,
          hpaId: null,
        })
      }

      getHpasLists(getHpasListsParams)
    }
  }, [selectedPlatform, selectedCommunication, selectedRegion, selectedUnit])

  useEffect(() => {
    if (regions && !regionOptions) {
      const regionOptions = regions ? getOptions(regions) : []
      setRegionOptions(regionOptions)
    }

    if (platforms && !platformOptions) {
      const platformOptions = platforms ? getOptions(platforms) : []
      setPlatformOptions(platformOptions)
    }

    if (communications && !communicationOptions) {
      const communicationOptions = communications ? getOptions(communications) : []
      setCommunicationOptions(communicationOptions)
    }

    if (units && !unitOptions) {
      const unitOptions = units ? getOptions(units) : []
      setUnitOptions(unitOptions)
    }
  }, [platforms, communications, regions, units])

  useEffect(() => {
    if (hpasLists) {
      const platformFilterValues = hpasLists.platform['hydra:member']
      const communicationFilterValues = hpasLists.communication['hydra:member']
      const regionFilterValues = hpasLists.region['hydra:member']
      const unitFilterValues = hpasLists.unit['hydra:member']

      const platformOptions = getOptions(platforms).filter(option => platformFilterValues.includes(option.value))
      const communicationOptions = getOptions(communications).filter(option => communicationFilterValues.includes(option.value))
      const regionOptions = getOptions(regions).filter(option => regionFilterValues.includes(option.value))
      const unitOptions = getOptions(units).filter(option => unitFilterValues.includes(option.value))

      platformOptions.unshift(emptyOption)
      communicationOptions.unshift(emptyOption)
      regionOptions.unshift(emptyOption)
      unitOptions.unshift(emptyOption)

      setPlatformOptions(platformOptions)
      setCommunicationOptions(communicationOptions)
      setRegionOptions(regionOptions)
      setUnitOptions(unitOptions)
    }
  }, [hpasLists])

  useEffect(() => {
    if (platformClients) {
      if (platformClients.length) {
        let clientContractors = []

        for (let client of platformClients) {
          if (client.contractor && client.contractor.id) {
            clientContractors.push(client.contractor)
          }
        }

        const selectedContractorInClients = selectedContractor.value ? clientContractors.find(contractor => {
          return contractor['@id'] === selectedContractor.value
        }) : ''

        if (!selectedContractorInClients) {
          onChange({}, 'contractor')
        }

        setContractors(clientContractors)
      } else {
        onChange({}, 'contractor')
        setContractors([])
      }
    }
  }, [platformClients])

  useEffect(() => {
    if (formFace.platform && formFace.platform['@id']) {
      setSelectedPlatform({
        value: formFace.platform['@id'],
        label: formFace.platform.name
      })
    } else {
      setSelectedPlatform({})
    }
  }, [formFace.platform])

  useEffect(() => {
    if (formFace.region && formFace.region['@id']) {
      setSelectedRegion({
        value: formFace.region['@id'],
        label: formFace.region.name
      })
    } else {
      setSelectedRegion({})
    }
  }, [formFace.region])

  useEffect(() => {
    if (formFace.communication && formFace.communication['@id']) {
      setSelectedCommunication({
        value: formFace.communication['@id'],
        label: formFace.communication.name
      })
    } else {
      setSelectedCommunication({})
    }
  }, [formFace.communication])

  useEffect(() => {
    if (formFace.hpa && formFace.hpa.platform && formFace.hpa.platform['@id']) {
      setSelectedPlatform({
        value: formFace.hpa.platform['@id'],
        label: formFace.hpa.platform.name
      })
    } else {
      setSelectedPlatform({})
    }

    if (formFace.hpa && formFace.hpa.communication && formFace.hpa.communication['@id']) {
      setSelectedCommunication({
        value: formFace.hpa.communication['@id'],
        label: formFace.hpa.communication.name
      })
    } else {
      setSelectedCommunication({})
    }

    if (formFace.hpa && formFace.hpa.region && formFace.hpa.region['@id']) {
      setSelectedRegion({
        value: formFace.hpa.region['@id'],
        label: formFace.hpa.region.name
      })
    } else {
      setSelectedRegion({})
    }
  }, [formFace.hpa])

  useEffect(() => {
    if (formFace.contractor && formFace.contractor['@id']) {
      setSelectedContractor({
        value: formFace.contractor['@id'],
        label: formFace.contractor.name
      })
    } else {
      setSelectedContractor({})
    }
  }, [formFace.contractor])

  useEffect(() => {
    if (formFace.faceUnits && formFace.faceUnits.length) {
      const faceUnit = formFace.faceUnits[0]

      setSelectedUnit({
        value: faceUnit.unit['@id'],
        label: faceUnit.unit.name
      })
    } else {
      setSelectedUnit([])
    }
  }, [formFace.faceUnits])

  useEffect(() => {
    if (ratiosFromApi) {
      const ratioValues = ratiosFromApi

      if (formFace.faceUnits && formFace.faceUnits.length) {
        const unit = formFace.faceUnits[0]

        if (unit.ratios) {
          for (let faceRatio of unit.ratios) {
            const ratio = ratioValues.find(ratio => ratio['@id'] === faceRatio.ratio['@id'])

            if (!ratio) {
              ratioValues.push(faceRatio.ratio)
            }
          }
        }
      }

      setRatios(ratioValues)
    }
  }, [ratiosFromApi])

  const setNewFace = () => {
    setFormFace({
      code: '',
      formatText: '',
      name: '',
      targeting: '',
      advertising: {
        '@id': `/adverts/${props.advertisingId}`,
        id: props.advertisingId,
      },
      platform: {},
      communication: {},
      channel: {},
      source: {},
      strategy: {},
      type: {
        formats: []
      },
      formats: [],
      faceUnits: [],
    })
  }

  const onChange = (value, name) => {
    if (value && value.value) {
      setFormFace({
        ...formFace,
        [name]: {
          '@id': value.value,
          name: value.label
        }
      })
    } else {
      setFormFace({
        ...formFace,
        [name]: value
      })
    }
  }

  const onUnitChange = (value) => {
    if (!(formFace.faceUnits) || (formFace.faceUnits[0] && !formFace.faceUnits[0].id)) {
      formFace.faceUnits[0] = {}

      const unit = units.find(unit => unit['@id'] === value.value)

      if (unit && unit.id) {
        formFace.faceUnits[0].id = unit.id
      }
    }

    setFormFace({
      ...formFace,
      faceUnits: [{
        ...formFace.faceUnits[0],
        ...{
          unit: {'@id': value.value, 'name': value.label}
        }
      }]
    })
  }

  const onUnitCostChange = (value) => {
    if (formFace.faceUnits && formFace.faceUnits[0]) {
      setFormFace({
        ...formFace,
        faceUnits: [{
          ...formFace.faceUnits[0],
          unitCost: value
        }]
      })
    }
  }

  const getHpa = async () => {
    const hpa = await getFaceHpa(
      selectedPlatform.value.split('/').pop(),
      selectedCommunication.value.split('/').pop(),
      selectedRegion.value.split('/').pop(),
      selectedUnit.value.split('/').pop()
    )

    if (hpa && hpa['@id']) {
      setFormFace(formFace => {
        return{
          ...formFace,
          hpaIri: hpa['@id'],
          hpaId: hpa.id,
        }
      })
    } else {
      setFormFace({
        ...formFace,
        hpaIri: null,
        hpaId: null,
      })
    }
  }

  const save = async () => {
    if (!isFaceUnits(formFace)) {
      toast.error(i18n.t('clients.advertising.empty_face_units'))
      return
    }

    if (isAffinity(formFace) && !isAffinityValues(formFace)) {
      toast.error(i18n.t('clients.advertising.empty_affinity_values'))
      return
    }

    if (!isQualities(formFace)) {
      toast.error(i18n.t('clients.advertising.empty_quality_values'))
      return
    }

    const isHpasError = checkFieldsForHpas()

    if (isHpasError) {
      return
    }

    const hpa = await getFaceHpa(
      selectedPlatform.value.split('/').pop(),
      selectedCommunication.value.split('/').pop(),
      selectedRegion.value.split('/').pop(),
      selectedUnit.value.split('/').pop()
    )

    if (formFace.hpaIri) {
      if (formFace.hpaIri != hpa['@id']) {
        toast.error(i18n.t('face.edit.code_error'))
        return
      }
    } else {
      toast.error(i18n.t('face.edit.code_not_find'))
      return
    }

    const data = getDataFromFace(formFace, ratios)

    if (formFace.id) {
      updateFace(formFace.id, data)
    } else {
      addFace(data)
    }
  }

  const getOptions = items => {
    const options = items
      ? items.map((item) => {
        return {value: item['@id'], label: item.name}
      }) : []

    options.unshift(emptyOption)

    return options
  }

  /*const platformOptions = getOptions(platforms)
  const regionOptions = getOptions(regions)
  const communicationsOptions = getOptions(communications)*/
  const contractorOptions = getOptions(contractors)
  //const unitOptions = getOptions(units)

  const onAffinityChange = (value) => {
    setFormFace({
      ...formFace,
      affinities: value
    })
  }

  const onCpppChange = (value) => {
    setFormFace({
      ...formFace,
      cppps: value
    })
  }

  const onQualityChange = (value) => {
    setFormFace({
      ...formFace,
      qualities: value
    })
  }

  const onDurationSplitsChange = (value) => {
    setFormFace({
      ...formFace,
      durationSplits: value
    })
  }

  const checkFieldsForHpas = () => {
    let error = false

    if (!selectedPlatform || !selectedPlatform.value) {
      error = true
      toast.error(i18n.t('face.edit.platform_not_selected'))
    }

    if (!selectedCommunication || !selectedCommunication.value) {
      error = true
      toast.error(i18n.t('face.edit.communication_not_selected'))
    }

    if (!selectedRegion || !selectedRegion.value) {
      error = true
      toast.error(i18n.t('face.edit.region_not_selected'))
    }

    if (!selectedUnit || !selectedUnit.value) {
      error = true
      toast.error(i18n.t('face.edit.unit_not_selected'))
    }

    return error
  }

  const getCode = async () => {
    const isError = checkFieldsForHpas()
    if (isError) {
      return
    }

    let code = ''
    const hpa = await getFaceHpa(selectedPlatform.value.split('/').pop(),
      selectedCommunication.value.split('/').pop(),
      selectedRegion.value.split('/').pop(),
      selectedUnit.value.split('/').pop()
    )

    if (hpa && hpa.id) {
      code = formFace.advertising.id + '_' + hpa.id
    }

    if (code) {
      setFormFace({
        ...formFace,
        code
      })
    }
  }

  const onRatioChange = (ratioIri, value, isDiscount = false) => {
    let faceRatios = (formFace && formFace.faceUnits && formFace.faceUnits.length && formFace.faceUnits[0]
      && formFace.faceUnits[0].ratios && formFace.faceUnits[0].ratios.length)
      ? formFace.faceUnits[0].ratios
      : []

    let parsedValue = parseFloat(value)

    const faceRatio = faceRatios.find(ratio => (ratio.ratio && ratio.ratio['@id'] === ratioIri))
    const ratio = ratios.find(ratio => ratio['@id'] === ratioIri)

    if (faceRatio) {
      faceRatio.value = parsedValue

      if (ratio) {
        faceRatio.ratio.asPercent = ratio.asPercent
      }
    } else {
      if (ratio) {
        faceRatios.push({
          ratio: {
            '@id': ratio['@id'],
            name: ratio.name,
            asPercent: ratio.asPercent
          },
          value: parsedValue
        })
      }
    }

    formFace.faceUnits[0].ratio = faceRatios.filter(ratio => !ratio.ratio.asPercent).reduce((acc, ratio) => {
      return ratio.value
        ? (acc * ratio.value)
        : (acc * 1)
    }, 1)

    formFace.faceUnits[0].discount = faceRatios.filter(ratio => ratio.ratio.asPercent).reduce((acc, ratio) => {
      return ratio.value
        ? (acc * getRatioCoefficient(ratio.value))
        : (acc * 1)
    }, 1)

    formFace.faceUnits[0].ratios = faceRatios

    setFormFace(deepClone(formFace))
  }

  const coefficientRatios = ratios
    ? ratios.filter(ratio => !ratio.asPercent)
    : []

  const discountRatios = ratios
    ? ratios.filter(ratio => ratio.asPercent)
    : []

  const getTotalDiscount = () => {
    return (formFace && formFace.faceUnits && formFace.faceUnits[0] && formFace.faceUnits[0].discount !== undefined)
      ? ((1 - formFace.faceUnits[0].discount) * 100).toFixed(fractionDigits)
      : 0
  }

  const getTotalCoefficient = () => {
    return (formFace && formFace.faceUnits && formFace.faceUnits[0] && formFace.faceUnits[0].ratio)
      ? formFace.faceUnits[0].ratio.toFixed(fractionDigits)
      : 1
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
           onClick={() => props.setSelectedFace(null)}/>
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => props.setSelectedFace(null)}>
            <i className='fas fa-remove'/>
          </button>
        </div>

        {formFace && formFace.name !== undefined &&
          <div className='popup-form__body'>
            <div className='popup-form__rows'>
              <FormSelect
                name='platform'
                value={selectedPlatform}
                options={platformOptions}
                label={i18n.t('clients.advertising.platform')}
                onChange={(value) => onChange(value, 'platform')}
              />

              <FormSelect
                name='communication'
                value={selectedCommunication}
                options={communicationOptions}
                label={i18n.t('clients.advertising.communication')}
                onChange={(value) => onChange(value, 'communication')}
              />

              <FormSelect
                name='region'
                value={selectedRegion}
                options={regionOptions}
                label={i18n.t('face.edit.region')}
                onChange={(value) => onChange(value, 'region')}
              />

              <div className={'popup-form__row'}>
                <div className={'popup-form__row-title'}>
                  {i18n.t('clients.advertising.targeting')}
                </div>
                <Input
                  value={formFace.targeting}
                  onChange={(value) => onChange(value, 'targeting')}
                />
              </div>

              <div className={'popup-form__row'}>
                <div className={'popup-form__row-title'}>
                  {i18n.t('clients.advertising.sponsorship')}
                </div>
                <Checkbox checked={formFace.sponsorship}
                  onChange={(value) => {
                    onChange(value, 'sponsorship')
                  }} />
              </div>

              <div className={'popup-form__row face-code'}>
                <div className={'popup-form__row-title'}>
                  {i18n.t('clients.advertising.code')}
                </div>
                <div className={'flex-spread'}>
                  <Input
                    className={'face-code__input ' + (
                      ((formFace.hpa && formFace.hpa['@id']) || formFace.hpaId)
                        ? ''
                        : 'error')}
                    value={formFace.hpa && formFace.hpa['@id'] ? formFace.hpa['@id'].split('/').pop() : (formFace.hpaId || '')}
                    disabled={true}
                  />
                </div>
                <span className={'face-code__error'}>
                {getCodeError}
              </span>
              </div>

              <div className={'popup-form__row unit-fields'}>
                <div className={'unit-field'}>
                  <div className='popup-form__row-title'>
                    {i18n.t('clients.advertising.unit')}
                  </div>
                  <span className="input-wrap">
                <Select
                  className="react-select-container select-pseudo-input small"
                  classNamePrefix="react-select"
                  placeholder={i18n.t('periods.face.select')}
                  options={unitOptions}
                  value={selectedUnit}
                  onChange={(value) => onUnitChange(value)}
                />
              </span>
                </div>

                <div className={'unit-field'}>
                  <div className={'popup-form__row-title'}>
                    {i18n.t('clients.advertising.unitCost')}
                  </div>
                  <FloatInput
                    className={'unit-cost'}
                    value={(formFace && formFace.faceUnits && formFace.faceUnits[0]) ? formFace.faceUnits[0].unitCost : ''}
                    onChange={(value) => onUnitCostChange(value)}
                    disabled={!(formFace && formFace.faceUnits && formFace.faceUnits[0])}
                  />
                </div>
              </div>

              <RatioBlock
                ratios={coefficientRatios}
                title={i18n.t('clients.advertising.ratio')}
                onRatioChange={onRatioChange}
                formFace={formFace}
                inputsTitle={i18n.t('clients.advertising.ratio_coefficients')}
                value={getTotalCoefficient()}
              />

              <AffinitiesBlock
                affinities={formFace.affinities}
                title={i18n.t('clients.advertising.affinities')}
                onChange={onAffinityChange}
                formFace={formFace}
              />

              <AffinitiesBlock
                affinities={formFace.cppps}
                title={i18n.t('clients.advertising.cppps')}
                onChange={onCpppChange}
                formFace={formFace}
              />

              <QualitiesBlock
                qualities={formFace.qualities}
                title={i18n.t('clients.advertising.qualities')}
                onChange={onQualityChange}
                formFace={formFace}
              />

              <DurationSplitsBlock
                durationSplits={formFace.durationSplits}
                title={i18n.t('clients.advertising.durations_splits')}
                onChange={onDurationSplitsChange}
                formFace={formFace}
              />

              <RatioBlock
                ratios={discountRatios}
                title={i18n.t('clients.advertising.ratio_discount')}
                onRatioChange={onRatioChange}
                formFace={formFace}
                inputsTitle={i18n.t('clients.advertising.ratio_discounts')}
                value={getTotalDiscount()}
              />

              <FormSelect
                name='contractor'
                value={selectedContractor}
                options={contractorOptions}
                label={i18n.t('clients.advertising.contractor')}
                onChange={(value) => onChange(value, 'contractor')}
              />
            </div>
            <div className={'popup-form__buttons'}>
              <button className={"popup-form__button button-accent " + (!formFace.hpaIri ? 'disabled' : '')} onClick={save}>
                Сохранить
              </button>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default TvForm
