import React, {useState} from 'react';
import RatioRow from "./RatioRow";

function RatioBlock({ratios, onRatioChange, formFace, title, value}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'popup-form__row-title'}>{title}</div>
      <div className={'ratios-wrapper'}>
        <div className={'ratio-sum ratio-toggle'} onClick={() => setIsOpen(!isOpen)}>
          <span className={'ratio-sum--field'}>{value}</span>
          <span><i className={'fas ' + (isOpen ? 'fa-chevron-up' : 'fa-chevron-down')} /></span></div>
        <div>
          {ratios && ratios.length > 0 && isOpen &&
          <>
            <div>
              {ratios.map(ratio => (
                <RatioRow
                  key={ratio['@id']}
                  ratio={ratio}
                  formFace={formFace}
                  onRatioChange={onRatioChange}
                />
              ))}
            </div>
          </>
          }
        </div>
      </div>
    </div>
  )
}

export default RatioBlock;