import React from 'react';
import Popup from 'reactjs-popup';

import i18n from "i18next";

import 'reactjs-popup/dist/index.css';

function ModalWindow({close, open, className = '', children}) {
  return (
    <Popup
      open={open}
      modal
      onClose={close}
    >
      <div className={"modal-window flex " + className}>
        <a className="close" onClick={close}>
          &times;
        </a>
        <div className={'modal-window__wrapper'}>
          <div className={'modal-window__header'}>
            <div>{i18n.t('face_check.check_popup_title')}</div>
          </div>
          <div className="modal-window__body">
            {
              children
            }
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default ModalWindow;