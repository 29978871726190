import React, {Component} from 'react';
import {getSortParams, getFiltersQuery} from '../../utils';
import {api} from '../../api/apiProvider'
import {Link} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import {cloneDeep} from 'lodash';
import i18n from "i18next";
import ReactPaginate from "react-paginate";
import TopMenu from "../../Components/TopMenu";
import Filter from "../../Components/Filter/Filter";
import AdvertTable from "./AdvertTable";
import apiUrls from "../../ApiUrls";
import Protected from "../../Components/Roles/Protected";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisings: [],
      budgetChanges: {},
      companies: [],
      channels: [],
      products: [],
      managers: [],
      pools: [],
      types: [],
      companyRole: {},
      companyId: '',
      projectsTotalCount: 0,
      perPage: 30,
      page: 1,
      availableCompanies: [],
      availableCompaniesId: [],
      advertisingsFacesBudgetSums: {},

      filter: {
        id: '',
        name: '',
        company: '',
        channel: '',
        product: '',
        manager: '',
        type: '',
        pool: '',
        dateStart: '',
        dateEnd: '',
        status: '',
        dateStatus: '',
      },

      typeTimeOut: null,

      filterDateFormat: 'YYYY-MM-DD',
      selectedFilterDateStatus: '',
      sort: {},
      showLoader: false,
      ascSortOrder: 'asc',
      descSortOrder: 'desc',
      manualStatus: 'manual',
      autoStatus: 'auto',

      passedStatus: 'passed',
      currentStatus: 'current',
      upcomingStatus: 'upcoming',
      allowedChannelCodes: ['digital', 'internet', 'tv']
    }
  }

  componentWillMount = async () => {
    await api.get(apiUrls.get.clientRoles()).then((response) => {
      let roles = response['hydra:member'];

      if (roles[0]) {
        this.setState({companyRole: roles[0]})
      }
    });

    this.getCompanies()
    this.getChannels()
    this.getTypes()
    this.getPools()
    this.getManagers()

    if (this.state.companyRole.id) {
      api.get(apiUrls.get.companies()).then((data) => {
        const companies = data['hydra:member']
        const companiesId = companies.map((company) => {
          return company.id
        })
        this.setState({availableCompanies: companies})
        this.setState({availableCompaniesId: companiesId})
      })
    }
  }

  getCompanies = async () => {
    let companiesUrl = apiUrls.get.clients();

    const params = new URLSearchParams();

    if (this.state.filter.pool && this.state.filter.pool.length) {
      for (let pool of this.state.filter.pool) {
        if (pool.value) {
          params.append('pool.id[]', pool.value)
        }
      }
    }

    companiesUrl += '&' + params.toString()

    await api.get(companiesUrl).then(async (data) => {
      const companies = data['hydra:member']
      this.setState({companies})
    })
  }

  getChannels = async () => {
    await api.get(apiUrls.get.channels()).then(async (data) => {
      const channels = data['hydra:member']
      this.setState({channels})
    })
  }

  getTypes = async () => {
    await api.get(apiUrls.get.advertTypes()).then(async (data) => {
      const types = data['hydra:member']
      this.setState({types})
    })
  }

  getPools = async () => {
    await api.get(apiUrls.get.pools()).then(async (data) => {
      const pools = data['hydra:member']
      this.setState({pools})
    })
  }

  getManagers = async () => {
    api.get(`${apiUrls.get.managers()}&order[lastName]=asc&order[firstName]=asc`).then((data) => {
      this.setState({managers: data['hydra:member']});
    })
  }

  getProducts = async () => {
    if (!this.state.filter.company || !this.state.filter.company.value) {
      this.setState({products: []})
      return
    }

    let productsUrl = apiUrls.get.products();

    const params = new URLSearchParams();
    params.append('pagination', 'false')
    params.append('company.id[]', this.state.filter.company.value)

    productsUrl += '?' + params.toString()

    await api.get(productsUrl).then((data) => {
      const products = data['hydra:member']

      if (!products.length) {
        const filter = cloneDeep(this.state.filter)
        filter.product = ''

        this.setState({filter})
      }

      this.setState({products})
    })
  }

  async getFacesBudget() {
    let channelAdvertisingIds = {}

    for (let advertising of this.state.advertisings) {
      if (advertising.targets && Array.isArray(advertising.targets)) {
        for (let target of advertising.targets) {
          if (target.channel && target.channel.code && this.state.allowedChannelCodes.includes(target.channel.code)) {
            if (!channelAdvertisingIds[target.channel.code]) {
              channelAdvertisingIds[target.channel.code] = []
            }

            channelAdvertisingIds[target.channel.code].push(advertising.id)
          }
        }
      }
    }

    Promise.all(Object.keys(channelAdvertisingIds)
      .map(code => api.get(apiUrls.get.advertisingsFacesBudget(code, channelAdvertisingIds[code]))))
      .then(values => {
        let advertisingsFacesBudgetSums = {}

        for (let channelValues of values) {
          if (channelValues.items && channelValues.items['hydra:member']) {
            for (let advertising of channelValues.items['hydra:member']) {
              if (!advertisingsFacesBudgetSums[advertising.advertising]) {
                advertisingsFacesBudgetSums[advertising.advertising] = 0
              }

              if (advertising.data && advertising.data.budget) {
                advertisingsFacesBudgetSums[advertising.advertising] += Math.round((Number(advertising.data.budget) + Number.EPSILON) * 100) / 100
              }
            }
          }
        }

        this.setState({advertisingsFacesBudgetSums});
      })
  }

  setStatus = (advertisings) => {
    let currentDate = new moment()

    for (let advert of advertisings) {
      const advertStartDate = moment(advert.dateStart)
      const advertEndDate = moment(advert.dateEnd)

      if (advertEndDate < currentDate) {
        advert.status = this.state.passedStatus
      } else if (advertStartDate > currentDate) {
        advert.status = this.state.upcomingStatus
      } else if (advertStartDate < currentDate && advertEndDate > currentDate) {
        advert.status = this.state.currentStatus
      }
    }
  }

  setFilterQuery = () => {
    let query = ''

    for (let filterName in this.state.filter) {
      if (this.state.filter.hasOwnProperty(filterName) && this.state.filter[filterName]) {
        switch (filterName) {
          case 'id':
          case 'name':
            query += '&' + filterName + '=' + this.state.filter[filterName]
            break;

          case 'company':
          case 'product':
          case 'type':
            if (this.state.filter[filterName].value) {
              query += '&' + filterName + '.id=' + this.state.filter[filterName].value
            }

            break
          case 'manager':
            if (this.state.filter[filterName].value) {
              if (this.state.filter[filterName].value === 'withoutManager') {
                query += '&exists[manager]=false'
              } else {
                query += '&' + filterName + '.id=' + this.state.filter[filterName].value
              }
            }

            break;
          case 'channel':
            if (this.state.filter[filterName].value) {
              query += '&targets.' + filterName + '.id=' + this.state.filter[filterName].value
            }

            break;
          case 'pool':
            if (this.state.filter[filterName] && this.state.filter[filterName].length) {
              for (let item of this.state.filter[filterName]) {
                if (item.value) {
                  query += '&company.' + filterName + '.id[]=' + item.value
                }
              }
            }

            break;
          case 'dateStart':
            query += '&' + filterName + '[after]=' + this.state.filter[filterName]
            break;
          case 'dateEnd':
            query += '&' + filterName + '[before]=' + this.state.filter[filterName]
            break;
          case 'status':
            if (this.state.filter[filterName] === this.state.manualStatus) {
              query += '&budget[gt]=0'
            } else if (this.state.filter[filterName] === this.state.autoStatus) {
              query += '&budget[lte]=0'
            }

            break;
          case 'dateStatus':
            let date = moment().format(this.state.filterDateFormat)

            switch (this.state.filter[filterName]) {
              case this.state.passedStatus:
                query += '&dateEnd[strictly_before]=' + date
                break;
              case this.state.currentStatus:
                query += '&dateStart[before]=' + date + '&dateEnd[after]=' + date
                break;
              case this.state.upcomingStatus:
                query += '&dateStart[strictly_after]=' + date
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }

    return query
  }

  updateData = async () => {
    this.showLoader()
    let url = ''

    if (this.state.companyId) {
      url = apiUrls.get.adverts() + "?t[]=advert_list&company.id=" + this.state.companyId + '&page=' + this.state.page
    } else {
      url = apiUrls.get.adverts() + "?t[]=advert_list&company.role.id=" + this.state.companyRole.id + '&page=' + this.state.page
    }

    url += this.setFilterQuery()

    url += '&' + getSortParams(this.state.sort).toString()

    api.get(url).then((data) => {
      const advertisings = data['hydra:member'];

      this.setStatus(advertisings)

      this.setState({advertisings: advertisings});
      this.setState({projectsTotalCount: data['hydra:totalItems']})
      this.getFacesBudget()
      this.hideLoader()
    }).catch(() => {
      this.hideLoader()
    })
  }

  deleteAdvertisings = async (advertisingid) => {
    try {
      if (window.confirm(i18n.t('periods.face.are_you_sure'))) {
        await api.delete(apiUrls.get.advertising(advertisingid));
        this.updateData();
        toast.success(i18n.t('clients.edit.advert_deleted'));
      }
    } catch (error) {
      toast.error(i18n.t('clients.edit.delete_fail'))
    }
  }

  showLoader = () => {
    this.setState({showLoader: true})
  }

  hideLoader = () => {
    this.setState({showLoader: false})
  }

  /*onBudgetChange = (value, advertisingid) => {
    const budgetChanges = cloneDeep(this.state.budgetChanges);
    budgetChanges[advertisingid] = parseFloat(value);
    this.setState({budgetChanges: budgetChanges});
  }*/

  /*deleteBudgetChange = (key) => {
    const budgetChanges = cloneDeep(this.state.budgetChanges)

    if (budgetChanges.hasOwnProperty(key)) {
      delete(budgetChanges[key])
    }

    this.setState({budgetChanges: budgetChanges});
  }*/

  updateAdvert = (advertId, budget) => {
    const adverts = cloneDeep(this.state.advertisings)

    for (let advert of adverts) {
      if (advert.id === advertId) {
        advert.budget = budget
      }
    }

    this.setState({advertisings: adverts})
  }

  /*onBudgetSave = async () => {
    const budgetChanges = this.state.budgetChanges

    for (const key in budgetChanges) {
      if (budgetChanges.hasOwnProperty(key)) {
        const newBudget = budgetChanges[key];

        const body = {
          budget: newBudget
        }

        try {
          const response = await api.patch(apiUrls.get.advertising(key), body)

          toast.success(i18n.t('clients.edit.budget_changed'))

          if (response.id && response.budget) {
            this.updateAdvert(response.id, response.budget)
          }

        } catch (error) {
          toast.error(i18n.t('clients.edit.save_fail'))
        }
      }

      this.deleteBudgetChange(key)
    }
  }*/

  handlePageClick = (data) => {
    if (data.selected !== undefined) {
      this.setState({page: (+data.selected + 1)}, () => {
        this.updateData()
      })
    }
  }

  /*onClickSave = () => {
    this.onBudgetSave();
  }*/

  updateDataWithoutPagination = () => {
    this.setState({page: 1}, () => {
      this.updateData()
    })
  }

  setSort = (sort) => {
    let sortState = this.state.sort

    this.setState({sort}, () => this.goToFilters());
  }

  onSortClick = async (name) => {
    this.showLoader()

    let sortParams = cloneDeep(this.state.sort)

    if (this.state.sort[name] === this.state.ascSortOrder) {
      sortParams[name] = this.state.descSortOrder
    } else if (this.state.sort[name] === this.state.descSortOrder) {
      delete sortParams[name]
    } else {
      sortParams[name] = this.state.ascSortOrder
    }

    await this.setSort(sortParams)

    this.updateData()
  }

  setPage = (page) => {
    this.setState({
      page
    })
  }

  setFilter = (filter, callback) => {
    this.setState({
      filter
    }, () => {
      if (callback && typeof callback === 'function') {
        callback()
      }

      if (this.state.typeTimeOut) {
        clearTimeout(this.state.typeTimeOut)
      }

      const typeTimeOut = setTimeout(() => {
        this.goToFilters()
      }, 500)

      this.setState({
        typeTimeOut
      })
    })
  }

  goToFilters = () => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: getFiltersQuery(this.state.filter, this.state.page, this.state.sort)
    })
  }

  getFilterOptions = (productOptions, companyOptions, channelOptions, poolOptions, typeOptions, managerOptions) => {
    let filter = this.state.filter

    return [
      {
        type: 'text',
        value: filter.id,
        placeholder: i18n.t('clients.page.id'),
        name: 'id',
        className: 'has-separator has-search-icon'
      },
      {
        type: 'text',
        value: filter.name,
        placeholder: i18n.t('clients.page.name'),
        name: 'name',
        className: 'has-separator'
      },
      {
        type: 'dropdown',
        value: filter.pool,
        placeholder: i18n.t('clients.page.pool'),
        options: poolOptions,
        name: 'pool',
        className: 'has-separator pointer',
        callback: this.getCompanies,
        isMulti: true,
      },
      {
        type: 'dropdown',
        value: filter.company,
        placeholder: i18n.t('clients.page.company'),
        options: companyOptions,
        name: 'company',
        className: 'has-separator pointer',
        callback: this.getProducts
      },
      {
        type: 'dropdown',
        value: filter.product,
        placeholder: i18n.t('clients.page.product'),
        options: productOptions,
        name: 'product',
        className: 'has-separator pointer'
      },
      {
        type: 'dropdown',
        value: filter.channel,
        placeholder: i18n.t('clients.advertising.channel'),
        options: channelOptions,
        name: 'channel',
        className: 'has-separator pointer'
      },
      {
        type: 'dropdown',
        value: filter.type,
        placeholder: i18n.t('clients.advertising.type'),
        options: typeOptions,
        name: 'type',
        className: 'has-separator pointer'
      },
      {
        type: 'dropdown',
        value: filter.manager,
        placeholder: i18n.t('clients.advertising.manager'),
        options: managerOptions,
        name: 'manager',
        className: 'has-separator pointer'
      },
      {
        type: 'date',
        value: filter.dateStart,
        placeholder: i18n.t('clients.advertising.date_start'),
        name: 'dateStart',
        //callback: self.updateDataWithoutPagination,
        format: i18n.t('calendar_date_format'),
        filterFormat: this.state.filterDateFormat,
        className: 'has-separator pointer'
      },
      {
        type: 'date',
        value: filter.dateEnd,
        placeholder: i18n.t('clients.advertising.date_end'),
        name: 'dateEnd',
        //callback: self.updateDataWithoutPagination,
        format: i18n.t('calendar_date_format'),
        filterFormat: this.state.filterDateFormat,
        className: 'pointer'
      },
    ]
  }

  render() {
    const productOptions = this.state.products
      ? this.state.products.map((product) => {
          return {value: product.id, label: product.name}
        })
      : []

    const emptyOption = {
      label: i18n.t('projects.list.not_selected'),
      value: ''
    }

    productOptions.unshift(emptyOption)

    const companyOptions = this.state.companies
      ? this.state.companies.map((company) => {
          return {value: company.id, label: company.name}
        })
      : []

    const channelOptions = this.state.channels
      ? this.state.channels.map((channel) => {
        return {value: channel.id, label: channel.name}
      })
      : []

    const poolOptions = this.state.pools
      ? this.state.pools.map((pool) => {
        return {value: pool.id, label: pool.name}
      })
      : []

    const typeOptions = this.state.types
      ? this.state.types.map((type) => {
        return {value: type.id, label: type.name}
      })
      : []

    const managerOptions = this.state.managers
      ? this.state.managers.map((manager) => {
        return {value: manager.id, label: manager.name}
      })
      : []

    companyOptions.unshift(emptyOption)
    channelOptions.unshift(emptyOption)
    poolOptions.unshift(emptyOption)
    typeOptions.unshift(emptyOption)
    managerOptions.unshift({
      label: i18n.t('clients.advertising.without_manager'),
      value: 'withoutManager',
    })
    managerOptions.unshift(emptyOption)

    let filters = this.getFilterOptions(productOptions, companyOptions, channelOptions, poolOptions, typeOptions, managerOptions)

    return (
      <div className="row content">
        <div className="edit-client">
          <div className="table-edit-client advertising-table advertising-main-table">
            <TopMenu title={i18n.t('clients.edit.advertisings')}>
              <Protected access={'AdvertisingsEdit'}>
                <div className={'adverts-menu-header'}>
                  <div>
                    <Link className="blue-cursor-button" to={"/advertisings/new" +
                    ((this.state.companyId && this.state.availableCompaniesId.includes(parseInt(this.state.companyId))) ? '/?company_id=' + this.state.companyId : '')}>
                      <i className="fal fa-plus mr-for-icon" />
                      {i18n.t('clients.edit.add_new')}
                    </Link>

                    <Link className="get-xls-button"
                      to={'/advertisings/upload-xls'}>
                      {i18n.t('advertising.list.upload_digital_plan')}
                    </Link>
                  </div>
                </div>
              </Protected>

            </TopMenu>

            {this.state.products && this.state.companies &&
              <Filter
                filters={filters}
                filter={this.state.filter}
                setPage={this.setPage}
                setSort={this.setSort}
                setFilter={this.setFilter}
                location={this.props.location}
                getData={this.updateData}
              />
            }

            {this.state.showLoader &&
              <div className={'loader-background'} />
            }

            <div className={'loader' + (this.state.showLoader ? '' : ' hidden')}>
              <i className="fa fa-refresh fa-spin" />
            </div>

            <AdvertTable
              advertisings={this.state.advertisings}
              sort={this.state.sort}
              ascSortOrder={this.state.ascSortOrder}
              descSortOrder={this.state.descSortOrder}
              onSortClick={this.onSortClick}
              /*budgetChanges={this.state.budgetChanges}*/
              /*onBudgetChange={this.onBudgetChange}*/
              deleteAdvertisings={this.deleteAdvertisings}
              advertisingsFacesBudgetSums={this.state.advertisingsFacesBudgetSums}
            />

          </div>
        </div>
        {(this.state.projectsTotalCount > 0 && this.state.projectsTotalCount > this.state.perPage) &&
        <ReactPaginate
          previousLabel={i18n.t('projects.list.prev')}
          nextLabel={i18n.t('projects.list.next')}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(this.state.projectsTotalCount / this.state.perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          forcePage={this.state.page - 1}
        />
        }

      </div>
    );
  }
}

export default List;
