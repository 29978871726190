import React, {useState} from 'react';
import i18n from "i18next";
import {toast} from "react-toastify";
import FileInput from "../../../../Components/Inputs/FileInput";
import xlsServiceProvider from "../../../../api/xlsServiceProvider";
import TopMenu from "../../../../Components/TopMenu";
import responseStatuses from "../../../../api/responseStatuses";
import Loader from "../../../../Components/Loader/Loader";
import AdvertisingsList from "./AdvertisingsList";
import AdvertisingMatchingErrors from "./AdvertisingMatchingErrors";
import AdvertisingWritingErrors from "./AdvertisingWritingErrors";

const uploadDataMatchingErrors = 'Ошибки соответствия данных'
const uploadDataWritingErrors = 'Ошибки во время записи данных'

function UploadFinancialMonthFactXls() {
  const [fileInput, setFileInput] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [uploadResponse, setUploadResponse] = useState(null)
  const save = () => {
    setUploadResponse(null)
    const formData = new FormData()

    if (!selectedFile) {
      toast.error(i18n.t('advertising.list.select_file'))
      return
    }

    formData.append('file', selectedFile, selectedFile.name)

    const request = xlsServiceProvider.postMonthFact;

    if (!request) {
      toast.error(i18n.t('server_error'));
    }

    setIsLoading(true)

    request(formData).then(response => {
      if (response.ok) {
        response.json()
          .then(data => {
            if (data.message && data.report) {
              setUploadResponse(data)
            }
          })
      }

      if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
        toast.error(i18n.t('invoice.edit.get_xls_error'));
      }

    }).catch((e) => {
      toast.error(i18n.t('server_error'));
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className="row content">
      <div className="edit-client">
        <div className="table-edit-client advertising-table advertising-main-table">
          <TopMenu title={i18n.t('financial.upload_month_fact')}>
            <div className={'adverts-menu-header'}>

            </div>
          </TopMenu>
          <div className='popup-form__rows'>
            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('documents.edit.file')}:
              </div>
              <FileInput
                setFileInput={setFileInput}
                value={(selectedFile && selectedFile.name) ? selectedFile : ''}
                onChange={(value) => {
                  if (value && value[0]) {
                    setSelectedFile(value[0])
                  }
                }}
              />
            </div>

          </div>
          <div className={'popup-form__buttons xls-upload-buttons'}>
            <button className="popup-form__button button-accent"
                    onClick={save}
                    disabled={isLoading}
            >
              {i18n.t('documents.edit.save')}
            </button>
            {isLoading &&
              <Loader/>
            }
          </div>

          {uploadResponse &&
            <div className={'upload-data-wrapper'}>
              <div className={'upload-data-wrapper__message'}>
                {uploadResponse.message}
              </div>
              {uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Created &&
                <AdvertisingsList
                  title={i18n.t('financial.created') + ':'}
                  advertisings={uploadResponse.report.success.Created}
                />
              }
              {uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Updated &&
                <AdvertisingsList
                  title={i18n.t('financial.updated') + ':'}
                  advertisings={uploadResponse.report.success.Updated}
                />
              }

              {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataMatchingErrors] &&
                <AdvertisingMatchingErrors
                  title={uploadDataMatchingErrors + ':'}
                  errors={(Object.entries(uploadResponse.report.errors[uploadDataMatchingErrors]))}
                />
              }

              {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataWritingErrors] &&
                <AdvertisingWritingErrors
                  title={uploadDataWritingErrors + ':'}
                  errors={uploadResponse.report.errors[uploadDataWritingErrors]}
                />
              }
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default UploadFinancialMonthFactXls
