import React, {useState, useEffect} from 'react'
import i18next from 'i18next'
import {Link} from "react-router-dom";
import FormattedNumber from "../../Components/FormattedNumber";
import {getTaxedValue} from "../../utils";
import Protected from "../../Components/Roles/Protected";

const defaultTax = 20

function FaceTable(props) {
  const [totalBudget, setTotalBudget] = useState(0)
  const [totalSpent, setTotalSpent] = useState(0)
  const [totalExpenses, setTotalExpenses] = useState(0)
  const [totalSpentDelta, setTotalSpentDelta] = useState(0)
  const [totalBudgetDelta, setTotalBudgetDelta] = useState(0)

  const [totalExpensesTaxed, setTotalExpensesTaxed] = useState(0)

  useEffect(() => {
    if (props.rows.length) {
      calcFields()
    }
  }, [props.rows])

  const calcFields = () => {
    let totalBudget = 0
    let totalSpent = 0
    let totalExpenses = 0
    let totalExpensesTaxed = 0

    for (let row of props.rows) {
      if (row.budget) {
        totalBudget += row.budget
      }

      if (row.spent) {
        totalSpent += row.spent
      }

      if (row.expenses) {
        totalExpenses += row.expenses
        row.expensesTaxed = getTaxedValue(row.expenses, row.tax.tax || defaultTax)
        totalExpensesTaxed += row.expensesTaxed
      }

      row.spentDelta = (row.expenses - row.spent)
      row.budgetDelta = (row.expenses - row.budget)
    }

    setTotalBudget(totalBudget)
    setTotalSpent(totalSpent)
    setTotalExpenses(totalExpenses)
    setTotalExpensesTaxed(totalExpensesTaxed)

    const totalSpentDelta = totalExpenses - totalSpent
    const totalBudgetDelta = totalExpenses - totalBudget

    setTotalSpentDelta(totalSpentDelta)
    setTotalBudgetDelta(totalBudgetDelta)
  }

  return (
    <table className="table redesign-table">
      <thead>
      <tr>
        <th>#</th>
        <th>{i18next.t('face.list.product')}</th>
        <th>{i18next.t('face.list.channel')}</th>
        <th>{i18next.t('face.list.budget_id')}</th>
        <th>{i18next.t('face.list.closing')}</th>
        <th>{i18next.t('face.list.face')}</th>
        <th>{i18next.t('face.list.work')}</th>
        <th>{i18next.t('face.list.contractor')}</th>
        <th>{i18next.t('face.list.tax')}</th>
        <th>{i18next.t('face.list.client_budget_plan_without_vat')}</th>
        <th>{i18next.t('face.list.client_budget_fact_without_vat')}</th>
        <th>{i18next.t('face.list.budget_by_documents_without_vat')}</th>
        <th>{i18next.t('face.list.budget_by_documents_with_vat')}</th>
        <th>{i18next.t('face.list.delta_plan')}</th>
        <th>{i18next.t('face.list.delta_fact')}</th>
      </tr>
      </thead>
      <tbody>
      {props.rows.map((row, index) => (
        <tr key={index}>
          <td>{row.id}</td>
          <td>{row.advertising.product.name || ''}</td>
          <td>{row.channel.name || ''}</td>
          <td>{row.advertising.budgetCode || ''}</td>
          <td>
            <Link to={`/advertisings/${row.advertising.id}`}>
              {row.advertising.nameFinancial}
            </Link>
          </td>
          <td>
            {row.faces.map(face => (
              <div key={face.name}>{face.name || ''}</div>
            ))}
          </td>
          <td>{row.work.name || ''}</td>
          <td>
            <Link to={`/client/${props.companyId}/legal/${row.contractor.id}`}>
              {row.contractor.name || ''}
            </Link>
          </td>
          <td>{row.tax.name || ''}</td>
          <td>
            <FormattedNumber
              value={row.budget}  type={'float'} />
          </td>
          <td>
            <FormattedNumber
              value={row.spent}  type={'float'} />
          </td>
          <td>
            <Link to={`/registry/${row.registry.id}`}>
              <FormattedNumber
                value={row.expenses}  type={'float'} />
            </Link>
          </td>
          <td>
            <FormattedNumber
              value={row.expensesTaxed}  type={'float'} />
          </td>
          <td>
            <FormattedNumber
              value={row.budgetDelta}  type={'float'} />
          </td>
          <td>
            <FormattedNumber
              value={row.spentDelta}  type={'float'} />
          </td>
          <td>
            <Protected access={'FinancialInvoicesEdit'}>
              <button onClick={() => props.deleteRow(row.id)}>
                <i className="fal fa-trash-alt icon-delete" />
              </button>
            </Protected>
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan={9}>
          {i18next.t('face.list.faces_total')}
        </td>
        <td>
          <FormattedNumber
            value={totalBudget}  type={'float'} />
        </td>
        <td>
          <FormattedNumber
            value={totalSpent}  type={'float'} />
        </td>
        <td>
          <FormattedNumber
            value={totalExpenses}  type={'float'} />
        </td>
        <td>
          <FormattedNumber
            value={totalExpensesTaxed}  type={'float'} />
        </td>
        <td>
          <FormattedNumber
            value={totalBudgetDelta}  type={'float'} />
        </td>
        <td>
          <FormattedNumber
            value={totalSpentDelta}  type={'float'} />
        </td>
        <td></td>
      </tr>
      <tr>
        <th colSpan={11}>
          {i18next.t('face.list.invoice_total')}
        </th>
        <th >
          <FormattedNumber
            value={totalExpenses}  type={'float'} />
        </th>
        <th colSpan={4} />
      </tr>
      </tbody>
    </table>
  )
}

export default FaceTable;
