import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {Route} from 'react-router-dom'
import Header from "../Components/header/Header";

function PrivateRoute({comp: Component, ...rest}) {
  const {keycloak} = useKeycloak();

  const isAuthorized = () => {
    return keycloak.authenticated && keycloak.token
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized() ? (
          <>
            <Header onLogoutSuccess={() => keycloak.logout()} {...props}/>
            <Component {...props} />
          </>
        ) : (
          '<div></div>'
        )
      }
    />
  );
}

export default PrivateRoute;