import React from 'react';
import Face from "./Face";
import useDelete from "../../../../Components/Hooks/useDelete";
import i18n from "i18next";
import LoaderBackdrop from "../../../../Components/Loader/LoaderBackdrop";

export default function UnusedFaces({isLoading, channelEntity, advertisingId, setSelectedFace, faces, getAdvertFaces, addFaceToDetails, plan}) {
  const getDeleteFaceUrl = (id) => {
    if (channelEntity) {
      return channelEntity.getApiUrlWithId('faces', id)
    }
  }

  const addNewFace = () => {
    const newFace = {
      code: '',
      formatText: '',
      name: '',
      targeting: '',
      advertising: {
        '@id': `/adverts/${advertisingId}`,
        id: advertisingId,
      },
      platform: {},
      communication: {},
      channel: {},
      source: {},
      strategy: {},
      type: {
        formats: []
      },
      formats: [],
      faceUnits: [],
    }

    setSelectedFace(newFace)
  }

  const deleteFace = useDelete(getDeleteFaceUrl)

  const isInPlan = face => {
    for (let interval of plan) {
      for (let planFace of interval.data) {
        if (planFace.item['@id'] === face['@id']) {
          return true
        }
      }
    }

    return false
  }

  return (
    <div className={'period-faces advert-plan-faces '  + (isLoading ? ' loading' : '')}>
      <div>
        <h2>{i18n.t('clients.advertising.faces')}</h2>
        <a
          className="blue-cursor-button add-link"
          onClick={addNewFace}
        >
          <i className="fal fa-plus mr-for-icon" />
          {i18n.t('clients.advertising.add_platform')}</a>
      </div>
      <div className={'period-faces__items'}>
        {faces && faces.filter(face => !isInPlan(face)).map(face => (
          <Face
            key={face['@id']}
            face={face}
            deleteFace={deleteFace}
            getAdvertFaces={getAdvertFaces}
            setSelectedFace={(face) => {
              setSelectedFace(face)
            }
            }
            addFaceToDetails={addFaceToDetails}
            //isDigital={props.isDigital}
            //isInternet={props.isInternet}
            channelEntity={channelEntity}
          />
        ))}
      </div>
      {isLoading &&
        <LoaderBackdrop />
      }
    </div>
  )
}