import {cloneDeep} from "lodash";
import {EventSourcePolyfill} from 'event-source-polyfill';
import moment from 'moment'
import i18next from "i18next";
import {toastSubscribeResult} from "../../../subscribe";
import {getFieldValue} from "../../../utils";
import keycloak from "../../../keycloak";

const EventSource = EventSourcePolyfill;

const dateFormat = 'DD-MM-Y'

const getFaceData = face => {
  let data = face

  if (face['@id']) {
    data.key = face['@id']
  }

  if (data.spent) {
    data.budget = data.spent
  }

  return data
}

const getFactDataValuesToSave = (face, detailFields) => {
  let data = {}

  for (let field of detailFields) {
    if (face.hasOwnProperty(field.code) && face[field.code].fact !== undefined) {
      data[field.code] = field.type === 'float'
        ? parseFloat(getFieldValue(face[field.code].fact))
        : getFieldValue(face[field.code].fact)
    }
  }

  return data
}

const getWeekPeriods = (dateStart, dateEnd) => {
  const dates = []
  dateStart = getUtcDate(dateStart, dateFormat)
  dateEnd = getUtcDate(dateEnd, dateFormat)

  let startDate = dateStart

  while (startDate <= dateEnd) {
    let dateEnd = cloneDeep(startDate).endOf('week').add(1, 'day')
    let monthEnd = cloneDeep(dateStart).endOf('month')

    dates.push({
      dateStart: startDate.toISOString(),
      dateEnd: dateEnd.toDate() > monthEnd.toDate()
        ? monthEnd.startOf('day').toISOString()
        : dateEnd.startOf('day').toISOString()
    })

    startDate = dateEnd.add(1, "day")
  }

  return dates
}

const getDayPeriods = (dateStart, dateEnd) => {
  const dates = []
  dateStart = getUtcDate(dateStart, dateFormat)
  dateEnd = getUtcDate(dateEnd, dateFormat)

  while (dateStart <= dateEnd) {
    let dateEnd = cloneDeep(dateStart)

    dates.push({
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString()
    })

    dateStart = dateEnd.add(1, "day")
  }

  return dates
}

export const getIntervals = (startDate, endDate, unit = 'month') => {
  const dateStart = getFormattedDate(startDate, dateFormat)
  const dateEnd = getFormattedDate(endDate, dateFormat)

  switch (unit) {
    case 'month':
      return [{
        dateStart: getUtcDate(dateStart, dateFormat).toISOString(),
        dateEnd: getUtcDate(dateEnd, dateFormat).toISOString(),
      }]

    case 'week':
      return getWeekPeriods(dateStart, dateEnd)

    case 'day':
      return getDayPeriods(dateStart, dateEnd)

    default:
      return [{
        dateStart: getUtcDate(dateStart, dateFormat).toISOString(),
        dateEnd: getUtcDate(dateEnd, dateFormat).toISOString(),
      }]
  }
}

const isEqualIntervals = (compareInterval, interval) => {
  if (!compareInterval.dateStart || !compareInterval.dateEnd || !interval.dateStart || !interval.dateEnd) {
    return  false
  }

  return (getISODate(compareInterval.dateStart) >= interval.dateStart)
    && (getISODate(compareInterval.dateEnd) <= interval.dateEnd)
}

const getData = (period, data, detailFields, type) => {
  let periodEdit = cloneDeep(period)

  if (data && detailFields) {
    for (let dataInterval of data) {
      for (let interval of periodEdit) {
        if (isEqualIntervals(dataInterval, interval)) {
          if (dataInterval.data && dataInterval.data.length) {
            for (let face of dataInterval.data) {
              if (face && face.item) {
                const itemIri = (typeof face.item === 'object')
                  ? face.item['@id']
                  : face.item

                if (itemIri && interval && interval.faces[itemIri]) {
                  for (let field of detailFields) {
                    if (!interval.faces[itemIri][field.code]) {
                      interval.faces[itemIri][field.code] = {}
                    }

                    interval.faces[itemIri][field.code][type] = face.properties[field.code]
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return periodEdit
}

const setSums = (period) => {
  for (let intervalId in period) {
    let interval = period[intervalId]

    for (let faceId in interval.faces) {
      let face = interval.faces[faceId]

      for (let field in face) {
        let fieldValues = face[field]

        if (typeof fieldValues === 'object') {
          fieldValues.sum = 0

          if (fieldValues.fact) {
            fieldValues.sum += getFieldValue(fieldValues.fact)
          }

          if (fieldValues.correction) {
            fieldValues.sum += getFieldValue(fieldValues.correction)
          }
        }
      }
    }
  }
}

export const getFormPeriod = (intervals, plansPeriod, factData, advertFaces, detailFields) => {
  let periodEdit = intervals

  for (let interval of periodEdit) {
    interval.faces = cloneDeep(advertFaces)
  }

  periodEdit = getData(periodEdit, plansPeriod, detailFields, 'plan')
  periodEdit = getData(periodEdit, factData, detailFields, 'fact')

  //setSums(periodEdit)
  return periodEdit
}

export const getIntervalsToSave = (period, advertIri, detailFields, faceIri) => {
  let intervals = []

  for (let intervalKey in period) {
    let interval = period[intervalKey]
    if (!interval.faces) {
      continue
    }

    let correctionInterval = {}

    correctionInterval.dateStart = moment(interval.dateStart).format('Y-MM-DD')
    correctionInterval.dateEnd = moment.utc(interval.dateEnd).startOf('day').format('Y-MM-DD')

    correctionInterval.data = []

    for (let faceId in interval.faces) {
      if (faceIri && faceIri !== faceId) {
        continue
      }
      let detail = interval.faces[faceId]

      let face = {
        item: detail.key,
      }

      let isValue = false
      
      face = {
        ...face,
        properties: getFactDataValuesToSave(detail, detailFields)
      }

      for (let field in face) {
        if (face[field]) {
          isValue = true
        }
      }

      if (isValue) {
        correctionInterval.data.push(face)
      }
    }

    if (correctionInterval.data.length) {
      intervals.push(correctionInterval)
    }
  }

  return {intervals}
}

export const subscribeToAdvertMerge = (mediaplanId, onSuccess, onError) => {
  let eventSource = new EventSource(process.env.REACT_APP_MERCURE_SUBSCRIBE_URL + '?topic='
    + encodeURIComponent('/mediaplans/' + mediaplanId + '/merge-adverts'),
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      }
    });

  eventSource.onerror = () => {
    if (typeof onError === 'function') {
      onError()
    }
  }

  eventSource.onmessage = async event => {
    try {
      const result = JSON.parse(event.data);

      toastSubscribeResult(result)

      if (result && result.status === 'success') {
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }
    } catch (e) {
    }
  }
}

export const subscribeToAdvertisingUpdates = (id, onSuccess, onError) => {
  let eventSource = new EventSource(process.env.REACT_APP_MERCURE_SUBSCRIBE_URL + '?topic='
    + encodeURIComponent(`/adverts/${id}/pull`),
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      }
    });

  eventSource.onerror = () => {
    if (typeof onError === 'function') {
      onError()
    }
  }

  eventSource.onmessage = async event => {
    try {
      const result = JSON.parse(event.data);

      toastSubscribeResult(result)

      if (result && result.status === 'success') {
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }
    } catch (e) {
      if (typeof onError === 'function') {
        onError()
      }
    }
  }
}

export const getUtcDate = (dateString, format = '') => {
  const dateWithoutTime = moment.utc(dateString, format).format(dateFormat)

  return moment.utc(dateWithoutTime, dateFormat)
}

export const getFormattedDate = (dateString, format = i18next.t('date_format')) => {
  return moment.utc(dateString).format(format)
}

export const getISODate = date => {
  return getUtcDate(date).toISOString()
}