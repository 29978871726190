import React from 'react';
import i18n from "i18next";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

export default function TrackingSourceRow({source, keyOptions, values, onChange, isDisabled, onCreateValue = () => {}}) {
  const onKeyChange = (key) => {
    const value = Object.values(source)[0] || null

    onChange({
      [key]: value
    })
  }

  const onValueChange = (value) => {
    const key = Object.keys(source)[0] || null

    onChange({
      [key]: value
    })
  }

  const getOptions = () => {
    const options = []
    const selectedKey = Object.keys(source)[0]

    if (selectedKey && selectedKey in values) {
      for (let item of values[selectedKey]) {
        options.push({value: item, label: item})
      }
    }

    return options
  }

  const valuesOptions = getOptions()

  return (
    <div key={Object.keys(source).pop()} className={'datasource-item'}>
      <Select
        className="react-select-container select-pseudo-input small"
        classNamePrefix="react-select"
        placeholder={i18n.t('periods.face.select')}
        options={keyOptions}
        value={{
          value: Object.keys(source)[0],
          label: Object.keys(source)[0]
        }}
        onChange={value => onKeyChange(value.value)}
        isDisabled={isDisabled}
      />

      <CreatableSelect
        className="react-select-container select-pseudo-input small"
        classNamePrefix="react-select"
        placeholder={i18n.t('periods.face.select')}
        options={valuesOptions}
        value={{
          value: Object.values(source)[0],
          label: Object.values(source)[0]
        }}
        onChange={value => onValueChange(value.value)}
        onCreateOption={value => onCreateValue(Object.keys(source)[0], value)}
        isDisabled={isDisabled}
      />
    </div>
  )
}