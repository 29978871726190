import React, {useState} from 'react';
import NumberFormat from "react-number-format";
import i18n from "i18next";
import {getNumber} from "../../utils";

const noop = () => {}

function IntInput({value, isValid, onChange = noop, errorText = '', allowNegative = false, allowEmpty = false, ...rest}) {
  const val = getNumber(value, allowEmpty)

  const [thousandSeparator] = useState(' ')

  return (
    <span className={"input-wrap decimal-input" + ((isValid && isValid(val)) ? '' : ' has-error') + (val >= 0 ? '' : ' is-negative')}>
      <NumberFormat
        onValueChange={val => {
          value = (val.floatValue === undefined || val.value === ".00")
            ? ''
            : val.floatValue
          onChange(value)
        }}
        thousandsGroupStyle="thousand"
        value={val}
        decimalSeparator=","
        displayType="input"
        type="text"
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        decimalScale={2}
        isNumericString={true}
        {...rest}
      />

      {
        isValid && isValid(val) === false &&
        <span className="title-error">
           {errorText || i18n.t('errors.not_valid')}
        </span>
      }
    </span>
  );
}

export default IntInput;