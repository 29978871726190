import React, { Component } from 'react';
import { api } from '../../api/apiProvider';

class AutocompleteInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            inputValue: ""
        }
    }

    onChange = async (event) => {
        const value = event.target.value;
        if(value === ""){
            this.setState({data:[], inputValue:value});
        }
        else{
            this.setState({inputValue:value})
            const data = await api.get("/" + this.props.resource + "?" + this.props.filter + "=" + value);
            this.setState({ data: data['hydra:member'] });
        }        
    }
    render() {
        return (
            <div>
                <input type="text" value={this.state.inputValue} onChange={this.onChange} placeholder={this.props.placeholder}/>
                <div className="container-input">
                    {this.state.data.map((item, index) => (
                        <div className="input-result">
                            <div key={index} className="result" onClick={() => {
                                this.props.onSelect(item)
                                this.setState({data:[], inputValue:""});
                            }}>
                                {item[this.props.filter]}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default AutocompleteInput;