import { SUCCESS, ERROR } from './actionTypes';

export const initialState = {
  data: null,
  errors: null
}

const reducerPost = (state = initialState, {type, data} = {}) => {
  switch (type) {
    case SUCCESS:
      return { ...initialState, data: true, errors: null }
    case ERROR:
      return { ...initialState, errors: data }
    default:
      return initialState;
  }
}

export default reducerPost