import React from 'react';
import i18n from 'i18next'
import {logError} from '../utils'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      logError('js error- ' + error.message);
    } else {
      logError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <div
        className={this.props.className}
      >{this.props.errorText || i18n.t('errors.something_went_wrong')}</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary