import React, {useEffect, useState} from 'react'
import DatePicker from "react-datepicker";

function DateFilter({element, value, onChange}) {
  const [filterValue, setFilterValue] = useState(new Date())

  useEffect(() => {
    onChange(filterValue)
  }, [])

  useEffect(() => {
    if (value) {
      setFilterValue(value)
    }
  }, [value])

  return (
    <span className={'filter-item ' + (element.className || '')}>
      <DatePicker
        placeholderText={element.placeholder}
        selected={filterValue || ''}
        onChange={(value) => {
          onChange(value || '')
          setFilterValue(value)
        }}
        dateFormat={element.format}
        showMonthYearPicker={element.isMonth}
      /></span>
  )
}

export default DateFilter