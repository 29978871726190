import { LOADING, SUCCESS, ERROR } from './actionTypes';

export const initialState = {
  data: null,
  total: 0,
  error: null,
  isLoading: false
}

const reducerFetch = (state = initialState, {type, data} = {}) => {
  if (type === 'set' && state && state.name && state.value) {
    return {
      ...state, [state.name]: state.value
    }
  }

  switch (type) {
    case LOADING:
      return { ...state, isLoading: true };
    case SUCCESS:
      return { ...state, isLoading: false, ...data };
    case ERROR:
      return { ...state, isLoading: false, error: data };
    default:
      return state;
  }
}

export default reducerFetch