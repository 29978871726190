import {DigitalFace} from "./DigitalFace";
import {InternetFace} from "./InternetFace";
import {TvFace} from "./TvFace";

export class FaceFactory {
  static create (code) {
    switch (code) {
      case 'digital':
        return new DigitalFace()
      case 'internet':
        return new InternetFace()
      case 'tv':
        return new TvFace()
      default:
        return null
    }
  }
}