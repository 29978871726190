import React, { Component } from 'react';
import Input from './Input';

class QueueInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: ""
        }
    }

    enqueueItem = () => {
        const currentItems = this.props.value.slice(0);
        currentItems.push(this.state.inputValue)
        this.props.onChange(currentItems)
        this.setState({ inputValue: "" })
    }

    removeItem = (index) => {
        let currentItems = this.props.value.slice(0);
        currentItems.splice(index, 1);
        this.props.onChange(currentItems);


    }

    onInputChange = (value) => {
        this.setState({ inputValue: value })
    }

    render() {
        return (
            <div>
                <Input value={this.state.inputValue} onChange={this.onInputChange} />
                <div className="container-button">
                    <button type="button" className="button-border" onClick={this.enqueueItem}>
                        <i className="fal fa-plus mr-for-icon"></i>
                        {this.props.nameButton}
                    </button>
                </div>
                <div className="add_new_city" id="client-edit-button">
                    {this.props.value.map((item, index) => (
                        <div key={index} className="city_show">
                            <span onClick={() => this.removeItem(index)}>
                                <i className="fal fa-minus"></i>
                            </span>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default QueueInput;