import React from 'react';
import IntInput from "./IntInput";

function FloatInput(props) {
  return (
    <IntInput
      decimalSeparator={props.decimalSeparator || ','}
      decimalScale={props.decimalScale || 2}
      fixedDecimalScale={props.value !== ''}
      {...props}
    />
  );
}

export default FloatInput;