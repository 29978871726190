import i18next from "i18next";
import {toast} from "react-toastify";

const STATUS_STARTED = 'started'
const STATUS_SUCCESS = 'success'
const STATUS_ERROR = 'error'
const STATUS_WARNING = 'warning'

const getMessage = (status, message) => {
  let statusMessage = i18next.exists(`subscribe.${status}`)
    ? i18next.t(`subscribe.${status}`)
    : ''

  switch (status) {
    case STATUS_STARTED:
    case STATUS_SUCCESS:
    case STATUS_ERROR:
      return statusMessage + ': \n' + message
    case STATUS_WARNING:
      return message
  }
}

const noteMessage = (status, message) => {
  switch (status) {
    case STATUS_STARTED:
      toast.info(message)
      break
    case STATUS_SUCCESS:
      toast.success(message)
      break
    case STATUS_ERROR:
      toast.error(message)
      break
    case STATUS_WARNING:
      toast.warn(message)
      break
  }
}

export const toastSubscribeResult = (response) => {
  if (response.status && response.message) {
    const message = getMessage(response.status, response.message)

    noteMessage(response.status, message)
  }
}