import React, {Component} from 'react';
import MenuClient from '../../Components/MenuClient';
import Select from 'react-select';
import {
  addInputError,
  deleteInputError,
  deleteInputErrors,
  showApiErrors,
  logError, sprintToastErrors, isFloatNumbersEqual
} from '../../utils';
import {api, responseHandler} from '../../api/apiProvider'
import Input from '../../Components/Inputs/Input';
import DatePicker from "react-datepicker";
import {toast} from 'react-toastify';
import {cloneDeep} from "lodash";
import Parts from './Parts';
import SubTitle from './SubTitle';
import Details from './Details';
import i18n from "i18next";
import { transliterate as tr } from 'transliteration';
import {connect} from "react-redux";
import {getFormattedDate, getUtcDate} from "./Detail/Fact/Utils";
import apiUrls from "../../ApiUrls";
import FloatInput from "../../Components/Inputs/FloatInput";
import Checkbox from "../../Components/Inputs/Checkbox";
import moment from "moment";
import ButtonWithLoader from "../../Components/Buttons/ButtonWithLoader";
import LoaderBackdrop from "../../Components/Loader/LoaderBackdrop";
import {ChannelFactory} from "../../entities/channel/ChannelFactory";
import Protected from "../../Components/Roles/Protected";
import Roles from "../../roles/Roles";
import Footer from "./Detail/components/Footer";
import {Link} from "react-router-dom";
import {InternetChannel} from "../../entities/channel/InternetChannel";
import {sendPlanValues} from "./Utils";
import sprintf from 'sprintf-js'

const mapStateToProps = state => ({inputErrors: state.inputErrors})
const vsprintf = sprintf.vsprintf

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableCompanies: [],
      advertTypes: [],
      contractTypes: [],
      goals: [],
      selectedCompany: null,
      selectedManager: null,
      selectedClientManager: null,
      selectedProduct: null,
      selectedContractType: null,
      selectedLegal: null,
      selectedType: null,
      companyId: null,
      name: "",
      code: "",
      budget: "",
      facesBudgetSum: null,
      managerText: "",
      linkMrm: "",
      comment: "",
      dashboard: "",
      confirmed: false,
      //budgetCode: "",
      dateEnd: null,
      dateStart: null,
      advertDateStart: null,
      advertDateEnd: null,
      products: [],
      product: [],
      targets: [],
      channels: [],
      managers: [],
      clientManagers: [],
      clientLegals: null,
      parts: [],
      details: [],
      selectedPart: null,
      budgetDetail: [],
      addedParts: [],
      selectedRegion: null,
      channelEntity: null,
      regions: [],
      addedDetails: [],
      cachedDetails: [],
      dateFormat: this.dateFormat,
      filterDateFormat: 'Y-MM-DD',
      clientDateFormat: 'DD.MM.Y',
      clientCompanyRole: 'client',
      channelsCodes: ['digital', 'internet'],
      budgetMarginError: process.env.REACT_APP_BUDGET_MARGIN_OF_ERROR || 0.05,
      isSaveLoader: false,
      isSubscribed: false,
    }
  }

  getDate = (dateString) => {
    return getUtcDate(dateString)
  }

  setFactUrls = () => {
    let details = cloneDeep(this.state.addedDetails)

    for (let detail of details) {
      const channelCode = detail.channel.code

      for (let period of detail.periods) {
        const dateStart = getFormattedDate(period.dateStartDetail, this.state.filterDateFormat)
        const dateEnd = getFormattedDate(period.dateEndDetail, this.state.filterDateFormat)

        period.factDataUrl = `/advertisings/${this.props.match.params.advertisingId}/fact/${channelCode}?dateStart=${dateStart}&dateEnd=${dateEnd}`
      }
    }

    this.setState({addedDetails: details})
  }

  getGoals() {
    api.get(apiUrls.get.goals()).then(data => {
      this.setState({goals: data['hydra:member']});
    })
  }

  getFacesBudget(advertisingId, channelCodes) {
    Promise.all(channelCodes.map(code => api.get(apiUrls.get.advertisingsFacesBudget(code, [advertisingId]))))
      .then(values => {
        let facesBudgetSum = 0

        for (let channelValues of values) {
          if (channelValues.items && channelValues.items['hydra:member']) {
            for (let advertising of channelValues.items['hydra:member']) {
              if (advertising.data && advertising.data.budget) {
                facesBudgetSum += Math.round((Number(advertising.data.budget) + Number.EPSILON) * 100) / 100
              }
            }
          }
        }

        this.setState({facesBudgetSum});
      })
  }

  async getAdvertising () {
    const advertisingId = this.props.match.params.advertisingId;

    if (!advertisingId) {
      return
    }

    await api.get(apiUrls.get.advertising(advertisingId) +
      "?t[]=advert:read&t[]=contract_type&t[]=company_legal").then((data) => {
      this.setState({
        budget: data.budget,
        //budgetCode: data.budgetCode,
        name: data.name,
        code: data.code,
        managerText: data.managerText,
        linkMrm: data.linkMrm,
        comment: data.comment,
        dashboard: data.dashboard,
        confirmed: data.confirmed,
        selectedType: (data.type && data.type['@id'] && data.type.name) ? {
          value: data.type['@id'],
          label: data.type.name,
        } : null,
        product: data.product,
        targets: data.targets,
        selectedRegion: {value: data.region["@id"], label: data.region.name},
        dateStart: this.getDate(data.dateStart),
        advertDateStart: this.getDate(data.dateStart),
        dateEnd: this.getDate(data.dateEnd),
        advertDateEnd: this.getDate(data.dateEnd),
        selectedProduct: {value: data.product["@id"], label: data.product.name},
        selectedManager: data.manager ? {value: data.manager["@id"], label: data.manager.name} : null,
        selectedClientManager: data.clientManager ? {value: data.clientManager["@id"], label: data.clientManager.name} : null,
        selectedLegal: data.legal ? {value: data.legal["@id"], label: data.legal.name} : null,
        addedParts: data.parts.map((part) => {
          return {
            partName: {label: part.name, value: part.name},
            id: part.id
          }
        }),
        addedDetails: data.targets.map((target) => {
          return {
            id: target.id,
            goal: {
              label: target.name,
              value: target.name
            },
            numberGoal: target.goal,
            budgetGoal: target.budget,
            channel: {label: target.channel.name, value: target.channel["@id"], code: target.channel.code},
            periods: target.periods.map((period) => {
              return {
                id: period.id,
                numberDetail: period.goal,
                budgetDetail: period.budget,
                dateStartDetail: period.dateStart,
                dateEndDetail: period.dateEnd,
                budgetCode: period.budgetCode,
                nameFinancial: period.nameFinancial,
                approved: period.approved,
              }
            })
          }
        }),
        companyId: data.company.id,
        selectedContractType: (data.contractType && data.contractType['@id'] && data.contractType.name) ? {
          value: data.contractType['@id'],
          label: data.contractType.name,
        } : null,
      }, () => {
        this.cacheDetails()
        this.setFactUrls()
        this.getCompany(true)
        this.getRegions()
      })

      const channelCodes = data.targets.map(target => target.channel.code)

      const targets = data.targets

      if (targets[0] && targets[0].channel) {
        const channelEntity = ChannelFactory.create(targets[0].channel.code)

        this.setState({
          channelEntity
        })

        if (channelEntity instanceof InternetChannel) {
          this.getGoals()
          this.getParts(data.product.id)
        }
      }

      this.getFacesBudget(advertisingId, channelCodes)
    })
  }

  getRegions = () => {
    const regionsParams = new URLSearchParams({
      't[]': 'company_region',
      'company.id': this.state.companyId,
      'order[name]': 'asc',
      pagination: false,
    })

    api.get(apiUrls.get.regions() + '?' + regionsParams.toString()).then((data) => {
      this.setState({regions: data['hydra:member']});
    })
  }

  getLegals = (companyId, isAfterSave = false) => {
    api.get(apiUrls.get.clientLegals() + `&company.id=${companyId}`).then((data) => {
      const legals = data['hydra:member']

      if (this.state.clientLegals && !isAfterSave && legals.length === 1) {
        const legal = legals[0]

        if (legal) {
          this.setState({
            selectedLegal: {
              value: legal['@id'],
              label: legal.name,
            },
          })
        }
      }

      if (this.state.selectedLegal) {
        const companyLegal = legals.find(legal => legal['@id'] === this.state.selectedLegal.value)

        if (!companyLegal) {
          this.setState({
            selectedLegal: null,
          })
        }
      }

      this.setState({
        clientLegals: legals.map(legal => ({value: legal['@id'], label: legal.name}))
      })
    })
  }

  getCompany = (isAfterSave = false) => {
    if (this.state.companyId) {
      api.get(apiUrls.get.company(this.state.companyId)
        + "?t[]=company_product").then((data) => {
        this.setState({
          products: data.products,
          companyId: data.id,
          selectedCompany: {value: data["@id"], label: data.name},
        }, () => {
          this.getLegals(this.state.companyId, isAfterSave)
        });

        if (this.isNew()) {
          this.setState({
            selectedContractType: data.contractType
              ? {value: data.contractType["@id"], label: data.contractType.name}
              : null,
          });
        }
      })

      api.get(apiUrls.get.clientManagers(this.state.companyId)).then((data) => {
        this.setState({clientManagers: data['hydra:member']})
      })
    }
  }

  async getData() {
    const advertisingId = this.props.match.params.advertisingId;

    api.get(apiUrls.get.channels()).then((data) => {
      this.setState({channels: data['hydra:member']})
    })

    api.get(apiUrls.get.managers()).then((data) => {
      this.setState({managers: data['hydra:member']})
    })

    api.get(apiUrls.get.advertTypes()).then((data) => {
      this.setState({advertTypes: data['hydra:member']})
    })

    api.get(apiUrls.get.contractTypes()).then((data) => {
      this.setState({contractTypes: data['hydra:member']})
    })

    if (advertisingId !== undefined) {
      this.getAdvertising()
    }
  }

  componentDidMount() {
    deleteInputErrors()

    api.get(apiUrls.get.clients()).then((data) => {
      const companies = data['hydra:member']
      this.setState({availableCompanies: companies})
    })

    this.getData()
  }

  getParts (productId) {
    if (typeof productId === 'string'){
      productId = productId.replace(/\D/g , '');
    }
    let url = apiUrls.get.productCampaignNames(productId)

    const params = new URLSearchParams();

    if (this.state.dateStart && this.state.dateEnd) {
      params.append('dateStart', moment(this.state.dateStart).format(this.state.filterDateFormat))
      params.append('dateEnd', moment(this.state.dateEnd).format(this.state.filterDateFormat))

      url += '&' + params.toString()
    }

    api.get(url).then((data) => {
      this.setState({parts: data})
    })
  }

  // Обновляется компонент, если меняется выбранный продукт
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedProduct !== this.state.selectedProduct) {
      if (this.state.selectedProduct === null) {
        //this.setState({goals: [], parts: []})
      } else {
        const productID = this.state.selectedProduct.value.replace("/products/", "");
        /*api.get(apiUrls.get.productAdvertGoals(productID)).then((data) => {
          this.setState({goals: data});
        })*/

        if (this.state.channelEntity && this.state.channelEntity instanceof InternetChannel) {
          this.getParts(productID)
        }
      }
    }

    if ((prevState.dateStart !== this.state.dateStart || prevState.dateEnd !== this.state.dateEnd)
      && this.state.dateStart !== null && this.state.dateEnd !== null
      && !(prevState.dateStart === null && prevState.dateEnd === null)) {
      const dates = this.generateGoalPeriods(this.state.dateStart, this.state.dateEnd);
      this.reMapAddedDetails(dates)
    }
  }

  generateGoalPeriods = (periodStart, periodEnd) => {
    let dateStart = getUtcDate(periodStart)
    const datePeriodEnd = getUtcDate(periodEnd)
    const dates = []

    while (dateStart <= datePeriodEnd) {
      let dateEnd = cloneDeep(dateStart)
      dateEnd = getUtcDate(dateEnd.endOf("month"))

      dates.push({
        dateStart: cloneDeep(dateStart),
        dateEnd: dateEnd
      })

      dateStart = getUtcDate(cloneDeep(dateEnd).add(1, 'months').startOf('month').toISOString())
    }

    if (dates.length) {
      dates[dates.length - 1].dateEnd = datePeriodEnd
    }

    return dates;
  }

  cacheDetails = () => {
    let cachedDetails = {}

    if (!this.state.addedDetails) {
      return
    }

    for (let detail of this.state.addedDetails) {
      if (!detail.id || !detail.periods) {
        continue
      }

      cachedDetails[detail.id] = {}

      for (let period of detail.periods) {
        try {
          cachedDetails[detail.id][+this.getDate(period.dateStartDetail).month() + 1] = period
        } catch (error) {

        }
      }
    }

    this.setState({cachedDetails})
  }

  reMapAddedDetails = (dates) => {
    if (!this.state.addedDetails) {
      return
    }

    const newFormValue = cloneDeep(this.state.addedDetails)

    for (let detail of newFormValue) {
      if (!detail.periods) {
        continue
      }

      const periods = {}

      for(let period of detail.periods) {
        if (period.dateStartDetail) {
          periods[(+this.getDate(period.dateStartDetail).month() + 1)] = period
        }
      }

      let months = []

      for(let date of dates) {
        const month = +(date.dateStart.month()) + 1

        months.push(month)

        if (periods[month]) {
          periods[month].dateStartDetail = date.dateStart
          periods[month].dateEndDetail = date.dateEnd
        } else {
          periods[month] = this.generateNewPeriod(date)

          if (this.state.cachedDetails
            && this.state.cachedDetails[detail.id]
            && this.state.cachedDetails[detail.id][month]
          ) {
            let cachedPeriod = this.state.cachedDetails[detail.id][month]

            periods[month].id = cachedPeriod.id
            periods[month].numberDetail = cachedPeriod.numberDetail
            periods[month].budgetDetail = cachedPeriod.budgetDetail
          }
        }
      }

      for (let month in periods) {
        if (!months.includes(parseInt(month))) {
          delete(periods[month])
        }
      }

      detail.periods = []

      for (let month in periods) {
        detail.periods.push(periods[month])
      }
    }

    this.setState({addedDetails: newFormValue})
  }

  onProductChange = (object) => {
    this.setState({selectedProduct: object})
    deleteInputError('advert_product')
  }

  onLegalChange = (object) => {
    this.setState({selectedLegal: object})
  }

  onCompanyChange = (object) => {
    if (!object || !object.value) {
      return
    }

    this.setState({companyId: object.value}, () => {
      this.getCompany()
      this.getRegions()
      this.setState({selectedRegion: null})
      this.setState({selectedProduct: null})
    })
  }

  onManagerChange = (object) => {
    this.setState({selectedManager: object})
  }

  onClientManagerChange = (object) => {
    this.setState({selectedClientManager: object})
  }

  onRegionChange = (object) => {
    this.setState({selectedRegion: object})
    deleteInputError('advert_region')
  }

  onDateStartChange = (date) => {
    if (!date) {
      this.setState({dateStart: null})
      return
    }

    const offset = date.getTimezoneOffset()

    if (offset < 0) {
      date.setHours(-offset / 60)
    }

    deleteInputError('advert_date_start')

    this.setState({dateStart: this.getDate(date)}, () => {
      if (this.checkDates('advert_date_start')) {
        deleteInputError('advert_date_start')
      } else {
        this.setState({dateStart: null})
      }
    })
  }

  onDateEndChange = (date) => {
    if (!date) {
      this.setState({dateEnd: null})
      return
    }

    const offset = date.getTimezoneOffset()

    if (offset < 0) {
      date.setHours(-offset / 60)
    }

    date.setHours(-offset / 60)
    deleteInputError('advert_date_end')

    this.setState({dateEnd: this.getDate(date)}, () => {
      if (this.checkDates('advert_date_end')) {
        deleteInputError('advert_date_end')
      } else {
        this.setState({dateEnd: null})
      }
    })
  }

  onDetailChange = (object, index) => {
    deleteInputError('advert_budget')

    for (const index in this.state.addedDetails) {
      deleteInputError('channel_budget_' + index)
      deleteInputError('channel_goal_' + index)
      deleteInputError('period_budget_' + index)
      deleteInputError('period_goal_' + index)
    }

    const addedDetails = cloneDeep(this.state.addedDetails);
    addedDetails[index] = object;

    this.setState({addedDetails}, () => {
      this.checkBudgetSum()
      this.checkPeriodsBudgetSum()
      this.checkPeriodsGoalSum()
    })
  }

  checkBudgetSum = () => {
    let totalSumDetail = 0;

    for (const item of this.state.addedDetails) {
      totalSumDetail += (Number(item.budgetGoal))
    }

    let limitBudgetDetail = (Number(this.state.budget));

    if ((Math.abs(limitBudgetDetail - totalSumDetail) > this.state.budgetMarginError)
      && this.state.addedDetails.length !== 0) {
      addInputError('advert_budget', i18n.t('clients.advertising.sum_budget_not_equal'))

      for (const index in this.state.addedDetails) {
        addInputError('channel_budget_' + index, i18n.t('clients.advertising.sum_budget_not_equal'))
      }

      return false
    }

    return true
  }

  checkPeriodsBudgetSum = () => {
    let noError = true

    for (const index in this.state.addedDetails) {
      if (!this.state.addedDetails.hasOwnProperty(index)) {
        continue
      }

      let item = this.state.addedDetails[index]

      let limitDetail = 0;
      let sumBudget = 0;
      limitDetail = (Number(item.budgetGoal))

      for (const period of item.periods) {
        sumBudget += (Number(period.budgetDetail))
      }

      if (Math.abs(limitDetail - sumBudget) > this.state.budgetMarginError && this.state.addedDetails.length !== 0) {
        addInputError('channel_budget_' + index, i18n.t('clients.advertising.sum_period_budget_not_equal'))
        addInputError('period_budget_' + index, i18n.t('clients.advertising.sum_period_budget_not_equal'))
        noError = false
      }
    }

    return noError
  }

  checkPeriodsGoalSum = () => {
    let noError = true

    for (const index in this.state.addedDetails) {
      if (!this.state.addedDetails.hasOwnProperty(index)) {
        continue
      }

      let item = this.state.addedDetails[index]

      let goalLimit = 0;
      let goalSum = 0;

      goalLimit = Number(item.numberGoal)

      for (const period of item.periods) {
        goalSum += Number(period.numberDetail)
      }

      if (Math.abs(goalLimit - goalSum) > 0) {
        addInputError('channel_goal_' + index, i18n.t('clients.advertising.goal_numbers_not_equal'))
        addInputError('period_goal_' + index, i18n.t('clients.advertising.goal_numbers_not_equal'))
        noError = false
      }
    }

    return noError
  }

  onPartsChange = (object, index) => {
    const addedParts = cloneDeep(this.state.addedParts);
    addedParts[index] = object;
    this.setState({addedParts})
  }

  checkDates = (dateItem) => {
    if (dateItem !== 'advert_date_start' && dateItem !== 'advert_date_end') {
      return true
    }

    if (this.state.dateStart && this.state.dateEnd) {

      if (this.state.dateStart > this.state.dateEnd) {
        addInputError(dateItem, i18n.t('clients.advertising.date_start_after_than_date_end'))
        return false
      }
    }

    return true
  }

  onGeneratePeriodDetail = (index) => {
    if (this.state.dateStart === null || this.state.dateEnd === null) {
      toast.warning(i18n.t('clients.edit.select_campaign'));
      return;
    }
    const detailForm = cloneDeep(this.state.addedDetails);

    detailForm[index].periods = this.generateGoalPeriods(this.state.dateStart, this.state.dateEnd).map((date,) => {
      return (this.generateNewPeriod(date))
    })
    this.setState({addedDetails: detailForm})
  }

  generateNewPeriod = (date) => {
    return {
      dateStartDetail: date.dateStart,
      dateEndDetail: date.dateEnd,
      budgetDetail: 0,
      numberDetail: 0,
    }
  }

  addDetailForm = () => {
    const detailForm = this.state.addedDetails;
    detailForm.push({
      goal: {},
      numberGoal: "",
      channel: null,
      budgetGoal: "",
      periods: []
    })
    this.setState({addedDetails: detailForm})
  }

  addPartsNewForm = () => {
    const newParts = this.state.addedParts;
    newParts.push({
      name: ""
    })
    this.setState({addedParts: newParts})
  }

  deleteFormParts = (index) => {
    const deletePart = this.state.addedParts;
    deletePart.splice(index, 1);
    this.setState({addedParts: deletePart})
  }

  showSaveLoader = () => {
    this.setState({
      isSaveLoader: true
    })
  }

  hideSaveLoader = () => {
    this.setState({
      isSaveLoader: false
    })
  }

  addAdvertising = async () => {
    if (!this.state.addedDetails.length) {
      toast.error(i18n.t('clients.edit.empty_channel'))
      this.hideSaveLoader()
      return
    }

    try {
      var body = {
        company: this.state.selectedCompany.value,
        name: this.state.name.trim(),
        code: this.state.code,
        product: this.state.selectedProduct.value,
        region: this.state.selectedRegion.value,
        contractType: this.state.selectedContractType.value,
        budget: Number(this.state.budget),
        //budgetCode: this.state.budgetCode,
        manager: (this.state.selectedManager && this.state.selectedManager.value )
          ? this.state.selectedManager.value
          : null,
        clientManager: (this.state.selectedClientManager && this.state.selectedClientManager.value )
          ? this.state.selectedClientManager.value
          : null,
        legal: (this.state.selectedLegal && this.state.selectedLegal.value) ? this.state.selectedLegal.value : null,
        linkMrm: this.state.linkMrm,
        comment: this.state.comment,
        dashboard: this.state.dashboard,
        confirmed: this.state.confirmed,
        type: (this.state.selectedType && this.state.selectedType.value) ? this.state.selectedType.value : null,
        // временное решение проблемы переноса дат - форматируем Date без timezone, возможно в дальнейшем потребуется время
        dateStart: getFormattedDate(this.state.dateStart, this.dateFormat),
        dateEnd: getFormattedDate(this.state.dateEnd, this.dateFormat),
        targets: this.state.addedDetails.map((detail) => {
          return {
            name: detail.goal.value,
            goal: Number(detail.numberGoal),
            budget: Number(detail.budgetGoal),
            channel: detail.channel.value,
            periods: detail.periods.map((period) => {
              return {
                budget: Number(period.budgetDetail),
                goal: Number(period.numberDetail),
                dateStart: this.getDate(period.dateStartDetail).format(this.dateFormat),
                dateEnd: this.getDate(period.dateEndDetail).format(this.dateFormat)
              }
            })

          }
        }),
        parts: this.state.addedParts.map((part) => {
          return {
            name: part.partName.value
          }
        })
      }

    } catch (error) {
      logError(error)
      toast.error(i18n.t('clients.edit.empty_fields'));
      this.hideSaveLoader()
      return
    }

    try {
      await api.post(apiUrls.get.adverts() + "?t[]=advert:write", body)
        .then(
          async (result) => {
            if (result && result.id && result.company && result.targets) {

              await this.savePeriods(result.targets).then(() => {
                toast.success(i18n.t('clients.edit.advertising_created'))

                const advertId = result.id
                this.props.history.push(`/advertisings/${advertId}`)

                this.getAdvertising()
              })
            }
          },
          (error) => showApiErrors(error)
        ).finally(() => {
          this.hideSaveLoader()
        })

    } catch (error) {
      toast.error(i18n.t('clients.edit.save_fail'))
      this.hideSaveLoader()
    }
  }

  isPeriodsChanged = () => {
    //const startDate = getFormattedDate(this.state.dateStart, this.state.dateFormat)
    //const oldStartDate = getFormattedDate(this.state.advertDateStart, this.state.dateFormat)
    // const endDate = getFormattedDate(this.state.dateEnd, this.state.dateFormat)
    // const oldEndDate = getFormattedDate(this.state.advertDateEnd, this.state.dateFormat)
    // it is allowed to increase end date
    return (this.state.dateStart > this.state.advertDateStart) || (this.state.dateEnd < this.state.advertDateEnd)
  }

  editAdvertising = async () => {
    if (this.isPeriodsChanged()) {
      const isFactData = await this.isTotalFactData()

      if (isFactData) {
        alert(i18n.t('clients.edit.fact_data_confirm_message'))
        this.hideSaveLoader()
        return
      }

      let advertDatesChangedMessage = ''

      if (!this.state.dateStart.isSame(this.state.advertDateStart)) {
        const newStartDate = this.state.dateStart.format(this.state.clientDateFormat)
        const advertStartDate = this.state.advertDateStart.format(this.state.clientDateFormat)
        advertDatesChangedMessage += vsprintf(i18n.t('clients.edit.advert_start_date_change'),
          [advertStartDate, newStartDate])
      }

      if (!this.state.dateEnd.isSame(this.state.advertDateEnd)) {
        const newEndDate = this.state.dateEnd.format(this.state.clientDateFormat)
        const advertEndDate = this.state.advertDateEnd.format(this.state.clientDateFormat)
        advertDatesChangedMessage += vsprintf(i18n.t('clients.edit.advert_end_date_change'),
          [advertEndDate, newEndDate])
      }

      if (advertDatesChangedMessage) {
        advertDatesChangedMessage += ' ' + i18n.t('clients.edit.plan_data_will_be_deleted')
        const isConfirm = window.confirm(`${advertDatesChangedMessage} ${i18n.t('clients.edit.continue')}?`)

        if (!isConfirm) {
          this.hideSaveLoader()
          return
        }
      }
    }

    let body = {}

    try {
      for (let detail of this.state.addedDetails) {
        if (!detail.channel || !detail.channel.value) {
          toast.error(i18n.t('clients.edit.empty_channel'));
          return
        }
      }

      body = {
        name: this.state.name.trim(),
        code: this.state.code,
        product: this.state.selectedProduct.value,
        region: this.state.selectedRegion.value,
        budget: parseFloat(this.state.budget),
        company: this.state.selectedCompany.value,
        manager: (this.state.selectedManager && this.state.selectedManager.value)
          ? this.state.selectedManager.value
          : null,
        clientManager: (this.state.selectedClientManager && this.state.selectedClientManager.value)
          ? this.state.selectedClientManager.value
          : null,
        legal: (this.state.selectedLegal && this.state.selectedLegal.value) ? this.state.selectedLegal.value : null,
        linkMrm: this.state.linkMrm,
        comment: this.state.comment,
        dashboard: this.state.dashboard,
        confirmed: this.state.confirmed,
        type: (this.state.selectedType && this.state.selectedType.value) ? this.state.selectedType.value : null,
        // временное решение проблемы переноса дат - форматируем Date без timezone
        dateStart: getFormattedDate(this.state.dateStart, this.dateFormat),
        dateEnd: getFormattedDate(this.state.dateEnd, this.dateFormat),
        targets: this.state.addedDetails.map((detail) => {
          return {
            name: detail.goal.value,
            goal: Number(detail.numberGoal),
            budget: Number(detail.budgetGoal),
            channel: detail.channel ? detail.channel.value : '',
            id: detail.id,
            periods: detail.periods.map((period) => {
              return {
                id: period.id,
                budget: Number(period.budgetDetail),
                goal: Number(period.numberDetail),
                dateStart: getFormattedDate(period.dateStartDetail, this.dateFormat),
                dateEnd: getFormattedDate(period.dateEndDetail, this.dateFormat)
              }
            })
          }
        }),
        parts: this.state.addedParts.map((part) => {
          return {
            id: part.id,
            name: part.partName.value
          }
        })
      }

    } catch (error) {
      logError(error)
      this.hideSaveLoader()
      return;
    }

    try {
      await api
        .patch(apiUrls.patch.adverts(this.props.match.params.advertisingId) + "?t[]=advert:write", body)
        .then(responseHandler)
        .then(async (result) => {
          if (result.id && result.targets) {
            await this.savePeriods(result.targets).then(() => {
              toast.success(i18n.t('clients.edit.advert_changed'))
              this.getAdvertising()

              if (result.company) {
                this.props.history.push(`/advertisings/${result.id}`)
              }
            })
          }

          if (this.isPeriodsChanged()) {
            this.deletePlanData()
          }
        })
        .catch((error) => {
          showApiErrors(error)
        })
        .finally(() => {
          this.hideSaveLoader()
        })

    } catch (error) {
      toast.error(error.message)
      this.hideSaveLoader()
    }
  }

  savePeriods = async (savedTargets) => {
    if (!Roles.hasAccess('AdvertisingPeriodsBudgetCodeEdit')) {
      return
    }

    for (let targetId in this.state.addedDetails) {
      const target = this.state.addedDetails[targetId]

      if (savedTargets[targetId]) {
        if (target.periods) {
          await Promise.all(target.periods.map(async (period, periodId) => {
            if (savedTargets[targetId].periods && savedTargets[targetId].periods[periodId]) {
              await this.savePeriod(period, savedTargets[targetId].periods[periodId])
            }
          }))
        }
      }
    }
  }

  savePeriod = async (period, savedPeriod) => {
    return new Promise((resolve, reject) => {
      api.patch(apiUrls.patch.patchApproved(savedPeriod.id), {
        budgetCode: period.budgetCode,
        nameFinancial: period.nameFinancial,
        approved: period.approved,
      })
        .then(responseHandler)
        .then((data) => {
          resolve()
        }).catch((error) => {
          sprintToastErrors(error)
          reject()
        })
    })
  }

  hasFormErrors = () => {
    let isError = false

    if (!this.state.selectedCompany || !this.state.selectedCompany.value) {
      toast.error(i18n.t('clients.advertising.empty_company'))
      return true
    }

    if (!this.state.selectedType || !this.state.selectedType.value) {
      addInputError('type', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (!this.state.name) {
      addInputError('advert_name', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (this.state.budget <= 0) {
      addInputError('advert_budget', i18n.t('clients.edit.sum_budget_must_more_zero'))
      isError = true
    }

    if (!this.state.selectedProduct || !this.state.selectedProduct.value) {
      addInputError('advert_product', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (!this.state.selectedRegion || !this.state.selectedRegion.value) {
      addInputError('advert_region', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (!this.state.dateStart) {
      addInputError('advert_date_start', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (!this.state.dateEnd) {
      addInputError('advert_date_end', i18n.t('errors.not_to_be_empty'))
      isError = true
    }

    if (!this.checkBudgetSum()) {
      isError = true
    }

    if (!this.checkPeriodsBudgetSum()) {
      isError = true
    }

    if (!this.checkPeriodsGoalSum()) {
      isError = true
    }

    {
      let stopDate;
      for (const item of this.state.addedDetails) {
        for (const period of item.periods) {
          stopDate = period.dateStartDetail
        }
        if (stopDate === null) {
          toast.warning(i18n.t('clients.edit.periods_should_be_generated'))
          isError = true
        }
      }
    }

    return isError
  }

  isNew = () => {
    return this.props.match.params.advertisingId === undefined
  }

  getFactsParams = (dateStart, dateEnd) => {
    return new URLSearchParams({
      'date[after]': dateStart.format(this.state.filterDateFormat),
      'date[before]': dateEnd.format(this.state.filterDateFormat),
      'face.advertising.id': this.props.match.params.advertisingId,
      interval: 'month'
    })
  }

  getDeletePlanParams = (dateStart, dateEnd) => {
    return new URLSearchParams({
      'date[after]': dateStart.format(this.state.filterDateFormat),
      'date[before]': dateEnd.format(this.state.filterDateFormat),
      'face.advertising.id': this.props.match.params.advertisingId,
    })
  }

  deletePlanData = async () => {
    const changedIntervals = []
    if (!this.state.dateStart.isSame(this.state.advertDateStart)) {
      const startDate = cloneDeep(this.state.dateStart)
      changedIntervals.push({
        start: this.state.advertDateStart,
        end: startDate.subtract(1, 'days'),
      })
    }

    if (!this.state.dateEnd.isSame(this.state.advertDateEnd)) {
      const endDate = cloneDeep(this.state.dateEnd)
      changedIntervals.push({
        start: endDate.add(1, 'days'),
        end: this.state.advertDateEnd,
      })
    }

    for (let target of this.state.targets) {
      let channelEntity = ChannelFactory.create(target.channel.code)

      for (let period of changedIntervals) {
        const params = this.getDeletePlanParams(period.start, period.end)
        let url = channelEntity.apiUrls.totalPlan + '?' + params.toString()

        api.delete(url)
      }
    }
  }

  isTotalFactData = async () => {
    const changedIntervals = []
    if (!this.state.dateStart.isSame(this.state.advertDateStart)) {
      const startDate = cloneDeep(this.state.dateStart)
      changedIntervals.push({
        start: this.state.advertDateStart,
        end: startDate.subtract(1, 'days'),
      })
    }

    if (!this.state.dateEnd.isSame(this.state.advertDateEnd)) {
      const endDate = cloneDeep(this.state.dateEnd)
      changedIntervals.push({
        start: endDate.add(1, 'days'),
        end: this.state.advertDateEnd,
      })
    }

    for (let target of this.state.targets) {
      let channelEntity = ChannelFactory.create(target.channel.code)

      for (let period of changedIntervals) {
        const params = this.getFactsParams(period.start, period.end)
        let url = channelEntity.apiUrls.totalFact + '?' + params.toString()

        let response = await api.get(url)

        let intervals = (response && response['hydra:member'] && response['hydra:member'].length)
          ? response['hydra:member']
          : []

        for (let interval of intervals) {
          if (interval.data && interval.data.length) {
            return true
          }
        }
      }
    }

    return false
  }

  onSaveClick = () => {
    if (this.hasFormErrors()) {
      return
    }

    this.showSaveLoader()

    if (this.isNew()) {
      this.addAdvertising();
    } else {
      this.editAdvertising();
    }
  }

  onSaveBudgetCodesClick = () => {
    this.savePeriods(this.state.addedDetails).then(() => {
      toast.success(i18n.t('clients.edit.advert_periods_budget_codes_saved'))
      this.getAdvertising()
    })
  }

  isInternet = () => {
    return this.state.addedDetails && this.state.addedDetails.some(target => target.channel && target.channel.code
      && target.channel.code === InternetChannel.getCode())
  }

  sendPlan = () => {
    if (!this.state.isSubscribed) {
      sendPlanValues(this.props.match.params.advertisingId)
      this.state.isSubscribed = true
    } else {
      sendPlanValues(this.props.match.params.advertisingId, false)
    }
  }

  setChannelEntity = channelCode => {
    const channelEntity = ChannelFactory.create(channelCode)
    this.setState({
      channelEntity
    })
  }

  render() {
    let topTitle;
    if (this.isNew()) {
      topTitle = i18n.t('clients.edit.add_advertising');
    } else {
      topTitle = i18n.t('clients.edit.edit_advertising')
    }

    const emptyOption = {
      label: i18n.t('projects.list.not_selected'),
      value: ''
    }

    const productOptions = this.state.products.map((product) => {
      return {value: product["@id"], label: product.name}
    })

    const contractTypeOptions = [this.state.selectedContractType]

    const companyOptions = this.state.availableCompanies.map((company) => {
      return {value: company.id, label: company.name}
    })

    const regionOptions = this.state.regions.map((region) => {
      return {value: region["@id"], label: region.name}
    })

    const typeOptions = this.state.advertTypes.map((type) => {
      return {value: type["@id"], label: type.name}
    })

    const managerOptions = this.state.managers.map((manager) => {
      return {value: manager["@id"], label: manager.name}
    })

    const clientManagerOptions = this.state.clientManagers.map((manager) => {
      return {value: manager["@id"], label: manager.name}
    })

    if (clientManagerOptions.length) {
      clientManagerOptions.unshift(emptyOption)
    }

    let globalSumDetail = 0;
    for (const item of this.state.addedDetails) {
      globalSumDetail += (Number(item.budgetGoal) * 100)
    }

    const legalOptions = this.state.clientLegals ? cloneDeep(this.state.clientLegals) : []

    if (legalOptions.length) {
      legalOptions.unshift(emptyOption)
    }

    return (
      <div className="row content flex">
        <div className="edit-client">
          <div className={"table-edit-client advertising-table edit-advert-table " + (this.state.isSaveLoader ? 'loading' : '')}>
            {this.state.isSaveLoader &&
              <LoaderBackdrop />
            }
            <MenuClient title={topTitle}>
              <div className={'advertising-table__buttons'}>
                <Protected access={'AdvertisingPeriodsBudgetCodeSave'}>
                  <div className={'save-button-wrapper'}>
                    <button
                      onClick={this.onSaveBudgetCodesClick}
                      className="button-accent mediaplan-ad-button"
                    >{i18n.t('clients.edit.save_budget_codes')}</button>
                  </div>
                </Protected>

                <div className={'advertising-table__buttons-upload'}>
                  <Link to={`/advertisings/${this.props.match.params.advertisingId}/upload-xls`} title={i18n.t('advertising.list.upload_file')}>
                    <i className="fal fa-upload" />
                  </Link>
                </div>

                {this.isInternet() &&
                  <button onClick={() => this.sendPlan()} className={'btn-icon advertising-table__buttons-send'}
                          title={i18n.t('clients.advertising.save_plan_values')}>
                    <i className="fal fa-cloud" />
                  </button>
                }
              </div>

            </MenuClient>
            <div>
              <table className="table redesign-table table-client">
                <tbody>
                <tr>
                  <td>
                    {i18n.t('clients.page.company')}
                  </td>
                </tr>
                <tr>
                  <td>
                  <span className="input-wrap">
                    <Select
                      className={'react-select-container select-pseudo-input' +
                        (this.props.inputErrors['advert_company'] ? ' has-error' : '')}
                      classNamePrefix="react-select"
                      placeholder={i18n.t('clients.edit.select_company')}
                      options={companyOptions}
                      value={this.state.selectedCompany}
                      onChange={this.onCompanyChange}
                    />
                  </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('invoice.list.clients_legal')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="input-wrap">
                    <Select
                      className={"react-select-container select-pseudo-input"}
                      classNamePrefix="react-select"
                      placeholder={''}
                      options={legalOptions}
                      value={this.state.selectedLegal}
                      onChange={this.onLegalChange}
                    />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.page.contract_type')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="input-wrap">
                    <Select
                      className={"react-select-container select-pseudo-input"}
                      classNamePrefix="react-select"
                      placeholder={''}
                      options={contractTypeOptions}
                      value={this.state.selectedContractType}
                      //onChange={this.onProductChange}
                      isDisabled={true}
                    />
                      {this.props.inputErrors['contractType'] &&
                        <span className="title-error">
                          {i18n.t('errors.select_not_to_be_empty')}
                        </span>
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.edit.type')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="input-wrap">
                    <Select
                      className={"react-select-container select-pseudo-input" +
                        (this.props.inputErrors['type'] ? ' has-error' : '')}
                      classNamePrefix="react-select"
                      placeholder={i18n.t('clients.edit.select_advert_type')}
                      options={typeOptions}
                      value={this.state.selectedType}
                      onChange={value => {
                        this.setState({selectedType: value})
                        deleteInputError('type')
                      }}
                    />
                      {this.props.inputErrors['type'] &&
                        <span className="title-error">
                          {i18n.t('errors.select_not_to_be_empty')}
                        </span>
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.edit.name')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      value={this.state.name}
                      onChange={
                        (value) => {
                          this.setState({name: value},
                            this.setState({code: tr(value.trim().replace(/\.|,|;|:|'|"|\\|\/|\(|\)|/g, '').replace(/\s/g, '_').toLowerCase())})
                          )
                          deleteInputError('advert_name')

                        }
                      }
                      isValid={
                        () => !this.props.inputErrors['advert_name']
                      }
                      className={this.props.inputErrors['advert_name'] ? ' has-error' : ''}
                      errorText={this.props.inputErrors['advert_name']}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.edit.code')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      value={this.state.code}
                      onChange={
                        (value) => {
                          this.setState({code: value})
                        }
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.page.product')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="input-wrap">
                    <Select
                      className={"react-select-container select-pseudo-input" +
                        (this.props.inputErrors['advert_product'] ? ' has-error' : '')}
                      classNamePrefix="react-select"
                      placeholder={i18n.t('clients.edit.select_product')}
                      options={productOptions}
                      value={this.state.selectedProduct}
                      onChange={this.onProductChange}
                    />

                      {this.props.inputErrors['advert_product'] &&
                        <span className="title-error">
                          {i18n.t('errors.select_not_to_be_empty')}
                        </span>
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.t('clients.edit.client_region')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="input-wrap">
                      <Select
                        className={"react-select-container select-pseudo-input" +
                          (this.props.inputErrors['advert_region'] ? ' has-error' : '')}
                        classNamePrefix="react-select"
                        placeholder={i18n.t('clients.edit.select_region')}
                        options={regionOptions}
                        value={this.state.selectedRegion}
                        onChange={this.onRegionChange} />
                      {this.props.inputErrors['advert_region'] &&
                        <span className="title-error">
                          {i18n.t('errors.select_not_to_be_empty')}
                        </span>
                      }
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.manager')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      className={'react-select-container select-pseudo-input' +
                        (this.props.inputErrors['manager'] ? ' has-error' : '')}
                      classNamePrefix="react-select"
                      placeholder={i18n.t('clients.edit.select')}
                      options={managerOptions}
                      value={this.state.selectedManager}
                      onChange={this.onManagerChange}
                      //isDisabled={!this.isNew()}
                    />
                    {this.state.managerText &&
                      <span className={'used-before'}>{i18n.t('clients.edit.selected_before')}&nbsp;
                        <span>{this.state.managerText}</span></span>
                    }

                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.client_manager')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      className={'react-select-container select-pseudo-input' +
                        (this.props.inputErrors['client_manager'] ? ' has-error' : '')}
                      classNamePrefix="react-select"
                      placeholder={i18n.t('clients.edit.select')}
                      options={clientManagerOptions}
                      value={this.state.selectedClientManager}
                      onChange={this.onClientManagerChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.linkMrm')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      value={this.state.linkMrm}
                      onChange={
                        (value) => {
                          this.setState({linkMrm: value})
                        }
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.dashboard')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      value={this.state.dashboard}
                      onChange={
                        (value) => {
                          this.setState({dashboard: value})
                        }
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.comment')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      value={this.state.comment}
                      onChange={
                        (value) => {
                          this.setState({comment: value})
                        }
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {i18n.t('clients.edit.confirmed')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox checked={this.state.confirmed}
                              onChange={(value) => {
                                this.setState({confirmed: value})
                              }} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <div className="flex-space date-group">
                      <div>
                        {i18n.t('clients.edit.faces_budget')}
                      </div>
                      <div>
                        {i18n.t('clients.edit.budget_without_vat')}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="flex-space">
                      <div className="w-48">
                        <div>
                          <FloatInput
                            value={this.state.facesBudgetSum}
                            className={"face-budgets-sum" +
                              (!isFloatNumbersEqual(this.state.facesBudgetSum, this.state.budget, 2) ? ' error' : '')}
                            isAllowed={(values) => false}
                          />
                        </div>
                      </div>
                      <div className="w-50">
                        <FloatInput
                          value={this.state.budget}
                          onChange={(value) => {
                            this.setState({budget: value}, () => {
                              this.checkBudgetSum()
                            })
                            deleteInputError('advert_budget')

                            for (const index in this.state.addedDetails) {
                              deleteInputError('channel_budget_' + index)
                            }
                          }
                          }
                          isValid={
                            (value) => {
                              return !isNaN(value) && !this.props.inputErrors['advert_budget']
                            }
                          }
                          className={this.props.inputErrors['advert_budget'] ? 'has-error' : ''}
                          errorText={this.props.inputErrors['advert_budget']}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="flex-space date-group">
                      <div>
                        {i18n.t('clients.advertising.date_start')}
                      </div>
                      <div>
                        {i18n.t('clients.advertising.date_end')}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="flex-space">
                      <div className="w-48">
                        <span className="input-wrap">
                          <DatePicker
                            className={this.props.inputErrors['advert_date_start'] ? 'has-error' : ''}
                            selected={this.state.dateStart ? this.state.dateStart.toDate() : ''}
                            onChange={this.onDateStartChange}
                            dateFormat={i18n.t('calendar_date_format')}
                            utcOffset={5}
                          />
                          {this.props.inputErrors['advert_date_start'] &&
                            <span className="title-error">
                            {this.props.inputErrors['advert_date_start']}
                          </span>
                          }
                        </span>
                      </div>
                      <div className="w-50">
                        <span className="input-wrap">
                        <DatePicker
                          className={this.props.inputErrors['advert_date_end'] ? 'has-error' : ''}
                          selected={this.state.dateEnd ? this.state.dateEnd.toDate() : ''}
                          onChange={this.onDateEndChange}
                          dateFormat={i18n.t('calendar_date_format')}
                        />
                          {this.props.inputErrors['advert_date_end'] &&
                            <span className="title-error">
                            {this.props.inputErrors['advert_date_end']}
                          </span>
                          }
                          </span>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <SubTitle title={i18n.t('clients.advertising.goal_and_budget_data')} />
              { this.state.addedDetails.map((detail, index) => {
                return (
                  <Details
                    key={index}
                    index={index}
                    channels={this.state.channels}
                    goals={this.state.goals}
                    getGoals={() => this.getGoals()}
                    getParts={() => {
                      if (this.state.selectedProduct && this.state.selectedProduct.value) {
                        this.getParts(this.state.selectedProduct.value)
                      }
                    }}
                    setChannelEntity={this.setChannelEntity}
                    selectedProduct={this.state.selectedProduct}
                    detail={detail}
                    isNew={this.isNew()}
                    onChange={(object) => this.onDetailChange(object, index)}
                    generatePeriod={() => this.onGeneratePeriodDetail(index)}
                    mainBudgetLimit={this.state.budget}
                    globalSumDetail={globalSumDetail}
                    companyId={this.state.companyId}
                    advertId={this.props.match.params.advertisingId}
                    parts={(
                      <div className={'advert-parts-wrapper'}>
                        <SubTitle title={i18n.t('clients.advertising.channel_advertising_campaigns')} />
                        {this.state.addedParts.map((part, index) => {
                          return (
                            <Parts
                              key={index}
                              part={part}
                              parts={this.state.parts}
                              onChange={(object) => this.onPartsChange(object, index)}
                              deletePart={() => this.deleteFormParts(index)} />
                          )
                        })}
                        <div className="container-button-advert">
                          <div className="subtitle" />
                          <button onClick={this.addPartsNewForm}>
                            <i className="fa fa-plus" />
                            <span className="title-button">
                        {i18n.t('clients.advertising.add_form_part')}
                          </span>
                          </button>
                        </div>
                      </div>)}
                  />
                )
              })}
              <div className="container-button-advert">
                <div className="subtitle" />
                <button onClick={this.addDetailForm}>
                  <i className="fa fa-plus" />
                  <span className="title-button">
                      {i18n.t('clients.advertising.add_form_target')}
                      </span>
                </button>
              </div>
            </div>

          </div>
        </div>
        <Footer>
          <div />
          <div>
            <ButtonWithLoader
              onClick={this.onSaveClick}
              className="button mediaplan-ad-button"
              text={i18n.t('clients.edit.save')}
              loadingText={i18n.t('clients.edit.saving')}
              isLoading={this.state.isSaveLoader}
            />
          </div>
        </Footer>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Edit);
