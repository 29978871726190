import {useReducer, useCallback} from 'react';
import reducer, {initialState} from '../Api/ReducerPost'
import {SUCCESS, ERROR} from '../Api/actionTypes';
import {getInputErrors, getUrlWithParams, sprintToastErrors} from "../../utils";
import {api, responseHandler} from '../../api/apiProvider'

const nope = () => {}

const useApiPatch = (getUrlFunction, errorDescription, translationPath, callback, errorCallback = nope) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const makeRequest = useCallback((id, data, params, requestCallback, requestErrorCallback) => {
    try {
      const url = id ? getUrlFunction(id) : getUrlFunction()
      const patchUrl = getUrlWithParams(url, params)

      api.patch(patchUrl, data)
        .then(responseHandler)
        .then((response) => {
          if (response) {
            dispatch({type: SUCCESS, data: {}})

            if (typeof callback === 'function') {
              callback(response.id)
            }

            if (params.callback && typeof params.callback === 'function') {
              params.callback(response)
            }
          }

          if (typeof requestCallback === 'function') {
            requestCallback()
          }
        })
        .catch((error) => {
          sprintToastErrors(error, errorDescription, translationPath)
          dispatch({type: ERROR, data: getInputErrors(error)})

          if (typeof errorCallback === 'function') {
            errorCallback(error)
          }

          if (typeof requestErrorCallback === 'function') {
            requestErrorCallback(error)
          }
        })
    } catch (error) {
      sprintToastErrors(error, errorDescription, translationPath)
      dispatch({type: ERROR, data: getInputErrors(error)})
    }
  }, [getUrlFunction]);

  return [state, makeRequest];
};

export default useApiPatch;