import React from 'react'
import Input from "../Inputs/Input";

function TextInput(props) {
  return (
    <span className={'filter-item ' + props.filter.className}>
      <Input
        value={props.filter.value || ''}
        onChange={props.onChange}
        placeholder={props.filter.placeholder}
        className="input-line" />
    </span>
  )
}

export default TextInput