import React from 'react'
import i18next from 'i18next'
import {Link} from "react-router-dom";
import i18n from "i18next";
import FormattedNumber from "../../Components/FormattedNumber";

function FaceTable(props) {
  const getExpensesSum = () => {
    return props.formRegistry.invoiceRows.reduce((acc, row) => {
      return acc + row.expenses
    }, 0)
  }

  const getTaxedExpensesSum = () => {
    return props.formRegistry.invoiceRows.reduce((acc, row) => {
      return acc + (row.expenses_taxed || 0)
    }, 0)
  }

  return (
    <div className={'faces-list'}>
      <h3>{i18n.t('registries.edit.invoices_list')}</h3>
      <table className="table redesign-table table-client">
        <thead>
        <tr>
          <th>{i18next.t('registries.edit.invoice')}</th>
          <th>{i18next.t('registries.edit.advert')}</th>
          <th>{i18next.t('registryFaces.edit.faces')}</th>
          <th>{i18next.t('registries.edit.expenses_sum')}</th>
          <th>{i18n.t('registries.edit.expenses_with_tax')}</th>
          {/*<th />*/}
        </tr>
        </thead>
        <tbody>
        {props.formRegistry.invoiceRows.map((face, index) => (
          <tr key={face.id}>
            <td>
              <Link to={`/invoice/${face.invoice.id}/edit`}>
                {face.invoice.name || ''}
              </Link>
            </td>
            <td>
              <Link to={`/advertisings/${face.advertising.id}`}>
                {face.advertising.name || ''}
              </Link>
            </td>
            <td>
              {face.internetFaces &&
                <>
                  {face.internetFaces.map((face, index) => (
                    <div key={index}>{face.name}</div>
                  ))}
                </>
              }

              {face.digitalFaces &&
              <>
                {face.digitalFaces.map((face, index) => (
                  <div key={index}>{face.name}</div>
                ))}
              </>
              }

            </td>

            <td>
              <FormattedNumber
                value={face.expenses}  type={'float'} />
            </td>

            <td>
              <FormattedNumber
                value={face.expenses_taxed}  type={'float'} />
            </td>
          </tr>
        ))}

        <tr>
          <td colSpan={3}>{i18next.t('registries.edit.all_sum')}</td>
          <td className={'money'}>
            <FormattedNumber
              value={getExpensesSum()}  type={'float'} />
          </td>
          <td className={'money'}>
          <FormattedNumber
            value={getTaxedExpensesSum()}  type={'float'} />
        </td>
        </tr>
        <tr>
          <td colSpan={3}>{i18next.t('registries.edit.delta')}</td>
          <td className={'money'}>
            <FormattedNumber
              value={Math.abs(props.formRegistry.expenses - getExpensesSum())}  type={'float'} />
          </td>
          <td />
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default FaceTable;
