import React, {Component} from 'react'
import i18next from 'i18next'
import {Link} from "react-router-dom";
import i18n from "i18next";
import xlsServiceProvider from "../../api/xlsServiceProvider";
import DownloadFileButton from "../../Components/Buttons/DownloadFileButton";
import Protected from "../../Components/Roles/Protected";

class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fractionDigits: process.env.REACT_APP_NUMBER_FRACTION_DIGITS,
      dateFormat: 'DD-MM-YYYY',
    }
  }

  render() {
    let invoices = []

    for (let invoice of this.props.invoices.values()) {
      invoices.push(invoice)
    }

    return (
      <table className="mediaplans redesign-table">
        <thead>
        <tr>
          <th>{i18next.t('invoice.list.id')}</th>
          <th>{i18next.t('invoice.list.name')}</th>
          <th>{i18next.t('invoice.list.clients_legal')}</th>
          <th>{i18next.t('invoice.list.sum_plan')}</th>
          <th>{i18next.t('invoice.list.sum_fact')}</th>
          <th>{i18next.t('invoice.list.expenses')}</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {invoices.map((invoice, index) => (
          <tr key={index}>
            <td>{invoice.id}</td>
            <td><Link className="blue-cursor-button" to={"/invoice/" + invoice.id + "/edit"}>
              {invoice.name}
            </Link></td>
            <td>{invoice.legalEntity.name}</td>
            <td className={'money'}>
              {invoice.budget.toFixed(this.state.fractionDigits)}
            </td>
            <td className={'money'}>
              {invoice.spent.toFixed(this.state.fractionDigits)}
            </td>
            <td className={'money'}>
              {invoice.expenses.toFixed(this.state.fractionDigits)}
            </td>
            <td>
              <Protected access={'FinancialInvoicesEdit'}>
                <DownloadFileButton
                  getFunction={() => xlsServiceProvider.getForInvoice(invoice.id)}
                  text={i18n.t('invoice.edit.get_xls')}
                  fileName={`invoice_${invoice.id}.xlsx`}
                />
              </Protected>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    );
  }
}

export default InvoiceTable;