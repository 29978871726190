import React from 'react'
import Input from "../../Inputs/Input";

function TextFilter({element, value, onChange}) {
  return (
    <span className={'filter-item ' + element.className}>
      <Input
        value={value || ''}
        onChange={onChange}
        placeholder={element.placeholder}
        className="input-line" />
    </span>
  )
}

export default TextFilter