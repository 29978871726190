import React, {Component} from 'react'
import Input from "../Components/Inputs/Input"
import Select from "react-select"
import moment from "moment"
import {cloneDeep} from "lodash"
import DatePicker from "react-datepicker"
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeTime: 500
    }
  }

  onFilterChange = async (name, value, callback, timeOut = null) => {
    await this.setFilterValue(name, value, callback, timeOut)
  }

  setFilterValue = async function (name, value, callback, timeOut) {
    await this.props.setPage(1)

    if (this.props.filter.hasOwnProperty(name)) {
      let filter = cloneDeep(this.props.filter)

      filter[name] = value

      this.props.setFilter(filter, timeOut)
    }
  }

  async componentDidMount() {
    await this.setFilters()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location && (decodeURI(this.props.location.search) !== decodeURI(prevProps.location.search))) {

      if (!this.props.location.search) {
        await this.setFilters(true)
      } else {
        await this.setFilters()
      }
    }
  }

  setFilters = async (reset = false) => {
    try {
      let filter = this.props.filter
      let filterOptions = this.props.filters

      if (reset) {
        for (let filterName in filter) {
          filter[filterName] = ''
        }
      }

      let filters = this.props.location.search.split('&')

      for (let queryFilter of filters) {
        if (queryFilter.includes('=')) {
          let filterName = queryFilter.split('=').shift()
          let value = decodeURI(queryFilter.split('=').slice(1))

          if (filter.hasOwnProperty(filterName)) {
            for (let filterOption of filterOptions) {

              if (filterOption.name === filterName) {
                if (filterOption.type === 'dropdown') {
                  for (let option of filterOption.options) {
                    if (option.value == value) {
                      filter[filterName] = {
                        label: option.label,
                        value,
                      }
                    }
                  }
                } else {
                  filter[filterName] = value
                }
              }
            }
          }

          if (filterName === 'page') {
            this.props.setPage(value)
          }

          if (filterName.includes('order')) {
            let sortName = filterName.split('[').pop().split(']').shift()

            if (sortName && value) {
              this.props.setSort(sortName, value)
            }
          }
        }
      }

      let isEmptyRequiredFields = false

      await this.props.setFilter(filter, null)

      for (let filterName in filter) {
        let options = ''

        for (let option of filterOptions) {
          if (option.name == filterName) {
            options = option
          }
        }

        if (options) {
          if (options.required) {
            if ((options.type === 'date') && (!filter[filterName])
              || ((options.type === 'dropdown') && (!filter[filterName].value)
              )) {
              isEmptyRequiredFields = true
            }
          }
        }

        if (reset) {
          filter[filterName] = ''
        }
      }

      if (!isEmptyRequiredFields) {
        await this.props.getData()
      } else {
        this.props.setEmptyData()
      }

    } catch (e) {

    }
  }

  render() {
    const renderTextType = (textFilter, index) => {
      return <span className={'filter-item ' + textFilter.className} key={index}>
        <Input
          value={textFilter.value || ''}
          onChange={(value) => this.onFilterChange(textFilter.name, value, textFilter.callback, this.state.typeTime)}
          placeholder={textFilter.placeholder}
          className="input-line"/></span>
    }

    const renderDateType = (dateFilter, index) => {
      return <span className={'filter-item ' + dateFilter.className} key={index}>
        <DatePicker
          placeholderText={dateFilter.placeholder}
          selected={dateFilter.value ?
            moment(dateFilter.value, dateFilter.filterFormat).toDate() : ''}
          onChange={(value) => this.onFilterChange(dateFilter.name, (value ? moment(value).format(dateFilter.filterFormat) : ''), dateFilter.callback)}
          dateFormat={dateFilter.format}
          showMonthYearPicker={dateFilter.showMonthYearPicker}
        /></span>
    }

    const renderDropdownType = (dropdownFilter, index) => {
      return <span className={'filter-item ' + dropdownFilter.className} key={index}>
        <Select className="react-select-container"
                classNamePrefix="react-select"
                options={dropdownFilter.options}
                placeholder={dropdownFilter.placeholder}
                onChange={(value) => this.onFilterChange(dropdownFilter.name, value, dropdownFilter.callback)}
                value={dropdownFilter.value}/>
      </span>
    }

    const renderRadioType = (radioFilter, index) => {
      return <div className={'radio-filter-block'} key={index}>
        {radioFilter.options.length && radioFilter.options.map((option, index) =>
          <div key={index}>
            <label className={option.className}>
              <input
                type="radio"
                name={radioFilter.name}
                onChange={() => this.onFilterChange(radioFilter.name, option.value, radioFilter.callback)}
                checked={radioFilter.value === option.value}
              />{option.name}
            </label></div>
        )}
      </div>
    }

    const renderFilter = (filter, index) => {
      switch (filter.type) {
        case 'text':
          return renderTextType(filter, index)
        case 'date':
          return renderDateType(filter, index)
        case 'dropdown':
          return renderDropdownType(filter, index)
        case 'radio':
          return renderRadioType(filter, index)
      }
    }

    return (
      <div className="menu-filter">
        {this.props.filters && this.props.filters.map((filter, index) => renderFilter(filter, index))}
      </div>
    );
  }
}

export default Filter;