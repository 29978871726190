import React, {Component} from 'react';
import MenuClient from '../../Components/MenuClient';
import Select from 'react-select';
import {deleteInputError} from '../../utils';
import {api} from '../../api/apiProvider'
import Input from '../../Components/Inputs/Input';
import DatePicker from "react-datepicker";
import {toast} from 'react-toastify';
import moment from 'moment';
import Popup from 'reactjs-popup';

import i18n from "i18next";

import 'reactjs-popup/dist/index.css';

class InvoiceAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalEntities: [],
      selectedLegalEntity: null,

      name: "",
      dateStart: null,
      dateEnd: null,
    }
  }

  UNSAFE_componentWillMount = async () => {
    this.getData()
  }

  getData() {
    api.get("/legals?role.code=client").then((data) => {
      if (!data['hydra:member']) {
        return
      }

      const legalEntities = data['hydra:member']

      if (legalEntities.length) {
        this.setState({legalEntities})
      }
    })
  }

  onLegalEntityChange = (object) => {
    this.setState({selectedLegalEntity: object})
  }

  onNameChange = (name) => {
    this.setState({name})
  }

  onDateStartChange = (date) => {
    deleteInputError('advert_date_start')

    this.setState({dateStart: date}, () => {
      if (this.checkDates('advert_date_start')) {
        deleteInputError('advert_date_start')
      } else {
        this.setState({dateStart: null})
      }
    })
  }

  onDateEndChange = (date) => {
    deleteInputError('advert_date_end')

    this.setState({dateEnd: date}, () => {
      if (this.checkDates('advert_date_end')) {
        deleteInputError('advert_date_end')
      } else {
        this.setState({dateEnd: null})
      }
    })
  }

  checkDates = (dateItem) => {
    if (dateItem !== 'advert_date_start' && dateItem !== 'advert_date_end') {
      return true
    }

    if (this.state.dateStart && this.state.dateEnd) {

      if (this.state.dateStart > this.state.dateEnd) {
        toast.error(i18n.t('clients.advertising.date_start_after_than_date_end'))
        return false
      }
    }

    return true
  }

  isEmptyFields = () => {
    let isEmpty = false

    if (!this.state.selectedLegalEntity
      || !this.state.selectedLegalEntity.value
      || !this.state.name
      || !this.state.dateStart
      || !this.state.dateEnd) {
      isEmpty = true
    }

    return isEmpty
  }

  addInvoice = async () => {
    if (this.isEmptyFields()) {
      toast.error(i18n.t('clients.edit.empty_fields'));
      return
    }

    const body = {
      legalEntity: this.state.selectedLegalEntity.value,
      name: this.state.name,
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      invoiceFaces: this.props.selectedFaces
    }

    try {
      const data = await api.post("/invoices", body);

      if (data.id) {
        toast.success(i18n.t('invoice.edit.created'));
        this.props.onInvoiceAdd()
      }

    } catch (error) {
      toast.error(i18n.t('invoice.edit.save_fail'))
    }
  }

  render() {
    const legalEntityOptions = this.state.legalEntities.map((legalEntity) => {
      return {value: legalEntity["@id"], label: legalEntity.name}
    })

    return (
      <Popup
        open={this.props.showAddInvoice}
        modal
        onClose={this.props.closeModal}
      >
        <div>
          <a className="close" onClick={this.props.closeModal}>
            &times;
          </a>
          <div className="row content flex">
            <div className="edit-client edit-invoice">
              <div className="table-edit-client advertising-table">
                <MenuClient title={i18n.t('invoice.edit.add')}></MenuClient>
                <div>
                  <table className="table redesign-table table-client">
                    <tbody>
                    <tr>
                      <td>
                        {i18n.t('clients.edit.name')}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Input
                          value={this.state.name}
                          onChange={this.onNameChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Select
                          className={'react-select-container select-pseudo-input'}
                          classNamePrefix="react-select"
                          placeholder={i18n.t('invoice.edit.select_legal')}
                          options={legalEntityOptions}
                          value={this.state.selectedLegalEntity}
                          onChange={this.onLegalEntityChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex-space date-group">
                          <div>
                            {i18n.t('clients.advertising.date_start')}
                          </div>
                          <div>
                            {i18n.t('clients.advertising.date_end')}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex-space">
                          <div className="w-48">
                        <span className="input-wrap">
                          <DatePicker
                            selected={this.state.dateStart}
                            onChange={this.onDateStartChange}
                            dateFormat={i18n.t('calendar_date_format')}
                          />
                        </span>
                          </div>
                          <div className="w-50">
                        <span className="input-wrap">
                        <DatePicker
                          selected={this.state.dateEnd}
                          onChange={this.onDateEndChange}
                          dateFormat={i18n.t('calendar_date_format')}
                        />
                          </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div>
                    <button className="button-accent" onClick={this.addInvoice}>
                      {i18n.t('clients.edit.save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Popup>
    );
  }
}

export default InvoiceAdd;