import React from 'react';
import i18n from "i18next";
import {getUtcDate} from "../../Advertising/Detail/Fact/Utils";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Form({formInterval,  setFormInterval, inputErrors, intervals}) {
  const onDateChange = (name, value) => {
    const date = value.getFullYear() + '-' + `${(+value.getMonth() + 1)}`.padStart(2, "0") + '-' + `${value.getDate()}`.padStart(2, "0");

    setFormInterval({
      ...formInterval,
      [name]: date ? getUtcDate(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''
    })
  }

  return (
    <table className="table redesign-table table-client">
      <tbody>
      <tr>
        <td>
          {i18n.t('commissions.edit.interval')}
        </td>
      </tr>
      <tr>
        <td className={(inputErrors.hasOwnProperty('name') ? 'has-error' : '')}>
          <Select
            className={"react-select-container select-pseudo-input"}
            classNamePrefix="react-select"
            placeholder={i18n.t('commissions.edit.interval')}
            options={intervals}
            onChange={(value) => setFormInterval({...formInterval, name: value.value})}
            value={
              {
                label: formInterval.name,
                value: formInterval.name
              }
            }
            />
            {inputErrors.hasOwnProperty('name') &&
              <span className="error-text"> {i18n.t('errors.select_not_to_be_empty')} </span>
            }
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.date_start')}
        </td>
      </tr>
      <tr>
        <td>
          <DatePicker
            className={inputErrors['date_start'] ? 'has-error' : ''}
            selected={formInterval.dateStart ? getUtcDate(formInterval.dateStart).toDate() : ''}
            onChange={value => onDateChange('dateStart', value)}
            dateFormat={i18n.t('calendar_date_format')}
          />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('clients.edit.date_end')}
        </td>
      </tr>
      <tr>
        <td>
          <DatePicker
            className={inputErrors['date_end'] ? 'has-error' : ''}
            selected={formInterval.dateEnd ? getUtcDate(formInterval.dateEnd).toDate() : ''}
            onChange={value => onDateChange('dateEnd', value)}
            dateFormat={i18n.t('calendar_date_format')}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default Form;
