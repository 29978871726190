import React, {useState} from 'react';
import store from './store/store';
import Router from './routes/index'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {setUserData} from './store/actions';
import keycloak from './keycloak'

const keycloakOptions = {
  onLoad: 'login-required'
}

const onToken = () => {
  if (keycloak.token && keycloak.refreshToken) {
    store.dispatch(setUserData(keycloak.token))
    localStorage.setItem("token", keycloak.token);
    localStorage.setItem("refresh-token", keycloak.refreshToken);
  }
}

const onTokenExpired = () => {
  keycloak
    .updateToken(30)
    .then(() => {
      localStorage.setItem("token", keycloak.token);
      localStorage.setItem("refresh-token", keycloak.refreshToken);
    })
    .catch(() => {
      console.error("failed to refresh token");
    })
}

function App() {
  const [isUser, setIsUser] = useState(false)

  function getName(state) {
    return state.user.username
  }

  let currentValue

  function handleChange() {
    let previousValue = currentValue
    currentValue = getName(store.getState())

    if (currentValue && previousValue !== currentValue) {
      setIsUser(true)
    }
  }

  store.subscribe(handleChange)

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<div>Loading...</div>}
      initOptions={keycloakOptions}
      onTokens={onToken}
      onTokenExpired={onTokenExpired}
    >
      {isUser &&
        <Router />
      }

      <ToastContainer />
    </ReactKeycloakProvider>
  )
}

export default App;