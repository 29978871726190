import React, {Component} from 'react';
import {api} from '../api/apiProvider';
import SubMenu from '../Components/SubMenu';
import MenuClient from '../Components/MenuClient';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import Input from '../Components/Inputs/Input';
import {cloneDeep} from 'lodash';
import i18n from "i18next";
import i18next from "i18next";
import ReactPaginate from "react-paginate";

class Advertising extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisings: [],
      budgetChanges: {},
      projectsTotalCount: 0,
      perPage: 30,
      page: 1,
    }
  }

  componentDidMount() {
    this.updateData()
  }

  updateData = async () => {
    let url = "/adverts?t[]=advert_list&company.id=" +
      this.props.match.params.companyID + '&page=' + this.state.page;

    api.get(url).then((data) => {
      const advertisings = data['hydra:member'];
      this.setState({advertisings: advertisings});
      this.setState({projectsTotalCount: data['hydra:totalItems']})
    })
  }

  deleteAdvertisings = async (advertisingid) => {
    try {
      await api.delete("/adverts/" + advertisingid);
      this.updateData();
      toast.success(i18n.t('clients.edit.advert_deleted'));
    } catch (error) {
      toast.error(i18n.t('clients.edit.delete_fail'))
    }
  }

  onBudgetChange = (value, advertisingid) => {
    const budgetChanges = cloneDeep(this.state.budgetChanges);

    budgetChanges[advertisingid] = parseFloat(value);

    this.setState({budgetChanges: budgetChanges});
  }

  onBudgetSave = async () => {
    const budgetChanges = this.state.budgetChanges;
    for (const key in budgetChanges) {
      if (budgetChanges.hasOwnProperty(key)) {
        const newBudget = budgetChanges[key];
        const body = {
          budget: newBudget
        }
        try {
          await api.patch("/adverts/" + key, body);
          toast.success(i18n.t('clients.edit.budget_changed'))
        } catch (error) {
          toast.error(i18n.t('clients.edit.save_fail'))
        }
      }
    }
  }

  onClickSave = () => {
    this.onBudgetSave();
  }

  handlePageClick = (data) => {
    if (data.selected !== undefined) {
      this.setState({page: (+data.selected + 1)}, () => {
        this.updateData()
      })
    }
  }

  render() {
    return (
      <div className="row content">
        <div className="edit-client">
          <SubMenu/>
          <div className="table-edit-client advertising-table">
            <MenuClient title={i18n.t('clients.edit.advertisings')}>
              <Link className="blue-cursor-button"
                    to={"/advertisings/new?company_id=" + this.props.match.params.companyID}>
                <i className="fal fa-plus mr-for-icon"></i>
                {i18n.t('clients.edit.add_new')}
              </Link>
              <button className="button-accent ml-2" onClick={this.onClickSave}>
                {i18n.t('clients.edit.save')}
              </button>
            </MenuClient>
            <table className="mediaplans redesign-table regional-client">
              <thead>
              <tr>
                <th>{i18n.t('clients.edit.name')}</th>
                <th>{i18n.t('clients.page.product')}</th>
                <th>{i18n.t('clients.edit.budget')}</th>
                <th>{i18n.t('clients.edit.budget_id')}</th>
                <th>{i18n.t('clients.edit.date_start')}</th>
                <th>{i18n.t('clients.edit.date_end')}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {this.state.advertisings.map((advertising, index) => (
                <tr key={index}>
                  <td>
                    {advertising.name}
                  </td>
                  <td>
                    {advertising.product.name}
                  </td>
                  <td>
                    <Input value={this.state.budgetChanges[advertising.id] || advertising.budget}
                           onChange={(value) => this.onBudgetChange(value, advertising.id)}/>
                  </td>
                  <td>
                    {advertising.budgetCode}
                  </td>
                  <td>
                    {moment(advertising.dateStart).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    {moment(advertising.dateEnd).format("YYYY-MM-DD")}
                  </td>
                  <td className="right-text">
                    <Link to={"/advertisings/" + advertising.id}>
                      <i className="fal fa-edit"></i>
                    </Link>
                    <button onClick={() => this.deleteAdvertisings(advertising.id)}>
                      <i className="fal fa-trash-alt icon-delete"></i>
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        {(this.state.projectsTotalCount > 0 && this.state.projectsTotalCount > this.state.perPage) &&
        <ReactPaginate
          previousLabel={i18next.t('projects.list.prev')}
          nextLabel={i18next.t('projects.list.next')}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(this.state.projectsTotalCount / this.state.perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
        }
      </div>
    );
  }
}

export default Advertising;