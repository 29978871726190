import React from 'react'
import Select from "react-select";
import i18n from "i18next";

function FormSelect(props) {
  return (
    <div className='popup-form__row'>
      <div className='popup-form__row-title'>
        {props.label}
      </div>
      <span className="input-wrap">
            <Select
              className="react-select-container select-pseudo-input small"
              classNamePrefix="react-select"
              placeholder={props.placeholder || (i18n.t('periods.face.select'))}
              options={props.options}
              value={props.value}
              onChange={props.onChange}
              isMulti={props.isMulti}
              isDisabled={props.isDisabled}
            />
          </span>
    </div>
  )
}

export default FormSelect