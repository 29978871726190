import React, {useEffect} from 'react';
import SubMenu from '../../../Components/SubMenu';
import MenuClient from '../../../Components/MenuClient';
import i18n from "i18next";
import Table from "./Table";
import apiUrls from "../../../ApiUrls";
import useApiFetch from "../../../Components/Hooks/useApiFetch";

function List(props) {
  const companyId = props.match.params.companyID

  const [{data: clientManagers}, getClientManagers] = useApiFetch(
    apiUrls.get.clientManagers(companyId)
  );

  useEffect(() => {
    getClientManagers()
  }, [])

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu/>
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('clients.edit.client_manager')}/>
            {clientManagers &&
              <Table
                clientManagers={clientManagers}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;