import React from "react";
import i18next from './i18n'
import {toast} from "react-toastify";
import {setErrors, setError, deleteError, deleteErrors} from './store/actions';
import store from './store/store';
import sprintf from 'sprintf-js'
import i18n from "i18next";
import {getUtcDate} from "./Views/Financial/FacesTotal/Utils";

const vsprintf = sprintf.vsprintf

export const parseJwt = function (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))

  return JSON.parse(jsonPayload);
};

export const isFloat = (number) => {
  return Number(number) === parseFloat(number) && number % 1 !== 0
}

export const isInteger = (number) => {
  return Number(number) === parseInt(number) && number % 1 === 0
}

export const parsedInt = (value) => {
  return isNaN(parseInt(value)) ? 0 : parseInt(value)
}

export const renderSortIcon = (name, sort, sortAscString, sortDescString) => {
  let sortClass = ''
  let sortTitle = ''

  if (!sort[name]) {
    sortClass = 'fa-sort'
    sortTitle = i18next.t('clients.advertising.sort_asc')
  }

  if (sort[name] === sortAscString) {
    sortClass = 'fa-sort-asc'
    sortTitle = i18next.t('clients.advertising.sort_desc')
  }

  if (sort[name] === sortDescString) {
    sortClass = 'fa-sort-desc'
    sortTitle = i18next.t('clients.advertising.sort_cancel')
  }

  return <i className={"fa " + sortClass} aria-hidden="true"
    title={sortTitle} />
}

export const getSortParams = (sort) => {
  const sortParams = new URLSearchParams()

  for (let key in sort) {
    if (!sort.hasOwnProperty(key)) {
      continue
    }

    sortParams.append('order[' + key + ']', sort[key])
  }

  return sortParams
}

export const getFiltersQuery = function (filter, page = null, sort = null) {
  let searchParams = new URLSearchParams();

  for (let name in filter) {
    if (!filter.hasOwnProperty(name)) {
      continue
    }

    if (Array.isArray(filter[name])) {
      for (let filterValue of filter[name]) {
        if (filterValue.value) {
          searchParams.append(name + '[]', filterValue.value)
        }
      }
    } else if ((typeof filter[name] === 'object' && filter[name] !== null) && filter[name].hasOwnProperty('value')) {
      if (filter[name].value) {
        searchParams.append(name, filter[name].value)
      }
    } else if (filter[name]) {
      searchParams.append(name, filter[name])
    }
  }

  if (page) {
    searchParams.append('page', page)
  }

  if (sort) {
    for (let key in sort) {
      if (!sort.hasOwnProperty(key)) {
        continue
      }

      searchParams.append(`order[${key}]`, sort[key])
    }
  }

  return searchParams.toString()
}

export const setUrlParams = (props, params) => {
  if (!Object.keys(params).length || !props) {
    return
  }

  let urlParams = []

  for (let key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      for (let value of params[key]) {
        urlParams.push(`${key}=${value}`)
      }
    } else {
      urlParams.push(`${key}=${params[key]}`)
    }
  }

  props.history.replace({
    pathname: props.history.location.pathname,
    search: urlParams.join('&')
  })
}

export const getFromLocation = (name, location) => {
  if (!name || !location || !location.search) {
    return null
  }

  let queries = location.search.replace('?', '').split('&')

  for (let query of queries) {
    let params = query.split('=')

    if (params.shift() === name) {
      return +params.pop()
    }
  }

  return null
}

/**
 * @param error
 */
export const showApiErrors = (error) => {
  if (error.violations) {
    let errors = error.violations

    for (let error of errors) {
      if (!error.message) {
        continue
      }

      if (error.message.includes(':')) {
        error.message = error.message.split(':').pop()
        continue
      }

      error.message = i18next.t(error.message)
    }

    let inputErrors = errors.filter((error) => {
      return error.propertyPath
    })

    let inputErrorsOb = {}

    for (let error of inputErrors) {
      inputErrorsOb[error.propertyPath] = error.message
    }

    store.dispatch(setErrors(inputErrorsOb))

    errors
      .filter((violation) => {
        return violation.message
      })
      .forEach((violation) => {
        toast.error(<div>{violation.message}</div>)
      })
  } else if (error['hydra:description']) {
    let errors = error['hydra:description'].split(/\r\n|\r|\n/g)
    errors
      .forEach((error) => {
        if (i18next.exists(error)) {
          toast.error(<div>{i18next.t(error)}</div>)
        } else {
          toast.error(<div>{error}</div>)
        }
      })

  }
}

export const getInputErrors = (response) => {
  const errors = {}

  if (response.violations) {
    for (let violation of response.violations) {
      if (violation.message && violation.propertyPath) {
        const error = violation.message.trim().replace(/\./g, "")

        errors[violation.propertyPath] = i18next.exists(`errors.${error}`)
          ? i18next.t(`errors.${error}`)
          : error
      }
    }
  }

  return errors
}

export const addInputError = (name, error) => {
  logError(name)
  logError(error)

  store.dispatch(setError({
    key: name,
    error
  }))
}

export const deleteInputError = (key) => {
  store.dispatch(deleteError(key))
}

export const deleteInputErrors = () => {
  store.dispatch(deleteErrors())
}

export const logError = error => console.log(error)

const getTranslatedError = (error, description = '', translationPath = 'errors') => {
  if (i18next.exists(translationPath + '.' + error)) {
    return i18next.t(translationPath + '.' + error)
  }

  if (description) {
    return description + ': ' + error
  }

  return error
}

export const sprintToastError = (errorString, errorDescription, translationPath) => {
  let error = ''
  let errorArray = errorString.split(' ; ')

  if (errorArray.length <= 1) {
    error = getTranslatedError(errorString, errorDescription, translationPath)
  } else {
    const message = errorArray.shift().trim().replace(/\./g, "")

    const errorParams = errorArray.map(error => {
      if (i18next.exists(translationPath + '.' + error)) {
        return i18next.t(translationPath + '.' + error)
      }

      return error
    })

    error = vsprintf(getTranslatedError(message, errorDescription), errorParams)
  }

  toast.error(error)
}

export const sprintToastErrors = (response, errorDescription, translationPath) => {
  if (response.violations && response.violations.length) {
    for (let violation of response.violations) {
      if (!violation.message) {
        continue
      }

      const error = violation.propertyPath
        ? violation.message + ' ; ' + violation.propertyPath
        : violation.message

      sprintToastError(error, errorDescription, translationPath)
    }
  } else if (response['hydra:description']) {
    const errors = response['hydra:description'].split(/\r\n|\r|\n/g)

    const parsedErrors = errors.map(error => {
      if (error.includes(':')) {
        const arErrors = error.split(':')
        return arErrors.pop() + ' ; ' + arErrors.pop()
      } else {
        return error
      }
    })
    parsedErrors
      .forEach((error) => {
        sprintToastError(error, errorDescription, translationPath)
      })
  }
}

export const getUrlWithParams = (url, params = {}) => {
  if (typeof params !== 'object') {
    return url
  }

  let urlParams = []

  for (let key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      for (let value of params[key]) {
        urlParams.push(`${key}=${value}`)
      }
    } else {
      if (key !== 'callback') {
        urlParams.push(`${key}=${params[key]}`)
      }
    }
  }

  const urlParamsString = urlParams.join('&')

  const delimiter = url.includes('?')
    ? '&'
    : '?'

  return urlParams.length
    ? `${url}${delimiter}${urlParamsString}`
    : url
}

export const getNumber = (value, allowEmpty = false) => {
  if (typeof value === 'number' && isFinite(value)) {
    return value
  }

  if (typeof value === 'string') {
    if (!value) {
      return allowEmpty ? '' : 0
    }

    value = value.replace(',', '.').replace(/[^.\d]+/g, "")

    const parsedFloat = parseFloat(value)

    if (isNaN(parsedFloat)) {
      return allowEmpty ? '' : 0
    }

    return parsedFloat
  }

  return value
}

export const getRowDataFromTableText = text => {
  const rows = text.split("\n")

  let data = []

  for (let row of rows) {
    const cells = row.split("\t")
      .map(cell => getNumber(cell))

    data.push(cells)
  }

  return data
}

export const getColumnDataFromTableText = (text, fields, selectedField) => {
  const data = getRowDataFromTableText(text)

  let columnsCount = 0;

  if (data[0]) {
    columnsCount = data[0].length
  }

  let columnsData = []

  for (let i = 0; i < columnsCount; i++) {
    for (let row of data) {
      if (!columnsData[i]) {
        columnsData[i] = []
      }

      columnsData[i].push(row[i])
    }
  }

  let fieldsData = {}
  let fieldIndex = null

  for (let field of fields) {
    if (field.code === selectedField) {
      fieldIndex = 0
    }

    if (fieldIndex !== null) {
      if (!fieldsData[field.code]) {
        fieldsData[field.code] = []
      }

      if (columnsData[fieldIndex]) {
        fieldsData[field.code] = columnsData[fieldIndex]
      }

      fieldIndex++
    }
  }

  return fieldsData
}

export const getTaxedValue = (value, tax) => {
  return value * (1 + tax/100)
}

export const isFieldEditable = (field) => {
  if (!field || typeof field !== 'object') {
    return false
  }

  return Boolean(field.editable)
}

export const getValuesFromUrl = () => {
  const values = []
  const params = new URLSearchParams(window.location.search.substring(1))

  params.forEach((value, key) => {
    if (!values[key]) {
      values[key] = []
    }
    values[key].push(value)
  });

  return values
}

export const getFieldValue = (field, allowEmpty = false) => {
  if (!field) {
    return allowEmpty ? '' : 0
  }

  return (typeof field !== 'object')
    ? field
    : field.value || (allowEmpty ? '' : 0)
}

export const downloadFile = (file, fileName) => {
  const downloadLink = window.document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(file);
  downloadLink.download = fileName;
  downloadLink.click();
}

export const isRequiredFilterValue = (filter, filterName) => {
  if (filter.hasOwnProperty(filterName)) {
    const filterValue = filter[filterName]

    if (!filterValue) {
      return false
    }

    if (Array.isArray(filterValue)) {
      if (!filterValue.length) {
        return false
      }
    } else if (typeof filterValue === 'object') {
      if (filterValue && !filterValue.value) {
        return false
      }
    } else if (!filterValue) {
      return false
    }
  }

  return true

}

export const getCoefficientPercent = (coefficient) => {
  return (1 - coefficient) * 100
}

export const getOptions = items => {
  let options = []

  if (!items || !items.length) {
    return options
  }

  for (let item of items) {
    if (item['@id'] && item.name) {
      options.push({
        value: item['@id'],
        label: item.name
      })
    }
  }

  return options
}

export const getOptionsWithEmpty = items => {
  let options = getOptions(items)

  options.unshift({
    value: null,
    label: i18n.t('projects.list.not_selected')
  })

  return options
}

export const getRoundedNumber = function (number, places) {
  return +(Math.round(number + "e+" + places) + "e-" + places);
}

export const isFloatNumbersEqual = (numberOne, numberTwo, afterDotNumbersCount) => {
  if (Number.isNaN(numberOne) || Number.isNaN(numberTwo) || numberOne === null || numberTwo === null) {
    return false
  }

  return getRoundedNumber(numberOne, afterDotNumbersCount) === getRoundedNumber(numberTwo, afterDotNumbersCount)
}

export const groupParamsByKey = (params) => params ? [...params.entries()].reduce((acc, tuple) => {
 const [key, val] = tuple;
  if(acc.hasOwnProperty(key)) {
    if(Array.isArray(acc[key])) {
      acc[key] = [...acc[key], val]
    } else {
      acc[key] = [acc[key], val];
    }
  } else {
    acc[key] = val;
  }

  return acc;
}, {}) : {};

export const getAdvertisingDates = (dateStart, dateEnd, periodStart, periodEnd) => {
  const periodStartDate = getUtcDate(periodStart).toDate()
  const periodEndDate = getUtcDate(periodEnd).toDate()

  const advertisingStartDate = getUtcDate(dateStart).toDate()
  const advertisingEndDate = getUtcDate(dateEnd).toDate()

  const start = advertisingStartDate > periodStartDate
    ? advertisingStartDate
    : periodStartDate

  const end = advertisingEndDate < periodEndDate
    ? advertisingEndDate
    : periodEndDate

  return [start, end];
}