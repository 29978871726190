import {createStore, combineReducers} from 'redux';
import {SET_USER_DATA, SET_ERROR, SET_ERRORS, DELETE_ERROR, DELETE_ERRORS, ADD_SUBSCRIBED} from './actions';

const user = (state = {
  username: null,
  roles: []
}, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        username: action.user.username,
        roles: action.user.roles
      }

    default:
      return state;
  }
}

const inputErrors = (state = {}, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        [action.error.key]: action.error.error
      }

    case SET_ERRORS:
      return action.errors

    case DELETE_ERROR:
      let errors = state

      for (let key in errors) {
        if (key === action.key) {
          delete(errors[key])
        }
      }

      return errors

    case DELETE_ERRORS:
      return {}

    default:
      return state
  }
}

const subscribed = (state = {}, action) => {
  switch (action.type) {
    case ADD_SUBSCRIBED:
      return {
        ...state,
        [action.url]: action.url,
      }

    default:
      return state;
  }
}

const reducer = combineReducers({
  user,
  inputErrors,
  subscribed,
})

const store = createStore(reducer);
store.subscribe(() => {
  if (process.env.NODE_ENV === "development") {
    //console.log('store:', store.getState());
  }
})

export default store;