import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import {toast} from "react-toastify";
import FormSelect from "../../Components/Inputs/FormSelect";
import FloatInput from "../../Components/Inputs/FloatInput";
import apiUrls from "../../ApiUrls";
import Input from "../../Components/Inputs/Input";
import {getUtcDate} from "../Advertising/Detail/Fact/Utils";
import {ChannelFactory} from "../../entities/channel/ChannelFactory";

const dateFormat = 'Y-MM-DD'

function InvoiceForm(props) {
  const getAdvertsId = () => {
    return props.faces.map(face => face.advertising.id)
  }

  const channel = props.channels.find(channel => channel.id == props.channel.value)

  const channelEntity = ChannelFactory.create(channel.code);

  const [formInvoice, setFormInvoice] = useState({})

  const [selectedRegistry, setSelectedRegistry] = useState({})

  const [{data: registries}, getRegistries] = useApiFetch(apiUrls.get.registries())

  const [{errors: updateErrors}, updateInvoice] = useApiPatch(
    apiUrls.patch.invoice,
    '',
    'invoice.edit',
    () => {
      props.setSelectedFaces([])
      props.updateData()
      props.setIsInvoicePopup(false)
      toast.success(i18n.t('periods.face.saved'))
    }
  )

  const getDataFromInvoice = (formInvoice) => {
    let form = {}
    form.channel = `/channels/${formInvoice.channel.value}`
    form.advertising = `/adverts/${formInvoice.faces[0].advertising.id}`
    form.work = `/works/${formInvoice.faces[0].work.id}`
    form.expenses = parseFloat(formInvoice.expenses)

    if (formInvoice.registry && formInvoice.registry['@id']) {
      form.registry = formInvoice.registry['@id']
    }

    form[channelEntity.facesName] = formInvoice.faces.map(face => face.face['@id'])

    return {
      rows: [
        form
      ]
    }
  }

  const getFacesSpentSum = () => {
    const expensesSum = (props.faces && props.faces.length)
      ? props.faces.reduce((acc, face) => acc + face.spent, 0)
      : 0

    return Math.round((expensesSum + Number.EPSILON) * 100) / 100
  }

  useEffect(() => {
    getRegistries({
      'pagination': 'false',
      'advertising.id': getAdvertsId(),
      'dateEnd[after]': props.date,
      'dateStart[before]': getUtcDate(props.date).endOf('month').format(dateFormat)
    })
  }, [])

  useEffect(() => {
    if (props.invoice) {
      setFormInvoice({
        expenses: getFacesSpentSum(),
        channel: props.channel,
        advertising: props.invoice.advertising,
        work: props.invoice.work,
        registry: props.invoice.registry,
        faces: props.faces
      })
    }
  }, [props.invoice])

  useEffect(() => {
    if (props.invoice && registries) {
      const selectedRegistry = registries.find(registry =>
        registry['@id'] === props.invoice.registry
      )

      if (selectedRegistry) {
        setSelectedRegistry({
          '@id': selectedRegistry['@id'],
          name: selectedRegistry.name,
        })
      }
    }
  }, [props.invoice, registries])

  useEffect(() => {
    if (formInvoice.registry && formInvoice.registry['@id']) {
      setSelectedRegistry({
        value: formInvoice.registry['@id'],
        label: formInvoice.registry.name,
      })
    }
  }, [formInvoice])

  const onChange = (value, name) => {
    if (value.value) {
      setFormInvoice({
        ...formInvoice,
        [name]: {
          '@id': value.value,
          name: value.label
        }
      })
    } else {
      setFormInvoice({
        ...formInvoice,
        [name]: value
      })
    }
  }

  const save = () => {
    const data = getDataFromInvoice(formInvoice)

    updateInvoice(props.invoice.id, data)
  }

  const registryOptions = registries
    ? registries.map((registry) => {
      return {value: registry['@id'], label: registry.title} /*Временное решение - составное имя счета*/
    })
    : []

  const getSelectedRegistryExpenses = () => {
    if (selectedRegistry && selectedRegistry.value) {
      const registry = registries.find(registry => registry['@id'] === selectedRegistry.value)

      if (registry && registry.expenses) {
        return registry.expenses
      }
    }

    return -1
  }

  return (
    <div className='popup-form invoice-form'>
      <div className='popup-form__background'
        onClick={() => props.setIsInvoicePopup(false)} />
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => props.setIsInvoicePopup(false)}>
            <i className='fas fa-remove' />
          </button>
        </div>
        <div className='popup-form__body'>
          <div className='popup-form__rows'>
            <FormSelect
              name='platform'
              value={selectedRegistry}
              options={registryOptions}
              label={i18n.t('invoice.edit.registry')}
              onChange={(value) => onChange(value, 'registry')}
            />

            <div className={'popup-form__row'}>
              {getSelectedRegistryExpenses() >= 0 &&
              <>
                <div className={'popup-form__row-title'}>
                  {i18n.t('invoice.edit.registry_expenses')}
                </div>
                <Input
                  type='number'
                  value={getSelectedRegistryExpenses()}
                  disabled
                />
              </>
              }
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('face.list.contractor_budget_fact_without_vat')}
              </div>
              {formInvoice.expenses !== undefined &&
              <FloatInput
                value={formInvoice.expenses}
                onChange={(val) => {
                  if (val) {
                    onChange(val, 'expenses')
                  }
                }}
              />
              }
            </div>

            <div className={'popup-form__row'}>
              <table className="table redesign-table table-client">
                <thead>
                <tr>
                  <th>{i18n.t('invoice.edit.selected_faces')}</th>
                  <th>{i18n.t('invoice.edit.spent')}</th>
                </tr>
                </thead>
                <tbody>
                {props.faces.map(face => (
                  <tr
                    key={face.face.id}
                  >
                    <td>{face.face.name}</td>
                    <td className={'money'}>{face.spent.toFixed(props.fractionDigits)}</td>
                  </tr>
                ))}
                </tbody>
              </table>

              <div className={'popup-form__row-title'}>
                <span>{i18n.t('invoice.edit.selected_faces_sum')}</span> - <span>{getFacesSpentSum()}</span>
              </div>
            </div>

          </div>
          <div className={'popup-form__buttons'}>
            <button className="popup-form__button button-accent" onClick={save}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceForm