import React, {useEffect, useState} from 'react';
import xlsServiceProvider from "../../api/xlsServiceProvider";
import DownloadFileButton from "../../Components/Buttons/DownloadFileButton";

function DownloadMediaplanXls({advertisingId}) {
  return (
    <>
      {advertisingId &&
        <DownloadFileButton
          text={`Скачать медиаплан в формате xls`}
          getFunction={() => xlsServiceProvider.getForAdvertisng(advertisingId)}
          fileName={`mediaplan_${advertisingId}.xlsx`}
        />
      }
    </>
  )
}

export default DownloadMediaplanXls