import React, {useEffect, useState} from 'react'
import MenuClient from '../../../Components/MenuClient'
import i18n from "i18next"
import Table from "./Table"
import apiUrls from "../../../ApiUrls"
import useApiFetch from "../../../Components/Hooks/useApiFetch"
import Filter from "../../../Components/NewFilter/Filter";
import {getUtcDate} from "../FacesTotal/Utils";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../Components/Loader/Loader";
import Protected from "../../../Components/Roles/Protected";
import {Link} from "react-router-dom";
import {groupParamsByKey} from "../../../utils";

const dateFormat = 'Y-MM-DD'

function List() {
  const [filterParams, setFilterParams] = useState('')

  const [{data: clientInvoices, isLoading: isClientInvoicesLoading}, getClientInvoices] = useApiFetch(apiUrls.get.clientInvoices())

  const filters = {
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], dateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(dateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(dateFormat)
        const endDate = dateValue.endOf('month').format(dateFormat)
        params.append('dateStart[after]', startDate)
        params.append('dateEnd[before]', endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: dateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, values) => {
        params.delete('client.id[]')
        for (let filterValue of values) {
          params.append('client.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, company) => {
        if (company && company.length) {
          params.delete('company[]')
          for (let companyItem of company) {
            params.append('company[]', companyItem.value)
          }
        } else {
          params.delete('company[]')
        }
      },
      isMulti: true,
    },
  }

  useEffect(() => {
    if (filterParams) {
      getClientInvoices(getDataParams())
    }
  }, [filterParams])

  const getDataParams = () => {
    const params = {
      't[]': ['target'],
    }

    let filters = {}

    if (filterParams) {
      filters = groupParamsByKey(filterParams)
    }

    return {...params, ...filters}
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  return (
    <div className="row content flex">
      <div className={"table-edit-client " + (isClientInvoicesLoading ? ' loading' : '')}>
        <div>
          <MenuClient title={'Документы клиентам'}>
            {clientInvoices &&
              <Protected access={'FinancialCheckAdd'}>
                <Link to={'/financial/client_invoices/new'}>
                  <i className="fal fa-plus mr-for-icon" />
                  {'Добавить'}
                </Link>
              </Protected>
            }

          </MenuClient>
          <Filter
            filters={Object.values(filters)}
            getData={setParams}
          />

          <Table
            clientInvoices={clientInvoices}
            getData={() => getClientInvoices(getDataParams())}
          />

          {isClientInvoicesLoading &&
            <Loader />
          }

          {isClientInvoicesLoading &&
            <LoaderBackdrop />
          }
        </div>
      </div>
    </div>
  );
}

export default List;