import React, {useState, useEffect} from 'react'
import i18n from "i18next";
import moment from "moment";
import MenuClient from "../../Components/MenuClient";
import {Link} from "react-router-dom";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import useApiPost from "../../Components/Hooks/useApiPost";
import useApiPatch from "../../Components/Hooks/useApiPatch";
import apiUrls from "../../ApiUrls"
import FaceForm from "./FaceForm";
import {toast} from "react-toastify";
import useDelete from "../../Components/Hooks/useDelete";

function FaceEdit(props) {
  const registryId = props.match.params.registryId

  const [formFace, setFormFace] = useState({
    registry: `/financial/registries/${registryId}`
  })
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [selectedFaces, setSelectedFaces] = useState(null)
  const [selectedAdvert, setSelectedAdvert] = useState(null)
  const [inputErrors, setInputErrors] = useState([])
  const [faces, setFaces] = useState(null)
  const [advertName, setAdvertName] = useState('')

  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: registry}, getRegistry] = useApiFetch(apiUrls.get.registry(registryId))
  const [{data: digitalFaces}, getDigitalFaces] = useApiFetch(apiUrls.get.digitalFaces())
  const [{data: internetFaces}, getInternetFaces] = useApiFetch(apiUrls.get.internetFaces())
  const [{data: adverts}, getAdverts] = useApiFetch(apiUrls.get.adverts())

  const [{errors: addErrors}, addFace] = useApiPost(
    apiUrls.post.registryFace(),
    '',
    'registryFaces.edit',
    (id) => {
      toast.success(i18n.t('registryFaces.edit.added'))

      if (id) {
        props.history.push(`/registry/${registryId}`)
      }
    }
  )

  const [{errors: updateErrors}, updateFace] = useApiPatch()

  useEffect(() => {
    getChannels({
      'code[]': [
        'internet',
        'digital',
      ],
    })
    getRegistry({
      't[]': 'face_add'
    })
  }, [])

  useEffect(() => {
    if (registry && registry.client && registry.client.id) {
      getAdverts({
        't[]': 'short',
        'company.id': registry.client.id,
        'name': advertName,
        'dateStart[after]': moment(registry.dateStart).subtract(6, 'month').format('DD.MM.YYYY'),
        'dateEnd[before]': moment(registry.dateEnd).add(6, 'month').format('DD.MM.YYYY'),
      })
    }
  }, [registry, advertName])

  useEffect(() => {
    if (addErrors) {
      setInputErrors(addErrors)
    } else if (updateErrors) {
      setInputErrors(updateErrors)
    } else {
      setInputErrors([])
    }
  }, [addErrors, updateErrors])
  
  useEffect(() => {
    if (selectedChannel) {
      if (selectedChannel.label === "Performance") {
        getInternetFaces()
      } else {
        getDigitalFaces()
      }
    }
  }, [selectedChannel])

  useEffect(() => {
    let faces = []

    if (internetFaces) {
      for (let internet of internetFaces) {
        faces.push(internet)
      }
    }

    if (digitalFaces) {
      for (let digital of digitalFaces) {
        faces.push(digital)
      }
    }

    setFaces(faces)
  }, [digitalFaces, internetFaces])

  const onClickSave = () => {
    //isNew()
      addFace(formFace)
  }

  const advertOptions = adverts
    ? adverts.map(advert => ({value: advert['@id'], label: advert.name}))
    : []

  const channelOptions = channels
    ? channels.map(channel => ({value: channel['@id'], label: channel.name}))
    : []

  const faceOptions = faces
    ? faces.map(face => ({value: face['@id'], label: face.name}))
    : []

  return (
    <div className="row content flex">
      <div className="edit-client">
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('registryFaces.edit.title')}>
              <button className="button-accent" onClick={onClickSave}>
                {i18n.t('clients.edit.save')}
              </button>
              <Link className="blue-cursor-button back-button" to={'/registries'}>
                {i18n.t('registries.edit.back')}
              </Link>
            </MenuClient>
            <FaceForm
              formFace={formFace}
              setFormFace={setFormFace}
              inputErrors={inputErrors}
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              selectedFaces={selectedFaces}
              setSelectedFaces={setSelectedFaces}
              selectedAdvert={selectedAdvert}
              setSelectedAdvert={setSelectedAdvert}
              advertName={advertName}
              setAdvertName={setAdvertName}
              channels={channels}
              digitalFaces={digitalFaces}
              getDigitalFaces={getDigitalFaces}
              internetFaces={internetFaces}
              getInternetFaces={getInternetFaces}
              advertOptions={advertOptions}
              channelOptions={channelOptions}
              faceOptions={faceOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceEdit;