import i18n from "i18next";
import sprintf from 'sprintf-js'
import {getUtcDate} from "./Fact/Utils";
import {cloneDeep} from 'lodash'

const vsprintf = sprintf.vsprintf

export const getConfirmMessage = (advertising, period, withGoal = true) => {
  const budgetMarginError = process.env.REACT_APP_BUDGET_MARGIN_OF_ERROR ? process.env.REACT_APP_BUDGET_MARGIN_OF_ERROR : 0.05

  let errors = {}

  let totalSumBudget = 0
  let totalSumGoal = 0

  for (let intervalIndex in period.intervals) {
    if (!period.intervals.hasOwnProperty(intervalIndex)) {
      continue
    }

    let interval = period.intervals[intervalIndex]

    for (let detailIndex in interval.details) {
      if (!interval.details.hasOwnProperty(detailIndex)) {
        continue
      }

      let detail = interval.details[detailIndex]
      const budgetValue = (detail.budget !== undefined && detail.budget.value !== undefined)
        ? detail.budget.value
        : detail.budget

      totalSumBudget += (Number(budgetValue))

      const goalValue = (detail.goal !== undefined && detail.goal.value !== undefined)
        ? detail.goal.value
        : detail.goal

      if (withGoal) {
        totalSumGoal += (Number(goalValue))
      }

    }
  }

  const budgetDelta = Number(totalSumBudget - period.budget)
  const goalDelta = Number(totalSumGoal - period.goal)

  const goalMessage = getGoalMessage(goalDelta)
  const budgetMessage = getBudgetMessage(budgetDelta, advertising)

  return (goalMessage + budgetMessage) ? (goalMessage + budgetMessage + '\n' + i18n.t('periods.edit.save')) : ''
}

const getGoalMessage = (delta) => {
  const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS
  const deltaMessage = getDeltaMessage(delta)
  const deltaSum = Math.abs(delta).toFixed(fractionDigits)

  return delta ? vsprintf(i18n.t('periods.edit.goal_delta_message'), [deltaMessage, deltaSum]) : '';
}

const getBudgetMessage = (delta, advertising) => {
  const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS
  const advertisingBudget = Number(advertising.budget).toFixed(fractionDigits)
  const advertisingNewBudget = Number(advertising.budget + delta).toFixed(fractionDigits)
  const deltaMessage = getDeltaMessage(delta)
  const deltaSum = Math.abs(delta).toFixed(fractionDigits)

  return delta
    ? vsprintf(i18n.t('periods.edit.budget_delta_message'), [deltaMessage, deltaSum]) +
      vsprintf(i18n.t('periods.edit.budget_before_message'), [advertisingBudget]) +
      vsprintf(i18n.t('periods.edit.budget_after_message'), [advertisingNewBudget])
    : '';
}

const getDeltaMessage = delta => delta > 0 ? i18n.t('periods.edit.up') : i18n.t('periods.edit.down')


export const getMonthPeriods = (startDate, endDate) => {
  let dateStart = getUtcDate(startDate)
  const datePeriodEnd = getUtcDate(endDate)
  const dates = []

  while (dateStart <= datePeriodEnd) {
    let dateEnd = cloneDeep(dateStart)
    dateEnd = getUtcDate(dateEnd.endOf("month"))

    dates.push({
      dateStart: cloneDeep(dateStart),
      dateEnd: dateEnd
    })

    dateStart = getUtcDate(cloneDeep(dateEnd).add(1, 'months').startOf('month').toISOString())
  }

  if (dates.length) {
    dates[dates.length - 1].dateEnd = datePeriodEnd
  }

  return dates;
}