import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "./RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';
import i18n from "i18next";
import {useVT} from 'virtualizedtableforantd4';
import {Link} from "react-router-dom";
import FormattedNumber from "../../../Components/FormattedNumber";
import AllSum from "./AllSum";
import Checkbox from "../../../Components/Inputs/Checkbox";

export default function AdvertFacesTable({
                                           adverts, onAdvertChange, onFaceChange, setSelectedFace, internetGoals,
                                           onSaveAdvertData, saveFace, addFace, setAdverts
                                         }) {

  const [vt, set_components] = useVT(() => ({scroll: {y: 600}}), []);
  const [columns] = useState([
    {
      title: i18n.t('common.name'),
      width: 270,
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: i18n.t('common.contractor'),
      width: 200,
      dataIndex: 'contractorName',
    },
    {
      title: i18n.t('metrics.goal'),
      width: 200,
      dataIndex: 'goal',
    },
    {
      title: i18n.t('metrics.agent_budget'),
      width: 170,
      dataIndex: 'budget',
    },
    {
      title: i18n.t('metrics.agent_budget_approved'),
      width: 170,
      dataIndex: 'checkRowApproved',
    },
    {
      title: '',
      width: 40,
      dataIndex: 'checkRowBudgetCopy',
    },
    {
      title: i18n.t('metrics.contractor_budget'),
      width: 170,
      dataIndex: 'checkRowBudget',
    },
    {
      title: i18n.t('metrics.contractor_tax'),
      width: 170,
      dataIndex: 'checkRowTax',
    },
    {
      title: i18n.t('metrics.contractor_budget_with_tax'),
      width: 170,
      dataIndex: 'checkRowBudgetTax',
    },
    {
      title: i18n.t('metrics.bonus'),
      width: 170,
      dataIndex: 'bonus',
    },
    {
      title: i18n.t('metrics.agent_budget_bonus_diff'),
      width: 170,
      dataIndex: 'diffAgentBudgetAndBonus',
    },
    {
      title: i18n.t('metrics.client_budget'),
      width: 170,
      dataIndex: 'clientCheckRowBudget',
    },
    {
      title: i18n.t('metrics.client_budget_approved'),
      width: 170,
      dataIndex: 'clientCheckRowApproved',
    },
  ])
  const [isAllCheckRowChecked, setIsAllCheckRowChecked] = useState(null)
  const [isAllBudgetChecked, setIsAllBudgetChecked] = useState(null)

  useEffect(() => {
    if (isAllCheckRowChecked !== null) {
      checkAllCheckRow(isAllCheckRowChecked)
    }
  }, [isAllCheckRowChecked])

  useEffect(() => {
    if (isAllBudgetChecked !== null) {
      checkAllBudget(isAllBudgetChecked)
    }
  }, [isAllBudgetChecked])

  const advertFaceSumFields = [
    'budget',
    'goal',
    'checkRowBudget',
    'checkRowTax',
    'checkRowBudgetTax',
    'clientCheckRowBudget',
  ]

  const sums = useMemo(() => {
    const sums = {}

    adverts.forEach(advertising => {
      for (let platformName in advertising.faces) {
        for (let face of advertising.faces[platformName]) {
          for (let field of advertFaceSumFields) {
            if (!(field in sums)) {
              sums[field] = 0
            }

            if (field === 'budget') {
              if (face.channel.name !== 'Услуги') {
                if (!('nonServiceBudgetSum' in sums)) {
                  sums.nonServiceBudgetSum = 0
                }

                sums.nonServiceBudgetSum += face[field] || 0
              }

              sums[field] += face[field] || 0
            } else if (field === 'goal') {
              if (face.channel.name === 'CPA') {
                if (!('cpaGoalSum' in sums)) {
                  sums.cpaGoalSum = 0
                }

                sums.cpaGoalSum += face[field] || 0
              } else if (face.channel.name !== 'Услуги') {
                sums[field] += face[field] || 0
              }
            } else {
              sums[field] += face[field] || 0
            }
          }
        }
      }
    })

    if (sums && columns) {
      (Object.entries(columns)).map(([key, column], index) => {
        if (column.dataIndex === 'budget') {
          sums[column.dataIndex] = <AllSum
            data={[
              {value: sums[column.dataIndex], title: i18n.t('close_performance.all_sum')},
              {value: sums.nonServiceBudgetSum, title: i18n.t('close_performance.non_service_sum')}
            ]}
          />
        } else if (column.dataIndex === 'goal') {
          sums[column.dataIndex] = <AllSum
            data={[
              {value: sums[column.dataIndex], title: i18n.t('close_performance.conversion')},
              {value: sums.cpaGoalSum, title: i18n.t('close_performance.cpa_conversion')}
            ]}
          />
        } else if (column.dataIndex === 'checkRowApproved') {
          sums[column.dataIndex] = <div className={'detail-cell'}><Checkbox
            onChange={setIsAllCheckRowChecked}
            checked={isAllCheckRowChecked}
          /></div>
        } else if (column.dataIndex === 'clientCheckRowApproved') {
          sums[column.dataIndex] = <div className={'detail-cell'}><Checkbox
            onChange={setIsAllBudgetChecked}
            checked={isAllBudgetChecked}
          /></div>
        } else if (column.dataIndex === 'checkRowBudgetCopy') {
          sums[column.dataIndex] = <div className={'copy-budget'} onClick={() => copyAllBudget()}
                                        title={i18n.t('close_performance.copy_budget')}>
            <i className="fas fa-arrow-right"/>
          </div>
        } else {
          if (typeof sums[column.dataIndex] === 'string') {
            sums[column.dataIndex] = sums[column.dataIndex]
          } else if (typeof sums[column.dataIndex] === 'number') {
            sums[column.dataIndex] = <FormattedNumber
              value={sums[column.dataIndex]}
              type={'float'}/>
          }
        }
      })
    }

    return sums

  }, [adverts])

  const expandedRowRender = record => {
    const columns = [
      {
        title: 'Название ДБ',
        width: 170,
        dataIndex: 'nameFinancial',
      },
      {
        title: 'ID бюджета',
        width: 170,
        dataIndex: 'budgetCode',
      },
      {
        title: i18n.t('clients.edit.commentary'),
        width: 170,
        dataIndex: 'advertComment',
      },
      {
        title: i18n.t('clients.edit.dashboard'),
        width: 170,
        dataIndex: 'advertDashboard',
      },
      {
        title: 'Что принимаем за конверсию (Цели)',
        width: 170,
        dataIndex: 'advertTargetName',
      },
    ]

    const advertData = {
      nameFinancial: record.nameFinancial,
      budgetCode: record.budgetCode,
      advertComment: record.advertComment,
      advertDashboard: record.advertDashboard,
      advertTargetName: {
        label: record.advertTargetName,
        value: record.advertTargetName,
      },
    }

    const advertFields = {
      key: 'advert_table' + record.id,
    }

    for (let propertyCode in advertData) {
      if (advertData.hasOwnProperty(propertyCode)) {
        advertFields[propertyCode] = <RowCell
          className={'advert-field'}
          value={advertData[propertyCode]}
          onChange={value => {
            onAdvertChange(value, propertyCode, record.id)
          }}
          id={record.id}
          options={(propertyCode === 'advertTargetName') ? internetGoals.map(goal => ({
            label: goal.name,
            value: goal.name,
          })) : []}
          editable={true}
        />
      }
    }

    return <Table columns={columns} dataSource={[advertFields]} pagination={false}/>;
  }

  const checkAllCheckRow = value => {
    const newAdverts = new Map(adverts)
    newAdverts.forEach(advertising => {
      for (let faceChannelCode in advertising.faces) {
        const faces = advertising.faces[faceChannelCode]
        for (let face of faces) {
          if (face.hasOwnProperty('checkRowApproved')) {
            face.checkRowApproved = value
          }
        }
      }
    })

    setAdverts(newAdverts)
  }

  const checkAllBudget = value => {
    const newAdverts = new Map(adverts)
    newAdverts.forEach(advertising => {
      for (let faceChannelCode in advertising.faces) {
        const faces = advertising.faces[faceChannelCode]
        for (let face of faces) {
          if (face.hasOwnProperty('clientCheckRowApproved') && face.hasOwnProperty('checkRowApproved')
            && face.checkRowApproved) {
            face.clientCheckRowApproved = value
          }
        }
      }
    })

    setAdverts(newAdverts)
  }

  const copyBudget = (advertisingId, faceId) => {
    const newAdverts = new Map(adverts)

    if (newAdverts.has(advertisingId)) {
      const advert = newAdverts.get(advertisingId)

      for (let faceChannelCode in advert.faces) {
        const faces = advert.faces[faceChannelCode]
        for (let face of faces) {
          if (face.id === faceId) {
            face.checkRowBudget = face.budget
            face.clientCheckRowBudget = face.checkRowBudget - (face.bonus || 0)
          }
        }
      }
    }

    setAdverts(newAdverts)
  }

  const copyAllBudget = () => {
    const newAdverts = new Map(adverts)

    newAdverts.forEach(advertising => {
      for (let faceChannelCode in advertising.faces) {
        const faces = advertising.faces[faceChannelCode]
        for (let face of faces) {
          const isBudgetEditable = !face.contractor || !face.checkRowApproved
          if (isBudgetEditable && face.budget) {
            face.checkRowBudget = face.budget

            face.clientCheckRowBudget = face.checkRowBudget - (face.bonus || 0)
          }
        }
      }
    })

    setAdverts(newAdverts)
  }

  const data = useMemo(() => {
      const data = []

      adverts.forEach(advertising => {
        const advertFields = {
          key: 'advert' + advertising.id,
          id: advertising.id,
          advertName: advertising.advertName,
          name: <div className="period-face-title__wrapper">
            <div className="period-face-title">
              <Link to={advertising.factPageLink}>{advertising.advertName.toUpperCase()}</Link>
            </div>
            <div className={'button'}
                 onClick={() => addFace(advertising)}
                 title={i18n.t('face.add_face')}>
              <i className={'far fa-plus'}/>
            </div>
            <div className={'buttons'}>
              <div className={'button'}
                   onClick={() => onSaveAdvertData(advertising)}
                   title={i18n.t('common.save')}>
                <i className={'far fa-save'}/>
              </div>
            </div>
          </div>,
          nameFinancial: advertising.nameFinancial,
          budgetCode: advertising.budgetCode,
          advertComment: advertising.advertComment,
          advertDashboard: advertising.advertDashboard,
          advertTargetName: advertising.advertTargetName,
        }

        let cpaGoalSum = 0
        let nonServiceBudgetSum = 0

        for (let platformName in advertising.faces) {
          for (let face of advertising.faces[platformName]) {
            for (let field of advertFaceSumFields) {
              if (!(field in advertFields)) {
                advertFields[field] = 0
              }

              if (field === 'goal') {
                if (face.channel.name === 'CPA') {
                  cpaGoalSum += face[field] || 0
                } else if (face.channel.name !== 'Услуги') {
                  advertFields[field] += face[field] || 0
                }
              } else if (field === 'budget') {
                advertFields[field] += face[field] || 0
                if (face.channel.name !== 'Услуги') {
                  nonServiceBudgetSum += face[field] || 0
                }
              } else {
                advertFields[field] += face[field] || 0
              }
            }
          }
        }

        for (let field of advertFaceSumFields) {
          if (field === 'goal') {
            advertFields[field] = <AllSum
              data={[
                {value: advertFields[field], title: i18n.t('close_performance.conversion')},
                {value: cpaGoalSum, title: i18n.t('close_performance.cpa_conversion')}
              ]}
            />
          } else if (field === 'budget') {
            advertFields[field] = <AllSum
              data={[
                {value: advertFields[field], title: i18n.t('close_performance.all_sum')},
                {value: nonServiceBudgetSum, title: i18n.t('close_performance.non_service_sum')}
              ]}
            />
          } else {
            advertFields[field] = <FormattedNumber value={advertFields[field]} type={'float'}/>
          }
        }

        data.push(advertFields)

        for (let channelName in advertising.faces) {
          const item = {
            name: channelName,
            key: advertising.id + channelName,
          }

          data.push(item)

          for (let face of advertising.faces[channelName]) {
            const item = {
              name: <div className="period-face-title__wrapper">
                <div className={'period-face-title__block'}>
                  <div className="period-face-title">
                    <a
                      className={'detail-name'}
                      onClick={() => setSelectedFace({
                        id: face.id,
                        advertisingId: advertising.id
                      })}
                    >
                      {face.name}
                    </a>
                  </div>
                  <div className={'button'}
                       onClick={() => saveFace(face, advertising)}
                       title={i18n.t('common.save')}>
                    <i className={'far fa-save'}/>
                  </div>

                </div>
              </div>,
              key: 'face' + advertising.id + face.id,
            }

            const isBudgetEditable = !face.contractor || !face.checkRowApproved

            const itemParams = {
              budget: {value: face.budget || 0, editable: isBudgetEditable},
              goal: {value: face.goal || 0, editable: isBudgetEditable},
              budgetApproved: {value: face.budgetApproved, editable: true},
              clientCheckRowBudget: {value: face.clientCheckRowBudget || 0, editable: !face.clientCheckRowApproved},
              clientCheckRowApproved: {value: face.clientCheckRowApproved || false, editable: !isBudgetEditable},
            }

            if (face.contractor) {
              itemParams.contractorName = {value: face.contractor.name, editable: false}
              itemParams.checkRowBudget = {value: face.checkRowBudget || 0, editable: isBudgetEditable && face.budget}
              itemParams.checkRowApproved = {value: face.checkRowApproved || false, editable: face.checkRowBudget}
              itemParams.checkRowTax = {value: face.checkRowTax || 0, editable: isBudgetEditable && face.budget}
              itemParams.checkRowBudgetTax = {
                value: face.checkRowBudgetTax || 0,
                editable: isBudgetEditable && face.budget
              }
              itemParams.bonus = {value: face.bonus || 0, editable: true}

              itemParams.diffAgentBudgetAndBonus = {value: face.checkRowBudget - (face.bonus || 0), editable: false}
            } else {
              itemParams.checkRowBudget = {value: i18n.t('commissions.edit.empty_contractor'), editable: false}
              itemParams.checkRowTax = {value: i18n.t('commissions.edit.empty_contractor'), editable: false}
              itemParams.checkRowBudgetTax = {value: i18n.t('commissions.edit.empty_contractor'), editable: false}
            }

            if (itemParams.checkRowBudget && itemParams.checkRowBudget.editable) {
              item.checkRowBudgetCopy = <div className={'copy-budget'}
                                             onClick={() => copyBudget(advertising.id, face.id)}
                                             title={i18n.t('close_performance.copy_budget')}>
                <i className="fas fa-arrow-right"/>
              </div>
            }

            for (let propertyCode in itemParams) {
              item[propertyCode] = <RowCell
                value={itemParams[propertyCode].value}
                onChange={value => {
                  onFaceChange(value, propertyCode, face['@id'])
                }}
                onSave={() => saveFace(face, advertising)}
                id={face.id}
                editable={itemParams[propertyCode].editable}
              />
            }

            data.push(item)
          }
        }
      })

      return data
    }
    ,
    [adverts, isAllCheckRowChecked]
  )

  return <Table
    columns={columns}
    dataSource={data}
    expandable={{expandedRowRender, defaultExpandedRowKeys: ['1'], rowExpandable: record => record.advertName,}}
    pagination={false}
    summary={() => (
      <Table.Summary fixed={'top'}>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2}>
            {i18n.t('common.all')}
          </Table.Summary.Cell>
          {sums && columns && Object.entries(columns).filter(([key, column]) => column.dataIndex != 'name')
            .map(([key, column], index) => (
              <Table.Summary.Cell key={key}>
                {sums[column.dataIndex]}
              </Table.Summary.Cell>
            ))}
        </Table.Summary.Row>
      </Table.Summary>
    )}
    scroll={{
      x: '100%',
      y: '65vh',
    }}
    components={vt}
  />
}