import React from 'react';
import i18n from "i18next";
import Select from "react-select";

function DataSourceRow({datasource, properties, sources, onChange, isDisabled}) {
  const onKeyChange = (key) => {
    const value = Object.values(datasource)[0] || null

    onChange({
      [key]: value
    })
  }

  const onValueChange = (value) => {
    const key = Object.keys(datasource)[0] || null

    onChange({
      [key]: value
    })
  }

  const getOptions = items => {
    return items
      ? items.map((item) => {
        return {value: item.name, label: item.name}
      })
      : []
  }

  const propertiesOptions = getOptions(properties)

  const sourcesOptions = getOptions(sources)

  return (
    <div key={Object.keys(datasource).pop()} className={'datasource-item'}>
      <Select
        className="react-select-container select-pseudo-input small"
        classNamePrefix="react-select"
        placeholder={i18n.t('periods.face.select')}
        options={propertiesOptions}
        value={{
          value: Object.keys(datasource)[0],
          label: Object.keys(datasource)[0]
        }}
        onChange={value => onKeyChange(value.value)}
        isDisabled={isDisabled}
      />

      <Select
        className="react-select-container select-pseudo-input small"
        classNamePrefix="react-select"
        placeholder={i18n.t('periods.face.select')}
        options={sourcesOptions}
        value={{
          value: Object.values(datasource)[0],
          label: Object.values(datasource)[0]
        }}
        onChange={value => onValueChange(value.value)}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default DataSourceRow;