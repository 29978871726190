import React from 'react';
import i18n from "i18next";
import Select from "react-select";

function SelectField({title, options, onChange, selected, isMulti, className = 'react-select-container select-pseudo-input',
                       isDisabled = false, placeholder=i18n.t('commissions.edit.select')}) {
  return (
    <>
      <tr>
        <td>
          {title}
        </td>
      </tr>
      <tr>
        <td>
          <Select
            className={className}
            classNamePrefix="react-select"
            placeholder={placeholder}
            options={options}
            value={selected}
            onChange={onChange}
            isDisabled={isDisabled}
            isMulti={isMulti}
          />
        </td>
      </tr>
    </>
  )
}

export default SelectField;