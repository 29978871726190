import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import Input from "../../../../Components/Inputs/Input";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import {toast} from "react-toastify";
import FormSelect from "../../../../Components/Inputs/FormSelect";
import Select from "react-select";
import {deepClone} from "transliteration/dist/node/src/common/utils";
import FloatInput from "../../../../Components/Inputs/FloatInput";
import RatioBlock from "./RatioBlock";
import {getCoefficientPercent} from "../../../../utils";
import DataSourcesBlock from "./DataSourcesBlock";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import {getFormattedDate} from "../Fact/Utils";
import AccordionBlock from "./AccordionBlock";
import FactorsBlock from "./FactorsBlock";
import TrackingSourcesBlock from "./TrackingSourceBlock";
import {FaceFactory} from "../../../../entities/face/FaceFactory";
import Checkbox from "../../../../Components/Inputs/Checkbox";

const getRatioCoefficient = (value) => {
  return 1 - (value / 100)
}

const getRatioPercent = (value) => {
  return getCoefficientPercent(value)
}

const isFaceUnits = (face) => {
  return Boolean(face.faceUnits && face.faceUnits.length)
}

const emptyOption = {
  label: i18n.t('projects.list.not_selected'),
  value: null
}

const getDataFromFace = (formFace, ratios) => {
  let form = {}
  form.id = formFace.id
  form.code = formFace.code
  form.formatText = formFace.formatText
  form.name = formFace.name
  form.targeting = formFace.targeting
  form.datasources = formFace.datasources
  form.trackings = formFace.trackings
  form.targetings = formFace.targetings
  form.isRim = formFace.isRim
  form.isSendedOrd = formFace.isSendedOrd
  form.ordToken = formFace.ordToken
  form.frequency = parseFloat(formFace.frequency)
  form.advertising = formFace.advertising['@id']
  form.platform = formFace.platform['@id']
  form.strategy = formFace.strategy? formFace.strategy['@id'] : null
  form.channel = formFace.channel['@id']
  form.department = formFace.department ? formFace.department['@id'] : null
  form.media = formFace.media ? formFace.media['@id'] : null
  // form.source = formFace.source['@id']
  form.type = formFace.type['@id']
  form.formats = []
  form.faceUnits = []
  form.factors = []

  if (formFace.factors && formFace.factors.length) {
    for (let factor of formFace.factors) {
      form.factors.push({
        factor: factor.factor,
        value: factor.value,
      })
    }
  }

  if (formFace.contractor && formFace.contractor['@id']) {
    form.contractor = formFace.contractor['@id']
  } else {
    form.contractor = null
  }

  if (formFace.formats && formFace.formats.length) {
    for (let format of formFace.formats) {
      form.formats.push(format['@id'])
    }
  }

  if (isFaceUnits(formFace)) {
    for (let faceUnit of formFace.faceUnits) {
      let unitCost = null

      if (faceUnit.unitCost !== null) {
        unitCost = isNaN(parseFloat(faceUnit.unitCost)) ? null : parseFloat(faceUnit.unitCost)
      }

      if (!faceUnit.ratios || !faceUnit.ratios.length) {
        faceUnit.ratios = []
      }

      for (let ratio of ratios) {
        const ratioValue = faceUnit.ratios
          ? faceUnit.ratios.find(unitRatio => unitRatio.ratio['@id'] === ratio['@id'])
          : false

        if (!ratioValue) {
          faceUnit.ratios.push({
            ratio: {
              '@id': ratio['@id'],
              name: ratio.name
            },
            value: 1
          })
        }
      }

      for (let ratio of faceUnit.ratios) {
        if (ratio.ratio.asPercent) {
          ratio.value = getRatioCoefficient(ratio.value)
        }
      }

      const faceUnitParams = {
        unit: faceUnit.unit['@id'],
        unitCost: unitCost,
        ratios: faceUnit.ratios
      }

      if (formFace.id) {
        faceUnitParams.id = faceUnit.id
      }

      form.faceUnits.push(faceUnitParams)
    }
  }

  return form
}

const fractionDigits = process.env.REACT_APP_NUMBER_FRACTION_DIGITS

function DigitalForm(props) {
  const companyId = props.companyId

  const [formFace, setFormFace] = useState({})
  const [formatsOptions, setFormatsOptions] = useState([])
  const [selectedFormats, setSelectedFormats] = useState([])

  const [selectedPlatform, setSelectedPlatform] = useState({})
  const [selectedType, setSelectedType] = useState({})
  const [selectedStrategy, setSelectedStrategy] = useState({})
  const [selectedContractor, setSelectedContractor] = useState({})
  const [selectedUnit, setSelectedUnit] = useState({})
  const [selectedRim, setSelectedRim] = useState({})
  const [selectedDigitalSource, setSelectedDigitalSource] = useState({})
  const [selectedDigitalChannel, setSelectedDigitalChannel] = useState({})
  const [selectedDigitalDepartment, setSelectedDigitalDepartment] = useState({})
  const [selectedDigitalMedia, setSelectedDigitalMedia] = useState({})
  const [isRatiosOpened, setIsRatiosOpened] = useState(false)
  const [contractors, setContractors] = useState([])
  const [ratios, setRatios] = useState([])
  const [rimOptions] = useState([
    {
      value: true,
      label: i18n.t('face_form.isRimValue'),
    },
    {
      value: false,
      label: i18n.t('face_form.isNotRimValue'),
    },
  ])

  const [{data: platforms}, getPlatforms] = useApiFetch(apiUrls.get.digitalPlatforms())
  const [{data: subtypes}, getSubtypes] = useApiFetch(apiUrls.get.digitalSubtypes())
  const [{data: formats}, getFormats] = useApiFetch(apiUrls.get.digitalFormats())
  const [{data: units}, getUnits] = useApiFetch(apiUrls.get.digitalUnits())
  const [{data: ratiosFromApi}, getRatiosFromApi] = useApiFetch(apiUrls.get.digitalRatios())
  const [{data: strategies}, getStrategies] = useApiFetch(apiUrls.get.digitalStrategies())
  const [{data: platformClients}, getPlatformClients] = useApiFetch(apiUrls.get.digitalPlatformClients())
  // const [{data: digitalSources}, getDigitalSources] = useApiFetch(apiUrls.get.digitalSources())
  const [{data: digitalChannels}, getDigitalChannels] = useApiFetch(apiUrls.get.digitalChannels())
  const [{data: digitalDepartments}, getDigitalDepartments] = useApiFetch(apiUrls.get.digitalDepartments())
  const [{data: digitalMedias}, getDigitalMedias] = useApiFetch(apiUrls.get.digitalMedias())
  const [{data: total}, getTotal] = useApiFetch((new DigitalChannel()).apiUrls.totalFact)
  const [{data: face}, getFace] = useApiFetch(apiUrls.get.digitalFace(props.faceId))

  const [{errors: updateErrors}, updateFace] = useApiPatch(
    apiUrls.patch.digitalFace,
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      props.getAdvertPeriod({
        't[]': 'target'
      })
      toast.success(i18n.t('periods.face.saved'))
    }
  )

  const [{errors: addErrors}, addFace] = useApiPost(
    apiUrls.post.digitalFace(),
    '',
    'periods.face',
    () => {
      props.getAdvertFaces()
      props.setSelectedFace(null)
      toast.success(i18n.t('periods.face.added'))
    }
  )

  const getTotalData = () => {
    if (formFace && formFace.advertising) {
      getTotal({
        'props[]': 'budget',
        'face.id': formFace.id,
        'date[after]': getFormattedDate(formFace.advertising.dateStart, 'Y-MM-DD'),
        'date[before]': getFormattedDate(formFace.advertising.dateEnd, 'Y-MM-DD'),
      })
    }
  }

  const isNew = () => !Boolean(props.face && props.face['@id'])

  useEffect(() => {
    if (props.faceId) {
      const faceEntity = FaceFactory.create('digital')
      getFace({
        't[]': faceEntity.listGroupParams
      })
    } else {
      setNewFace()
    }

    getPlatforms()
    getSubtypes()
    getStrategies()
    getFormats()
    getUnits()
    getRatiosFromApi()

    getDigitalChannels({
      pagination: false
    })

    getDigitalDepartments({
      pagination: false
    })

    getDigitalMedias({
      pagination: false
    })


  }, [])

  useEffect(() => {
    if (face) {
      const formFace = deepClone(face)

      if (props.isNew) {
        delete(formFace.id)
        delete(formFace.code)

        formFace.advertising = {
          '@id': `/adverts/${props.advertisingId}`,
          id: props.advertisingId,
        }
      }

      if (formFace.faceUnits && formFace.faceUnits.length) {
        for (let unit of formFace.faceUnits) {
          if (!unit.ratios) {
            continue
          }

          for (let ratio of unit.ratios) {
            if (ratio.ratio && ratio.ratio.asPercent) {
              ratio.value = getRatioPercent(ratio.value)
            }
          }
        }
      }

      setSelectedFormats(formFace.formats ? formFace.formats.map(format => ({
        value: format['@id'],
        label: format.name
      })) : [])

      setFormFace(formFace)
    }
  }, [face])

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.value) {
      getPlatformClients({
        't[]': 'platform-legal',
        'platform.id': selectedPlatform.value.split('/').pop(),
        'client.id': companyId,
        'pagination': false
      })
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (platformClients) {
      if (platformClients.length) {
        let clientContractors = []

        for (let client of platformClients) {
          if (client.contractor && client.contractor.id) {
            clientContractors.push(client.contractor)
          }
        }

        const selectedContractorInClients = selectedContractor.value ? clientContractors.find(contractor => {
          return contractor['@id'] === selectedContractor.value
        }) : ''

        if (!selectedContractorInClients) {
          onChange({}, 'contractor')
        }

        setContractors(clientContractors)
      } else {
        onChange({}, 'contractor')
        setContractors([])
      }
    }
  }, [platformClients])

  useEffect(() => {
    if (formats && (!formFace.type || !formFace.type.formats || !formFace.type.formats.length)) {
      setFormatsOptions(formats.map((format) => {
        return {value: format['@id'], label: format.name}
      }))
    } else if (formFace.formats && formFace.formats.length) {
      setFormatsOptions(
        formFace.formats.map((format) => {
          return {value: format['@id'], label: format.name}
        })
      )
    }
  }, [formats, formFace])

  useEffect(() => {
    if (formFace.platform && formFace.platform['@id']) {
      setSelectedPlatform({
        value: formFace.platform['@id'],
        label: formFace.platform.name
      })
    } else {
      setSelectedPlatform({})
    }
  }, [formFace.platform])

  useEffect(() => {
    let label = ''

    if (formFace.isRim) {
      label = i18n.t('face_form.isRimValue')
    } else if (formFace.isRim !== undefined) {
      label = i18n.t('face_form.isNotRimValue')
    }

    setSelectedRim({
      value: formFace.isRim,
      label: label
    })
  }, [formFace.isRim])

  useEffect(() => {
    if (formFace.type && formFace.type['@id']) {
      setSelectedType({
        value: formFace.type['@id'],
        label: formFace.type.name
      })
    } else {
      setSelectedType({})
    }
  }, [formFace.type])

  useEffect(() => {
    if (formFace.strategy && formFace.strategy['@id']) {
      setSelectedStrategy({
        value: formFace.strategy['@id'],
        label: formFace.strategy.name
      })
    } else {
      setSelectedStrategy({})
    }
  }, [formFace.strategy])

  useEffect(() => {
    if (formFace.contractor && formFace.contractor['@id']) {
      setSelectedContractor({
        value: formFace.contractor['@id'],
        label: formFace.contractor.name
      })
    } else {
      setSelectedContractor({})
    }
  }, [formFace.contractor])

  useEffect(() => {
    if (formFace.channel && formFace.channel['@id']) {
      setSelectedDigitalChannel({
        value: formFace.channel['@id'],
        label: formFace.channel.name
      })
    } else {
      setSelectedDigitalChannel({})
    }
  }, [formFace.channel])

  useEffect(() => {
    if (formFace.department && formFace.department['@id']) {
      setSelectedDigitalDepartment({
        value: formFace.department['@id'],
        label: formFace.department.name
      })
    } else {
      setSelectedDigitalDepartment({})
    }
  }, [formFace.department])

  useEffect(() => {
    if (formFace.media && formFace.media['@id']) {
      setSelectedDigitalMedia({
        value: formFace.media['@id'],
        label: formFace.media.name
      })
    } else {
      setSelectedDigitalMedia({})
    }
  }, [formFace.media])

  useEffect(() => {
    if (formFace.source && formFace.source['@id']) {
      setSelectedDigitalSource({
        value: formFace.source['@id'],
        label: formFace.source.name
      })
    } else {
      setSelectedDigitalSource({})
    }
  }, [formFace.source])

  useEffect(() => {
    if (formFace.faceUnits && formFace.faceUnits.length) {
      const faceUnit = formFace.faceUnits[0]

      setSelectedUnit({
        value: faceUnit['@id'],
        label: faceUnit.unit.name
      })
    } else {
      setSelectedUnit({})
    }
  }, [formFace.faceUnits])

  useEffect(() => {
    if (formFace.formats && formFace.formats.length) {
      setSelectedFormats(formFace.formats.map(format => ({
        value: format['@id'],
        label: format.name
      })))
    } else {
      setSelectedFormats([])
    }
  }, [formFace.formats])

  useEffect(() => {
    if (formFace && !isNew()) {
      getTotalData()
    }
  }, [formFace.advertising])

  useEffect(() => {
    if (ratiosFromApi) {
      const ratioValues = ratiosFromApi

      if (formFace.faceUnits && formFace.faceUnits.length) {
        const unit = formFace.faceUnits[0]

        if (unit.ratios) {
          for (let faceRatio of unit.ratios) {
            const ratio = ratioValues.find(ratio => ratio['@id'] === faceRatio.ratio['@id'])

            if (!ratio) {
              ratioValues.push(faceRatio.ratio)
            }
          }
        }
      }

      setRatios(ratioValues)
    } else {
      setRatios([])
    }
  }, [ratiosFromApi])

  const setNewFace = () => {
    setFormFace({
      code: '',
      formatText: '',
      name: '',
      targeting: '',
      advertising: {
        '@id': `/adverts/${props.advertisingId}`,
        id: props.advertisingId,
      },
      platform: {},
      communication: {},
      channel: {},
      source: {},
      strategy: {},
      type: {
        formats: []
      },
      formats: [],
      faceUnits: [],
    })
  }

  const onChange = (value, name) => {
    if (value.value !== undefined) {
      setFormFace({
        ...formFace,
        [name]: {
          '@id': value.value,
          name: value.label
        }
      })
    } else {
      setFormFace({
        ...formFace,
        [name]: value
      })
    }
  }

  const onFormatsChange = (formats) => {
    setFormFace({
      ...formFace,
      formats: formats
        ? formats.map(format => ({
          '@id': format.value,
          name: format.label
        }))
        : []
    })
  }

  const onUnitChange = (value) => {
    if (!(formFace.faceUnits) || (formFace.faceUnits[0] && !formFace.faceUnits[0].id)) {
      formFace.faceUnits[0] = {}

      const unit = units.find(unit => unit['@id'] === value.value)

      if (unit.id) {
        formFace.faceUnits[0].id = unit.id
      }
    }

    setFormFace({
      ...formFace,
      faceUnits: [{
        ...formFace.faceUnits[0],
        ...{
          unit: {'@id': value.value, 'name': value.label}
        }
      }]
    })
  }

  const onUnitCostChange = (value) => {
    if (formFace.faceUnits && formFace.faceUnits[0]) {
      setFormFace({
        ...formFace,
        faceUnits: [{
          ...formFace.faceUnits[0],
          unitCost: value
        }]
      })
    }
  }

  const onDataSourcesChange = (value) => {
    setFormFace({
      ...formFace,
      datasources: value
    })
  }

  const onTrackingsChange = (value) => {
    setFormFace({
      ...formFace,
      trackings: value
    })
  }

  const onTargetingsChange = (value) => {
    setFormFace({
      ...formFace,
      targetings: value
    })
  }

  const onFactorsChange = (value) => {
    setFormFace({
      ...formFace,
      factors: value
    })
  }

  const save = () => {
    let isError = false

    if (!isFaceUnits(formFace)) {
      toast.error(i18n.t('clients.advertising.empty_face_units'))
      isError = true
    }

    if (!formFace.strategy || !formFace.strategy['@id']) {
      toast.error(i18n.t('clients.advertising.empty_strategy'))
      isError = true
    }

    if (!formFace.formats || !formFace.formats.length) {
      toast.error(i18n.t('clients.advertising.empty_formats'))
      isError = true
    }

    if (!formFace.formatText) {
      toast.error(i18n.t('clients.advertising.empty_format_text'))
      isError = true
    }

    if (!formFace.channel || !formFace.channel['@id']) {
      toast.error(i18n.t('clients.advertising.empty_digital_channel'))
      isError = true
    }

    if (isError) {
      return
    }

    const data = getDataFromFace(formFace, ratios)

    if (formFace.id) {
      updateFace(formFace.id, data)
    } else {
      addFace(data)
    }
  }

  const getOptions = items => {
    return items
      ? items.map((item) => {
        return {value: item['@id'], label: item.name}
      })
      : []
  }

  const platformOptions = getOptions(platforms)

  const subtypeOptions = getOptions(subtypes)

  const strategyOptions = getOptions(strategies)

  const contractorOptions = getOptions(contractors)

  const unitOptions = getOptions(units)

  const channelOptions = getOptions(digitalChannels)

  const departmentOptions = getOptions(digitalDepartments)

  const mediaOptions = getOptions(digitalMedias)

  departmentOptions.unshift(emptyOption)
  mediaOptions.unshift(emptyOption)

  const onRatioChange = (ratioIri, value, isDiscount = false) => {
    let faceRatios = (formFace && formFace.faceUnits && formFace.faceUnits.length && formFace.faceUnits[0]
        && formFace.faceUnits[0].ratios && formFace.faceUnits[0].ratios.length)
      ? formFace.faceUnits[0].ratios
      : []

    let parsedValue = parseFloat(value)

    const faceRatio = faceRatios.find(ratio => (ratio.ratio && ratio.ratio['@id'] === ratioIri))
    const ratio = ratios.find(ratio => ratio['@id'] === ratioIri)

    if (faceRatio) {
      faceRatio.value = parsedValue

      if (ratio) {
        faceRatio.ratio.asPercent = ratio.asPercent
      }
    } else {
      if (ratio) {
        faceRatios.push({
          ratio: {
            '@id': ratio['@id'],
            name: ratio.name,
            asPercent: ratio.asPercent
          },
          value: parsedValue
        })
      }
    }

    formFace.faceUnits[0].ratio = faceRatios.filter(ratio => !ratio.ratio.asPercent).reduce((acc, ratio) => {
      return ratio.value
        ? (acc * ratio.value)
          : (acc * 1)
    }, 1)

    formFace.faceUnits[0].discount = faceRatios.filter(ratio => ratio.ratio.asPercent).reduce((acc, ratio) => {
      return ratio.value
        ? (acc * getRatioCoefficient(ratio.value))
        : (acc * 1)
    }, 1)

    formFace.faceUnits[0].ratios = faceRatios

    setFormFace(deepClone(formFace))
  }

  const coefficientRatios = ratios
    ? ratios.filter(ratio => !ratio.asPercent)
    : []

  const discountRatios = ratios
    ? ratios.filter(ratio => ratio.asPercent)
    : []

  const getTotalDiscount = () => {
    return (formFace && formFace.faceUnits && formFace.faceUnits[0] && formFace.faceUnits[0].discount !== undefined)
    ? ((1- formFace.faceUnits[0].discount) * 100).toFixed(fractionDigits)
    : 0}

  const getTotalCoefficient = () => {
    return (formFace && formFace.faceUnits && formFace.faceUnits[0] && formFace.faceUnits[0].ratio)
    ? formFace.faceUnits[0].ratio.toFixed(fractionDigits)
    : 1}

  const notCheck = () => {
    if (!formFace.advertising) {
      return false
    }

    if (!total || !Array.isArray(total)) {
      return false
    }

    return !total.some(interval => {
      return interval.data && interval.data.length && interval.data.some(face => (face.check))
    })
  }

  return (
    <div className='popup-form'>
      <div className='popup-form__background'
        onClick={() => props.setSelectedFace(null)} />
      <div className='popup-form__wrapper'>
        <div className='popup-form__header'>
          <button
            onClick={() => props.setSelectedFace(null)}>
            <i className='fas fa-remove' />
          </button>
        </div>

        {formFace && formFace.name !== undefined &&
        <div className='popup-form__body'>
          <div className='popup-form__rows'>
            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('clients.advertising.code')}
              </div>
              {
                props.readonly
                  ? <div className={'popup-form__readonly'}>{formFace.code}</div>
                  :
                  <Input
                    value={formFace.code}
                    onChange={(value) => onChange(value, 'code')}
                  />
              }
            </div>

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.subtype')}</div>
                <div className={'popup-form__readonly'}>{selectedType ? selectedType.label : ''}</div>
              </>

              : <FormSelect
                name='subtype'
                value={selectedType}
                options={subtypeOptions}
                label={i18n.t('clients.advertising.subtype')}
                onChange={(value) => onChange(value, 'type')}
              />
            }

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.platform')}</div>
                <div className={'popup-form__readonly'}>{selectedPlatform ? selectedPlatform.label : ''}</div>
              </>

              : <FormSelect
                name='platform'
                value={selectedPlatform}
                options={platformOptions}
                label={i18n.t('clients.advertising.platform')}
                onChange={(value) => onChange(value, 'platform')}
              />
            }

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.contractor')}</div>
                <div className={'popup-form__readonly'}>{selectedContractor ? selectedContractor.label : ''}</div>
              </>

              : <FormSelect
                name='contractor'
                value={selectedContractor}
                options={contractorOptions}
                label={i18n.t('clients.advertising.contractor')}
                onChange={(value) => onChange(value, 'contractor')}
                isDisabled={!notCheck() && !isNew()}
              />
            }

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('clients.advertising.targeting')}
              </div>
              {
                props.readonly
                  ? <div className={'popup-form__readonly'}>{formFace.targeting}</div>
                  :
                    <Input
                      value={formFace.targeting}
                      onChange={(value) => onChange(value, 'targeting')}
                    />
              }
            </div>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('clients.advertising.formatText')}
              </div>
              {
                props.readonly
                  ? <div className={'popup-form__readonly'}>{formFace.formatText}</div>
                  :
                  <Input
                    value={formFace.formatText}
                    onChange={(value) => onChange(value, 'formatText')}
                  />
              }
            </div>

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.format')}</div>
                <div className={'popup-form__readonly'}>{selectedFormats ? selectedFormats.label : ''}</div>
              </>

              : <FormSelect
                  name='formats'
                  value={selectedFormats}
                  options={formatsOptions}
                  label={i18n.t('clients.advertising.format')}
                  onChange={(value) => onFormatsChange(value)}
                  isMulti={true}
                />
            }

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.strategy')}</div>
                <div className={'popup-form__readonly'}>{selectedStrategy ? selectedStrategy.label : ''}</div>
              </>

              : <FormSelect
                  name='strategy'
                  value={selectedStrategy}
                  options={strategyOptions}
                  label={i18n.t('clients.advertising.strategy')}
                  onChange={(value) => onChange(value, 'strategy')}
                />
            }

            <div className={'popup-form__row unit-fields'}>
              <div className={'unit-field'}>
                <div className='popup-form__row-title'>
                  {i18n.t('clients.advertising.unit')}
                </div>
                <span className="input-wrap">
                  {props.readonly
                    ? <div className={'popup-form__readonly'}>{selectedUnit ? selectedUnit.label : ''}</div>
                    : <Select
                        className="react-select-container select-pseudo-input small"
                        classNamePrefix="react-select"
                        placeholder={i18n.t('periods.face.select')}
                        options={unitOptions}
                        value={selectedUnit}
                        onChange={(value) => onUnitChange(value)}
                      />
                  }
              </span>
              </div>

              <div className={'unit-field'}>
                <div className={'popup-form__row-title'}>
                  {i18n.t('clients.advertising.unitCost')}
                </div>
                {
                  props.readonly
                    ? <div className={'popup-form__readonly'}>
                        {(formFace && formFace.faceUnits && formFace.faceUnits[0]) ? formFace.faceUnits[0].unitCost : ''}
                      </div>
                    : <FloatInput
                        className={'unit-cost'}
                        value={(formFace && formFace.faceUnits && formFace.faceUnits[0]) ? formFace.faceUnits[0].unitCost : ''}
                        onChange={(value) => onUnitCostChange(value)}
                        disabled={!(formFace && formFace.faceUnits && formFace.faceUnits[0])}
                      />
                }
              </div>
            </div>

            <RatioBlock
              ratios={coefficientRatios}
              title={i18n.t('clients.advertising.ratio')}
              onRatioChange={onRatioChange}
              formFace={formFace}
              inputsTitle={i18n.t('clients.advertising.ratio_coefficients')}
              value={getTotalCoefficient()}
            />

            <RatioBlock
              ratios={discountRatios}
              title={i18n.t('clients.advertising.ratio_discount')}
              onRatioChange={onRatioChange}
              formFace={formFace}
              inputsTitle={i18n.t('clients.advertising.ratio_discounts')}
              value={getTotalDiscount()}
            />

            <AccordionBlock title={i18n.t('clients.advertising.datasources')} >
              <DataSourcesBlock
                datasources={formFace.datasources}
                onChange={onDataSourcesChange}
                formFace={formFace}
                isDisabled={props.readonly}
              />
            </AccordionBlock>

            <AccordionBlock title={i18n.t('face.list.factors')} >
              <FactorsBlock
                factors={formFace.factors}
                onChange={onFactorsChange}
                //formFace={formFace}
                isDisabled={props.readonly}
              />
            </AccordionBlock>

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.digital_channel')}</div>
                <div className={'popup-form__readonly'}>{selectedDigitalChannel ? selectedDigitalChannel.label : ''}</div>
              </>

              : <FormSelect
                  name='channel'
                  value={selectedDigitalChannel}
                  options={channelOptions}
                  label={i18n.t('clients.advertising.digital_channel')}
                  onChange={(value) => onChange(value, 'channel')}
                  isDisabled={props.readonly}
                />
            }

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.digital_department')}</div>
                <div className={'popup-form__readonly'}>{selectedDigitalDepartment ? selectedDigitalDepartment.label : ''}</div>
              </>

              : <FormSelect
                name="department"
                value={selectedDigitalDepartment}
                options={departmentOptions}
                label={i18n.t('clients.advertising.digital_department')}
                onChange={(value) => onChange(value, 'department')}
                isDisabled={props.readonly}
              />
            }

            {props.readonly
              ?
              <>
                <div>{i18n.t('clients.advertising.digital_media')}</div>
                <div className={'popup-form__readonly'}>{selectedDigitalMedia ? selectedDigitalMedia.label : ''}</div>
              </>

              : <FormSelect
                name='media'
                value={selectedDigitalMedia}
                options={mediaOptions}
                label={i18n.t('clients.advertising.digital_media')}
                onChange={(value) => onChange(value, 'media')}
                isDisabled={props.readonly}
              />
            }

            <AccordionBlock title={i18n.t('clients.advertising.trackings')} >
              <TrackingSourcesBlock
                sources={formFace.trackings}
                onChange={onTrackingsChange}
                formFace={formFace}
                isDisabled={props.readonly}
                optionsUrl={apiUrls.get.digitalTrackings()}
              />
            </AccordionBlock>

            <AccordionBlock title={i18n.t('clients.advertising.targetings')} >
              <TrackingSourcesBlock
                sources={formFace.targetings}
                onChange={onTargetingsChange}
                formFace={formFace}
                isDisabled={props.readonly}
                optionsUrl={apiUrls.get.digitalTargetings()}
              />
            </AccordionBlock>

            <div className={'popup-form__row'}>
              <div className={'popup-form__row-title'}>
                {i18n.t('face_form.rim_block')}
              </div>

              <div className={'rim-block'}>
                <div className='popup-form__row-title'>
                  {i18n.t('face_form.isRim')}
                </div>
                <Select
                  className="react-select-container select-pseudo-input small"
                  classNamePrefix="react-select"
                  options={rimOptions}
                  value={selectedRim}
                  onChange={value => onChange(value.value, 'isRim')}
                />

                <label>
                  <Checkbox checked={formFace.isSendedOrd}
                            onChange={(value) => {
                              onChange(value, 'isSendedOrd')
                            }} />
                  {i18n.t('face_form.isSendedOrd')}
                </label>

                <div className={'popup-form__row-title'}>
                  {i18n.t('face_form.ordToken')}
                </div>
                {
                  props.readonly
                    ? <div className={'popup-form__readonly'}>{formFace.ordToken}</div>
                    :
                    <Input
                      value={formFace.ordToken}
                      onChange={(value) => onChange(value, 'ordToken')}
                    />
                }
              </div>
            </div>
          </div>
          {!props.readonly &&
            <div className={'popup-form__buttons'}>
              <button className="popup-form__button button-accent" onClick={save}>
                Сохранить
              </button>
            </div>
          }
        </div>
        }

      </div>
    </div>
  )
}

export default DigitalForm;
