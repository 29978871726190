import React, {useState, useEffect, Fragment, useCallback} from 'react'
import MenuClient from "../../../Components/MenuClient";
import Header from "../../Advertising/Detail/components/Table/Header";
import Row from "../../Advertising/Detail/components/Table/Row";
import RowCell from "./RowCell";
import {Link} from "react-router-dom";
import LoaderBackdrop from "../../../Components/Loader/LoaderBackdrop";
import Footer from "../../Advertising/Detail/components/Footer";
import ButtonWithLoader from "../../../Components/Buttons/ButtonWithLoader";
import i18n from "../../../i18n";
import apiUrls from "../../../ApiUrls";
import {getUtcDate} from "../FacesTotal/Utils";
import Filter from "../../../Components/NewFilter/Filter";
import useApiFetch from "../../../Components/Hooks/useApiFetch";
import {InternetChannel} from "../../../entities/channel/InternetChannel";
import {
  getFormattedDate,
  subscribeToAdvertisingsUpdates,
} from "../../Advertising/Detail/Fact/Utils";
import Checkbox from "../../../Components/Inputs/Checkbox";
import {getAdvertisingDates} from "../../../utils";
import {api} from "../../../api/apiProvider";
import PullHistory from "../../Advertising/Detail/Fact/PullHistory";
import Loader from "../../../Components/Loader/Loader";
import {ChannelFactory} from "../../../entities/channel/ChannelFactory";
import {toast} from "react-toastify";
import useApiPost from "../../../Components/Hooks/useApiPost";
import advertising from "../../Advertising";

const filterDateFormat = 'YYYY-MM-DD'

export default function MonthFactIndex() {
  const [dataFilterParams, setDataFilterParams] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [properties, setProperties] = useState(null)
  const [advertisingsFacesMap, setAdvertisingsFacesMap] = useState(null)
  const [selectedAdvertisingsIds, setSelectedAdvertisingsIds] = useState([])
  const [dateStartFilter, setDateStartFilter] = useState(null)
  const [dateEndFilter, setDateEndFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [advertisingsStatuses, setAdvertisingsStatuses] = useState({})
  const [pullHistories, setPullHistories] = useState(null)
  const [pullHistoriesAdvertising, setPullHistoriesAdvertising] = useState(null)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isFaceSaving, setIsFaceSaving] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [changedFaces, setChangedFaces] = useState([])

  const getFacesUrl = () => {
    if (selectedChannel && selectedChannel.code) {
      const channelEntity = ChannelFactory.create(selectedChannel.code)

      return channelEntity.apiUrls.faces
    }

    return null
  }

  const getFactDataUrl = () => {
    if (selectedChannel && selectedChannel.code) {
      const channelEntity = ChannelFactory.create(selectedChannel.code)

      return channelEntity.apiUrls.totalFact
    }

    return null
  }

  const [{data: faces, isLoading: isFacesLoading}, getFaces] = useApiFetch(getFacesUrl())
  const [{data: factData, isLoading: isFactDataLoading}, getFactData] = useApiFetch(getFactDataUrl())

  const [{data: advertisings, isLoading: isAdvertisingsLoading}, getAdvertisings] = useApiFetch(apiUrls.get.adverts())
  const [{
    data: advertPullHistories,
    isLoading: isHistoryLoading
  }, getAdvertPullHistories] = useApiFetch(apiUrls.get.pullHistories())

  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(
    getFactDataUrl(),
    '',
    'clients.advertising'
  )

  const handlePullResult = useCallback((result) => {
    if (result.advertising && result.status && result.dateStart && result.dateEnd
      && advertisingsFacesMap.has(result.advertising) && result.dateStart === dateStartFilter
      && result.dateEnd === dateEndFilter) {
      setAdvertisingStatus(result.advertising, result)
    }
  }, [advertisingsFacesMap, dateStartFilter, dateEndFilter])

  const filters = {
    channel: {
      type: 'select',
      placeholder: i18n.t('clients.advertising.channel'),
      getOptionsUrlFunction: apiUrls.get.channels,
      setOptions: (options, change) => {
        if (options) {
          const internetChannel = options.find(option => option.code === InternetChannel.getCode())

          if (internetChannel) {
            setSelectedChannel(internetChannel)
            change(internetChannel)
          }
        }
      },
      name: 'channel',
      setFilterParams: (params/*, filterValue*/) => {
        if (selectedChannel && selectedChannel.value) {
          params.append('targets.channel.id', selectedChannel.value)
          //setSelectedChannel(filterValue)
        }

        return ''
      },
      getValueToUrl: (params, channel) => {
        params.set('channel', channel.value)
        return params
      },
      className: 'pointer',
      required: true,
      isDisabled: () => true
    },
    date: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'date',
      getValueFromUrl: values => {
        if (values.date && values.date[0]) {
          const dateValue = getUtcDate(values.date[0], filterDateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('date', getUtcDate(value).format(filterDateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.startOf('month').format(filterDateFormat)
        const endDate = dateValue.endOf('month').format(filterDateFormat)
        params.append('dateEnd[after]', startDate)
        params.append('dateStart[before]', endDate)
        setDateStartFilter(startDate)
        setDateEndFilter(endDate)
      },
      format: "MM.yyyy",
      rangeFormat: "DD.MM.YYYY",
      filterFormat: filterDateFormat,
      required: true,
      isMonth: true,
    },
    company: {
      type: 'select',
      value: null,
      placeholder: i18n.t('clients.page.company'),
      getOptionsUrlFunction: apiUrls.get.clients,
      name: 'company',
      setFilterParams: (params, values) => {
        params.delete('company.id[]')
        const clients = []
        for (let filterValue of values) {
          params.append('company.id[]', filterValue.value)
          clients.push(filterValue)
        }

        setClientFilter(clients)
      },
      getValueToUrl: (params, company) => {
        if (company && company.length) {
          params.delete('company[]')
          for (let companyItem of company) {
            params.append('company[]', companyItem.value)
          }
        } else {
          params.delete('company[]')
        }
      },
      isMulti: true,
    },
  }

  useEffect(() => {
    if (dataFilterParams && selectedChannel) {
      getAdvertisings(getParams())
      getFaces(getFacesParams())
      getFactData(getTotalDataParams())
      setAdvertisingsStatuses({})
    }
  }, [selectedChannel, dataFilterParams])

  useEffect(() => {
    if (advertisingsFacesMap && dataFilterParams) {
      const subscription = subscribeToAdvertisingsUpdates(handlePullResult, handlePullResult, handlePullResult, false)

      return () => {
        subscription.close()
      }
    }
  }, [advertisingsFacesMap, dataFilterParams])

  useEffect(() => {
    if (advertisings && faces && factData) {
      const facesMap = new Map()

      faces.map(face => {
        facesMap.set(face['@id'], {
          '@id': face['@id'],
          advertisingId: face.advertising.id,
          contractor: face.contractor,
          id: face.id,
          name: face.name,
        })
      })

      if (factData[0] && factData[0].data && Array.isArray(factData[0].data)) {
        factData[0].data.map(item => {
          if (item.item && facesMap.has(item.item) && item.properties && item.properties.budget) {
            const face = facesMap.get(item.item)

            facesMap.set(item.item, {
              ...face,
              budget: item.properties.budget
            })
          }
        })
      }

      const advertsMap = new Map()

      advertisings.map(advertising => {
        advertsMap.set(advertising.id,
          {
            id: advertising.id,
            ['@id']: advertising['@id'],
            name: advertising.name,
            company: advertising.company,
            dateStart: {
              origin: advertising.dateStart,
              value: getFormattedDate(advertising.dateStart)
            },
            dateEnd: {
              origin: advertising.dateEnd,
              value: getFormattedDate(advertising.dateEnd)
            },
            parts: advertising.parts,
          })
      })

      facesMap.forEach((face, faceIri) => {
        const faceAdvertisingId = face.advertisingId

        if (faceAdvertisingId && advertsMap.has(faceAdvertisingId)) {
          const advertising = advertsMap.get(faceAdvertisingId)

          let faces = advertising.faces

          if (!faces) {
            faces = []
          }

          faces.push(face)

          advertsMap.set(advertising.id,
            {
              ...advertising,
              faces
            })
        }
      })

      advertsMap.forEach(advertising => {
        const faces = advertising.faces

        if (faces) {
          const budget = advertising.faces.reduce((prev, face) => {
            return prev + (face.budget ? face.budget.value : 0)
          }, 0)

          advertsMap.set(advertising.id,
            {
              ...advertising,
              budget: {
                editable: false,
                value: budget,
              },
            })
        } else {
          advertsMap.set(advertising.id,
            {
              ...advertising,
              budget: {
                editable: false,
                value: 0,
              },
            })
        }

      })

      setAdvertisingsFacesMap(advertsMap)
    }
  }, [advertisings, faces, factData])

  useEffect(() => {
    const properties = []

    properties.push({
      name: i18n.t('clients.edit.date_start'),
      code: 'dateStart',
      type: 'string'
    })

    properties.push({
      name: i18n.t('clients.edit.date_end'),
      code: 'dateEnd',
      type: 'string'
    })

    properties.push({
      name: 'Бюджет (агентский) факт',
      code: 'budget',
      type: 'float'
    })

    setProperties(properties)
  }, [])

  useEffect(() => {
    if (advertPullHistories) {
      const pullMap = new Map()

      for (let historyItem of advertPullHistories) {
        const key = `${historyItem.platform.id}${historyItem.channel.id}${historyItem.face}${historyItem.error}`

        if (!pullMap.has(key, historyItem)) {
          pullMap.set(key, historyItem)
        }
      }

      setPullHistories(Array.from(pullMap, ([name, value]) => ({...value})))
    }
  }, [advertPullHistories])

  useEffect(() => {
    if (advertisingsFacesMap) {
      selectAll(isAllChecked)
    }
  }, [isAllChecked])

  useEffect(() => {
    if (advertisingsStatuses && Object.values(advertisingsStatuses).length) {
      const isLoading = Object.values(advertisingsStatuses).find(item => item.status === 'loading')

      if (!isLoading) {
        getFactData(getTotalDataParams())
      }
    }
  }, [advertisingsStatuses])

  const getFactResultsHistory = advertisingId => {
    const advertising = advertisingsFacesMap.get(advertisingId)

    if (!advertising) {
      return
    }

    getAdvertPullHistories({
      'advertising.id': advertising.id,
      'order[id]': 'desc',
      'exists[error]': true,
      'requestedDateStart[before]': dateEndFilter,
      'requestedDateEnd[after]': dateStartFilter,
      't[]': ['face_platform', 'face_channel'],
    })
    setPullHistoriesAdvertising(advertising)
  }

  const getParams = () => {
    const params = {}
    for (let param of dataFilterParams) {
      params[param[0]] = param[1]
    }

    if (selectedChannel && selectedChannel.value) {
      params['targets.channel.id'] = selectedChannel.value
    }

    params['pagination'] = 'false'
    params['t[]'] = ['short', 'part', 'company']

    return params
  }

  const getFacesParams = () => {
    return {
      'advertising.company.id[]': (clientFilter && clientFilter.length)
        ? clientFilter.map(client => client.value)
        : [],
      'advertising.dateEnd[after]': dateStartFilter,
      'advertising.dateStart[before]]': dateEndFilter,
      't[]': ['name', 'face_contractor', 'advertising'],
      'pagination': 'false',
    }
  }

  const getTotalDataParams = () => {
    if (selectedChannel && clientFilter && dateStartFilter && dateEndFilter) {
      return {
        'face.advertising.company.id[]': (clientFilter && clientFilter.length)
          ? clientFilter.map(client => client.value)
          : [],
        interval: 'month',
        'date[after]': dateStartFilter,
        'date[before]': dateEndFilter,
        'props[]': 'budget',
      }
    }

    return {}
  }

  const setFilterParams = (filterParams) => {
    setDataFilterParams(filterParams)
  }

  const onSelect = (advertisingId, value) => {
    if (value) {
      if (!selectedAdvertisingsIds.includes(advertisingId)) {
        setSelectedAdvertisingsIds([
          ...selectedAdvertisingsIds,
          advertisingId
        ])
      }
    } else {
      if (selectedAdvertisingsIds.includes(advertisingId)) {
        setSelectedAdvertisingsIds(selectedAdvertisingsIds.filter(id => id !== advertisingId))
      }
    }
  }

  const selectAll = value => {
    if (value) {
      const advertisingsIds = []

      advertisingsFacesMap.forEach((advertising, key) => {
        if (advertising.parts && advertising.parts.length) {
          advertisingsIds.push(key)
        }
      })

      setSelectedAdvertisingsIds(advertisingsIds)
    } else {
      setSelectedAdvertisingsIds([])
    }
  }

  const getFactDataPageUrl = advertising => {
    const channelCode = selectedChannel.code

    const [intervalStart, intervalEnd]
      = getAdvertisingDates(advertising.dateStart.origin, advertising.dateEnd.origin, dateStartFilter, dateEndFilter)

    if (channelCode) {
      return `/advertisings/${
        advertising.id}/fact/${channelCode}?dateStart=${
        getFormattedDate(intervalStart, filterDateFormat)}&dateEnd=${
        getFormattedDate(intervalEnd, filterDateFormat)}`
    }
  }

  const setAdvertisingStatus = (id, value) => {
    setAdvertisingsStatuses(advertisingsStatuses => ({
      ...advertisingsStatuses,
      [id]: value,
    }))
  }

  const onDownloadFactClick = async () => {
    setAdvertisingsStatuses({})

    setIsAllChecked(false)

    for (let advertisingId of selectedAdvertisingsIds) {
      setAdvertisingStatus(advertisingId, {
        status: 'loading'
      })
    }

    setSelectedAdvertisingsIds([])

    for (let advertisingId of selectedAdvertisingsIds) {
      const params = {
        id: advertisingId,
        dateStart: dateStartFilter,
        dateEnd: dateEndFilter,
      }

      await api.post(apiUrls.post.getAdvertisingFactResults(params), {}).catch(error => {
        if (error['hydra:description']) {
          setAdvertisingStatus(advertisingId, {
            message: error['hydra:description'],
            isError: true,
            status: 'danger',
          })
        }
      })
    }
  }

  const onStatusClick = result => {
    if (result.advertising) {
      getFactResultsHistory(result.advertising)
    }
  }

  const onRowChange = async (value, property, face) => {
    if (advertisingsFacesMap.has(face.advertisingId)) {
      const advertising = advertisingsFacesMap.get(face.advertisingId)

      for (let advertisingFace of advertising.faces) {
        if (advertisingFace.id === face.id) {
          if (!advertisingFace[property.code]) {
            advertisingFace[property.code] = {}
          }

          advertisingFace[property.code].value = value
        }
      }

      if (!changedFaces.includes(face.id)) {
        setChangedFaces(values => (
          [
            ...values,
            face.id
          ]
        ))
      }

      advertisingsFacesMap.set(face.advertisingId, advertising)
    }
  }

  const saveFactDataValues = (intervals, callback) => {
    if (intervals && intervals.length) {
      saveFactData({
        intervals
      }, null, {}, true, () => {
        if (typeof callback === 'function') {
          callback()
        }
      }, () => {
      });
    }
  }

  const saveFaceFactData = async (face) => {
    return new Promise((resolve, reject) => {
      const advertising = advertisingsFacesMap.get(face.advertisingId)
      if (!advertising) {
        return resolve(true)
      }

      const [intervalStart, intervalEnd]
        = getAdvertisingDates(advertising.dateStart.origin, advertising.dateEnd.origin, dateStartFilter, dateEndFilter)

      const intervals = [
        {
          data: [
            {
              item: face['@id'],
              properties: {
                budget: face.budget.value || 0
              }
            }
          ],
          dateStart: getFormattedDate(intervalStart, filterDateFormat),
          dateEnd: getFormattedDate(intervalEnd, filterDateFormat),
        }
      ]

      saveFactDataValues(intervals, () => {
        return resolve(true)
      })
    })
  }

  const onSaveFaceData = async face => {
    setIsFaceSaving(true)

    Promise.all(
      [
        saveFaceFactData(face),
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        getFactData(getTotalDataParams())
        setChangedFaces([])
      })
      .finally(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
      })
      .catch(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
      })
  }

  const onSaveAllFacesData = async () => {
    setIsFaceSaving(true)

    Promise.all(
      [
        saveAllFactData()
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        getFactData(getTotalDataParams())
        setChangedFaces([])
      })
      .finally(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
      })
      .catch(() => {
        setIsFaceSaving(false)
        setChangedFaces([])
      })
  }

  const saveAllFactData = async () => {
    return new Promise((resolve, reject) => {
      const faceIntervals = []

      advertisingsFacesMap.forEach((advertising, advertisingId) => {
        const faces = advertising.faces

        if (faces && Array.isArray(faces)) {
          for (let face of faces) {
            if (changedFaces.includes(face.id)) {
              const [intervalStart, intervalEnd]
                = getAdvertisingDates(advertising.dateStart.origin, advertising.dateEnd.origin, dateStartFilter, dateEndFilter)

              const intervalStartDate = getFormattedDate(intervalStart, filterDateFormat)
              const intervalEndDate = getFormattedDate(intervalEnd, filterDateFormat)

              const intervalKey = intervalStartDate + intervalEndDate

              if (!faceIntervals[intervalKey]) {
                faceIntervals[intervalKey] = {
                  dateStart: intervalStartDate,
                  dateEnd: intervalEndDate,
                  data: []
                }
              }

              faceIntervals[intervalKey].data.push({
                item: face['@id'],
                properties: {
                  budget: face.budget.value
                }
              })
            }
          }
        }
      })

      if (!Object.keys(faceIntervals).length) {
        return resolve(true)
      }

      saveFactDataValues(Object.values(faceIntervals), () => {
        return resolve(true)
      })
    })
  }

  const isAnyLoading = () => isAdvertisingsLoading || isFactDataLoading || isFacesLoading || isFaceSaving

  return (
    <div className="row content flex container-fluid padding-side-15 mediaplan-page">
      <div>
        <div>
          <MenuClient title={i18n.t('header.month_fact')}/>
        </div>

        <div className={'financial-month-header'}>
          <Filter
            filters={Object.values(filters)}
            getData={setFilterParams}
          />

          <Link to={`/financial/month-fact/upload/`} title={i18n.t('financial.save_month_fact')}>
            <i className="fal fa-upload" />
          </Link>
        </div>

        {advertisingsFacesMap &&
          <>
            <div className={'mediaplan-tables'}>
              <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
                <table className="table table-client goal-detail month-fact-table">
                  <Header
                    properties={properties}
                    cellsBeforeProps={
                      <>
                        <td>
                          <Checkbox
                            onChange={setIsAllChecked}
                            checked={isAllChecked}
                          />
                        </td>
                        <td>ID</td>
                        <td>{i18n.t('clients.page.name')}</td>
                        <td>{i18n.t('clients.advertising.contractor')}</td>
                      </>
                    }
                    cellsAfterProps={
                      <>
                        <td/>
                        <td/>
                      </>
                    }
                  />
                  <tbody>
                  {advertisingsFacesMap && [...advertisingsFacesMap].map(([advertisingId, advertising]) => (
                    <Fragment key={advertisingId}>
                      <Row
                        properties={properties}
                        values={advertising || {}}
                        titleColSpan={1}
                        index={advertisingId}
                        key={advertising.dateStart + advertising.dateEnd}
                        title={
                          <>
                            <div>
                              <Link to={getFactDataPageUrl(advertising)}>
                                {advertising.name}
                              </Link>
                            </div>
                            <div className={'month-fact-table__row-sub-title'}>
                              {advertising.parts && advertising.parts[0] &&
                                advertising.parts[0].name
                              }
                            </div>
                          </>
                        }
                        isSelectToPaste={false}
                        rowCellComponent={RowCell}
                        cellBeforeTitle={
                          <>
                            <td>
                              <Checkbox
                                onChange={value => onSelect(advertising.id, value)}
                                checked={selectedAdvertisingsIds && selectedAdvertisingsIds.includes(advertising.id)}
                                disabled={!advertising.parts || !advertising.parts.length}
                                title={(!advertising.parts || !advertising.parts.length) ? i18n.t('clients.advertising.empty_parts') : ''}
                              />
                            </td>
                            <td>
                              {advertising.id}
                            </td>
                          </>
                        }
                        cellAfterTitle={
                          <td/>
                        }
                        onChange={() => {}}
                        cellAfterProperties={
                          <>
                            <td/>
                            <td>
                              <div className='month-fact-buttons'>
                                {(!advertising.parts || !advertising.parts.length)
                                  ? <i className="fas fa-chain-broken" title={i18n.t('clients.advertising.empty_parts')}></i>
                                  : (
                                    <>
                                      {advertisingsStatuses && advertisingsStatuses[advertising.id] &&
                                        <>
                                          {advertisingsStatuses[advertising.id].status === 'loading' &&
                                            <Loader/>
                                          }

                                          {advertisingsStatuses[advertising.id].status === 'success' &&
                                            <span
                                              className={'month-fact-icon ' + advertisingsStatuses[advertising.id].status}>
                                              <i className="fas fa-check"></i>
                                            </span>
                                          }

                                          {advertisingsStatuses[advertising.id].status === 'empty' &&
                                            <i className="fas fa-chain-broken"
                                               title={i18n.t('clients.advertising.empty_parts')}></i>
                                          }

                                          {(advertisingsStatuses[advertising.id].status === 'error'
                                              || advertisingsStatuses[advertising.id].status === 'warning') &&
                                            <span
                                              className={'month-fact-icon ' + advertisingsStatuses[advertising.id].status}
                                              onClick={() => onStatusClick(advertisingsStatuses[advertising.id])}
                                              title={advertisingsStatuses[advertising.id].message}>
                                              <i className={"fas fa-exclamation-triangle"}></i>
                                            </span>
                                          }
                                        </>
                                      }
                                    </>
                                  )
                                }
                              </div>
                            </td>
                          </>
                        }
                      />

                      {advertising.faces && advertising.faces.length > 0 && advertising.faces.map((face, index) => (
                        <Fragment key={index + face.id}>
                          <Row
                            className={'row-face'}
                            properties={properties}
                            values={face || {}}
                            titleColSpan={1}
                            index={index}
                            key={index}
                            title={
                              <span>{face.name}</span>
                            }
                            isSelectToPaste={false}
                            rowCellComponent={RowCell}
                            onChange={(value, property) => onRowChange(value, property, face)}
                            cellBeforeTitle={
                              <>
                                <td/>
                                <td>{face.id}</td>
                              </>
                            }
                            cellAfterTitle={
                              <td>{face.contractor ? face.contractor.name : ''}</td>
                            }
                            row={face}
                            cellAfterProperties={
                              <>
                                <td>
                                  <div className='month-fact-buttons'>
                                    <div
                                      onClick={() => onSaveFaceData(face)}
                                      title={i18n.t('common.save')}>
                                      <i className={'far fa-save'}/>
                                    </div>
                                  </div>
                                </td>
                                <td/>
                              </>
                            }
                          />
                        </Fragment>
                      ))

                      }

                    </Fragment>
                  ))}

                  </tbody>
                </table>
                {isAnyLoading() &&
                  <LoaderBackdrop/>
                }

                {isAnyLoading() &&
                  <Loader/>
                }

                <Footer>
                  <div>
                    {selectedAdvertisingsIds && selectedAdvertisingsIds.length > 0 &&
                      <ButtonWithLoader
                        onClick={onDownloadFactClick}
                        className={"button " + (isAdvertisingsLoading ? 'load' : '')}
                        text={i18n.t('mediaplans.page.get_fact_results')}
                      />
                    }
                  </div>
                  <div>
                    {changedFaces && changedFaces.length > 0 &&
                      <ButtonWithLoader
                        onClick={() => onSaveAllFacesData()}
                        className={"button " + (isFaceSaving ? 'load' : '')}
                        text={i18n.t('clients.advertising.save_all')}
                        loadingText={i18n.t('clients.advertising.saving_values')}
                        isLoading={isFaceSaving}
                      />
                    }
                  </div>
                </Footer>
              </div>
            </div>
          </>
        }

        {pullHistories && pullHistoriesAdvertising &&
          <PullHistory
            items={pullHistories}
            setItems={setPullHistories}
            advertising={pullHistoriesAdvertising}
            getItems={getFactResultsHistory}
            isLoading={isHistoryLoading}
          />
        }
      </div>
    </div>
  )
}
