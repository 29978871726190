import React, {useEffect, useState} from 'react';
import SubMenu from '../../Components/SubMenu';
import MenuClient from '../../Components/MenuClient';
import {Link} from 'react-router-dom';
import i18n from "i18next";
import Table from "./Table";
import apiUrls from "../../ApiUrls"
import useDelete from "../../Components/Hooks/useDelete";
import useApiFetch from "../../Components/Hooks/useApiFetch";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import i18next from "i18next";
import {getFromLocation, groupParamsByKey, setUrlParams} from "../../utils";
import Filter from "../../Components/NewFilter/Filter";
import options from "../../options";

const perPage = 30;

function List(props) {
  const companyId = props.match.params.companyID
  const [page, setPage] = useState(getFromLocation('page', props.location) || 1)
  const [sort, setSort] = useState({})
  const [filterParams, setFilterParams] = useState('')
  const [{data: products, total: productsTotal}, getProducts] = useApiFetch(apiUrls.get.companyProducts(companyId))

  const deleteProductFunction = useDelete(apiUrls.delete.products)
  const deleteProduct = async (id) => {
    await deleteProductFunction(id, () => {
      toast.success(i18n.t('products.list.product_deleted'))
    })

    getProducts()
  }

  const changeSort = async (name, value) => {
    await setSort({
      ...sort,
      [name]: value
    })
  }

  const onSortClick = async (name) => {
    let order = ''

    if (sort[name] === options.ascSortOrder) {
      order = options.descSortOrder
    } else if (sort[name] === options.descSortOrder) {
      order = ''
    } else {
      order = options.ascSortOrder
    }

    changeSort(name, order)
  }

  useEffect(() => {
    const filterParamsByKey = groupParamsByKey(filterParams)
    const filters = getFilterParams(filterParams)

    if (page && filterParamsByKey.hasOwnProperty('id')
      && filterParamsByKey.hasOwnProperty('name')) {
      for (let key in sort) {
        const value = sort[key]
        if (sort.hasOwnProperty(key) && value) {
          filters[`order[${key}]`] = value
        }
      }

      getProducts(filters)
    }

  }, [filterParams, page, sort])

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const getFilterParams = (filterParams) => {
    const filterParamsByKey = groupParamsByKey(filterParams)
    const params = {
      page
    }
    if (filterParamsByKey.id) {
      params.id = filterParamsByKey.id
    }
    if (filterParamsByKey.name) {
      params.name = filterParamsByKey.name
    }

    return params
  }

  const filters = {
    id: {
      type: 'text',
      value: null,
      placeholder: i18n.t('products.list.id'),
      name: 'id',
      setFilterParams: (params, filterValue) => {
        params.append('id', filterValue)
        setPage(1)
      },
      getValueToUrl: (params, value) => {
        params.set('id', value)
        return params
      },
      getValueFromUrl: values => {
        if (values.id) {
          return values.id
        }

        return ''
      },
      className: 'pointer',
    },
    name: {
      type: 'text',
      placeholder: i18n.t('products.list.name'),
      name: 'name',
      getValueFromUrl: values => {
        if (values.name) {
          return values.name
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('name', value)
      },
      setFilterParams: (params, filterValue) => {
        params.append('name', filterValue)
        setPage(1)
      },
    },
  }

  return (
    <div className="row content flex">
      <div className="edit-client">
        <SubMenu />
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('products.list.products_list')}>
              <Link className="blue-cursor-button" to={"/client/" + props.match.params.companyID + "/product/new"}>
                <i className="fal fa-plus mr-for-icon" />
                {i18n.t('products.list.add_product')}
              </Link>
            </MenuClient>
            <Filter
              filters={Object.values(filters)}
              getData={setParams}
            />

            {products && products.length > 0 &&
            <>
              <Table
                products={products}
                companyId={props.match.params.companyID}
                deleteProduct={deleteProduct}
                sort={sort}
                onSortClick={onSortClick}
              />

              {(productsTotal > 0 && productsTotal > perPage) &&
              <ReactPaginate
                previousLabel={i18next.t('projects.list.prev')}
                nextLabel={i18next.t('projects.list.next')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(productsTotal / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(value) => {
                  setPage(+value.selected + 1)
                  setUrlParams(props,
                    {
                      page: +value.selected + 1
                    })
                }
                }
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                initialPage={page - 1}
              />
              }
            </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;