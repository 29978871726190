import React from 'react';
import i18next from "../i18n";
import options from "../options";

function SortIcon ({name, sort}) {
  const getSortClass = () => {
    if (!sort[name]) {
      return 'fa-sort'
    }

    if (sort[name] === options.ascSortOrder) {
      return 'fa-sort-asc'
    }

    if (sort[name] === options.descSortOrder) {
      return 'fa-sort-desc'
    }
  }

  const getSortName = () => {
    if (!sort[name]) {
      return i18next.t('clients.advertising.sort_asc')
    }

    if (sort[name] === options.ascSortOrder) {
      return i18next.t('clients.advertising.sort_desc')
    }

    if (sort[name] === options.descSortOrder) {
      return i18next.t('clients.advertising.sort_cancel')
    }
  }

  return (
    <i className={"fa " + getSortClass()} aria-hidden="true"
    title={getSortName()} />
  )
}

export default SortIcon;