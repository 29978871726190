import React from 'react';
import ArrowRight from "../Icons/ArrowRight";
import {Link} from "react-router-dom";

function ArrowLink({to, text, className}) {
  return (
    <Link
      to={to}
      className={'arrow-link ' + className}
    >
      <span className="arrow-link__text">
        {text}
        <span className="arrow-link__icon">
          <ArrowRight/>
        </span>
      </span>
    </Link>
  )
}

export default ArrowLink;