import React, {useState} from 'react';
import AffinityRow from "./AffinityRow";
import {cloneDeep} from 'lodash'
import {getFormattedDate} from "../Fact/Utils";
import i18n from "i18next";

function AffinitiesBlock({affinities, onChange, formFace, title}) {
  //const [isOpen, setIsOpen] = useState(false)
  const onRowChange = (index, value) => {
    let affs = cloneDeep(affinities)

    if (affs[index]) {
      affs[index] = value;
    }

    onChange(affs)
  }

  const deleteRow = (index) => {
    let affs = cloneDeep(affinities)

    affs.splice(index, 1);

    onChange(affs)
  }

  const addRow = () => {
    let affs = cloneDeep(affinities) || []

    affs.push({
      dateStart: getFormattedDate(new Date(), 'YYYY-MM-DD'),
      value: 0
    })

    onChange(affs)
  }

  return (
    <div className={'popup-form__row ratios'}>
      <div className={'popup-form__row-title'}>{title}</div>
      <div className={'affinities-wrapper'}>
        <div>
          <div>
            {affinities && affinities.length > 0 &&
            <>
              {affinities.map((affinity, index) => (
                <div className={'affinity-wrapper'} key={index}>
                  <AffinityRow
                    key={affinity.id}
                    affinity={affinity}
                    formFace={formFace}
                    onChange={value => onRowChange(index, value)}
                  />
                  <button onClick={() => deleteRow(index)}>
                    <i className="fal fa-trash-alt icon-delete" />
                  </button>
                </div>
              ))}
            </>
            }

            <a onClick={() => addRow()} className="blue-cursor-button add-link">
              <i className="fal fa-plus mr-for-icon" />
              {i18n.t('clients.advertising.add')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AffinitiesBlock;