import React, {Fragment, useEffect, useState} from 'react';
import i18n from "i18next";
import 'react-accessible-accordion/dist/fancy-example.css';
import {cloneDeep} from 'lodash'
import useApiFetch from "../../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../../ApiUrls";
import {toast} from "react-toastify";
import {getMonthPeriods} from "../../Utils";
import {getFormattedDate} from "../../Fact/Utils";
import ButtonWithLoader from "../../../../../Components/Buttons/ButtonWithLoader";
import LoaderBackdrop from "../../../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../../../Components/Loader/Loader";
import {ChannelFactory} from "../../../../../entities/channel/ChannelFactory";
import {TvChannel} from "../../../../../entities/channel/TvChannel";
import {dayInterval, monthInterval, weekInterval, wholeInterval} from "../components/dateIntervals";
import RadioGroup from "../../../../../Components/Inputs/RadioGroup";
import BackButtons from "../../components/BackButtons";
import Header from "../../components/Header";
import TableHeader from "../../components/Table/Header";
import SumRow from "../../components/Table/SumRow";
import Footer from "../../components/Footer";
import Row from "../../components/Table/Row";
import useApiPost from "../../../../../Components/Hooks/useApiPost";
import PeriodTitle from "../../components/Table/PeriodTitle";
import PasteForm from "../../components/PasteForm";
import {getColumnDataFromTableText, getRowDataFromTableText} from "../../../../../utils";
import RowCell from "../../components/Table/RowCell";

const dateFormat = 'YYYY-MM-DD';

const dateIntervals = [wholeInterval, monthInterval, weekInterval, dayInterval]

const getAdvertisingPeriod = (advertising) => {
  return {
    value: {
      dateStart: getFormattedDate(advertising.dateStart, dateFormat),
      dateEnd: getFormattedDate(advertising.dateEnd, dateFormat),
    },
    code: 'whole',
    label: i18n.t('clients.advertising.whole_advertising')
  }
}

const getDatePeriods = (advertising) => {
  let periods = []

  const advertisingPeriod = getAdvertisingPeriod(advertising)

  periods.push(advertisingPeriod)

  let monthPeriods = getMonthPeriods(advertising.dateStart, advertising.dateEnd)

  if (monthPeriods.length > 1) {
    let index = 1

    for (let month of monthPeriods) {
      periods.push({
        value: {
          dateStart: getFormattedDate(month.dateStart, dateFormat),
          dateEnd: getFormattedDate(month.dateEnd, dateFormat),
        },
        code: 'month' + index,
        label: i18n.t('clients.advertising.month') + ' ' + index
      })

      index++
    }
  }

  return periods
}

const getDataToSave = plan => {
  let data = cloneDeep(plan)

  for (let period of data) {
    for (let item of period.data) {
      if (item.check !== undefined) {
        delete item.check
      }

      for (let propCode in item.properties) {
        item.properties[propCode] = item.properties[propCode].value
      }
    }
  }

  return data
}

const getPlanSumValues = (properties, plan) => {
  const sum = {}

  for (let property of properties) {
    for (let period of plan) {
      for (let items of period.data) {
        if (items.properties && items.properties.hasOwnProperty(property.code) && items.properties[property.code].value) {
          if (!sum[property.code]) {
            sum[property.code] = 0
          }

          if (property.method === 'sum') {
            sum[property.code] += items.properties[property.code].value
          } else if (property.method === 'max') {
            if (items.properties[property.code].value > sum[property.code]) {
              sum[property.code] = items.properties[property.code].value
            }
          }
        }
      }
    }
  }

  return sum
}

function List(props) {
  const companyId = props.match.params.companyID
  const advertisingId = props.match.params.advertisingId
  const channelCode = props.match.params.channel

  const [channelEntity, setChannelEntity] = useState(ChannelFactory.create(channelCode))

  const defaultInterval = channelEntity instanceof TvChannel ? monthInterval : wholeInterval;

  const [selectedInterval, setSelectedInterval] = useState(defaultInterval)
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [datePeriods, setDatePeriods] = useState([])
  const [formPlan, setFormPlan] = useState(null)
  const [isPlanLoading, setIsPlanLoading] = useState(false)
  const [isPlanSaving, setIsPlanSaving] = useState(null)
  const [selectedAdvertisingToPaste, setSelectedAdvertisingToPaste] = useState(null)
  const [selectedFieldToPaste, setSelectedFieldToPaste] = useState(null)
  const [textToPasteTable, setTextToPasteTable] = useState(null)

  const [{data: advertising}, getAdvertising] = useApiFetch(apiUrls.get.advertising(advertisingId))
  const [{data: channels}, getChannels] = useApiFetch(apiUrls.get.channels())
  const [{data: properties}, getProperties] = useApiFetch(channelEntity.getApiUrl('properties') + '?entity=advertising')
  const [{data: plan}, getPlan] = useApiFetch(apiUrls.get.advertisingPlan(channelCode))

  const [{errors: updateErrors, data: updateData}, updatePlan] = useApiPost(
    apiUrls.get.advertisingPlan(channelCode),
    '',
    'periods.edit',
    () => {
      toast.success(i18n.t('clients.advertising.plans_saved'))
      setIsPlanLoading(true)
      setIsPlanSaving(false)
    }
  )

  const setDatePeriodsFromAdvertising = (advertising) => {
    const advertisingPeriod = getAdvertisingPeriod(advertising)

    setSelectedPeriod(advertisingPeriod)

    const periods = getDatePeriods(advertising)
    setDatePeriods(periods)
  }

  const fetchPlan = async () => {
    setIsPlanLoading(true)

    await getPlan({
      'advertising.id': advertisingId,
      'date[before]': getFormattedDate(selectedPeriod.value.dateEnd, dateFormat),
      'date[after]': getFormattedDate(selectedPeriod.value.dateStart, dateFormat),
      interval: selectedInterval.value
    })
  }

  useEffect(() => {
    getAdvertising({
      't[]': 'target'
    })

    getChannels({
      code: channelEntity.getCode()
    })

    getProperties()
  }, [])

  useEffect(() => {
    if (advertising) {
      setDatePeriodsFromAdvertising(advertising)
    }
  }, [advertising])

  useEffect(() => {
    if (isPlanSaving === false) {
      fetchPlan()
    }
  }, [isPlanSaving])

  useEffect(() => {
    if (advertising && selectedPeriod && selectedInterval) {
      fetchPlan()
    }
  }, [selectedPeriod, selectedInterval])

  useEffect(() => {
    if (plan) {
      setIsPlanLoading(false)

      setFormPlan(plan)
    }
  }, [plan])

  useEffect(() => {
    if (channels && channels.length) {
      const channel = channels.pop()

      if (channel.name) {
        channelEntity.name = channel.name
        setChannelEntity(channelEntity)
      }
    }
  }, [channels])

  const getItemIri = () => {
    return apiUrls.get.advertising(advertisingId);
  }

  const isAnyLoading = () => isPlanLoading || isPlanSaving

  const onSave = () => {
    setIsPlanSaving(true)

    updatePlan({
      intervals: getDataToSave(formPlan)
    })
  }

  const onRowChange = (value, property, periodIndex, rowIndex) => {
    const formNewPlan = cloneDeep(formPlan)

    if (formNewPlan && formNewPlan[periodIndex] && formNewPlan[periodIndex].data) {
      if (!formNewPlan[periodIndex].data[rowIndex]) {
        formNewPlan[periodIndex].data = [{
          item: getItemIri(),
          properties: {}
        }]

        formNewPlan[periodIndex].data[rowIndex].properties[property.code] = {
          editable: true,
          value
        }
      } else {
        if (formNewPlan[periodIndex].data[rowIndex].properties) {
          if (formNewPlan[periodIndex].data[rowIndex].properties[property.code]) {
            formNewPlan[periodIndex].data[rowIndex].properties[property.code].value = value
          } else {
            formNewPlan[periodIndex].data[rowIndex].properties[property.code] = {
              editable: true,
              value
            }
          }
        }
      }
    }

    setFormPlan(formNewPlan)
  }

  const pasteFromTable = () => {
    if ((selectedAdvertisingToPaste === null && selectedFieldToPaste === null)
      || textToPasteTable === null
    ) {
      return
    }

    if (selectedFieldToPaste !== null) {
      const data = getColumnDataFromTableText(textToPasteTable, properties, selectedFieldToPaste)

      setColumnDetailValues(data)
    } else if (selectedAdvertisingToPaste !== null) {
      const data = getRowDataFromTableText(textToPasteTable)

      setRowDetailValues(data)
    }

    setTextToPasteTable(null)
    setSelectedAdvertisingToPaste(null)
    setSelectedFieldToPaste(null)
  }

  const setRowDetailValues = (values) => {
    const formNewPlan = cloneDeep(formPlan)

    for (let valuesIndex in values) {
      const value = values[valuesIndex]
      const advertIndex = +valuesIndex + selectedAdvertisingToPaste

      if (formNewPlan && formNewPlan[advertIndex] && formNewPlan[advertIndex].data) {
        if (!formNewPlan[advertIndex].data[0]) {
          formNewPlan[advertIndex].data = [{
            item: getItemIri(),
            properties: {}
          }]
        }

        let index = 0
        for (let property of properties) {
          formNewPlan[advertIndex].data[0].properties[property.code] = {
            editable: true,
            value: value[index]
          }

          index++;
        }
      }

      setFormPlan(formNewPlan)

    }
  }

  const setColumnDetailValues = (values) => {
    const formNewPlan = cloneDeep(formPlan)

    for (let advertIndex in formNewPlan) {
      const advert = formNewPlan[advertIndex]

      if (!advert.data || !advert.data.length) {
        advert.data.push({
          item: getItemIri(),
          properties: {},
        })
      }

      for (let property of properties) {
        if (values[property.code] && values[property.code][advertIndex] !== undefined) {
          advert.data[0].properties[property.code] = {
            editable: true,
            value: values[property.code][advertIndex],
          }
        }
      }
    }

    setFormPlan(formNewPlan)
  }

  return (
    <>
      <div className="container-fluid padding-side-15 mediaplan-page mediaplan plan-table">
        {/*<div>{selectedAdvertisingToPaste}</div>*/}
        <div className="row">
          {advertising && channelEntity &&
            <>
              <Header
                title={i18n.t('clients.advertising.advertising_detail')}
                channelName={channelEntity.name || ''}
                productName={advertising.product.name}
                advertisingName={`(${advertising.id}) ${advertising.name}`}
                period={getFormattedDate(advertising.dateStart, 'DD.MM.Y') + '-' + getFormattedDate(advertising.dateEnd, 'DD.MM.Y')}
              />

              <BackButtons
                companyId={companyId}
                advertisingId={advertisingId}
              />

              <div className={'radio-block card'}>
                <RadioGroup
                  title={i18n.t('clients.advertising.select_interval')}
                  selected={selectedInterval}
                  setSelected={setSelectedInterval}
                  options={dateIntervals}
                  name={'interval'}
                />

                {datePeriods && selectedPeriod && (datePeriods.length > 1) &&
                  <RadioGroup
                    title={i18n.t('clients.advertising.select_period')}
                    selected={selectedPeriod}
                    setSelected={setSelectedPeriod}
                    options={datePeriods}
                    name={'period'}
                  />
                }
              </div>

              {properties && formPlan &&
                <div className={'mediaplan-tables'}>
                  <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
                    <table className="table redesign-table table-client goal-detail mediaplans">
                      <TableHeader
                        properties={properties}
                        leftColumnsColSpan={2}
                      />
                      <tbody>
                      <SumRow
                        properties={properties}
                        values={getPlanSumValues(properties, formPlan)}
                        title={i18n.t('clients.advertising.all_input_values')}
                        titleColSpan={2}
                      />

                      <tr>
                        <td />
                        <td />
                        {properties.map(property =>(
                          <td className={'select-paste-column'} key={property.code}>
                        <span
                          onClick={() => {
                            setSelectedFieldToPaste(property.code)
                          }}
                        ><i className="fas fa-arrow-alt-circle-down" /></span>
                          </td>
                        ))}
                      </tr>

                      {formPlan.map((period, periodIndex) => (
                        <Fragment key={period.dateStart + period.dateEnd}>
                          {period.data &&
                            <>
                              {period.data.length > 0 && period.data.map((item, rowIndex) => (
                                <>
                                  <Row
                                    properties={properties}
                                    values={item.properties || {}}
                                    titleColSpan={1}
                                    index={periodIndex}
                                    onChange={(value, property) => onRowChange(value, property, periodIndex, rowIndex)}
                                    onSave={onSave}
                                    key={period.dateStart + period.dateEnd}
                                    setSelectedRow={setSelectedAdvertisingToPaste}
                                    title={<PeriodTitle
                                      interval={selectedInterval}
                                      dateStart={period.dateStart}
                                      dateEnd={period.dateEnd}
                                    />}
                                    rowCellComponent={RowCell}
                                  />
                                </>
                              ))}

                              {period.data.length === 0 &&
                                <Row
                                  properties={properties}
                                  values={{}}
                                  titleColSpan={1}
                                  index={periodIndex}
                                  onChange={(value, property) => onRowChange(value, property, periodIndex, 0)}
                                  onSave={onSave}
                                  setSelectedRow={setSelectedAdvertisingToPaste}
                                  title={<PeriodTitle
                                    interval={selectedInterval}
                                    dateStart={period.dateStart}
                                    dateEnd={period.dateEnd}
                                  />}
                                  rowCellComponent={RowCell}
                                />
                              }
                            </>
                          }
                        </Fragment>
                      ))}

                      </tbody>
                    </table>
                    {isAnyLoading() &&
                      <LoaderBackdrop />
                    }

                    {isPlanLoading &&
                      <Loader />
                    }
                  </div>
                </div>
              }
            </>
          }
        </div>

        {(selectedAdvertisingToPaste !== null || selectedFieldToPaste !== null) &&
          <PasteForm
            value={textToPasteTable || ''}
            setTextToPasteTable={setTextToPasteTable}
            setSelectedDetailToPasteTable={setSelectedAdvertisingToPaste}
            setSelectedFieldToPaste={setSelectedFieldToPaste}
            paste={pasteFromTable}
          ></PasteForm>
        }

        <Footer>
          <div />
          <div>
            {formPlan &&
              <ButtonWithLoader
                onClick={onSave}
                className={"button " + (isPlanSaving ? 'load' : '')}
                text={i18n.t('clients.advertising.save')}
                loadingText={i18n.t('clients.advertising.saving_values')}
                isLoading={isPlanSaving}
              />
            }
          </div>
        </Footer>

      </div>
    </>
  )
}

export default List;