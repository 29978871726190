import React, {useEffect, useState} from 'react'
import MenuClient from '../../Components/MenuClient'
import i18n from "i18next"
import Table from "./Table"
import apiUrls from "../../ApiUrls"
import useApiFetch from "../../Components/Hooks/useApiFetch"
import ReactPaginate from "react-paginate";
import i18next from "i18next";
import {Link} from "react-router-dom";
import Filter from "../../Components/Filter/Filter";
import moment from "moment";
import {getFiltersQuery} from "../../utils";
import Protected from "../../Components/Roles/Protected";

const perPage = 30
const dateFormat = 'Y-MM-DD'

function List(props) {
  const [page, setPage] = useState(1)
  const [registries, setRegistries] = useState([])
  const [filter, setFilter] = useState({
    date: null,
    contractor: '',
    advertisings: '',
  })
  const [{data: fetchedRegistries, total: registriesTotal}, getRegistries] = useApiFetch(apiUrls.get.registries())
  const [{data: contractors}, getContractors] = useApiFetch(apiUrls.get.contractorLegals())
  const [{data: advertisings}, getAdvertisings] = useApiFetch(apiUrls.get.adverts())

  const getAdverts = () => {
    getAdvertisings({
      't[]': 'short',
      'dateStart[before]': (filter.date ? moment.utc(filter.date).endOf('month').format(dateFormat) : moment(new Date()).endOf('year').format(dateFormat)),
      'dateEnd[after]': (filter.date ? moment.utc(filter.date).format(dateFormat) : moment(new Date()).startOf('year').format(dateFormat)),
      'pagination': false
    })
  }

  useEffect(() => {
    getContractors({
      'order[name]': 'asc',
      'pagination': false
    })
  }, [])

  useEffect(() => {
    getRegistries({
      page,
      't[]': 'registry_list',
      ...getFilterParams()
    })
  }, [page, filter])

  useEffect(() => {
    getAdverts()
  }, [filter.date])

  useEffect(() => {
    if (fetchedRegistries) {
      const regs = fetchedRegistries.slice()

      for (let registry of regs) {
        registry.sumExpenses = 0

        if (registry.invoiceRows && registry.invoiceRows.length) {
          registry.sumExpenses = registry.invoiceRows.reduce((total, row) => {
            return +total + row.expenses
          }, 0)
        }
      }

      setRegistries(regs)
    }
  }, [fetchedRegistries])

  const getFilterParams = () => {
    let queryParams = {}

    for (let filterName in filter) {
      if (filter.hasOwnProperty(filterName) && filter[filterName]) {
        switch (filterName) {
          case 'contractor':
          case 'advertisings':
            if (filter[filterName].value) {
              queryParams[filterName + '.id'] = filter[filterName].value
            }

            break;

          case 'date':
            queryParams['dateStart[after]'] = moment.utc(filter[filterName]).startOf('month').format(dateFormat)
            queryParams['dateEnd[before]'] = moment.utc(filter[filterName]).endOf('month').format(dateFormat)
            break;
        }
      }
    }

    return queryParams
  }

  const toggleRegistry = index => {
    const regs = registries.slice()

    if (regs[index]) {
      regs[index].active = !regs[index].active

      setRegistries(regs)
    }
  }

  const onFilterSet = (filter, callback, name) => {
    setFilter(filter)
    props.history.push({
      pathname: props.history.location.pathname,
      search: getFiltersQuery(filter, page)
    })
  }

  const emptyOption = {
    label: i18n.t('projects.list.not_selected'),
    value: ''
  }

  const contractorOptions = contractors
    ? contractors.map((contractor) => {
      return {value: contractor.id, label: contractor.name}
    })
    : []

  contractorOptions.unshift(emptyOption)

  const advertisingOptions = advertisings
    ? advertisings.map((advertising) => {
      return {value: advertising.id, label: advertising.name}
    })
    : []

  advertisingOptions.unshift(emptyOption)

  const filterOptions = [
    {
      type: 'date',
      value: filter.date,
      placeholder: i18n.t('registries.list.month'),
      name: 'date',
      format: "MM.yyyy",
      filterFormat: dateFormat,
      className: 'has-separator pointer has-search-icon',
      showMonthYearPicker: true,
    },
    {
      type: 'dropdown',
      value: filter.contractor,
      placeholder: i18n.t('registries.list.contractor'),
      options: contractorOptions,
      name: 'contractor',
      className: 'has-separator pointer',
    },
    {
      type: 'dropdown',
      value: filter.advertisings,
      placeholder: i18n.t('registries.list.advertising'),
      options: advertisingOptions,
      name: 'advertisings',
      className: 'has-separator pointer'
    },
  ]

  return (
    <div className="row content flex">
      <div className="edit-client">
        <div className="table-edit-client">
          <div>
            <MenuClient title={i18n.t('registries.list.title')}>
              <Protected access={'FinancialRegistriesEdit'}>
                <Link className="blue-cursor-button" to={"registry/new"}>
                  <i className="fal fa-plus mr-for-icon" />
                  {i18n.t('registries.list.add_new')}
                </Link>
              </Protected>
            </MenuClient>
            {filterOptions && contractors &&
            <Filter
              filters={filterOptions}
              filter={filter}
              setPage={setPage}
              setFilter={onFilterSet}
              location={props.location}
              /*getData={updateData}
              setEmptyData={setEmptyData}*/
            />
            }

            {registries && registries.length > 0 &&
            <Table
              registries={registries}
              toggleRegistry={toggleRegistry}
            />
            }

            {(registriesTotal > 0 && registriesTotal > perPage) &&
            <ReactPaginate
              previousLabel={i18next.t('projects.list.prev')}
              nextLabel={i18next.t('projects.list.next')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(registriesTotal / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(value) => {
                setPage(+value.selected + 1)
              }
              }
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;