import React, {useEffect, useState} from 'react';
import DownloadMediaplanXls from "../DownloadMediaplanXls";

function PageName({advertising}) {
  return (
    <div className="top-menu">
      <div className="name-page">Плановые данные РК {advertising.name}</div>
      {advertising &&
        <DownloadMediaplanXls advertisingId={advertising.id} />
      }
    </div>
  )
}

export default PageName