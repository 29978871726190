import React, {Fragment, useEffect, useMemo, useState} from 'react';
import i18n from "i18next";
import useApiFetch from "../../../../Components/Hooks/useApiFetch";
import apiUrls from "../../../../ApiUrls";
import {Link, useHistory} from "react-router-dom";
import {useParams} from 'react-router';
import {cloneDeep} from "lodash";
import {toast} from "react-toastify";
import {getFormattedDate, getFormPeriod, getIntervals, getIntervalsToSave, getUtcDate} from "./Utils";
import useApiPost from "../../../../Components/Hooks/useApiPost";
import PasteForm from "../components/PasteForm";
import {getColumnDataFromTableText, getFieldValue, getRowDataFromTableText} from "../../../../utils";
import {api} from "../../../../api/apiProvider";
import Roles from "../../../../roles/Roles";
import RadioGroup from "../../../../Components/Inputs/RadioGroup";
import ButtonWithLoader from "../../../../Components/Buttons/ButtonWithLoader";
import Footer from "../components/Footer";
import TableHeader from "../components/Table/Header";
import SumRow from "../components/Table/SumRow";
import Row from "../components/Table/Row";
import PeriodTitle from "../components/Table/PeriodTitle";
import LoaderBackdrop from "../../../../Components/Loader/LoaderBackdrop";
import Loader from "../../../../Components/Loader/Loader";
import FactRowCell from "./FactRowCell";
import PlusIcon from "../../../../Components/Icons/PlusIcon";
import Protected from "../../../../Components/Roles/Protected";
import FilterSelect from "../../../../Components/Inputs/FilterSelect";
import {ChannelFactory} from "../../../../entities/channel/ChannelFactory";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import MultiSelectWithCheckbox from "../../../../Components/Inputs/MultiSelectWithCheckbox";
import useDelete from "../../../../Components/Hooks/useDelete";
import {InternetChannel} from "../../../../entities/channel/InternetChannel";
import {DigitalChannel} from "../../../../entities/channel/DigitalChannel";
import DigitalFaceInfo from "../Faces/DigitalFaceInfo";
import {sendPlanValues} from "../../Utils";
import Checkbox from "../../../../Components/Inputs/Checkbox";

const monthInterval = {
  value: null,
  label: i18n.t('clients.advertising.month'),
  code: 'month',
}

const weekInterval = {
  value: 7,
  label: i18n.t('clients.advertising.week'),
  code: 'week',
}

const dayInterval = {
  value: 1,
  label: i18n.t('clients.advertising.day'),
  code: 'day',
}

const dateFormat = 'Y-MM-DD'

const dateIntervals = [
  monthInterval, weekInterval, dayInterval
]

const round = value => Math.round((value + Number.EPSILON) * 100) / 100


function PeriodsTable(props) {
  const channelId = props.channel.id
  const channelCode = props.channel.code
  const channelEntity = ChannelFactory.create(channelCode)
  const history = useHistory()
  const [interval, setInterval] = useState(monthInterval)
  const {companyID} = useParams();

  const [advert] = useState(props.advert)
  const properties = useMemo(() => {
    return cloneDeep(props.fields)
  }, [props.fields])

  const [period, setPeriod] = useState(null)
  const [advertFaces, setAdvertFaces] = useState(props.advertFaces)
  const [contractorsOptions, setContractorsOptions] = useState([])
  const [selectedContractor, setSelectedContractor] = useState(null)
  const [facesReturns, setFacesReturns] = useState(null)
  const [facesCommissions, setFacesCommissions] = useState(null)
  const [selectedDetailToPasteTable, setSelectedDetailToPasteTable] = useState(null)
  const [selectedPeriodToPasteTable, setSelectedPeriodToPasteTable] = useState(null)
  const [selectedFieldToPasteTable, setSelectedFieldToPasteTable] = useState(null)
  const [textToPasteTable, setTextToPasteTable] = useState(null)
  const [isFaceSaving, setIsFaceSaving] = useState(null)
  const [clientCheck, setClientCheck] = useState(null)
  const [selectedProps, setSelectedProps] = useState(null)
  const [selectedProperties, setSelectedProperties] = useState(null)
  const [timer, setTimer] = useState(null)
  const [selectedFaceForInfo, setSelectedFaceForInfo] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [selectedForDelete, setSelectedForDelete] = useState(null)

  const [{data: periods, isLoading: periodsLoading}, getPeriods] = useApiFetch(channelEntity.apiUrls.totalPlan)
  const [{data: factData, isLoading: isFactDataLoading}, getFactData] = useApiFetch(channelEntity.apiUrls.totalFact)

  const [{
    data: clientChecks,
    isLoading: isClientChecksLoading
  }, getClientChecks] = useApiFetch(apiUrls.get.clientChecks())

  const [{errors: saveFactDataErrors}, saveFactData] = useApiPost(
    channelEntity.apiUrls.totalFact,
    i18n.t('clients.advertising.correction_update_error'),
    'clients.advertising'
  )

  const deleteFaceFact = useDelete(() => channelEntity.apiUrls.totalFact)

  const [{errors: createClientCheckError}, createClientCheck] = useApiPost(
    apiUrls.get.clientChecks(),
    '',
    'errors'
  )

  const [{errors: updateClientCheckErrors}, updateClientCheck] = useApiPatch(
    apiUrls.get.clientCheck,
    '',
    'errors',
    () => {
    },
    () => {
      setIsFaceSaving(false)
    }
  )

  const [{errors: addClientCheckRowErrors}, addClientCheckRow] = useApiPost(
    apiUrls.get.clientCheckRows(),
    '',
    'errors',
  )

  const [{errors: updateClientCheckRowErrors}, updateClientCheckRow] = useApiPatch(
    apiUrls.get.clientCheckRow,
    '',
    'errors',
  )

  const deleteClientCheckRow = useDelete(apiUrls.get.clientCheckRow)

  const getIntervalUnit = () => {
    return interval.code
  }

  const saveValueToUrl = (name, value) => {
    let params = new URLSearchParams(history.location.search)

    if (!value) {
      params.delete(name)
    } else {
      params.set(name, value)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const fetchData = () => {
    const params = {
      'face.advertising.id': advert.id,
      'date[after]': props.dateStart,
      'date[before]': props.dateEnd,
      'interval': interval.code
    }

    if (selectedContractor && selectedContractor.value) {
      params['face.contractor.id'] = selectedContractor.value
    }

    params['props[]'] = selectedProperties.map(property => property.code)
    params['props[]'].push('budget')

    getPeriods(params)
    getFactData(params)

    if (interval === monthInterval) {
      getClientChecks(getClientChecksParams())
    }
  }

  const getPeriod = () => {
    fetchData()
  }

  useEffect(() => {
    if (selectedContractor) {
      saveValueToUrl('contractor', selectedContractor.value)
    }
  }, [selectedContractor])

  useEffect(() => {
    if (factData && factData[0] && factData[0].data) {
      const faces = {}

      for (let face of props.advertFaces) {
        faces[face['@id']] = {
          name: (face.regions && face.regions.length && face.regions[0].name)
            ? face.name + '.' + face.regions[0].name
            : face.name,
          key: face['@id'],
          id: face.id,
          contractor: face.contractor
        }
      }

      setAdvertFaces(faces)
      setSelectedForDelete(null)
    }
  }, [factData])

  useEffect(() => {
    if (
      (!periods || !periods.length)
      || (!factData || !factData.length)
      || (!advertFaces || !Object.values(advertFaces).length)
      || (!clientCheck && (interval === monthInterval))
      || isFactDataLoading
      || isClientChecksLoading
    ) {
      return
    }

    const intervals = getIntervals(props.dateStart, props.dateEnd, getIntervalUnit())

    let periodEdit = getFormPeriod(intervals, periods, factData, advertFaces, properties)

    const faceClientCheckRows = new Map()

    if (clientCheck.rows) {
      for (let row of clientCheck.rows) {
        const faceIri = row.faces[0]['@id']

        faceClientCheckRows.set(faceIri, row)
      }
    }

    for (let period of periodEdit) {
      if (period.faces) {
        for (let faceIri in period.faces) {
          const face = period.faces[faceIri]
          face.clientBudget = {
            fact: {
              editable: true,
              value: '',
            },
            plan: {
              editable: false,
              value: '',
            }
          }

          if (face.budget && face.budget.plan && face.budget.plan.value !== undefined) {
            face.clientBudget.plan.value = face.budget.plan.value
          }

          if (faceClientCheckRows.has(faceIri)) {
            const checkRow = faceClientCheckRows.get(faceIri)
            face.clientBudget.fact.value = checkRow.budget
            face.clientBudget.fact.editable = !checkRow.approved
            face.checkRow = checkRow

            if (checkRow.approved) {
              face.clientBudget.bottom = i18n.t('face_check.client_budget_checked')
              face.clientBudget.bottomClass = 'cell-bottom checked'
            }
          }

          if (face.check && !face.budget.fact.editable) {
            face.budget.bottom = i18n.t('face_check.budget_checked')
            face.budget.bottomClass = 'cell-bottom checked'
          }

          if (face.check && face.budget.fact.editable) {
            face.budget.bottom = i18n.t('face_check.budget_checking')
            face.budget.bottomClass = 'cell-bottom non-checked'
          }
        }
      }
    }

    setPeriod(periodEdit)
  }, [periods, factData, advertFaces, clientCheck])

  useEffect(() => {
    if (selectedProperties) {
      const params = new URLSearchParams(history.location.search)
      const contractorFromUrl = params.get('contractor')

      if (contractorFromUrl && !selectedContractor) {
        return
      }

      if (timer) {
        clearTimeout(timer);
      }

      const timerId = setTimeout(() => {
        getPeriod()
      }, 1300);

      setTimer(timerId)
    }
  }, [advert, interval, selectedProperties, selectedContractor])

  useEffect(() => {
    if (Roles.hasAccess('FinancialCommissionsRead')) {
      async function fetchData(faceIds) {
        const url = channelEntity.getApiUrl('faceCommissions')
        const params = new URLSearchParams({
          dateStart: props.dateStart,
          dateEnd: props.dateEnd,
        })

        const faceParams = faceIds.map(id => `face.id[]=${id}`).join('&')

        const faceCommissionsResult = await api.get(url + '?' + params.toString() + '&' + faceParams)
        const faceCommissions = faceCommissionsResult['hydra:member']

        let advertFaces = {}
        let facesCommissions = {}

        for (let faceCommission of faceCommissions) {
          const faceId = faceCommission['@id'].split('/').pop()

          if (faceCommission && faceCommission.contractType && faceCommission.contractType.return) {
            advertFaces[faceId] = faceCommission.contractType.return
          }

          if (faceCommission.commissions && faceCommission.commissions.length) {
            facesCommissions[faceId] = faceCommission.commissions
          }
        }

        return {
          advertFaces,
          facesCommissions,
        }
      }

      const faceIds = props.advertFaces.map(face => face.id)
      const chunkedFaceIds = chunk(faceIds, 100);

      Promise.all(chunkedFaceIds.map(faceIds => fetchData(faceIds))).then(values => {
        let advertFaces = {}
        let facesCommissions = {}

        for (let value of values) {
          if (value.advertFaces && Object.keys(value.advertFaces).length) {
            for (let key in value.advertFaces) {
              advertFaces[key] = value.advertFaces[key]
            }
          }

          if (value.facesCommissions && Object.keys(value.facesCommissions).length) {
            for (let key in value.facesCommissions) {
              if (value.facesCommissions[key] && value.facesCommissions[key].length) {
                const commissions = value.facesCommissions[key].map(commission => {
                  return {
                    id: commission['@id'].split('/').pop()
                  }
                })

                facesCommissions[key] = commissions
              }
            }
          }
        }

        setFacesReturns(advertFaces)
        setFacesCommissions(facesCommissions)
      })
    }

    const facesWithContractors = props.advertFaces.filter(face => face.contractor)
    const faceContractors = [...new Map(facesWithContractors.map(face =>
      [face.contractor.id, face.contractor])).values()];

    const contractors = faceContractors.map(contractor => ({
      label: contractor.name,
      value: contractor.id,
    }))

    contractors.unshift({
      label: i18n.t('projects.list.not_selected'),
      value: null,
    })

    if (!contractorsOptions || !contractorsOptions.length) {
      setContractorsOptions(contractors)

      const params = new URLSearchParams(history.location.search)
      const contractorFromUrl = params.get('contractor')

      if (contractorFromUrl) {
        const selectedContractor = contractors.find(contractor => contractor.value === parseInt(contractorFromUrl))

        if (selectedContractor) {
          setSelectedContractor(selectedContractor)
        } else {
          setSelectedContractor(contractors[0])
        }
      }
    }
  }, [advertFaces])

  useEffect(() => {
    if (clientChecks) {
      if (!clientChecks.length) {
        const dateStart = getUtcDate(props.dateStart).startOf('month').format(dateFormat)
        const dateEnd = getUtcDate(props.dateEnd).endOf('month').format(dateFormat)

        createClientCheck({
          channel: `/channels/${props.channel.id}`,
          client: `/companies/${props.companyId}`,
          dateStart,
          dateEnd,
        }, null, {}, true, () => {
          getClientChecks(getClientChecksParams())
        })
      } else {
        setClientCheck(clientChecks[0])
      }
    }
  }, [clientChecks])

  useEffect(() => {
    if (properties && !selectedProperties) {
      setSelectedProps([])
    }
  }, [properties])

  useEffect(() => {
    if (properties) {
      let selectedProperties = (selectedProps && selectedProps.length)
        ? properties.filter(property => selectedProps.map(property => property.value).includes(property.code))
        : []

      if (!Roles.hasAccess('ReconciliationWithClientRead')) {
        selectedProperties = selectedProperties.filter(property => property.code !== 'budget')
      }

      const defaultPropertiesCodes = ['quantity']

      if (Roles.hasAccess('ReconciliationWithClientRead')) {
        defaultPropertiesCodes.push('budget')
      }

      let defaultProperties = properties.filter(property => defaultPropertiesCodes.includes(property.code))
        .sort((a, b) => (a.sort < b.sort) ? 1 : ((b.sort < a.sort) ? -1 : 0))

      for (let prop of defaultProperties) {
        selectedProperties.splice(0, 0, prop)
      }

      if (interval === monthInterval) {
        selectedProperties.unshift({
          //...fields[0],
          code: 'clientBudget',
          nameFact: i18n.t('clients.edit.client_budget'),
          type: "float",
        })
      }

      const returnProp = properties.find(property => property.code === 'return')

      if (returnProp) {
        selectedProperties.unshift(returnProp)
      }

      setSelectedProperties(selectedProperties)
    }
  }, [selectedProps, interval])

  const getClientChecksParams = () => {
    if (props.channel.id) {
      const dateStart = getUtcDate(props.dateStart).startOf('month').format(dateFormat)
      const dateEnd = getUtcDate(props.dateEnd).endOf('month').format(dateFormat)

      return {
        'client.id': props.companyId,
        'channel.id': props.channel.id,
        dateStart,
        dateEnd,
      }
    }

    return {}
  }

  const saveFactDataValues = (intervals, callback) => {
    if (intervals && intervals.length) {
      saveFactData({
        intervals
      }, null, {
        'face.advertising.id': advert.id,
        'date[after]': props.dateStart,
        'date[before]': props.dateEnd,
        'interval': interval.code
      }, true, () => {
        if (typeof callback === 'function') {
          callback()
        }
      }, () => {
      });
    }
  }

  const chunk = (array, size) =>
    Array.from({length: Math.ceil(array.length / size)}, (value, index) =>
      array.slice(index * size, index * size + size));

  const onSaveAllFacesData = async (periodToSave = null) => {
    setIsFaceSaving(true)

    Promise.all(
      [
        saveAllFactData(),
        saveAllClientCheckRows()
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        fetchData()
      })
      .finally(() => {
        setIsFaceSaving(false)
      })
      .catch(() => {
        setIsFaceSaving(false)
      })
  }

  const saveAllClientCheckRows = async () => {
    return new Promise(async (resolve, reject) => {
      if (interval !== monthInterval) {
        resolve(true)
        return
      }

      const check = {
        client: clientCheck.client,
        channel: clientCheck.channel,
        dateStart: clientCheck.dateStart,
        dateEnd: clientCheck.dateEnd,
        rows: [],
      }

      for (let interval of period) {
        if (interval.faces) {
          for (let faceIri in interval.faces) {
            const face = interval.faces[faceIri]

            if (face.clientBudget && face.clientBudget.fact && face.clientBudget.fact.editable) {
              if (face.clientBudget.fact.value === '') {
                if (face.checkRow && face.checkRow.id) {
                  await deleteClientCheckRow(face.checkRow.id, () => {
                  })
                }

                continue
              }

              const checkRowData = {
                budget: face.clientBudget.fact.value,
                faces: [
                  faceIri
                ]
              }

              if (face.checkRow && face.checkRow['@id']) {
                checkRowData.id = face.checkRow['@id']
              }

              check.rows.push(checkRowData)
            }
          }
        }
      }

      if (check.rows.length) {
        updateClientCheck(clientCheck.id, check, {}, () => {
          resolve(true)
        })
      } else {
        resolve(true)
      }
    })
  }

  const saveAllFactData = async (periodToSave = null) => {
    return new Promise((resolve, reject) => {
      if (!selectedProperties || !selectedProperties.length) {
        return resolve(true)
      }

      const formPeriod = periodToSave || period

      const {intervals} = getIntervalsToSave(formPeriod, advert['@id'], properties)

      if (!intervals || !intervals.length) {
        return resolve(true)
      }

      saveFactDataValues(intervals, () => {
        return resolve(true)
      })
    })
  }

  const onSaveFaceData = async (face) => {
    setIsFaceSaving(true)

    Promise.all(
      [
        saveFaceFactData(face),
        saveClientCheckRow(face)
      ]
    )
      .then(() => {
        toast.success(i18n.t('common.saved'))
        fetchData()
      })
      .finally(() => {
        setIsFaceSaving(false)
      })
      .catch(() => {
        setIsFaceSaving(false)
      })
  }

  const saveClientCheckRow = async (face) => {
    const faceIri = face.key

    return new Promise((resolve, reject) => {
      if (interval !== monthInterval) {
        return resolve(true)
      }

      if (!face || !face.clientBudget || !face.clientBudget.fact || !face.clientBudget.fact.editable) {
        return resolve(true)
      }

      if (face.checkRow && face.checkRow.id) {
        if (face.clientBudget.fact.value !== '') {
          updateClientCheckRow(face.checkRow.id, {
            clientCheck: clientCheck['@id'],
            budget: face.clientBudget.fact.value,
            faces: [
              faceIri
            ]
          }, {}, () => {
            return resolve(true)
          }, () => {
            return reject(false)
          })
        } else {
          deleteClientCheckRow(face.checkRow.id, () => {
            return resolve(true)
          })
        }
      } else if (face.clientBudget.fact.value !== '') {
        addClientCheckRow({
          clientCheck: clientCheck['@id'],
          budget: face.clientBudget.fact.value,
          faces: [
            faceIri
          ]
        }, true, {}, true, () => {
          return resolve(true)
        }, () => {
          return reject(false)
        })
      } else {
        return resolve(true)
      }
    })
  }

  const saveFaceFactData = async (face) => {
    return new Promise((resolve, reject) => {
      if (!selectedProperties || !selectedProperties.length) {
        return resolve(true)
      }

      const {intervals} = getIntervalsToSave(period, advert['@id'], properties, face.key)

      if (!intervals || !intervals.length) {
        return resolve(true)
      }

      saveFactDataValues(intervals, () => {
        return resolve(true)
      })
    })
  }

  const onDeleteClientCheckRow = (face) => {
    if (face && face.checkRow) {
      deleteClientCheckRow(face.checkRow.id, () => {
        toast.success(i18n.t('common.deleted'))
        fetchData()
      })
    }
  }

  const onRowChange = async (value, property, periodIndex, faceIri) => {
    const formPeriod = cloneDeep(period)

    if (formPeriod && formPeriod[periodIndex] && formPeriod[periodIndex].faces[faceIri]) {
      if (!formPeriod[periodIndex].faces[faceIri][property.code]) {
        formPeriod[periodIndex].faces[faceIri][property.code] = {
          fact: {},
          plan: {},
        }
      }

      if (!formPeriod[periodIndex].faces[faceIri][property.code].fact) {
        formPeriod[periodIndex].faces[faceIri][property.code].fact = {}
      }

      formPeriod[periodIndex].faces[faceIri][property.code].fact.value = value
    }

    await setPeriod(formPeriod)
  }

  const getCorrectionValue = (type, value) => {
    if (type === 'float') {
      return Math.round(parseFloat(value))
    } else if (type === 'int') {
      return Math.round(parseInt(value))
    }
  }

  const setRowDetailValues = (values) => {
    if (values && values.length) {
      const formPeriod = cloneDeep(period)

      if (formPeriod[selectedPeriodToPasteTable] && formPeriod[selectedPeriodToPasteTable].faces) {
        let valuesIndex = null
        for (let detailKey in formPeriod[selectedPeriodToPasteTable].faces) {
          let face = formPeriod[selectedPeriodToPasteTable].faces[detailKey]

          if (detailKey === selectedDetailToPasteTable) {
            valuesIndex = 0
          }

          if (valuesIndex !== null) {
            if (values[valuesIndex]) {
              let fieldIndex = 0

              for (let field of properties) {
                if (field.code !== 'return') {
                  const type = field.type

                  if (values[valuesIndex][fieldIndex]) {
                    if (!face[field.code]) {
                      face[field.code] = {
                        fact: {}
                      }
                    } else if (!face[field.code].fact) {
                      face[field.code].fact = {}
                    }

                    face[field.code].fact.value = getCorrectionValue(type, values[valuesIndex][fieldIndex])
                  }

                  fieldIndex++
                }
              }
            }

            valuesIndex++

            if (!values[valuesIndex]) {
              break
            }
          }
        }
      }

      setPeriod(formPeriod)

    }
  }

  const setColumnDetailValues = (values) => {
    if (values && typeof values === 'object') {
      const formPeriod = cloneDeep(period)
      for (let code in values) {
        const propertyField = properties.find(field => field.code === code)

        if (!propertyField || !propertyField.type) {
          continue
        }

        const type = propertyField.type

        if (formPeriod[selectedPeriodToPasteTable] && formPeriod[selectedPeriodToPasteTable].faces) {
          let fieldIndex = null

          for (let detailKey in formPeriod[selectedPeriodToPasteTable].faces) {
            let face = formPeriod[selectedPeriodToPasteTable].faces[detailKey]

            if (fieldIndex === null) {
              fieldIndex = 0
            }

            if (values[code] && values[code][fieldIndex] !== undefined) {
              if (!face[code]) {
                face[code] = {
                  fact: {}
                }
              }

              if (!face[code].fact) {
                face[code].fact = {}
              }

              face[code].fact.value = getCorrectionValue(type, values[code][fieldIndex])
            }

            fieldIndex++
          }
        }
      }

      setPeriod(formPeriod)
    }
  }

  const pasteFromTable = () => {
    if ((selectedDetailToPasteTable === null && selectedFieldToPasteTable === null)
      || (selectedPeriodToPasteTable === null)
      || textToPasteTable === null
    ) {
      return
    }

    if (selectedFieldToPasteTable !== null) {
      const data = getColumnDataFromTableText(textToPasteTable, properties, selectedFieldToPasteTable)

      setColumnDetailValues(data)
    } else if (selectedDetailToPasteTable !== null) {
      const data = getRowDataFromTableText(textToPasteTable)

      setRowDetailValues(data)
    }

    setTextToPasteTable(null)
    setSelectedDetailToPasteTable(null)
    setSelectedFieldToPasteTable(null)
    setSelectedPeriodToPasteTable(null)
  }

  const getSumValues = (type) => {
    let sums = {
      [type]: {}
    }

    if (!selectedProperties || !selectedProperties.length) {
      return sums
    }

    for (let field of selectedProperties) {
      sums[type][field.code] = 0
    }

    for (let intervalId in period) {
      if (!period.hasOwnProperty(intervalId)) {
        continue
      }

      let interval = period[intervalId]

      for (let faceId in interval.faces) {
        if (!interval.faces.hasOwnProperty(faceId)) {
          continue
        }

        let face = interval.faces[faceId]

        for (let field of selectedProperties) {
          if (face.hasOwnProperty(field.code) && face[field.code][type]) {
            const value = getFieldValue(face[field.code][type])

            sums[type][field.code] += Number(value)
          }

          sums[type][field.code] = round(sums[type][field.code])
        }
      }
    }

    return sums[type]
  }

  const setFaceSelected = faceId => {
    const advertFace = props.advertFaces.find(face => face.id === faceId)

    if (advertFace) {
      props.setSelectedFace(advertFace)
    }
  }

  const isCheck = () => {
    return period.every(interval => {
      return interval.faces && interval.faces.length && interval.faces.every(face => (face.check))
    })
  }

  const isAnyLoading = () => isFaceSaving || isFactDataLoading || periodsLoading

  const propsOptions = (properties && properties.length)
    ? properties.filter(property => !['return', 'budget', 'quantity'].includes(property.code)).map(property => ({
      value: property.code,
      label: property.nameFact,
    }))
    : []

  const isSaveButton = face => {
    return face && ((face.budget && face.budget.fact && face.budget.fact.editable)
      || (face.clientBudget && face.clientBudget.fact && face.clientBudget.fact.editable))
  }

  const isDeleteButton = face => {
    return face && face.checkRow && face.checkRow.id && !face.checkRow.approved
  }

  const setSelectedFace = face => {
    if (face.id) {
      const channelFace = props.advertFaces.find(channelFace => channelFace.id === face.id)

      if (channelFace) {
        setSelectedFaceForInfo(channelFace)
      }
    }
  }

  const sendPlan = () => {
    if (!isSubscribed) {
      sendPlanValues(advert.id)
      setIsSubscribed(true)
    } else {
      sendPlanValues(advert.id, false)
    }
  }

  const handleForDelete = (period, face, value) => {
    let selected = new Map(selectedForDelete)

    if (value) {
      if (selected.has(period)) {
        const periodFaces = selected.get(period)
        periodFaces.push(face)
        selected.set(period, [
          ...periodFaces
        ])
      } else {
        selected.set(period, [
          face
        ])
      }
    } else {
      if (selected.has(period)) {
        const periodFaces = selected.get(period)
        if (periodFaces.includes(face)) {
          const newFaces = periodFaces.filter(periodFace => periodFace !== face)

          if (newFaces.length) {
            selected.set(period, [
              ...newFaces
            ])
          } else {
            selected.delete(period)
          }
        }
      }
    }

    setSelectedForDelete(selected)
  }

  const handleSelectAll = () => {
    if (selectedForDelete && selectedForDelete.size) {
      setSelectedForDelete(null)
    } else {
      const items = new Map()
      for (let periodIndex in period) {
        const periodItem = period[periodIndex]
        const periodFaces = []

        for (let faceIndex in Object.values(periodItem.faces)) {
          periodFaces.push(+faceIndex)
        }

        items.set(+periodIndex, [
          ...periodFaces
        ])
      }

      setSelectedForDelete(items)
    }
  }

  const deleteFactItem = (startDate, endDate, facesIds) => {
    deleteFaceFact(null, () => {}, {
      'date[before]': endDate,
      'date[after]': startDate,
      'face.id[]': facesIds,
    })
  }

  const deleteFact = () => {
    const itemsToDelete = []

    selectedForDelete.forEach((periodFaces, periodIndex) => {
      const periodItem = period[periodIndex]
      const startDate = getFormattedDate(periodItem.dateStart, dateFormat)
      const endDate = getFormattedDate(periodItem.dateEnd, dateFormat)
      const facesIds = []
      for (let faceIndex of periodFaces) {
        const face = Object.values(periodItem.faces)[faceIndex]
        facesIds.push(face.id)
      }

      itemsToDelete.push({
        startDate,
        endDate,
        facesIds,
      })
    })

    Promise.all(itemsToDelete.map(item => deleteFactItem(item.startDate, item.endDate, item.facesIds))).then(values => {
      toast.success(i18n.t('common.deleted'))
      fetchData()
    })
  }

  return (
    <div>
      {<div>
        {props.button}
      </div>}
      <div>
        <div className={'filters'}>
          <RadioGroup
            title={i18n.t('clients.advertising.select_interval')}
            selected={interval}
            setSelected={setInterval}
            options={dateIntervals}
            name={'fact_interval'}
          />

          <FilterSelect
            options={contractorsOptions}
            title={i18n.t('clients.advertising.select_contractor')}
            onChange={setSelectedContractor}
            selected={selectedContractor}
          />

          <div className="filter-group-wrapper props-select">
            <div className="filter-group__title">
              {i18n.t('clients.advertising.select_metrics')}
            </div>
            <MultiSelectWithCheckbox
              className={'className'}
              classNamePrefix="react-select"
              placeholder={i18n.t('common.select')}
              options={propsOptions}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={setSelectedProps}
              allowSelectAll={true}
              value={selectedProps}
            />
          </div>

        </div>

        <>
          <div className={'mediaplan-tables fact-table'}>
            <div className={"edit-client" + (isAnyLoading() ? ' loading' : '')}>
              <table className="table redesign-table table-client goal-detail mediaplans with-bottom-text">
                <TableHeader
                  properties={selectedProperties}
                  leftColumnsColSpan={5}
                  titleField={'nameFact'}
                />
                <tbody>
                <SumRow
                  properties={selectedProperties}
                  values={getSumValues('fact')}
                  title={i18n.t('clients.advertising.fact_values')}
                  cellsBeforeTitle={
                    <td/>
                  }
                  titleColSpan={4}
                />
                <SumRow
                  properties={selectedProperties}
                  values={getSumValues('plan')}
                  title={i18n.t('clients.advertising.all_values')}
                  cellsBeforeTitle={
                    <td>
                      <Checkbox checked={selectedForDelete && selectedForDelete.size}
                                onChange={handleSelectAll}/>
                    </td>
                  }
                  titleColSpan={4}
                />

                {period && period.map((periodItem, periodIndex) => (
                  <Fragment key={periodItem.dateStart + periodItem.dateEnd}>
                    <tr>
                      <td/>
                      <td>
                        {interval && interval.code !== 'month' &&
                          <PeriodTitle
                            interval={interval}
                            dateStart={period.dateStart}
                            dateEnd={period.dateEnd}
                          />
                        }
                      </td>
                      <td/>
                      <td/>
                      <td/>
                      {selectedProperties && selectedProperties.map(property => (
                        <td className={'select-paste-column'} key={property.code}>
                          {property.code !== 'return' &&
                            <span
                              onClick={() => {
                                setSelectedFieldToPasteTable(property.code)
                                setSelectedPeriodToPasteTable(periodIndex)
                              }}
                            ><i className="fas fa-arrow-alt-circle-down"/></span>
                          }
                        </td>
                      ))}
                    </tr>

                    {periodItem.faces &&
                      <>
                        {Object.values(periodItem.faces).length > 0 && Object.values(periodItem.faces).map((face, faceIndex) => (
                          <Fragment key={face.name}>
                            <Row
                              properties={selectedProperties}
                              values={face || {}}
                              titleColSpan={1}
                              index={periodIndex}
                              faceIindex={faceIndex}
                              onChange={(value, property) => onRowChange(value, property, periodIndex, face.key)}
                              onSave={onSaveFaceData}
                              key={periodItem.dateStart + periodItem.dateEnd}
                              setSelectedRow={(periodIndex, faceIndex) => {
                                setSelectedPeriodToPasteTable(periodIndex)
                                setSelectedDetailToPasteTable(face.key)
                              }}
                              title={
                                <div className="period-face-title">
                                  <span
                                    onClick={() => setSelectedFace(face)}
                                    title={i18n.t('periods.face.booking')}
                                  ><i className="fal fa-info-circle"/></span>
                                  <a onClick={() => setFaceSelected(face.id)} className={'detail-name'}>{face.name}</a>
                                </div>
                              }
                              faceReturn={facesReturns ? facesReturns[face.id] : null}
                              faceCommissions={facesCommissions ? facesCommissions[face.id] : null}
                              rowCellComponent={FactRowCell}
                              cellAfterTitle={
                                <>
                                  <td>
                                    {
                                      face.contractor && face.contractor.name
                                        ? <span className='success-colored'>
                                            {face.contractor.name}
                                          </span>
                                        : <span className='error-colored'>
                                            {i18n.t('clients.advertising.empty_contractor')}
                                          </span>
                                    }
                                  </td>
                                  <td>
                                    <div className={'face-buttons'}>
                                      <Protected access={'FinancialCommissionsEdit'}>
                                        {face.contractor && face.contractor.id &&
                                          <Link
                                            to={`/financial/commission/from-fact/${advert.id}/${channelCode}/${face.id}`}>
                                        <span className={'face-add-commission'}
                                              title={i18n.t('clients.advertising.add_commission')}><PlusIcon/></span>
                                          </Link>
                                        }
                                      </Protected>
                                      <Protected access={'AdvertisingsFactsEdit'}>
                                        {isSaveButton(face) &&
                                          <div className={'correction-button'}
                                               onClick={() => onSaveFaceData(face)}
                                               title={i18n.t('clients.advertising.edit_correction')}>
                                            <i className={'far fa-save'}/>
                                          </div>
                                        }
                                      </Protected>

                                      <Protected access={'AdvertisingsFactsEdit'}>
                                        {isDeleteButton(face) &&
                                          <div className={'correction-button'}
                                               onClick={() => onDeleteClientCheckRow(face)}
                                               title={i18n.t('clients.advertising.delete_correction')}>
                                            <i className={'far fa-trash'}/>
                                          </div>
                                        }
                                      </Protected>

                                    </div>
                                  </td>
                                </>
                              }
                              cellBeforeTitle={
                                <td>
                                  <Checkbox checked={selectedForDelete && selectedForDelete.has(periodIndex)
                                    && selectedForDelete.get(periodIndex).includes(faceIndex)}
                                            onChange={value => handleForDelete(periodIndex, faceIndex, value)}
                                    title={i18n.t('clients.advertising.delete_all')}
                                  />
                                </td>
                              }
                            />
                          </Fragment>
                        ))}
                      </>
                    }
                  </Fragment>
                ))}

                </tbody>
              </table>
              {isAnyLoading() &&
                <LoaderBackdrop/>
              }

              {isFactDataLoading &&
                <Loader/>
              }
            </div>
          </div>
        </>
      </div>

      {(selectedDetailToPasteTable !== null || selectedFieldToPasteTable !== null) &&
        <PasteForm
          value={textToPasteTable || ''}
          setTextToPasteTable={setTextToPasteTable}
          setSelectedDetailToPasteTable={setSelectedDetailToPasteTable}
          setSelectedFieldToPasteTable={setSelectedFieldToPasteTable}
          paste={pasteFromTable}
        />
      }

      <Footer>
        <div className={'fact-table__buttons'}>
          <ButtonWithLoader
            onClick={props.getFactResultsCommand}
            className={"button"}
            text={i18n.t('mediaplans.page.get_fact_results')}
          />

          {channelEntity instanceof InternetChannel &&
            <a className="blue-cursor-button footer-link-button fact-table__buttons-history"
               onClick={props.getFactResultsHistory}
               title={'История ошибок загрузки'}
            >
              <i className="fal fa-list-alt"/>
            </a>
          }

          {channelEntity instanceof InternetChannel &&
            <button onClick={() => sendPlan()} className={'btn-icon fact-table__buttons-send'}
                    title={i18n.t('clients.advertising.save_plan_values')}>
              <i className="fal fa-cloud"/>
            </button>
          }
        </div>

        <div>
          {selectedForDelete && selectedForDelete.size &&
            <ButtonWithLoader
              onClick={deleteFact}
              className={"button"}
              text={i18n.t('clients.advertising.delete_all')}
              loadingText={''}
              isLoading={false}
            />
          }
          {period && !isCheck() &&
            <ButtonWithLoader
              onClick={() => onSaveAllFacesData()}
              className={"button " + (isFaceSaving ? 'load' : '')}
              text={i18n.t('clients.advertising.save_all')}
              loadingText={i18n.t('clients.advertising.saving_values')}
              isLoading={isFaceSaving}
            />
          }
        </div>
        {selectedFaceForInfo &&
          <>
            {channelEntity instanceof DigitalChannel &&
              <DigitalFaceInfo
                face={selectedFaceForInfo}
                setSelectedFaceForInfo={setSelectedFaceForInfo}
                companyId={companyID}
                closeModal={() => setSelectedFaceForInfo(null)}
                advertising={advert}
              />
            }
          </>
        }
      </Footer>

    </div>
  )
}

export default PeriodsTable
