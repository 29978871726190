import React from 'react';
import i18n from "i18next";
import Input from "../../Components/Inputs/Input";
import Select from 'react-select';
import Creatable from 'react-select/creatable';

function Form(props) {
  return (
    <table className="table redesign-table table-client">
      <tbody>
      <tr>
        <td>
          {i18n.t('products.list.name')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
            className={props.inputErrors.hasOwnProperty('name') ? 'has-error' : ''}
            value={props.formProduct.name || ''}
            onChange={(value) => props.setFormProduct({...props.formProduct, name: value})} />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('products.list.interval')}
        </td>
      </tr>
      <tr>
        <td className={props.inputErrors.hasOwnProperty('interval') ? 'has-error' : ''}>
          <Select
            className={"react-select-container select-pseudo-input " + (props.inputErrors.hasOwnProperty('interval') ? 'has-error' : '')}
            classNamePrefix="react-select"
            placeholder={i18n.t('products.list.interval')}
            options={props.intervalOptions}
            onChange={(interval) => {
              props.setSelectedInterval(interval)
              props.setFormProduct({...props.formProduct, interval: interval.value})
            }}
            value={props.selectedInterval || ''} />
        </td>
      </tr>

      {props.selectedInterval && props.selectedInterval.value === 'P1W' &&
      <>
        <tr>
          <td>
            {i18n.t('products.list.weekday')}
          </td>
        </tr>
        <tr>
          <td className={props.inputErrors.hasOwnProperty('weekday') ? 'has-error' : ''}>
            <Select
              className={"react-select-container select-pseudo-input" + (props.inputErrors.hasOwnProperty('weekday') ? 'has-error' : '')}
              classNamePrefix="react-select"
              placeholder={i18n.t('products.list.weekday')}
              options={props.weekDayOptions}
              onChange={(weekday) => {
                props.setSelectedWeekday(weekday)
                props.setFormProduct({...props.formProduct, weekDay: weekday.value})
              }}
              value={props.selectedWeekday || ''} />
          </td>
        </tr>
      </>
      }

      <tr>
        <td>
          {i18n.t('products.list.foreignCode')}
        </td>
      </tr>
      <tr>
        <td  className={props.inputErrors.hasOwnProperty('foreignCode') ? 'has-error' : ''}>
          <Creatable
            className={"react-select-container select-pseudo-input" +(props.inputErrors.hasOwnProperty('foreignCode') ? 'has-error' : '')}
            classNamePrefix="react-select"
            placeholder={i18n.t('products.list.foreignCode')}
            options={props.foreignCodeOptions}
            onChange={(foreignCode) => {
              props.setSelectedForeignCode(foreignCode)
              props.setFormProduct({...props.formProduct, foreignCode: foreignCode.value})
            }}
            value={props.selectedForeignCode} />
        </td>
      </tr>
      <tr>
        <td>
          {i18n.t('products.edit.mrmCode')}
        </td>
      </tr>
      <tr>
        <td>
          <Input type="text"
                 className={props.inputErrors.hasOwnProperty('mrmCode') ? 'has-error' : ''}
                 value={props.formProduct.mrmCode || ''}
                 onChange={(value) => props.setFormProduct({...props.formProduct, mrmCode: value})} />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default Form;