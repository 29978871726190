import React, {Fragment} from 'react';
import {renderSortIcon} from "../../../../../utils";

function Header({
                  properties,
                  leftColumnsColSpan = 0,
                  leftColumnsTitle = '',
                  cellsBeforeProps,
                  cellsAfterProps,
                  sortParams = {},
                  propertiesSortParams = {},
                  setSortParams = () => {},
                  titleField = 'name',
                }) {

  return (
    <thead className={'table-header'}>
    <tr>
      {leftColumnsColSpan > 0 &&
        <td colSpan={leftColumnsColSpan}>{leftColumnsTitle}</td>
      }

      {cellsBeforeProps && cellsBeforeProps}

      {properties && properties.map(property => (
        <Fragment key={property.code}>
          <td className={'money'}>
            {propertiesSortParams[property.code] ?
              < div className={'sort ' + (sortParams[property.code] || '')}
                    onClick={() => setSortParams(property.code)}
              >
                <span>{property[titleField]}</span>
                {renderSortIcon(property.code, sortParams, propertiesSortParams[property.code].asc, propertiesSortParams[property.code].desc)}
              </div>

              :
              <span>{property[titleField]}</span>
            }
          </td>
        </Fragment>
      ))
      }

      {cellsAfterProps && cellsAfterProps}
    </tr>
    </thead>
  )
}

export default Header;